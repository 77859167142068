import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    json: JsonType;

    constructor(
        private http: HttpClient
    ) { }

    loadConfig() {
        return this.http.get('./assets/config/config.json').toPromise().then(config => this.json = (config as any));
    }
}

type JsonType = {
    apiEndpoint: string;
    apiEndpointAuth: string;
    adminLogin: string;
    apiEndpointRdbms: string;
    apiEndpointVOne: string;
    imageHttpsUrlPrefix: string;
    imageUploadEndpoint: string;
    imageUrlPrefix: string;
    epgEndPoint:string;
    userType: "MSO" | "LCO" | "Distributor";
    adminLoginasDistributor:string;
    paymentStatus:string;
    imagPrefix:string
  }
