import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { RolebasedGuard } from '../guards/rolebased.guard';
import { AuthenticationModule } from '../authentication/authentication.module';
import { MsoModule } from '../mso/mso.module';
import { LcoModule } from '../lco/lco.module';
import { DistributorModule } from '../distributor/distributor.module';
import { StbModule } from '../stb/stb.module';
import { SubscriberGroupModule } from '../subscriber-group/subscriber-group.module';
import { LocationModule } from '../location/location.module';
import { SubscriberModule } from '../subscriber/subscriber.module';
import { PackageModule } from '../package/package.module';
import { BroadcasterModule } from '../broadcaster/broadcaster.module';
import { ChannelModule } from '../channel/channel.module';
import { CategoryModule } from '../category/category.module';
import { BouquetModule } from '../bouquet/bouquet.module';
import { MailModule } from '../mail/mail.module';
import { OsdModule } from '../osd/osd.module';
import { FingerprintModule } from '../fingerprint/fingerprint.module';
import { SubscriptionModule } from '../subscription/subscription.module';
import { BatchJobsManagementModule } from '../batch-jobs-management/batch-jobs-management.module';
import { ReportTraiModule } from '../report-trai/report-trai.module';
import { LogsModule } from '../logs/logs.module';
import { SettingsModule } from '../settings/settings.module';
import { AccessControlListModule } from '../access-control-list/access-control-list.module';
import { AdManagerModule } from '../ad-manager/ad-manager.module';
import { OTAManagerModule } from '../otamanager/otamanager.module';
import { ThemeManagerModule } from '../theme-manager/theme-manager.module';
import { AccountsModule } from '../accounts/accounts.module';
import { WalletModule } from '../wallet/wallet.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { ProfileModule } from '../profile/profile.module';
import { ManagementComponent } from '../bouquet/management/management.component';
import { MSOLoginAsLCOComponent } from './mso-login-lco';
import { MSOLoginAsDistributorComponent } from './mso-login-distributor';
import { SelfRegistrationModule } from '../self-registration/self-registration.module';
import { StatwiseLcnModule } from '../statwise-lcn/statwise-lcn.module';
import { CustomReportTraiModule } from '../custom-report-trai/custom-report-trai.module';
import { AffiliatorModule } from '../affiliator/affiliator.module';
import { LeadModule } from '../lead/lead.module';
import { LandingChannelManagementModule } from '../landing-channel-management/landing-channel-management.module';
import { OrdersModule } from '../orders/orders.module';

// const oldRoutes: Routes = [
//   { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
//   {
//     path: 'login',
//     loadChildren: () =>
//       import('../authentication/authentication.module').then(
//         (m) => m.AuthenticationModule
//       ),
//   },
//   {
//     path: 'admin',
//     loadChildren: () =>
//       import('../admin/admin.module').then((m) => m.AdminModule),
//   },
//   {
//     path: 'subscriber',
//     loadChildren: () =>
//       import('../subscriber/subscriber.module').then((m) => m.SubscriberModule),
//   },
//   {
//     path: 'bouquet',
//     loadChildren: () =>
//       import('../bouquet/bouquet.module').then((m) => m.BouquetModule),
//   },
//   {
//     path: 'subscriber-groups',
//     loadChildren: () =>
//       import('../subscriber-group/subscriber-group.module').then(
//         (m) => m.SubscriberGroupModule
//       ),
//   },
//   {
//     path: 'stb',
//     loadChildren: () => import('../stb/stb.module').then((m) => m.StbModule),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'channel',
//     loadChildren: () =>
//       import('../channel/channel.module').then((m) => m.ChannelModule),
//     canActivate: [RolebasedGuard],
//   },
//   {
//     path: 'category',
//     loadChildren: () =>
//       import('../category/category.module').then((m) => m.CategoryModule),
//     canActivate: [RolebasedGuard],
//   },
//   {
//     path: 'packages',
//     loadChildren: () =>
//       import('../package/package.module').then((m) => m.PackageModule),
//     canActivate: [RolebasedGuard],
//   },
//   {
//     path: 'mail',
//     loadChildren: () => import('../mail/mail.module').then((m) => m.MailModule),
//     canActivate: [RolebasedGuard],
//   },
//   {
//     path: 'osd',
//     loadChildren: () => import('../osd/osd.module').then((m) => m.OsdModule),
//     canActivate: [RolebasedGuard],
//   },
//   {
//     path: 'settings',
//     loadChildren: () =>
//       import('../settings/settings.module').then((m) => m.SettingsModule),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'transcoder',
//     loadChildren: () =>
//       import('../transcoder/transcoder.module').then((m) => m.TranscoderModule),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'trai-reports',
//     loadChildren: () =>
//       import('../report-trai/report-trai.module').then(
//         (m) => m.ReportTraiModule
//       ),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'statistics',
//     loadChildren: () =>
//       import('../statistics/statistics.module').then((m) => m.StatisticsModule),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'epg',
//     loadChildren: () => import('../epg/epg.module').then((m) => m.EpgModule),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'tags',
//     loadChildren: () => import('../tags/tags.module').then((m) => m.TagsModule),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'profile',
//     loadChildren: () =>
//       import('../profile/profile.module').then((m) => m.ProfileModule),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'miscellaneous',
//     loadChildren: () =>
//       import('../miscellaneous/miscellaneous.module').then(
//         (m) => m.MiscellaneousModule
//       ),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'dashboard',
//     loadChildren: () =>
//       import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'subscription',
//     loadChildren: () =>
//       import('../subscription/subscription.module').then(
//         (m) => m.SubscriptionModule
//       ),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'acl',
//     loadChildren: () =>
//       import('../access-control-list/access-control-list.module').then(
//         (m) => m.AccessControlListModule
//       ),
//   },
//   {
//     path: 'inventory',
//     loadChildren: () =>
//       import('../inventory/inventory.module').then((m) => m.InventoryModule),
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'location',
//     data: { preload: true },
//     loadChildren: () =>
//       import('../location/location.module').then((m) => m.LocationModule),
//   },
//   {
//     path: 'broadcaster',
//     loadChildren: () =>
//       import('../broadcaster/broadcaster.module').then(
//         (m) => m.BroadcasterModule
//       ),
//     canActivate: [AuthGuard, RolebasedGuard],
//   },
//   {
//     path: 'fingerprint',
//     loadChildren: () =>
//       import('../fingerprint/fingerprint.module').then(
//         (m) => m.FingerprintModule
//       ),
//     canActivate: [AuthGuard, RolebasedGuard],
//   },
//   {
//     path: 'lco',
//     loadChildren: () => import('../lco/lco.module').then((m) => m.LcoModule),
//     canActivate: [AuthGuard, RolebasedGuard],
//     data: { role: ['MSO'] },
//   },
//   {
//     path: 'mso',
//     loadChildren: () => import('../mso/mso.module').then((m) => m.MsoModule),
//     canActivate: [AuthGuard, RolebasedGuard],
//     data: { role: ['MSO'] },
//   },
//   {
//     path: 'logs',
//     loadChildren: () => import('../logs/logs.module').then((m) => m.LogsModule),
//   },
//   {
//     path: 'accounts',
//     loadChildren: () =>
//       import('../accounts/accounts.module').then((m) => m.AccountsModule),
//   },
//   {
//     path: 'batch-job',
//     loadChildren: () =>
//       import('../batch-jobs-management/batch-jobs-management.module').then(
//         (m) => m.BatchJobsManagementModule
//       ),
//   },
//   {
//     path: 'manage',
//     loadChildren: () =>
//       import('../distributor/distributor.module').then(
//         (m) => m.DistributorModule
//       ),
//   },
//   {
//     path: 'wallet',
//     loadChildren: () =>
//       import('../wallet/wallet.module').then((m) => m.WalletModule),
//   },
//   {
//     path: 'ad-manager',
//     loadChildren: () =>
//       import('../ad-manager/ad-manager.module').then((m) => m.AdManagerModule),
//   },
//   {
//     path: 'ota-manager',
//     loadChildren: () =>
//       import('../otamanager/otamanager.module').then((m) => m.OTAManagerModule),
//   },
//   {
//     path: 'theme',
//     loadChildren: () =>
//       import('../theme-manager/theme-manager.module').then(
//         (m) => m.ThemeManagerModule
//       ),
//   },
//   {
//     path: '**',
//     loadChildren: () =>
//       import('../not-found/not-found.module').then((m) => m.NotFoundModule),
//   },
// ];

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => AuthenticationModule,
  },
  {
    path: 'user/:id',
    component: MSOLoginAsLCOComponent,
  },
  {
    path: 'userdistributor/:id',
    component: MSOLoginAsDistributorComponent,
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => DashboardModule,
  },
  {
    path: 'manage',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'mso',
        loadChildren: () => MsoModule,
      },
      {
        path: 'lco',
        loadChildren: () => LcoModule,
      },
      {
        path: 'distributor',
        loadChildren: () => DistributorModule,
      },
      {
        path: 'subscriber',
        loadChildren: () => SubscriberModule,
      },
      {
        path: 'stb',
        loadChildren: () => StbModule,
      },
      {
        path: 'subscriber-groups',
        loadChildren: () => SubscriberGroupModule,
      },
      {
        path: 'location',
        loadChildren: () => LocationModule,
      },
      {
        path: 'bouquet-management',
        component: ManagementComponent,
      },
      {
        path: 'form-request',
        loadChildren: () => SelfRegistrationModule,
      },
      {
        path:'affiliator',
        loadChildren:() => AffiliatorModule,
      },
      {
        path:'lead',
        loadChildren:() => LeadModule,
      },
      {
        path:'orders',
        loadChildren:() => OrdersModule,
      },

    ],
  },
  {
    path: 'content',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'broadcaster',
        loadChildren: () => BroadcasterModule,
      },
      {
        path: 'channel',
        loadChildren: () => ChannelModule,
      },
      {
        path: 'category',
        loadChildren: () => CategoryModule,
      },
      {
        path: 'bouquet',
        loadChildren: () => BouquetModule,
      },
      {
        path: 'statewise-lcn',
        loadChildren: () => StatwiseLcnModule,
      },
    ],
  },
  {
    path: 'communication',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'mail',
        loadChildren: () => MailModule,
      },
      {
        path: 'osd',
        loadChildren: () => OsdModule,
      },
      {
        path: 'fingerprint',
        loadChildren: () => FingerprintModule,
      },
    ],
  },
  {
    path: 'subscription',
    canActivate: [AuthGuard],
    loadChildren: () => SubscriptionModule,
  },
  {
    path: 'batch-jobs',
    canActivate: [AuthGuard],
    loadChildren: () => BatchJobsManagementModule,
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'trai',
        loadChildren: () => ReportTraiModule,
      },
      {
        path: 'custom-reports',
        loadChildren: () => CustomReportTraiModule,
      },
    ],
  },
  {
    path: 'logs',
    canActivate: [AuthGuard],
    loadChildren: () => LogsModule,
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'general-settings',
        loadChildren: () => SettingsModule,
      },
      {
        path: 'access-control',
        loadChildren: () => AccessControlListModule,
      },
      {
        path: 'ad-manager',
        loadChildren: () => AdManagerModule,
      },
      {
        path: 'ota-manager',
        loadChildren: () => OTAManagerModule,
      },
      {
        path: 'theme-manager',
        loadChildren: () => ThemeManagerModule,
      },
      {
        path: 'landing-channel-management',
        loadChildren: () => LandingChannelManagementModule,
      }
    ],
  },
  {
    path: 'accounts',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'invoice',
        loadChildren: () => AccountsModule,
      },
      {
        path: 'wallet',
        loadChildren: () => WalletModule,
      },
    ],
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => ProfileModule,
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
