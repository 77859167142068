import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-distributor-search',
  templateUrl: './distributor-search.component.html',
  styleUrls: ['./distributor-search.component.scss'],
})
export class DistributorSearchComponent implements OnInit {
  @Input() searchText!: string;
  @Output() userDetail: EventEmitter<DistributorSearchType> =
    new EventEmitter<DistributorSearchType>();
  constructor(
    private http: HttpClientWrapperService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    console.log("hai")
    if (this.searchText != undefined) {
      this.fetchDistributorDetails();
    }
  }

  users;
  isEmpty = false;
  fetchDistributorDetails() {
    // let url = `${this.configService.json.apiEndpointRdbms}distributor/search?searchKeyword=${this.searchText}`;
    let url = this.configService.json.apiEndpointRdbms+`distributor/search?searchKeyword=${this.searchText}`
    this.http.get(url).subscribe({
      next: (res) => {
        console.log(res);
        this.users = res.data
      },
    });
  }

  getUser(user: any) {
    console.log(user);
    this.userDetail.emit(user);
  }
}
export type DistributorSearchType = {
  id: number;
  name: string;
  distributorCode: string;
  mobileNumber: string;
  email: string;
  address: string;
  createdDate: number;
};
