<div class="cms__content">
  <div class="top__heading">
    <h3>Add Subscriber Funds</h3>
    <a [routerLink]="['/accounts/wallet']"><button type="button" class="default__button float-right">Back</button></a>
  </div>
  <form [formGroup]="subscriberFundsForm" >
    <div class="col-12 form__style">
      <div class="row">

        <div class="col-12 col-md-6">
          <div class="input_search">
            <label>Search Subscriber</label>
            <input type="text" autocomplete="off" class="custom__field" id="searchText"  formControlName="subscriberId" (input)="getSubscriberDetails($event)" [ngClass]="{'error__field': !subscriberCode.valid && subscriberCode.touched}"/>
            <p id="clearInput" (click)="clearInput()" >Clear </p>
          </div>
          <div [hidden]="!subscriberCode.touched">
            <span class="error__msg" *ngIf="subscriberCode.hasError('required')">Please enter subscriber code</span>
          </div>
          <div class="search_param" *ngIf="openSearch" >
            <app-subscriber-search [searchText]="searchText" (userDetail)="getUserDetail($event)" ></app-subscriber-search>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Mode Of Payment</label>
          <select name="payment-type" id="payment-type" class="custom__field" formControlName="modeOfPayment" [ngClass]="{'error__field': !modeOfPayment.valid && modeOfPayment.touched}">
            <option value="">Select</option>
            <option value="cash">Cash</option>
            <option value="imps">IMPS</option>
            <option value="neft">NEFT</option>
            <option value="upi">UPI</option>
            <option value="cheque">Cheque</option>
          </select>
          <div [hidden]="!modeOfPayment.touched">
            <span class="error__msg" *ngIf="modeOfPayment.hasError('required')">Please select mode of payment</span>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Amount</label>
          <input type="number" class="custom__field" formControlName="amount"  [ngClass]="{'error__field': !amount.valid && amount.touched}"/>
          <div [hidden]="!amount.touched">
            <span class="error__msg" *ngIf="amount.hasError('required')">Please enter amount</span>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Transaction ID</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="transactionID" [ngClass]="{'error__field': !transactionID.valid && transactionID.touched}"/>
          <div [hidden]="!transactionID.touched">
            <span class="error__msg" *ngIf="transactionID.hasError('required')">Please enter transaction ID</span>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Remarks</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="remarks" [ngClass]="{'error__field': !remarks.valid && remarks.touched}"/>
          <div [hidden]="!remarks.touched">
            <span class="error__msg" *ngIf="remarks.hasError('required')">Please enter remarks</span>
          </div>
        </div>

      </div>
    </div>
  </form>
  <div class="col-12">
    <button class="cta float-right" [disabled]="subscriberFundsForm.invalid" (click)="persist()">Submit</button>
  </div>
</div>
