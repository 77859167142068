<div class="cms__content">
    <app-reports-header></app-reports-header>
  
    <div class="top__heading">
      <h3>Landing Channel Setting Logs</h3>
      <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
    </div>

    <div class="third_box">
        <div class="col-12 col-md-6 report">
          <p>
            Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
          </p>
        </div>
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
              PDF
              <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
              Excel
              <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
              CSV
              <img src="./assets/csv.svg" alt="download" />
            </button>
          </div>
      </div>
  
   
    <div class="custom__table__filter">
      <span>
        Show
        <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
          <option
            *ngFor="let size of paginationObj.pageSizeOptions"
            [ngValue]="size"
          >
            {{ size }}
          </option>
        </select>
        Entries
      </span>
      <div class="table__right">
        <p class="table__summary">
          Total <span>{{ paginationObj.totalElements }}</span> records
        </p>
        <div class="search__container">
          <input
            type="text"
            autocomplete="off"
            class="custom__field"
            id="searchKeyword"
            [(ngModel)]="paginationObj.searchKeyword"
            (keyup.enter)="searchKeyWord()"
          />
          <button (click)="searchKeyWord()">
            <img src="./assets/search.png" alt="search" />
          </button>
        </div>
      </div>
    </div>
  
    <div class="col-12 list__table">
      <div class="row scroll">
        <table class="custom__iptv__table" #reportTable id="reportTable">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>LCO ID</th>
              <th>LCO Name</th>
              <th>Channel ID</th>
              <th>Channel Number</th>
              <th>Channel Name</th>
              <th>Created By</th>
        

            </tr>
          </thead>
          <tbody>
            <tr *ngIf="reportList.length ==0 && !loadingFlag" >
              <td style="text-align: center !important;" colspan="12" >{{isLoad}}</td>
            </tr>
            <tr *ngIf="loadingFlag; else showList">
              <td style="text-align: center !important;" colspan="12">
              <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <ng-template #showList>
              <tr *ngFor="let report of reportList, let j= index">
                <!-- <td>{{report.stbId? report.stbId : '---'}}</td> -->
                 <td>{{(paginationObj.pageNumber - 1) * paginationObj.pageSize +(j + 1)}}</td>
                <td>{{report.lcoId? report.lcoId : '---' }}</td>
                <td>{{report.lcoName? report.lcoName : '---'}}</td>
                <td>{{report.channelId ? report.channelId:'---'}}</td>
                <td>{{report.channelLcn ?report.channelLcn:'---' }}</td>
                <td>{{report.channelName ?report.channelName:'---' }}</td>
                <td>{{report.createdBy?report.createdBy:'---'}}</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  