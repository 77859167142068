import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectService } from 'src/app/services/subject.service';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/constants/global-constants';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import {
  AclGroupInfo,
  AclMenus,
} from 'src/app/models/acl-group/acl-group-info';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { ResizeService } from './resize.service';
import menus from './menu';
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit, OnDestroy {
  constructor(
    private subject: SubjectService,
    private router: Router,
    private auth: AuthService,
    private http: HttpClientWrapperService,
    private config: ConfigService,
    private resize: ResizeService
  ) {}

  profileFlag = false;
  logFlag = false;
  reportFlag = false;
  communicationsFlag = false;
  pluginsFlag = false;
  contentFlag = false;
  manageFlag = false;
  settingsFlag = false;
  accountsFlag = false;
  showMobileFlag = false;
  subscriptionFlag = false;
  inventoryFlag = false;

  menuData = {};
  menuMapping = {};
  userRole: string;

  isNotMso = true;
  resizeSubscription: Subscription;
  ngOnInit(): void {
    this.resizeSubscription = this.resize.getResizeEvent().subscribe(() => {
      this.screenWidthDetect();
    });
    this.screenWidthDetect();
    this.initParams();
    this.initMenuData();
    // this.getMenusFromLocalStorage();
  }

  listen = false;
  i = 1;
  clickOutside(): void {
    if (
      this.i == 1 &&
      (this.manageFlag ||
        this.profileFlag ||
        this.logFlag ||
        this.reportFlag ||
        this.communicationsFlag ||
        this.pluginsFlag ||
        this.contentFlag ||
        this.settingsFlag ||
        this.accountsFlag ||
        this.subscriptionFlag ||
        this.inventoryFlag)
    ) {
      this.i += 1;
    } else {
      this.manageFlag = false;
      this.profileFlag = false;
      this.logFlag = false;
      this.reportFlag = false;
      this.communicationsFlag = false;
      this.pluginsFlag = false;
      this.contentFlag = false;
      this.settingsFlag = false;
      this.accountsFlag = false;
      this.subscriptionFlag = false;
      this.inventoryFlag = false;
      this.listen = false;
      this.i = 1;
    }
  }

  menuArray = menus;
  getMenusFromLocalStorage() {
    let str = atob(localStorage.getItem('menus'));
    let menuObj = JSON.parse(str);
    let parentObj: any = {};
    for (let ele of menuObj) {
      if (ele.parentId == 0) {
        parentObj[ele.id] = { ...ele, children: [] };
      }
    }
    for (let ele of menuObj) {
      if (ele.parentId != 0) {
        parentObj[ele.parentId].children.push(ele);
      }
    }
    let menuArray = [];
    for (let ele of Object.keys(parentObj)) {
      menuArray.push(parentObj[ele]);
    }
    this.menuArray = menuArray;
    console.log(menuArray);
  }

  getMenus(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'menu/getMenus';
    return this.http.get(url);
  }

  initParams() {
    this.getMenus().subscribe({
      next: (data) => {
        let datdata = data;
        datdata.map((each) => {
          this.menuMapping[each.name] = each.id;
        });
      },
      error: () => {
        this.initParams();
      },
    });
  }

  initMenuData() {
    this.userRole = this.auth.getRole();
    this.menuData = {};
    if (
      this.userRole === 'LCO' ||
      this.userRole === 'MSO' ||
      this.userRole === 'Distributor'
    ) {
      for (let i = 1; i <= 40; i++) {
        this.menuData[i] = true;
      }
    } else {
      (this.auth.userMenuData === undefined
        ? JSON.parse(this.auth.getMenus())
        : this.auth.userMenuData
      ).map((each) => {
        this.menuData[each.id] = true;
      });
    }
    this.setWalletData();
  }

  amount;
  setWalletData() {
    if (this.userRole === 'MSO') {
      this.isNotMso = false;
      return;
    }
    let id = this.auth.getLcoId();
    let url;
    if (this.userRole === 'LCO') {
      this.isNotMso = true;
      url = `${this.config.json.apiEndpoint}wallet/lco?lcoId=${id}`;
    }
    if (this.userRole === 'Distributor') {
      this.isNotMso = true;
      url = `${this.config.json.apiEndpoint}wallet/distributor?distributorId=${id}`;
    }
    this.http.get(url).subscribe({
      next: (res) => {
        this.amount = res.data.amount;
      },
    });
  }

  openMenu(str: string): void {
    switch (str) {
      case 'manage':
        this.manageFlag = !this.manageFlag;
        this.profileFlag = false;
        this.logFlag = false;
        this.reportFlag = false;
        this.communicationsFlag = false;
        this.pluginsFlag = false;
        this.contentFlag = false;
        this.settingsFlag = false;
        this.accountsFlag = false;
        this.subscriptionFlag = false;
        this.inventoryFlag = false;
        break;
      case 'profile':
        this.profileFlag = !this.profileFlag;
        this.manageFlag = false;
        this.logFlag = false;
        this.reportFlag = false;
        this.communicationsFlag = false;
        this.pluginsFlag = false;
        this.contentFlag = false;
        this.settingsFlag = false;
        this.accountsFlag = false;
        this.subscriptionFlag = false;
        this.inventoryFlag = false;
        break;
      case 'logs':
        this.logFlag = !this.logFlag;
        this.manageFlag = false;
        this.profileFlag = false;
        this.reportFlag = false;
        this.communicationsFlag = false;
        this.pluginsFlag = false;
        this.contentFlag = false;
        this.settingsFlag = false;
        this.accountsFlag = false;
        this.subscriptionFlag = false;
        this.inventoryFlag = false;
        break;
      case 'reports':
        this.reportFlag = !this.reportFlag;
        this.manageFlag = false;
        this.profileFlag = false;
        this.logFlag = false;
        this.communicationsFlag = false;
        this.pluginsFlag = false;
        this.contentFlag = false;
        this.settingsFlag = false;
        this.accountsFlag = false;
        this.subscriptionFlag = false;
        this.inventoryFlag = false;
        break;
      case 'communications':
        this.communicationsFlag = !this.communicationsFlag;
        this.manageFlag = false;
        this.profileFlag = false;
        this.logFlag = false;
        this.reportFlag = false;
        this.pluginsFlag = false;
        this.contentFlag = false;
        this.settingsFlag = false;
        this.subscriptionFlag = false;
        this.accountsFlag = false;
        this.inventoryFlag = false;
        break;
      case 'plugin':
        this.pluginsFlag = !this.pluginsFlag;
        this.manageFlag = false;
        this.profileFlag = false;
        this.logFlag = false;
        this.reportFlag = false;
        this.communicationsFlag = false;
        this.contentFlag = false;
        this.settingsFlag = false;
        this.accountsFlag = false;
        this.subscriptionFlag = false;
        this.inventoryFlag = false;
        break;
      case 'content':
        this.contentFlag = !this.contentFlag;
        this.manageFlag = false;
        this.profileFlag = false;
        this.logFlag = false;
        this.reportFlag = false;
        this.communicationsFlag = false;
        this.pluginsFlag = false;
        this.settingsFlag = false;
        this.accountsFlag = false;
        this.subscriptionFlag = false;
        this.inventoryFlag = false;
        break;
      case 'settings':
        this.settingsFlag = !this.settingsFlag;
        this.manageFlag = false;
        this.profileFlag = false;
        this.logFlag = false;
        this.reportFlag = false;
        this.communicationsFlag = false;
        this.pluginsFlag = false;
        this.contentFlag = false;
        this.accountsFlag = false;
        this.subscriptionFlag = false;
        this.inventoryFlag = false;
        break;
      case 'accounts':
        this.accountsFlag = !this.accountsFlag;
        this.manageFlag = false;
        this.profileFlag = false;
        this.logFlag = false;
        this.reportFlag = false;
        this.communicationsFlag = false;
        this.pluginsFlag = false;
        this.contentFlag = false;
        this.settingsFlag = false;
        this.subscriptionFlag = false;
        this.inventoryFlag = false;
        break;
      case 'subscription':
        this.subscriptionFlag = !this.subscriptionFlag;
        this.manageFlag = false;
        this.profileFlag = false;
        this.logFlag = false;
        this.reportFlag = false;
        this.communicationsFlag = false;
        this.pluginsFlag = false;
        this.contentFlag = false;
        this.settingsFlag = false;
        this.accountsFlag = false;
        this.inventoryFlag = false;
        break;
      case 'inventory':
        this.inventoryFlag = !this.inventoryFlag;
        this.subscriptionFlag = false;
        this.manageFlag = false;
        this.profileFlag = false;
        this.logFlag = false;
        this.reportFlag = false;
        this.communicationsFlag = false;
        this.pluginsFlag = false;
        this.contentFlag = false;
        this.settingsFlag = false;
        this.accountsFlag = false;
    }
    this.listen = true;
  }

  mobileMenu(): void {
    this.showMobileFlag = !this.showMobileFlag;
  }

  screenWidthDetect(): void {
    if (window.innerWidth < 768) {
      this.showMobileFlag = false;
    } else {
      this.showMobileFlag = true;
    }
  }

  logout(): void {
    window.localStorage.removeItem(GlobalConstants.access);
    this.subject.setAccessSubject(false);

    this.auth.logout();
    this.router.navigate(['login']);
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
  }
}
