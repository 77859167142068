import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-report-subscriber-details',
  templateUrl: './report-subscriber-details.component.html',
  styleUrls: ['./report-subscriber-details.component.scss']
})
export class ReportSubscriberDetailsComponent implements OnInit {

  reportList = [];
  bouquetType = 0;
  broadcasterId: number;

  broadcasterList: BroadcasterInfo[] = [];

  selectedDate: any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config : ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchLocationList();
    this.fetchData()
  }

  fetchLocationList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }

  statUS=['Inactive', 'Active', 'Blacklisted','New', 'Terminated']
  fetchData(): void {
    // //// console.log(this.broadcasterId, this.selectedDate)
    // if (this.selectedDate && this.broadcasterId) {
    //   const body = {
    //     "monthYear": this.selectedDate + '-01',
    //     broadcasterId: this.broadcasterId
    //   }
    //   this.report.getBroadcasterWiseChannelsReportMonthly(body).subscribe(data => {
    //     // //// console.log(data)
    //     this.reportList = data;
    //   });
    // }
    this.report.getSubscriberDetails().subscribe((res)=>{
      // //// console.log(res)
      this.reportList=res.data
      if(this.reportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }

  loadingFlag=false
  isLoad = 'Choose Date'

  downloadTableExcel(): void {
    let name='Subscriber Details Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'subscriber_details');
  }

  downloadTablePdf(): void {
    const tempDate = new Date(this.selectedDate);
    const monthYear = tempDate.toLocaleString('default', {month:'long'}) + " " + tempDate.getFullYear();
    this.pdf.generatePdfHtml('#reportTable',
    'subscriber_details',
    `Source Type : SMS `
    );
  }


  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.","Subscriber Code","Mac ID","Subscriber Name","State","City","Subscriber Status",
    ,"Subscription Type", 'Price Package', 'Subscription Status']
    };
    let data = []

    // // console.log(this.reportList)
    let i = 1
    this.reportList.forEach(report => {
      let csvLine = {
        slNo : i,
        subscriber_code : report.subscriber_code? report.subscriber_code : '---',
        ethernet_mac_address : report.ethernet_mac_address? report.ethernet_mac_address : '---',
        subscriber_name : report.subscriber_name? report.subscriber_name : '---',
        state : report.state? report.state : '---',
        city : report.city? report.city : '---',
        subscriber_status : this.statUS[report.subscriber_status] != null? this.statUS[report.subscriber_status] : '---',
        subscription_type : report.subscription_type? report.subscription_type : '---' ,
        price_package_name : report.price_package_name? report.price_package_name : '---',
        subscription_status : this.statUS[report.subscription_status] != null? this.statUS[report.subscription_status] : '---',
      }
      // // console.log(csvLine, i)
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_details_report', options);}

    searchKeyword(){
      let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
      let url = this.config.json.apiEndpointRdbms + 'subscriber/getSubscriberDetails/?searchKeyword='+value
      this.http.get(url).subscribe(data => {
        this.reportList = data.data;
        if(this.reportList.length != 0){
          this.loadingFlag=false
        }else{
          this.loadingFlag=false
        this.isLoad='No Records'
        }
      },()=>{
        this.loadingFlag=false
        this.isLoad='No Records'
      });
    }

}
