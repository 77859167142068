import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assign-subscribers-to-subscriber-group',
  templateUrl: './assign-subscribers-to-subscriber-group.component.html',
  styleUrls: ['./assign-subscribers-to-subscriber-group.component.scss']
})
export class AssignSubscribersToSubscriberGroupComponent implements OnInit {

  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLco()
  }


  lcoList
  getLco() {
    let url = this.config.json.apiEndpointRdbms + 'subscribergroup/getSubscriberGroups'
    this.http.get(url).subscribe({
      next: (res) => {
        // // console.log(res)
        this.lcoList = res
      }
    })
  }

  sampleData = [
    {
      'Subscriber Code': 'BAN0000007'
    },
    {
      'Subscriber Code': 'BAN0000008'
    },
  ];

  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'assign_subscriber_to_subscriber_groups');
  }

  selectedLco
  optionSelected = false
  selectOption() {
    this.optionSelected = true
  }

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement
    this.excelgen.deselectSelectedFiles(input)
    this.isSubmitReady = false
  }


  isSubmitReady = false;
  message: string = ''
  errorBody = []
  errorAvailable = false
  e
  selectedFile(e) {
    this.message = ''
    this.e = e
    const url = this.config.json.apiEndpointRdbms + `bulkUpload/validation/subcribers/groupName/${this.selectedLco.name}`;
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        // // console.log(res)
        if (res.result) {
          this.isSubmitReady = true
          this.errorAvailable = false
        } else {
          this.errorAvailable = true
          this.errorBody = res.errorBody
        }
        if (res.hasOwnProperty('errorMessage')) {
          this.errorAvailable = false
        }
      },
      error: (error) => {
        this.isSubmitReady = false
        this.message = error.error.message
      }
    });
  }

  successMessage = 'No errors Found'
  uploadFile() {
    const url = this.config.json.apiEndpointRdbms + `bulkUpload/subscribersGroup/${this.selectedLco.name}`;
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message
          this.router.navigate(['/manage/subscriber-groups', 'list'])
        } else {
          this.errorAvailable = true
          this.successMessage = res.message
          this.errorBody = res.errorBody
        }
      }
    })
  }


}
