import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-subscribers',
  templateUrl: './create-subscribers.component.html',
  styleUrls: ['./create-subscribers.component.scss']
})
export class CreateSubscribersComponent implements OnInit {

  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLco();
    this.getPendingStatus()
  }

  lcoList;
  reportList;
  viewModal = false

  refreshClicked(){
    this.viewModal=!this.viewModal
    this.getPendingStatus()
  }

  
  getPendingStatus(){
    let url = this.config.json.apiEndpoint + 'subscriber/pendingRequestCount'
    this.http.get(url).subscribe({
      next: (res) => {
        this.reportList = res.data;
      },
    });  
  }
  getLco() {
    // let url = this.config.json.apiEndpoint + 'lco/getLCOs';
      let url = this.config.json.apiEndpoint + 'lco/dropdown'
    this.http.get(url).subscribe({
      next: (res) => {
        this.lcoList = res.data;
      },
    });
  }
  sampleData = [
    {
      'First Name': 'Mohan',
      'Last Name': 'Kumar',
      'Email': 'tester@gmail.com',
      'Mobile': '7979797979',
      'Gender': 'Male',
      'Address': '#213,4th main,kaveripura,560079',
      'Pincode': '560040',
      'District': 'Banglore',
      'State': 'Karnataka'
    },
    {
      'First Name': 'Tester',
      'Last Name': 'Onnet',
      'Email': 'testerOnnet@gmail.com',
      'Mobile': '7979797979',
      'Gender': 'Male',
      'Address': '#213,4th main,Summanahalli,560079',
      'Pincode': '560040',
      'District': 'Banglore',
      'State': 'Karnataka'
    },
  ];

  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'create_subscriber');
  }

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement
    this.excelgen.deselectSelectedFiles(input)
    this.isSubmitReady = false

  }


  selectedLco;
  optionSelected = false;
  selectOption() {
    this.optionSelected = true;
  }



  isSubmitReady = false;
  message: string = '';
  errorBody = [];
  errorAvailable = false;
  e;


  pushFunction(){
    let url=this.config.json.apiEndpoint+'subscriber/pushSubscriber'
    this.http.post(url,{}).subscribe(
      (res)=>{
        alert(res.message)
      }
    )
  }
  selectedFile(e) {
    this.message = ''
    this.e = e
    let file = e.target.files[0]
    let formData = new FormData()
    formData.append('file', file)
    const url = this.config.json.apiEndpoint + 'bulkUpload/validation/subscribers';
    this.http.postwithresponseType(url,formData).subscribe(
      (response) => {
        const reader = new FileReader();
  
        reader.onload = () => {
          const result = reader.result as string;
          if (typeof result === 'string' && result.trim().toLowerCase().includes('success')) {
           alert('Validation successful');
           this.isSubmitReady = true
           this.errorAvailable = false
 
          } else {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'errors_subscriber_creation.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            this.errorAvailable = true
            this.isSubmitReady = true
            this.errorBody = ['Please check a Downloaded file']
          }
        };
        reader.readAsText(response);
      },
      (error) => {
        console.error('Error downloading Excel file:', error);
      }
    )

  }

  successMessage = 'No errors Found';
  uploadFile() {
    const url = this.config.json.apiEndpoint + 'bulkUpload/subscribers';
    // this.config.json.apiEndpointRdbms +
    // `bulkUpload/subscribers/${this.selectedLco.lcoCode}`;
    this.excelgen.uploadFileForSubmitwithparams(this.e, url, this.selectedLco.id).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message
          if (confirm('Redirect to list of Subscribers?')) {
            this.router.navigate(['/manage/subscriber/list'])
          }
        } else {
          this.errorAvailable = true
          this.successMessage = res.message
          this.errorBody = res.errorBody
        }

      },
    });
  }

}
