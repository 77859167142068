import { Pipe, PipeTransform } from '@angular/core';
import { SubscriberInfo } from 'src/app/models/subscriber/subscriber-info';

@Pipe({
  name: 'subscriptionStatus'
})
export class SubscriptionStatusPipe implements PipeTransform {

  transform(subscriber: SubscriberInfo): boolean {

    if (subscriber.subscription && Object.keys(subscriber.subscription).length > 0) {
      return true;
    } else {
      return false;
    }

  }

}
