import { Component } from '@angular/core';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IPTv-Admin';

  constructor(
    private config: ConfigService) { }

    ngOnInit() {
      // //// console.log(this.config.json);
    }
}
