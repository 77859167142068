import { FormatWidth } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { PathNames } from 'src/app/constants/path-names';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { LocationInfo } from 'src/app/models/location/location-info';
import { PackageInfo } from 'src/app/models/package/package-info';
import { StbInfo } from 'src/app/models/stb/stb-info';
import { SubscriberGroupInfo } from 'src/app/models/subscriber-group/subscriber-group-info';
import {
  SubscriberInfo,
  SubscriptionPackage,
} from 'src/app/models/subscriber/subscriber-info';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';
import { Country, State, City } from 'country-state-city';
// import { ICountry, IState, ICity } from 'country-state-city/dist/lib/interface';
import { ICountry, IState, ICity } from 'country-state-city';

@Component({
  selector: 'app-create-subcriber',
  templateUrl: './create-subcriber.component.html',
  styleUrls: ['./create-subcriber.component.scss'],
})
export class CreateSubcriberComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private auth: AuthService,
    private config: ConfigService
  ) {}

  dropdownChannelList = [];
  dropdownPackageList = [];
  selectedList = [];

  selectedPackageList = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    textField: 'name',
  };

  subscriber = {} as SubscriberInfo;

  package = {} as SubscriberInfo;

  subscriberForm: FormGroup;

  // subscriberStbId: any;

  isEdit = false;

  phonePattern = '^[+][9][1][6-9][0-9]{9}$';
  passwordRegex =
    '^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*s).{8,15}$';

  stbList: StbInfo[] = [];

  packageList: PackageInfo[] = [];
  packages = {} as PackageInfo;
  channelList: ChannelInfo[] = [];
  groupList: SubscriberGroupInfo[] = [];

  locationList: LocationInfo[] = [];

  stbDropdownList = [];

  stbdata = [];
  highestDuration = 1;
  ngOnInit(): void {
    this.getSTB();
    this.getPathParam();
    this.initForm();
    if (!this.auth.isLCO()) {
      this.getlcoList();
    }
    this.initLocationForm();
  }

  lcoList;
  getlcoList() {
    const url = this.config.json.apiEndpointRdbms + 'lco/getLCOs';
    this.http.get(url).subscribe({
      next: (res) => {
        // console.log(res)
        this.lcoList = res.data as any[];
      },
      complete: () => {
        let select = document.getElementById('selectLCO') as HTMLSelectElement;
        // console.log(this.subscriber)
        select.value = this.subscriber.lcoId as unknown as string;
      },
    });
  }

  isOtherLocation = false;
  locationSelected(e: Event) {
    let select = (e.target as HTMLSelectElement).value;

    if (select == 'other') {
      this.isOtherLocation = true;
    } else {
      this.isOtherLocation = false;
    }
  }

  countryList: ICountry[] = Country.getAllCountries();
  stateList: IState[];
  cityList: ICity[];

  selectedCountry: ICountry;
  selectedState: IState;

  pincodeRegex = '^[1-9][0-9]{5}$';
  locationForm: FormGroup;

  initLocationForm(): void {
    this.locationForm = new FormGroup({
      countryCode: new FormControl('', [Validators.required]),
      stateCode: new FormControl('', [Validators.required]),
      districtCode: new FormControl('', [Validators.required]),
      locality: new FormControl('', [Validators.required]),
      pincode: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.pincodeRegex),
      ]),
    });

    this.initializeFormChangeEvents();
  }

  initializeFormChangeEvents(): void {
    this.country.valueChanges.subscribe((ct) => {
      this.listenCountryChange(ct);
    });
    this.state.valueChanges.subscribe((st) => {
      this.listenStateChange(st);
    });

    // Setting value here, to trigger above observable after it's initialization
    this.country.setValue('India');
  }

  get country(): FormControl {
    return this.locationForm.get('countryCode') as FormControl;
  }
  get state(): FormControl {
    return this.locationForm.get('stateCode') as FormControl;
  }
  get city(): FormControl {
    return this.locationForm.get('districtCode') as FormControl;
  }
  get pin(): FormControl {
    return this.locationForm.get('pincode') as FormControl;
  }

  listenCountryChange(ctname: string): void {
    if (ctname) {
      const cntry = this.countryList.filter((ct) => ct.name === ctname);
      if (cntry.length > 0) {
        this.selectedCountry = cntry[0];
        this.stateList = State.getStatesOfCountry(this.selectedCountry.isoCode);
      }
    }
  }

  listenStateChange(stname: string): void {
    if (stname) {
      const statList = this.stateList.filter((st) => st.name === stname);
      if (statList.length > 0) {
        this.selectedState = statList[0];
        const list = City.getCitiesOfState(
          this.selectedCountry.isoCode,
          this.selectedState.isoCode
        );
        this.cityList = (list as any) !== '' ? list : [];
      }
    }
  }

  selectedLcoId = +this.auth.getLcoId();
  isStbDropDown = true;
  getLcoId() {
    this.isStbDropDown = false;
    let lcoId = (document.getElementById('selectLCO') as HTMLSelectElement)
      .value;
    console.log(document.getElementById('selectLCO') as HTMLSelectElement);

    this.selectedLcoId = +lcoId;
    const url =
      this.config.json.apiEndpointRdbms + 'stb/getAvailableSTBsByLCO/' + lcoId;
    this.http.get(url).subscribe((res1) => {
      this.stbDropdownList = res1;
      this.stbDropdownList = this.stbDropdownList.map((ad) => {
        return {
          id: ad.id,
          name: ad.ethernetMac,
        };
      });
      this.isStbDropDown = true;
    });
    console.log(lcoId);
  }

  getSTB() {
    const url =
      this.config.json.apiEndpointRdbms +
      'stb/getAvailableSTBsByLCO/' +
      this.selectedLcoId;
    this.http.get(url).subscribe((res1) => {
      this.stbDropdownList = res1;
      this.stbDropdownList = this.stbDropdownList.map((ad) => {
        return {
          id: ad.id,
          name: ad.ethernetMac,
        };
      });
    });
  }

  subscriberFormValidity() {
    return !this.subscriberForm.valid;
  }

  dontPersist() {
    console.log(this.subscriberForm);
  }

  /**
   * Check for path param and decide whether create or edit flow
   */
  subscriberId;
  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.subscriberId = id;
    if (id && id !== PathNames.create) {
      this.updateFlow(id);
    } else {
      this.createFlow();
    }
  }

  createFlow(): void {
    this.fetchCreateData();
  }

  updateFlow(id: string): void {
    this.isEdit = true;
    this.fetchUpdateData(id);
  }

  fetchRecordObs(id: string): Observable<SubscriberInfo> {
    const url =
      this.config.json.apiEndpointRdbms + 'subscriber/getSubscriber/' + id;
    return this.http.get(url);
  }

  fetchUnassignedStbList(): Observable<StbInfo[]> {
    // const url = environment.apiEndpointRdbms + 'stb/getSTBsByLCO/' + this.auth.getLcoId();

    const url =
      this.config.json.apiEndpointRdbms +
      'stb/getAvailableSTBsByLCO/' +
      this.auth.getLcoId();
    return this.http.get(url);
  }
  lcoId = '0';
  fetchPackageList(): Observable<PackageInfo[]> {
    // const url = this.config.json.apiEndpointRdbms + 'bouquet/getBouquetsByLCO/' + this.auth.getLcoId();
    //const url = this.config.json.apiEndpointRdbms + 'bouquet/getBouquetDropdownList'
    if (this.auth.isLCO()) {
      this.lcoId = this.auth.getLcoId();
    }
    const url =
      this.config.json.apiEndpointRdbms +
      'bouquet/' +
      this.lcoId +
      '/getBouquetDropdownList';

    return this.http.get(url);
  }

  fetchChannelList(): Observable<ChannelInfo[]> {
    // const url = this.config.json.apiEndpointRdbms + 'channel/getChannelsByLCO/' + this.auth.getLcoId();
    const url =
      this.config.json.apiEndpointRdbms + 'channel/getDropdownChannelList';
    return this.http.get(url);
  }

  fetchGroupList(): Observable<SubscriberGroupInfo[]> {
    const url =
      this.config.json.apiEndpointRdbms +
      'subscribergroup/getSubscriberGroups/';
    return this.http.get(url);
  }

  fetchLocationListObs(): Observable<LocationInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'location/getLocations';
    return this.http.get(url);
  }

  /**
   * Get relevant data for create flow
   */
  fetchUpdateData(id: string): void {
    forkJoin([
      this.fetchRecordObs(id),
      this.fetchUnassignedStbList(),
      this.fetchPackageList(),
      this.fetchLocationListObs(),
      this.fetchChannelList(),
      this.fetchGroupList(),
    ]).subscribe((data) => {
      console.log(data[0]);
      this.subscriber = data[0];
      console.log(this.subscriber);
      // this.stbList = data[1];
      this.packageList = data[2]['data'];
      this.locationList = data[3];
      this.channelList = data[4]['data'];
      this.groupList = data[5];
      this.formatChannelList();
      this.formatPackageList();
      this.formatGroupList();
      this.updateForm();
    });
  }

  /**
   * Get relevant data for update flow
   */
  fetchCreateData(): void {
    forkJoin([
      this.fetchUnassignedStbList(),
      this.fetchPackageList(),
      this.fetchLocationListObs(),
      this.fetchChannelList(),
      this.fetchGroupList(),
    ]).subscribe((data) => {
      this.stbList = data[0];
      this.packageList = data[1]['data'];
      this.locationList = data[2];
      this.channelList = data[3]['data'];
      this.groupList = data[4];
      this.formatChannelList();
      this.formatPackageList();
      // this.setDefaultPackage();
      this.formatGroupList();
    });
  }

  formatPackageList(): void {
    this.dropdownPackageList = this.packageList.map((bouquet) => {
      // // console.log(bouquet )
      return {
        id: bouquet['bouquetId'],
        name: bouquet['bouquetName'],
      };
    });
    const ids = this.subscriber.subscription
      ? Object.keys(this.subscriber.subscription)
      : [];
    // //// console.log(ids)
    const temp = [];
    // // console.log(this.packageList)
    this.packageList.forEach((bouquet) => {
      ids.forEach((id) => {
        if (bouquet['bouquetId'] === Number(id)) {
          temp.push({
            id: bouquet['bouquetId'],
            name: bouquet['bouquetName'],
          });
          // //// console.log(id);
          // //// console.log(bouquet.id);
          // //// console.log(Number(id));
        }
      });
    });

    this.selectedPackageList = temp;
    // //// console.log(this.selectedPackageList);
    // //// console.log(ids);
  }

  onItemSelect() {
    // // console.log("hello")
    // // console.log(this.selectedPackageList)
    out: for (let item of this.selectedPackageList) {
      for (let item1 of this.packageList) {
        if (item.id == item1['bouquetId']) {
          if (this.highestDuration < item1['packDuration']) {
            this.highestDuration = item1['packDuration'];
            break out;
          }
        }
      }
    }
    let currentDate = new Date();
    let validFrom = currentDate.toISOString().split('T')[0];
    this.subFrom.setValue(validFrom);
    let addDate = this.addMonths(currentDate, this.highestDuration);
    let validTo = addDate.toISOString().split('T')[0];
    this.subTo.setValue(validTo);
  }

  onItemDeSelect() {
    // // console.log(this.selectedPackageList)
    this.highestDuration = 0;
    out: for (let item of this.selectedPackageList) {
      for (let item1 of this.packageList) {
        if (item.id == item1['bouquetId']) {
          if (this.highestDuration < item1['packDuration']) {
            this.highestDuration = item1['packDuration'];
            break out;
          }
        }
      }
    }
    let currentDate = new Date();
    let validFrom = currentDate.toISOString().split('T')[0];
    this.subFrom.setValue(validFrom);
    let addDate = this.addMonths(currentDate, this.highestDuration);
    let validTo = addDate.toISOString().split('T')[0];
    this.subTo.setValue(validTo);
  }

  addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }

  formatGroupList(): void {
    if (this.isEdit == true) {
      const url =
        this.config.json.apiEndpointRdbms +
        'stb/getSTBBySubscriberId/' +
        this.subscriberId;
      this.http.get(url).subscribe((res) => {
        let data = res;
        console.log(data)
        data = data.map((ad) => {
          return {
            id: ad.id,
            name: ad.ethernetMac,
          };
        });
        // this.stbDropdownList=res
        // this.stbDropdownList = this.stbDropdownList.map(ad => {
        //   return {
        //     id: ad.id,
        //     name: ad.ethernetMac
        //   };
        // });
        // // console.log(this.stbDropdownList)
        this.setStdData(data);
        const url =
          this.config.json.apiEndpointRdbms +
          'stb/getAvailableSTBsByLCO/' +
          this.auth.getLcoId();
        this.http.get(url).subscribe((res1) => {
          this.stbDropdownList = res1;
          this.stbDropdownList = this.stbDropdownList.map((ad) => {
            return {
              id: ad.id,
              name: ad.ethernetMac,
            };
          });
          for (let i of data) {
            this.stbDropdownList.push(i);
          }
          // // console.log(this.stbDropdownList)
        });
      });
    } else {
      const url =
        this.config.json.apiEndpointRdbms +
        'stb/getAvailableSTBsByLCO/' +
        this.auth.getLcoId();
      this.http.get(url).subscribe((res) => {
        this.stbDropdownList = res;
        this.stbDropdownList = this.stbDropdownList.map((ad) => {
          return {
            id: ad.id,
            name: ad.ethernetMac,
          };
        });
        // // console.log(this.stbDropdownList)
      });
    }
    // // console.log( this.stbDropdownList)
  }

  formatChannelList(): void {
    // // console.log(this.channelList)
    this.dropdownChannelList = this.channelList.map((channel) => {
      return {
        id: channel.id,
        name: channel.name,
      };
    });
    // // console.log(this.dropdownChannelList)
    const ids = this.subscriber.channels
      ? Object.keys(this.subscriber.channels)
      : [];
    // //// console.log(ids)

    const tempList = [];
    // //// console.log(this.channelList)
    this.channelList.forEach((channel) => {
      let f = false;
      ids.forEach((id) => {
        // //// console.log(channel.id)
        if (channel.id == Number(id)) {
          // f = true;
          tempList.push({
            id: channel.id,
            name: channel.name,
          });
        }
      });
    });
    // //// console.log(tempList)
    this.selectedList = tempList;

    // //// console.log(this.selectedList);
    // //// console.log(ids);
  }

  /**
   * Set the first package as default selected package, to ensure a package is always selected
   */
  setDefaultPackage(): void {
    this.subscriberForm
      .get('packages')
      .setValue(this.packageList[0] ? this.packageList[0].id : '');
  }

  /**
   * Initialize the form-group structure
   */
  initForm(): void {
    if (this.auth.isLCO()) {
      this.subscriberForm = new FormGroup({
        userId: new FormControl(''),
        title: new FormControl(''),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl(''),
        email: new FormControl('', {
          validators: [Validators.required, Validators.email],
          // asyncValidators: [this.emailValidator()]
        }),
        mobile: new FormControl('', {
          validators: [
            Validators.required,
            // Validators.pattern(this.phonePattern)
          ],
          // asyncValidators: [this.phoneValidator()]
        }),
        landline: new FormControl('', {
          validators: [
            // Validators.required,
            // Validators.pattern(this.phonePattern)
          ],
          // asyncValidators: [this.phoneValidator()]
        }),
        gender: new FormControl(0),
        billingAddress: new FormControl('', [Validators.required]),
        installationAddress: new FormControl('', [Validators.required]),
        password: new FormControl(''),
        confirmPassword: new FormControl(''),
        avatar: new FormControl(''),
        stb: new FormControl(),
        packages: new FormControl(0),
        status: new FormControl(1),
        location: new FormControl('', [Validators.required]),
        lco: new FormControl(''),
        subFrom: new FormControl(''),
        subTo: new FormControl(''),
        cafNumber: new FormControl(0),
        groupId: new FormControl(),
        customerPhotoUrl: new FormControl(''),
        identityProofUrl: new FormControl(''),
        cafFormUrl: new FormControl(''),
        addressProofUrl: new FormControl(''),
      });
      return;
    }
    this.subscriberForm = new FormGroup({
      userId: new FormControl(''),
      title: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(''),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        // asyncValidators: [this.emailValidator()]
      }),
      lcoId: new FormControl('', Validators.required),
      mobile: new FormControl('', {
        validators: [
          Validators.required,
          // Validators.pattern(this.phonePattern)
        ],
        // asyncValidators: [this.phoneValidator()]
      }),
      landline: new FormControl('', {
        validators: [
          // Validators.required,
          // Validators.pattern(this.phonePattern)
        ],
        // asyncValidators: [this.phoneValidator()]
      }),
      gender: new FormControl(0),
      billingAddress: new FormControl('', [Validators.required]),
      installationAddress: new FormControl('', [Validators.required]),
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
      avatar: new FormControl(''),
      stb: new FormControl(),
      packages: new FormControl(0),
      status: new FormControl(1),
      location: new FormControl('', [Validators.required]),
      lco: new FormControl(''),
      subFrom: new FormControl(''),
      subTo: new FormControl(''),
      cafNumber: new FormControl(0),
      groupId: new FormControl(),
      customerPhotoUrl: new FormControl(''),
      identityProofUrl: new FormControl(''),
      cafFormUrl: new FormControl(''),
      addressProofUrl: new FormControl(''),
    });

    // this.conditionalValidators();
  }

  // http://localhost:7080/admin/api/

  get email(): FormControl {
    return this.subscriberForm.get('email') as FormControl;
  }

  get mobile(): FormControl {
    return this.subscriberForm.get('mobile') as FormControl;
  }

  get landline(): FormControl {
    return this.subscriberForm.get('landline') as FormControl;
  }

  get password(): FormControl {
    return this.subscriberForm.get('password') as FormControl;
  }
  get title(): FormControl {
    return this.subscriberForm.get('title') as FormControl;
  }

  get cpassword(): FormControl {
    return this.subscriberForm.get('confirmPassword') as FormControl;
  }

  get startDate(): FormControl {
    return this.subscriberForm.get('subFrom') as FormControl;
  }

  get endDate(): FormControl {
    return this.subscriberForm.get('subTo') as FormControl;
  }

  get stbId(): FormControl {
    return this.subscriberForm.get('stb') as FormControl;
  }

  get subFrom(): FormControl {
    return this.subscriberForm.get('subFrom') as FormControl;
  }

  get subTo(): FormControl {
    return this.subscriberForm.get('subTo') as FormControl;
  }

  get location(): FormControl {
    return this.subscriberForm.get('location') as FormControl;
  }

  /**
   * set validators for password fields only in create flow
   */
  conditionalValidators(): void {
    if (!this.isEdit) {
      this.subscriberForm.get('password').setValidators(Validators.required);
      this.subscriberForm
        .get('password')
        .setValidators(Validators.minLength(5));
      this.subscriberForm
        .get('password')
        .setValidators(Validators.pattern(this.passwordRegex));
      this.subscriberForm
        .get('confirmPassword')
        .setValidators(Validators.required);
      this.subscriberForm.setValidators(this.confirmPasswordValidator);
    }
  }

  /**
   * Update initialized form with data from api
   */
  updateForm(): void {
    this.subscriberForm.get('userId').setValue(this.subscriber.userId);
    this.subscriberForm.get('title').setValue(this.subscriber.title);
    this.subscriberForm.get('firstName').setValue(this.subscriber.firstName);
    this.subscriberForm.get('lastName').setValue(this.subscriber.lastName);
    this.subscriberForm.get('email').setValue(this.subscriber.email);
    this.subscriberForm.get('mobile').setValue(this.subscriber.mobile);
    this.subscriberForm.get('landline').setValue(this.subscriber.landline);
    this.subscriberForm
      .get('billingAddress')
      .setValue(this.subscriber.billingAddress);
    this.subscriberForm
      .get('installationAddress')
      .setValue(this.subscriber.installationAddress);
    this.subscriberForm.get('stb').setValue(this.subscriber.stbId);
    this.subscriberForm.get('location').setValue(this.subscriber.locationId);
    this.subscriberForm.get('lcoId').setValue(this.subscriber.lcoId);
    this.subscriberForm.get('gender').setValue(this.subscriber.gender);
    this.subscriberForm.get('status').setValue(this.subscriber.status);
    this.subscriberForm.get('cafNumber').setValue(this.subscriber.cafNumber);
    this.subscriberForm.get('groupId').setValue(this.subscriber.groupId);
    if (this.subscriber.startDate) {
      this.subscriberForm
        .get('subFrom')
        .setValue(this.subscriber.startDate.split('T')[0]);
    }
    if (this.subscriber.endDate)
      this.subscriberForm
        .get('subTo')
        .setValue(this.subscriber.endDate.split('T')[0]);
    // this.subscriberForm.get('subFrom').setValue(this.subscriber.startDate.substring(0, 16));
    // this.subscriberForm.get('subTo').setValue(this.subscriber.endDate.substring(0, 16));
    // this.updateSubscriptionForm();
    // this.subscriberStbId = this.subscriberForm.get("stb").value
    this.updateChannelData();
    this.updatePackageData();
    setTimeout(() => {
      this.getLcoId();
    }, 300);
  }

  isUpdated = {
    customerPhotoUrl: 'Photo',
    identityProofUrl: 'Identity',
    addressProofUrl: 'Address',
    cafFormUrl: 'CAF',
  };
  uploadDocuments(e: Event, type: string) {
    let urlType;
    switch (type) {
      case 'customerPhotoUrl':
        urlType = 'photo';
        break;
      case 'identityProofUrl':
        urlType = 'identity';
        break;
      case 'addressProofUrl':
        urlType = 'address';
        break;
      case 'cafFormUrl':
        urlType = 'caf';
        break;
    }

    let file = (e.target as HTMLInputElement).files[0];
    let formData = new FormData();
    formData.append('file', file);
    let url = `${this.config.json.apiEndpoint}subscriberDocuments/upload/${urlType}`;
    this.http
      .post(url, formData)
      .pipe(
        finalize(() => {
          formData = new FormData();
        })
      )
      .subscribe({
        next: (res: any) => {
          this.isUpdated[type] = res.data.message;
          this.subscriberForm.get(type).setValue(res.data.url);
          this.subscriber[type] = res.data.url;
        },
      });
  }

  // updateSubscriptionForm(): void {
  //   if (this.subscriber.subscription) {
  //     // getting the first object's data
  //     const pkgKey = Object.keys(this.subscriber.subscription)[0];
  //     const pkg = this.subscriber.subscription[pkgKey];
  //     this.subscriberForm.get('packages').setValue(pkgKey);
  //     // this.subscriberForm.get('subFrom').setValue(pkg.startDate.substring(0, 16));
  //     // this.subscriberForm.get('subTo').setValue(pkg.endDate.substring(0, 16));
  //   }
  // }
  copyBillingAddress(e: Event) {
    let isSet = (e.target as HTMLInputElement).checked;
    this.subscriberForm
      .get('installationAddress')
      .setValue(isSet ? this.subscriberForm.get('billingAddress').value : null);
  }

  persist(): void {
    this.updateModel();
    // this.updateSubscriptionData();
    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    const url =
      this.config.json.apiEndpointRdbms + 'subscriber/createSubscriber';
    this.subscriber.startDate = new Date(this.subscriber.startDate);
    this.subscriber.endDate = new Date(this.subscriber.endDate);
    this.subscriber.lcoId = this.selectedLcoId;
    if (this.isOtherLocation) {
      this.subscriber = {
        ...this.subscriber,
        ...this.locationForm.value,
        locationId: null,
      };
    }
    this.http.post(url, this.subscriber).subscribe(
      (data) => {
        if (data.statusCode == 200) {
          this.router.navigate(['/manage/subscriber', 'list']);
        } else {
          alert(data.message);
        }
      },
      (error) => {
        // //// console.log(error)
        window.alert(error.error['error']);
      }
    );
  }

  update(): void {
    // console.log(this.subscriber.stbId)
    const url =
      this.config.json.apiEndpointRdbms + 'subscriber/updateSubscriber';
    this.subscriber.startDate = new Date(this.subscriber.startDate);
    this.subscriber.endDate = new Date(this.subscriber.endDate);
    // //// console.log("STB ID:",this.subscriber.stbId)
    // // console.log(this.subscriber)
    this.subscriber.lcoId = this.selectedLcoId;
    if (this.isOtherLocation) {
      this.subscriber = {
        ...this.subscriber,
        ...this.locationForm.value,
        locationId: null,
      };
    }
    this.http.put(url, this.subscriber).subscribe(
      (data) => {
        // //// console.log("EDIT:",data)
        if(data.statusCode==200){
          this.router.navigate(['/manage/subscriber', 'list']);
          }
          else
          {
          window.alert(data.message)
          }
       
      },
      (error) => {
        // //// console.log(error)
        window.alert(error.error['error']);
      }
    );
  }

  /**
   * Update model with data from form
   */
  updateModel(): void {
    console.log(this.subscriberForm.get('stb').value)
    this.subscriber.userId = this.subscriberForm.get('userId').value;
    this.subscriber.title = this.subscriberForm.get('title').value;
    this.subscriber.firstName = this.subscriberForm.get('firstName').value;
    this.subscriber.lastName = this.subscriberForm.get('lastName').value;
    this.subscriber.email = (
      this.subscriberForm.get('email').value as string
    ).toLowerCase();
    this.subscriber.mobile = this.subscriberForm.get('mobile').value;
    this.subscriber.landline = this.subscriberForm.get('landline').value;
    this.subscriber.billingAddress =
      this.subscriberForm.get('billingAddress').value;
    this.subscriber.installationAddress = this.subscriberForm.get(
      'installationAddress'
    ).value;
    // this.subscriber.stbId = [this.subscriberForm.get('stb').value];
    // this.subscriber.stbId = [this.stbdata]
    this.subscriber.stbId = this.getStdData();
    this.subscriber.locationId = this.subscriberForm.get('location').value;
    this.subscriber.gender = this.subscriberForm.get('gender').value;
    // this.subscriber.bouquetId = this.subscriberForm.get('packages').value;
    this.subscriber.status = this.subscriberForm.get('status').value;
    this.subscriber.cafNumber = this.subscriberForm.get('cafNumber').value;
    this.subscriber.groupId = this.subscriberForm.get('groupId').value;
    this.subscriber.startDate = this.subscriberForm.get('subFrom').value;
    this.subscriber.endDate = this.subscriberForm.get('subTo').value;

    // this.subscriber.lcoId = this.subscriberForm.get('lco').value;

    // Set password only for new accounts
    if (!this.isEdit) {
      this.subscriber.password = this.subscriberForm.get('password').value;
      this.subscriber.lcoId = Number(atob(localStorage.getItem('id')));
    }
    this.updateChannelData();
    this.updatePackageData();
  }

  stbdataCopy =[]
  getStdData() {
    if(this.isEdit== false){
      let array = [];
      for (let a of this.stbdata) {
        array.push(a['id']);
      }
      console.log(array)
      return array;
    }
    else{
      let array = [];
      console.log(this.stbdataCopy)
      console.log(this.stbdata)
    for (let a of this.stbdataCopy) {
      for(let b of this.stbdata){
        if(b==a.name)
      array.push(a['id']);
      }
    }
     console.log(array)
      return array;
    }
    
  }

  setStdData(data) {
    console.log(data)
    this.stbdataCopy = data
    this.stbdata = data;
  }

  // updateSubscriptionData(): void {

  //   const pkSub = {} as SubscriptionPackage;
  //   pkSub.status = 1;
  //   pkSub.type = 1;
  //   pkSub.startDate = this.subscriberForm.get('subFrom').value;
  //   pkSub.endDate = this.subscriberForm.get('subTo').value;

  //   const subsc = {} as any;
  //   subsc[this.subscriberForm.get('packages').value] = pkSub;

  //   this.subscriber.subscription = subsc;

  // }

  updatePackageData(): void {
    // //// console.log("packages");
    const pkSub = {} as SubscriptionPackage;
    pkSub.status = 1;
    pkSub.type = 0;
    pkSub.startDate = this.subscriberForm.get('subFrom').value;
    pkSub.endDate = this.subscriberForm.get('subTo').value;

    const bouquet = {} as any;
    this.selectedPackageList.forEach((p) => {
      bouquet[p.id] = pkSub;
    });
    this.subscriber.subscription = bouquet;
    // this.package.subscription = bouquet;
    // //// console.log(this.package.subscription);
  }

  updateChannelData(): void {
    const pkSub = {} as SubscriptionPackage;
    pkSub.status = 1;
    pkSub.type = 0;
    pkSub.startDate = this.subscriberForm.get('subFrom').value;
    pkSub.endDate = this.subscriberForm.get('subTo').value;

    const subsc = {} as any;
    this.selectedList.forEach((ch) => {
      subsc[ch.id] = pkSub;
    });

    this.subscriber.channels = subsc;
  }

  // **************************************************************************************
  // Custom Async Validators
  // **************************************************************************************

  emailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkEmail(control.value).pipe(
        map((res) => {
          // duplicate is the key name in response coming from server
          return res.duplicate ? { duplicate: true } : null;
        })
      );
    };
  }

  checkEmail(value: string): Observable<any> {
    const url = this.config.json.apiEndpoint + 'subscriber/duplicateEmailCheck';
    const body = {
      email: value,
      id: this.isEdit ? this.subscriber.id : null,
    };

    return this.http.post(url, body);
  }

  phoneValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkPhone(control.value).pipe(
        map((res) => {
          // duplicate is the key name in response coming from server
          return res.duplicate ? { duplicate: true } : null;
        })
      );
    };
  }

  checkPhone(value: string): Observable<any> {
    const url = this.config.json.apiEndpoint + 'subscriber/duplicatePhoneCheck';
    const body = {
      phone: value,
      id: this.isEdit ? this.subscriber.id : null,
    };

    return this.http.post(url, body);
  }

  confirmPasswordValidator(group: AbstractControl): ValidationErrors | null {
    if (group.get('confirmPassword').value !== group.get('password').value) {
      return { mismatch: true };
    }
  }

  renewPackages() {
    const obj = {};
    obj['subscriberCode'] = this.subscriber['subscriberCode'];
    var arr = [];
    for (let ele in this.subscriber['subscription']) {
      for (let elem in this.packageList) {
        if (ele == this.packageList[elem]['bouquetId']) {
          var dateObjSubscriber = new Date(
            this.subscriber['subscription'][ele].endDate
          );
          var year = dateObjSubscriber.getFullYear();
          var month = dateObjSubscriber.getMonth() + 1;
          var date = dateObjSubscriber.getDate();
          var tempMonth = month + this.packageList[elem]['packDuration'];
          var updatedMonth =
            month + this.packageList[elem]['packDuration'] < 10
              ? '0' + tempMonth
              : tempMonth;
          var updatedDate = year + '-' + updatedMonth + '-' + date;
        }
      }
      let obj = {};
      obj['bouquetId'] = ele;
      obj['endDate'] = updatedDate;
      arr.push(obj);
    }
    obj['bouquets'] = arr;
    // // console.log(obj)
    this.http
      .post(
        this.config.json.apiEndpointRdbms + 'subscriber/renewSubscription',
        obj
      )
      .subscribe({
        next: (res) => {},
        error: () => {},
      });
  }
}
