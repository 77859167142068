import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PathNames } from 'src/app/constants/path-names';
import { environment } from 'src/environments/environment';
import { Validators, FormControl, FormGroup, AsyncValidatorFn, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { TranscoderProfileInfo } from 'src/app/models/transcoder/transcoder-profile-info';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { CategoryInfo } from 'src/app/models/category/category-info';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PackageInfo } from 'src/app/models/package/package-info';
import { ConfigService } from 'src/app/services/config.service';
import { atLeastOneFieldValidator } from './oneFieldValidator';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-create-channel',
  templateUrl: './create-channel.component.html',
  styleUrls: ['./create-channel.component.scss']
})
export class CreateChannelComponent implements OnInit {

  dropdownCategoryList = [];
  selectedCategoryList = [];
  dropdownPackageList = [];
  selectedPackageList = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    textField: 'name',
  };

  channel = {} as ChannelInfo;

  channelForm: FormGroup;

  isEdit = false;

  isTrancoderActive = false;

  imageObj: File;

  selectedProfile = {} as TranscoderProfileInfo;
  profileList: TranscoderProfileInfo[] = [];
  broadcasterList: BroadcasterInfo[] = [];
  categoryList: CategoryInfo[] = [];
  packageList: PackageInfo[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private imageService: ImageUploadService,
    private config: ConfigService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.getPathParam();
    this.initForm();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.fetchEditData(id);
    } else {
      this.fetchSaveData();
    }
  }

  fetchEditData(id: string): void {
    forkJoin([this.fetchRecordObs(id), this.fetchProfileListObs(), this.fetchBroadcasterListObs(),
    this.fetchCategoryListObs(), this.fetchPackageListObs()]).subscribe(data => {
      this.handleFetchRecord(data[0]);
      this.handleFetchProfileList(data[1]);
      this.handleFetchBroadcasterList(data[2]);
      this.handleFetchCategoryList(data[3]);
      this.handleFetchPackageList(data[4]);
    });
  }

  channelList
  getName(e: Event) {
    let value = (e.target as HTMLInputElement).value
    let div = document.getElementById('searchResult')
    //const url = `${(this.config.json.apiEndpoint as string).replace("admin/api","epg/v1")}channels/search/masterTable/` + value
    
    
    const url = `${(this.config.json.epgEndPoint as string)}channels/search/masterTable/` + value
    this.httpClient.get(url).subscribe({
      next: (res: any) => {
        // console.log(res)
        this.channelList = res.data
        div.style.display = 'block'
        div.className = 'search_result animation_on'
        if (res.data.length == 0) this.channelList = [{ channel_name: 'No Data Found' }]
      }
    })
  }

  isValueSelected = false
  isChannelSelected = false
  channelClicked(item: any) {
    if (item.channel_name !== 'No Data Found') {
      this.isChannelSelected = true
      this.channelForm.get('name').setValue(item.channel_name)
      this.channelForm.get('genre').setValue(item.channel_category)
      this.channelForm.get('language').setValue(item.channel_language)
      let div = document.getElementById('searchResult')
      div.className = 'search_result animation_off'
      setTimeout(() => {
        div.style.display = "none"
        this.isValueSelected = true
      }, 300);
    }
  }

  channelCleared() {
    this.channelForm.get('name').setValue('')
    this.channelForm.get('genre').setValue('')
    this.channelForm.get('language').setValue('')
    this.isValueSelected = false
  }

  fetchSaveData(): void {
    forkJoin([this.fetchProfileListObs(), this.fetchBroadcasterListObs(), this.fetchCategoryListObs(),
    this.fetchPackageListObs()]).subscribe(data => {
      this.handleFetchProfileList(data[0]);
      this.handleFetchBroadcasterList(data[1]);
      this.handleFetchCategoryList(data[2]);
      this.handleFetchPackageList(data[3]);
    });
  }

  fetchRecordObs(id: string): Observable<ChannelInfo> {
    const url = this.config.json.apiEndpointRdbms + 'channel/getChannel/' + id;
    return this.http.get(url);
  }

  fetchBroadcasterListObs(): Observable<BroadcasterInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'broadcaster/getBroadcasters';
    return this.http.get(url);
  }

  fetchCategoryListObs(): Observable<CategoryInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'category/getCategories';
    return this.http.get(url);
  }

  fetchPackageListObs(): Observable<PackageInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'bouquet/getBouquets';
    return this.http.get(url);
  }

  // fetchProfileListObs(): Observable<TranscoderProfileInfo[]> {
  //   const url = environment.apiEndpoint + 'transcoder/profile/getProfileList';
  //   return this.http.get(url);
  // }

  fetchProfileListObs(): Observable<TranscoderProfileInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'bouquet/getBouquets';
    return this.http.get(url);
  }

  handleFetchRecord(data: ChannelInfo): void {
    console.log("update Data",data);

    this.channel = data;
    this.updateForm();
    if (this.channel.transcoderProfileId) {
      this.isTrancoderActive = true;
      // Check if channel's profile id is present in profile list
      const profileFilter = this.profileList.filter(pro => pro.id === this.channel.transcoderProfileId);
      if (profileFilter && profileFilter.length > 0) {
        this.selectedProfile = profileFilter[0];
      }
    }

    if (this.channel.broadcasterId) {
      const bc = this.broadcasterList.filter(b => b.id === this.channel.broadcasterId);
      if (bc && bc.length > 0) {
        this.broadcaster.setValue(bc[0].id);
      }
    }
  }

  handleFetchProfileList(data: TranscoderProfileInfo[]): void {
    this.profileList = data;
  }

  handleFetchBroadcasterList(data: BroadcasterInfo[]): void {
    this.broadcasterList = data;
  }

  handleFetchCategoryList(data: CategoryInfo[]): void {
    this.categoryList = data;
    this.formatCategoryList();
  }

  handleFetchPackageList(data: PackageInfo[]): void {
    this.packageList = data['data'];
    this.formatPackageList();
  }

  formatCategoryList(): void {
    this.dropdownCategoryList = this.categoryList.map(category => {
      return {
        id: category.id,
        name: category.name
      };
    });

    this.selectedCategoryList = this.channel.categories ? this.channel.categories.map(category => {
      return {
        id: category.id,
        name: category.name
      };
    }) : [];
  }

  formatPackageList(): void {
    this.dropdownPackageList = this.packageList.map(pk => {
      return {
        id: pk.id,
        name: pk.name
      };
    });

    this.selectedPackageList = this.channel.bouquet ? this.channel.bouquet.map(pk => {
      return {
        id: pk.id,
        name: pk.name
      };
    }) : [];
  }

  channelFormValidity() {
    // // console.log(this.channelForm)
    return this.channelForm.invalid && !this.isChannelSelected
  }

  initForm(): void {
    this.channelForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      number: new FormControl(0, {
        validators: [Validators.required, Validators.min(1)],
        // asyncValidators: [this.channelNumberValidator()]
      }),
      // channelId: new FormControl('', {
      //   validators: [Validators.required],
      //   // asyncValidators: [this.channelIdValidator()]
      // }),
      description: new FormControl('', [Validators.required]),
      hlsUrl: new FormControl('', [Validators.pattern('^(http:\/\/|udp:\/\/).*$')]),
      dashUrl: new FormControl('', [Validators.pattern('^(http:\/\/|udp:\/\/).*$')]),
      multiCastUrl: new FormControl('', [Validators.pattern('^(http:\/\/|udp:\/\/).*$')]),
      status: new FormControl(1, [Validators.required]),
      type: new FormControl(0, [Validators.required]),
      price: new FormControl({ value: 0, disabled: true }, [Validators.required, Validators.min(0)]),
      quality: new FormControl(0, [Validators.required]),
      // logo: new FormControl(),
      language: new FormControl('', [Validators.required]),
      genre: new FormControl('', [Validators.required]),
      protocol: new FormControl('', [Validators.required]),
      broadcasterId: new FormControl(0, [Validators.required])
    },
      {
        validators: atLeastOneFieldValidator('hlsUrl', 'dashUrl', 'multiCastUrl')
      });

    this.listenFormChanges();
  }

  clickOutside() {
    let div = document.getElementById('searchResult')
    div.style.display = div.style.display != 'block' ? 'none' : 'none'
  }

  listenFormChanges(): void {
    this.type.valueChanges.subscribe(value => {
      // If 0, then it's free channel and hence disabling price input and setting it's value to 0
      if (value === 0) {
        this.price.disable();
        this.price.setValue(0);
      } else {
        // If not 0, then it's paid, and hence enabling the input field
        this.price.enable();
      }
    });
  }

  toggleTranscoder(): void {
    this.isTrancoderActive = !this.isTrancoderActive;
  }
  onProfileChange(event): void {
    //// console.log(event);
    this.channel.transcoderProfileId = event;
    // Get the profile obj from profile list using it's id
    this.selectedProfile = this.profileList.filter(pro => pro.id === event)[0];
  }


  get channelNumber(): FormControl {
    return this.channelForm.get('number') as FormControl;
  }

  get channelId(): FormControl {
    return this.channelForm.get('channelId') as FormControl;
  }

  get type(): FormControl {
    return this.channelForm.get('type') as FormControl;
  }

  get price(): FormControl {
    return this.channelForm.get('price') as FormControl;
  }
  get broadcaster(): FormControl {
    return this.channelForm.get('broadcasterId') as FormControl;
  }

  get hlsUrl(): FormControl {
    return this.channelForm.get('hlsUrl') as FormControl;
  }
  get dashUrl(): FormControl {
    return this.channelForm.get('dashUrl') as FormControl;
  }
  get multiCastUrl(): FormControl {
    return this.channelForm.get('multiCastUrl') as FormControl;
  }

  onLogoPicked(event: Event): void {
    const FILE = (event.target as HTMLInputElement).files[0];
    this.imageObj = FILE;
  }


  updateForm(): void {
    console.log(this.channelForm);

    this.channelForm.get('name').setValue(this.channel.name);
    this.channelForm.get('number').setValue(this.channel.number);
    // this.channelForm.get('channelId').setValue(this.channel.channelId);
    this.channelForm.get('description').setValue(this.channel.description);
    this.channelForm.get('hlsUrl').setValue((this.channel as any).hlsUrl);
    this.channelForm.get('dashUrl').setValue((this.channel as any).dashUrl);
    this.channelForm.get('multiCastUrl').setValue((this.channel as any).multiCastUrl);
    this.channelForm.get('status').setValue(this.channel.status);
    this.channelForm.get('type').setValue(this.channel.type);
    this.channelForm.get('quality').setValue(this.channel.quality);
    this.channelForm.get('price').setValue(this.channel.price);
    this.channelForm.get('language').setValue(this.channel.language);
    this.channelForm.get('genre').setValue(this.channel.genre);
    this.channelForm.get('protocol').setValue(this.channel.protocol);
    this.channelForm.get('broadcasterId').setValue(this.channel.broadcasterId);
    // this.channelForm.get('logo').setValue(this.channel.logo);
  }


  persist(): void {
    this.updateModel();
    this.saveEdit();
    if (this.imageObj) {
      this.uploadLogo();
    } else {
    }
  }

  saveEdit(): void {
    this.isEdit ? this.update() : this.save();
  }

  createBody() {
    let body = JSON.parse(JSON.stringify(this.channel))
    let formValue = JSON.parse(JSON.stringify(this.channelForm.value))
    body['hlsUrl'] = formValue.hlsUrl == "" ? null : formValue.hlsUrl
    body['dashUrl'] = formValue.dashUrl == "" ? null : formValue.dashUrl
    body['multiCastUrl'] = formValue.multiCastUrl == "" ? null : formValue.multiCastUrl
    body['channelId'] = formValue.number
    return body
  }

  save(): void {
    this.saveClicked=true
    const url = this.config.json.apiEndpointRdbms + 'channel/createChannel';
    // console.log(this.createBody())

    this.http.post(url, this.createBody()).subscribe(data => {
      if (data.statusCode == 200) {
        this.getBouquet(data)
      }
      else {
        this.saveClicked=false
        alert(data.message)
        

      }
    }, (error) => {
      this.saveClicked=false
      window.alert(error.error["message"])
    });
  }

  getBouquet(responseFromcreateChannel){
    let checked = (document.getElementById('alacartePack') as HTMLInputElement).checked
    if(!checked){
      this.router.navigate(['/content/channel', 'list']);
      return;
    }
    console.log(responseFromcreateChannel);
    let data = responseFromcreateChannel['data']
    data.price = data.price+(data.price*0.18)
    let bodyForcreateBouquet = {
      name : data.name,
      status : data.status,
      bouquetType : 0,
      packType : "ALACARETE_PACK",
      channels : [data],
      description : data.description,
      duration : 1,
      price : data.price
    }
    console.log(data)
    const url = this.config.json.apiEndpointRdbms + 'bouquet/createBouquet';
    this.http.post(url, bodyForcreateBouquet).subscribe({
      next : () => {},
      complete : () =>{
        this.router.navigate(['/content/channel', 'list']);
      }
    })

  }

  saveClicked = false
  update(): void {
    this.saveClicked=true
    const url = this.config.json.apiEndpointRdbms + 'channel/updateChannel';
    this.http.put(url, this.createBody()).subscribe(data => {
      if (data.statusCode == 200) {
      this.getBouquet(data)
      }
      else{
        this.saveClicked=false
        alert(data.message)
      }
    },
      (error) => {
        this.saveClicked=false
        window.alert(error.error["error"])
      }
    );
  }

  updateModel(): void {
    this.channel.name = this.channelForm.get('name').value;
    this.channel.number = this.channelForm.get('number').value;
    this.channel.description = this.channelForm.get('description').value;
    this.channel.url = this.channelForm.get('hlsUrl').value;
    this.channel.url = this.channelForm.get('dashUrl').value;
    this.channel.url = this.channelForm.get('multiCastUrl').value;
    this.channel.status = this.channelForm.get('status').value;
    this.channel.type = this.channelForm.get('type').value;
    this.channel.quality = this.channelForm.get('quality').value;
    this.channel.price = this.channelForm.get('price').value;
    this.channel.language = this.channelForm.get('language').value;
    this.channel.genre = this.channelForm.get('genre').value;
    this.channel.protocol = this.channelForm.get('protocol').value;
    this.channel.broadcasterId = this.channelForm.get('broadcasterId').value;

    // this.channel.logo = this.channelForm.get('logo').value;
    if (!this.isTrancoderActive) {
      this.channel.transcoderProfileId = null;
    }

    this.channel.categories = this.categoryList.filter(category => {
      return this.selectedCategoryList.some(select => {
        return category.id === select.id;
      });
    });

    this.channel.bouquet = this.packageList.filter(pk => {
      return this.selectedPackageList.some(select => {
        return pk.id === select.id;
      });
    });

    // //// console.log(this.channel);
  }

  uploadLogo(): void {
    const imageForm = new FormData();
    imageForm.append('image', this.imageObj);
    this.imageService.imageUpload(imageForm).subscribe(
      (res) => {
        if (res.image) {
          this.channel.logo = res.image;
          // this.channelForm.get('logo').setValue(this.channel.logo);
        }
        // this.channel.logo = environment.imagePreviewUrl + res.image;
        this.saveEdit();
      },
      (err) => {
        alert('Image upload failed');
        this.saveEdit();
      });
  }




  // **************************************************************************************
  // Custom Async Validators
  // **************************************************************************************

  channelNumberValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkNumber(control.value).pipe(
        map(res => {
          // duplicate is the key name in response coming from server
          return res.duplicate ? { duplicate: true } : null;
        })
      );
    };
  }

  checkNumber(value: number): Observable<any> {
    const url = this.config.json.apiEndpoint + 'channel/duplicateChannelNumberCheck';
    const body = {
      number: value,
      id: this.isEdit ? this.channel.id : null
    };

    return this.http.post(url, body);
  }


  channelIdValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkChannelId(control.value).pipe(
        map(res => {
          // duplicate is the key name in response coming from server
          return res.duplicate ? { duplicate: true } : null;
        })
      );
    };
  }

  checkChannelId(value: string): Observable<any> {
    const url = this.config.json.apiEndpoint + 'channel/duplicateChannelIdCheck';
    const body = {
      channelId: value,
      id: this.isEdit ? this.channel.id : null
    };

    return this.http.post(url, body);
  }

}
