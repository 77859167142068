<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Subscriber Details</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="col-12 col-md-6">
      <!-- <label for="month">Select Month:</label>
      <input class="custom__field" type="month" id="month" [(ngModel)]="selectedDate" (ngModelChange)="fetchData()"> -->
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
        <img src="./assets/csv.svg" alt="download" />			</button>
    </div>
  </div>

  <!-- <div class="col-12 col-md-6 report_selection_block">
    <label class="report_selection_label">Select Broadcaster</label>
    <select class="custom__field report_selection" [(ngModel)]="broadcasterId" (ngModelChange)='fetchData()'>
      <option *ngFor="let lco of broadcasterList" [ngValue]='lco.id'>
        {{lco.name}}
      </option>
    </select>
  </div> -->

  <div class="third_box">
    <div class="col-12 col-md-6">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div class="search__container">
      <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
      <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <th>Sl no</th>
            <th>Subscriber Code</th>
            <!-- <th>CAF Number</th> -->
            <th>MAC ID</th>
            <th>Subscriber Name</th>
            <th>State</th>
            <th>District</th>
            <th>Subscriber Status</th>
            <th>Subscription Type</th>
            <th>Price Package</th>
            <th>Subscription Status</th>
            <!-- <th>Report Generation Time</th> -->
            <!-- <th>Total Subscriptions</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length ==0" >
            <td style="text-align: center !important;" colspan="11">
              <app-custom-spinner></app-custom-spinner>
              </td>
          </tr>
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{j+1}}</td>
              <td>{{report.subscriber_code}}</td>
              <!-- <td>{{report.caf_no}}</td> -->
              <td>{{report.ethernet_mac_address}}</td>
              <td>{{report.subscriber_name}}</td>
              <td>{{report.state}}</td>
              <td>{{report.city}}</td>
              <td>{{statUS[report.subscriber_status]}}</td>
              <td>{{report.subscription_type}}</td>
              <td>{{report.price_package_name}}</td>
              <td>{{statUS[report.subscription_status]}}<td>
              <!-- <td>{{report.channelName}}</td>
              <td>{{report.channelNo}}</td>
              <td>{{report.channelId}}</td>
              <td>{{report.subscriptionType}}</td>
              <td>{{report.subscriberCountAt7}}</td>
              <td>{{report.subscriberCountAt14}}</td>
              <td>{{report.subscriberCountAt21}}</td>
              <td>{{report.subscriberCountAt28}}</td>
              <td>{{(report.subscriberCountAt7+report.subscriberCountAt14+
                report.subscriberCountAt21+report.subscriberCountAt28)/4}}</td> -->
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
