import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MailRoutingModule } from './mail-routing.module';
import { CreateMailComponent } from './create-mail/create-mail.component';
import { ListMailComponent } from './list-mail/list-mail.component';
import { SharedModule } from '../shared/shared.module';
import { SubscriptionModule } from '../subscription/subscription.module';


@NgModule({
  declarations: [
    ListMailComponent,
    CreateMailComponent],
  imports: [
    CommonModule,
    MailRoutingModule,
    SharedModule,
    SubscriptionModule
  ],
  exports: [
    ListMailComponent,
    CreateMailComponent
  ]
})
export class MailModule { }
