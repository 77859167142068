<div class="cms__content">
    <app-reports-header></app-reports-header>
    <div class="top__heading">
      <h3>Active Subscriber Count Report</h3>
      <a [routerLink]="['/reports/custom-reports']"
        ><button class="default__button float-right">Back</button></a
      >
    </div>
    <div class="clearfix"></div>
      <div class="row">
        <div class="filter__group col-12 col-md-6">
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
          </div>
          <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
              PDF
              <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
              Excel
              <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
              CSV
              <img src="./assets/csv.svg" alt="download" /> </button>
          </div>
      </div>
      <div class="row row-top">
          <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
              <label class="report_selection_label"> Distributor: </label>
                  <select class="custom__field report_selection" [(ngModel)]="selectedDistributorId" (change)='fetchData()'>
                    <option value="0">ALL</option>
                    <option *ngFor="let distributor of distributorList" [ngValue]="distributor.id">
                        {{ distributor.name }}
                    </option>
                  </select>
            </div>
      
          <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
            <label class="report_selection_label"> Lco: </label>
                <select class="custom__field report_selection" [(ngModel)]="selectedLcoId" (change)='fetchData()'>
                  <option value="0">ALL</option>
                  <option *ngFor="let lco of lcoList" [ngValue]="lco.id">
                      {{ lco.name }}
                  </option>
                </select>
          </div>
         
    
        </div>
  
    <div class="third_box">
      <div class="col-12 col-md-6 report">
        <p>
          Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
        </p>
      </div>
      <div style="display: flex; gap:20px" >
        <div class="table__right" style="padding-bottom: 2%;">
          <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
        </div>
        <!-- <div class="search__container">
          <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
          <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
        </div> -->
      </div>
    </div>
  
    <div class="col-12 list__table">
      <div class="row scroll">
        <table class="custom__iptv__table" #reportTable id="reportTable">
          <thead>
            <tr>
              <th>Sl no</th>
              <th>LCO ID</th>
              <th>LCO Code</th>
              <th>Distributor ID</th>
              <th>Distributor Code</th>
             <th>Active Subscriber</th>
    
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngIf="reportList.length ==0" >
              <td style="text-align: center !important;" colspan="11">
                <app-custom-spinner></app-custom-spinner>
                </td>
            </tr> -->
            <tr *ngIf="reportList.length == 0 && loadingFlag">
              <td style="text-align: center !important" colspan="6">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <tr *ngIf="!loadingFlag && reportList.length == 0">
              <td style="text-align: center !important" colspan="6">
                {{ isLoad }}
              </td>
            </tr>
            <tr *ngFor="let report of reportList; let j = index">
              <td>{{ j + 1 }}</td>
              <td>{{report.lcoId?report.lcoId:'---'}}</td>
              <td>{{ report.lcoCode ? report.lcoCode : "---" }}</td>
              <td >{{report.distributorId? report.distributorId: "---"}}</td>
              <td>{{ report.distributorCode ? report.distributorCode : "---" }}</td>
              <td>{{report.activeSubscriber?report.activeSubscriber:"---"}}</td>
            </tr>
            <tr *ngIf="!loadingFlag && reportList.length === []">
              <td style="text-align: center !important" colspan="12">
                No Records.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  