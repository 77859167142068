import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { NavbarComponent } from './navbar/navbar.component';
import { NavigationRoutingModule } from './navigation-routing.module';
import { TopNavComponent } from './top-nav/top-nav.component';
import { TopBarComponent } from './top-bar/top-bar.component';

@NgModule({
  declarations: [NavbarComponent, TopNavComponent, TopBarComponent],
  imports: [CommonModule, NavigationRoutingModule, SharedModule],
  exports: [NavbarComponent, TopNavComponent],
})
export class NavigationModule {}
