<div class="cms__content">
  <div class="top__heading">
    <h3>LCO</h3>
    <a [routerLink]="['/manage/lco/create']"><button class="default__button float-right" *ngIf="menuAccess">+ Add New</button></a>
  </div>
  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Accounts</span>
          <span class="list__item">{{ TotalLcoCount }}</span>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Activated Accounts</span>
          <span class="list__item">{{ LcoActiveCount }}</span>
        </div>
      </div>
       
        <div class="download__report col-md-6">
          <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
            PDF
            <img src="./assets/pdf.svg" alt="download" />
          </button>
          <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
            Excel
            <img src="./assets/excel.svg" alt="download" />
          </button>
          <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
            CSV
            <img src="./assets/csv.svg" alt="download" />
          </button>
      </div>
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">
              {{ size }}
            </option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">
            Total <span>{{ paginationObj.totalElements }}</span> records
          </p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
              (keyup.enter)="$event.preventDefault(); onSearch()" />
            <button (click)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>
      <div class="tableBox">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>LCO ID</th>
            <th>LCO Code</th>
            <th (click)="sortByItem(sortByProperties.name)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.name
              }">
              Name
            </th>
            <th (click)="sortByItem(sortByProperties.cp)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.cp
              }">
              Contact Person
            </th>
            <th (click)="sortByItem(sortByProperties.distributorCode)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.distributorCode
              }">
              Distributor Code
            </th>
            <!-- <th (click)="sortByItem(sortByProperties.tp)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.tp}">
              Transfer Price (%)
            </th> -->
            <th (click)="sortByItem(sortByProperties.mobile)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.mobile
              }">
              Mobile
            </th>
            <th (click)="sortByItem(sortByProperties.email)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.email
              }">
              Email
            </th>
            <th class="label__align" (click)="sortByItem(sortByProperties.status)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.status
              }">
              Status
            </th>
            <th (click)="sortByItem(sortByProperties.date)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.date
              }">
              Created On
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loadingFlag; else showList">
            <td class="loading" colspan="9">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let admin of lcoList; let j = index">
              <td>{{ (pageNumber - 1) * pageSize + (j + 1) }}</td>
              <td>{{ admin.id }}</td>
              <td>{{ admin.lcoCode ? admin.lcoCode : "---" }}</td>
              <td>{{ admin.name ? admin.name : "---" }}</td>
              <td>{{ admin.contactPerson ? admin.contactPerson : "---" }}</td>
              <td>{{admin.distributorCode? admin.distributorCode : '---'}}</td>
              <td>{{ admin.mobile ? admin.mobile : "---" }}</td>
              <td>{{ admin.email ? admin.email : "---" }}</td>
              <td class="label__align">
                <span [ngClass]="{
                    safe__label: admin.status,
                    danger__label: !admin.status
                  }">
                  {{ admin.status === 0 ? "X" : "&#10003;" }}
                </span>
              </td>
              <td>
                {{ admin.createdDate | date : "dd-MM-yyyy HH:mm:ss" }}
                <div class="action__items" *ngIf="pdfDown==false">
                  <button class="cta" (click)="viewLCODetails(admin)">View</button>
                  <button *ngIf="!isDistributor && menuAccess==true" (click)="edit(admin)">Edit</button>
                  <ng-container *ngIf="menuAccess==true"><button *msoAccess="true"  (click)="login(admin)">
                    Login
                  </button></ng-container>
                  <button *msoAccess="true" (click)="SetBouquet(admin)">
                    Set Bouquet
                  </button>
                  <!-- <button class="danger"
                    (click)="updateStatus(admin)">{{admin.status===1?'Deactivate':'Activate'}}</button> -->
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span>Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="modal_box" *ngIf="viewModal">
  <div class="modal_box_inner">
    <h3>LCO Details</h3>
    <div class="parent">
      <p class="div1">Lco ID</p>
      <p class="div2">{{LCODetails.lcoDetails[0].lcoId}}</p>
      <p class="div3">LCO Name</p>
      <p class="div4">{{LCODetails.lcoDetails[0].lcoName}}</p>
      <p class="div5">Distributor</p>
      <p class="div6">{{LCODetails.lcoDetails[0].distributorName}}</p>
      <p class="div7">Created Date</p>
      <p class="div8">{{LCODetails.lcoDetails[0].createdDate | date}}</p>
      <p class="div9" style="text-align:start">GST Number</p>
      <p class="div10">{{LCODetails.lcoDetails[0].gstNumber}}</p>
      <p class="div11" style="text-align:start">Subscriber Count</p>
      <p class="div12">{{LCODetails.lcoDetails[0].subscriberCount}}</p>
      <p class="div13" style="text-align:start">Wallet balance</p>
      <p class="div14">{{LCODetails.lcoDetails[0].walletBalance | currency:'INR'}}</p>
      
    </div>
    <div class="custom_iptv_table" *ngIf="LCODetails.last5Transaction.length>0">
      <h6>Last Transactions</h6>
      <div class="table__height">
      <table class="custom__iptv__table">
        <tbody>
          <tr>
            <th>Sl No</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Remarks</th>
          </tr>
          <ng-container *ngFor="let item of LCODetails.last5Transaction;let i=index">
            <tr>
              <td>{{i+1}}</td>
              <td>{{item.amount| currency:'INR'}}</td>
              <td>{{item.type}}</td>
              <td>{{item.remarks }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      </div>
    </div>
    <div class="custom_iptv_table" *ngIf="LCODetails.BouquetDetails.length>0">
      <h6>Bouquet Details</h6>
      <div class="table__height">
      <table class="custom__iptv__table">
        <tbody>
          <tr>
            <th>Bouquet Id</th>
            <th>Bouquet Name</th>
            <th>Bouquet Price</th>
            <th>MSO Amount</th>
            <th>LCO Amount</th>
          </tr>
          <ng-container *ngFor="let item of LCODetails.BouquetDetails">
            <tr>
              <td>{{item.bouquetId}}</td>
              <td>{{item.bouquetName}}</td>
              <td>{{item.bouquetPrice | currency:'INR'}}</td>
              <td>{{item.msoAmount  | currency:'INR'}}</td>
              <td>{{item.lcoAmount==''?'Not set': item.lcoAmount | currency:'INR'}}</td>
            
            </tr>
          </ng-container>
        </tbody>
      </table></div>
    </div>
   
    <button class="cta float-right" (click)="viewModal = !viewModal"> Close </button>
  </div>
</div>