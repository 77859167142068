import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { LogsDataService } from '../plugins/logs-data.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { SubscriberSearchType } from 'src/app/subscription/subscriber-search/subscriber-search';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
@Component({
  selector: 'app-logs-subscription',
  templateUrl: './logs-subscription.component.html',
  styleUrls: ['./logs-subscription.component.scss'],
})
export class LogsSubscriptionComponent implements OnInit {
  reportList = [];
  from: any;
  to: any;
  currentDate = new Date();
  loadingFlag = false;
  isLoad = 'Search Subscriber';

  dateSelected = false;
  broadcasteSelected = false;
  ev;
  reportDate;
  paginationObj = new PaginationObj();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private configService: ConfigService,
    private http: HttpClientWrapperService
  ) {}

  ngOnInit(): void {
    this.fetchData(0);
  }

  fetchData(subId: string | number) {
    this.loadingFlag = true
    this.reportList = []
    let body = {
      subscriberId: subId,
      fromDate: '',
      toDate: '',
    };
    PaginationUtilities.giveConfig(this.configService.json.apiEndpoint)
    let url = PaginationUtilities.urlBuilder("logs/subscriptionLogs",this.paginationObj)
    console.log(subId)
    url += `&subscriberId=${subId ? subId : ''}&fromDate&toDate`
    console.log(url)
    this.http.get(url).subscribe({
      next: (data) => {
        this.handlePageMetadata(data.metadata);
        this.reportList = data.data;
        this.loadingFlag= false
        if(data.data.length == 0){
          this.isLoad="No Data"
        }
      },
    });
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus()
  }

  searchText!: string;
  openSearch = false;
  getSubscriberId(e: Event) {
    this.openSearch = false;
    this.searchText = (e.target as HTMLInputElement).value;
    if(this.searchText.length >= 3){
      setTimeout(() => {
        this.openSearch = true;
      }, 500);
    }
  }

  subId: string = '';

  getUser(e: SubscriberSearchType) {
    let inputBox = document.getElementById('searchSubCode') as HTMLInputElement;
    inputBox.value = `${e.subscriberCode}, ${e.subscriberName}`;
    this.fetchData(e.subscriberId);
    this.subId = e.subscriberId;
    this.openSearch = false;
    this.searchText = undefined;
  }

  clearInput() {
    let inputBox = document.getElementById('searchSubCode') as HTMLInputElement;
    inputBox.value = ''
    this.fetchData(0)
  }

  // downloadTableExcel(): void {
  //   let name = 'Subscription Logs';
  //   if (this.reportList.length == 0) {
  //     alert('No Data!!');
  //   } else {
  //     this.doc.exportTableToExcelwithImage(
  //       this.reportTable,
  //       name,
  //       'Subscription Logs',
  //       undefined,
  //       undefined,
  //       'Subscriber ID :', this.subId
  //     );
  //   }
  // }

  downloadTableExcel(){
    let url
      url= this.configService.json.apiEndpoint+'generate/subscriptionLogs?searchKeyword='+this.paginationObj.searchKeyword+
      '&subscriberId='+this.subId
      // if (this.from != "0000-00-00" && this.to != "0000-00-00" && this.from != undefined && this.to!=undefined ) {
      //   url = url + "&fromDate=" + this.from + "&toDate=" + this.to;
      // }
      
      this.http.getwithresponseType(url).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'Subscription_logs.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }




  downloadTablePdf(): void {
    if (this.reportList.length == 0) {
      alert('No Data!!');
    } else {
      this.pdf.generatePdfHtml(
        '#reportTable',
        'Subscription_logs',
        `Subscriber Id : ${this.subId}`,
        'portrait'
      );
    }
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Sl No',
        'Subscriber Code',
        'Name',
        'STB',
        'MAC ID',
        'Bouquet Name',
        'Process Type',
        'Date',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        subscriberCode: line.subscriberCode ? line.subscriberCode : '---',
        subscriberName: line.subscriberName ? line.subscriberName : '---',
        macId: line.macId ? line.macId : '---',
        stbId: line.stbId ? line.stbId : '---',
        bouquetName: line.bouquetName ? line.bouquetName : '---',
        processType: line.processType? line.processType : '---', 
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'Subscription_Logs', options);
  }

  disableFirstBtn = false;
  disablePreviousBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;

  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (
      this.paginationObj.pageNumber == this.paginationObj.totalPages
    ) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData(this.subId);
  }

  pageSizeChange() {
    this.fetchData(this.subId);
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData(this.subId);
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData(this.subId);
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData(this.subId);
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData(this.subId);
  }

}
