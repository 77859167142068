import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriberRoutingModule } from './subscriber-routing.module';
import { CreateSubcriberComponent } from './create-subcriber/create-subcriber.component';
import { ListSubcriberComponent } from './list-subcriber/list-subcriber.component';
import { SharedModule } from '../shared/shared.module';
import { SubscriptionStatusPipe } from './addons/subscription-status.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SwapStbComponent } from './swap-stb/swap-stb.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  declarations: [
    ListSubcriberComponent,
    CreateSubcriberComponent,
    SubscriptionStatusPipe,
    SwapStbComponent
  ],
  imports: [
    CommonModule,
    SubscriberRoutingModule,
    SharedModule,
    InfiniteScrollModule,
    NgMultiSelectDropDownModule
  ],
  exports: [
    ListSubcriberComponent,
    CreateSubcriberComponent]
})
export class SubscriberModule { }
