<div class="cms__content">
  <div class="top__heading">
    <h3>OTA Updates</h3>
    <a [routerLink]="['/settings/ota-manager/update']"
      ><button class="default__button float-right">Update app</button></a
    >
  </div>
  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select
            [(ngModel)]="paginationObj.pageSize"
            (change)="pageSizeChange()"
          >
            <option
              *ngFor="let size of paginationObj.pageSizeOptions"
              [ngValue]="size"
            >
              {{ size }}
            </option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">
            Total <span>{{ paginationObj.totalElements }}</span> records
          </p>
          <div class="search__container">
            <input
              type="text"
              autocomplete="off"
              class="custom__field"
              [(ngModel)]="paginationObj.searchKeyword"
              (keyup.enter)="$event.preventDefault(); onSearch()"
            />
            <button (click)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>

      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>STB Type</th>
            <th>Uploaded By</th>
            <th>Environment</th>
            <th>Release Note</th>
            <th>Version Number</th>
            <th>APK Link</th>
            <th>Device</th>
            <th>Created Date</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngIf="loadingFlag; else showList">
            <td class="loading" colspan="9">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>

          <ng-template #showList>
            <tr *ngFor="let ota of updatedList; let j = index">
              <td>{{ (pageNumber - 1) * pageSize + (j + 1) }}</td>
              <td>{{ ota.stbType ? ota.stbType : "---" }}</td>
              <td>{{ ota.uploadedBy ? ota.uploadedBy : "---" }}</td>
              <td>{{ ota.environment ? ota.environment : "---" }}</td>
              <td>{{ ota.releaseNote ? ota.releaseNote : "---" }}</td>
              <td>{{ ota.versionNumber ? ota.versionNumber : "---" }}</td>
              <td><a href="{{ota.apkLink}}" download target="_blank">{{ ota.apkLink ? ota.apkLink : "---" }}</a></td>
              <td>{{ ota.device ? ota.device : "---" }}</td>
              <td>{{ ota.createdDate ? ota.createdDate : "---" }}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button
              [disabled]="loadingFlag || disableFirstBtn"
              (click)="pageFirstBtnClick()"
            >
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button
              [disabled]="loadingFlag || disablePreviousBtn"
              (click)="pagePreviousBtnClick()"
            >
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button
              [disabled]="loadingFlag || disableNextBtn"
              (click)="pageNextBtnClick()"
            >
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button
              [disabled]="loadingFlag || disableLastBtn"
              (click)="pageLastBtnClick()"
            >
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
