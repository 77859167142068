<div class="cms__content">
  <div class="top__heading">
    <h3>TRAI Reports</h3>
  </div>

  <div class="col-12 list__summary">
    <div class="row">
      <!-- Actual cards for category -->
      <div class="col-12">
        <div class="row sticky">
          <h4 class="sub__heading">STB Reports</h4>
        </div>

        <div class="row">
          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-status']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  STB Status Report
                </div>
              </div>
            </a>
          </div> -->

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-list']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">STB List Report</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-count']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">STB Count Report</div>
              </div>
            </a>
          </div>

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-active-count']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">STB Active Count Report</div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-inactive-count']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">STB Inactive Count Report</div>
              </div>
            </a>
          </div> -->

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-inventory-new']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">STB Inventory Report</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-location-wise']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">STB Location Wise Report</div>
              </div>
            </a>
          </div>

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-warehouse-stock']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  STB Warehouse Stock Report
                </div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-lco-stock']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  STB Partner Stock Report
                </div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-deployed']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  STB Deployed Report
                </div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-faulty']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  STB Faulty Report
                </div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-active-list']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">STB Active List Report</div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-inactive-list']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">STB Inactive List Report</div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-blacklist-list']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">STB Blacklisted List Report</div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-suspended']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Suspended Reports</div>
              </div>
            </a>
          </div> -->

          <div class="col-6 col-md-3" *msoAccess>
            <a [routerLink]="['/reports/trai/report-channels-list-new']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Channels List</div>
              </div>
            </a>
          </div>

          <!-- <div class="col-6 col-md-3">
                        <a [routerLink]="['/reports/trai/subscriber-authorization-report']">
                            <div class="list__card alt__card trai__report">
                                <label class="safe__label">TRAI</label>
                                <div class="card__txt">
                                    Indepth Subscription History
                                </div>
                            </div>
                        </a>
                    </div> -->

          <!-- <div class="col-6 col-md-3">
                        <a [routerLink]="['/reports/trai/subscriber-authorization-report']">
                            <div class="list__card alt__card trai__report">
                                <label class="safe__label">TRAI</label>
                                <div class="card__txt">
                                    Subscriber Authorization
                                </div>
                            </div>
                        </a>
                    </div> -->
        </div>
      </div>

      <div class="col-12" *msoAccess>
        <div class="row sticky">
          <h4 class="sub__heading">Broadcaster Reports</h4>
        </div>

        <div class="row">
          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-broadcaster-channel-wise']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Broadcaster Ala-carte Channel Wise Weekly Report
                </div>
              </div>
            </a>
          </div>
          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-broadcaster-week-wise']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Broadcaster Channel Wise Weekly Report
                </div>
              </div>
            </a>
          </div> -->

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-broadcaster-week-wise']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Broadcaster Bouquet Wise Weekly Report
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a
              [routerLink]="['/reports/trai/report-bouquet-subscription-count']"
            >
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Broadcaster Bouquet Subscription Count Report
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row sticky">
          <h4 class="sub__heading">Product/Subscription Reports</h4>
        </div>

        <div class="row">
          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-subscriber-list-new']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Subscribers List Report</div>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-product-subscription-status']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Product Subscription Status Report</div>
              </div>
            </a>
          </div>


          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-subscribers-details']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subscribers Details Report
                </div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-subscriber-device-replacement']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subsciber Device Replacement
                </div>
              </div>
            </a>
          </div> -->

          <div class="col-6 col-md-3">
            <a
              [routerLink]="[
                '/reports/trai/report-subscriber-order-history-active'
              ]"
            >
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Subscribers Order History Active</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a
              [routerLink]="[
                '/reports/trai/report-subscriber-order-history-inactive'
              ]"
            >
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Subscribers Order History Inactive</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-subwise-ai-ldt']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subscription wise Subscriber Active & Deactive With Location,
                  Date And time
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-statewise-susbscriber']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Statewise Subscriber</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a
              [routerLink]="[
                '/reports/trai/report-subscriberwise-subscription-active'
              ]"
            >
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subscriber Wise Active Subscriptions
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a
              [routerLink]="[
                '/reports/trai/report-subscriberwise-subscription-inactive'
              ]"
            >
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subscriber Wise Inactive Subscriptions
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-bouquetwise-channel']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Bouquet Wise Channel Report</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3" *msoAccess>
            <a [routerLink]="['/reports/trai/report-bouquet-modification']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Bouquet Modification Report</div>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-bouquet-subscription']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Bouquet Subscription</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3" *msoAccess>
            <a [routerLink]="['/reports/trai/report-channel-subscription']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Channel Subscription</div>
              </div>
            </a>
          </div>

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-package-ageing']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Package Ageing Active Report</div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/package-ageing-report-inactive']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Package Ageing Inactive Report</div>
              </div>
            </a>
          </div> -->

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-package-ageing-active-inactive']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Package Ageing  Report</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-product-composition']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Package Composition Report
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-todays-expiry']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Today's Expiry Report</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-msr']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">MSR Report</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-next-seven-days-expiry']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  <div>Next Seven Days Expiry Report</div>
                </div>
              </div>
            </a>
          </div>

          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-next-fifteen-days-expiry']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  <div>Next 15 Days Expiry Report</div>
                </div>
              </div>
            </a>
          </div> -->
        </div>
      </div>

      <!-- <div class="col-12">

        <div class="row">
          <h4 class="sub__heading">
            Subscriber Reports
          </h4>
        </div>

        <div class="row">



          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-subscriber-status']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subscriber Status Report
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-subscriber-data']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subscriber Data Report
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-subscription']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subscription Report
                </div>
              </div>
            </a>
          </div>

        </div>
      </div> -->

      <div class="col-12" *msoAccess>
        <div class="row sticky">
          <h4 class="sub__heading">Miscellaneous Reports</h4>
        </div>

        <div class="row">
          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-osd']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">OSD Report</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-fingerprint']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Fingerprint Report</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/billing']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Billing</div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-universal']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">Universal Report</div>
              </div>
            </a>
          </div>



          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-sync-cas']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  CAS Synchronization Report
                </div>
              </div>
            </a>
          </div> -->
        </div>
      </div>

      <div class="col-12" *msoAccess>
        <div class="row sticky">
          <h4 class="sub__heading">Reconciliation Reports</h4>
        </div>

        <div class="row">
          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-stb-reconciliation-count']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  STB Reconciliation Count Report
                </div>
              </div>
            </a>
          </div>
          <!-- <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-broadcaster-week-wise']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Broadcaster Channel Wise Weekly Report
                </div>
              </div>
            </a>
          </div> -->

          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-subscriber-reconciliation-count']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subscriber Reconciliation Count Report
                </div>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-channel-reconciliation-count']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Channel Reconciliation Count Report
                </div>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-boquet-reconciliation-count']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Boquet Reconciliation Count Report
                </div>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-3">
            <a [routerLink]="['/reports/trai/report-subscription-reconciliation-count']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">TRAI</label>
                <div class="card__txt">
                  Subscription  Reconciliation Count Report
                </div>
              </div>
            </a>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
