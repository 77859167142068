import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transfer-subscriber-to-other-lco',
  templateUrl: './transfer-subscriber-to-other-lco.component.html',
  styleUrls: ['./transfer-subscriber-to-other-lco.component.scss'],
})
export class TransferSubscriberToOtherLCOComponent implements OnInit {
  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getLco();
  }

  // lcoList;
  // getLco() {
  //   let url = this.config.json.apiEndpoint + 'lco/getLCOs';
  //   this.http.get(url).subscribe({
  //     next: (res) => {
  //       this.lcoList = res.data;
  //     },
  //   });
  // }


lcoList: any[];
filteredLcoList: any[]; // Store filtered options
fromLco;
toLco;
toOptionSelected = false;
fromOptionSelected = false;

getLco() {
  // let url = this.config.json.apiEndpoint + 'lco/getLCOs';
    let url = this.config.json.apiEndpoint + 'lco/dropdown'
  this.http.get(url).subscribe({
    next: (res) => {
      this.lcoList = res.data;
      this.filteredLcoList = this.lcoList.slice(); // Initialize filtered list
    },
  });
}

selectOption(e: string) {

  // validation for filter option from "from" to "to"
  if (e === 'from') {
    this.filteredLcoList = this.lcoList.filter(lco => lco !== this.fromLco);
    // Reset toLco if it matches the selected option
    if (this.toLco && this.toLco.id === this.fromLco.id) { // Assuming ID is unique
      this.toLco = null; // Or a default value
    }
  }
  // Validation completed


  switch (e) {
    case 'from':
      this.fromOptionSelected = true;
      break;
    case 'to':
      this.toOptionSelected = true;
      break;
  }
  if (this.fromLco == '' || this.fromLco == undefined) {
    this.fromOptionSelected = false;
  }
  if (this.toLco == '' || this.toLco == undefined) {
    this.toOptionSelected = false;
  }
}




  sampleData = [
    {
      'Mobile Number': '9109457845',
    },
    {
      'Mobile Number': '7437412254',
    },
  ];

  saveSample() {
    this.excelgen.saveSample(
      this.sampleData,
      'transfer_subscriber_to_other_lco'
    );
  }

  
  // selectOption(e) {
  //   // // console.log({'': this.fromLco, 'd' : this.toLco})
  //   switch (e) {
  //     case 'from':
  //       this.fromOptionSelected = true;
  //       break;
  //     case 'to':
  //       this.toOptionSelected = true;
  //       break;
  //   }
  //   if (this.fromLco == '' || this.fromLco == undefined) {
  //     this.fromOptionSelected = false;
  //   }
  //   if (this.toLco == '' || this.toLco == undefined) {
  //     this.toOptionSelected = false;
  //   }
  // }

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement;
    this.excelgen.deselectSelectedFiles(input);
    this.isSubmitReady = false;
  }

  isSubmitReady = false;
  message: string = '';
  errorBody = [];
  errorAvailable = false;
  e;
  selectedFile(e: any) {
    this.message = '';
    this.e = e;
    const url =
      this.config.json.apiEndpointRdbms +
      `bulkUpload/validation/subscribersTransfer/${this.fromLco.lcoCode}/${this.toLco.lcoCode}`;
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        // // console.log(res)
        if (res.result) {
          this.isSubmitReady = true;
          this.errorAvailable = false;
        } else {
          this.errorAvailable = true;
          this.errorBody = res.errorBody;
        }
        if (res.hasOwnProperty('errorMessage')) {
          this.errorAvailable = false;
        }
      },
      error: (error) => {
        this.message = error.message;
      },
    });
  }

  successMessage = 'No errors Found';
  uploadFile() {
    const url =
      this.config.json.apiEndpointRdbms +
      `bulkUpload/transferSubscribers/${this.fromLco.lcoCode}/${this.toLco.lcoCode}`;
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message;
          this.router.navigate(['/manage/subscriber', 'list']);
        } else {
          this.errorAvailable = true;
          this.successMessage = res.message;
          this.errorBody = res.errorBody;
        }
      },
    });
  }
}
