<div class="cms__content">
    <app-reports-header></app-reports-header>

    <div class="top__heading">
        <h3>STB Count Report</h3>
        <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>
    <!-- <div class="row filterbtnStyle">
      <div class="search_filters">
        <div class="col-12 col-md-6">
          <label for="month">Select Date:</label>
          <div class="filter__group">
          <app-date-picker (dateChange)="dateChange($event)"></app-date-picker></div>
        </div>
        <div class="col-12 col-md-6">
          <label class="report_selection_label">Status</label>
          <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="lcoValue" (change)="fetchData()" >
            <option value="All">ALL</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Terminated">Terminated</option>
            <option value="Suspended">Suspended</option>
            <option value="Blacklisted">Blacklisted</option>
          </select>
        </div>
      </div>
    </div> -->


    <div class="filter-download_container">
        <div class="filter_container">
            <div class="label-container">
                <label for="">Select Date :</label>
            </div>
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
            <div class="div label-container">
                <label>Select Partner:</label>
            </div>
            <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="lcoValue"
                (change)="fetchData()">
                <option value="0">ALL</option>
                <option *ngFor="let lco of lcoList" [ngValue]="lco">
                    {{ lco.name }}
                </option>
            </select>
        </div>
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
                CSV
                <img src="./assets/csv.svg" alt="download" /> </button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 report-generated-fix">
            <p>
                Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
            </p>
        </div>
        
    </div>

    <div class="col-12 list__table">
        <div class="row overflow">
            <div class="custom__table__filter">
                <span>
                    Show
                    <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
                        <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
                    </select>
                    Entries
                </span>
                <!-- -->
                <div class="col-12 col-md-6 table-search">
                    <div class="table__right">
                        <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span>
                            record{{reportList.length == 1?
                            '' : 's'}}</p>
                        <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
                        <!-- </p> -->
                    </div>
                    <div class="search__container">
                        <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()" />
                        <button (click)="onSearch()" (keydown.enter)="onSearch()">
                            <img src="./assets/search.png" alt="search" />
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div class="row scroll "> -->
            <table class="custom__iptv__table" #reportTable id="print">
                <thead>
                    <tr>
                        <!-- <th>Subscriber ID</th> -->
                        <th>Sl No.</th>
                        <th class="label__align" (click)="sortByItem(sortByProperties.lcoCode)"
                            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.lcoCode}">Partner Code</th>
                        <th class="label__align" (click)="sortByItem(sortByProperties.lcoName)"
                            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.lcoName}">Partner Name</th>
                        <th class="label__align" (click)="sortByItem(sortByProperties.stbActiveCount)"
                            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.stbActiveCount}">Active
                            Count</th>
                        <th class="label__align" (click)="sortByItem(sortByProperties.stbInactiveCount)"
                            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.stbInactiveCount}">Inactive
                            Count</th>
                        <th class="label__align" (click)="sortByItem(sortByProperties.stbBlacklistedCount)"
                            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.stbBlacklistedCount}">
                            Blacklisted Count</th>
                        <th class="label__align" (click)="sortByItem(sortByProperties.stbSuspenedCount)"
                            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.stbSuspenedCount}">Suspended
                            Count</th>
                        <th class="label__align" (click)="sortByItem(sortByProperties.stbTerminatedCount)"
                            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.stbTerminatedCount}">
                            Terminated Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="reportList?.length ==0 && !loadingFlag">
                        <td style="text-align: center !important;" colspan="8">{{isLoad}}</td>
                    </tr>
                    <tr *ngIf="loadingFlag; else showList">
                        <td style="text-align: center !important;" colspan="8">
                            <app-custom-spinner></app-custom-spinner>
                        </td>
                    </tr>
                    <ng-template #showList>
                        <tr *ngFor="let report of reportList, let j = index">
                            <!-- <td>{{report.subscriber_id}}</td> -->
                            <td>{{j+1}}</td>
                            <td>{{(report.lcoCode || report.lcoCode ) == 'null' || (report.lcoCode
                                ||
                                report.lcoCode ) == '' || (report.lcoCode || report.lcoCode ) ==
                                null ? '---' :
                                (report.lcoCode || report.lcoCode )}}</td>
                            <td>{{(report.lcoName|| report.lcoName) == 'null' || (report.lcoName ||
                                report.lcoName) == '' ||
                                (report.lcoName || report.lcoName) == null ? '---' : (report.lcoName ||
                                report.lcoName)}}</td>
                            <td>{{(report.stbActiveCount || report.stbActiveCount) == 'null' ||
                                (report.stbActiveCount
                                || report.stbActiveCount) == null || (report.stbActiveCount ||
                                report.stbActiveCount) ==
                                '' ? '0' : (report.stbActiveCount || report.stbActiveCount)}}</td>
                            <td>{{report.stbInactiveCount == 'null' || report.stbInactiveCount == null ||
                                (report.stbInactiveCount ||
                                report.stbInactiveCount) == '' ? '0' : (report.stbInactiveCount ||
                                report.stbInactiveCount)}}</td>

                            <td>{{report.stbBlacklistedCount == 'null' || report.stbBlacklistedCount == null ||
                                (report.stbBlacklistedCount ||
                                report.stbBlacklistedCount) == '' ? '0' : (report.stbBlacklistedCount ||
                                report.stbBlacklistedCount)}}</td>
                            <td>{{(report.stbSuspenedCount || report.stbSuspenedCount) == null
                                ||(report.stbSuspenedCount ||
                                report.stbSuspenedCount) == 'null' || (report.stbSuspenedCount ||
                                report.stbSuspenedCount) == ''? '0' :
                                (report.stbSuspenedCount || report.stbSuspenedCount)}}</td>
                            <td>{{(report.stbTerminatedCount || report.stbTerminatedCount) == 'null' ||
                                (report.stbActiveCount
                                || report.stbTerminatedCount) == null || (report.stbTerminatedCount ||
                                report.stbTerminatedCount) ==
                                '' ? '0' : (report.stbTerminatedCount || report.stbTerminatedCount)}}</td>
                        </tr>
                        <tr style="font-weight: bold;">
                            <!-- <td colspan="3" style="text-align: center;">Total</td> -->
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>Total</td>
                                <td>{{ totalActiveCount }}</td>
                                <td>{{ totalInactiveCount }}</td>
                                <td>{{ totalBlacklistedCount }}</td>
                                <td>{{ totalSuspendedCount }}</td>
                                <td>{{ totalTerminatedCount }}</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>

            <div class="custom__pagination">
                <ul>
                    <li>
                        <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                            <img src="./assets/firstPage.png" /></button>
                    </li>
                    <li>
                        <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                            <img src="./assets/previousPage.png" /></button>
                    </li>
                    <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
                    <li>
                        <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                            <img src="./assets/nextPage.png" /></button>
                    </li>
                    <li>
                        <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                            <img src="./assets/lastPage.png" /></button>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</div>