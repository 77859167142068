import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LcoRoutingModule } from './lco-routing.module';
import { ListLcoComponent } from './list-lco/list-lco.component';
import { CreateLcoComponent } from './create-lco/create-lco.component';
import { SharedModule } from '../shared/shared.module';
import { LcoSearchComponent } from './lco-search/lco-search.component';
import { LcoSetBouquetComponent } from './lco-set-bouquet/lco-set-bouquet.component';


@NgModule({
  declarations: [
    ListLcoComponent,
    CreateLcoComponent,
    LcoSearchComponent,
    LcoSetBouquetComponent
  ],
  imports: [
    CommonModule,
    LcoRoutingModule,
    SharedModule
  ],
  exports: [
    LcoSearchComponent
  ]
})
export class LcoModule { }
