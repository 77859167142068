import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { LogsDataService } from '../plugins/logs-data.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { DistributorSearchType } from 'src/app/distributor/distributor-search/distributor-search.component';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { SubscriberSearchType } from 'src/app/subscription/subscriber-search/subscriber-search';

@Component({
  selector: 'app-wallet-recharge-history',
  templateUrl: './wallet-recharge-history.component.html',
  styleUrls: ['./wallet-recharge-history.component.scss'],
})
export class WalletRechargeHistoryComponent implements OnInit {
  reportList = [];
  currentDate = new Date();
  loadingFlag = false;
  isLoad = 'Choose Date';
  reportDate;
  searchKeyword = '';
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) {}

  body = {
    fromDate: '',
    toDate: '',
    type: 'lco',
    id: '',
  };
  ngOnInit(): void {
    this.fetchData();
  }

  fromDate = '';
  toDate = '';
  dateChange(e: NgxDateRangeInfo) {
    console.log(
      e,
      e.startDate.date(),
      e.startDate.month(),
      e.startDate.year(),
      e.endDate
    );
    let fromDate = `${e.startDate.year()}-${String(
        e.startDate.month() + 1
      ).padStart(2, '0')}-${String(e.startDate.date()).padStart(2, '0')}`,
      toDate = `${e.endDate.year()}-${String(e.endDate.month() + 1).padStart(
        2,
        '0'
      )}-${String(e.endDate.date()).padStart(2, '0')}`;
    [this.fromDate, this.toDate] = [fromDate, toDate];
    this.body = {
      ...this.body,
      fromDate,
      toDate,
    };
    this.fetchData();
  }

  fetchData(): void {
    this.loadingFlag = false;
    this.report.getWalletRechargeHistory(this.body).subscribe(
      (data: any) => {
        this.reportList = data.data;
        console.log(this.reportList);
        if (data.data) {
          this.loadingFlag = true;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  isSearch = false;

  searchUser(e: Event) {
    this.searchKeyword = (e.target as HTMLInputElement).value
    this.isSearch = false;
    if(this.searchKeyword.length >= 3 ){
      setTimeout(() => {
        this.isSearch = true;
      }, 300);
    }
  }

  getUserDetail(
    e: DistributorSearchType | SubscriberSearchType | LcoSearchType
  ): void {
    let user = e;
    let input = document.getElementById('searchUser') as HTMLInputElement;
    switch (this.type) {
      case 'distributor':
        user = e as DistributorSearchType;
        this.body = {
          ...this.body,
          id: String(user.id),
          type: this.type,
        };
        input.value = `${user.distributorCode}, ${user.name}`;
        this.fetchData();
        break;
      case 'lco':
        user = e as LcoSearchType;
        this.body = {
          ...this.body,
          id: String(user.lcoId),
        };
        input.value = `${user.lcoCode}, ${user.lcoName}`;
        this.fetchData();
        break;
      case 'subscriber':
        user = e as SubscriberSearchType;
        this.body = {
          ...this.body,
          id: String(user.subscriberId),
        };
        input.value = `${user.subscriberCode}, ${user.subscriberName}`;
        this.fetchData();
        break;
    }

    this.isSearch = false
  }

  type: 'lco' | 'distributor' | 'subscriber' = 'lco';
  selectedType(e: Event) {
    this.type = (e.target as HTMLSelectElement).value as
      | 'lco'
      | 'distributor'
      | 'subscriber';
    console.log(this.type);

    // this.body = {
    //   ...this.body,
    //   type: this.type,
    // }
    // this.fetchData()
  }
  downloadTableExcel(): void {
    let name = 'Wallet Recharge History';
    if (this.reportList.length == 0) {
      alert('No Data!!');
    } else {
      this.doc.exportTableToExcelwithImage(
        this.reportTable,
        name,
        name,
        undefined,
        undefined,
        'Filter Date :',
        this.reportDate
      );
    }
  }

  downloadTablePdf(): void {
    if (this.reportList.length == 0) {
      alert('No Data!!');
    } else {
      this.pdf.generatePdfHtml(
        '#reportTable',
        'Wallet_Recharge_History',
        `Filter date : ${this.reportDate}`,
        'portrait'
      );
    }
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Sl No',
        'Date',
        'Broadcaster Name',
        'Channel Name',
        'Channel ID',
        'Channel Number',
        'Action',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        date: line.fromDate,
        name: line.broadcasterName,
        channelName: line.channelName,
        channelId: line.channelId,
        channelNo: line.channelNo,
        action: line.action || line.ACTION,
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'Wallet_Recharge_History', options);
  }
}

