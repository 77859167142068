<div class="cms__content">
  <div class="top__heading">
    <h3>
      {{ isEdit ? "Edit" : "Create" }} LCO
      <span class="current-item">{{ this.wizardNavname }}</span>
    </h3>

    <a (click)="clickOnBack()">
      <button class="default__button float-right">Back</button></a>
  </div>
  <div class="wizard__navigation hide__mobile">
    <ul>
      <li (click)="wizardDirectNavigation(0)" [ngClass]="{ 'completed-step': verifiedWizardNav >= 0 }">
        Basic Details
      </li>
      <li (click)="wizardDirectNavigation(1)" [ngClass]="{ 'completed-step': verifiedWizardNav >= 1 }">
        Add Packages
      </li>
      <li (click)="wizardDirectNavigation(2)" [ngClass]="{ 'completed-step': verifiedWizardNav >= 2 }">
        Set Package Prices
      </li>
      <!-- <li (click)="wizardDirectNavigation(3)" [ngClass]="{'completed-step': verifiedWizardNav>=3}">
        Add Ala-Carte Channels
      </li>
      <li (click)="wizardDirectNavigation(4)" [ngClass]="{'completed-step': verifiedWizardNav>=4}">
        Set Channel Prices
      </li> -->
      <li (click)="wizardDirectNavigation(5)" [ngClass]="{ 'completed-step': verifiedWizardNav >= 5 }">
        Review
      </li>
      <li (click)="wizardDirectNavigation(6)" [ngClass]="{ 'completed-step': verifiedWizardNav >= 6 }">
        Complete
      </li>
    </ul>
  </div>

  <ng-container *ngIf="onLoadFlag">
    <app-channel-selector [preselectedChannelIds]="channelIds" class="temp__channel_selector"></app-channel-selector>
  </ng-container>

  <div class="basic__details wizard__sections" *ngIf="wizardNav === 0">
    <form action="" [formGroup]="lcoForm" (ngSubmit)="persist()">
      <div class="col-12 form__style">
        <div class="row">
          <div class="col-12 col-md-6">
            <label>Name</label>
            <input type="text" autocomplete="off" class="custom__field" formControlName="name" />
          </div>

          <div class="col-12 col-md-6">
            <label>Contact Person</label>
            <input type="text" class="custom__field" formControlName="contactPerson" />
          </div>

          <div class="col-12 col-md-6">
            <label>Mobile</label>
            <input type="phone" inputmode="numeric" class="custom__field" placeholder="format: 98654..."
              formControlName="mobile" maxlength="10" oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              maxlength="10" [ngClass]="{ error__field: !mobile.valid && mobile.touched }"
              (input)="numValidation($event, 'mobile')" />
            <span class="error__msg" *ngIf="numError">Please Enter Number</span>
            <div [hidden]="!mobile.touched">
              <span class="error__msg" *ngIf="mobile.hasError('required')">Please enter mobile number</span>
              <span class="error__msg" *ngIf="isPatternCorrect">Number is invalid</span>
              <span class="error__msg" *ngIf="mobile.errors?.duplicate">Number is taken</span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <label>Landline</label>
            <input type="phone" class="custom__field" placeholder="Enter number here" formControlName="landline"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')" pattern="^\d{10}$" maxlength="10"
              [ngClass]="{ error__field: !landline.valid && landline.touched }"
              (input)="numValidation($event, 'landline')" />
            <span class="error__msg" *ngIf="mobError">Please Enter Number</span>
            <div [hidden]="!landline.touched">
              <span class="error__msg" *ngIf="landline.hasError('required')">Please enter landline number</span>
              <span class="error__msg" *ngIf="landline.hasError('pattern')">Number is invalid</span>
              <span class="error__msg" *ngIf="landline.errors?.duplicate">Number is taken</span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <label>Email</label>
            <input type="email" class="custom__field" placeholder="format: abc@xyz" formControlName="email" readonly
              [ngClass]="{ error__field: !email.valid && email.touched }"  *ngIf="isEdit"/>
              <input type="email" class="custom__field" placeholder="format: abc@xyz" formControlName="email"
              [ngClass]="{ error__field: !email.valid && email.touched }"  *ngIf="!isEdit"/>
            <div [hidden]="!email.touched">
              <span class="error__msg" *ngIf="email.hasError('required')">Please enter email</span>
              <span class="error__msg" *ngIf="email.hasError('email')">Email is invalid</span>
              <span class="error__msg" *ngIf="email.errors?.duplicate">Email is taken</span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <label>Status</label>
            <select class="custom__field" formControlName="status">
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          </div>

          <div *ngIf="!isEdit" class="col-12 col-md-6 password-field_show-hide">
            <label>New Password</label>
            <input autocomplete="off" [type]="showPassword ? 'text' : 'password'" class="custom__field"
              formControlName="password" [ngClass]="{ error__field: !password.valid && password.touched }" />
            <img (click)="showPassword = !showPassword" [src]="
                !showPassword
                  ? '/assets/icons/eye.svg'
                  : '/assets/icons/eye-no.svg'
              " alt="Show Password" />
            <div [hidden]="!password.touched" style="margin: 0">
              <span class="error__msg" *ngIf="password.hasError('required')">Required</span>
              <span class="error__msg" *ngIf="password.hasError('minlength')">Should be atleast 8 characters long</span>
              <span class="error__msg" style="position: static" *ngIf="password.hasError('pattern')">
                Must contain at least lowercase and uppercase letters, numeric
                digit, special character and 8 digits long
              </span>
            </div>
          </div>

          <div *ngIf="!isEdit" class="col-12 col-md-6 password-field_show-hide">
            <label>Confirm Password</label>
            <input autocomplete="off" [type]="showConfirmPassword ? 'text' : 'password'" class="custom__field"
              formControlName="confirmPassword" [ngClass]="{
                error__field: !cpassword.valid && cpassword.touched
              }" />
            <img (click)="showConfirmPassword = !showConfirmPassword" [src]="
                !showConfirmPassword
                  ? '/assets/icons/eye.svg'
                  : '/assets/icons/eye-no.svg'
              " alt="Show Password" />
            <div [hidden]="!cpassword.touched">
              <span class="error__msg" *ngIf="lcoForm.errors?.mismatch">Passwords do not match</span>
            </div>
          </div>

          <div class="col-12 col-md-6" style="display: none">
            <label>LCO Share (in %)</label>
            <input type="number" class="custom__field" formControlName="transferPrice" />
          </div>
          <div class="col-12 col-md-6">
            <label>Feed Type</label>
            <select name="" id="" class="custom__field" formControlName="feedType">
              <option value="multicast">multicast</option>
              <option value="unicast">---</option>
            </select>
          </div>
          <div class="col-12 col-md-6" style="position: relative">
            <label>GST Number</label>
            <div style="
                position: absolute;
                right: 0;
                top: 0;
                padding-right: 15px;
                display: none;
                gap: 10px;
                align-items: center;
              ">
              <label for="" class="not__required">IGST?</label>
              <input type="checkbox" name="" id="" style="margin-left: auto" />
            </div>
            <input type="text" class="custom__field" formControlName="gstNo" />
          </div>

          <div class="col-12">
            <label>Address</label>
            <textarea class="custom__field" formControlName="address"></textarea>
          </div>

          <div class="col-12 col-md-6">
            <label>Location</label>
            <select class="custom__field" formControlName="locationId">
              <!-- <option *ngIf="packageList.length===0" value="">No Packages to choose</option> -->
              <!-- <option value="">No location assigned</option> -->
              <option *ngFor="let location of locationList" [value]="location.id">
                {{ location.locality }}, {{ location.city }},
                {{ location.state }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-6" [ngStyle]="distributorDropDown">
            <label>Distributor</label>
            <select class="custom__field" formControlName="distributorId" (change)="getBoquetByDistributorId($event)">
              <!-- <option *ngIf="packageList.length===0" value="">No Packages to choose</option> -->
              <!-- <option value="">No location assigned</option> -->
              <option *ngFor="let distributor of distributorList" [value]="distributor.id"
                [disabled]="distributor.status == 0">
                {{ distributor.name }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-6" style="display: 'block'">
            <label>ACL Group</label>
            <select class="custom__field" formControlName="aclGroupId">
              <option *ngFor="let aclGroup of AclGroupList" [value]="aclGroup.id">
                {{ aclGroup.name }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-6 checkbox">
            <input type="checkbox" [(ngModel)]="isChecked" (click)="changingCheck($event)" [ngModelOptions]="{standalone: true}"/>
            <!-- <input type="checkbox" [(ngModel)]="isChecked" (click)="" [ngModelOptions]="{standalone: true}"/> -->
            <label>Self GST Filing</label>
          
          </div>
          

          <div class="col-12">
            <!-- <button type="button" [routerLink]="['/lco/list']" class="default__button">Cancel</button> -->
            <!-- <button class="cta float-right" [disabled]="!lcoForm.valid">Save</button> -->
            <button class="cta" disabled (click)="wizardNavigationPrevious()">
              Previous
            </button>
            <button class="cta float-right" (click)="wizardNavigationNext()" [disabled]="lcoForm.invalid">
              Next
            </button>
            <!-- <button class="cta float-right" (click)="wizardNavigationNext()" >Next</button> -->
            <!-- <button class="cta float-right" [disabled]="!lcoForm.valid" (click)="wizardNavigationNext()">Next</button> -->
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="add__channels wizard__sections" *ngIf="wizardNav === 1">
    <div class="col-12 list__table">

      <div class="row">
        <div class="col-12 search">
        <div class="col-md-4 search">
        <button style="margin-bottom: 5px" *ngIf="!selectAllClicked" (click)="selectAll()">
          Select All
        </button>
        <button *ngIf="selectAllClicked" (click)="unselectAll()">
          Unselect All
        </button>
      </div>
        <div class="col-md-4 search">
          <label>Pack Type</label>
           <select class="custom__field" [(ngModel)]="packType" (change)="fetchPackageList()">
            <option value="All">All</option>
            <option value="BASE_PACK"> Base pack</option>
            <option value="ADD_ON_PACK">Add on Pack</option>
            <option value="ALACARETE_PACK">Alacartee-pack</option>
          </select>
        </div>

        <div class="col-md-4 search">
          <input type="text" placeholder="search Package" class="custom__field" id="searchBox"
            (keydown.enter)="search()" />
          <button (click)="!isSearch ? search() : cancel()">{{!isSearch ? 'Search' : 'Cancel'}}</button>
        </div>
      </div>
        <div id="tableBox">


          <!-- <div class="custom__table__filter">
          <span>
            Show
            <select [(ngModel)]="paginationObjPackage.pageSize" (change)="pageSizeChangePackage()">
              <option *ngFor="let size of paginationObjPackage.pageSizeOptions" [ngValue]="size">{{size}}</option>
            </select>
            Entries
          </span>
          <div class="table__right">
            <p class="table__summary">Total <span>{{paginationObjPackage.totalElements}}</span> records</p>
            <div class="search__container">
              <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObjPackage.searchKeyword" />
              <button (click)="fetchPagePackage()"><img src="/assets/search.png" alt="search" /></button>
            </div>
          </div>
        </div> -->
          <div class="tableBox">
            <table class="custom__iptv__table" id="secondStepTable">
              <thead>
                <tr>
                  <th>Selected</th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Price (in Rs)</th>
                  <th>Package Type</th>
                </tr>
              </thead>
              <tbody id="scroll">
                <tr *ngIf="loadingFlagPackage">
                  <td class="loading" colspan="7">
                    <app-custom-spinner></app-custom-spinner>
                  </td>
                </tr>
                <tr *ngFor="let package of packageList">
                  <td>
                    <input type="checkbox" [(ngModel)]="package.checked"
                      (ngModelChange)="packageSelectChecked(package)" />
                  </td>
                  <td>{{ package.id }}</td>
                  <td>{{ package.name }}</td>
                  <td>{{ package.price }}</td>
                  <td>{{ package.packType }}</td>
                  <!-- <td>
                  <span
                    [ngClass]="{
                      safe__label: package.status === 1,
                      danger__label: !(package.status === 1)
                    }"
                  >
                    {{ package.status === 1 ? "&#10003;" : "X" }}
                  </span>
                </td>
                <td>
                  {{ package.createdDate | date : "MMM d, y" }}
                </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <button class="cta" (click)="wizardNavigationPrevious()">Previous</button>
    <ng-container *ngIf="packageList">
      <button class="cta float-right" [disabled]="packageList.length == 0" (click)="wizardNavigationNext()">
        Next
      </button>
    </ng-container>
    <ng-container *ngIf="!packageList">
      <button class="cta float-right" disabled>Next</button>
    </ng-container>
  </div>

  <div class="set__discounts wizard__sections" *ngIf="wizardNav === 2">
    <div class="table_container">
      <div class="tableBox">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th class="set-package-price_th">Name</th>
              <th class="set-package-price_th">MRP</th>
              <!-- <th>Discount (in %)</th> -->
              <!-- <th>Discount Price</th> -->
              <th class="set-package-price_th">LCO transfer price</th>
              <!-- <th>LCO Share Price</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ch of selectedLcoPackageList; let i = index">
              <td>{{ ch.name }}</td>
              <td>{{ ch.price }}</td>
              <!-- <td>
              <input type="number" placeholder="Enter Discount" class="custom__field"
                (change)="discountPercentChanged($event, ch, i)" />
            </td> -->
              <!-- <td>{{ch.discountPrice | number : '1.2-2' }}</td> -->
              <td (change)="lcoSharePercentChanged($event, ch, i)">
                <input type="number" placeholder="Enter Price" class="custom__field"
                  (input)="lcoSharePercentChanged($event, ch, i)" 
                  [value]="
                  ch.lcoSharePercent == undefined ? '' : ch.lcoSharePercent
                " />
                <!-- <input type="number" placeholder="Enter Price" class="custom__field"
                  (ngModelChange)="lcoSharePercentChanged($event, ch, i)" 
                  [value]="
                  ch.lcoSharePercent == undefined ? '' : ch.lcoSharePercent
                " /> -->
              </td>
              <!-- <td>{{ch.lcoSharePrice | number : '1.2-2' }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div class="summary__cost">
      Total Cost
      <span>Rs {{updateLcoPackageTotalPrice() | number : '1.2-2'}}</span>
    </div> -->

    <button class="cta" (click)="wizardNavigationPrevious()">Previous</button>
    <button class="cta float-right" (click)="wizardNavigationNext()"   [disabled]="isNextButtonDisabled()">
      Next
    </button>
  </div>

  <div class="review__section wizard__sections" *ngIf="false">
    <app-channel-selector [preselectedChannelIds]="channelIds"></app-channel-selector>

    <button class="cta" (click)="wizardNavigationPrevious()">Previous</button>
    <button class="cta float-right" (click)="wizardNavigationNext()">
      Next
    </button>
  </div>

  <div class="set__discounts wizard__sections" *ngIf="false">
    <div class="table_container">
      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th class="set-package-price_th">Name</th>
            <th class="set-package-price_th">MRP</th>
            <!-- <th>Discount (in %)</th> -->
            <!-- <th>Discount Price</th> -->
            <th class="set-package-price_th">LCO transfer price</th>
            <!-- <th>LCO Share Price</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ch of selectedLcoChannelList; let i = index">
            <td>{{ ch.name }}</td>
            <td>{{ ch.price }}</td>
            <!-- <td>
              <input type="number" placeholder="Enter Discount" class="custom__field" [(ngModel)]="ch.discountPercent"
                (ngModelChange)="ch.discountPrice = ch.price - (ch.price * ch.discountPercent/100);
                ch.lcoSharePrice = ch.discountPrice * ch.lcoSharePercent/100" />
            </td> -->
            <!-- <td>{{ch.discountPrice | number : '1.2-2'}}</td> -->
            <td>
              <input type="number" placeholder="Enter Price" class="custom__field" [value]="ch.price"
                (change)="lcoSharePercentChanged($event, ch, i)" />
            </td>
            <!-- <td>{{ch.lcoSharePrice | number : '1.2-2'}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="summary__cost">
      Total Cost
      <span>Rs {{updateLcoChannelTotalPrice() | number : '1.2-2'}}</span>
    </div> -->

    <button class="cta" (click)="wizardNavigationPrevious()">Previous</button>
    <button class="cta float-right" (click)="wizardNavigationNext()">
      Next
    </button>
  </div>

  <div class="review__section wizard__sections" *ngIf="wizardNav === 5">
    <div class="table_container">
      <table style="margin-bottom: 10px" class="custom__iptv__table">
        <thead>
          <tr>
            <th>Label</th>
            <th colspan="3">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td colspan="3">{{ lco.name }}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td colspan="3">{{ lco.email }}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td colspan="3">{{ lco.address }}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td colspan="3">{{ lco.mobile }}</td>
          </tr>
          <tr>
            <td>Landline</td>
            <td colspan="3">{{ lco.landline }}</td>
          </tr>
          <tr>
            <td>Bouquets</td>
            <td colspan="3">
              <table class="custom__iptv__table">
                <tr *ngFor="let pk of selectedLcoPackageList">
                  <td>{{ pk.name }}</td>
                  <td>LCO Share Price: Rs {{ pk.lcoSharePercent }}</td>
                </tr>
              </table>
            </td>
          </tr>
        
        </tbody>
      </table>
    </div>
    <button class="cta" (click)="wizardNavigationPrevious()">Previous</button>
    <button class="cta float-right" (click)="wizardNavigationNext()" [disabled]="!lcoForm.valid">
      Submit
    </button>
  </div>

  <div class="status__section wizard__sections" *ngIf="wizardNav === 6">
    <div class="complete__tick">
      <img src="/assets/check_lco.svg" alt="Completed" />
    </div>
    <h3>LCO Successfully {{ isEdit ? "Updated" : "Created" }}</h3>
    <a (click)="onClickDone()">
      <button class="default__button float-right">Done</button></a>
  </div>
</div>