import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-location',
  templateUrl: './create-location.component.html',
  styleUrls: ['./create-location.component.scss'],
})
export class CreateLocationComponent implements OnInit {
  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private route: Router
  ) { }

  ngOnInit(): void { }

  sampleData = [
    {
      Locality: 'Rajajinagar',
      City: 'Banglore',
      District: 'Banglore',
      'Pin Code': '560010',
      State: 'Karnataka',
    },
    {
      Locality: 'Peenya',
      City: 'Banglore',
      District: 'Banglore',
      'Pin Code': '560053',
      State: 'Karnataka',
    },
  ];

  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'create_location');
  }

  isSubmitReady = false;
  message: string = ''
  errorBody = []
  errorAvailable = false
  e: any
  selectedFile(e: any) {
    this.message = ''
    this.e = e
    const url = this.config.json.apiEndpoint + 'bulkUpload/validation/locations';
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        // console.log(res)
        if (res.result) {
          this.isSubmitReady = true
          this.errorAvailable = false
        } else {
          // console.log(res)
          this.errorAvailable = true
          this.errorBody = [res.errorBody]
        }
      },
      error: (error) => {
        this.message = error.error.message
      }
    });
  }
  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement
    this.excelgen.deselectSelectedFiles(input)
    this.isSubmitReady = false

  }
  successMessage = 'No errors Found'
  uploadFile() {
    const url = this.config.json.apiEndpoint + 'bulkUpload/locations';
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message
          if (confirm('Redirect to list of Locations?')) {
            this.route.navigate(['/manage/location/list'])
          }
        } else {
          this.errorAvailable = true
          this.successMessage = res.message
          this.errorBody = res.errorBody
        }
      }
    })
  }
}
