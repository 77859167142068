<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>STB List Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>
  <!-- <div class="row filterbtnStyle">
    <div class="search_filters">
      <div class="col-12 col-md-6">
        <label for="month">Select Date:</label>
        <div class="filter__group">
        <app-date-picker (dateChange)="dateChange($event)"></app-date-picker></div>
      </div>
      <div class="col-12 col-md-6">
        <label class="report_selection_label">Status</label>
        <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="statusValue" (change)="fetchData()" >
          <option value="All">ALL</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
          <option value="Terminated">Terminated</option>
          <option value="Suspended">Suspended</option>
          <option value="Blacklisted">Blacklisted</option>
        </select>
      </div>
    </div>
  </div> -->


  <div class="filter-download_container">
    <div class="filter_container">
      <div class="label-container">
        <label for="">Select Date :</label>
      </div>
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
      <div class="div label-container">
        <label class="report_selection_label">Status:</label>
      </div>
      <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="statusValue" (change)="fetchData()" >
        <option value="All">ALL</option>
        <option value="Active">Active</option>
        <option value="Inactive">Inactive</option>
        <option value="Terminated">Terminated</option>
        <option value="Suspended">Suspended</option>
        <option value="Blacklisted">Blacklisted</option>
      </select>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" /> </button>
    </div>
  </div>

  <div class="row"> 
    <div class="col-md-6 report-generated-fix">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    
  </div>

  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <!-- -->
        <div class="col-12 col-md-6 table-search">
          <div class="table__right">
            <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> record{{reportList.length == 1?
              '' : 's'}}</p>
            <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
            <!-- </p> -->
          </div>
          <div class="search__container">
            <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()" [(ngModel)]="paginationObj.searchKeyword" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="row scroll "> -->
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
            <!-- <th>Subscriber ID</th> -->
            <th>Sl No.</th>
           
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberId)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberId}">Subscriber ID</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.stbId)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.stbId}">STB Id</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.subscriberName)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subscriberName}">Subscriber Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.partnerName)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.partnerName}">Partner Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.ethernetMacAddress)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.ethernetMacAddress}">MAC Address</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.triggerDate)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.triggerDate}"> Triggered Date</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.status)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">Status</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.stbType)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.stbType}">STB Type</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList?.length ==0 && !loadingFlag">
            <td style="text-align: center !important;" colspan="8">{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="8">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList, let j = index">
              <!-- <td>{{report.subscriber_id}}</td> -->
              <td>{{j+1}}</td>
              <td>{{report.subscriberId == 'null' || report.subscriberId == null || (report.subscriberId ||
                report.subscriberId) == '' ? '---' : (report.subscriberId || report.subscriberId)}}</td>
              <td>{{report.stbId == 'null' || report.stbId == null || (report.stbId ||
                report.stbId) == '' ? '---' : (report.stbId || report.stbId)}}</td>
              <td>{{(report.subscriberName || report.subscriber_name ) == 'null' || (report.subscriberName ||
                report.subscriber_name ) == '' || (report.subscriberName || report.subscriber_name ) == null ? '---' :
                (report.subscriberName || report.subscriber_name )}}</td>
              <td>{{(report.partnerName|| report.partnerName) == 'null' || (report.partnerName || report.partnerName) == '' ||
                (report.partnerName || report.partnerName) == null ? '---' : (report.partnerName || report.partnerName)}}</td>
              <td>{{(report.ethernet_mac_address || report.ethernetMacAddress) == 'null' || (report.ethernet_mac_address
                || report.ethernetMacAddress) == null || (report.ethernet_mac_address || report.ethernetMacAddress) ==
                '' ? '---' : (report.ethernet_mac_address || report.ethernetMacAddress)}}</td>
                <td>{{(report.triggerDate || report.triggerDate) == null ||(report.triggerDate ||
                  report.triggerDate) == 'null' || (report.triggerDate || report.triggerDate) == ''? '---' :
                  (report.triggerDate || report.triggerDate)| date: 'dd-MM-yyyy HH:mm:ss'}}</td>
               
              <td>{{(report.stbStatus || report.stbStatus) == null ||(report.stbStatus ||
                report.stbStatus) == 'null' || (report.stbStatus || report.stbStatus) == ''? '---' :
                (report.stbStatus || report.stbStatus)}}</td>
                <td>{{report.stbType == 'null' || report.stbType == null || (report.stbType ||
                  report.stbType) == '' ? '---' : (report.stbType || report.stbType)}}</td>
                
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>