import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';


@Component({
  selector: 'app-subscriber-packages-report',
  templateUrl: './subscriber-packages-report.component.html',
  styleUrls: ['./subscriber-packages-report.component.scss']
})
export class SubscriberPackagesReportComponent implements OnInit {

  reportList = [];

  currentDate = new Date();

  selected: NgxDateRangeInfo;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  locale: any = {
    format: 'DD/MM/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'DD/MM/YYYY', // default is format value
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Select', // detault is 'Apply'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 0 // first day is monday
  };

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }


  ngOnInit(): void {
    this.setInitialDate();
  }

  setInitialDate(): void {
    this.selected = this.calendar.getDate();
    this.fetchData(this.selected.startDate.valueOf(), this.selected.endDate.valueOf());
  }

  fetchData(from: number, to: number): void {
    this.report.getSubscriberPackagesReport(from, to).subscribe(data => {
      this.reportList = data;
    });
  }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      this.calendar.setDate(ev);
      // // console.log(this.calendar)
      this.fetchData(ev.startDate.valueOf(), ev.endDate.valueOf());
    }
  }

  downloadTableExcel(): void {
    let name='Subcriber packages Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'subscriber_packages_report', undefined, undefined);
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable', 'subscriber_packages_report');
  }
  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Date", "Total Packages", "Active", "inactive",
      "Channel Number", "Mac Address", "Start Date", "End Date"]
    };

    let data = []
    this.reportList.forEach(line => {
      let csvLine = {
        date: line.date,
        totalPackages: line.totalPackages,
        activePackages: line.activePackages,
        inActivePackages: line.inActivePackages,
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_packages_report', options);
  }

}
