<div class="cms__content">
  <div class="top__heading">
    <app-reports-header></app-reports-header>

    <h3>Subscriber Package History</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <input type="text" autocomplete="off" ngxDaterangepickerMd [(ngModel)]="selected" placeholder="Choose date"
        (change)="dateChange($event)" [ranges]="ranges" [alwaysShowCalendars]="true" [customRangeDirection]="true"
        [locale]="locale" class="custom__field" />
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
        <img src="./assets/csv.svg" alt="download" />			</button>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" placeholder="Subscriber ID" [(ngModel)]="subscriberId" />
        <button (click)="searchIcon()"><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-6">
    <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
  </div>

  <div class="col-12 list__table">
    <div class="row">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Package Name</th>
            <th>Subscription Type</th>
            <th>Status</th>
            <th>Subscriber On</th>
            <th>Expiry</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of reportList, let j=index">
            <td>{{j+1}}</td>
            <td>{{report.packageName}}</td>
            <td>{{report.subscriptionType}}</td>
            <td>{{report.subscriptionStatus === 1 ? 'Active':'Inactive'}}</td>
            <td>{{report.subscriptionDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
            <td>{{report.expireDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
