import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-report-stb-blacklist-list',
  templateUrl: './report-stb-blacklist-list.component.html',
  styleUrls: ['./report-stb-blacklist-list.component.scss', '../report-filters.scss']
})
export class ReportStbBlacklistListComponent implements OnInit {

  reportList = [];
  selectedLco = 0;
  from:any;
  to:any;

  lcoList: LCOInfo[] = [];

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config :ConfigService,
    private http : HttpClientWrapperService
  ) { }


  ngOnInit(): void {
    this.fetchLcoList();
    this.fetchData('','')
  }

  fetchLcoList(): void {
    this.report.getLCOList().subscribe(data => {
      this.lcoList = data.data;
    });
  }

  isLoad = 'Choose Date'
  isLoading  = true
  fetchData(from: any, to: any): void {
    // // console.log("called")
    this.partnerNameSetter(this.selectedLco)
    let body = {'lcoId': this.selectedLco, 'searchKeyword':'',
  fromDate : from, toDate : to
  }
    this.report.getStbBlacklistReport(body).subscribe((res) => {
      this.reportList = res['data'];
      this.isLoading = true
      // // console.log(this.reportList)
      if(this.reportList.length != 0){
        this.isLoading = false
      }
      if(this.reportList.length==0){
        this.isLoad = 'No Records'
        this.isLoading = true
      }
    },()=>{
      this.isLoad = 'No Records'
        this.isLoading = true
    });
  }

  lcoSelect(): void {
    this.fetchData(this.calendar.getDate()['startDate'], this.calendar.getDate()['endDate']);
  }

  partnerName;
  partnerNameSetter(selectedLco : number){
    for(let ele of this.lcoList){
      if(ele.id == selectedLco){
        this.partnerName = ele.name
      }
    }
    selectedLco ==0? this.partnerName = 'All' : this.partnerName = this.partnerName
  }

  dateString;
  eve



  dateChangeTillDate(ev){
    // // console.log(ev)
    let dateObj = new Date(ev.target.value)
    const toDate = dateObj.getFullYear()+"-"+String(dateObj.getMonth()+1).padStart(2,'0')+"-"+String(dateObj.getDate()).padStart(2,'0')
    this.dateString = toDate
    this.fetchData('', toDate);
  }

  downloadTableExcel(): void {
    let name='Stb Blacklisted Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'stb_blacklist_list_report',undefined,undefined,"Till Date :",this.dateString);
  }

  downloadTablePdf(): void {
    if(this.reportList == undefined || this.reportList == null || this.reportList?.length == 0){
      alert('No Data');
      return;
    }
    if(!this.dateString){
      this.pdf.generatePdfHtml('#reportTable', 'stb_blacklisted_report', `Till now    Source Type : SMS`)
      return;
    }
    this.pdf.generatePdfHtml('#reportTable',
    'stb_blacklisted_report',
    `Till Date: ${this.dateString},   Source Type : SMS`
    );
  }

  formatDate(ele) {
    if(ele == undefined){
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No", "Subscriber Code","Subscriber Name","Partner Name","STB MAC","Blacklisted Date"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo : i,
        subCode: line.subscriberCode,
        subName : line.subscriberName,
        lcoName: line.lcoName,
        ethernetMacAddress: line.ethernetMacAddress,
        blackListedDate: line.blacklistedDate,
      }
      data.push(csvLine);
      i +=1
    });

    new ngxCsv(data, 'stb_blacklist_list_report', options);
  }

  searchKeyword(){
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms+'reports/getStbBlacklistReport'
    let body = {
      'searchKeyword' : value,
      'lcoId': this.selectedLco,
      'fromDate' : '',
      'toDate' : this.dateString
    }
    this.http.post(url, body).subscribe(data => {
      this.reportList = data.data;
      // // console.log(data)
      // // console.log(this.reportList)
      if(this.reportList.length != 0){
        this.isLoading = false
      }
      if(this.reportList.length==0){
        this.isLoad = 'No Records'
        this.isLoading = true
      }
    },()=>{
      this.isLoad = 'No Records'
        this.isLoading = true
    })
  }

}
