import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { PageInfo } from 'src/app/models/pagination/page-info';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { SettingsInfo } from 'src/app/models/settings/settings-info';
import { SubscriberGroupInfo } from 'src/app/models/subscriber-group/subscriber-group-info';
import { SubscriberInfo } from 'src/app/models/subscriber/subscriber-info';
import { ConfigService } from 'src/app/services/config.service';
import { SubscriberDataService } from 'src/app/services/data-services/subscriber-data.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { InvoiceDataService } from '../plugins/invoice-data.service';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { SubscriberSearchType } from 'src/app/subscription/subscriber-search/subscriber-search';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private invoiceData: InvoiceDataService,
    private subscriberData: SubscriberDataService,
    private router: Router,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private auth: AuthService
  ) {}

  @ViewChild('invoiceTable', { static: false }) invoiceTable: ElementRef;
  fingerprintConfigurationForm: FormGroup;

  settings = {} as SettingsInfo;

  selectedTab = 0;

  subscriberSearch = false;

  chSearch = false;
  showSidePanel = false;
  fingerprintSendForm: FormGroup;
  isFixedFp = false;

  paginationObjSubscriber = new PaginationObj();
  paginationObjChannel = new PaginationObj();

  subscriberList: SubscriberInfo[] = [];
  channelList: ChannelInfo[] = [];
  subscriberGroupList: SubscriberGroupInfo[] = [];

  subLoadingFlag = false;
  chLoadingFlag = false;

  emmOvertFlag = true;
  ecmOvertFlag = true;

  fingerprintConfig: any;
  sidepanelHeading = 0;

  fpSubscriberTarget = 0;

  invoiceList: any[] = [];
  detailsAccessed = false;
  json;
  ngOnInit(): void {
    this.initFpSendForm();
    // this.fetchInvoiceData();
  }

  initFpSendForm(): void {
    this.fingerprintSendForm = new FormGroup({
      subscriberFilter: new FormControl('name'),
      subscriberId: new FormControl(''), // pid of subscriber
    });
  }

  get fpSubscriberId(): FormControl {
    return this.fingerprintSendForm.get('subscriberId') as FormControl;
  }

  get fpSubscriberFilter(): FormControl {
    return this.fingerprintSendForm.get('subscriberFilter') as FormControl;
  }

  searchSubscriber(): void {
    this.subLoadingFlag = true;
    this.subscriberData
      .getSubscribersByPagination(this.paginationObjSubscriber)
      .subscribe((data: PageInfo<SubscriberInfo>) => {
        this.subLoadingFlag = false;
        this.handleSearchSubscriber(data);
      });
  }

  fetchInvoiceData(): void {
    this.invoiceData
      .getInvoiceListBySubscriber(this.fpSubscriberId.value)
      .subscribe((data) => {
        this.invoiceList = data;
      });
  }

  handleSearchSubscriber(data: PageInfo<SubscriberInfo>): void {
    this.subscriberList = data.data;
    this.subscriberList.forEach((val) => (val.checked = false));
  }

  subscriberCheckbox(data: SubscriberInfo): void {
    data.checked = !data.checked;

    if (data.checked) {
      this.subscriberList.forEach((val) => (val.checked = false));
      data.checked = true;
      this.fpSubscriberId.setValue(data.id);
    } else {
      this.fpSubscriberId.setValue(null);
    }
  }

  detail(invoice: any): void {
    this.router.navigate(['/accounts/invoice/detail', invoice]);
  }

  searchText: string;
  openSearch = false;
  subscriberCode;
  subscriberName;
  getUserDetail(e: SubscriberSearchType) {
    this.subscriberCode = e.subscriberCode;
    this.fingerprintSendForm
      .get('subscriberId')
      .setValue(`${e.subscriberCode}, ${e.subscriberName}`);
    this.subscriberName = e.subscriberName;
    this.openSearch = false;
    this.billing();
  }

  searchSubscriberDetails() {
    this.searchText = (document.getElementById('subCode') as HTMLInputElement).value;
    this.openSearch = false;
    if(this.searchText.length >= 3){
      setTimeout(() => {
        this.openSearch = true;
      }, 300);
    }
  }

  clearInput() {
    this.openSearch = false;
    this.searchText = '';
    let input = document.getElementById('subCode') as HTMLInputElement;
    input.value = '';
  }

  subscriberId;
  billing() {
    let subCode = this.subscriberCode;
    let url1 =
      this.config.json.apiEndpointRdbms +
      `subscriber/getSubscriberByCode/${subCode}`;
    this.http.get(url1).subscribe({
      next: (res: any) => {
        if (res.statusCode == 200) {
          this.subscriberId = res.data.id;
          let url =
            this.config.json.apiEndpointRdbms + `invoice/${this.subscriberId}`;
          this.http.get(url).subscribe({
            next: (res) => {
              // console.log(res)
              this.invoiceList = res.data;
            },
          });
        } else {
          alert('No Subscriber found');
        }
      },
      complete: () => {
        this.detailsAccessed = true;
      },
    });
  }

  isDetail = false;
  invoiceDetail;
  isOpen = false;
  totalBouquetPrice = 0;
  inWordsArray = [];
  showInvoiceDetails(invoice, index) {
    this.totalBouquetPrice = invoice.total_price;
    // let transactionDate = new Date(invoice.transaction_date),
    //   date = `${transactionDate.getFullYear()}-${String(
    //     transactionDate.getMonth() + 1
    //   ).padStart(2, '0')}-${String(transactionDate.getDate()).padStart(
    //     2,
    //     '0'
    //   )} ${String(transactionDate.getHours()).padStart(2, '0')}:${String(
    //     transactionDate.getMinutes()
    //   ).padStart(2, '0')}:${String(transactionDate.getSeconds()).padStart(
    //     2,
    //     '0'
    //   )}`;

    let obj = {
      subscriberId: this.subscriberId,
      stbId: invoice.stbId,
      id: invoice.id,
    };
    let url = this.config.json.apiEndpointRdbms + 'invoice/getInvoiceByDate';
    this.http.post(url, obj).subscribe({
      next: (res) => {
        console.log(res);
        this.invoiceDetail = res.data;
        this.json = res.data;
        console.log(res.data.invoice);
        this.inWordsArray = (res.data.invoice.inWords as string).split(' ');
      },
      complete: () => {
        this.isDetail = true;
      },
    });
  }

  closeInvoiceBar() {
    let slider = document.getElementById('invoiceDetailsBox');
    slider.classList.remove('slider_in');
    slider.classList.add('slider_out');
    setTimeout(() => {
      this.isDetail = false;
    }, 200);
  }

  downloadPDF() {
    const pdf = new jsPDF();
    let element = document.getElementById('print');
    element.style.display = 'block';
    element.style.display = 'block';
    let pw = pdf.internal.pageSize.width;
    pdf.html(element, {
      callback: (doc) => {
        doc.save(this.subscriberName + ' [' + new Date() + ']');
        element.style.display = 'none';
      },
      width: pw,
      windowWidth: 800,
    });
  }
}
