import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { SubscriberInfo } from 'src/app/models/subscriber/subscriber-info';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-report-package-ageing-active-inactive',
  templateUrl: './report-package-ageing-active-inactive.component.html',
  styleUrls: ['./report-package-ageing-active-inactive.component.scss', '../report-filters.scss']
})
export class ReportPackageAgeingActiveInactiveComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;


  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  reportList = [];
  selectedLco = 0;
  selectBouquetType: any = "All";
  selectedBouquetId: any = 0;
  from: any = '0000-00-00';
  to: any = '0000-00-00';
  onDate:any = '0000-00-00';
  bouquetList = [];

  currentDate = new Date();
  statusValue = "5"
  
  isLoad = 'Choose Date';
  pageNumber
  dateString;
  eve;

  sortByProperties = {
    bouquetId:'bouquetId',
    bouquetName: 'bouquetName',
    bouquetType: 'bouquetType',
    a: '0-30',
    b: '31-60',
    c: '61-90',
    d: '91-120',
    e: '>121',
  };

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchBouquetList();
    this.fetchData()
  }
  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe(data => {
      this.bouquetList = data.data;
    });
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  // downloadTableExcel(): void {
  //   let name = 'Package Ageing Active Report',filters
  //   if(this.onDate == "0000-00-00"){
  //     filters =  `Source Type : SMS , Product Id : ${this.selectedBouquetId} , Product type : ${this.selectBouquetType} `
  //   }
  //   else {
  //     filters = `Source Type : SMS , Date : ${this.onDate} , Product Id : ${this.selectedBouquetId} , Product type : ${this.selectBouquetType}`
  //   }
  //   this.doc.exportTableToExcelwithImage(this.reportTable, name, 'package_ageing_report', undefined,
  //     undefined,
  //     'Filters : ',
  //     filters);
  // }

  downloadTableExcel(){
    let url
    if(this.onDate != "0000-00-00"){
     url= this.config.json.apiEndpoint+'generate/packageAgeingReports?bouquetType='+this.selectBouquetType+'&bouquetId='
    +this.selectedBouquetId+'&onDate='+this.onDate+'&searchKeyword'+this.paginationObj.searchKeyword
    }
    else{
      url= this.config.json.apiEndpoint+'generate/packageAgeingReports?bouquetType='+this.selectBouquetType+'&bouquetId='
      +this.selectedBouquetId+'&searchKeyword'+this.paginationObj.searchKeyword
    }
    this.http.getwithresponseType(url).subscribe(
      (response) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const jsonResponse = JSON.parse(reader.result as string);
            if (jsonResponse.status === 'FAIL') {
             alert(`${jsonResponse.message}`);
              return;
            }
          } catch (error) {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'package_ageing_report.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
          }
        };
        reader.readAsText(response);
      },
      (error) => {
        console.error('Error downloading Excel file:', error);
      }
    );
  }

  downloadTablePdf(): void {
    if(this.onDate == "0000-00-00") {
      this.pdf.generatePdfHtml('#print',
        'package_ageing_report',
        `Source Type : SMS , Product Id : ${this.selectedBouquetId} , Product type : ${this.selectBouquetType} `
      );
    }
    else{
      this.pdf.generatePdfHtml('#print',
          'package_ageing_report',
          `Source Type : SMS , Date : ${this.onDate} , Product Name : ${this.selectedBouquetId} , Product type : ${this.selectBouquetType}`
        );
    }
  }
  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }
  

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.","Bouquet Id", "Bouquet Name", "Bouquet Type", "0-30", "31-60", "61-90", "91-120", ">120"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(report => {
      let csvLine = {
        slNo: i,
        bouquetid:report.bouquetId,
        bouquetName: report.bouquetName,
        bouquetType: report.bouquetType,
        subscription_status: report["0-30"],
        start_date: report["31-60"],
        end_date: report["61-90"],
        ageing: report["91-120"],
        price_package_name: report[">121"],
      }
      i += 1
      data.push(csvLine);
    });
    new ngxCsv(data, 'package_ageing_report', options);
  }

  handlePaginationBtnDisableStatuses(): void {
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchData();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }
  handleFetchPage(data): void {
    this.reportList = data.data;
    if (this.reportList.length != 0) {
      this.loadingFlag = false;
    } else {
      this.loadingFlag = false;
      this.isLoad = 'No Records';
    }
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

 
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  fetchData(): void {
    this.loadingFlag = true
    this.reportList = []
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    let url = PaginationUtilities.urlBuilder('reports/packageAgeingReports', this.paginationObj);
    url = url + "&status=" + this.statusValue + "&bouquetType=" + this.selectBouquetType + "&bouquetId=" + this.selectedBouquetId
    if (this.onDate != "0000-00-00") {
      url = url + "&onDate=" + this.onDate;
    }
    this.http.get(url).subscribe(
      (data) => {
        this.handleFetchPage(data);
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  lcoSelect(): void {
    this.from = this.calendar.getDate()['startDate']
    this.to = this.calendar.getDate()['endDate']
    this.fetchData();
  }


  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.from = ev.startDate.format('YYYY-MM-DD');
      this.to = ev.endDate.format('YYYY-MM-DD');
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }



  formatDate(ele) {
    if (ele == undefined) {
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

}
