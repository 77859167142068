import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { DashboardTopChannelInfo } from 'src/app/models/dashboard/dashboard-top-channel-info';
import { ConfigService } from 'src/app/services/config.service';
import { map } from 'rxjs/operators';
import { DashAll } from 'src/app/models/dashboard/dash-all';
import { DashMonthly } from 'src/app/models/dashboard/dash-monthly';

@Injectable()
export class DashboardDataService {
  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  getDashboardData(): Observable<any> {
    const url = this.config.json.apiEndpoint + 'dashboard/getDashboardInfo';
    return this.http.get(url);
  }

  getDashboardDataAll(body) {
    const url = this.config.json.apiEndpoint + 'dashboard/dashAll';
    return this.http.post(url, body).pipe(
      map((res) => {
        let data = res.data[0] as DashAll;
        let body = {
          stbStatus: [
            data?.active_stb,
            data?.inactive_stb,
            data?.blacklisted_stb,
          ],
          totalSubscribers: data?.total_subscribers,
          totalBroadcasters: data?.total_broadcasters,
          totalChannels: data?.total_channels,
          subscribers: [data?.active_subscribers, data?.inactive_subscribers],
          mostViewedChannels: data?.most_view_channels as string[],
          bestSellingPacks: arrangeInDescendingOrder(data?.best_selling_packs),
          expiringSubscriptions: {
            day: data?.today_expiring_subscriptions,
            week: data?.this_week_expiring_subscriptions,
            month: data?.this_month_expiring_subscriptions,
            year: data?.this_year_expiring_subscriptions,
          },
          revenueGenerated: {
            today: data?.revenue_today,
            month: data?.revenue_this_month,
            year: data?.revenue_this_year,
          },
          smallBlockCounts: {
            customerOnboardedToday: data?.today_subscribers,
            totalLco: data?.total_lco,
            totalSubscribers: data?.total_subscribers,
            broadcasters: data?.total_broadcasters,
            channels: data?.total_channels,
          },
        };

        function arrangeInDescendingOrder(obj) {
          let arr = [];
          for (let [key, value] of Object.entries(obj)) {
            arr.push({ key, value });
          }
          arr = arr.sort((a, b) => b.value - a.value);
          return arr;
        }

        if (res.data.length == 0) {
          body = {
            stbStatus: [1, 1, 1, 1, 1, 1, 1],
            totalSubscribers: 0,
            totalBroadcasters: 0,
            totalChannels: 0,
            subscribers: [1, 1],
            mostViewedChannels: ['', '', '', '', '', ''],
            bestSellingPacks: arrangeInDescendingOrder({
              'No data1': 1,
              'No data2': 2,
              'No data3': 3,
              'No data4': 4,
              'No data5': 5,
            }),
            expiringSubscriptions: {
              day: 0,
              week: 0,
              month: 0,
              year: 0,
            },
            revenueGenerated: {
              today: 0,
              month: 0,
              year: 0,
            },
            smallBlockCounts: {
              customerOnboardedToday: 0,
              totalLco: 0,
              totalSubscribers: 0,
              broadcasters: 0,
              channels: 0,
            },
          };
        }
        return body;
      })
    );
  }

  getDashboardDataMonthly(body) {
    const url = this.config.json.apiEndpoint + 'dashboard/dashMonthly';
    return this.http.post(url, body).pipe(
      map((res) => {
        let data = res.data as DashMonthly[];
        let format = {
          months: [],
          previousYearRevenue: [],
          currentYearRevenue: [],
          newSubscribers: [],
          oldSubscribers: [],
          activeSubscribers: [],
          inactiveSubscribers: [],
        };
        console.log();

        if (data.length !== 0) {
          for (let ele of data) {
            format.months.push(ele.month);
            format.previousYearRevenue.push(ele.previous_year_revenue);
            format.currentYearRevenue.push(ele.current_year_revenue);
            format.newSubscribers.push(ele.new_subscribers_count);
            format.oldSubscribers.push(ele.previous_year_subscribers_count);
            format.activeSubscribers.push(ele.active_subscribers);
            format.inactiveSubscribers.push(ele.inactive_subscribers);
          }
        } else {
          format = {
            months: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            previousYearRevenue: new Array(12).fill(1),
            currentYearRevenue: new Array(12).fill(2),
            newSubscribers: new Array(12).fill(2),
            oldSubscribers: new Array(12).fill(1),
            activeSubscribers: new Array(12).fill(2),
            inactiveSubscribers: new Array(12).fill(1),
          };
        }
        return format;
      })
    );
  }

  getChannelViewData(): Observable<DashboardTopChannelInfo[]> {
    const url =
      this.config.json.apiEndpoint + 'reports/getChannelViewsForDashboard';
    return this.http.get(url);
  }
}
