import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PathNames } from 'src/app/constants/path-names';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ICountry, IState, ICity } from 'country-state-city';
import { ConfigService } from 'src/app/services/config.service';
import { RegionInfo } from 'src/app/models/regions/region-info';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-create-region',
  templateUrl: './create-region.component.html',
  styleUrls: ['./create-region.component.scss']
})
export class CreateRegionComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) { }


  dropdownStateList = [];
  region = {} as RegionInfo;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    textField: 'name',
  };

  regionForm: FormGroup;
  stateDataSelected = []

  isEdit = false;

  stateList;
  regionId

  selectedCountry: ICountry;
  selectedState: IState;


  ngOnInit(): void {
    this.getPathParam();
    this.fetchStates();
    this.initForm();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.regionId = id
      this.fetchRecord(id);
    }
  }

  fetchRecord(id: string): void {
    const url =
      this.config.json.apiEndpointRdbms + 'region/' + id;

    this.http.get(url).subscribe((data) => {
      this.region = data.data;
      console.log(this.region)
      this.updateForm();
    });
  }

  initForm(): void {
    this.regionForm = new FormGroup({
      regionName: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
    });
  }

  get regionName(): FormControl {
    return this.regionForm.get('regionName') as FormControl;
  }
  get state(): FormControl {
    return this.regionForm.get('state') as FormControl;
  }

  updateForm(): void {
    let stateArray=[]
    this.regionName.setValue(this.region.regionName);
    this.stateDataSelected = this.region['state'];
    this.stateDataSelected = this.stateDataSelected.map((state) => {
      return {
        id: state.id,
        name: state.stateName,
      }});
  }


  updateModel(): void {
    this.region.regionName = this.regionForm.get('regionName').value;
    this.region.states = this.regionForm.get('state').value;
    console.log(this.region)
  }

  persist(): void {
    this.updateModel();
    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'region/create';
    this.http.post(url, this.region).subscribe((data) => {
      if (data.statusCode == 200) {
        this.router.navigate(['/content/statewise-lcn', 'list']);
      } else {
        alert(data.message);
      }
    });
  }

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'region/update/' + this.regionId;
    this.http.put(url, this.region).subscribe((data) => {
      if (data.statusCode == 200) {
        this.router.navigate(['/content/statewise-lcn', 'list']);
      }
      else {
        window.alert(data.message)
      }
    });
  }



  fetchStates() {
    let url = this.config.json.apiEndpoint + 'states/getState'
    this.http.get(url).subscribe(
      (res) => {
        this.stateList = res.data
        this.stateList = this.stateList.map((state) => {
          return {
            id: state.id,
            name: state.stateName,
          };
        })
      })
  }




}
