import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, } from '@angular/core';
import { DashboardDataService } from '../dashboard-service/dashboard-data.service';
import { forkJoin, Subscription } from 'rxjs';
import { DashboardTopChannelInfo } from 'src/app/models/dashboard/dashboard-top-channel-info';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color, SingleDataSet } from 'ng2-charts';
import { AuthService } from 'src/app/services/auth.service';
import { plugins } from 'chart.js';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import * as Chart from 'chart.js';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss'],
})
export class DashboardHomeComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private data: DashboardDataService,
    private authService: AuthService,
    public config: ConfigService,
    public router: Router,
    private http: HttpClientWrapperService
  ) {
  }
  refreshSubscription: Subscription

  ngOnInit(): void {
    this.refreshClicked()
    this.refreshSubscription = this.authService.refresh$.subscribe(() => {
      console.log(this.refreshSubscription)
      this.destroyAllCharts()
      this.userId = atob(window.localStorage.getItem('id'))
      this.getCountData();
      this.getTableTop5Data();
      this.getTopCardDetails();
      if (this.config.json.userType == "MSO") {
        this.userType = true
      } else {
        this.userType = false
        this.getNotifications()
      }
    });
    this.userId = atob(window.localStorage.getItem('id'))

    // this.createChart();
    if (this.config.json.userType == "MSO") {
      this.userType = true
    } else {
      this.userType = false
      this.getNotifications()
    }
  }

  chart1
  chart2
  chart3
  chart4

  routetoSubscriberList() {
    if(this.config.json.userType=="MSO"){
      this.router.navigateByUrl('/reports/trai/report-subscriber-list-new')
    }
  }

  routetoActive() {
    if(this.config.json.userType=="MSO"){
    this.router.navigateByUrl('/reports/trai/report-subscriberwise-subscription-active')
    }
  }

  routetostb() {
    if(this.config.json.userType=="MSO"){
    this.router.navigateByUrl('/reports/trai/report-stb-list')
    }
  }
  refreshClicked() {
    let url = this.config.json.apiEndpoint + 'dashboard/refresh'
    this.http.get(url).subscribe()

  }

  ngAfterViewInit() {
    console.log("calling ng after view")
    // this.createChartProperties();
    // setTimeout(() => {
    //     this.createChart(this.labels, this.datasets);
    //     this.createChartSTB(this.labelsSTB, this.datasetsSTB);
    // }, 0);
    setTimeout(() => {
      this.getCountData();
      this.getTableTop5Data();
      this.getTopCardDetails();
    }, 500);
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.updateAllCharts()
      }
    });
    const resizeHandler = () => {
      console.log("visibility")
      this.updateAllCharts();
    };

    const debounce = (func: () => void, timeout: number = 300) => {
      let timer: any;
      return () => {
        clearTimeout(timer);
        timer = setTimeout(func, timeout);
      };
    };
    window.addEventListener('resize', debounce(resizeHandler));

    window.addEventListener('resize', () => {
      this.destroyAllCharts();
      this.recreateAllCharts();
    });
  }

  destroyAllCharts() {

    if (this.chart1) this.chart1.destroy();
    if (this.chart2) this.chart2.destroy();
    if (this.chart3) this.chart3.destroy();
    if (this.chart4) this.chart4.destroy();
  }

  recreateAllCharts() {
    this.createChart(['Active', 'Inactive'], this.datasets);
    this.createChartSTB(['Active', 'Inactive', 'Blacklist'], this.dataset2);
    this.createhalfPiechart(['With Subscription', 'No Subscription'], this.dataset3);
    this.createhalfPiechartSTB(['Active Stbs', 'Online Stbs'], this.dataset4);
  }

  userType = false
  userId
  selectedPeriodRegistration = '1d'
  selectedPeriodRevenue = '1d'
  selectedPeriodSUbscription = '1d'
  selectedPeriodExpiry = '1d'
  sortOrder = true


  tableData = []
  combinedData = []
  countData = []
  totalSubscriberCount
  totalStbCount
  stbActiveCount
  totalSubscription
  walletBalances = {}
  revenueDetails = []
  registrationDetails = []
  expiryDetails = []
  susbscriptionDetails = []

  allPieChartType: ChartType = 'doughnut';
  allPieChartLegend = false;
  subscriberPieChartData: number[] = [45000, 15000];

  subscriberPieChartLabels: Label[] = ['Active', 'Inactive'];
  subscriberPieChartColors: Color[] = [
    {
      backgroundColor: ['#5a85e3', '#5c5c5c']
    }
  ];

  stbPieChartLabels: Label[] = ['Active', 'Inactive', 'Blacklisted'];
  stbPieChartData: number[] = [70, 30, 40];
  stbPieChartColors: Color[] = [
    {
      backgroundColor: [
        '#5abee3',
        'rgba(152, 173, 94, 0.8)',
        '#5c5c5c',
      ],
    },
  ];

  public activesubscriberPieChartLabels: string[] = ['With Subscription', 'No Subscription'];
  public activePieChartData: number[] = [30, 15];
  public activePieChartType: string = 'doughnut';
  public stbActivePieChartLabels: string[] = ['Active Stbs', 'Online Stbs'];
  public stbActivePieChartData: number[] = [30, 15];


  public activePieChartColors: any[] = [
    {
      backgroundColor: ['#5a85e3', '#4325bf'],
    }
  ];

  activestbPieChartColors: any[] = [
    {
      backgroundColor: ['#5abee3', '#3abf25'],
    }
  ];


  activePieChartLegend: boolean = false;



  setPeriodRegistration(period: string) {
    this.selectedPeriodRegistration = period;
  }

  setPeriodRevenue(period: string) {
    this.selectedPeriodRevenue = period;
  }

  setPeriodSubscription(period: string) {
    this.selectedPeriodSUbscription = period;
  }

  setPeriodExpiry(period: string) {
    this.selectedPeriodExpiry = period;
  }

  getCountData() {
    let url = this.config.json.apiEndpoint + 'dashboard/countDetails?userType=' + this.config.json.userType.toLowerCase() + '&userId=' + this.userId
    this.http.get(url).subscribe(
      res => {
        this.countData = res.data
        this.handleSubscribersPieChart(this.countData['subscriberStatusCount'])
        this.handleStbPieChart(this.countData['stbStatusCount'])
        this.handleactivePieChart(this.countData['activeSubscriberCountForSubscription'])
        this.handleactiveSTbPieChart(this.countData['stbActiveStatusCount'])
        this.walletBalances = this.countData['unusedWalletBalance']
      }
    )
  }
  notificationData = []


  getNotifications() {
    let url = this.config.json.apiEndpoint + 'notifications?userType=' + this.config.json.userType.toLowerCase() + '&userId=' + this.userId
    // let url = this.config.json.apiEndpoint + 'notifications?userType=lco' + '&userId=' + 1
    this.http.get(url).subscribe(
      res => {
        this.notificationData = res.data
      }
    )
  }

  getTopCardDetails() {
    let url = this.config.json.apiEndpoint + 'dashboard/topCardDetails?userType=' + this.config.json.userType.toLowerCase() + '&userId=' + this.userId
    this.http.get(url).subscribe(
      res => {
        this.revenueDetails = res.data.revenue
        this.registrationDetails = res.data.registration
        this.expiryDetails = res.data.expiry
        this.susbscriptionDetails = res.data.subscription
      }
    )
  }



  getTableTop5Data() {
    this.tableData = []
    let order = ''
    if (this.sortOrder) {
      order = 'DESC'
    }
    else {
      order = 'ASEC'
    }
    let url = this.config.json.apiEndpoint + 'dashboard/topOrLeastDetails?userType=' + this.config.json.userType.toLowerCase() +
      '&userId=' + this.userId + '&displayOrder=' + order
    this.http.get(url).subscribe(
      res => {
        this.tableData = res.data
        this.combineData()
        // console.log(this.tableData['lcosBySubscriberCount'][0].lcoName)
      },
      err => {
        console.log(err)
      }
    )
  }

  combineData() {
    this.combinedData = []
    for (let i = 0; i < 5; i++) {
      this.combinedData.push([
        this.tableData['lcosBySubscriberCount'][i] || {},
        this.tableData['lcosByRevenueAmount'][i] || {},
        this.tableData['bouquetBySubscriptionCount'][i] || {},
        this.tableData['channelByWatchRate'][i] || {}
      ]);
    }
  }



  // ngAfterViewInit(): void {
  //   this.handleSubscribersPieChart(this.countData['subscriberStatusCount'])
  //   this.handleStbPieChart(this.countData['stbStatusCount'])
  //   this.handleactivePieChart(this.countData['activeSubscriberCountForSubscription'])
  //   this.handleactiveSTbPieChart(this.countData['stbActiveStatusCount'])
  // }

  datasets = [
    {
      "data": [50, 60],
      "backgroundColor": ['#5a85e3', '#5c5c5c']
    }
  ]
  dataset2 = [
    {
      "data": [50, 60, 80],
      "backgroundColor": ['#5abee3',
        'rgba(152, 173, 94, 0.8)',
        '#5c5c5c']
    }
  ]
  dataset3 = [
    {
      "data": [50, 60],
      "backgroundColor": ['#5a85e3', '#4325bf']
    }
  ]
  dataset4 = [
    {
      "data": [50, 60],
      "backgroundColor": ['#5abee3', '#3abf25'],
    }
  ]
  handleSubscribersPieChart(data) {
    this.subscriberPieChartData = [data.activeSubscriberCount, data.inactiveSubscriberCount];
    // this.subscriberPieChartData =[1000,6900]

    this.totalSubscriberCount = data.totalSubscriberCount
    if (this.totalSubscriberCount > 1000) {
      this.totalSubscriberCount = (this.totalSubscriberCount / 1000).toFixed(1) + 'k';
    } else {
      this.totalSubscriberCount = this.totalSubscriberCount
    }
    this.datasets[0]['data'] = this.subscriberPieChartData
    this.createChart(['Active', 'Inactive'], this.datasets);
  }



  handleStbPieChart(data) {
    this.stbPieChartData = [data.activeStbCount, data.inactiveStbCount, data.blacklistCount];
    this.totalStbCount = data.totalStbCount

    if (this.totalStbCount > 1000) {
      this.totalStbCount = (this.totalStbCount / 1000).toFixed(1) + 'k';
    } else {
      this.totalStbCount = this.totalStbCount
    }
    this.dataset2[0]['data'] = this.stbPieChartData
    this.createChartSTB(['Active', 'Inactive', 'Blacklist'], this.dataset2);
  }

  handleactivePieChart(data) {
    this.activePieChartData = [data.withSubscription, data.withoutSubscription];
    // this.activePieChartData = [100,200]
    this.totalSubscription = data.totalSubscriberCount

    this.dataset3[0]['data'] = this.activePieChartData
    // dataset[0]['data'] = [1,0]
    if (this.totalSubscription > 1000) {
      this.totalSubscription = (this.totalSubscription / 1000).toFixed(1) + 'k';
    } else {
      this.totalSubscription = this.totalSubscription
    }
    this.createhalfPiechart(['With Subscription', 'No Subscription'], this.dataset3);

  }

  handleactiveSTbPieChart(data) {
    this.stbActivePieChartData = [data.activeStbCount, data.liveStbCount];
    this.stbActiveCount = data.totalStbCount

    // dataset[0]['data'] = [1,0]
    this.dataset4[0]['data'] = this.stbActivePieChartData

    if (this.stbActiveCount > 1000) {
      this.stbActiveCount = (this.stbActiveCount / 1000).toFixed(1) + 'k';
    } else {
      this.stbActiveCount = this.stbActiveCount
    }

    this.createhalfPiechartSTB(['Active Stbs', 'Online Stbs'], this.dataset4);
    this.createChartProperties();

  }

  allZeroSub = false
  allZeroSTB = false
  allZeroSubhalf = false
  allZeroSTBhalf = false

  createChart(labels: string[], datasets: any[]): void {
    const allZero = datasets.every((dataset: any) => dataset.data.every((value: number) => value === 0));
    if (allZero) {
      datasets.forEach((dataset: any) => {
        dataset.backgroundColor = 'transparent';
        dataset.borderColor = 'black';
        dataset.data = [0, 0, 1];
        this.allZeroSub = true
      });

    }
    this.chart1 = new Chart('myDoughnutChart', {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        tooltips: {
          enabled: false
        },
        responsive: true,
        maintainAspectRatio: false,
        // layout: {
        //   padding: {
        //     top: 35,
        //     bottom: 35,
        //     right:35,
        //     left:35
        //   }
        // },
        layout: {
          padding: {
            top: 35,
            bottom: 40,
            right: 35,
            left: 35
          }
        },
        legend: {
          display: false
        },
        hover: {
          mode: null,
        },
        rotation: 0,
        circumference: 2 * Math.PI,
      },

      plugins: [{
        beforeDraw: (chart) => {
          const ctx = chart.ctx;
          const chartArea = chart.chartArea;
          const total = this.totalSubscriberCount
          ctx.save();
          ctx.font = 'bold 16px sans-serif';
          ctx.fillStyle = '#5a85e3';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(total, (chartArea.left + chartArea.right) / 2, (chartArea.top + chartArea.bottom) / 2);
          ctx.font = 'normal 8px sans-serif';
          ctx.fillStyle = '#000000';
          ctx.fillText("Total", (chartArea.left + chartArea.right) / 2, (chartArea.top + chartArea.bottom) / 2 + 16);
          ctx.restore();
        }
      }]
    });
  }


  //   createChart(labels: string[], datasets: any[]): void {
  //     const allZero = datasets.every((dataset: any) => dataset.data.every((value: number) => value === 0));
  //     if (allZero) {
  //         datasets.forEach((dataset: any) => {
  //             dataset.backgroundColor = 'transparent';
  //             dataset.borderColor = 'black';
  //             dataset.data = [0, 0, 1];
  //             this.allZeroSub = true;
  //         });
  //     }
  //     const chart = new Chart('myDoughnutChart', {
  //         type: 'doughnut',
  //         data: {
  //             labels: labels,
  //             datasets: datasets
  //         },
  //         options: {
  //             responsive: true,
  //             maintainAspectRatio: false,
  //             layout: {
  //                 padding: this.calculateDynamicPadding()
  //             },
  //             legend: {
  //                 display: false
  //             }
  //         },
  //         plugins: [{
  //             beforeDraw: (chart) => {
  //                 const ctx = chart.ctx;
  //                 const chartArea = chart.chartArea;
  //                 const total = this.totalSubscriberCount;
  //                 ctx.save();
  //                 ctx.font = 'bold 16px sans-serif';
  //                 ctx.fillStyle = '#5a85e3';
  //                 ctx.textAlign = 'center';
  //                 ctx.textBaseline = 'middle';
  //                 ctx.fillText(total, (chartArea.left + chartArea.right) / 2, (chartArea.top + chartArea.bottom) / 2);
  //                 ctx.font = 'normal 8px sans-serif';
  //                 ctx.fillStyle = '#000000';
  //                 ctx.fillText("Total", (chartArea.left + chartArea.right) / 2, (chartArea.top + chartArea.bottom) / 2 + 16);
  //                 ctx.restore();
  //             }
  //         }]
  //     });

  //     const resizeHandler = () => {
  //         chart.options.layout.padding = this.calculateDynamicPadding();
  //         chart.update();
  //     };

  //     // Debounce function to limit the frequency of resize events
  //     const debounce = (func: () => void, timeout: number = 300) => {
  //         let timer: any;
  //         return () => {
  //             clearTimeout(timer);
  //             timer = setTimeout(func, timeout);
  //         };
  //     };

  //     window.addEventListener('resize', debounce(resizeHandler));

  //     // Handle visibility change to resize chart when returning from inspect mode
  //     document.addEventListener('visibilitychange', () => {
  //         if (document.visibilityState === 'visible') {
  //             resizeHandler();
  //         }
  //     });
  // }



  createChartSTB(labels: string[], datasets: any[]): void {
    const allZero = datasets.every((dataset: any) => dataset.data.every((value: number) => value === 0));
    if (allZero) {
      datasets.forEach((dataset: any) => {
        dataset.backgroundColor = 'transparent';
        dataset.borderColor = 'black';
        dataset.data = [0, 0, 1];
        this.allZeroSTB = true
      });
    }
    this.chart2 = new Chart('myDoughnutChartSTB', {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        tooltips: {
          enabled: false
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 35,
            bottom: 40,
            right: 35,
            left: 35
          }
        },
        legend: {
          display: false
        },
        hover: {
          mode: null
        },
        plugins: {
          datalabels: {
            color: 'red',
          }
        },
        rotation: 0,
        circumference: 2 * Math.PI
      },
      plugins: [{
        beforeDraw: (chart) => {
          const ctx = chart.ctx;
          const chartArea = chart.chartArea;
          const total = this.totalStbCount
          ctx.save();
          ctx.font = 'bold 16px sans-serif';
          ctx.fillStyle = '#67c3e5';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(total, (chartArea.left + chartArea.right) / 2, (chartArea.top + chartArea.bottom) / 2);
          ctx.font = 'normal 8px sans-serif';
          ctx.fillStyle = '#000000';
          ctx.fillText("Total", (chartArea.left + chartArea.right) / 2, (chartArea.top + chartArea.bottom) / 2 + 16);
          ctx.restore();
        }
      }]
    });
  }

  calculateDynamicPadding(): any {
    const screenWidth = window.innerWidth;
    const padding = Math.round(screenWidth * 0.03); // Example: 5% of screen width
    return {
      top: padding,
      bottom: padding,
      right: padding,
      left: padding
    };
  }
  createhalfPiechart(labels: string[], datasets: any[]): void {
    const allZero = datasets.every(dataset => dataset.data.every(value => value === 0));

    if (allZero) {
      datasets.forEach(dataset => {
        dataset.backgroundColor = 'transparent';
        dataset.borderColor = 'black';
        dataset.data = [0, 0, 1];
        this.allZeroSubhalf = true
      });
    }
    this.chart3 = new Chart('myDoughnutCharthalf', {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        tooltips: {
          enabled: false
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 35,
            bottom: 15,
            right: 35,
            left: 35
          }
        },
        legend: {
          display: false
        },
        hover: {
          mode: null // Disable hover effects
        },
        rotation: -Math.PI,
        circumference: Math.PI,
      },
      plugins: [{
        afterDraw: (chart) => {
          const ctx = chart.ctx;
          const width = chart.width;
          const height = chart.height / 1.2;
          ctx.restore();
          const total = this.totalSubscription
          ctx.font = 'bold 14px Arial';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#826fd4';
          const text = `${total}`;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]
    })
  }

  updateAllCharts() {
    if (this.chart1) this.chart1.update();
    if (this.chart2) this.chart2.update();
    if (this.chart3) this.chart3.update();
    if (this.chart4) this.chart4.update();
  }

  createhalfPiechartSTB(labels: string[], datasets: any[]): void {
    const allZero = datasets.every(dataset => dataset.data.every(value => value === 0));

    if (allZero) {
      datasets.forEach(dataset => {
        dataset.backgroundColor = 'transparent';
        dataset.borderColor = 'black';
        dataset.data = [0, 0, 1]; // Ensures empty half-circle
        this.allZeroSTBhalf = true;
      });
    }
    this.chart4 = new Chart('myDoughnutCharthalfSTB', {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        tooltips: {
          enabled: false
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 35,
            bottom: 15,
            right: 35,
            left: 35
          }
        },
        legend: {
          display: false
        },
        hover: {
          mode: null
        },

        rotation: -Math.PI,
        circumference: Math.PI,
      },
      plugins: [{
        afterDraw: (chart) => {
          const ctx = chart.ctx;
          const width = chart.width;
          const height = chart.height / 1.2;
          ctx.restore();
          const total = this.stbActiveCount
          ctx.font = 'bold 14px Arial';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#88d0ea';
          const text = `${total}`;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]
    })
    // window.addEventListener('resize', () => {
    //   chart.options.layout.padding = this.calculateDynamicPadding();
    //   chart.update();
    // });
  }

  createChartProperties() {

    // Chart.plugins.register({
    //   afterDatasetsDraw:  (chart) =>{
    //     const ctx = chart.ctx;
    //     const chartArea = chart.chartArea;
    //     const allZero = chart.data.datasets.every((dataset: { data: number[] }) =>
    //       dataset.data.every((value: number) => 
    //         {value === 0
    //           // console.log(value)
    //   })
    //     );

    //     // if (this.allZeroSTB) {
    //     //   return;
    //     // }
    //     // else if(this.allZeroSTBhalf){
    //     //   return
    //     // }
    //     // else if(this.allZeroSub){
    //     //   return
    //     // }
    //     // else if(this.allZeroSubhalf){
    //     //   return
    //     // }


    //     chart.data.datasets.forEach(function (dataset, i) {
    //       const meta = chart.getDatasetMeta(i);
    //       meta.data.forEach(function (element, index) {
    //         let dataLabel = String(chart.data.labels[index]);
    //         let dataValue: any = +(dataset.data[index]);
    //         if (dataValue > 1000) {
    //           dataValue = (dataValue / 1000).toFixed(1) + 'k';
    //         } else {
    //           dataValue = dataValue
    //         }

    //         const innerRadius = element._model.innerRadius;
    //         const outerRadius = element._model.outerRadius;
    //         const middleRadius = (innerRadius + outerRadius) / 2;
    //         const angle = (element._model.startAngle + element._model.endAngle) / 2;

    //         const circleX = element._model.x + Math.cos(angle) * middleRadius;
    //         const circleY = element._model.y + Math.sin(angle) * middleRadius;
    //         const circleRadius = 3;


    //         // Draw the small circle
    //         ctx.fillStyle = 'white';
    //         ctx.beginPath();
    //         ctx.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI);
    //         ctx.fill();

    //         // Calculate the end of the line position
    //         const lineStartX = circleX + Math.cos(angle) * circleRadius;
    //         const lineStartY = circleY + Math.sin(angle) * circleRadius;
    //         const offset = 20;
    //         let lineEndX = element._model.x + Math.cos(angle) * (outerRadius + offset);
    //         let lineEndY = element._model.y + Math.sin(angle) * (outerRadius + offset);

    //         // Measure the label width to adjust the position accordingly
    //         ctx.font = Chart.helpers.fontString(13, 'normal', 'Helvetica Neue');
    //         const label = `${dataLabel}`;
    //         const valueWidth = ctx.measureText(dataValue).width;
    //         const labelWidth = ctx.measureText(label).width;

    //         // Check if the label is inside the chart area or overlaps with the chart
    //         if (
    //           (lineEndX > chartArea.left && lineEndX < chartArea.right && lineEndY > chartArea.top && lineEndY < chartArea.bottom) ||
    //           (angle > Math.PI && lineEndX - Math.max(valueWidth, labelWidth) < chartArea.left) || // Left side overflow
    //           (angle <= Math.PI && lineEndX + Math.max(valueWidth, labelWidth) > chartArea.right)  // Right side overflow
    //         ) {
    //           // Adjust the label position to be further outside the chart area
    //           const newOffset = offset + 40; // increase the offset more for long labels
    //           lineEndX = element._model.x + Math.cos(angle) * (outerRadius + newOffset);
    //           lineEndY = element._model.y + Math.sin(angle) * (outerRadius + newOffset);
    //         }

    //         // Draw the line from the small circle to the label position
    //         ctx.strokeStyle = 'gray';
    //         ctx.beginPath();
    //         ctx.moveTo(lineStartX, lineStartY);
    //         ctx.lineTo(lineEndX, lineEndY);
    //         ctx.stroke();

    //         // Position the label and value
    //         ctx.textAlign = angle > Math.PI ? 'right' : 'left';
    //         ctx.textBaseline = 'middle';
    //         ctx.fillStyle = 'black';

    //         // Draw the value (e.g., "15k")
    //         ctx.fillText(dataValue, lineEndX, lineEndY - 5);

    //         // Draw the label (e.g., "Inactive") below the value
    //         ctx.fillText(dataLabel, lineEndX, lineEndY + 10);
    //       });
    //     });
    //   },

    // });


    // Chart.plugins.register({
    //   afterDatasetsDraw: (chart) => {
    //     const ctx = chart.ctx;
    //     const chartArea = chart.chartArea;

    //     chart.data.datasets.forEach((dataset, i) => {
    //       const meta = chart.getDatasetMeta(i);
    //       meta.data.forEach(function (element, index) {
    //         let dataLabel = String(chart.data.labels[index]);
    //         let dataValue: any = +(dataset.data[index]);
    //         if (dataValue > 1000) {
    //           dataValue = (dataValue / 1000).toFixed(1) + 'k';
    //         } else {
    //           dataValue = dataValue
    //         }

    //         const innerRadius = element._model.innerRadius;
    //         const outerRadius = element._model.outerRadius;
    //         const middleRadius = (innerRadius + outerRadius) / 2;
    //         const angle = (element._model.startAngle + element._model.endAngle) / 2;

    //         const circleX = element._model.x + Math.cos(angle) * middleRadius;
    //         const circleY = element._model.y + Math.sin(angle) * middleRadius;
    //         const circleRadius = 3;

    //         // Draw the small circle
    //         ctx.fillStyle = 'white';
    //         ctx.beginPath();
    //         ctx.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI);
    //         ctx.fill();

    //         // Calculate the end of the line position
    //         const lineStartX = circleX + Math.cos(angle) * circleRadius;
    //         const lineStartY = circleY + Math.sin(angle) * circleRadius;
    //         let offset = 15;
    //         let lineEndX = element._model.x + Math.cos(angle) * (outerRadius + offset);
    //         let lineEndY = element._model.y + Math.sin(angle) * (outerRadius + offset);

    //         // Draw the line from the small circle to the outer area of the circle
    //         ctx.strokeStyle = 'gray';
    //         ctx.beginPath();
    //         ctx.moveTo(lineStartX, lineStartY);
    //         ctx.lineTo(lineEndX, lineEndY);

    //         const horizontalLineLength = 15;
    //         if (angle > Math.PI) { // Left side
    //           ctx.lineTo(lineEndX - horizontalLineLength, lineEndY);
    //           lineEndX -= horizontalLineLength;
    //         } else { // Right side
    //           ctx.lineTo(lineEndX + horizontalLineLength, lineEndY);
    //           lineEndX += horizontalLineLength;
    //         }
    //         ctx.stroke();

    //         ctx.textAlign = angle > Math.PI ? 'right' : 'left';
    //         ctx.textBaseline = 'middle';
    //         ctx.fillStyle = 'black';

    //         const centerX = element._model.x;
    //         const centerY = element._model.y;
    //         const distanceFromCenter = Math.sqrt(Math.pow(lineEndX - centerX, 2) + Math.pow(lineEndY - centerY, 2));
    //         const minDistance = middleRadius + offset + horizontalLineLength;
    //         if (distanceFromCenter < minDistance) {
    //           offset += (minDistance - distanceFromCenter);
    //           lineEndX = element._model.x + Math.cos(angle) * (outerRadius + offset + horizontalLineLength);
    //           lineEndY = element._model.y + Math.sin(angle) * (outerRadius + offset + horizontalLineLength);
    //         }
    //         ctx.fillText(dataValue, lineEndX, lineEndY - 10);
    //         ctx.fillText(dataLabel, lineEndX, lineEndY + 10);
    //       });
    //     });
    //   },
    // });
    // Chart.plugins.register({
    //   afterDatasetsDraw: (chart) => {
    //     const ctx = chart.ctx;
    //     const chartArea = chart.chartArea;

    //     chart.data.datasets.forEach((dataset, i) => {
    //       const meta = chart.getDatasetMeta(i);
    //       meta.data.forEach(function (element, index) {
    //                 let dataLabel = String(chart.data.labels[index]);
    //                 let dataValue: any = +(dataset.data[index]);
    //                 if (dataValue > 1000) {
    //                   dataValue = (dataValue / 1000).toFixed(1) + 'k';
    //                 } else {
    //                   dataValue = dataValue
    //                 }

    //         const innerRadius = element._model.innerRadius;
    //         const outerRadius = element._model.outerRadius;
    //         const middleRadius = (innerRadius + outerRadius) / 2;
    //         const angle = (element._model.startAngle + element._model.endAngle) / 2;

    //         const circleX = element._model.x + Math.cos(angle) * middleRadius;
    //         const circleY = element._model.y + Math.sin(angle) * middleRadius;
    //         const circleRadius = 3;

    //         ctx.fillStyle = 'white'; 
    //         ctx.beginPath();
    //         ctx.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI);
    //         ctx.fill();
    //         const lineStartX = circleX + Math.cos(angle) * circleRadius;
    //         const lineStartY = circleY + Math.sin(angle) * circleRadius;
    //         let offset = 15;
    //         let lineEndX = element._model.x + Math.cos(angle) * (outerRadius + offset);
    //         let lineEndY = element._model.y + Math.sin(angle) * (outerRadius + offset);
    //         ctx.strokeStyle = 'gray';
    //         ctx.beginPath();
    //         ctx.moveTo(lineStartX, lineStartY);
    //         ctx.lineTo(lineEndX, lineEndY);
    //         const horizontalLineLength = 15;
    //         if (angle > Math.PI) {
    //           ctx.lineTo(lineEndX - horizontalLineLength, lineEndY);
    //           lineEndX -= horizontalLineLength;
    //         } else { 
    //           ctx.lineTo(lineEndX + horizontalLineLength, lineEndY);
    //           lineEndX += horizontalLineLength;
    //         }
    //         ctx.stroke();
    //         ctx.textAlign = angle > Math.PI ? 'right' : 'left';
    //         ctx.textBaseline = 'middle';
    //         ctx.fillStyle = 'black'; // Label color
    //         const centerX = element._model.x;
    //         const centerY = element._model.y;
    //         const distanceFromCenter = Math.sqrt(Math.pow(lineEndX - centerX, 2) + Math.pow(lineEndY - centerY, 2));
    //         const minDistance = middleRadius + offset + horizontalLineLength;
    //         if (distanceFromCenter < minDistance) {
    //           offset += (minDistance - distanceFromCenter);
    //           lineEndX = element._model.x + Math.cos(angle) * (outerRadius + offset + horizontalLineLength);
    //           lineEndY = element._model.y + Math.sin(angle) * (outerRadius + offset + horizontalLineLength);
    //         }
    //         ctx.fillText(dataValue, lineEndX, lineEndY - 10);
    //         ctx.fillText(dataLabel, lineEndX, lineEndY + 10);
    //       });
    //     });
    //   },
    // });

    // Chart.plugins.register({
    //   beforeDatasetDraw: (chart) => {


    //   }
    // })

    Chart.plugins.register({
      afterDatasetsDraw: (chart) => {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;

        chart.data.datasets.forEach((dataset, i) => {
          const meta = chart.getDatasetMeta(i);
          meta.data.forEach(function (element, index) {
            let dataLabel = String(chart.data.labels[index]);
            let dataValue: any = +(dataset.data[index]);
            if (dataValue > 1000) {
              dataValue = (dataValue / 1000).toFixed(1) + 'k';
            } else {
              dataValue = dataValue;
            }

            const innerRadius = element._model.innerRadius;
            const outerRadius = element._model.outerRadius;
            const middleRadius = (innerRadius + outerRadius) / 2;
            const angle = (element._model.startAngle + element._model.endAngle) / 2;

            const circleX = element._model.x + Math.cos(angle) * middleRadius;
            const circleY = element._model.y + Math.sin(angle) * middleRadius;
            const circleRadius = 3;

            ctx.fillStyle = 'white';
            ctx.beginPath();
            ctx.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI);
            ctx.fill();

            const lineStartX = circleX + Math.cos(angle) * circleRadius;
            const lineStartY = circleY + Math.sin(angle) * circleRadius;
            let offset = 15;
            let lineEndX = element._model.x + Math.cos(angle) * (outerRadius + offset);
            let lineEndY = element._model.y + Math.sin(angle) * (outerRadius + offset);

            ctx.strokeStyle = 'gray';
            ctx.lineWidth = 1.5; // Make the line slightly thicker
            ctx.beginPath();
            ctx.moveTo(lineStartX, lineStartY);
            ctx.lineTo(lineEndX, lineEndY);

            const horizontalLineLength = 15;
            if (angle > Math.PI) {
              ctx.lineTo(lineEndX - horizontalLineLength, lineEndY);
              lineEndX -= horizontalLineLength;
            } else {
              ctx.lineTo(lineEndX + horizontalLineLength, lineEndY);
              lineEndX += horizontalLineLength;
            }
            ctx.stroke();

            ctx.textAlign = angle > Math.PI ? 'right' : 'left';
            ctx.textBaseline = 'middle';

            ctx.fillStyle = 'Black';
            ctx.font = '14px Arial';

            const centerX = element._model.x;
            const centerY = element._model.y;
            const distanceFromCenter = Math.sqrt(Math.pow(lineEndX - centerX, 2) + Math.pow(lineEndY - centerY, 2));
            const minDistance = middleRadius + offset + horizontalLineLength;

            if (distanceFromCenter < minDistance) {
              offset += (minDistance - distanceFromCenter);
              lineEndX = element._model.x + Math.cos(angle) * (outerRadius + offset + horizontalLineLength);
              lineEndY = element._model.y + Math.sin(angle) * (outerRadius + offset + horizontalLineLength);
            }

            ctx.fillText(dataValue, lineEndX, lineEndY - 10);
            ctx.fillStyle = 'gray';
            ctx.font = '10px Arial';
            ctx.fillText(dataLabel, lineEndX, lineEndY + 10);
          });
        });
      },
    });



  }



  ngOnDestroy() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }


}
