import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';

@Component({
  selector: 'app-logs-user-activity',
  templateUrl: './logs-user-activity.component.html',
  styleUrls: ['./logs-user-activity.component.scss']
})
export class LogsUserActivityComponent implements OnInit {

  reportList = [];

  lcoList: LCOInfo[] = [];
  from:any;
  to:any;

  currentDate = new Date();

  // Changed to string, to save the email of selected LCO, since that is what we need for api request
  selectedLco

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    this.fetchLcoList();
    let dateObj = new Date()
    let todate = dateObj.getFullYear()+"-"+(dateObj.getMonth()+1 > 10 ? dateObj.getMonth()+1 : "0"+(dateObj.getMonth()+1))+"-"+(dateObj.getDate() > 10 ? dateObj.getDate() : "0"+(dateObj.getDate()))
    let body = {
      'fromDate' : '2018-01-01',
      'toDate' : todate,
      'email' : '0'
    }
    this.fetchData(body)
  }

  loadingFlag=false
  isLoad = 'Loading'


  fetchLcoList(): void {
    this.report.getLCOList().subscribe(data => {
      this.lcoList = data.data;
    });
  }

  fetchData(body): void {

    this.report.getUserActivityHistory(body).subscribe(data => {
      // Convenient to map the response directly, since we know response is list of JSON objects
      this.reportList = data.data;
      if(this.reportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }

  lcoSelect(): void {
    // Getting the selected start and end date from service, whose value is updated in dateChange function
    this.loadingFlag = true
    // // console.log(this.selectedLco)
    let startMonth = this.eve.startDate.month()>10 ? this.eve.startDate.month() : "0"+this.eve.startDate.month()
    let startdate = this.eve.startDate.date()>10 ? this.eve.startDate.date() : "0"+this.eve.startDate.date()
    let endMonth = this.eve.endDate.month()>10 ? this.eve.endDate.month() : "0"+this.eve.endDate.month()
    let enddate = this.eve.endDate.date()>10 ? this.eve.endDate.date() : "0"+this.eve.endDate.date()
    if(this.eve != undefined || this.eve != null){
      var body = {
        'fromDate' : this.eve.startDate.year()+"-"+startMonth+"-"+startdate,
        'toDate' : this.eve.endDate.year()+"-"+endMonth+"-"+enddate,
        'email' : this.selectedLco.email? this.selectedLco.email : '0'
      }
    }else{
      let dateObj = new Date()
    let todate = dateObj.getFullYear()+"-"+(dateObj.getMonth()+1 > 10 ? dateObj.getMonth()+1 : "0"+(dateObj.getMonth()+1))+"-"+(dateObj.getDate() > 10 ? dateObj.getDate() : "0"+(dateObj.getDate()))
      var body = {
        'fromDate' : '2018-01-01',
        'toDate' : todate,
        'email' : this.selectedLco.email? this.selectedLco.email : '0'
      }
    }

    this.fetchData(body);
  }

  eve
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      // prioritize async ops before sync ops (ex: call api before executing other synchronous code)
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve = ev
      // this.fetchData(ev.startDate, ev.endDate); // async ops
      this.calendar.setDate(ev); //sync ops
      this.loadingFlag = false
      this.isLoad = 'Choose Partner'
    }
  }

  downloadTableExcel(): void {
    let name='User Activity Logs'
    if(this.eve){
      // // console.log('hi')
      let reportDate = this.eve.startDate.day()+"-"+this.doc.monthNameCalculator(this.eve.startDate.month())+"-"+this.eve.startDate.year() +" to "+this.eve.endDate.day()+"-"+this.doc.monthNameCalculator(this.eve.endDate.month())+"-"+this.eve.endDate.year()
      this.doc.exportTableToExcelwithImage(this.reportTable, name,'user_activity_logs', undefined, undefined, 'Report Date :',reportDate,"Partner Name :", this.selectedLco != 0?  this.selectedLco.name : 'All');
    }else{
      // // console.log('hello')
      this.doc.exportTableToExcelwithImage(this.reportTable, name,'user_activity_logs', undefined, undefined, 'Report Date :','Start to Now', "Partner Name :", this.selectedLco != 0 ? this.selectedLco.name : 'All');
    }
  }

  downloadTablePdf(): void {
    if(this.eve){
      // // console.log(this.selectedLco? 'true' : 'false', this.selectedLco.name)
      this.pdf.generatePdfHtml('#reportTable',
      'user_activity_logs',
      `From Date: ${this.eve.startDate.day()+"-"+this.doc.monthNameCalculator(this.eve.startDate.month())+"-"+this.eve.startDate.year()}     To Date: ${this.eve.endDate.day()+"-"+this.doc.monthNameCalculator(this.eve.endDate.month())+"-"+this.eve.endDate.year()}     Partner Name: ${this.selectedLco != 0? this.selectedLco.name : 'All'}`, 'portrait'
      );
    }else{
      // // console.log('hey')
      this.pdf.generatePdfHtml('#reportTable',
      'user_activity_logs',
      `From Start to Now    Partner Name: ${this.selectedLco? this.selectedLco.name : 'All'}`, 'portrait'
      );
    }
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Sl No.","Id", "User email", "Activity Type", "Activity Description","Module name","Date"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo : i,
        id : line.id,
        email: line.userEmail,
        type: line.activityType,
        activity: line.activityDescription,
        module: line.moduleName,
        date: line.createdDate,
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'user_activity_logs', options);
  }

}
