import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdManagerComponent } from './ad-manager/ad-manager.component';

const routes: Routes = [
    {
        path: 'ad-upload',
        component: AdManagerComponent
    },
    {
        path: '',
        redirectTo: 'ad-upload'
    },
    {
        path: '**',
        component: AdManagerComponent
    }
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdManagerRoutingModule { }