import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-report-broadcaster-channel-wise',
  templateUrl: './report-broadcaster-channel-wise.component.html',
  styleUrls: ['./report-broadcaster-channel-wise.component.scss']
})
export class ReportBroadcasterChannelWiseComponent implements OnInit {

  dateChoosed = false;

  reportList = [];
  bouquetType = 0;
  broadcasterId: number;

  broadcasterList: BroadcasterInfo[] = [];

  selectedDate: any = new Date();

  currentDate = new Date();

  isDateSelected = false
  isBroadcasterSelected = true
  isProductSelected = true

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    // // console.log(this.selectedDate)
    this.selectedDate = this.selectedDate.toISOString().split('T')[0]
    // this.selectedDate=this.selectedDate.toISOString().split('T')[0]
    this.selectedDate = this.selectedDate.substring(0, 7);
    // // console.log(this.selectedDate)
    this.body = {
      'fromDate' : '2018-01-01',
      'toDate':`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2,'0')}-${new Date().getDate()}`,
      'broadcasterId' : this.broadcasterId? this.broadcasterId : '0',
      'productType' : 0
    }
    this.fetchData()
    this.fetchLocationList();
  }


  isLoading = false
  fetchLocationList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
      this.broadcasterId = this.broadcasterList[0].id
      // this.fetchData()

    });
  }


  resetReportList(){
      this.reportList = [];
      this.dateChoosed = false;
      this.isLoading = false
  }

  showMonth = false
  showDateRange = false
  dateSelector = true
  tableDisplay = true
  dateTypeSelectors(num) {
    if (num == 0) {
      this.dateSelector = true
      this.showMonth = false
      this.showDateRange = false
      this.tableDisplay = true
      this.resetReportList()
    }
    if (num == 1) {
      this.dateSelector = false
      this.showMonth = true
      this.showDateRange = false
      this.tableDisplay = true
    }
    if (num == 2) {
      this.dateSelector = false
      this.showDateRange = true
      this.showMonth = false
      this.tableDisplay = false
    }
  }
  productType
  selectedProductType() {
    var name = document.getElementById('productTypeSelection') as HTMLSelectElement
    this.productType = name.value
    this.isProductSelected = true;
    if (this.isDateSelected && this.isBroadcasterSelected) {
      this.fetchData()
      this.isLoading = true
      // // console.log("Date", this.isDateSelected, "broad", this.isBroadcasterSelected, "prod", this.isProductSelected)
      }else if(this.isDateSelected){
      //   this.isLoad = 'Select Broadcaster'
      //   // console.log("Date", this.isDateSelected,"broad", this.isBroadcasterSelected,"prod", this.isProductSelected)
      // }else if(this.isBroadcasterSelected){
      //   this.isLoad = 'Select Date'
      //   // console.log("Date", this.isDateSelected,"broad", this.isBroadcasterSelected,"prod", this.isProductSelected)
    } else {
      this.isLoad = 'Select Broadcaster'
      // // console.log("Date", this.isDateSelected, "broad", this.isBroadcasterSelected, "prod", this.isProductSelected)
    }
  }

  isLoad = 'Choose Date'
  dateChanged(e) {
    this.dateChoosed = true;
    this.selectedDate = ''
    this.selectedDate += e.target.value + '-01'
    this.body['monthYear'] = this.selectedDate
    this.body["broadcasterId"] = +this.broadcasterId
    this.body["productType"] = 0
    this.body['searchKeyword'] = this.searchWord? this.searchWord : ''
    this.body['fromDate'] = ''
    this.body['toDate'] = ''
    let day = new Date(this.body['monthYear']+"-01")
    this.reportDate = this.doc.monthNameCalculator(day.getMonth())+" "+day.getFullYear()
    this.isDateSelected = true
      this.fetchData()
      this.isLoading = true
  }

  eve
  body = {}
  searchWord
  reportDate = this.reportList?.length == 0? "Not Selected" : "From Start to Now"
  dateChange(ev) {
    // // console.log(ev.startDate.date(), ev.startDate.month(), ev.startDate.year())
    this.dateChoosed = true;
    this.isDateSelected = true
    if (this.isBroadcasterSelected) {
      this.eve=ev
      let obj = {}
      let startMonth = ev.startDate.month() + 1;
      startMonth = startMonth<10 ? '0'+startMonth : startMonth;
      let startDate = ev.startDate.date()<10 ? '0'+ev.startDate.date() : ev.startDate.date()
      let endMonth = ev.endDate.month() + 1;
      endMonth = endMonth<10 ? '0'+endMonth : endMonth;
      let endDate = ev.endDate.date()<10 ? '0'+ev.endDate.date() : ev.endDate.date()
      obj['fromDate'] = ev.startDate.year() + "-" + startMonth + "-" + startDate
      obj['toDate'] = ev.endDate.year() + "-" + endMonth + "-" + endDate
      obj["broadcasterId"] = +this.broadcasterId
      obj["productType"] = 0
      obj['searchKeyword'] = this.searchWord? this.searchWord : ''
      this.body=obj
      this.reportDate = this.body['fromDate']+" To "+this.body['toDate']
      // // console.log(obj)
      this.report.getBroadcasterWiseChannelsReportMonthly(obj).subscribe(data => {
        this.reportList = data.data;
        if(!this.reportList){
          this.isLoading = false
          this.isLoad = 'No Records'
          return;
        }
        if (this.reportList?.length == 0) {
          this.isLoading = false
          this.isLoad = 'No Records'
        }
      });
    } else {
      this.isLoad = 'Select BroadCaster'
    }

  }

  broadcasterSelected() {
    var name = document.getElementById('broadcasterselected') as HTMLSelectElement
    // // console.log(name.value)
    this.isBroadcasterSelected = true
    this.broadcasterId = parseInt(name.value)
      this.fetchData()
      this.isLoading = true
  }

  broadCasterName // was not neccesary, but done because of emergency
  broadCasterNameSetter(broadcasterId) {
    for (let ele of this.broadcasterList) {
      if (ele.id == broadcasterId) {
        this.broadCasterName = ele.name
      }
    }
    broadcasterId == 0 ? this.broadCasterName = 'All' : this.broadCasterName = this.broadCasterName
  }

  fetchData(): void {
    this.isLoading = true
    this.reportList = []
    // if (this.selectedDate && this.broadcasterId) {
    // // console.log("Date", this.isDateSelected,"broad", this.isBroadcasterSelected,"prod", this.isProductSelected)
    this.broadCasterNameSetter(this.broadcasterId)
    this.body['broadcasterId'] = this.broadcasterId
    // // console.log(this.body)
    this.report.getBroadcasterWiseChannelsReportMonthly(this.body).subscribe(data => {
      this.reportList = data.data;
      // // console.log(data)
      // // console.log(this.reportList)
      this.isLoading = false
      if (this.reportList?.length == 0) {
        this.isLoad = 'No Records'
      }
    });
    // }
  }

  // downloadTableExcel(): void {
  //   let name = 'Broadcaster Ala-carte Channel Wise Weekly Report'
  //   this.doc.exportTableToExcelwithImage(this.reportTable, name, 'broadcaster_channel_wise_report', undefined, undefined, "Report Date :",this.showDateRange?  this.reportDate : this.selectedDate, "Broadcaster Name", this.broadCasterName);
  // }

  downloadTableExcel(){
    let url= this.config.json.apiEndpoint+'generate/getBroadcasterWiseChannelsReport'
      this.http.postwithresponseType(url,this.body).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`Error: ${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'broadcaster_channel_wise_report.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }


  downloadTablePdf(): void {
    if(this.reportList == undefined || this.reportList == null || this.reportList.length == 0){
      alert('No Data')
      return;
    }
    let day = new Date()
    let reportGenerated = `${String(day.getDate()).padStart(2,'0')}-${String(day.getMonth()+1).padStart(2,'0')}-${day.getFullYear()}`
    this.pdf.generatePdfHtml('#reportTable',
      'Broadcaster Ala-carte Channel Wise Weekly Report',
      `Report Date : ${this.showDateRange?  this.reportDate : this.selectedDate},    Broadcaster Name : ${this.broadCasterName ? this.broadCasterName : "Not selected"}   Source Type : SMS    Report Generated : ${reportGenerated}`
    );
  }
  // Broadcaster ID: ${this.broadcasterId},

  downloadTableCSV(): void {

    const monthHeaders = ["Sl No","Channel Name","NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 7TH DAY OF MONTH","NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 14TH DAY OF MONTH","NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 21TH DAY OF MONTH","NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 28TH DAY OF MONTH","MONTHLY SUBSCRIPTION OF THE CHANNELS"]
    const randomHeaders = ["Sl No","Channel Name","Subscriber Count"]

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      // headers: ["Sl No","Channel Name","NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 7TH DAY OF MONTH","NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 14TH DAY OF MONTH","NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 21TH DAY OF MONTH","NUMBER OF SUBSCRIBERS OF THE CHANNEL ON 28TH DAY OF MONTH","MONTHLY SUBSCRIPTION OF THE CHANNELS"]
      headers : this.tableDisplay ? monthHeaders : randomHeaders
    };
    let data = []
    let i = 1
    this.reportList?.forEach(line => {
      // let csvLine = {
      //   slno : i,
      //   channelName: line.channelName,
      //   subscriberCountAt7: line.subscriberCountAt7,
      //   subscriberCountAt14: line.subscriberCountAt14,
      //   subscriberCountAt21: line.subscriberCountAt21,
      //   subscriberCountAt28: line.subscriberCountAt28,
      //   monthly: (line.subscriberCountAt7+line.subscriberCountAt14+
      //     line.subscriberCountAt21+line.subscriberCountAt28) / 4
      // }

      let csvLineMonth = {
          slno : i,
          channelName: line.channelName,
          subscriberCountAt7: line.subscriberCountAt7,
          subscriberCountAt14: line.subscriberCountAt14,
          subscriberCountAt21: line.subscriberCountAt21,
          subscriberCountAt28: line.subscriberCountAt28,
          monthly: (line.subscriberCountAt7+line.subscriberCountAt14+
          line.subscriberCountAt21+line.subscriberCountAt28) / 4
      }

      let csvLineRandom = {
        slno : i,
        channelName: line.channelName,
        subscriberCount : line.subscriberCount
      }

     let csvLine = this.tableDisplay ? csvLineMonth : csvLineRandom

      data.push(csvLine);
       i += 1
    });

    new ngxCsv(data, 'broadcaster_channel_wise_report', options);
  }

  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    this.searchWord = value
    let url = this.config.json.apiEndpointRdbms + 'reports/getBroadcasterWiseChannelsReport'
    let body = {}
    if(this.showDateRange){
      body = {
        'fromDate' : this.eve.startDate.format('YYYY-MM-DD'),
        'toDate' : this.eve.endDate.format('YYYY-MM-DD'),
        'searchKeyword' : value,
        "broadcasterId": +this.broadcasterId,
        "productType": 0
      }

    }
    if(this.showMonth){
      body = {
        'monthYear' : this.selectedDate,
        'searchKeyword' : value,
        "broadcasterId": +this.broadcasterId,
        "productType": 0
      }
    }
    if(!this.showMonth&&!this.showDateRange){
    let day = new Date()
    let toDate = `${day.getFullYear()}-${String(day.getMonth()+1).padStart(2,'0')}-${day.getFullYear()}-${String(day.getDate()).padStart(2,'0')}`
      body = {
        'fromDate' : '2018-01-01',
        'toDate' : toDate,
        'searchKeyword' : value,
        "broadcasterId": +this.broadcasterId,
        "productType": 0
      }
    }
    // // console.log(this.showDateRange, this.showMonth)
    this.http.post(url, body).subscribe(data => {
      this.reportList = data.data;
      if (this.reportList?.length == 0) {
        this.isLoading = false
        this.isLoad = 'No Records'
      }
    });
  }

}
