import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-transfer-stbs-to-lco',
  templateUrl: './transfer-stbs-to-lco.component.html',
  styleUrls: ['./transfer-stbs-to-lco.component.scss']
})
export class TransferStbsToLcoComponent implements OnInit {

  lcoList
  fromLco
  toLco
  toOptionSelected = false
  fromOptionSelected = false
  successMessage = undefined
  selectedStatus: any = '';
  statusList: any = [{ label: 'Assigned', value: '1' }, { label: 'Unassigned', value: '2' }];

  constructor(
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router

  ) { }

  ngOnInit(): void {
    this.getLco()
  }

  getLco() {
    let url = this.config.json.apiEndpoint + 'lco/getLCOs'
    this.http.get(url).subscribe({
      next: (res) => {
        this.lcoList = res.data
      }
    })
  }

  selectOption(e) {
    // // console.log({'from': this.fromLco, 'to' : this.toLco})
    switch (e) {
      case 'from': this.fromOptionSelected = true; break;
      case 'to': this.toOptionSelected = true; break;
    }
    if (this.fromLco == '' || this.fromLco == undefined) {
      this.fromOptionSelected = false
    }
    if (this.toLco == '' || this.toLco == undefined) {
      this.toOptionSelected = false
    }
    this.successMessage = ""
  }


  onSubmit() {

    const statusParam = this.selectedStatus ? `&status=${this.selectedStatus}` : '';
    const url = this.config.json.apiEndpointRdbms + `bulkUpload/transfer-stbs?lcoCode1=${this.fromLco.lcoCode}&lcoCode2=${this.toLco.lcoCode}${statusParam}`;

    this.http.post(url, []).subscribe(
      (res) => {
        alert(res.desc)
        this.successMessage = res.desc
        this.fromOptionSelected = false;
        this.toOptionSelected = false;
        this.fromLco = null;
        this.toLco = null;
        this.selectedStatus = null
      },
      (err) => {
        // // console.log(err);
      }
    )
  }

}
