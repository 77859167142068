import { Injectable, ElementRef } from '@angular/core';
import * as XLSX from 'xlsx';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DocumentGeneratorService {

  constructor() { }

  fileExtension = '.xlsx';

  exportTableToExcel(table: ElementRef, fileName: string, fileExt?: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //// console.log(wb)
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const ext = fileExt ? fileExt : this.fileExtension;
    const fn = fileName + '_' + Date.now();
    XLSX.writeFile(wb, fn + ext);
  }

  exportJsonToExcel(jsonData: any[], fileName: string, fileExt?: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const ext = fileExt ? fileExt : this.fileExtension;
    const fn = fileName + '_' + Date.now();
    XLSX.writeFile(wb, fn + ext);
  }

  async exportTableToExcelwithImage(table: ElementRef, name, fileName: string, table2?: ElementRef, fileExt?: string, datakey?: string, dataValue?: string, filter2key?: string, filter2value?: string) {
    let blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('My Sheet', {
      properties: {
        defaultColWidth: 30
      }
    });
    let tableRows = table.nativeElement.rows;
    // // console.log(tableRows)
    
    let ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table.nativeElement, { raw: true });
    let ab = XLSX.utils.sheet_to_json(ws)
    let currentDate = new Date();
    var row = worksheet.getRow(1);
    row.height = 100, 20
    if (datakey) {
      let row2 = worksheet.getRow(2)
      row2.getCell(1).value = datakey
      row2.getCell(2).value = dataValue
      if (!filter2key) {
        let row3 = worksheet.getRow(3)
        row3.getCell(1).value = "Report generated on :"
        row3.getCell(2).value = currentDate.getDate() + "-" + this.monthNameCalculator(currentDate.getMonth()) + "-" + currentDate.getFullYear() + " [" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds() + "]"
        let row4 = worksheet.getRow(4)
        row4.getCell(1).value = 'Source type :'
        row4.getCell(2).value = "SMS"
      }
    }
    if (filter2key) {
      let row2 = worksheet.getRow(3);
      row2.getCell(1).value = filter2key
      row2.getCell(2).value = filter2value
      let row3 = worksheet.getRow(4)
      row3.getCell(1).value = "Report generated on :"
      row3.getCell(2).value = currentDate.getDate() + "-" + this.monthNameCalculator(currentDate.getMonth()) + "-" + currentDate.getFullYear() + " [" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds() + "]"
      let row4 = worksheet.getRow(5)
      row4.getCell(1).value = 'Source type :'
      row4.getCell(2).value = 'SMS'
    }
    if (!datakey && !dataValue) {
      let row2 = worksheet.getRow(2);
      row2.getCell(1).value = "Report generated on :"
      row2.getCell(2).value = currentDate.getDate() + "-" + this.monthNameCalculator(currentDate.getMonth()) + "-" + currentDate.getFullYear() + " [" + currentDate.getHours() + ":" + String(currentDate.getMinutes()).padStart(2, "0") + ":" + String(currentDate.getSeconds()).padStart(2, "0") + "]"
      let row3 = worksheet.getRow(3)
      row3.getCell(1).value = 'Source type :'
      row3.getCell(2).value = 'SMS'
    }
    let a
    let nameRow = worksheet.addRow([name])
    worksheet.mergeCells('A1:D1')
    a = (ab.length === 1) ? Object.keys(ab[0]) : Object.keys(ab[1])
    let headerRow = worksheet.addRow(a)
    nameRow.eachCell((cell, number) => {
      cell.font = { bold: true, size: 14 }
    })
    headerRow.eachCell((cell, number) => {
      cell.font = { bold: true }
    })
    for (let x1 of ab) {
      let x2 = Object.keys(x1);
      let temp = []
      a = x2
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    
    var myBase64Image = "/assets/logos/esto_header_2.png";
    const response = await fetch(myBase64Image); const buffer12 = await response.arrayBuffer();
    var imageId2 = workbook.addImage({
      buffer: buffer12,
      extension: 'png',
    });
    worksheet['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 40 }, { width: 20 }, { width: 20 }]
    worksheet.addImage(imageId2, 'A1:C1');
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: blobType });
      FileSaver.saveAs(blob, fileName + " " + currentDate.getDate() + "-" + this.monthNameCalculator(currentDate.getMonth()) + "-" + currentDate.getFullYear());
    });
  }

  monthNameCalculator(monthNum: number): string {
    switch (monthNum) {
      case 0: return 'Jan';
      case 1: return 'Feb';
      case 2: return 'Mar';
      case 3: return 'Apr';
      case 4: return 'May';
      case 5: return 'Jun';
      case 6: return 'Jul';
      case 7: return 'Aug';
      case 8: return 'Sep';
      case 9: return 'Oct';
      case 10: return 'Nov';
      case 11: return 'Dec';
    }
  }

  testJsonToExcel(): void {
    const customers = [];
    for (let i = 0; i <= 25; i++) {
      customers.push({
        firstName: `first${i}`, lastName: `last${i}`,
        email: `abc${i}@gmail.com`, address: `000${i} street city, ST`, zipcode: `0000${i}`
      });
    }
    this.exportJsonToExcel(customers, 'customersExcel');
  }


  exportToExcel(tableId) {
    let tableData = document.getElementById(tableId).outerHTML;
    // tableData = tableData.replace(/<A[^>]*>|<\/A>/g, "");
    // tableData = tableData.replace(/<input[^>]*>|<\/input>/gi, "");
    let a = document.createElement('a');
    // a.href = `data:application/vnd.ms-excel, ${encodeURIComponent(tableData)}`
    // //// console.log(encodeURIComponent(tableData))
    a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${encodeURIComponent(tableData)}`
    a.download = 'downloaded_file_' + Date.now() + '.xlsx'
    a.click()
  }
}
