<div class="cms__content">
    <div class="top__heading">
        <h3>Channel Bulk Upload</h3>
        <a [routerLink]="['/content/channel/list']"><button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>
    <form action="">
        <div class="col-12 uploadSection">
            <div class="row">
              <div class="col-12 col-md-6 offset-md-3 uploadFile">
                <span>Upload File</span>
                <input type="file" class="custom__field" (change)="selectFile($event.target.files)"/>
                <p>(File types : csv | Excel | doc)</p>
                <!-- <button class="btn btn-primary" type="submit">Save file</button> -->
                <button class="btn btn-primary"
                [disabled]="selectedFiles" (click)="upload()">Save File</button>
              </div>
            </div>
          </div>
    </form>
</div>
