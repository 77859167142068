import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OsdRoutingModule } from './osd-routing.module';
import { OsdListComponent } from './osd-list/osd-list.component';
import { OsdCreateComponent } from './osd-create/osd-create.component';
import { OsdMessageComponent } from './osd-message/osd-message.component';
import { SharedModule } from '../shared/shared.module';
import { SubscriptionModule } from '../subscription/subscription.module';
import { LcoModule } from '../lco/lco.module';


@NgModule({
  declarations: [
    OsdListComponent,
    OsdCreateComponent,
    OsdMessageComponent,
  ],
  imports: [
    CommonModule,
    OsdRoutingModule,
    SharedModule,
    SubscriptionModule,
    LcoModule
  ],
  exports: [
    OsdListComponent, OsdCreateComponent, OsdMessageComponent
  ]
})
export class OsdModule { }
