import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListMailComponent } from './list-mail/list-mail.component';
import { CreateMailComponent } from './create-mail/create-mail.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListMailComponent },
  { path: ':id', component: CreateMailComponent },
  { path: 'create', component: CreateMailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MailRoutingModule { }
