import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SharedModule } from '../shared/shared.module';
import { LogsBouquetStatusChangeComponent } from './logs-bouquet-status-change/logs-bouquet-status-change.component';
import { LogsChannelStatusChangeComponent } from './logs-channel-status-change/logs-channel-status-change.component';
import { LogsProductCompositionChangeComponent } from './logs-product-composition-change/logs-product-composition-change.component';
import { LogsRoutingModule } from './logs-routing.module';
import { LogsStbConnectionStatusComponent } from './logs-stb-connection-status/logs-stb-connection-status.component';
import { LogsUserActivityComponent } from './logs-user-activity/logs-user-activity.component';
import { LogsUserLoginComponent } from './logs-user-login/logs-user-login.component';
import { LogsDataService } from './plugins/logs-data.service';
import { LogsCompleteComponent } from './logs-complete/logs-complete.component';
import { LogsStbActivationDeactivationComponent } from './logs-stb-activation-deactivation/logs-stb-activation-deactivation.component';
import { LogsSubscriptionDesubscriptionComponent } from './logs-subscription-desubscription/logs-subscription-desubscription.component';
import { LogsBouquetModificationComponent } from './logs-bouquet-modification/logs-bouquet-modification.component';
import { LogsChannelModificationComponent } from './logs-channel-modification/logs-channel-modification.component';
import { LogHomeComponent } from './log-home/log-home.component';
import { LogsBatchComponent } from './logs-batch/logs-batch.component';
import { LogsSubscriptionComponent } from './logs-subscription/logs-subscription.component';
import { SubscriptionModule } from '../subscription/subscription.module';
import { UserLoginHistoryComponent } from './user-login-history/user-login-history.component';
import { MSOLCOLoginHistoryComponent } from './msolcologin-history/msolcologin-history.component';
import { LogsSubscriberSmsComponent } from './logs-subscriber-sms/logs-subscriber-sms.component';
import { LcoModule } from '../lco/lco.module';
import { DistributorModule } from '../distributor/distributor.module';
import { WalletRechargeHistoryComponent } from './wallet-recharge-history/wallet-recharge-history.component';
import { LogsProductModificationComponent } from './logs-product-modification/logs-product-modification.component';
import { LogsProductSubscriptionDesubscriptionComponent } from './logs-product-subscription-desubscription/logs-product-subscription-desubscription.component';
import { LogsCompletecategoriesComponent } from './logs-completecategories/logs-completecategories.component';
import { LogsStbActivationDeactivationNewComponent } from './logs-stb-activation-deactivation-new/logs-stb-activation-deactivation-new.component';
import { LogsChannelModificationNewComponent } from './logs-channel-modification-new/logs-channel-modification-new.component';
import { LogsHomeNewComponent } from './logs-home-new/logs-home-new.component';
import { LogAdComponent } from './log-ad/log-ad.component';
import { LogsLandingchannelsettingsComponent } from './logs-landingchannelsettings/logs-landingchannelsettings.component';

@NgModule({
  declarations: [
    LogsUserLoginComponent,
    LogsUserActivityComponent,
    LogsProductCompositionChangeComponent,
    LogsStbConnectionStatusComponent,
    LogsChannelStatusChangeComponent,
    LogsBouquetStatusChangeComponent,
    LogsCompleteComponent,
    LogsStbActivationDeactivationComponent,
    LogsSubscriptionDesubscriptionComponent,
    LogsBouquetModificationComponent,
    LogsChannelModificationComponent,
    LogHomeComponent,
    LogsBatchComponent,
    LogsSubscriptionComponent,
    UserLoginHistoryComponent,
    MSOLCOLoginHistoryComponent,
    LogsSubscriberSmsComponent,
    WalletRechargeHistoryComponent,
    LogsProductModificationComponent,
    LogsProductSubscriptionDesubscriptionComponent,
    LogsCompletecategoriesComponent,
    LogsStbActivationDeactivationNewComponent,
    LogsChannelModificationNewComponent,
    LogsHomeNewComponent,
    LogAdComponent,
    LogsLandingchannelsettingsComponent,
  ],
  imports: [
    CommonModule,
    LogsRoutingModule,
    SharedModule,
    NgxDaterangepickerMd,
    SubscriptionModule,
    LcoModule,
    DistributorModule
  ],
  providers: [
    LogsDataService
  ]
})
export class LogsModule { }
