import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OtaManagerComponent } from './ota-manager/ota-manager.component';
import { ListOtaUpdateComponent } from './list-ota-update/list-ota-update.component';

const routes: Routes = [
  {
    path: 'list',
    component: ListOtaUpdateComponent,
  },
  {
    path: 'update',
    component: OtaManagerComponent,
  },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OTAManagerRoutingModule {}
