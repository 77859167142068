<div class="cms__content">
    <div class="top__heading">
      <h3>LCN Management for Region {{regionName.value}} </h3>
      <a [routerLink]="['/content/statewise-lcn']"><button type="button" class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>
    <form action="" [formGroup]="regionLCNForm"  >
      <div class="col-12">
        <div class="row" style="justify-content:flex-end;">

          <div class="third_box">
            <div style="display: flex;margin-bottom: 0px;" >
              <div class="search__container" style="justify-content: flex-end;  margin-bottom: 0px;" >
                <input type="text"  placeholder="Search Channels"  class="custom__field" (input)="filterChannels($event.target.value)" />
              </div>
            </div>
          </div>
          <div class="tableBox">
          <table  class="custom__iptv__table">
            <thead>
                <tr>
                <th>Sl No</th>
                <th>channel Id</th>
                <th>Channel Name</th>
                <th>LCN</th>
            </tr>
            </thead>
            <tr *ngFor="let channel of filteredChannelList;let j=index">
                <td>{{j+1}}</td>
                <td>{{channel.channelId}}</td>
                <td>{{channel.channelName}}</td>
                <td>{{channel.lcn?channel.lcn:'Not Set'}}
                  <div class="action__items">
                    <button class="cta" (click)="setLCNDetails(channel,j)">Set LCN</button>
                  </div>
                </td>
                <!-- <td *ngIf="channel.setFlag!=false"> 
                  <input type="text" [(ngModel)]="channelLCN" [ngModelOptions]="{standalone: true}">
                  <button (click)="setChannelLCNValue(channel,j)">Save</button>
                
              </td> -->
            </tr>
            
          </table>
  </div>
          <div class="col-12">
            <button class="cta float-right" [disabled]="!regionLCNForm.valid" (click)="persist()">Save</button>
          </div>
        </div>
      </div>
  
    </form>
  </div>


  <div class="modal_box" *ngIf="showPopup">
    <div class="modal_box_inner">
      <h3> Set LCN Details</h3>
      <div class="parent">
        <p class="div1">Region ID</p>
        <p class="div2">{{regionId.value}}</p>
        <p class="div3">Channel Id</p>
        <p class="div4">{{channelId.value}}</p>
        <p class="div5">Channel Name</p>
        <p class="div6">{{channelName.value}}</p>
        <p class="div7">Channel LCN</p>
        <p class="div8"> <input type="text" [(ngModel)]="channelLCN" [ngModelOptions]="{standalone: true}"></p>

      </div>
      <button class="float-left" (click)="cancelClick()"> cancel </button>
      <button class="cta float-right" (click)="setChannelLCNValue(channel)"> Save </button>
    </div>
  </div>