import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-lco',
  templateUrl: './list-lco.component.html',
  styleUrls: ['./list-lco.component.scss'],
})
export class ListLcoComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  LcoActiveCount: number;
  TotalLcoCount: number;
  sortByProperties = {
    name: 'name',
    email: 'email',
    mobile: 'mobile',
    date: 'createdDate',
    status: 'status',
    cp: 'contactPerson',
    tp: 'transferPercent',
    distributorCode: 'distributorCode'
  };

  lcoList: LCOInfo[] = [];

  activatedCount = 0;

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService,
    private httpService: HttpClient,
    private auth: AuthService
  ) { }

  isDistributor = false;
  ngOnInit(): void {
    this.fetchPage();
    this.fetchLcoCount();
    this.isDistributor = this.auth.isDistributor();
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  SetBouquet(admin) {
    this.router.navigateByUrl('manage/lco/set-bouquet/' + admin.id)
  }

  LCODetails: LCOInfo;
  viewModal = false;
  viewLCODetails(lcoDetails: any) {
    let lcoId = lcoDetails.id,
      url = `${this.config.json.apiEndpoint}lco/lcoDetails/` + lcoId;
    this.http.get(url).subscribe({
      next: (res: any) => {
        this.LCODetails = res.data;
        this.viewModal = true;
      },
    });
  }

  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url;
    if (this.auth.isDistributor()) {
      url = PaginationUtilities.urlBuilder(
        'lco/getLCOsByDistributor',
        this.paginationObj
      );
      url += `&distributorId=${this.auth.getLcoId()}`;
    } else {
      url = PaginationUtilities.urlBuilder('lco/getLCOs', this.paginationObj);
    }
    this.loadingFlag = true;

    this.http
      .get(url)
      .pipe(delay(250))
      .subscribe((data: PageInfo<LCOInfo>) => {
        this.loadingFlag = false;
        this.handleFetchPage(data);
      });
  }
  fetchLcoCount() {
    let url = this.config.json.apiEndpointRdbms + 'mso/getLcoCount';
    if (this.auth.isDistributor()) {
      url = `${this.config.json.apiEndpointRdbms
        }mso/getLcoCountByDistributor/${this.auth.getLcoId()}`;
    }
    this.http.get(url).subscribe((res) => {
      // // console.log(res)
      this.LcoActiveCount = res.data.active;
      this.TotalLcoCount = res.data.count;
    });
  }
  handleFetchPage(data: PageInfo<LCOInfo>): void {
    this.lcoList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countAdmin();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.pageSize = metadata.pageSize;
    this.pageNumber = metadata.pageNumber;
  }

  pageSize;
  pageNumber;
  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC
        ? SortOrder.ASC
        : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: AdminInfo): void {
    this.router.navigate(['/manage/lco', record.id]);
  }

  countAdmin(): void {
    this.activatedCount = this.lcoList.filter((x) => x.status === 1).length;
    // //// console.log(this.lcoList)
  }

  updateStatus(record: AdminInfo): void {
    record.status === 1 ? this.deactivate(record) : this.activate(record);
  }

  deactivate(record: AdminInfo): void {
    const url =
      this.config.json.apiEndpointRdbms +
      'admin/deactivateAccount/' +
      record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countAdmin();
      window.alert('Account deactivated');
    });
  }

  activate(record: AdminInfo): void {
    const url =
      this.config.json.apiEndpointRdbms + 'admin/activateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countAdmin();
      window.alert('Account activated');
    });
  }

  login(userDetail) {
    // console.log( JSON.parse(localStorage.getItem('profileInfo') as string).email);
    let body = {
      username: userDetail.email,
      loggedIn: JSON.parse(localStorage.getItem('profileInfo') as string).email,
    };
    let openUrl =
      this.config.json.adminLogin + 'user/' + btoa(JSON.stringify(body));
    window.open(openUrl, '_blank');
  }
}
