<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Bouquet Modification Logs</h3>
    <a [routerLink]="['/logs']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>

  <div class="row row-top">
    <div class="date_filter">
      <div class="div_tabs" *ngIf="dateSelector">
        <button (click)="dateTypeSelectors(1)">Till Date</button>
        <button (click)="dateTypeSelectors(2)">Random Date</button>
        <button (click)="dateTypeSelectors(3)">Year</button>
      </div>
      <div class="div_tabs2" *ngIf="showMonth">
        <div class="date_box">
          <label for="month">Till date:</label>
          <div class="date_box_orig">
            <input
              class="custom__field mar_zeroed"
              type="date"
              id="month"
              value="selectedDate"
              (change)="dateChangeMonth($event)"
            />
          </div>
        </div>
        <button (click)="dateTypeSelectors(0)">×</button>
      </div>
      <div class="div_tabs2" *ngIf="showDateRange">
        <label for="dateRange"> Select Date : </label>
        <div class="date_box_orig date_box_orig2">
          <app-date-picker
            id="mar_zeroeds"
            (dateChange)="dateChangeRange($event)"
          ></app-date-picker>
        </div>
        <button id="app_date_pick_button" (click)="dateTypeSelectors(0)">
          ×
        </button>
      </div>
      <div class="div_tabs2" *ngIf="showYearSelector">
        <label for=""> Select Year </label>
        <select name="" id="yearCalc" (change)="yearFilter()">
          <option *ngFor="let item of yearsArray" value="{{ item }}">
            {{ item }}
          </option>
        </select>
        <button (click)="dateTypeSelectors(0)">×</button>
      </div>
    </div>
    <div class="download__report col-md-6">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>

    <div class="col-12 col-md-6 report_selection_block">
      <!-- <label class="report_selection_label">Select Bouquet</label>
           <select class="custom__field report_selection" [(ngModel)]="selectedBouquetId" (change)='listItem()'>
               <option [ngValue] = 0 >ALL</option>
               <option *ngFor="let bq of bouquetList" [ngValue]='bq.id'>{{bq.name}}</option>
           </select> -->
    </div>
  </div>

  <div class="col-12 report">
    <p>Report generated on : {{ currentDate | date : "longDate" }}</p>
  </div>

  <div class="custom__table__filter">
    <span>
      Show
      <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
        <option
          *ngFor="let size of paginationObj.pageSizeOptions"
          [ngValue]="size"
        >
          {{ size }}
        </option>
      </select>
      Entries
    </span>
    <div class="table__right">
      <p class="table__summary">
        Total <span>{{ paginationObj.totalElements }}</span> records
      </p>
      <div class="search__container">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          id="searchKeyword"
          [(ngModel)]="paginationObj.searchKeyword"
          (keyup.enter)="searchKeyWord()"
        />
        <button (click)="searchKeyWord()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Channel ID</th>
            <th>Resource Code ID</th>
            <th>Resource Name</th>
            <th>Status</th>
            <th>Package Code</th>
            <th>Bouquet ID</th>
            <th>Bouquet Name</th>
            <th>Transaction Date</th>
            <!-- <th>Broadcaster Name</th> -->
            <!-- <th>Channel Name</th> -->
            <!-- <th>Channel Name</th>
                       <th>Channel Modification</th>
                       <th>Modified By</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="isLoading">
            <td colspan="9" style="text-align: center">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <tr *ngIf="reportList.length <= 0 && !isLoading && isLoad.length > 0">
            <td colspan="9" style="text-align: center">{{ isLoad }}</td>
          </tr>

          <tr *ngFor="let report of reportList; let j = index">
            <td>{{ j + 1 }}</td>
            <td>
              {{ report.service_asset_id ? report.service_asset_id : "---" }}
            </td>
            <td>{{ report.resource_code ? report.resource_code : "---" }}</td>
            <td>{{ report.resource_name ? report.resource_name : "---" }}</td>
            <td>{{ report.channel_status ? report.channel_status : "---" }}</td>
            <td>{{ report.package_code ? report.package_code : "---" }}</td>
            <td>{{ report.bouquet_id ? report.bouquet_id : "---" }}</td>
            <td>{{ report.package_name ? report.package_name : "---" }}</td>
            <td>
              {{
                report.transaction_date | date : "dd-MM-yyyy HH:mm:ss" : "UTC"
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
