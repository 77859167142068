<div class="cms__content">
    <app-reports-header></app-reports-header>
  
    <div class="top__heading">
      <h3>STB  Activation and Deactivation Logs</h3>
      <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
    </div>
  
    
    <div class="row">
      <div class="filter__group col-12 col-md-6">
        <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
      </div>
      </div>
  
      <div class="row row-top">
        
    
        <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
          <label class="report_selection_label" style="margin-right: 1%;"> Type: </label>
          <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="deviceType" (change)="fetchData()" >
            <option value="All">ALL</option>
            <option value="hard">hard</option>
            <option value="soft">soft</option>
          </select>
        </div>
        <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
            <label class="report_selection_label" style="margin-right: 1%;"> Status: </label>
            <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="selectedCategory" (change)="fetchData()" >
              <option value="All">ALL</option>
              <option value="Activated">Activated</option>
              <option value="Assigned">Assigned</option>
              <option value="Terminated">Terminated</option>
              <option value="Created">Created</option>
              <option value="Deactivated">Deactivated</option>
              <option value="Unassigned">Unassigned</option>
              <option value="Working">Working</option>
              <option value="Blacklisted">Blacklisted</option>
              <option value="Fresh">Fresh</option>
            </select>
          </div>
        
      </div>
  
    <div class="col-12 report">
      <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
      <div class="download__report col-md-6">
        <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
            PDF
            <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
            Excel
            <img src="./assets/excel.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
            CSV
            <img src="./assets/csv.svg" alt="download" /> </button>
    </div>
    </div>
    <div class="custom__table__filter">
      <span>
        Show
        <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
          <option
            *ngFor="let size of paginationObj.pageSizeOptions"
            [ngValue]="size"
          >
            {{ size }}
          </option>
        </select>
        Entries
      </span>
      <div class="table__right">
        <p class="table__summary">
          Total <span>{{ paginationObj.totalElements }}</span> records
        </p>
        <div class="search__container">
          <input
            type="text"
            autocomplete="off"
            class="custom__field"
            id="searchKeyword"
            [(ngModel)]="paginationObj.searchKeyword"
            (keyup.enter)="searchKeyWord()"
          />
          <button (click)="searchKeyWord()">
            <img src="./assets/search.png" alt="search" />
          </button>
        </div>
      </div>
    </div>
  
    <div class="col-12 list__table">
      <div class="row scroll">
        <table class="custom__iptv__table" #reportTable id="reportTable">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th (click)="sortByItem(sortByProperties.createdDate)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.createdDate}"> Transaction Date</th>
              <th (click)="sortByItem(sortByProperties.stbId)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.stbId}">STB ID</th>
              <th (click)="sortByItem(sortByProperties.subscriberCode)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.subscriberCode}">Subscriber Code</th>
              <th (click)="sortByItem(sortByProperties.ethernetMac)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.ethernetMac}">STB Mac address</th>
              <th (click)="sortByItem(sortByProperties.description)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.description}">Description</th>
              <th (click)="sortByItem(sortByProperties.stbLevel)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.stbLevel}">STB Level</th>
              <th (click)="sortByItem(sortByProperties.STATUS)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.STATUS}">Status</th>
              <th (click)="sortByItem(sortByProperties.commandStb)" [ngClass]="{
                selected: paginationObj.sortBy === sortByProperties.commandStb}">Command STB</th>
            
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="reportList.length ==0 && !loadingFlag" >
              <td style="text-align: center !important;" colspan="12" >{{isLoad}}</td>
            </tr>
            <tr *ngIf="loadingFlag; else showList">
              <td style="text-align: center !important;" colspan="12">
              <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <ng-template #showList>
              <tr *ngFor="let report of reportList, let j= index">
                <td>{{j+1}}</td>
                <td>{{report.createdDate ?report.createdDate:'---' }}</td>
                <td>{{report.stbId? report.stbId : '---'}}</td>
                <td>{{report.subscriberCode? report.subscriberCode : '---' }}</td>
                <td>{{report.ethernetMac? report.ethernetMac : '---'}}</td>
                <td>{{report.description ? report.description:'---'}}</td>
                <td>{{report.stbLevel? report.stbLevel : '---'}}</td>
                <td>{{ report.STATUS == 0 ? 'Inactive' : report.STATUS == 1 ? 'Active' : 'Terminated' }}</td>
                <td>{{report.commandStb === 0 ? "Active" : "Inactive"}}</td>
              
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  