import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { PackageInfo } from 'src/app/models/package/package-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';

@Component({
  selector: 'app-logs-stb-connection-status',
  templateUrl: './logs-stb-connection-status.component.html',
  styleUrls: ['./logs-stb-connection-status.component.scss']
})
export class LogsStbConnectionStatusComponent implements OnInit {

  subscriberReport = [];

  bouquetList: PackageInfo[] = [];
  selectedBouquetId: any;

  channelList: ChannelInfo[] = [];
  selectedChannelId: number;

  from: any;
  to: any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    this.fetchBouquetList();
    this.loadingFlag = true
    this.fetchDatasOninit();
    // this.fetchChannelList();
  }


  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe(data => {
      this.bouquetList = data.data;
    });
  }

  // fetchChannelList(): void {
  //   this.report.getChannelList().subscribe(data => {
  //     this.channelList = data;
  //   });
  // }

  loadingFlag = false
  isLoad = 'Choose Date'

  fetchData(): void {
    this.report.getStbList().subscribe(data => {
      // // console.log(data)
      this.subscriberReport = data.data;
      // // console.log(data.data)
      if (this.subscriberReport.length != 0) {
        this.loadingFlag = false
      } else {
        this.loadingFlag = false
        this.isLoad = 'No Records'
      }
    }, () => {
      this.loadingFlag = false
      this.isLoad = 'No Records'
    });
  }


  fetchDatasOninit() {
    this.selectedBouquetId = 0
    let dateObj = new Date()
    let toDate = dateObj.getFullYear() + "-" + (dateObj.getMonth() + 1) + "-" + dateObj.getDate()
    let body = {}
    body['boquetId'] = 0,
      body['searchKeyword'] = ''
    body['fromDate'] = '2018-01-01'
    body['toDate'] = toDate
    this.report.getStbActivationHistory2(body).subscribe(data => {
      this.subscriberReport = data.data;
      if (this.subscriberReport.length != 0) {
        this.loadingFlag = false
      } else {
        this.loadingFlag = false
        this.isLoad = 'No Records'
      }
    }, () => {
      this.loadingFlag = false
      this.isLoad = 'No Records'
    })
  }

  fetchDatas() {
    let body = {}
    body['boquetId'] = this.selectedBouquetId.id,
      body['searchKeyword'] = ''
    if (this.selectedBouquetId || this.selectedBouquetId == 0) {
      this.loadingFlag = true
    }
    this.report.getStbActivationHistory(this.ev.startDate, this.ev.endDate, body).subscribe(data => {
      this.subscriberReport = data.data;
      // // console.log(this.subscriberReport)
      if (this.subscriberReport.length != 0) {
        this.loadingFlag = false
      } else {
        this.loadingFlag = false
        this.isLoad = 'No Records'
      }
    }, () => {
      this.loadingFlag = false
      this.isLoad = 'No Records'
    })
  }

  statUS = ['Activated', 'Deactivated', 'Blacklisted', 'Whitelisted', 'Fresh', 'Assigned', 'Unassigned', 'Working', 'Repairable', 'Unrepairable']
  // fetchDatas(from: number, to: number): void {
  //   this.report.getUserLoginHistory(from, to, {}).subscribe(data => {
  //     this.subscriberReport = data;
  //   });
  // }
  dateSelected = false
  boqueSelected = false
  ev
  reportDate;
  dateChange(ev: NgxDateRangeInfo): void {
    this.dateSelected = true
    if (ev.startDate !== null && ev.endDate !== null) {
      this.reportDate = ev.startDate.format('DD-MM-YYYY') + " to " + ev.endDate.format('DD-MM-YYYY')
      this.ev = ev
      if (this.boqueSelected) {
        [this.from, this.to] = [ev.startDate, ev.endDate];
        this.calendar.setDate(ev);
        this.fetchDatas();
        this.loadingFlag = true
        this.isLoad = 'Loading'
      } else {
        this.isLoad = 'Select Bouquet'
      }
    }
  }

  listItem() {
    this.boqueSelected = true
    if (this.dateSelected) {
      this.fetchDatas()
    } else {
      this.isLoad = 'Select Date'
    }
  }

  downloadTableExcel(): void {
    let name = 'Stb Connection Logs'
    if (this.subscriberReport.length == 0) {
      alert('No Data!!')
    } else {
      if (this.ev) {
        this.doc.exportTableToExcelwithImage(this.reportTable, name, 'stb_connection_status_logs', undefined, undefined, "Filter Date :", this.reportDate, "Bouquet Name : ",this.selectedBouquetId ? this.selectedBouquetId.name : 'ALL');
      } else {
        this.doc.exportTableToExcelwithImage(this.reportTable, name, 'stb_connection_status_logs', undefined, undefined,"Bouquet Name : ", this.selectedBouquetId ? this.selectedBouquetId.name : 'ALL');
      }
    }
  }

  downloadTablePdf(): void {
    if (this.subscriberReport.length == 0) {
      alert('No Data!!')
    } else {
      if (this.ev) {
        this.pdf.generatePdfHtml('#reportTable',
          'STB Connection Logs',
          `Filter date : ${this.reportDate}   Bouquet Name: ${this.selectedBouquetId ? this.selectedBouquetId.name : 'ALL'}`, 'portrait'
        );
      } else {
        this.pdf.generatePdfHtml('#reportTable',
          'STB Connection Logs',
          `From Start to Now    Bouquet Name: ${this.selectedBouquetId ? this.selectedBouquetId.name : 'ALL'}`, 'portrait'
        );
      }
    }
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Sl No","Subscriber Code", "STB MAC", "Status Change", "Date"]
    };
    let data = []
    let i =1
    this.subscriberReport.forEach(line => {
      let csvLine = {
        slno : i,
        date: line.subscriberCode,
        email: line.ethernetMac,
        type: this.statUS[line.commandStb],
        activity: line.createdDate
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'stb_connection_status_logs', options);
  }

}

