<div class="cms__content">
  <app-reports-header></app-reports-header>
  <div class="top__heading">
    <h3>Bouquet Subscription Count Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
       <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
          CSV
          <img src="./assets/csv.svg" alt="download" />
        </button>
    </div>
  </div>

  <div class="col-12 col-md-6 report_selection_block">
    <label class="report_selection_label">Select Bouquet Type</label>
    <select class="custom__field report_selection" [(ngModel)]="bouquetType" (change)='lcoSelect()'>
      <!-- <option [ngValue]=-1>ALL</option> -->
      <option [ngValue]=0>MSO</option>
      <option [ngValue]=1>Broadcaster</option>
    </select>
  </div>

  <div class="col-12 col-md-6 report_selection_block">
    <label class="report_selection_label">Select Broadcaster</label>
    <select class="custom__field report_selection" [(ngModel)]="broadcasterId" (change)='lcoSelect()'
      [disabled]="bouquetType!==1">
      <!-- Changed ngValue binding from lco.id to lco.email -->
      <option value=0>ALL</option>
      <option *ngFor="let lco of broadcasterList" [ngValue]='lco.id'>
        {{lco.name}}
      </option>
    </select>
  </div>
  <div class="third_box">
    <div class="col-12 col-md-6 report">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div style="display: flex; gap:20px" >
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <th>Sl No.</th>
            <th>Bouquet ID</th>
            <th>Bouquet Name</th>
            <th>Bouquet Type</th>
            <th>Subscription Count</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="5" *ngIf="!isLoading" style="text-align: center;" >{{isLoad}}</td>
            <td *ngIf="isLoading && reportList.length == 0" colspan="9" style="text-align: center" ><app-custom-spinner></app-custom-spinner></td>
          </tr>

          <!-- <ng-template #showList> -->
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{j+1}}</td>
              <!-- <td>{{report.createdDate | date: 'dd-MM-yy'}}</td> -->
              <td>{{(report.bouquetId || report.bouqet_id ) == 'null' || (report.bouquetId || report.bouqet_id ) == null  || (report.bouquetId || report.bouqet_id ) == ''  ? '---' :  (report.bouquetId || report.bouqet_id )}}</td>
              <td>{{(report.bouquetName || report.bouquet_name) == 'null' || (report.bouquetName || report.bouquet_name) == null || (report.bouquetName || report.bouquet_name) == '' ? '---' : (report.bouquetName || report.bouquet_name)}}</td>
              <td>{{(report.bouquetType || report.boquet_type) == 'null' || (report.bouquetType || report.boquet_type) == null || (report.bouquetType || report.boquet_type) == '' ? '---' : (report.bouquetType || report.boquet_type)}}</td>
              <td>{{(report.subscriptionCount || report.subscription_count) == 'null' || (report.subscriptionCount || report.subscription_count) == null || (report.subscriptionCount || report.subscription_count) =='' ? '---' : (report.subscriptionCount || report.subscription_count)}}</td>
            </tr>
          <!-- </ng-template> -->
        </tbody>
      </table>
    </div>
  </div>
</div>
