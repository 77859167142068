import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import * as moment from 'moment';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-report-stb-mapping',
  templateUrl: './report-stb-mapping.component.html',
  styleUrls: ['./report-stb-mapping.component.scss']
})
export class ReportStbMappingComponent implements OnInit {
  
    reportList = [];
    isLoad = 'Choose Date';
    isLoading = false;
    currentDate = new Date();
    from:any;
    to:any;
    onDate
  
    paginationObj = new PaginationObj();

    loadingFlag = false;
  
  
    disableFirstBtn = false;
    disableLastBtn = false;
    disableNextBtn = false;
    disablePreviousBtn = false;
    
  
  
    @ViewChild('reportTable', { static: false }) reportTable: ElementRef;
  
    constructor(private report: ReportTraiDataService,
      private calendar: ReportCalendarService,
      private doc: DocumentGeneratorService,
      private pdf: PdfGeneratorService,
      private config: ConfigService,
      private http: HttpClientWrapperService) { }
  
    ngOnInit(): void {
      this.onDate=this.currentDate.toISOString().split('T')[0];
      this.fetchData()
    }
  
    sortByItem(item: string): void {
      console.log(item)
      this.paginationObj.sortBy = item;
      this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
      this.fetchData();
    }

    onSearch() {
      this.paginationObj.pageNumber = 1;
      this.fetchData();
    }
  
  
    handlePaginationBtnDisableStatuses(): void {
  
      // Left section
      this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
      this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
  
      // Right section
      this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
      this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    }
  
    pageSizeChange(): void {
      this.paginationObj.pageNumber = environment.defaultPageNumber;
      this.fetchData();
    }
  
    pagePreviousBtnClick(): void {
      if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
        this.paginationObj.pageNumber--;
      }
      this.handlePaginationBtnDisableStatuses();
      this.fetchData();
    }
  
    pageNextBtnClick(): void {
      if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
        this.paginationObj.pageNumber++;
      }
      this.handlePaginationBtnDisableStatuses();
      this.fetchData();
    }
  
    pageFirstBtnClick(): void {
      this.paginationObj.pageNumber = environment.defaultPageNumber;
  
      this.handlePaginationBtnDisableStatuses();
      this.fetchData();
    }
  
    pageLastBtnClick(): void {
      this.paginationObj.pageNumber = this.paginationObj.totalPages;
  
      this.handlePaginationBtnDisableStatuses();
      this.fetchData();
    }
    handleFetchPage(data): void {
      this.reportList = []
      this.reportList = data.data;
      if (this.reportList.length != 0) {
        this.loadingFlag = false;
      } else {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
      this.handlePaginationMetadata(data.metadata);
      this.handlePaginationBtnDisableStatuses();
    }
  
    pageNumber
    handlePaginationMetadata(metadata: PageMetadata): void {
      this.paginationObj.totalPages = metadata.totalPageCount;
      this.pageNumber = metadata.pageNumber
      this.paginationObj.totalElements = metadata.totalElementCount;
    }
  
    
  
  
    dateChange(ev: NgxDateRangeInfo): void {

      // if (ev.startDate !== null && ev.endDate !== null) {
      //   [this.from, this.to] = [ev.startDate, ev.endDate];
      //   this.fetchData(ev.startDate.format('YYYY-MM-DD'), ev.endDate.format('YYYY-MM-DD'));
      //   this.isLoad = 'Loading';
      //   this.calendar.setDate(ev);
      // }
    }
  
    // fetchData(): void {
    //   let url=this.config.json.apiEndpoint+'reports/stbMapReport/1?toDate='+this.onDate
    //   this.http.get(url).subscribe((data) => {
    //     this.isLoading = false;
    //     if(!(data.data)){
    //       this.isLoading = false;
    //       this.isLoad = 'No Records';
    //       this.reportList = []
    //       return
    //     }
    //     this.reportList = data.data;
    //     console.log(this.reportList)
    //     // this.SortData()
    //     if (this.reportList?.length == 0) {
    //       this.isLoading = false;
    //       this.isLoad = 'No Records';
    //     } else {
    //       this.isLoading = true;
    //     }
    //   },
    //   () => {
    //     this.isLoad = 'No Records';
    //     this.isLoading = false;
    //   }
    // )

    // }


    fetchData() {
      this.loadingFlag = true
      this.reportList = []
      PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
      let url = PaginationUtilities.urlBuilder('reports/stbMapReport', this.paginationObj);
      url = url + "&toDate="+this.onDate
      this.http.get(url).subscribe(
        (data) => {
          this.handleFetchPage(data);
        },
        () => {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      );
    }
  
   
  
    
  
    // downloadTableExcel(): void {
    //   let name = 'STB Mapping Report';
    //   this.doc.exportTableToExcelwithImage(this.reportTable, name, 'Stb_mapping_Report');
    // }

    downloadTableExcel(){
      let url= this.config.json.apiEndpoint+'generate/stbMapReport?toDate='+this.onDate
      console.log(url)
      this.http.getwithresponseType(url).subscribe(
        (response:Blob) => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Stb_mapping_Report.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
          },
          (error) => {
            console.error('Error downloading Excel file:', error);
          }
      )
    }
  
    downloadTablePdf(): void {
      this.pdf.generatePdfHtml('#reportTable', 'Stb_mapping_Report', `Source Type : SMS`);
    }
  
    
    downloadTableCSV(): void {
      if (this.reportTable) {
        const tableData = this.getTableData(this.reportTable.nativeElement);
        if (tableData) {
          var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            useBom: true,
            // headers: [
            //   'SMS',
            //   'DRM',
            //   'Mismatch %'
            // ],
          };
          new ngxCsv(tableData, 'Stb_mapping_Report', options);
        }
      }
    }
  
    private getTableData(table: any): any[] {
      const data = [];
      const rows = table.rows;
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowData = [];
        for (let j = 0; j < row.cells.length; j++) {
          rowData.push(row.cells[j].innerText.trim());
        }
        data.push(rowData);
      }
      return data;
  }
    // downloadTableCSV(): void {
    //   var options = {
    //     fieldSeparator: ',',
    //     quoteStrings: '"',
    //     decimalseparator: '.',
    //     showLabels: true,
    //     showTitle: false,
    //     // title: false,
    //     useBom: true,
    //     // noDownload: true,
    //     headers: [
    //       'SMS',
    //       'DRM',
    //       'Mismatch %'
    //     ],
    //   };
    //   let data=this.reportList;
    //   new ngxCsv(data, 'Subscription_Reconciliation_Report', options);
    // }
  
    searchKeyword() {
      let value = (document.getElementById('searchKeyword') as HTMLInputElement)
        .value;
      let url =
        this.config.json.apiEndpointRdbms + 'osd/list?searchKeyword=' + value;
      this.http.get(url).subscribe(
        (data) => {
          this.reportList = data;
          this.isLoading = false;
          if (this.reportList.length == 0) {
            this.isLoad = 'No Records';
          } else {
            this.isLoading = true;
          }
        },
        () => {
          this.isLoad = 'No Records';
          this.isLoading = false;
        }
      );
    }
  
  }
  
  
