<div class="cms__content">
    <h2>Theme Manager</h2>
    <div class="content_wrap">
        <div class="selection_bar">
            <ul>
                <li (click)="view = 'selectColor' ">Select Color theme</li>
                <li (click)="view = 'changeBackground'">Change Background</li>
                <li (click)="resetFactoryDefaults()">Reset to Factory Defaults</li>
            </ul>
        </div>
        <div class="component_view">
            <ng-container [ngSwitch]="view">
                <ng-container *ngSwitchCase="'selectColor'">
                    <color-selector></color-selector>
                </ng-container>
                <ng-container *ngSwitchCase="'changeBackground'">
                    <background-changer></background-changer>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>