import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-logs-home-new',
  templateUrl: './logs-home-new.component.html',
  styleUrls: ['./logs-home-new.component.scss']
})
export class LogsHomeNewComponent implements OnInit {

  constructor(private auth: AuthService) { }

  isDistributor = false;
  ngOnInit(): void {
    this.isDistributor = this.auth.isDistributor();
  }

}
