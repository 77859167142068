<div class="mainpage">
    <div class="container">
        <img src="assets/logos/skypro.png" alt="Logo" class="logo">
        <form action="" [formGroup]="userForm" (keydown.enter)="$event.preventDefault()">
            <div class="leftside">
                <div class="child">
                    <img src="assets/img12.png" alt="Logo" *ngIf="!radioButtonClicked || !mobileNumberClicked || !emailClicked || !companyNamePage||(addressPage==false && landlinePage ==true)||
                         (landlinePage==false && panPage ==true)||(paymentpage==false && lcocaptchaPage==true)||
                         (lcoamazingProgressPage==false && passwordPage==true && networkPage==false && networkPage1==false&& networkPage2==false  && networkPage3==false  && networkPage4==false  && networkPage5==false)||
                         (lcoamazingProgressPage==true && lcocaptchaPage == false)||(passwordPage==false && GSTPage==true)">
                    <img class="profileimg" src="assets/img13.png" alt="Logo"
                        *ngIf="companyNamePage==true && photoPage ==false && photoLink==''">
                    <img class="profileimg" src="assets/img14.png" alt="Logo"
                        *ngIf="(panPage==false && adharPage ==true) || (adharPage==false && photoPage ==true)|| (GSTPage==false && addressPage ==true)">
                    <img src="assets/networkimg.png" alt="Logo"
                        *ngIf="(networkPage1==false && networkPage==true)||
                        (networkPage2==false && networkPage1==true) ||
                        (networkPage3==false && networkPage2==true)||
                        (networkPage4==false && networkPage3==true)||
                        (networkPage5==false && networkPage4==true) ||
                        (networkPage6==false && networkPage5==true)" >
                    <img [src]="config.json.imagPrefix+photoLink" alt="Logo" *ngIf="companyNamePage==true && photoPage ==false && photoLink!=''">
                </div>
                <div class="child" *ngIf="radioButtonClicked == false">
                    <h5>Welcome to Skypro<br />Self Registration Form</h5>
                    <h6>Please fill this form to get associated with us</h6>
                    <br>
                    <h5 style="font-size:18px" *ngIf="radioButtonClicked == false"><b>I Am a</b></h5>
                    <div class="radio-container">
                        <div class="radio-option">
                            <input type="radio" id="option1" formControlName="userType" value="LCO">
                            <label for="option1" class="special-label">LCO<br />Local Cable Operator</label>
                        </div>
                        <div class="radio-option">
                            <input type="radio" id="option2" formControlName="userType" value="Distributor">
                            <label for="option2" class="special-label">Distributor<br />I have many LCOs</label>
                        </div>
                    </div>
                    <button *ngIf="radioButtonClicked == false" style="margin-left: 65%;
                    margin-top: 10%;" class="cta" [disabled]="!userType.valid"
                        (click)="RadioButtonvalue()">Next</button>
                </div>

                <div class="child" *ngIf="radioButtonClicked==true && mobileNumberClicked ==false">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <label>Phone Number</label>
                    <input type="text" class="custom__field" placeholder="format: +91......" formControlName="mobile"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '')" maxlength="10" pattern="^\d{10}$"
                        (input)="numValidation($event)"
                        [ngClass]="{ error__field: !mobile.valid && mobile.touched ,'success':mobileVerified}" />
                    <div [hidden]="!mobile.touched">
                        <span class="error__msg" *ngIf="mobile.hasError('required')">Please enter 10 digit mobile
                            number</span>
                        <span class="error__msg" *ngIf="isNumError">Number is invalid</span>
                        <span class="error__msg" *ngIf="mobile.errors?.duplicate">Number is taken</span>
                    </div>
                    <div class="button_group">
                        <button *ngIf="!mobileVerificationClicked" (click)="backClickedOnMobile()">Back</button>
                        <button *ngIf="!mobileVerificationClicked" class="cta" (click)="sendVerificationCodeForMobile()"
                            [disabled]="!mobile.valid">Request Otp</button>
                    </div>
                    <p *ngIf="mobileVerified" style="color:green">Verified<span class="verified">&#10004;</span> </p>


                    <div *ngIf="mobileVerificationClicked">
                        <label for="otp" *ngIf="mobileVerificationClicked && !mobileVerified"> Enter OTP </label>
                        <span *ngIf="timer!='0:00' && !mobileVerified " class="timer"> {{ timer }}</span>
                        <div class="otp-container" *ngIf="mobileVerificationClicked && !mobileVerified">
                            <input type="number" class="otp-input" maxlength="1" #otp1 formControlName="otp1"
                                (input)="moveToNext($event, otp2)" />
                            <input type="number" class="otp-input" maxlength="1" #otp2 formControlName="otp2"
                                (input)="moveToNext($event, otp3)" (keydown.backspace)="moveToPrevious($event, otp1)" />
                            <input type="number" class="otp-input" maxlength="1" #otp3 formControlName="otp3"
                                (input)="moveToNext($event, otp4)" (keydown.backspace)="moveToPrevious($event, otp2)" />
                            <input type="number" class="otp-input" maxlength="1" #otp4 formControlName="otp4"
                                (input)="moveToNext($event, otp5)" (keydown.backspace)="moveToPrevious($event, otp3)" />
                            <input type="number" class="otp-input" maxlength="1" #otp5 formControlName="otp5"
                                (input)="moveToNext($event, otp6)" (keydown.backspace)="moveToPrevious($event, otp4)" />
                            <input type="number" class="otp-input" maxlength="1" #otp6 formControlName="otp6"
                                (keydown.backspace)="moveToPrevious($event, otp5)" (input)="focusVerifyButton()" />
                        </div>
                        <a class="resend" *ngIf="mobileVerificationClicked && timer=='0:00'  && !mobileVerified"
                            (click)="ResendCodeForMobile()">Resend Code</a>
                        <div class="button_group">
                            <button *ngIf="mobileVerificationClicked" (click)="backClickedOnMobile()">Back</button>
                            <button class="cta" #verifyButton *ngIf="mobileVerificationClicked && !mobileVerified"
                                (click)="verifyMobile()">Verify</button>
                            <button class="cta" *ngIf="mobileVerified" (click)="MobileNextCLicked()">Next</button>
                        </div>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="!showWrongText">
                        Wrong Code Entered Try Again
                    </div>
                </div>

                <div class="child" *ngIf="mobileNumberClicked==true && emailClicked ==false">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <label>Email</label>
                    <input type="email" class="custom__field" placeholder="format: abc@xyz" formControlName="email"
                    (input)="emailvalidation($event)" disabled
                    [ngClass]="{ 'error__field': !email.valid && email.touched ,'success':emailVerified}" *ngIf="edit"/>
                    <input type="email" class="custom__field" placeholder="format: abc@xyz" formControlName="email"
                        (input)="emailvalidation($event)"
                        [ngClass]="{ 'error__field': !email.valid && email.touched ,'success':emailVerified}" *ngIf="!edit"/>

                    <div [hidden]="!email.touched">
                        <span class="error__msg" *ngIf="email.hasError('required')">Please enter email</span>
                        <span class="error__msg" *ngIf="email.hasError('email')">Email is invalid</span>
                        <span class="error__msg" *ngIf="email.errors?.duplicate">Email is taken</span>
                    </div>
                    <p *ngIf="emailVerified" style="color:green">Verified<span class="verified">&#10004;</span> </p>


                    <div class="button_group">
                        <button *ngIf="!emailVerificationClicked" (click)="backClickedOnEmail()">Back</button>
                        <button class="cta" *ngIf="!emailVerificationClicked" [disabled]="!email.valid"
                            (click)="sendVerificationCodeToEmail()">Request Otp</button>
                    </div>

                    <div *ngIf="emailVerificationClicked">
                        <label for="otp" *ngIf="emailVerificationClicked && !emailVerified"> Enter OTP </label> <span
                            *ngIf="timer!='0:00' && !emailVerified " class="timer"> {{ timer }}</span>
                        <div class="otp-container" *ngIf="emailVerificationClicked && !emailVerified">
                            <input type="number" class="otp-input" maxlength="1" #otp1 formControlName="otp1"
                                (input)="moveToNext($event, otp2)" />
                            <input type="number" class="otp-input" maxlength="1" #otp2 formControlName="otp2"
                                (input)="moveToNext($event, otp3)" (keydown.backspace)="moveToPrevious($event, otp1)" />
                            <input type="number" class="otp-input" maxlength="1" #otp3 formControlName="otp3"
                                (input)="moveToNext($event, otp4)" (keydown.backspace)="moveToPrevious($event, otp2)" />
                            <input type="number" class="otp-input" maxlength="1" #otp4 formControlName="otp4"
                                (input)="moveToNext($event, otp5)" (keydown.backspace)="moveToPrevious($event, otp3)" />
                            <input type="number" class="otp-input" maxlength="1" #otp5 formControlName="otp5"
                                (input)="moveToNext($event, otp6)" (keydown.backspace)="moveToPrevious($event, otp4)" />
                            <input type="number" class="otp-input" maxlength="1" #otp6 formControlName="otp6"
                                (keydown.backspace)="moveToPrevious($event, otp5)" (input)="focusVerifyButton()" />
                        </div>
                        <a class="resend" *ngIf="emailVerificationClicked && timer=='0:00'  && !emailVerified"
                            (click)="ResendCodeForEmail()">Resend Code</a>
                        <div class="button_group">
                            <button *ngIf="emailVerificationClicked" (click)="backClickedOnEmail()">Back</button>
                            <button class="cta" #verifyButton *ngIf="emailVerificationClicked && !emailVerified"
                                (click)="verifyEmail()">Verify</button>
                            <button class="cta" *ngIf="emailVerified" (click)="EmailNextCLicked()">Next</button>
                        </div>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="!showWrongTextforEmail">
                        Wrong Code Entered Try Again
                    </div>
                </div>


                <div class="child" *ngIf="emailClicked==true && companyNamePage ==false">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>

                    <label>My {{userType.value}} Bussiness Name is </label>
                    <input type="text" autocomplete="off" class="custom__field" formControlName="companyName" />

                    <label>My Name is</label>
                    <input type="text" autocomplete="off" class="custom__field" formControlName="contactPersonName" />
                    <div [hidden]="!contactPersonName.touched">
                        <span class="error__msg" *ngIf="contactPersonName.hasError('required')">Please enter Name</span>
                    </div>
                    <div class="button_group">
                        <button (click)="backClickedOnCompanyName()">Back</button>
                        <button class="cta" [disabled]="!contactPersonName.valid"
                            (click)="CompanynameNextClicked()">Next</button>
                    </div>
                </div>


                <div class="child" *ngIf="companyNamePage==true && photoPage ==false">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>

                    <label>Upload Photo</label>
                    <input class="custom__field" type="file" id="document" name="document" accept=".jpg, .jpeg, .png"
                        (change)="handlePhoto($event)" />

                    <label>Postal Registration</label>
                    <input class="custom__field" type="text" formControlName="postalDetails" />

                    <div class="button_group">
                        <button (click)="backClickedOnPhoto()">Back</button>
                        <button class="cta" [disabled]="!postalDetails.valid||photoLink==''"
                            (click)="photoPageNextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf="adharPage==false && photoPage ==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>

                    <label> Aadhar Card Number </label>
                    <input type="text" autocomplete="off" class="custom__field" maxlength="12" pattern="[0-9]*" formControlName="adharCard" />
                    <div [hidden]="!adharCard.touched">
                        <span class="error__msg" *ngIf="adharCard.hasError('required')">Please enter 12 digit Aadhar
                            number</span>
                        <span class="error__msg" *ngIf="adharCard.hasError('minlength')">Aadhar should be 12 digit number</span>

                        <span class="error__msg" *ngIf="adharCard.hasError('maxlength')">Aadhar should be 12 digit number</span>
                        <span class="error__msg" *ngIf="adharCard.hasError('pattern')">Aadhar shouldcontain only numbers</span>
                    </div>

                    <label>Upload Aadhar Card</label>
                    <input class="custom__field" type="file" accept=".jpg, .jpeg, .png,.pdf" (change)="handleAdhar($event)">
                    <div class="button_group">
                        <button (click)="backClickedonAdhar()">Back</button>
                        <button class="cta" [disabled]="!adharCard.valid||adharLink==''"
                            (click)="adharPageNextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf="panPage==false && adharPage ==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <label> Pan Card Number </label>
                    <input type="text" autocomplete="off" class="custom__field" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                    formControlName="panCard" maxlength="10" />
                    <div [hidden]="!panCard.touched">
                        <span class="error__msg" *ngIf="panCard.hasError('required')">Please enter 10 digit Pan number
                            number</span>
                        <span class="error__msg" *ngIf="panCard.hasError('minlength')">Pan should be 10 digit number</span>

                        <span class="error__msg" *ngIf="panCard.hasError('maxlength')">pan should be 10 digit number</span>
                        <span class="error__msg" *ngIf="panCard.hasError('pattern')">pan should be 10 digit Valid pan card number in correct Format</span>
                    </div>
                    <label>Upload Pan Card</label>
                    <input class="custom__field" type="file" id="document" name="document"  accept=".jpg, .jpeg, .png,.pdf"
                        (change)="handlePan($event)" />
                    <div class="button_group">
                        <button (click)="backClickedonPan()">Back</button>
                        <button class="cta" [disabled]="!panCard.valid||panLink==''"
                            (click)="panPageNextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf="landlinePage==false && panPage ==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>

                    <label>Landline</label>
                    <input type="text" class="custom__field" placeholder="format: +91......" formControlName="landline"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '')" pattern="^\d{10}$" maxlength="10"
                        [ngClass]="{ error__field: !landline.valid && landline.touched }" />
                    <div [hidden]="!landline.touched">
                        <span class="error__msg" *ngIf="landline.hasError('required')">Please enter landline
                            number</span>
                        <span class="error__msg" *ngIf="landline.hasError('pattern')">Number is invalid</span>
                        <span class="error__msg" *ngIf="landline.errors?.duplicate">Number is taken</span>
                    </div>

                    <label>Address</label>
                    <input type="text" class="custom__field" formControlName="address" required />
                    <div class="button_group">
                        <button (click)="backClickedOnLandline()">Back</button>
                        <button class="cta" [disabled]="!address.valid||!landline.valid"
                            (click)="landlinePageNextClicked()">Next</button>
                    </div>
                </div>


                <div class="child" *ngIf="addressPage==false && landlinePage ==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>

                    <input type="checkbox" id="agree" name="agree" (change)="pincodeChecked()">
                    <label for="knowcode">Please check the box if you know the PIN code</label>

                    <div *ngIf="pincodebox==true">
                        <label>Pincode</label>
                        <input *ngIf="pincodebox==true" type="text" autocomplete="off" class="custom__field"
                            formControlName="pincode" (change)="getLocation()" />
                        <div [hidden]="!pincode.touched">
                            <span class="error__msg" *ngIf="pincode.hasError('required')">Please enter Pincode</span>
                        </div>
                    </div>
                    <div class="stateClass">
                        <label>State</label>
                        <select class="custom__field" style="width:80%" formControlName="stateName"
                            (change)="getCities()">
                            <option *ngFor="let state of states" [value]="state">
                                {{ state}}
                            </option>
                        </select>
                        <div [hidden]="!stateName.touched"
                            *ngIf="mobileVerificationClicked && emailVerificationClicked">
                            <span class="error__msg" *ngIf="stateName.hasError('required')">Please select State</span>
                        </div>
                   

                    <label>City</label>
                    <select class="custom__field" style="width:80%" formControlName="cityName" (change)="getPincode()">
                        <option *ngFor="let city of cities" [value]="city">
                            {{ city}}
                        </option>
                    </select>
                    <div [hidden]="!cityName.touched">
                        <span class="error__msg" *ngIf="cityName.hasError('required')">Please enter city</span>
                    </div>
                </div>

                    <label *ngIf="pincodebox!=true">Pincode</label>
                    <select *ngIf="pincodebox!=true" style="width:80%" class="custom__field" formControlName="pincode">
                        <option *ngFor="let aclGroup of pincodes" [value]="aclGroup">
                            {{ aclGroup}}
                        </option>
                    </select>
                    <div [hidden]="!pincode.touched">
                        <span class="error__msg" *ngIf="pincode.hasError('required')">Please enter pincode</span>
                    </div>
                    <div class="button_group">
                        <button (click)="backClickedonAddress()">Back</button>
                        <button class="cta" [disabled]="!pincode.valid || !stateName.valid||!cityName.valid" (click)="addressPageNextClicked()">Next</button>
                    </div>

                </div>

                <div class="child" *ngIf="GSTPage==false && addressPage ==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>

                    <label *ngIf="GstNumberbox == true"> GST Number </label>
                    <input *ngIf="GstNumberbox == true"  accept=".jpg, .jpeg, .png,.pdf,.docx" minlength="15" maxlength="15" type="text" autocomplete="off" class="custom__field"
                        formControlName="gstNumber"  pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"/>
                        <div [hidden]="!gstNumber.touched" *ngIf="GstNumberbox">
                            <span class="error__msg" *ngIf="gstNumber.hasError('required')">Please enter 15 digit GST
                                number</span>
                            <span class="error__msg" *ngIf="gstNumber.hasError('minlength')">GST should be 15 digit number</span>
    
                            <span class="error__msg" *ngIf="gstNumber.hasError('maxlength')">GST should be 15 digit number</span>
                            <span class="error__msg" *ngIf="gstNumber.hasError('pattern')">GSt is not valid please check pattern</span>
                        </div>
                    <label *ngIf="!GstNumberbox"> Self Declaration Form </label>
                    <input *ngIf="!GstNumberbox" type="button" value="Download file" class="custom__field"
                        (click)="downloadFile()" />

                    <label>Upload GST</label>
                    <input class="custom__field" type="file" accept=".docx" (change)="handleGST($event)">
                    <div class="button_group">
                        <button style="margin: 10px;" (click)="GstNumberChecked()"> {{ GstNumberbox==false ? 'Have GST?'
                            :
                            'No GST?'
                            }}
                        </button>
                        <button (click)="backClickedonGST()">Back</button>
                        <button class="cta" [disabled]="gstLink==''||(GstNumberbox == true && !gstNumber.valid)" (click)="GSTPageNextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf="passwordPage==false && GSTPage==true">

                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <div class="password-field_show-hide">
                    <label>New Password</label>
                    <input [type]="showPassword ? 'text' : 'password'" class="custom__field" formControlName="password"
                        [ngClass]="{
                    error__field:
                      !password.valid && password.touched && !passwordStringMatch()
                  }" />
                    <img (click)="showPassword = !showPassword"
                        [src]="!showPassword ? '/assets/icons/eye.svg' : '/assets/icons/eye-no.svg'" alt="Show Password">
                    <br />
                    <div [hidden]="!password.touched">
                        <span [ngStyle]="style" class="error__msg" *ngIf="password.hasError('required')">Required</span>
                        <span [ngStyle]="style" class="error__msg" *ngIf="!passwordStringMatch()">
                            Must contain at least one lowercase letter, one uppercase letter,
                            one numeric digit, and one special character and 8 digits long
                        </span>
                        <span [ngStyle]="style" class="error__msg" *ngIf="password.hasError('minlength')">Should be atleast
                            8 characters long</span>
                    </div>
                    </div>
                    <div class="password-field_show-hide">
                    <label>Confirm Password</label>
                    <input [type]="showConfirmPassword? 'text' : 'password'" class="custom__field"
                        formControlName="confirmPassword"
                        [ngClass]="{ error__field: !cpassword.valid && cpassword.touched }" />
                    <img (click)="showConfirmPassword = !showConfirmPassword"
                        [src]="!showConfirmPassword ? '/assets/icons/eye.svg' : '/assets/icons/eye-no.svg'"
                        alt="Show Password">
                    <div [hidden]="!cpassword.touched">
                        <span class="error__msg" *ngIf="userForm.errors?.mismatch">Passwords do not match</span>
                    </div>
    </div>
    
                    <div class="button_group">
                        <button (click)="backClickedOnPasswordPage()">Back</button>
                        <button class="cta" [disabled]="!password.valid || !cpassword.valid|| userForm.errors?.mismatch" (click)="passwordPagePageNextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf=" userType.value=='LCO' && lcoamazingProgressPage==false && passwordPage==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <!-- <h6 class="small-text"><span class="star">*</span> Required Fields</h6> -->
                    <h5 class="centered">Amazing Progress, <br>Lets verify you with Captcha Now</h5>
                    <div class="button_group">
                        <button (click)="backClickedOnlocAmazingPage()">Back</button>
                        <button class="cta" (click)="lcoAmazingpageNextClicked()">Next</button>
                    </div>
                </div>
                <div class="child" *ngIf="userType.value=='Distributor' && networkPage==false && passwordPage == true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <!-- <h6 class="small-text"><span class="star">*</span> Required Fields</h6> -->
                    <h5 class="centered">Amazing Process <br>lets get into Networks now</h5>
                    <div class="button_group">
                        <button (click)="backClickedOndisAmazingPage()">Back</button>
                        <button class="cta" (click)="disAmazingpageNextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf="networkPage1==false && networkPage==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <label>Exchange Name</label>
                    <input class="custom__field" type="text" formControlName="exchangeName" required />

                    <label>Exchange Location</label>
                    <input class="custom__field" type="text" formControlName="exchangeLocation" required />
                    <div class="button_group">
                        <button (click)="backClickedOnExchangePage()">Back</button>
                        <button [disabled]="!exchangeName.valid||!exchangeLocation.valid" class="cta" (click)="exchangePageNextClicked()">Next</button>
                    </div>
                </div>
                <div class="child" *ngIf="networkPage2==false && networkPage1==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <label>DataCenter Name</label>
                    <input class="custom__field" type="text" formControlName="dataCenterName" required/>
                    <label>DataCenter Address</label>
                    <input class="custom__field" type="text" formControlName="dataCenterLocation" required/>
                    <div class="button_group">
                    <button  (click)="backClickedOnDataCenterPage()">Back</button>
                    <button  [disabled]="!dataCenterName.valid||!dataCenterLocation.valid" class="cta" (click)="dataCenterNextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf="networkPage3==false && networkPage2==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <label>Ipv4</label>
                    <input class="custom__field" type="text" formControlName="ipv4"  required pattern="\b(?:[0-9]{1,3}\.){3}[0-9]{1,3}\b"/>
                    <div [hidden]="!ipv4.touched">
                        <span class="error__msg" *ngIf="ipv4.errors?.pattern">Please Check Pattern</span>
                    </div>
                    <label>Ipv6</label>
                    <input class="custom__field" type="text" formControlName="ipv6" required />
                    <!-- pattern="^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$ -->
                    <!-- "/>
                    <div [hidden]="!ipv6.touched">
                        <span class="error__msg" *ngIf="ipv6.errors?.pattern">Please Check Pattern</span>
                    </div> -->
                    <div class="button_group">
                    <button  (click)="backClickedOnIpv4Page()">Back</button>
                    <button class="cta"  [disabled]="!ipv4.valid||!ipv6.valid"  (click)="Ipv4NextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf="networkPage4==false && networkPage3==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <label>ASN</label>
                    <input class="custom__field" type="text" formControlName="asn" required/>
                    <label>IP pool</label>
                    <input class="custom__field" type="text" formControlName="ipPool" required />
                    <div class="button_group">
                    <button  (click)="backClickedOnASNPage()">Back</button>
                    <button class="cta"  [disabled]="!asn.valid||!ipPool.valid" (click)="ASNNextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf="networkPage5==false && networkPage4==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <label>ISB License</label>
                    <input class="custom__field" type="text" formControlName="isbLicense"  required/>
                    <label for="document" class="special-label"> Upload ISB License</label>
                    <input class="custom__field" type="file" id="document" name="document"
                        (change)="handleISB($event)" />
                        <div class="button_group">
                    <button  (click)="backClickedOnISBPage()">Back</button>
                    <button class="cta"  [disabled]="!isbLicense.valid||isbLink==''" (click)="ISBPageNextClicked()">Next</button>
                    </div>
                </div>

                <div class="child" *ngIf="networkPage6==false && networkPage5==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <label>Ip Address</label>
                    <input class="custom__field" type="text" formControlName="ipAddress"  required pattern="\b(?:[0-9]{1,3}\.){3}[0-9]{1,3}\b"/>
                    <!-- pattern="'^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'"/> -->
                    <div [hidden]="!ipAddress.touched">
                        <span class="error__msg" *ngIf="ipAddress.errors?.pattern">Please Check Pattern</span>
                    </div>
                    <div class="button_group">
                    <button  (click)="backClickedOnIPAddressPage()">Back</button>
                    <button class="cta"  [disabled]="!ipAddress.valid" (click)="IPAddressPageNextClicked()">Next</button>
                    </div>
                </div>
                <div class="child" *ngIf="lcoamazingProgressPage==true && lcocaptchaPage == false">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <div class="captcha-container">
                        <span class="captcha-code">
                            <span class="captcha-letter" style="font-size: 24px;">{{ captcha[0] }}</span>
                            <span class="captcha-letter" style="font-size: 32px;">{{ captcha[1] }}</span>
                            <span class="captcha-letter" style="font-size: 27px;">{{ captcha[2] }}</span>
                            <span class="captcha-letter" style="font-size: 26px;">{{ captcha[3] }}</span>
                            <span class="captcha-letter" style="font-size: 34px;">{{ captcha[4] }}</span>
                            <span class="captcha-letter" style="font-size: 25px;">{{ captcha[5] }}</span>
                        </span>
                        <img src="/assets/refresh-icon.svg" class="refrehimg" (click)="generateCaptcha()">
                    </div>

                    <label>Enter Captcha</label>
                    <input type="text" class="custom__field" placeholder="Enter Text shown above here"
                        formControlName="captchaInput" />

                    <div *ngIf="captchaInput.invalid && captchaInput.touched" class="error__msg">
                        CAPTCHA is incorrect
                    </div>
                    <div class="button_group">
                    <button  (click)="backClickedOnCaptchaPage()">Back</button>
                    <button class="cta" *ngIf="!edit"  [disabled]="saveClicked" (click)="NextCLickedonCaptchaPage()">Save</button>
                    <button class="cta" *ngIf="edit" [disabled]="saveClicked" (click)="NextCLickedonCaptchaPage()">Save</button>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="wrongcaptchavalue">
                        Captcha Code is not Correct
                    </div>
                </div>


                <div class="child" *ngIf="paymentpage==false && lcocaptchaPage==true">
                    <h5><b>{{userType.value}} Registration Form</b></h5>
                    <h6 class="small-text"><span class="star">*</span> Required Fields</h6>
                    <h5 class="centered">To complete the registration, <br>You have to Pay sum of <br>
                        {{ userType.value == 'LCO' ? '₹11800' : '₹29500' }}<h6>(including GST )</h6>
                    </h5>
                    <label *ngIf="refrealCodeCheck">Refferal Code</label>
                    <input class="custom__field" type="text" formControlName="regCode"  *ngIf="refrealCodeCheck"required/>
                    <div class="button_group">
                    <button  (click)="backClickedonpaymentPage()">Back</button>
                    <button (click)="refrealCodeCheck=!refrealCodeCheck" *ngIf="refrealCodeCheck">No Coupon?

                    </button>
                    <button (click)="refrealCodeCheck=!refrealCodeCheck" *ngIf="!refrealCodeCheck">Have Coupon?</button>
                    <button class="cta" *ngIf="paymentStatus!='Success'" (click)="payment()">Next</button>
                    <button class="cta" [disabled]="paymentStatus!='Success'"  *ngIf="!refStatus" (click)="persist()">Save</button>
                    <button class="cta"  *ngIf="refStatus" (click)="persist()">Save</button>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="paymentStatus=='Failure'">
                         failed Try Again
                    </div>
                    <div class="alert alert-success" role="alert" *ngIf="paymentStatus=='Success'">
                          Success proceed with save
                    </div>
                </div>



            </div>

            <div class="parent" *ngIf="successPage==true">
                <div style="display: flex;flex-direction: row; gap:8px;margin-bottom:15px">
                <img style="width:50px"src="/assets/tick.png">
                <h5 >Congratulations!!<br> Welcome to Skypro family</h5>
            </div>
            <h6>You will receive a conformation mail along with User ID and Password.<br> You can access the portal here</h6>
           
                <h6 style="border:1px solid #707070;
                border-radius: 5px;width: fit-content;padding: 2%;" *ngIf="userType.value!='LCO'">https://distributor.skypro.co.in 
                <img (click)="copyToClipboard('https://distributor.skypro.co.in')" src="assets/icons/copy_outline.svg" /></h6>

                <h6 style="border:1px solid #707070;
                border-radius: 5px;width: fit-content;padding: 2%;" *ngIf="userType.value=='LCO'">https://lco.skypro.co.in
                <img (click)="copyToClipboard('https://lco.skypro.co.in')" src="assets/icons/copy_outline.svg" /></h6>
            </div>
        </form>

    </div>