import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeManagerRoutingModule } from './theme-manager-routing.module';
import { ThemeHomeComponent } from './theme-home/theme-home.component';
import { ColorSelectorComponent } from './color-selector/color-selector.component';
import { BackgroundChangerComponent } from './background-changer/background-changer.component';


@NgModule({
  declarations: [
    ThemeHomeComponent,
    ColorSelectorComponent,
    BackgroundChangerComponent
  ],
  imports: [
    CommonModule,
    ThemeManagerRoutingModule
  ]
})
export class ThemeManagerModule { }
