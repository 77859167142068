<div class="top__navbar">
  <div class="container__custom">
    <div style="width: 150px">
      IPTV
      <strong _ngcontent-hmd-c33=""
        >SMS<span style="font-size: 8px"> v1.14.07</span></strong
      >
    </div>
    <ng-container *ngIf="menuData[menuMapping['Dashboard']]">
      <div class="button__class">
        <button class="navButton" [routerLink]="['dashboard']">
          Dashboard
        </button>
        <button class="show__mobile" (click)="mobileMenu()">
          <img src="./assets/circle-menu.svg" alt="" />
        </button>
      </div>
    </ng-container>
    <ul *ngIf="showMobileFlag">
      <ng-container *ngIf="isNotMso">
        <li>
          <div
            class="accounts amount"
            [routerLink]="[
              userRole == 'LCO'
                ? '/profile/wallet/lco-wallet-recharge'
                : '/profile/wallet/distributor-wallet-recharge'
            ]"
          >
            <img src="assets/icons/wallet.svg" alt="" />
            <span>{{ amount }} &#8377;</span>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="menuData[menuMapping['Manage']]">
        <li [routerLinkActive]="['active']">
          <div class="accounts">
            <span (click)="openMenu('manage')">Manage</span>
            <div
              class="submenu--options"
              [ngClass]="{ opened: manageFlag }"
              (click)="manageFlag = !manageFlag"
            >
              <ul
                appClickOutsideCustom
                (clickOutside)="clickOutside($event, true)"
                [listen]="listen"
              >
                <ng-container *ngIf="menuData[menuMapping['MSO']]">
                  <li
                    [routerLink]="['mso']"
                    [routerLinkActive]="['active__link']"
                    *msoAccess
                    (click)="screenWidthDetect()"
                  >
                    MSO
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['LCO']]">
                  <li
                    [routerLink]="['lco']"
                    [routerLinkActive]="['active__link']"
                    *msoAccess
                    (click)="screenWidthDetect()"
                  >
                    LCO
                  </li>
                </ng-container>
                <ng-container>
                  <li
                    [routerLink]="['/manage/distributor/list']"
                    [routerLinkActive]="['active__link']"
                    *msoAccess
                    (click)="screenWidthDetect()"
                  >
                    Distributor
                  </li>
                </ng-container>
                <ng-container>
                  <li
                    [routerLink]="['bouquet/management']"
                    [routerLinkActive]="['active__link']"
                    *lcoAccess
                    (click)="screenWidthDetect()"
                  >
                    Bouquet
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['Subscriber']]">
                  <li
                    [routerLink]="['subscriber']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Subscriber
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['STB']]">
                  <li
                    [routerLink]="['stb']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    STB
                  </li>
                </ng-container>
                <ng-container
                  *ngIf="menuData[menuMapping['Subscriber Groups']]"
                >
                  <li
                    [routerLink]="['subscriber-groups']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Subscriber Groups
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['Location']]">
                  <li
                    [routerLink]="['location']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Location
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="menuData[menuMapping['Content']]">
        <li [routerLinkActive]="['active']" *msoAccess>
          <div class="content">
            <span (click)="openMenu('content')">Content</span>
            <div
              class="submenu--options"
              [ngClass]="{ opened: contentFlag }"
              (click)="contentFlag = !contentFlag"
            >
              <ul>
                <ng-container *ngIf="menuData[menuMapping['Broadcaster']]">
                  <li
                    [routerLink]="['broadcaster']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Broadcaster
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['Channel']]">
                  <li
                    [routerLink]="['channel']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Channel
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['Category']]">
                  <li
                    [routerLink]="['category']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Category
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['Bouquet']]">
                  <li
                    [routerLink]="['packages']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Bouquet
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="menuData[menuMapping['Communication']]">
        <li [routerLinkActive]="['active']" *msoAccess>
          <div class="communications">
            <span (click)="openMenu('communications')">Communication</span>
            <div
              class="submenu--options"
              [ngClass]="{ opened: communicationsFlag }"
              (click)="communicationsFlag = !communicationsFlag"
            >
              <ul>
                <ng-container *ngIf="menuData[menuMapping['Mail']]">
                  <li
                    [routerLink]="['mail']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Mail
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['OSD']]">
                  <li
                    [routerLink]="['osd']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    OSD
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['Fingerprint']]">
                  <li
                    [routerLink]="['fingerprint']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Fingerprint
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </li>
      </ng-container>

      <!-- <ng-container *ngIf="menuData[menuMapping['Plugins']]">
        <li [routerLinkActive]="['active']">
          <div class="plugins">
            <span (click)="openMenu('plugin')">Plugins</span>
            <div class="submenu--options" [ngClass]="{'opened':pluginsFlag}" (click)="pluginsFlag=!pluginsFlag">
              <ul>
                <ng-container *ngIf="menuData[menuMapping['Transcoder']]">
                  <li [routerLink]="['transcoder']" [routerLinkActive]="['active__link']">Transcoder</li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['EPG']]">
                  <li [routerLink]="['epg']" [routerLinkActive]="['active__link']">EPG</li>
                </ng-container>
              </ul>
            </div>
          </div>
        </li>
      </ng-container> -->

      <ng-container>
        <li [routerLinkActive]="['active']">
          <div class="plugins">
            <span (click)="openMenu('subscription')">Subscription</span>
            <div
              class="submenu--options"
              [ngClass]="{ opened: subscriptionFlag }"
              (click)="subscriptionFlag = !subscriptionFlag"
            >
              <ul>
                <ng-container>
                  <li
                    [routerLink]="['subscription', 'add-subscription']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Add Subscription
                  </li>
                </ng-container>
                <ng-container>
                  <li
                    [routerLink]="['subscription', 'renew-subscription']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Renew Subscription
                  </li>
                </ng-container>
                <ng-container>
                  <li
                    [routerLink]="['subscription', 'remove-subscription']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Remove Subscription
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="menuData[menuMapping['Logs']]">
        <li
          [routerLink]="['batch-job/']"
          [routerLinkActive]="['active']"
          *msoAccess
          (click)="screenWidthDetect()"
        >
          <div class="batchJobs">
            <span>Batch Jobs</span>
          </div>
        </li>
      </ng-container>
      <!--
      <ng-container>
        <li [routerLinkActive]="['active']" *msoAccess >
          <div class="plugins">
            <span (click)="openMenu('inventory')">Inventory</span>
            <div
              class="submenu--options"
              [ngClass]="{ opened: inventoryFlag }"
              (click)="inventoryFlag = !inventoryFlag"
            >
              <ul>
                <ng-container>
                  <li
                    [routerLink]="['inventory', 'vendors']"
                    [routerLinkActive]="['active__link']"
                  >
                    Vendors & Suppliers
                  </li>
                  <li
                    [routerLink]="['inventory', 'category']"
                    [routerLinkActive]="['active__link']"
                  >
                    Category
                  </li>
                  <li
                    [routerLink]="['inventory', 'products']"
                    [routerLinkActive]="['active__link']"
                  >
                    Products
                  </li>
                </ng-container>
                <ng-container >
                  <li [routerLink]="['inventory','renew-subscription']" [routerLinkActive]="['active__link']">Renew Subscription</li>
                </ng-container>
                <ng-container>
                  <li [routerLink]="['inventory','remove-subscription']" [routerLinkActive]="['active__link']">Remove Subscription</li>
                </ng-container>
              </ul>
            </div>
          </div>
        </li>
      </ng-container> -->

      <ng-container *ngIf="menuData[menuMapping['Reports']]">
        <li [routerLinkActive]="['active']" *msoAccess>
          <div class="reports">
            <span (click)="openMenu('reports')">Reports</span>
            <div
              class="submenu--options"
              [ngClass]="{ opened: reportFlag }"
              (click)="reportFlag = !reportFlag"
            >
              <ul>
                <!-- <li [routerLink]="['reports']">Console</li> -->
                <ng-container *ngIf="menuData[menuMapping['TRAI']]">
                  <li
                    [routerLink]="['trai-reports']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    TRAI
                  </li>
                </ng-container>
                <!-- <li>Broadcaster</li> -->
                <!-- <li>LCO</li> -->
                <!-- <li>MSO</li> -->
              </ul>
            </div>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="menuData[menuMapping['Logs']]">
        <li
          [routerLink]="['logs/']"
          [routerLinkActive]="['active']"
          (click)="screenWidthDetect()"
        >
          <div class="logs">
            <span (click)="openMenu('logs')">Logs</span>
            <!-- <div class="submenu--options" [ngClass]="{'opened':logFlag}" (click)="logFlag=!logFlag">
              <ul>
                <ng-container *ngIf="menuData[menuMapping['Authentication']]">
                  <li [routerLink]="['logs/logs-user-auth']" [routerLinkActive]="['active__link']">Authentication</li>
                </ng-container>
                  <ng-container *ngIf="menuData[menuMapping['User Activity']]">
                  <li [routerLink]="['logs/logs-user-activity']" [routerLinkActive]="['active__link']">User Activity</li>
                </ng-container>
                  <ng-container *ngIf="menuData[menuMapping['Bouquet Status']]">
                  <li [routerLink]="['logs/logs-bouquet-status']" [routerLinkActive]="['active__link']">Bouquet Status</li>
                </ng-container>
                  <ng-container *ngIf="menuData[menuMapping['Channel Status']]">
                  <li [routerLink]="['logs/logs-channel-status']" [routerLinkActive]="['active__link']">Channel Status</li>
                </ng-container>
                  <li [routerLink]="['logs/logs-product-composition']">Product Composition</li>
                <ng-container *ngIf="menuData[menuMapping['STB Connection']]">
                  <li [routerLink]="['logs/logs-stb-connection']" [routerLinkActive]="['active__link']">STB Connection</li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['Complete Logs']]">
                  <li [routerLink]="['logs/logs-complete']" [routerLinkActive]="['active__link']">Complete Logs</li>
                </ng-container>
                  <ng-container *ngIf="menuData[menuMapping['STB Activate/Deactivate']]">
                  <li [routerLink]="['logs/logs-stb-activate-deactivate']" [routerLinkActive]="['active__link']">STB Activate/Deactivate</li>
                </ng-container>
                  <ng-container *ngIf="menuData[menuMapping['Subscription/ Desubscription']]">
                  <li [routerLink]="['logs/logs-subscription-desubscription']" [routerLinkActive]="['active__link']">Subscription/ Desubscription</li>
                </ng-container>
                  <ng-container *ngIf="menuData[menuMapping['Bouquet Modification']]">
                  <li [routerLink]="['logs/logs-bouquet-modification']" [routerLinkActive]="['active__link']">Bouquet Modification</li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['Channel Modification']]">
                  <li [routerLink]="['logs/logs-channel-modification']" [routerLinkActive]="['active__link']">Channel Modification</li>
                </ng-container>
              </ul>
            </div> -->
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="menuData[menuMapping['Settings']]">
        <li [routerLinkActive]="['active']">
          <div class="settings">
            <span (click)="openMenu('settings')">Settings</span>
            <div
              class="submenu--options"
              [ngClass]="{ opened: settingsFlag }"
              (click)="settingsFlag = !settingsFlag"
            >
              <ul>
                <ng-container *ngIf="menuData[menuMapping['General Settings']]">
                  <li
                    [routerLink]="['settings']"
                    [routerLinkActive]="['active__link']"
                    *msoAccess
                    (click)="screenWidthDetect()"
                  >
                    General Settings
                  </li>
                </ng-container>
                <ng-container *ngIf="menuData[menuMapping['Access Control']]">
                  <li
                    [routerLink]="['acl']"
                    [routerLinkActive]="['active__link']"
                    *msoAccess
                    (click)="screenWidthDetect()"
                  >
                    Access Control
                  </li>
                </ng-container>
                <li
                  [routerLink]="['ad-manager']"
                  [routerLinkActive]="['active__link']"
                  *msoAccess
                  (click)="screenWidthDetect()"
                >
                  Ad Manager
                </li>
                <li
                  [routerLink]="['ota-manager']"
                  [routerLinkActive]="['active__link']"
                  *msoAccess
                  (click)="screenWidthDetect()"
                >
                  OTA Manager
                </li>
                <li
                  [routerLink]="['theme']"
                  [routerLinkActive]="['active__link']"
                  (click)="screenWidthDetect()"
                >
                  Theme Manager
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="menuData[menuMapping['Accounts']]">
        <li [routerLinkActive]="['active']">
          <div class="accounts">
            <span (click)="openMenu('accounts')">Accounts</span>
            <div
              class="submenu--options"
              [ngClass]="{ opened: accountsFlag }"
              (click)="accountsFlag = !accountsFlag"
            >
              <ul>
                <ng-container
                  *ngIf="menuData[menuMapping['Subscriber Revenue']]"
                >
                  <!-- <li
                    [routerLink]="['accounts/subscriber-revenue']"
                    [routerLinkActive]="['active__link']"
                  >
                    Subscriber Revenue
                  </li> -->
                </ng-container>
                <!-- <ng-container *ngIf="menuData[menuMapping['MSO Payments']]">
                  <li
                    [routerLink]="['accounts/mso-payments']"
                    [routerLinkActive]="['active__link']"
                  >
                    MSO Payments
                  </li>
                </ng-container> -->
                <ng-container *ngIf="menuData[menuMapping['Invoice']]">
                  <li
                    [routerLink]="['accounts/invoice']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Invoice
                  </li>
                  <li
                    [routerLink]="['profile/wallet']"
                    [routerLinkActive]="['active__link']"
                    (click)="screenWidthDetect()"
                  >
                    Wallet
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </li>
      </ng-container>

      <li>
        <div class="profile">
          <img
            (click)="openMenu('profile')"
            class="hide__mobile"
            src="./assets/profile.svg"
          /><span class="show__mobile" (click)="openMenu('profile')"
            >Profile</span
          >
          <div
            class="submenu--options"
            [ngClass]="{ opened: profileFlag }"
            (click)="profileFlag = !profileFlag"
          >
            <ul>
              <li
                [routerLink]="['profile/profile']"
                [routerLinkActive]="['active__link']"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="screenWidthDetect()"
              >
                My Profile
              </li>
              <!-- <li
                [routerLink]="['profile/my-packs']"
                [routerLinkActive]="['active__link']"
                *lcoAccess
              >
                My Packs
              </li> -->
              <!-- <li [routerLink]="['wallet']"  [routerLinkActive]="['active']">Wallet</li> -->

              <!-- <li [routerLink]='["profile/my-wallet"]' [routerLinkActive]="['active__link']">My Wallet</li> -->
              <li (click)="logout()">Logout</li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
