<div class="cms__content">
  <div class="top__heading">
    <h3>Bouquets</h3>
    <a *ngIf="!isDistributor" [routerLink]="['/content/bouquet/create']"><button class="default__button float-right">+
        Add New</button></a>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Total Bouquets</span>
          <span class="list__item">{{TotalBouquetCount}}</span>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Active Bouquets</span>
          <span class="list__item">{{BouquetActiveCount}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="row">


      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
              (keydown.enter)="onSearch()" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()"><img src="./assets/search.png"
                alt="search" /></button>
          </div>
        </div>
      </div>
      <div class="tableBox">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>
                ID
              </th>
              <th (click)="sortByItem(sortByProperties.name)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.name}">
                Name
              </th>

              <th (click)="sortByItem(sortByProperties.desc)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.desc}">
                Description
              </th>
              <th (click)="sortByItem(sortByProperties.price)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.price}">
                Price (in Rs)
              </th>
              <th (click)="sortByItem(sortByProperties.packType)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.packType}">
              BouquetType
            </th>
              <th class="no__sort">
                Channel Count
              </th>
             
              <th (click)="sortByItem(sortByProperties.status)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
                Status
              </th>
              <th (click)="sortByItem(sortByProperties.date)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.date}">
                Created On
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="loadingFlag; else showList">
              <td class="loading" colspan="7">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>

            <ng-template #showList>
              <tr *ngFor="let package of packageList; let i = index">
                <td>{{((paginationObj.pageNumber-1)*paginationObj.pageSize)+(i+1)}}</td>
                <td>{{package.id}}</td>
                <td>{{package.name}}</td>
                <td>{{package.description}}</td>
                <td>{{package.price|currency:'INR'}}</td>
                <td>{{package.packType}}</td>
                <td>{{package.channels.length}}</td>
                <td>
                  <span [ngClass]="{'safe__label' : package.status===1, 'danger__label' : !(package.status===1)}">
                    {{package.status===1?'&#10003;':'X'}}
                  </span>
                </td>
                <td>
                  {{package.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                  <div class="action__items">
                    <button class="cta" (click)="viewBouquetDetails(package)">View</button>
                    <button (click)="edit(package)">Edit</button>
                    <button class="danger" *ngIf="(package.status===1)"  (click)="validateToDisable(package)">Disable</button>
                    <button class="safe" *ngIf="!(package.status==1)" (click)="enable(package)">Enable</button>

                    <!-- <button class="danger" (click)="updateStatus(package)">
                      {{package.status===0?'Activate':'Deactivate'}}
                    </button> -->
                  </div>
                </td>

              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>


<div class="modal_box" *ngIf="viewModal">
 
  <div class="modal_box_inner">
    <button class="cta float-right" (click)="viewModal = !viewModal"> Close </button>
    <!-- <button class="close_button float-right" (click)="viewModal = !viewModal"> <img src="assets/close-line.svg" alt=""></button> -->
    <h3>Bouquet Details</h3>
    <div class="parent">
      <p class="div1">Bouquet ID</p>
      <p class="div2">{{bouquetDetails.id}}</p>
      <p class="div3">Bouquet Name</p>
      <p class="div4">{{bouquetDetails.bouquetName}}</p>
      <p class="div5">Bouquet Hd Channels</p>
      <p class="div6">{{bouquetDetails.hdChannelCount}} Channels</p>
      <p class="div7">Bouquet SD Channels</p>
      <p class="div8">{{bouquetDetails.sdChannelCount}} Channels</p>
      <p class="div18" style="text-align:start">Price</p>
      <p class="div19">{{bouquetDetails.price| currency:'INR'}}</p>
      <div class="div13">
        <h6>Channels</h6>
      </div>

    </div>
    <div class="col2">
      <div class="channel__selector_view">
        <div class="channel__card_view" *ngFor="let channel of bouquetDetails.channels">
          Ch : {{channel.channelNumber}}
          <div class="alt__card">
            <img [src]="prefix+channel.logo" onerror="this.src='assets/imageError.png'" />
          </div>
          {{channel.channelName}}
        </div>
      </div>
    </div>
   
  </div>
</div>



<div class="modal_box" *ngIf="showDisablePopup">
  <div class="modal_box_inner">
    <div class="custom_iptv_table">
     
      <h6 class="warning"><b> Warning: </b> Active Subscription Detected<br/>

        You are about to disable this pack, which is currently part of an active bouquet subscription. Disabling this pack will result in the removal of all active subscriptions associated with this bouquet.
        
        If you are sure you want to proceed, please confirm your action</h6>
        <div class="buttongroup">
        <button class="" (click)="showDisablePopup = !showDisablePopup"> Cancel </button>
        <button class="cta" (click)="disable()"> Confirm </button>
      </div>
      <table class="custom__iptv__table warningtable">
        <tbody>
          <tr>
            <th>sl No</th>
            <th>Subscriber Id</th>
            <th>Subscriber Code</th>
            <th>Subscriber Name</th>
            <th>STB Id</th>
          </tr>
          <ng-container *ngFor="let item of activeSubscriptions;let j = index">
            <tr>
              <td>{{j+1}}</td>
              <td>{{item.subscriberId}}</td>
              <td>{{item.subscriberCode}}</td>
              <td>{{item.subscriberName}}</td>
              <td>{{item.stbId}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
   
  </div>
</div>