<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Channels List</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="col-12 col-md-6">
      <!-- <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p> -->
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
				<img src="./assets/csv.svg" alt="download" />
			</button>
    </div>
  </div>
  <div class="third_box">
    <div class="col-12 col-md-6 report">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div style="display: flex; gap:20px" >
      <div class="table__right">
        <p class="table__summary">Total <span>{{channelReportList.length}}</span> record{{channelReportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div>
  </div>

    <!-- <div class="col-12 col-md-6">

    </div> -->
  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Channel ID</th>
            <th>Channel Name</th>
            <th>Broadcaster Name</th>
            <th>Channel LCN</th>
            <th>Category</th>
            <th>Language</th>
            <th>Genre</th>
            <th>Description</th>
            <th>Updated Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="channelReportList.length ==0" >
            <td colspan="10" style="text-align: center;" >
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
            <tr *ngFor="let report of channelReportList, let j=index">
              <td>{{j+1}}</td>
              <td>{{(report.channel_id || report.channelId) == null || (report.channel_id || report.channelId) == 'null' || (report.channel_id || report.channelId) == ''? '---' : (report.channel_id || report.channelId) }}</td>
              <td>{{(report.name || report['NAME']) == null || (report.name || report['NAME']) == 'null' || (report.name || report['NAME']) == ''? '---' : (report.name || report['NAME'])}}</td>
              <td>{{(report.broadcasterName || report['broadcaster_name']) == null || (report.broadcasterName || report['broadcaster_name']) == 'null' || (report.broadcasterName || report['broadcaster_name']) == ''? '---' : (report.broadcasterName || report['broadcaster_name'])}}</td>
              <td>{{(report.channel_no || report.channelNo) == null || (report.channel_no || report.channelNo) == 'null' || (report.channel_no || report.channelNo) == ''? '---' : (report.channel_no || report.channelNo)}}</td>
              <td>{{(report.category || report['CATEGORY']) == null || (report.category || report['CATEGORY']) == 'null' || (report.category || report['CATEGORY']) == '' ? '---' : (report.category || report['CATEGORY'])}}</td>
              <td>{{(report.language || report['LANGUAGE']) == null || (report.language || report['LANGUAGE']) == 'null' || (report.language || report['LANGUAGE']) == '' ? '---' : (report.language || report['LANGUAGE'])}}</td>
              <td>{{(report.genre || report['GENERE']) == null || (report.genre || report['GENERE']) == 'null' || (report.genre || report['GENERE']) == '' ? '---' : (report.genre || report['GENERE'])}}</td>
              <td>{{(report.description || report['DESCRIPTION']) == null || (report.description || report['DESCRIPTION']) == 'null' || (report.description || report['DESCRIPTION']) == '' ? '---' : (report.description || report['DESCRIPTION']) }}</td>
              <td>{{(report.updated_date || report.updatedDate) == null || (report.updated_date || report.updatedDate) == 'null' || (report.updated_date || report.updatedDate) == ''? '---' : (report.updated_date || report.updatedDate) | date: 'dd-MM-yy HH:mm:ss' : 'UTC' }}</td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
