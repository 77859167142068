import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BatchJobsManagementRoutingModule } from './batch-jobs-management-routing.module';
import { HomeComponent } from './home/home.component';
import { TransferSubscriberToOtherLCOComponent } from './transfer-subscriber-to-other-lco/transfer-subscriber-to-other-lco.component';
import { CreateLocationComponent } from './create-location/create-location.component';
import { CreateLCOsComponent } from './create-lcos/create-lcos.component';
import { CreateBroadcastersComponent } from './create-broadcasters/create-broadcasters.component';
import { CreateChannelsComponent } from './create-channels/create-channels.component';
import { AssignSubscribersToLCOComponent } from './assign-subscribers-to-lco/assign-subscribers-to-lco.component';
import { AssignSubscribersToSubscriberGroupComponent } from './assign-subscribers-to-subscriber-group/assign-subscribers-to-subscriber-group.component';
import { AssignStbToLcosComponent } from './assign-stb-to-lcos/assign-stb-to-lcos.component';
import { ActivateDeactivateSubscribersOfLcoComponent } from './activate-deactivate-subscribers-of-lco/activate-deactivate-subscribers-of-lco.component';
import { SendMailsComponent } from './send-mails/send-mails.component';
import { AddRemovePackagesToSubscribersComponent } from './add-remove-packages-to-subscribers/add-remove-packages-to-subscribers.component';
import { TransferStbsToLcoComponent } from './transfer-stbs-to-lco/transfer-stbs-to-lco.component';
import { CreateBouquetComponent } from './create-bouquet/create-bouquet.component';
import { CreateSubscribersComponent } from './create-subscribers/create-subscribers.component';


@NgModule({
  declarations: [
    HomeComponent,
    TransferSubscriberToOtherLCOComponent,
    CreateLocationComponent,
    CreateLCOsComponent,
    CreateBroadcastersComponent,
    CreateChannelsComponent,
    AssignSubscribersToLCOComponent,
    AssignSubscribersToSubscriberGroupComponent,
    AssignStbToLcosComponent,
    ActivateDeactivateSubscribersOfLcoComponent,
    SendMailsComponent,
    AddRemovePackagesToSubscribersComponent,
    TransferStbsToLcoComponent,
    CreateBouquetComponent,
    CreateSubscribersComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BatchJobsManagementRoutingModule
  ]
})
export class BatchJobsManagementModule { }
