import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MultiSelectType } from 'src/app/plugins/multiselect/multiselect.component';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { SubscriberSearchType } from '../subscriber-search/subscriber-search';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { PathNames } from 'src/app/constants/path-names';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.scss'],
})
export class AddSubscriptionComponent {
  constructor(
    private fb: FormBuilder,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private route: ActivatedRoute,
  ) { }
  ngOnInit(): void {
    this.initForm();
    this.getPathParam()
  }

  addSubscriptionForm: FormGroup;

  initForm() {
    this.addSubscriptionForm = this.fb.group({
      subscriberIdEdit:[],
      subscriberId:'',
      subscriberCode: ['', [Validators.required]],
      stbId: ['', [Validators.required]],
      bouquetId: ['', [Validators.required]],
      duration1: ['', [Validators.required]],
      endDate: [''],
      duration: [''],
    });
  }

  isEdit=false
  subid
  stbidEdit
  subscriberData


  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id)
        this.subid = id;
    if ( id == null) {
      this.isEdit =false
      // this.updateFlow(id);
    } else {
      this.isEdit = true
      this.stbidEdit=atob(localStorage.getItem('stbId'))
      console.log(this.stbidEdit)
      this.getSubscriberDetails()
      // this.createFlow();
    }
  }

  


  getSubscriberDetails(){
    const url =    this.config.json.apiEndpointRdbms + 'subscriber/getSubscriber/' +  this.subid ;
    this.http.get(url).subscribe(
      (res)=>{
        this.subscriberData = res
        this.subscriberId = this.subid
        this.getSTBList()
        this.updateFormEdit()
      }
    )
  }

  updateFormEdit(){
    let data=this.subscriberData.subscriberCode+','+this.subscriberData.firstName;
    this.subscriberIdEdit.setValue(data)
    this.addSubscriptionForm.get('subscriberCode').setValue(this.subscriberData.subscriberCode)
    this.addSubscriptionForm.get('subscriberId').setValue(this.subscriberData.id)
    this.lcoId=this.subscriberData.lcoId
    this.addSubscriptionForm.get('stbId').setValue(this.stbidEdit)
    console.log(this.stbidEdit)
    // this.subscriberC
  }


  // fetchRecord(id: string) {
  //   const url =
  //     this.config.json.apiEndpointRdbms + 'subscriber/getSubscriber/' + id;
  //   this.http.get(url).subscribe(
  //     (res)=>{
  //       this.subscriberData = res
  //       this.updateForm()
  //       this.getSTBList()
  //     }
  //   );
  // }

  // updateForm(){
  //   let data=this.subscriberData.subscriberCode+','+this.subscriberData.firstName;
  //   this.subscriberId.setValue(data)
  // }
  packagesOptions: MultiSelectType = {
    dataArray: [],
    keyToDisplay: '',
    idForSelected: '',
  };
  packages = new BehaviorSubject<MultiSelectType>(this.packagesOptions);

  searchText!: string;
  openSearch = false;
  getSubscriberId(e: Event) {
    this.openSearch = false;
    this.searchText = (e.target as HTMLInputElement).value;
    if (this.searchText.length >= 3) {
      setTimeout(() => {
        this.openSearch = true;
      }, 500);
    }
  }

  subscriberId;
  isSubscriberIdSelected = false;
  getUser(e: SubscriberSearchType) {
    console.log(e)
    this.lcoId = e.lcoId;
    let input = document.getElementById('searchSubCode') as HTMLInputElement;
    this.addSubscriptionForm.get('subscriberCode').setValue(e.subscriberCode);
    this.addSubscriptionForm.get('subscriberId').setValue(e.subscriberId);
    this.subscriberId = e.subscriberId;
    this.openSearch = false;
    this.getSTBList();
    this.searchText = undefined;
    input.value = `${e.subscriberCode}, ${e.subscriberName}`;
  }

  isDropDown = true;
  clearInput() {
    this.addSubscriptionForm.reset();
    this.packagesOptions = {
      ...this.packagesOptions,
      selectedArray: [],
    };
    this.packages.next(this.packagesOptions);
    this.stbList = [];
    this.selectedPackages = [];
  }

  stbList;
  lcoId;
  getSTBList() {
    const url =
      this.config.json.apiEndpointRdbms +
      'stb/getSTBBySubscriberId/' +
      this.subscriberId;
    this.http.get(url).subscribe({
      next: (res) => {
        this.stbList = res;
        // this.lcoId = res[0].lcoId;
        this.getPackageList();
      },
    });
  }

  getPackageList() {
    const url = `${this.config.json.apiEndpoint}bouquet/${this.lcoId + '/getBouquetDropdownList'
      }`;
    this.http.get(url).subscribe({
      next: (res) => {
        let data = res.data;
        this.packagesOptions = {
          ...this.packagesOptions,
          dataArray: data,
          keyToDisplay: 'bouquetName',
          idForSelected: 'bouquetId',
        };
        this.packages.next(this.packagesOptions);
      },
    });
  }

  selectedPackages = [];
  isComboSet = false
  packageSelected(e) {
    this.isComboSet = false
    let valcheck = this.daysChecked
    let dur = this.duration1.value
    this.selectedPackages = [];
    // if()
    // if()
    let packDur = 0
    for(let i of e){
      if(i.isCombo==1||i.isCombo==true){
        this.isComboSet=true
        if(packDur<i.packDuration){
          packDur=i.packDuration
        }
      }
    }
    if(this.isComboSet==true){
      dur=packDur;
      valcheck= false
      this.duration1.setValue(dur)
    }
    if (e?.length == 0 || e == undefined || e == null) {
      this.addSubscriptionForm.get('bouquetId').setValue(null);
      return;
    }

    function getLastDate() {
      if (valcheck == true) {
        return addDaysToDate(dur)
      }
      else {
        const currentDate = new Date();
        const newDate = new Date(currentDate);
        newDate.setDate((newDate.getDate()) + (dur * 30));
        return newDate;
      }
    }

    function addDaysToDate(daysToAdd: number): Date {
      const currentDate = new Date();
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + (daysToAdd * 1));
      return newDate;
    }
    let bouquetIds = '';
    for (let i = 0; i < e.length; i++) {
      this.selectedPackages.push({
        name: e[i]['bouquetName'],
        validFrom: new Date(),
        validTo: getLastDate(),
      });
      if (i == e.length - 1) {
        bouquetIds += `${e[i]['bouquetId']}`;
      } else {
        bouquetIds += `${e[i]['bouquetId']},`;
      }
    }
    this.addSubscriptionForm.get('bouquetId').setValue(bouquetIds);
  }

  daysChecked = false;


  changeDaysSub() {
    let valcheck = this.daysChecked
    let dur = this.duration1.value
    function getLastDate() {
      if (valcheck == true) {
        return addDaysToDate(dur)
      }
      else {
        const currentDate = new Date();
        const newDate = new Date(currentDate);
        newDate.setDate((newDate.getDate()) + (dur * 30));
        return newDate;
      }
    }

    function addDaysToDate(daysToAdd: number): Date {
      const currentDate = new Date();
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + (daysToAdd * 1));
      return newDate;
    }

    if (this.selectedPackages.length > 0) {
      for (let j of this.selectedPackages) {
        j.validFrom = new Date(),
          j.validTo = getLastDate()
      }
    }
  }
  changeForDays(e: Event) {
    let check = (e.target as HTMLInputElement).checked;
    this.daysChecked = check;
    let valcheck = this.daysChecked
    let dur = this.duration1.value
    function getLastDate() {
      if (valcheck == true) {
        return addDaysToDate(dur)
      }
      else {
        const currentDate = new Date();
        const newDate = new Date(currentDate);
        newDate.setDate((newDate.getDate()) + (dur * 30));
        return newDate;
      }
    }

    function addDaysToDate(daysToAdd: number): Date {
      const currentDate = new Date();
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + (daysToAdd * 1));
      return newDate;
    }

    if (this.selectedPackages.length > 0) {
      for (let j of this.selectedPackages) {
        j.validFrom = new Date(),
          j.validTo = getLastDate()
      }

    }
  }

  createBody() {
    console.log("calling this")
    this.addSubscriptionForm.get('stbId').setValue(+this.addSubscriptionForm.get('stbId').value);
    if(this.isComboSet==false){
    let checked = (document.getElementById('days') as HTMLInputElement).checked;
    if (checked) {
      this.addSubscriptionForm
        .get('endDate')
        .setValue(
          (document.getElementById('duration1') as HTMLInputElement).value
        );
    } else {
      this.addSubscriptionForm
        .get('duration')
        .setValue(
          +(document.getElementById('duration1') as HTMLInputElement).value
        );
    }
    let body = JSON.parse(JSON.stringify(this.addSubscriptionForm.value));
    if (checked) {
      delete body['duration'];
      delete body['duration1'];
    } else {
      delete body['endDate'];
      delete body['duration1'];
    }
    console.log(body)
    return body;
  }
  else{
    this.addSubscriptionForm.get('duration').setValue(this.duration1.value)
    let body = JSON.parse(JSON.stringify(this.addSubscriptionForm.value));
    delete body['endDate'];
      delete body['duration1'];
    return body;
  }
  }

  save() {
    const url =
      this.config.json.apiEndpointRdbms + 'subscriber/addSubscription';
    this.http.put(url, this.createBody()).subscribe(
      (res) => {
        if(res.statusCode == 200){
          alert(res.message)
          this.clearInput();
        }
        else{
          alert(res.message)
        }
        // if ((res.status as string).toUpperCase() === 'FAIL') {
        //   alert('Subscription was not created. Error: ' + res.message);
        //   return;
        // }
        // alert('Thank you, subscription has been created');
        // 
      },
  );
  }

  get duration1(): FormControl {
    return this.addSubscriptionForm.get('duration1') as FormControl;
  }
  get subscriberIdEdit():FormControl{
    return this.addSubscriptionForm.get('subscriberIdEdit') as FormControl;
  }
}
