import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { PackageInfo } from 'src/app/models/package/package-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';


@Component({
  selector: 'app-logs-bouquet-status-change',
  templateUrl: './logs-bouquet-status-change.component.html',
  styleUrls: ['./logs-bouquet-status-change.component.scss']
})
export class LogsBouquetStatusChangeComponent implements OnInit {

  reportList = [];
  bouquetList: PackageInfo[] = [];
  selectedBouquetId;
  from: any;
  to: any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    this.fetchBouquetList();
    let dateObj = new Date()
    let toDate = dateObj.getFullYear()+"-"+(dateObj.getMonth()+1>10? dateObj.getMonth()+1 : "0"+(dateObj.getMonth()+1))+"-"+dateObj.getDate()
    let body = {
      'fromDate' : '2018-01-01',
      'toDate' : toDate,
      'productId' : '0',
      'productType' : 1
    }
    this.fetchData(body)
  }

  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe(data => {
      this.bouquetList = data.data;
      // // console.log(this.bouquetList)
    });
  }

  loadingFlag = false
  isLoad = 'Loading'

  fetchData(body): void {

    this.report.getProductStatusChangeHistory(body)
      .subscribe(data => {
        this.reportList = data.data;
        // // console.log(data)
        if (this.reportList.length != 0) {
          this.loadingFlag = false
        } else {
          this.loadingFlag = false
          this.isLoad = 'No Records'
        }
      }, () => {
        this.loadingFlag = false
        this.isLoad = 'No Records'
      });
  }

  boquetSelected = false
  body
  listItem(): void {
    this.boquetSelected = true
    if (this.dateSelected) {
      let body = {
        'fromDate' : this.ev.startDate.format('YYYY-MM-DD'),
        'toDate' : this.ev.endDate.format('YYYY-MM-DD'),
        'productId' : this.selectedBouquetId !=0 ? this.selectedBouquetId.id : 0,
        'productType' : 1
      }
      this.body = body
      this.fetchData(body)
    } else {
      this.isLoad = 'Select Date'
      // this.loadingFlag = false
    }
  }

  ev;
  dateSelected = false
  dateChange(ev: NgxDateRangeInfo): void {
    // // console.log(ev.startDate.month())
    this.dateSelected = true
    this.ev = ev
    if (ev.startDate !== null && ev.endDate !== null) {
      if (this.boquetSelected) {
        [this.from, this.to] = [ev.startDate, ev.endDate];
        let startMonth = ev.startDate.month()+1>10?  ev.startDate.month()+1 : '0'+(ev.startDate.month()+1)
        let endMonth = ev.endDate.month()+1>10?  ev.endDate.month()+1 : '0'+(ev.endDate.month()+1)
        let startDate = ev.startDate.date()>10?  ev.startDate.date() : '0'+(ev.startDate.date())
        let endDate = ev.endDate.date()>10?  ev.endDate.date() : '0'+(ev.endDate.date())
        let body = {
          'fromDate' : ev.startDate.year()+"-"+startMonth+"-"+startDate,
          'toDate' : ev.endDate.year()+"-"+endMonth+"-"+endDate,
          'productType' : 1,
          'productId' : this.selectedBouquetId != 0 ? this.selectedBouquetId.id : '0'
        }
        this.body = body
        this.fetchData(body);
        this.calendar.setDate(ev);
        this.loadingFlag = true
        this.isLoad = 'Loading'
      } else {
        this.isLoad = 'Select Boquet'
      }
    }
  }

  downloadTableExcel(): void {
    let name = 'Bouquet Stautus Change Logs'
    this.doc.exportTableToExcelwithImage(this.reportTable, name, 'bouquet_status_change_logs', undefined, undefined, "Report Date :", this.body.fromDate+" to "+this.body.toDate, "Channel Name : ", this.selectedBouquetId.name);
  }

  downloadTablePdf(): void {
    if(this.ev){
      // // console.log('i hate you')
      this.pdf.generatePdfHtml('#reportTable',
      'bouquet_status_change_logs',
      `From ${this.body.fromDate} to ${this.body.toDate}     Bouquet Name: ${this.selectedBouquetId !=0 ? this.selectedBouquetId.name : 'All'}`,
      );
    }else{
      this.pdf.generatePdfHtml('#reportTable',
        'bouquet_status_change_logs',
        `From Start to Now     Bouquet Name: ${this.selectedBouquetId? this.selectedBouquetId.name : 'All'}`,
      );

    }
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Sl No.","Date", "Bouquet Name", "Product ID", "Product Status", "Product Type", "Modified by"]
    };
    let data = []
    let i =1;
    this.reportList.forEach(line => {
      let csvLine = {
        slno : i,
        date: line.createdDate,
        name: line.productName,
        channelName: line.productId,
        channelId: line.productStatus,
        channelNo: line.productType,
        action: line.modifiedBy
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'Bouquet_Status_Change_Logs', options);
  }


}
