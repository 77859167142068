import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSubscriptionComponent } from './add-subscription/add-subscription.component';
import { RemoveSubscriptionComponent } from './remove-subscription/remove-subscription.component';
import { EditSubscriptionComponent } from './edit-subscription/edit-subscription.component';
import { SubscriptionRoutingModule } from './subscription-routing.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SharedModule } from '../shared/shared.module';
import { SubscriberSearchComponent } from './subscriber-search/subscriber-search.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AddSubscriptionComponent,
    RemoveSubscriptionComponent,
    EditSubscriptionComponent,
    SubscriberSearchComponent,
  ],
  imports: [
    CommonModule,
    SubscriptionRoutingModule,
    NgMultiSelectDropDownModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  exports: [SubscriberSearchComponent],
})
export class SubscriptionModule {}
