import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LogsDataService } from 'src/app/logs/plugins/logs-data.service';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ngxCsv } from 'ngx-csv';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-transaction-logs',
  templateUrl: './transaction-logs.component.html',
  styleUrls: ['./transaction-logs.component.scss'],
})
export class TransactionLogsComponent implements OnInit {
  profileInfo = {};
  paginationObj = new PaginationObj();
  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  reportList = [];
  from;
  to;
  totalPages;
  searchKeywordW = '';
  currentDate = new Date();
  isLoad = 'Choose Date';
  pageSize = 10;
  pageNumber = 1;
  dateSelected = false;
  ev;
  reportDate;
  body = {
    fromDate: '',
    toDate: '',
  };
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private pdf: PdfGeneratorService,
    private router: Router,
    private auth: AuthService
  ) {}

  isInLogs = false;
  user
  userId
  ngOnInit(): void {
    if(this.config.json.userType=='MSO'){
      this.user=true
    }
    else{
      this.user=false
    }
    this.userId=JSON.parse(localStorage.getItem('profileInfo')).id
    this.isInLogs = this.router.url.includes('logs');
    this.body = {
      fromDate: '',
      toDate: '',
    };
    this.isLoad = 'Loading...';
    this.paginationObj.pageNumber = 1;
    this.fetchData(this.body);
  }

  userType = ''
  selectUserType(e: Event){
    this.userType = (e.target as HTMLSelectElement).value;
    this.fetchData(this.body)
  }

  fetchData(body: { fromDate: string; toDate: string }) {
    if(this.user==true){
    this.loadingFlag = true;
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      `wallet/${this.isInLogs ? 'transactions' : 'onlinePaymentHistory'}`,
      this.paginationObj
    );
    let subscription = {
      next: (res) => {
        this.handlePageMetadata(res.metadata);
        this.reportList = res.data;
        if (res.data?.length == 0) {
          this.isLoad = 'No Data';
          this.loadingFlag = true;
        }
      },
      error: () => {
        this.loadingFlag = true;
        this.isLoad = 'Error Fetching Data';
      },
      complete: () => {
        this.loadingFlag = false;
        if (this.paginationObj.pageNumber == 1) {
          this.disableFirstBtn = true;
          this.disablePreviousBtn = true;
          this.disableLastBtn = false;
          this.disableNextBtn = false;
        } else if (
          this.paginationObj.pageNumber == this.paginationObj.totalPages
        ) {
          this.disableFirstBtn = false;
          this.disablePreviousBtn = false;
          this.disableLastBtn = true;
          this.disableNextBtn = true;
        } else {
          this.disableFirstBtn =
            this.disablePreviousBtn =
            this.disableNextBtn =
            this.disableLastBtn =
              false;
        }
        console.log(this.paginationObj);
      },
    };
    if (!this.isInLogs) {
      url += `&userType=${this.userType}&fromDate=${body.fromDate}&toDate=${body.toDate}`;
      this.http.get(url).subscribe(subscription);
    } else {
      this.http.post(url, body).subscribe(subscription);
    }
  }
  else{
    this.loadingFlag = true;
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      `wallet/${this.isInLogs ? 'transactionsForLcoOrDistributor' : 'onlinePaymentHistory'}`,
      this.paginationObj
    );
    let subscription = {
      next: (res) => {
        this.handlePageMetadata(res.metadata);
        this.reportList = res.data;
        if (res.data?.length == 0) {
          this.isLoad = 'No Data';
          this.loadingFlag = true;
        }
      },
      error: () => {
        this.loadingFlag = true;
        this.isLoad = 'Error Fetching Data';
      },
      complete: () => {
        this.loadingFlag = false;
        if (this.paginationObj.pageNumber == 1) {
          this.disableFirstBtn = true;
          this.disablePreviousBtn = true;
          this.disableLastBtn = false;
          this.disableNextBtn = false;
        } else if (
          this.paginationObj.pageNumber == this.paginationObj.totalPages
        ) {
          this.disableFirstBtn = false;
          this.disablePreviousBtn = false;
          this.disableLastBtn = true;
          this.disableNextBtn = true;
        } else {
          this.disableFirstBtn =
            this.disablePreviousBtn =
            this.disableNextBtn =
            this.disableLastBtn =
              false;
        }
        console.log(this.paginationObj);
      },
    };
    if (!this.isInLogs) {
      url += `&userType=${this.userType}&fromDate=${body.fromDate}&toDate=${body.toDate}`;
      this.http.get(url).subscribe(subscription);
    } else {
      body['userType']=this.config.json.userType.toLowerCase()
      body['userId']=this.userId
      this.http.post(url, body).subscribe(subscription);
    }
  }
  }

  recheck(report : any){
    this.http.post(`${this.config.json.apiEndpoint}wallet/paymentOnSuccessOrFailure`,{txnid : report.transactionId }).subscribe({
      next : () => {
        this.fetchData(this.body)
      }
    })
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
  }

  dateChange(eve: NgxDateRangeInfo) {
    if (
      eve.startDate == null ||
      eve.startDate == undefined ||
      eve.endDate == null ||
      eve.endDate == undefined
    ) {
      return;
    }
    this.body = {
      fromDate: eve.startDate.format('YYYY-MM-DD'),
      toDate: eve.endDate.format('YYYY-MM-DD'),
    };
    this.reportDate = `${this.body.fromDate} to ${this.body.toDate}`;
    this.fetchData(this.body);
  }

  downloadTableExcel(): void {
    ////// console.log(this.reportTable)
    let header = [];
    let name = 'Transaction Logs';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'transaction_logs',
      undefined,
      undefined,
      'Report Date',
      this.reportDate ? this.reportDate : 'Not Selected'
    );
  }

  downloadTablePdf(): void {
    let date = new Date();
    let reportGenerated = `${String(date.getDate()).padStart(2, '0')}-${String(
      date.getMonth() + 1
    ).padStart(2, '0')}-${date.getFullYear()}`;
    this.pdf.generatePdfHtml(
      '#reportTable',
      'transaction_logs',
      `Report Date : ${
        this.reportDate ? this.reportDate : 'Not Selected'
      } Source Type : SMS   Report Generated On : ${reportGenerated}`
    );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Slno',
        'LCO Code',
        'Amount',
        'Transaction ID',
        'Mode of Payment',
        'Remark',
        'Date',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        subscriberCode: line.subscriberCode,
        amount: line.amount,
        slNo: line.slno,
        trnType: line.trnType,
        remarks: line.remarks,
        date: line.datee,
      };
      data.push(csvLine);
    });

    new ngxCsv(data, 'Transaction_Logs', options);
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData(this.body);
  }

  pageSizeChange() {
    this.fetchData(this.body);
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData(this.body);
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData(this.body);
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData(this.body);
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData(this.body);
  }
}
