import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { StbInfo } from 'src/app/models/stb/stb-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';


@Component({
  selector: 'app-report-subscriber-data',
  templateUrl: './report-subscriber-data.component.html',
  styleUrls: ['./report-subscriber-data.component.scss']
})
export class ReportSubscriberDataComponent implements OnInit {

  subscriberReport = [];

  channelList: ChannelInfo[] = [];
  selectedChannelId: number;

  stbList: StbInfo[] = [];
  selectedStbId: number;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    this.fetchChannelList();
    this.fetchStbList();
  }


  fetchChannelList(): void {
    this.report.getChannelList().subscribe(data => {
      this.channelList = data;
    });
  }


  fetchStbList(): void {
    this.report.getStbList().subscribe(data => {
      this.stbList = data;
    });
  }

  fetchData(from: any, to: any): void {
    this.report.getSubscriberDataReport(from, to, {}).subscribe(data => {
      this.subscriberReport = data;
      // //// console.log(this.subscriberReport)
      if(this.subscriberReport.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }

  loadingFlag=false
  isLoad = 'Choose Date'

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      this.calendar.setDate(ev);
      this.fetchData(ev.startDate, ev.endDate);
      this.loadingFlag = true
      this.isLoad = 'Loading'
    }
  }

  downloadTableExcel(): void {
    let name='Subscriber Data Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'subscriber_data_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable', 'subscriber_data_report');
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Date", "Total Subscribers", "Active", "Inactive","New Subscribers"]
    };
    let data = []
    this.subscriberReport.forEach(line => {
      let csvLine = {
        date: line.date,
        totalUsers: line.totalUsers,
        activeUsers: line.activeUsers,
        inActiveUsers: line.inActiveUsers,
        newUsers: line.newUsers,

      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_data_report', options);
  }

}
