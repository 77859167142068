import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-report-next-fifteen-days-expiry',
  templateUrl: './report-next-fifteen-days-expiry.component.html',
  styleUrls: ['./report-next-fifteen-days-expiry.component.scss']
})
export class ReportNextFifteenDaysExpiryComponent implements OnInit {
  expiryReportList = [];

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    this.fetchData()
  }

  fetchData():void{
    this.report.getNextFifteenDaysExpiryReport().subscribe((data) => {
      this.expiryReportList = data
      //// console.log(data)
    })

  }

  downloadTableExcel(): void {
    let name='Expiry for next 15 Days  Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'next_fifteen_days_expiry_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable',
    'next_fifteen_days_expiry_report',
    `Source Type : SMS`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Subscriber Name", "Subscriber ID","Product Name","Start Date","End Date"]
    };
    let data = []
    this.expiryReportList.forEach(line => {
      let csvLine = {
        subscriberName: line.subscriberName,
        subscriberId: line.subscriberId,
        productName: line.productName,
        startDate: line.startDate,
        endDate: line.endDate,
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'next_fifteen_days_expiry_report', options);
  }


}
