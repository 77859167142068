<div class="login__cont">
<img id="bgimg" [src]="backgroundImage" alt=""  onerror="this.src='../../../assets/login_bg.jpg'">
  <div class="login__form">
    <div class="top__heading">
      <!-- <h4 class="highlight"><b>IPTV Admin</b> Login</h4> -->
      <img id="logo" loading="lazy" src="assets/logos/esto_logo.png" alt="">
      <!-- <h4 class="highlight" style="text-align: center;" ><b>IPTV OnSMS</b> Login</h4> -->
    </div>
    <div class="col-12 form__style">
      <form [formGroup]="loginForm" *ngIf="!forgotPassword"  (ngSubmit)="login()">
        <div class="row">
          <div class="col-12">
            <label>ID</label>
            <input type="text" autocomplete="off" class="custom__field" formControlName="username" />
          </div>
          <div class="col-12" style="margin-bottom: 0;" >
            <label>Password</label>
            <div class="input__container">
            <input [type]="showPasswordField? 'text': 'password'"  formControlName = "password" 
            (keydown.enter)="login()" maxlength="20" class="custom__field">
            <button type="button" (click)="showPassword()"><img [src]="showPasswordField ? '/assets/icons/eye.svg' : '/assets/icons/eye-no.svg' "  alt="Show Password"></button>
          </div>
        </div>
<!-- 
          <div class="col-12">
            <div class="send__all">
              <label>Login as MSO</label>
              <div class="send__all--content">
                <input type="checkbox" formControlName="ismso" />
              </div>
            </div>
          </div> -->

          <div class="col-12" style="margin-bottom: 0;" >
            <!-- <button  type="button" class="default__button" (click)="clear()">Clear</button> -->
            <button style="width: 100%;" class="cta" [disabled]="!loginForm.valid">Login</button>
            <p style="text-align: center; width: 100%; font-size: small;margin: 10px 0 0 0; cursor: pointer;" (click)="forgotPassword = !forgotPassword"  >Forgot Password</p>
          </div>
        </div>
      </form>
      <div *ngIf="forgotPassword && !otpVerified" class="form__style">
        <div class="col-12">
          <label>Mobile / Email Id</label>
          <div class="input__container">
          <input type="text" autocomplete="off" (input)="emailPhoneForForgotPassword = ($event.target.value)" class="custom__field">
        </div>
          <label *ngIf="!mobileotpVerified">Mobile Otp</label>
          <div class="input__container" *ngIf="!mobileotpVerified">
          <input type="text" autocomplete="off" [disabled]="otpText == 'Get OTP' " (input)="otpmobile = ($event.target.value)"  class="custom__field">
          </div>
          <label *ngIf="mobileotpVerified">Email Otp</label>
          <div class="input__container" *ngIf="mobileotpVerified">
          <input type="text" autocomplete="off" [disabled]="otpText == 'Get OTP' " (input)="otpemail = ($event.target.value)"  class="custom__field">
          </div>
      </div>
      <div style="margin-top: 10px;" class="col-12">
        <button (click)="cancel()">Cancel</button>
        <button class="cta float-right"  *ngIf="!mobileotpVerified" (click)="otpText == 'Get OTP' ? getOtp() : verifyOTPMobile()" >{{otpText}}</button>
        <button class="cta float-right"  *ngIf="mobileotpVerified" (click)="otpText == 'Get OTP' ? getOtp() : verifyOTP()" >{{otpText}}</button>
      </div>
      </div>
    </div>

    <!-- <div *ngIf="forgotPassword && otpVerified && !mobileotpVerified" class="form__style">
      <div class="col-12">
        <label>Mobile Number</label>
        <div class="input__container">
        <input type="text" autocomplete="off" (input)="emailPhoneForForgotPassword = ($event.target.value)" class="custom__field">
      </div>
        <label>OTP</label>
        <div class="input__container">
        <input type="text" autocomplete="off" [disabled]="otpText == 'Get OTP' " (input)="otp = ($event.target.value)"  class="custom__field">
    </div>
    <div style="margin-top: 10px;" class="col-12">
      <button (click)="cancel()">Cancel</button>
      <button class="cta float-right" (click)="otpText == 'Get OTP' ? getOtp() : verifyOTPMobile()" >{{otpText}}</button>
    </div>
    </div>
  </div> -->


    <div *ngIf="otpVerified && mobileotpVerified" class="form__style">
      <div class="col-12">
        <label>New Password</label>
        <div class="input__container">
        <input (input)="getPassword($event, 'newPassword')" [type]="showPasswordField? 'text': 'password'" class="custom__field">
        <button type="button" (click)="showPassword()"><img [src]="showPasswordField ? '/assets/icons/eye.svg' : '/assets/icons/eye-no.svg' "  alt="Show Password"></button>
      </div>
      <label>Confirm Password</label>
      <div class="input__container">
      <input [type]="showPasswordField? 'text': 'password'" class="custom__field" (input)="getPassword($event, 'confirmPassword')" >
      <p class="error__msg" *ngIf="!passwordConfirmation()"  >Passwords do not match</p>
    </div>
    <div style="margin-top: 10px;" class="col-12">
      <button (click)="cancel()">Cancel</button>
      <button class="cta float-right" [disabled]="!passwordConfirmation()"  (click)="changePassword()" >Change</button>
    </div>
  </div>
</div>
