import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export function atLeastOneFieldValidator(field1: string, field2: string, field3: string): ValidatorFn {
  return (formGroup: FormGroup): { atLeastOneField: boolean } | null => {
    const field1Control = formGroup.get(field1);
    const field2Control = formGroup.get(field2);
    const field3Control = formGroup.get(field3);

    const field1Value = field1Control?.value;
    const field2Value = field2Control?.value;
    const field3Value = field3Control?.value;

    if (!(field1Value || field2Value || field3Value)) {
      return { atLeastOneField: true };
    }

    return null;
  };
}