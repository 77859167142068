import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';


@Component({
  selector: 'app-logs-subscription-desubscription',
  templateUrl: './logs-subscription-desubscription.component.html',
  styleUrls: ['./logs-subscription-desubscription.component.scss']
})
export class LogsSubscriptionDesubscriptionComponent implements OnInit {
  reportList = [];

  // macAddressList: stbMacInfo[] = []
  selectedStbMacAddress: string;

  from: any;
  to: any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
  }



  fetchData(from: any, to: any): void {
    this.report.getSubscriptionHistory(from, to, {}).subscribe(data => {
      const filteredData = data.filter(each => {
        const eachDate = new Date(each["dateTime"])
        if(eachDate >= from && eachDate <= to)
          return each;
        }
      )
      // //// console.log(data)
      // Convenient to map the response directly, since we know response is list of JSON objects
      this.reportList = filteredData.reverse();

    });
  }


  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.calendar.setDate(ev);
      this.fetchData(ev.startDate, ev.endDate);
    }
  }

  downloadTableExcel(): void {
    let name='Descubcribed Logs'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'subscription_desubscription_logs');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable',
    'subscription_desubscription_logs',
    `From Date: ${this.from.toISOString().slice(0, 10)}     To Date: ${this.to.toISOString().slice(0, 10)}`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Start Date", "STB MAC Address", "Bouquet/Channel ID", "Command","STB Level","Expiry Date"]
    };
    let data = []

    this.reportList.forEach(line => {
      let csvLine = {
        startdate: line.startDate,
        macAddress: line.stbMacAddress,
        id: line.bouquetId,
        command: line.command,
        stblevel: line.stbLevel,
        enddate: line.endDate
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscription_desubscription_logs', options);
  }

}
