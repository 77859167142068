import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MsoRoutingModule } from './mso-routing.module';
import { CreateMsoComponent } from './create-mso/create-mso.component';
import { ListMsoComponent } from './list-mso/list-mso.component';
import { SharedModule } from '../shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    CreateMsoComponent,
    ListMsoComponent
  ],
  imports: [
    CommonModule,
    MsoRoutingModule,
    SharedModule,
    NgMultiSelectDropDownModule
  ]
})
export class MsoModule { }
