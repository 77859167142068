import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { PackageInfo } from 'src/app/models/package/package-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-report-bouquetwise-channel',
  templateUrl: './report-bouquetwise-channel.component.html',
  styleUrls: ['./report-bouquetwise-channel.component.scss'],
})

//displays the channels belonging to the selected bouquet name.
export class ReportBouquetwiseChannelComponent implements OnInit {
  reportList = [];
  bouquetList: PackageInfo[] = [];
  selectedBouquetId: number = 0;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.fetchBouquetList();
    this.fetchData();
  }

  isLoading = true;
  //get the bouquet names to be displayed in the dropdown list.
  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe((data) => {
      this.bouquetList = data.data;
    });
  }

  //The selected bouquet name is matched with the bouquet id to get the data.
  fetchData(): void {
    this.reportList =[];
    this.isLoading = true;
    const body = {
      bouquetId: this.selectedBouquetId,
      searchKeyword: '',
    };
    // this.report.getBouquetwiseChannelReport(body).subscribe(data => {
    //   this.reportList = data;
    // });
    // //// console.log(this.report.getBouquetWiseChannel())
    // //// console.log(this.report.getBouquetwiseChannelReport(''))
    this.report.getBouquetWiseChannel(body).subscribe((res) => {
      //// console.log(res)
      this.reportList = res.data;
      //// console.log(this.reportList)
      this.isLoading = false;
    });
  }

  boquetName;
  bouquetSelect(): void {
    for (let ele of this.bouquetList) {
      if (ele.id == this.selectedBouquetId) {
        this.boquetName = ele.name;
      }
    }
    this.selectedBouquetId == 0 ? (this.boquetName = 'All') : null;
    this.reportList = [];
    this.fetchData();
    this.isLoading = true;
  }

  // downloadTableExcel(): void {
  //   let name = 'Bouquetwise Channel  Report';
  //   this.doc.exportTableToExcelwithImage(
  //     this.reportTable,
  //     name,
  //     'bouquet_wise_channel_report',
  //     undefined,
  //     undefined,
  //     'Boquet Name :',
  //     this.selectedBouquetId == 0 ? 'All' : this.boquetName
  //   );
  // }
  downloadTableExcel() {
    let url = `${this.config.json.apiEndpoint}generate/getPackageWiseChannels`;
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value;
  let body={
    bouquetId:this.selectedBouquetId,
    searchKeyword:value
    
  }
    this.http.postwithresponseType(url,body).subscribe(
      (response) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const jsonResponse = JSON.parse(reader.result as string);
            if (jsonResponse.status === 'FAIL') {
              console.error(`Error: ${jsonResponse.message}`);
              return;
            }
          } catch (error) {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'bouquet_wise_channel_report.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
          }
        };
        reader.readAsText(response);
      },
      (error) => {
        console.error('Error downloading Excel file:', error);
      }
    );
  }

  downloadTablePdf(): void {
    if(this.reportList == undefined || this.reportList == null || this.reportList?.length == 0){
      alert('No Data')
      return;
    }
    this.pdf.generatePdfHtml(
      '#reportTable',
      'bouquet_wise_channel_report',
      `Bouquet Type: ${
        this.boquetName ? this.boquetName : 'ALL'
      },   Source Type : SMS`
    );
  }
  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'SL No',
        'Broadcaster',
        'Price Package Name',
        'Bouquet Name',
        'Subscription Type',
        'Channel ID',
        'Channel Name',
        'Channel Number',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        broadcaster_name: line.broadcaster_name,
        price_package_name: line.price_package_name,
        bouquet_name: line.bouquet_name,
        pack_type: line.pack_type,
        channel_id: line.channel_id,
        channel_name: line.channel_name,
        channel_no: line.channel_no,
      };
      data.push(csvLine);
      i += 1;
    });

    new ngxCsv(data, 'bouquet_wise_channel_report', options);
  }
  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    let url =
      this.config.json.apiEndpointRdbms + 'bouquet/getPackageWiseChannels';
    let body = {
      boquetId: this.selectedBouquetId,
      searchKeyword: value,
    };
    this.http.post(url, body).subscribe((res) => {
      //// console.log(res)
      this.reportList = res.data;
      //// console.log(this.reportList)
      this.isLoading = false;
    });
  }
}
