import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateMsoComponent } from './create-mso/create-mso.component';
import { ListMsoComponent } from './list-mso/list-mso.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListMsoComponent },
  { path: ':id', component: CreateMsoComponent },
  // { path: 'create', component: CreateMsoComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MsoRoutingModule { }
