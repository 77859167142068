import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-report-stb-inactive-count',
  templateUrl: './report-stb-inactive-count.component.html',
  styleUrls: ['./report-stb-inactive-count.component.scss', '../report-filters.scss']
})
export class ReportStbInactiveCountComponent implements OnInit {

  reportList = [];
  selectedLco;
  from:any;
  to:any;

  isLoad = 'Choose Date'
  partnerName : string
  lcoNumber

  lcoList: LCOInfo[] = [];

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config : ConfigService,
    private http : HttpClientWrapperService
  ) { }


  ngOnInit(): void {
    this.fetchLcoList();
    this.selectedLco=0
    this.fetchData()
  }


  fetchLcoList(): void {
    this.report.getLCOList().subscribe(data => {
      this.lcoList = data.data;
    });
  }
  totalCount =0
  loadingFlag = false
  fetchData(): void {
    this.totalCount = 0

    // // console.log(this.selectedLco)
    if(this.selectedLco == 0){
      this.partnerName = 'All'
      this.lcoNumber = 0
    }else{
      // // console.log('call')
      this.lcoNumber = this.selectedLco.id
      this.partnerName= this.selectedLco.lcoCode

    }
    this.report.getStbInactiveCountReport({ 'lcoId': this.lcoNumber, 'searchKeyword':'' }).subscribe(data => {
      this.reportList = data.data;
      // this.dataValueSetter(this.reportList)
      this.totalCount = 0
      for(let ele of data.data){
        this.totalCount += ele.stb_active_count
      }
      this.loadingFlag=false
      if(this.reportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=true
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }

  // loadingFlag=false


  // lcoSelect(): void {
  //   this.fetchData(this.calendar.getDate()['startDate'], this.calendar.getDate()['endDate']);
  // }

  // dateChange(ev: NgxDateRangeInfo): void {
  //   if (ev.startDate !== null && ev.endDate !== null) {
  //     [this.from, this.to] = [ev.startDate, ev.endDate];
  //     this.fetchData(ev.startDate, ev.endDate);
  //     this.calendar.setDate(ev);
  //     this.loadingFlag = true
  //     this.isLoad = 'Loading'
  //   }
  // }



  downloadTableExcel(): void {
    let name='Stb Inactive Count Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'stb_inactive_count_report',undefined,undefined,"Partner Code : ", this.partnerName);
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable',
    'stb_inactive_count_report',
    // `From Date: ${this.from.toISOString().slice(0,10)}     To Date: ${this.to.toISOString().slice(0,10)}     LCO ID: ${this.selectedLco}`
    `Partner Code : ${this.partnerName}, Source Type : SMS`

    );
  }


  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No", "Partner Code", "Partner Name", "Inactive STB Count"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo : i,
        partnerCode: line.lco_code,
        name: line.name,
        activeCount   : line.stb_active_count
      }
      i = i+1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'stb_inactive_count_report', options);
  }

  searchKeyword(){
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms+'reports/getStbDeactiveCountReport'
    // this.dataValueSetter(this.selectedLco)
    let body = {
      'lcoId': this.lcoNumber,
      'searchKeyword' : value
    }
    this.http.post(url, body).subscribe(data => {
      this.reportList = data.data;
    this.totalCount = 0
      for(let ele of data.data){
        this.totalCount += ele.stb_active_count
      }
      //// console.log(this.reportList)
      this.loadingFlag = false
      if (this.reportList.length != 0) {
        this.loadingFlag = false
      } else {
        this.loadingFlag = true
        this.isLoad = 'No Records'
      }
    }, () => {
      this.loadingFlag = false
      this.isLoad = 'Error'
    });
  }


}
