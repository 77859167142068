<table class="custom__iptv__table" >
    <thead>
        <tr>
            <th>Subscriber Name</th>
            <th>Subscriber Code</th>
            <th>Mobile Number</th>
            <th>Email</th>
            <!-- <th>CAF No.</th> -->
            <th>Address</th>
            <th>Created Date</th>
        </tr>
    </thead>
    <tbody >
      <ng-container *ngIf="isEmpty" >
        <tr >
            <td colspan="7">No data</td>
        </tr>
    </ng-container>
        <ng-container *ngFor="let user of users" >
            <tr (click)="getUser(user)" >
                <td>{{user.subscriberName}}</td>
                <td>{{user.subscriberCode}}</td>
                <td>{{user.mobileNumber}}</td>
                <td>{{user.email}}</td>
                <!-- <td>{{user.cafNo}}</td> -->
                <td>{{user.address}}</td>
                <td>{{user.createdDate | date}}</td>
            </tr>
        </ng-container>
       
    </tbody>
</table>
