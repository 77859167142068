import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-report-stb-inactive-list',
  templateUrl: './report-stb-inactive-list.component.html',
  styleUrls: ['./report-stb-inactive-list.component.scss', '../report-filters.scss']
})
export class ReportStbInactiveListComponent implements OnInit {

  reportList = [];
  selectedLco = 0;
  from: any;
  to: any;

  lcoList: LCOInfo[] = [];

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config : ConfigService,
    private http : HttpClientWrapperService
  ) { }


  ngOnInit(): void {
    // this.fetchLcoList();
    this.fetchData('0000-00-00', '0000-00-00')
  }

  fetchLcoList(): void {
    this.report.getLCOList().subscribe(data => {
      this.lcoList = data.data;
    });
  }

  fetchData(from: any, to: any): void {
    this.report.getStbInactiveListReport({ 'lcoId': this.selectedLco, 'searchKeyword':'', fromDate : from, toDate : to }).subscribe(data => {
      // // console.log(data)
      this.reportList = data.data;
      if(this.reportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }

  loadingFlag=false
  isLoad = 'Choose Date'

  lcoSelect(): void {
    this.fetchData(this.calendar.getDate()['startDate'], this.calendar.getDate()['endDate']);
  }

  dateString;
  eve
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.dateString = ev.startDate.date()+"-"+this.doc.monthNameCalculator(ev.startDate.month())+"-"+ev.startDate.year()+" to "+ev.endDate.date()+"-"+this.doc.monthNameCalculator(ev.endDate.month())+"-"+ev.endDate.year()
      this.eve=ev
      this.fetchData(ev.startDate.format('YYYY-MM-DD'), ev.endDate.format('YYYY-MM-DD'));
      this.calendar.setDate(ev);
      this.loadingFlag = true
      this.isLoad = 'Loading'
    }
  }

  downloadTableExcel(): void {
    let name='Stb Inactive List Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'stb_inactive_list_report',undefined,undefined,"Report Date : ", this.dateString);
  }

  downloadTablePdf(): void {
    if(this.reportList == undefined || this.reportList == null || this.reportList?.length == 0){
      alert('No Data')
      return;
    }
    if(!this.formatDate(this.from)){
      this.pdf.generatePdfHtml('#reportTable', 'stb_inactive_list_report', `Till now    Source Type : SMS`)
      return;
    }
    this.pdf.generatePdfHtml('#reportTable',
      'stb_inactive_list_report',
      `From Date: ${this.formatDate(this.from)}     To Date: ${this.formatDate(this.to)},    Source Type : SMS`
    );
  }


  formatDate(ele) {
    if(ele == undefined){
      return false
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No", "Subscriber Code","Subscriber Name","Partner Name","MAC Address","Deactivation Date"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo : i,
        subCode: line.subscriber_code,
        subName: line.subscriberName,
        partnerName: line.lco_name,
        mac: line.ethernet_mac_address,
        activeDate : line.activation_date,
      }
      data.push(csvLine);
      i+=1
    });

    new ngxCsv(data, 'stb_inactive_list_report', options);
  }

  searchKeyword(){
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms+'reports/getStbDeactiveListReport'
    let body = {
      'fromDate' : this.eve.startDate.format('YYYY-MM-DD'),
      'toDate' : this.eve.endDate.format('YYYY-MM-DD'),
      'lcoId': this.selectedLco,
      'searchKeyword' : value
    }
    this.http.post(url, body).subscribe(data => {
      this.reportList = data.data;
      if(this.reportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }

}
