import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-list-broadcaster',
  templateUrl: './list-broadcaster.component.html',
  styleUrls: ['./list-broadcaster.component.scss']
})
export class ListBroadcasterComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    name: 'name',
    desc: 'description',
    email: 'email',
    mobile: 'mobile',
    cp: 'contactPerson',
    date: 'createdDate',
    status: 'status'
  };

  broadcasterList: BroadcasterInfo[] = [];
  activeBroadcasterCount = 0;
broadcasterCount:number;
  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
    this.fetchBroadcasterCount();

  }


  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }


  fetchBroadcasterCount() {
    const url = this.config.json.apiEndpointRdbms + 'broadcaster/getBroadcasterCount/'
    this.http.get(url).subscribe((res) => {
      // // console.log(res)
      this.broadcasterCount=res.data.broadcasterCount
      // // console.log(this.broadcasterCount);

    });
  }
  fetchPage(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('broadcaster/getBroadcasters', this.paginationObj);
    const url = this.config.json.apiEndpointRdbms + 'broadcaster/getBroadcasters/' + this.paginationObj.pageNumber + '?pageSize=' + this.paginationObj.pageSize + '&sortBy=' + this.paginationObj.sortBy + '&sortOrder=' + this.paginationObj.sortOrder + '&searchKeyword=' + this.paginationObj.searchKeyword

    this.loadingFlag = true;

    this.http.get(url).pipe(delay(250)).subscribe((data: PageInfo<BroadcasterInfo>) => {
      this.loadingFlag = false;
      // //// console.log(data);
      // this.broadcasterList = data;
      this.handleFetchPage(data);
    });
  }

  handleFetchPage(data: PageInfo<BroadcasterInfo>): void {
    this.broadcasterList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.paginationObj.pageNumber = metadata.pageNumber
    this.paginationObj.pageSize = metadata.pageSize
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: BroadcasterInfo): void {
    // // console.log(record)
    this.router.navigate(['/content/broadcaster', record.id]);
  }

}
