import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-broadcasters',
  templateUrl: './create-broadcasters.component.html',
  styleUrls: ['./create-broadcasters.component.scss'],
})
export class CreateBroadcastersComponent implements OnInit {
  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private route: Router
  ) {}

  ngOnInit(): void {}

  sampleData = [
    {
      'Broadcaster Name': 'Mohan',
      Description: 'Software Developer',
      'Contact Person': 'mohan@gmail.com',
      'Mobile Number': '7845129635',
      'Alternate Number': '9856321472',
      'Email ID': 'mohan2@gmail.com',
      City: 'Banglore',
      State: 'Karnataka',
      Pincode: '560053',
      Address: 'Moodalapalya',
    },
    {
      'Broadcaster Name': 'Mohan K',
      Description: 'Backend Developer',
      'Contact Person': 'mohan3@gmail.com',
      'Mobile Number': '9546782131',
      'Alternate Number': '9546782131',
      'Email ID': 'mohan4@gmail.com',
      City: 'Banglore',
      State: 'Karnataka',
      Pincode: '560054',
      Address: 'Moodalapalya Ext',
    },
  ];

  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'create_broadCaster');
  }

  isSubmitReady = false;
  message: string = '';
  errorBody = [];
  errorAvailable = false;
  e;
  selectedFile(e) {
    this.message = '';
    this.e = e;
    const url =
      this.config.json.apiEndpoint + 'bulkUpload/validation/broadcasters';
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.isSubmitReady = true;
          this.errorAvailable = false;
        } else {
          this.errorAvailable = true;
          this.errorBody = res.errorBody;
        }
        if (res.result == 'wrongSheet') {
          this.isSubmitReady = false;
          this.errorAvailable = false;
          this.message = res.message;
        }
      },
      error: (error) => {
        this.message = error.error.message;
      },
    });
  }

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement;
    this.excelgen.deselectSelectedFiles(input);
    this.isSubmitReady = false;
  }

  successMessage = 'No errors Found';
  uploadFile() {
    const url = this.config.json.apiEndpoint + 'bulkUpload/broadcasters';
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message;
          this.route.navigate(['/content/broadcaster/list']);
        } else {
          this.errorAvailable = true;
          this.successMessage = res.message;
          this.errorBody = res.errorBody;
        }
      },
    });
  }
}
