<div class="cms__content">
    <app-reports-header></app-reports-header>
    <div class="top__heading">
        <h3>BSNL Orders</h3>
        <a><button class="default__button float-right" (click)="sync()">Sync</button></a>
    </div>
    <div class="clearfix"></div>

    <div class="row row-top">
        <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">

            <label class="report_selection_label" style="margin-right: 1%;">Status: </label>
            <select class="custom__field report_selection" [(ngModel)]="statusvalue" (change)='fetchData()'>
              <option value="Open">Open</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
       </div> 
       
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
                CSV
                <img src="./assets/csv.svg" alt="download" />
            </button>
           
        </div>
    </div>

    <!-- <div class="third_box">
        <div class="col-12 col-md-6 report">
            <p>
                Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
            </p>
        </div>
       
    </div> -->

    <!-- <div class="row"> 
        <div class="col-md-6 report-generated-fix">
          <p>
            Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
          </p>
        </div>
       <div></div>
      </div> -->

      <div class="third_box">
        <div class="col-12 col-md-6 report">
            <span>
                Show
                <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
                  <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
                </select>
                Entries
              </span>
        </div>
        <div style="display: flex; gap:20px" >
          <div class="table__right">
            <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
          </div>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" [(ngModel)]="skWord" (keydown.enter)="searchKeyword()" />
            <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>
    <div class="col-12 list__table" style="margin-top: 6px;">
        <div class="row scroll">
            <!-- <div class="custom__table__filter">
               
              
                  <div class="table__right" style="gap:20px;">
                    <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> record{{reportList.length == 1?
                      '' : 's'}}</p>
                  <div class="search__container">
                    <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()" [(ngModel)]="paginationObj.searchKeyword" />
                    <button (click)="onSearch()" (keydown.enter)="onSearch()">
                      <img src="./assets/search.png" alt="search" />
                    </button>
                  </div>
                  </div>
               
              </div> -->
            <table class="custom__iptv__table" #reportTable id="print">
                <thead>
                    <tr>
                        <th>Sl No</th>
                        <th>Order ID</th>
                        <th>Customer Name</th>
                        <th>Cust Type</th>
                        <th>Cust Account Num</th>
                        <th>Scheme Num</th>
                        <th>Scheme Name</th>
                        <th> Mac Address</th>
                        <th>Address</th>
                        <th>Phone Num</th>
                        <th>Cache IP Address</th>
                        <th>Cache Unique ID</th>
                        <th>Cache VLAN ID</th>
                        <th>Username</th>
                        <th>LMO User</th>
                        <th>MTCE Franchise Code</th>
                        <th>Exchange Code</th>
                        <th>Vendor Code</th>
                        <th>Circle Code</th>
                        <th>BA Code</th>
                        <th>RMN</th>
                        <th>Bill Acc Num</th>
                        <th>IPTV Status</th>
                        <th>Status</th>
                        <th>Order Date</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="reportList.length == 0 && loadingFlag">
                        <td style="text-align: center !important" colspan="25">
                          <app-custom-spinner></app-custom-spinner>
                        </td>
                      </tr>
                      <tr *ngIf="!loadingFlag && reportList.length == 0">
                        <td style="text-align: center !important" colspan="25">
                          {{ isLoad }}
                        </td>
                      </tr>
                    <tr *ngFor="let report of filteredReports; let j = index">
                        <td>
                            {{
                            (paginationObj.pageNumber - 1) * paginationObj.pageSize +
                            (j + 1)
                            }}
                          </td>
                    <td>{{report.ORDER_ID?report.ORDER_ID:'---'}}</td>
                    <td>{{report.CUSTOMER_NAME?report.CUSTOMER_NAME:'---'}}</td>
                    <td>{{report.CUST_TYPE?report.CUST_TYPE:'---'}}</td>
                    <td>{{report.CUST_ACCNT_NO?report.CUST_ACCNT_NO:'---'}}</td>
                    <td>{{report.SCHEME_NUM?report.SCHEME_NUM:'---'}}</td>
                    <td>{{report.SCHEME_NAME?report.SCHEME_NAME:'---'}}</td>
                    <td>{{report.MAC_ID?report.MAC_ID:'---'}}</td>
                    <td>{{report.ADDRESS?(report.ADDRESS==""?'---':report.ADDRESS):'---'}}</td>
                    <td>{{report.PHONE_NO?report.PHONE_NO:'---'}}</td>
                    <td>{{report.CACHE_IP_ADDRESS?report.CACHE_IP_ADDRESS:'---'}}</td>
                    <td>{{report.CACHE_UNIQUE_ID?report.CACHE_UNIQUE_ID:'---'}}</td>
                    <td>{{report.CACHE_VLAN_ID?report.CACHE_VLAN_ID:'---'}}</td>
                    <td>{{report.USERNAME?report.USERNAME:'---'}}</td>
                    <td>{{report.LMO_USER?report.LMO_USER:'---'}}</td>
                    <td>{{report.MTCE_FRANCHISE_CODE?report.MTCE_FRANCHISE_CODE:'---'}}</td>
                    <td>{{report.EXCHANGE_CODE?report.EXCHANGE_CODE:'---'}}</td>
                    <td>{{report.VENDOR_CODE?report.VENDOR_CODE:'---'}}</td>
                    <td>{{report.CIRCLE_CODE?report.CIRCLE_CODE:'---'}}</td>
                    <td>{{report.BA_CODE?report.BA_CODE:'---'}}</td>
                    <td>{{report.RMN?report.RMN:'---'}}</td>
                    <td>{{report.BILL_ACCNT_NO?report.BILL_ACCNT_NO:'---'}}</td>
                    <td>{{report.IPTV_STATUS?report.IPTV_STATUS:'---'}}</td>
                    <td>{{report.STATUS?report.STATUS:'---'}}</td>
                    <td>{{report.ORDER_DATE?report.ORDER_DATE:'---'}}</td>
                  
                </tr>

                </tbody>
            </table>
            <div class="custom__pagination">
                <ul>
                  <li>
                    <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                      <img src="./assets/firstPage.png" /></button>
                  </li>
                  <li>
                    <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                      <img src="./assets/previousPage.png" /></button>
                  </li>
                  <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
                  <li>
                    <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                      <img src="./assets/nextPage.png" /></button>
                  </li>
                  <li>
                    <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                      <img src="./assets/lastPage.png" /></button>
                  </li>
                </ul>
        
              </div>
        </div>
    </div>
</div>

