<div class="cms__content">
    <app-reports-header></app-reports-header>
    <div class="top__heading">
      <h3>Subscriber Report</h3>
      <a [routerLink]="['/reports/custom-reports']"
        ><button class="default__button float-right">Back</button></a
      >
    </div>
    <div class="clearfix"></div>
    <div class="filter-download_container">
        <div class="filter_container">
          <div class="label-container">
            <label for="">Select Date :</label>
          </div>
          <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div>
        <div class="download__report col-md-6">
          <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
            PDF
            <img src="./assets/pdf.svg" alt="download" />
          </button>
          <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
            Excel
            <img src="./assets/excel.svg" alt="download" />
          </button>
          <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
            CSV
            <img src="./assets/csv.svg" alt="download" /> </button>
        </div>
      </div>
    
      <!-- <div class="row"> 
        <div class="col-md-6 report-generated-fix">
          <p>
            Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
          </p>
        </div>
        
      </div> -->
  
    <div class="third_box">
      <div class="col-12 col-md-6 report">
        <p>
          Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
        </p>
      </div>
      <div style="display: flex; gap:20px" >
        <div class="table__right" style="padding: 5% 0%">
          <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
        </div>
        <!-- <div class="search__container">
          <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
          <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
        </div> -->
      </div>
    </div>
  
    <div class="col-12 list__table">
      <div class="row scroll">
        <table class="custom__iptv__table" #reportTable id="reportTable">
          <thead>
            <tr>
              <th>Sl no</th>
              <th>Subscriber ID</th>
              <th>Subscriber Code</th>
              <th>Subscriber Name</th>
              <th>Subscriber Address</th>
              <th>LCO Code</th>
              <th>LCO Name</th>
              <th>Packages</th>
              <th>MAC Address</th>
              <th>STB ID</th>
              <th>Status</th>
              <th>Registered Date</th>

            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngIf="reportList.length ==0" >
              <td style="text-align: center !important;" colspan="11">
                <app-custom-spinner></app-custom-spinner>
                </td>
            </tr> -->
            <tr *ngIf="reportList.length == 0 && loadingFlag">
              <td style="text-align: center !important" colspan="12">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <tr *ngIf="!loadingFlag && reportList.length == 0">
              <td style="text-align: center !important" colspan="12">
                {{ isLoad }}
              </td>
            </tr>
            <tr *ngFor="let report of reportList; let j = index">
              <td>{{ j + 1 }}</td>
              <td>{{report.subsciberId?report.subsciberId:'---'}}</td>
              <td>{{ report.subscriberCode ? report.subscriberCode : "---" }}</td>
              <td style="text-wrap: nowrap; word-break: keep-all;" >{{report.subscriberName? report.subscriberName: "---"}}</td>
              <td>{{ report.subscriberAddress ? report.subscriberAddress : "---" }}</td>
              <td>{{report.lcoCode?report.lcoCode:"---"}}</td>
              <td>{{ report.lcoName ? report.lcoName : "---" }}</td>
              <td style="overflow-wrap: break-word;" >{{ report.packages ? report.packages : "No Subscription" }}</td>
              
              <td style="word-wrap: break-word; word-break: break-all;" >
                {{
                  report.ethernetMacAddress ? report.ethernetMacAddress : "---"
                }}
              </td>
              <td style="word-wrap: break-word; word-break: break-all;" >
                {{
                  report.stbId ? report.stbId : "---"
                }}
              </td>
              <td >
                {{report.subscriberStatus == 0? "Inactive":
                  report.subscriberStatus == 1? "Active"  :
                  report.subscriberStatus == 2? "Blacklisted" :
                  report.subscriberStatus == 3? "New": "Terminated"}}
              </td>
              <td>{{report.createdDate?report.createdDate:'---'}}</td>
            </tr>
            <tr *ngIf="!loadingFlag && reportList.length === []">
              <td style="text-align: center !important" colspan="12">
                No Records.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  