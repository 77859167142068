import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListRegionComponent } from './list-region/list-region.component';
import { CreateRegionComponent } from './create-region/create-region.component';
import { AssignLcmComponent } from './assign-lcm/assign-lcm.component';
import { AssignLcnComponent } from './assign-lcn/assign-lcn.component';


const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListRegionComponent },
  { path: 'edit/:id', component: CreateRegionComponent},
  { path: 'create', component: CreateRegionComponent },
  { path: 'lcm/:id',component:AssignLcmComponent},
  { path: 'lcn/:id',component:AssignLcnComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatewiseLCNRoutingModule { }
