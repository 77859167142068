import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from 'src/app/logs/plugins/logs-data.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'app-logs-channel-status-change',
  templateUrl: './logs-channel-status-change.component.html',
  styleUrls: ['./logs-channel-status-change.component.scss']
})
export class LogsChannelStatusChangeComponent implements OnInit {
  reportList = [];
  channelList: ChannelInfo[] = [];
  selectedChannelId;
  from: any;
  to: any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    //// console.log("")
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const toDate = `${year}-${month}-${day}`;
    let body = {
      'fromDate' : '2018-01-01',
      'toDate' : toDate,
      'productId' : 0,
      'productType' : 0
    }
    this.fetchChannelList();
    this.fetchData(body)
    // // console.log("called")
  }

  body

  fetchChannelList(): void {
    this.report.getChannelList().subscribe(data => {
      this.channelList = data.data;
    });
  }

  loadingFlag=false
  isLoad = 'Loading'

  fetchData(body): void {
    this.report.getProductStatusChangeHistory(body)
      .subscribe(data => {
        this.reportList = data.data;
        // //// console.log(this.selectedChannelId, "DATA: ", data)
        if(this.reportList.length != 0){
          this.loadingFlag=false
        }else{
          this.loadingFlag=false
        this.isLoad='No Records'
        }
      },()=>{
        this.loadingFlag=false
        this.isLoad='No Records'
      });
  }

  boquetSelected = false
  listItem(): void {
    this.boquetSelected = true
    if(this.dateSelected){
      let body = {
        'fromDate' : this.ev.startDate.format('YYYY-MM-DD'),
        'toDate' : this.ev.endDate.format('YYYY-MM-DD'),
        'productId' : this.selectedChannelId !=0 ? this.selectedChannelId.id : 0,
        'productType' : 0
      }
      this.body = body
      this.fetchData(body)
    }else{
      this.isLoad = 'Select Date'
      // this.loadingFlag = false
    }
  }

  dateSelected = false
  ev
  dateChange(ev: NgxDateRangeInfo): void {
    this.dateSelected = true
    this.ev = ev
    if (ev.startDate !== null && ev.endDate !== null) {
      if(this.boquetSelected){
        [this.from, this.to] = [ev.startDate, ev.endDate];
        let body = {
          'fromDate' : ev.startDate.format('YYYY-MM-DD'),
          'toDate' : ev.endDate.format('YYYY-MM-DD'),
          'productId' : this.selectedChannelId? this.selectedChannelId.id : '0',
          'productType' : 0
        }
        this.body
        this.fetchData(body);
        this.calendar.setDate(ev);
        this.loadingFlag = true
        this.isLoad = 'Loading'
      }else{
        this.isLoad = 'Select Boquet'
      }
    }
  }

  downloadTableExcel(): void {
    let name='Chanel Status Change Log'
    if(this.body != null){
      this.doc.exportTableToExcelwithImage(this.reportTable,name, 'channel_status_change_logs', undefined, undefined, "Report Date :", this.body.fromDate+" to "+this.body.toDate, "Channel Name :", this.selectedChannelId !=0 ? this.selectedChannelId.name : 'All');
    }else{
      this.doc.exportTableToExcelwithImage(this.reportTable,name, 'channel_status_change_logs', undefined, undefined, "Report Date :", "Start to Now", "Channel Name :", this.selectedChannelId? this.selectedChannelId.name : 'All');
    }
  }

  downloadTablePdf(): void {
    if(this.body){
      this.pdf.generatePdfHtml('#reportTable',
        'channel_status_change_logs',
        `From Date: ${this.body.fromDate}     To Date: ${this.body.toDate}     Channel: ${this.selectedChannelId != 0? this.selectedChannelId.name : "All"}`
      );
    }else{
      this.pdf.generatePdfHtml('#reportTable',
        'channel_status_change_logs',
        `From Start to Now    Channel: ${this.selectedChannelId? this.selectedChannelId.name : "All"}`
      );
    }
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Date", "Channel Name", "Channel ID", "Channel Status","Channel Type","Modified by"]
    };

    let data = []
    // let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        // slNo : i,
        date: line.created_date,
        name: line.product_name,
        id: line.product_id,
        status: line.product_status ===1?'Active':'Inactive',
        type: line.product_type ===0?'Bouquet':'Channel',
        modified : line.modified_by
      }
      // i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'Channel_Status_Change_Logs', options);
  }

}
