<div class="cms__content">
  <app-reports-header></app-reports-header>

 <div class="top__heading">
   <h3>Broadcaster Bouquet Wise Weekly Report</h3>
   <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
 </div>
 <div class="clearfix"></div>

 <div class="row row-top">
  <div class="date_filter">
      <div class="div_tabs" *ngIf="dateSelector">
        <button (click)="dateTypeSelectors(1)" >Month</button>
        <button (click)="dateTypeSelectors(2)" >Random Date</button>
      </div>
      <div class="div_tabs2" *ngIf="showMonth" >
       <div class="date_box">
        <label for="month">Select Month:</label>
        <div class="date_box_orig">
          <input class="custom__field mar_zeroed" type="month" id="month"  value="selectedDate" (change)="dateChangeMonth($event)">
        </div>
       </div>
        <button (click)="dateTypeSelectors(0)" >X</button>
      </div>
      <div class="div_tabs2" *ngIf="showDateRange" >
        <label for="dateRange">
          Select Date :
        </label>
        <div class="date_box_orig">
          <app-date-picker id="mar_zeroeds" (dateChange)="dateChangeRange($event)" ></app-date-picker>
        </div>
        <button id="app_date_pick_button" (click)="dateTypeSelectors(0)" >X</button>
      </div>
    </div>
   <div class="download__report col-md-6">
     <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
       PDF
       <img src="./assets/pdf.svg" alt="download" />
     </button>
     <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
       Excel
       <img src="./assets/excel.svg" alt="download" />
     </button>
     <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
           CSV
           <img src="./assets/csv.svg" alt="download" />
        </button>
   </div>
 </div>

 <div class="col-12 col-md-6 report_selection_block">
   <label class="report_selection_label">Select Broadcaster</label>
   <select class="custom__field report_selection" [(ngModel)]="broadcasterId" (ngModelChange)='broadcasterSelected()' [disabled]="!dateChoosed">
     <!-- Changed ngValue binding from lco.id to lco.email -->
     <!-- <option value = 0 >ALL</option> -->
     <option *ngFor="let lco of broadcasterList" [ngValue]='lco.id'>
       {{lco.name}}
     </option>
   </select>
 </div>
 <div class="third_box">
  <div class="col-12 col-md-6 report">
    <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
  </div>
  <div style="display: flex; gap:20px" >
    <div class="table__right">
      <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
    </div>
    <div class="search__container">
      <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
      <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
    </div>
  </div>
</div>
 <div class="col-12 list__table">
   <div class="row scroll">
     <table class="custom__iptv__table" #reportTable id="reportTable">
       <thead>
         <tr>
           <!-- <th>Date</th> -->
           <th>Sl No</th>
           <th>Bouquet Name</th>
           <th>Channels</th>
           <th *ngIf="!tableDisplay">Subscriber Count</th>
           <th *ngIf="tableDisplay">Subscriptions on 7th</th>
           <th *ngIf="tableDisplay">Subscriptions on 14th</th>
           <th *ngIf="tableDisplay">Subscriptions on 21st</th>
           <th *ngIf="tableDisplay">Subscriptions on 28th</th>
           <!-- <th *ngIf="tableDisplay">Subscriptions on End of Month</th> -->
           <th *ngIf="tableDisplay">Average Subscription</th>
           <!-- <th>Bouquet Pay Channel Name</th> -->
           <!-- <th>Channel LCN</th> -->
           <!-- <th>Channel ID</th> -->
           <!-- <th>Subscription Type</th> -->
           <!-- <th>Total Subscriptions</th> -->
         </tr>
       </thead>
       <tbody>
         <!-- <tr *ngIf="reportList.length == 0 && !isLoading" >
          <td colspan="8" style="text-align: center;" >{{isLoad}}</td>
         </tr>
         <tr *ngIf="isLoading" >
          <td colspan="8" style="text-align: center;" ><app-custom-spinner></app-custom-spinner></td>
         </tr> -->
         <tr *ngIf="isLoading && reportList.length<=0" >
          <td colspan="8" style="text-align: center;" ><app-custom-spinner></app-custom-spinner></td>
        </tr>
        <tr *ngIf="!dateChoosed" >
          <td colspan="8" style="text-align: center;" >Choose Date</td>
        </tr>
           <tr *ngFor="let report of reportList, let j=index">
             <td>{{j+1}}</td>
             <td>{{(report.bouquet_name || report.boquetName) == null || (report.bouquet_name || report.boquetName) == 'null' || (report.bouquet_name || report.boquetName) == ''? '---' : (report.bouquet_name || report.boquetName) }}</td>
             <td >{{(report.channel_names_list || report.channelNamesList) == null || (report.channel_names_list || report.channelNamesList) == 'null' || (report.channel_names_list || report.channelNamesList) == '' ? '---' : (report.channel_names_list || report.channelNamesList) }}</td>
             <td *ngIf="!tableDisplay">{{report.subscriberCount}}</td>
             <td *ngIf="tableDisplay">{{report.subscriberCountAt7}}</td>
             <td *ngIf="tableDisplay">{{report.subscriberCountAt14}}</td>
             <td *ngIf="tableDisplay">{{report.subscriberCountAt21}}</td>
             <td *ngIf="tableDisplay">{{report.subscriberCountAt28}}</td>
             <!-- <td *ngIf="tableDisplay">{{report.subscriberCountAtEndOfTheMonth}}</td> -->
             <td *ngIf="tableDisplay">{{(report.subscriberCountAt7+report.subscriberCountAt14+
               report.subscriberCountAt21+report.subscriberCountAt28)/4}}</td>
               <!-- <td></td> -->
             <!-- <td>{{report.channelNo}}</td> -->
             <!-- <td>{{report.channelId}}</td> -->
           </tr>
       </tbody>
     </table>
   </div>
 </div>
</div>
