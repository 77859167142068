import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WalletHomeComponent } from './wallet-home/wallet-home.component';
import { AddLcoFundsComponent } from './add-lco-funds/add-lco-funds.component';
import { AddSubscriberFundsComponent } from './add-subscriber-funds/add-subscriber-funds.component';
import { ReceiveLcoFundsComponent } from './receive-lco-funds/receive-lco-funds.component';
import { TransactionLogsComponent } from './transaction-logs/transaction-logs.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentErrorComponent } from './payment-error/payment-error.component';
import { WalletRechargeComponent } from './wallet-recharge/wallet-recharge.component';
import { AddDistributorFundsComponent } from './add-distributor-funds/add-distributor-funds.component';

const routes: Routes = [
  {
    path:'', component : WalletHomeComponent
  },
  {
    path : 'add-lco-funds',
    component : AddLcoFundsComponent
  },
  {
    path : 'add-distributor-funds',
    component : AddDistributorFundsComponent
  },
  {
    path : 'add-subscriber-funds',
    component : AddSubscriberFundsComponent
  },
  {
    path : 'receive-lco-funds',
    component : ReceiveLcoFundsComponent
  },
  {
    path : 'payment-success-rerouting',
    component : PaymentSuccessComponent
  },
  {
    path : 'payment-failure-rerouting',
    component : PaymentErrorComponent
  },
  {
    path : 'lco-wallet-recharge',
    component : WalletRechargeComponent
  },
  {
    path : 'distributor-wallet-recharge',
    component : WalletRechargeComponent
  },
  {
    path : 'transaction-history',
    component : TransactionLogsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WalletRoutingModule { }
