import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-receive-lco-funds',
  templateUrl: './receive-lco-funds.component.html',
  styleUrls: ['./receive-lco-funds.component.scss'],
})
export class ReceiveLcoFundsComponent implements OnInit {
  receiveFundsForm: FormGroup;
  lcoData;
  lcoID;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private auth: AuthService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  getLcoCode() {
    const url =
      this.config.json.apiEndpointRdbms +
      'lco/getLCOByCode/' +
      this.lcoCode.value;
    this.http.get(url).subscribe(
      (res) => {
        this.lcoData = res.data;
        this.lcoID = this.lcoData.id;
      },
      (err) => {
        //// console.log(err);
      }
    );
  }

  initForm(): void {
    this.receiveFundsForm = new FormGroup({
      lcoCode: new FormControl('', [Validators.required]),
      modeOfPayment: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      transactionID: new FormControl('', [Validators.required]),
      remarks: new FormControl('', [Validators.required]),
    });
  }

  get lcoCode(): FormControl {
    return this.receiveFundsForm.get('lcoCode') as FormControl;
  }

  get modeOfPayment(): FormControl {
    return this.receiveFundsForm.get('modeOfPayment') as FormControl;
  }

  get amount(): FormControl {
    return this.receiveFundsForm.get('amount') as FormControl;
  }

  get transactionID(): FormControl {
    return this.receiveFundsForm.get('transactionID') as FormControl;
  }

  get remarks(): FormControl {
    return this.receiveFundsForm.get('remarks') as FormControl;
  }

  createBody() {
    let body = {};
    body['subscriberId'] = this.lcoID;
    body['subscriberCode'] = this.lcoCode.value;
    body['modeOfPayment'] = this.modeOfPayment.value;
    body['amount'] = this.amount.value;
    body['transactionId'] = this.transactionID.value;
    body['remarks'] = this.remarks.value;
    return body;
  }

  searchText!: string;
  openSearch = false;
  getSubscriberId(e: Event) {
    this.openSearch = false;
    this.searchText = (e.target as HTMLInputElement).value;
    if(this.searchText.length >=3 ){
      setTimeout(() => {
        this.openSearch = true;
      }, 500);
    }
  }
  clearInput() {
    let input = document.getElementById('lcoCode') as HTMLInputElement;
    input.value = '';
    (this.searchText = ''), (this.openSearch = false);
    this.receiveFundsForm.reset();
  }

  getUser(e: LcoSearchType) {
    let inputBox = document.getElementById('lcoCode') as HTMLInputElement;
    if ((e as any) == '') {
      this.openSearch = false;
      this.searchText = undefined;
      this.receiveFundsForm.get('lcoCode').setValue(null);
      inputBox.value = '';
      return;
    }
    this.receiveFundsForm.get('lcoCode').setValue(e.lcoCode);
    inputBox.value = `${e.lcoCode}, ${e.lcoName}`;
    this.openSearch = false;
    this.searchText = undefined;
    this.searchText = undefined;
  }

  persist() {
    let body = this.createBody();
    let url = this.config.json.apiEndpointRdbms + 'wallet/paymentByLco';

    this.http.post(url, body).subscribe(
      (res) => {
        if (res.status === 'FAIL') {
          alert(res.message);
        } else {
          alert(res.message);
          this.receiveFundsForm.reset();
        }
      },
      (err) => {
        alert(err);
      }
    );
  }
}
