<div class="multi-select">
  <div class="data-field" (click)="openSelect()">
    <input type="text" style="display: none;" [formControl]="inputControl">
    <div class="selected-items">
      <ng-container *ngFor="let selectedItem of selectedData">
        <span>{{selectedItem[options?.keyToDisplay]}}</span>
      </ng-container>
    </div>
    <div class="drop-icon"></div>
  </div>
  <div class="expanded" >

      <div class="select-all">
        <input type="checkbox" [checked]="isSelectAll" name="selectAll" id="selectAll" (change)="selectAll()">
        <label for="selectAll" class="not__required" (click)="selectAll()">{{isSelectAll ? 'Unselect all' : 'SelectAll'}}</label>
        <div class="clear-search">
          <input type="text" placeholder="search" class="custom__field" (input)="partialSearch($event)" (keydown.enter)="searchThroughDataArray($event)" >
          <!-- <button (click)="clearSelected()">clear</button> -->
        </div>
      </div>
      <div class="options">
        <ng-container *ngFor="let data of options?.dataArray; let i=index">
          <div class="option" [ngClass]="{'disable':data[options?.idForDisabled] == options?.valueForDisabled}">
            <input type="checkbox" (change)="toggleSelection(data,i)" [name]="'item'+i"
              [checked]="isSelectAll || data?.checked" [id]="'item'+i">
            <label class="not__required" [for]="'item'+i">{{data[options?.keyToDisplay]}}</label>
          </div>
        </ng-container>
      </div>
      <button class="cta" (click)="continue()">Continue</button>
  </div>
</div>
