import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { SubscriberInfo } from 'src/app/models/subscriber/subscriber-info';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-report-package-ageing-inactive',
  templateUrl: './report-package-ageing-inactive.component.html',
  styleUrls: ['./report-package-ageing-inactive.component.scss']
})
export class ReportPackageAgeingInactiveComponent implements OnInit {

  reportList = [];
  from:any;
  to:any;
  subscriberId: number;
  subscriberList: SubscriberInfo[] = [];

  currentDate = new Date();


  selected: NgxDateRangeInfo;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  locale: any = {
    format: 'DD/MM/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'DD/MM/YYYY', // default is format value
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Select', // detault is 'Apply'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 0 // first day is monday
  };

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http : HttpClientWrapperService,
    private config : ConfigService
  ) { }



  ngOnInit(): void {
    // this.fetchSubscriberList();
    this.fetchData()
  }



  fetchSubscriberList(): void {
    this.report.getSubscribersListReport(this.from, this.to,{}).subscribe(data => {
      this.subscriberList = data;
      // //// console.log(data)
    });
  }

  isload = 'Loading'
  isLoading = false

  fetchData(): void {
    this.isLoading = true
    // //// console.log("call started")
    // const body = {
    //   "subscriberId": this.subscriberId
    // }
    // // this.report.getPackageAgeingReport(this.from, this.to,body).subscribe(data => {
    //   //   // //// console.log(data)
    //   //   this.reportList = data;
    //   // });
    //   const url = 'http://192.168.10.21:7080/admin/api/subscriber/getPackageAgeingInactiveList'
    //   this.http.get(url).subscribe((res)=>{
    //     //// console.log(res)
    //     this.reportList = res
    // })
    this.report.getPackageAgeingInActive().subscribe((res)=>{
      //// console.log(res)
      this.reportList = res.data
      if(res.data.length == 0){
        this.isLoading=false
        this.isload = 'No records'
      }
    })
  }

  selectSubscriber():void{
    this.fetchData()
  }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.calendar.setDate(ev);
      this.fetchData();
    }
  }

  downloadTableExcel(): void {
    let name='Package Ageing Inactive Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'inactive_package_ageing_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable',
    'package_ageing_inactive_report',
    `Source Type : SMS`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl N0.","Subscriber Code","MAC ID","Price Package Name","Subscription Status","Activation Date","Expiry Date","Ageing Days"]
    };
    let data = []
    let i =1
    this.reportList.forEach(report => {
      let csvLine = {
        slNo : i,
        subscriber_code : report.subscriber_code,
        ethernet_mac_address : report.ethernet_mac_address,
        price_package_name : report.price_package_name,
        subscription_status : report.subscription_status,
        start_date : report.start_date,
        end_date : report.end_date,
        ageing : report.ageing
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'package_ageing_Inactive_report', options);
  }

  searchKeyword(){
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms+'subscriber/getPackageAgeingInactiveList/?searchKeyword='+value
    this.http.get(url).subscribe((res)=>{
      //// console.log(res)
      this.isLoading = true
      this.reportList = res.data
      this.isLoading=false
      if(res.data.length == 0 ){
        this.isLoading=false
        this.isload = 'No Records'
      }
    })
  }

}
