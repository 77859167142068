<div class="cms__content">
  <div class="top__heading">
    <h3>Send Mails</h3>
    
    <button
      (click)="saveSample()"
      id="downLoadSample"
      class="default__button float-right"
    >
      <img
        style="width: 15px; height: fit-content; fill: black"
        src="assets/download.svg"
        alt=""
      />
      Download Sample
    </button>
    <a [routerLink]="['/batch-jobs']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="bod">
    <div class="to col-12">
      <label for="to">To : </label>
      <div style="display: flex; gap: 10px">
        <input type="file"
          name="to"
          accept=".xls, .xlsx"
          id="to"
          class="custom__field"
          (change)="selectedFile($event)"
        />
        <button id="clearInput" (click)="clearInput()">Clear</button>
      </div>
    </div>
    <div class="error" *ngIf="isError">
      <h4>Errors Found :</h4>
      <ng-container *ngFor="let item of errorBody">
        &nbsp;&nbsp;&nbsp;>{{ item }} <br />
      </ng-container>
    </div>
    <div class="subject col-12" *ngIf="isValidated">
      <label for="subj">Subject :</label>
      <input type="text" autocomplete="off" id="subject" (input)="subject()" />
    </div>
    <div class="body col-12" *ngIf="isBody">
      <label for="body_">Body : </label>
      <textarea
        name="body_"
        id="body_"
        cols="30"
        rows="10"
        (input)="body()"
      ></textarea>
    </div>
    <div class="send" *ngIf="isSend">
      <button (click)="submit()">Send</button>
    </div>
  </div>
</div>
