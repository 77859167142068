import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Observable } from 'rxjs';
import { AclGroupInfo, AclMenus } from 'src/app/models/acl-group/acl-group-info';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-acl',
  templateUrl: './acl.component.html',
  styleUrls: ['./acl.component.scss']
})
export class AclComponent implements OnInit {
  
  aclGroupList: AclGroupInfo[] = []

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchData()
    // this.fetchMenus()
  }

  getAclGroupList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'aclgroup/getAclGroups';
    return this.http.get(url);
  }

  getMenus(): Observable<any>{
    const url = this.config.json.apiEndpointRdbms + 'menu/getMenus';
    return this.http.get(url);
  }

  // fetchMenus(){
  //   this.getMenus().subscribe((data)=>//// console.log("MENU: ",data))
  // }

  fetchData(){
    this.getAclGroupList().subscribe((data) => {
      this.aclGroupList = data
    })
  }

  edit(record: AclGroupInfo): void {
    this.router.navigate(['/settings/access-control', record.id]);
  }

}
