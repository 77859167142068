<div class="table-container overflow">
  <table class="custom__iptv__table overflow">
    <thead>
      <tr>
        <th>Name</th>
        <th>Price</th>
        <th>Set Price</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let package of packagesList">
        <tr>
          <td>{{ package.name }}</td>
          <td>{{ package.price }}</td>
          <td>
            <input
              type="number"
              class="custom__field"
              [value]="package.priceUpdate[package.id]?.distributorShare ? package.priceUpdate[package.id]?.distributorShare : 0"
              (input)="updatePrice($event, package.id, package.price)"
            />
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div class="button__group">
  <button (click)="back()">Back</button>
  <button class="cta" (click)="setPackagePrices()">Next</button>
</div>
