<form action="" [formGroup]="distributorForm">
  <div class="col-12 form__style distributorForm">
    <div class="row">
      <div class="col-12 col-md-6">
        <label for="name">Name</label>
        <input type="text" autocomplete="off" class="custom__field" formControlName="name" />
      </div>
      <div class="field_container col-12 col-md-6">
        <label for="name">Mobile Number</label>
        <input type="text" class="custom__field" formControlName="mobileNumber"
          oninput="this.value = this.value.replace(/[^0-9]/g, '')" pattern="^\d{10}$" placeholder="format: +91......"
          (input)="checkMobileNumberFormat($event)" maxlength="10" />
        <div class="error">
          <span class="error__message" *ngIf="isMobileNumberInvalid">Mobile Number Invalid</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="">
          <label for="name">Email</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="email" />
        </div>
        <div class="error__msg" *ngIf="distributorForm?.get('email')?.errors?.email">
          <span *ngIf="distributorForm?.get('email')?.errors?.email" style="color: rgb(180, 0, 0)">Email is
            Invalid</span>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label for="name">Status</label>
        <select class="custom__field" name="status" id="status" formControlName="status">
          <option value="" disabled>--Select--</option>
          <option value="1" selected>Active</option>
          <option value="0">Inactive</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label for="name">Location</label>
        <!-- <input type="text" autocomplete="off" class="custom__field" formControlName="location" /> -->
        <select name="location" id="" class="custom__field" formControlName="locationId">
          <option selected disabled value="">---Select---</option>
          <option *ngFor="let item of location" [value]="item.id">
            {{ item.locality }} [{{ item.city }}, {{ item.state }}]
          </option>
        </select>
      </div>
    </div>
    <div class="row" *ngIf="!isEdit">
      <div class="password col-12 col-md-6 password-field">
        <label for="name">Password</label>
        <input [type]="showPasswordField ? 'password' : 'text'" id="password" autocomplete="off"
          formControlName="password" class="custom__field" />
        <span *ngIf="passwordValidity.touched && !passwordStringMatch()" style="color: brown">Invalid Password</span>
        <img (click)="showPasswordField = !showPasswordField" [src]="
            showPasswordField
              ? '/assets/icons/eye.svg'
              : '/assets/icons/eye-no.svg'
          " alt="Show Password" />
      </div>
      <div class="password col-12 col-md-6 password-field">
        <label for="name">Confirm Password</label>
        <input [type]="showConfirmPasswordField ? 'password' : 'text'" id="passwordC" autocomplete="off"
          formControlName="confirmPassword" class="custom__field" (input)="checkConfirmPassword()" />
        <span *ngIf="isConfirmPasswordError" style="color: brown">Password mismatch</span>
        <img (click)="showConfirmPasswordField = !showConfirmPasswordField" [src]="
            showPasswordField
              ? '/assets/icons/eye.svg'
              : '/assets/icons/eye-no.svg'
          " alt="Show Password" />
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="width: 100%">
        <label for="name">Address</label>
        <textarea rows="5" type="text" class="custom__field" formControlName="address"></textarea>
      </div>
    </div>
  </div>
  <button [disabled]="formCalculated()" (click)="isEdit ? update() : getFormData()" class="cta float-right">
    Next
  </button>
</form>