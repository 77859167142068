<div class="cms__content">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-2 top__heading">
                <h3>My Wallet</h3>
            </div>
            <div class="col-12 col-md-4 offset-md-2">
                <div class="card">
                    <b>your balance</b>
                    <p class="superBig highlight">Rs {{walletBalance}}</p>
                    <p>Last recharged <label class="safe__label">5 days ago</label></p>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card left-intend">
                    <b>recharge Wallet</b>
                    <input type="number" class="custom__field" placeholder="Enter Amount" [(ngModel)]="amount" />
                    <div class="recharge__history">
                        last recharge amount <b>Rs {{rechargeAmount}}</b>
                        <button>Repeat</button>
                    </div>
                    <button class="cta" (click)="recharge()">Recharge</button>
                </div>
            </div>

            <div class="col-12 col-md-8 offset-md-2" *ngIf="paymentStatus">
                <div class="card">
                    <div class="complete__tick">
                        <img src="./assets/check_lco.svg" alt="Completed">
                    </div>
                    <b>Payment Successful</b>
                </div>
            </div>
        </div>
    </div>
</div>