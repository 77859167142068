import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListBroadcasterComponent } from './list-broadcaster/list-broadcaster.component';
import { CreateBroadcasterComponent } from './create-broadcaster/create-broadcaster.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListBroadcasterComponent },
  { path: 'create', component: CreateBroadcasterComponent },
  { path: ':id', component: CreateBroadcasterComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BroadcasterRoutingModule { }
