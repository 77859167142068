import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import { PageMetadata } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';

@Component({
  selector: 'app-logs-subscriber-sms',
  templateUrl: './logs-subscriber-sms.component.html',
  styleUrls: ['./logs-subscriber-sms.component.scss']
})
export class LogsSubscriberSmsComponent implements OnInit {

  paginationObj = new PaginationObj();
  loadingFlag = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  reportList = [];
  isLoad = "No Data"
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;
  constructor(
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
  ) { }

  ngOnInit(): void {
    this.fetchData()
  }

  fetchData(){
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    const url = PaginationUtilities.urlBuilder(
      'logs/subscriberSMSLogs/list',
      this.paginationObj
    );
    console.log(url);
    this.http.get(url).subscribe({
      next : (data) => {
        this.reportList = data.data
        this.handlePaginationMetadata(data.metadata);
        if (data.data.length == 0) {
          this.isLoad = 'No Data';
        }
      },
      error : (err) =>{
        console.log(err);

      }
    })
  }

  handlePaginationMetadata(data: PageMetadata) {
    this.paginationObj = {
      ...this.paginationObj,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
      totalPages: data.totalPageCount,
      totalElements: data.totalElementCount,
      sortBy: data.sortBy,
      sortOrder: data.sortOrder,
      searchKeyword: data.searchKeyword,
    };
  }

  searchText = ''
  searchKeyword() {
    this.paginationObj = {
      ...this.paginationObj,
      searchKeyword : this.searchText
    }
    this.fetchData()
  }


  // downloadTableExcel(): void {
  //   let name = 'Complete Logs';
  //   this.doc.exportTableToExcelwithImage(
  //     this.reportTable,
  //     name,
  //     'subscriber_sms_logs'
  //   );
  // }


  
  downloadTableExcel(){
    let url

      url= this.config.json.apiEndpoint+'generate/subscriberSMSLogs?searchKeyword='+this.paginationObj.searchKeyword

      this.http.getwithresponseType(url).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'subscriber_sms_logs.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }

  downloadTablePdf(): void {
      this.pdf.generatePdfHtml(
        '#reportTable',
        'subscriber_sms_logs',
      );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Sl No',
        'Date',
        'Mobile Number',
        'Message Body',
        'Response Body'
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slNo: i,
        createdDate: line.createdDate,
        mobileNumber : line.mobileNumber,
        messageBody : line.messageBody,
        responseBody : line.responseBody
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_sms_Logs', options);
  }



  //pagination

  pagePreviousBtnClick(): void {
    this.paginationObj.pageNumber--
    this.fetchData()
    this.handleDisableStatus()
  }

  pageNextBtnClick(): void {
    this.paginationObj.pageNumber++
    this.fetchData()
    this.handleDisableStatus()
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = 1
    this.fetchData()
    this.handleDisableStatus()
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages
    this.fetchData()
    this.handleDisableStatus()
  }

  pageSizeChange(e: Event) {
    let pageSize = +((e.target as HTMLSelectElement).value)
    this.paginationObj = {
      ...this.paginationObj,
      pageSize
    }
    this.fetchData()
  }

  handleDisableStatus(){
    if(this.paginationObj.pageNumber == 1){
      this.disablePreviousBtn = true
    }else{
      this.disablePreviousBtn = false
    }
    if(this.paginationObj.pageNumber == this.paginationObj.totalPages){
      this.disableNextBtn = true
    }else{
      this.disableNextBtn = false
    }
  }

}
