import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[imgConverter]'
})
export class ImageSrcConverterDirective implements AfterViewInit {

  @Input() src: string;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngAfterViewInit(): void {
    const img = new Image();

    // Slice to create a copy of the string
    let httpsUrl = this.src.slice(0, this.src.length);
    httpsUrl = httpsUrl.replace(environment.imageUrlPrefix, environment.imageHttpsUrlPrefix);

    img.onload = () => {
      this.setImage(httpsUrl);
    };

    img.onerror = () => {
      this.setImage(this.src);
    };

    img.src = httpsUrl;
  }

  private setImage(imageSrc: string): void {
    this.elementRef.nativeElement.setAttribute('src', imageSrc);
  }

}
