import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { LogsDataService } from '../plugins/logs-data.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { Observable } from 'rxjs';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-user-login-history',
  templateUrl: './user-login-history.component.html',
  styleUrls: ['./user-login-history.component.scss'],
})
export class UserLoginHistoryComponent implements OnInit {
  reportList = [];
  from: any;
  to: any;
  currentDate = new Date();
  loadingFlag = false;
  isLoad = 'Choose Date';
  paginationObj = new PaginationObj();
  dateSelected = false;
  broadcasteSelected = false;
  ev;
  reportDate;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) {}

  body = {
    fromDate: '',
    toDate: '',
  };
  user
  userId
  ngOnInit(): void {
    if(this.config.json.userType=='MSO'){
      this.user=true
    }
    else{
      this.user=false
    }
    this.userId=JSON.parse(localStorage.getItem('profileInfo')).id
    this.fetchData();
  }

  fromDate = '';
  toDate = '';
  dateChange(e: NgxDateRangeInfo) {
    let fromDate = `${e.startDate.year()}-${String(
        e.startDate.month() + 1
      ).padStart(2, '0')}-${String(e.startDate.date()).padStart(2, '0')}`,
      toDate = `${e.endDate.year()}-${String(e.endDate.month() + 1).padStart(
        2,
        '0'
      )}-${String(e.endDate.date()).padStart(2, '0')}`;
    this.body = {
      fromDate,
      toDate,
    };
    this.fetchData();
    [this.fromDate, this.toDate] = [fromDate, toDate];
  }

  fetchData(): void {
    this.loadingFlag=true;
    this.reportList=[];
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint)
    let url=''
    if(this.user==true){
     url = PaginationUtilities.urlBuilder('logs/userLoginHistory', this.paginationObj)
    url += `&fromDate=${this.body.fromDate}&toDate=${this.body.toDate}`
  }
    else{
      url = PaginationUtilities.urlBuilder('logs/userLoginHistoryForLcoOrDistributor', this.paginationObj)
    url += `&fromDate=${this.body.fromDate}&toDate=${this.body.toDate}&userType=${this.config.json.userType}&userId=`+this.userId
    }
    this.http.get(url).subscribe(
      (data: any) => {
        this.reportList = data.data;
        this.handlePageMetadata(data.metadata)
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus()
  }

  // downloadTableExcel(): void {
  //   let name = 'User Login History';
  //   if (this.reportList.length == 0) {
  //     alert('No Data!!');
  //   } else {
  //     if (this.ev) {
  //       this.doc.exportTableToExcelwithImage(
  //         this.reportTable,
  //         name,
  //         name,
  //         undefined,
  //         undefined,
  //         'Filter Date :'
  //       );
  //     } else {
  //       this.doc.exportTableToExcelwithImage(
  //         this.reportTable,
  //         name,
  //         name,
  //         undefined,
  //         undefined
  //       );
  //     }
  //   }
  // }

  
  downloadTableExcel(){
    let url
    if(this.config.json.userType=='MSO'){
      url= this.config.json.apiEndpoint+'generate/userLoginHistory?searchKeyword='+this.paginationObj.searchKeyword
      if (this.from != "0000-00-00" && this.to != "0000-00-00") {
        url = url + "&fromDate=" + this.fromDate + "&toDate=" + this.toDate;
      }
    }
      else{
        url= this.config.json.apiEndpoint+'generate/userLoginHistoryForLcoOrDistributor?&userType='+this.config.json.userType+
        '&userId='+this.userId+'&searchKeyword='+this.paginationObj.searchKeyword   
        if (this.from != "0000-00-00" && this.to != "0000-00-00") {
          url = url + "&fromDate=" + this.fromDate + "&toDate=" + this.toDate;
        }
      }
      this.http.getwithresponseType(url).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'User_Login_History.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }

  downloadTablePdf(): void {
    if (this.reportList.length == 0) {
      alert('No Data!!');
    } else {
      if (this.ev) {
        this.pdf.generatePdfHtml(
          '#reportTable',
          'User_Login_History',
          `Filter date : ${this.reportDate}`,
          'portrait'
        );
      } else {
        this.pdf.generatePdfHtml(
          '#reportTable',
          'User_Login_History',
          `From Start to Now`,
          'portrait'
        );
      }
    }
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Sl No',
        'Date',
        'Broadcaster Name',
        'Channel Name',
        'Channel ID',
        'Channel Number',
        'Action',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        date: line.fromDate,
        name: line.broadcasterName,
        channelName: line.channelName,
        channelId: line.channelId,
        channelNo: line.channelNo,
        action: line.action || line.ACTION,
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'User_Login_History', options);
  }

  disableFirstBtn = false;
  disablePreviousBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;

  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (
      this.paginationObj.pageNumber == this.paginationObj.totalPages
    ) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }

}
