import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { PackageInfo } from 'src/app/models/package/package-info';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-report-boquet-modification',
  templateUrl: './report-boquet-modification.component.html',
  styleUrls: ['./report-boquet-modification.component.scss']
})
export class ReportBoquetModificationComponent implements OnInit {
  reportList = []
  bouquetList: PackageInfo[] = [];
  selectedBouquetId: number =0;
  from:any;
  to:any;

  currentDate = new Date();

  loadingFlag=false
  isLoad = 'Choose Date'
  reportDate;
  eve

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config :ConfigService,
    private http : HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    // this.fetchBouquetList()
    this.fetchData(this.body)
  }


body = {
  fromDate : '',
  toDate : '',
  tillDate :'',
  searchKeyword : ''
}
  dateChange(ev: NgxDateRangeInfo): void {
    this.loadingFlag = true
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve = ev
      this.body = {
        ...this.body,
        fromDate : ev.startDate.format('YYYY-MM-DD'),
        toDate : ev.endDate.format('YYYY-MM-DD'),
      }
      this.fetchData(this.body);
      this.reportDate = ev.startDate.date()+"-"+this.doc.monthNameCalculator(ev.startDate.month())+"-"+ev.startDate.year()+" to "+ev.endDate.date()+"-"+this.doc.monthNameCalculator(ev.endDate.month())+"-"+ev.endDate.year()
      this.calendar.setDate(ev);
    }
  }


     fetchData(body : {fromDate : string; toDate: string; tillDate : string; searchKeyword : string;}): void {
      this.reportList = []
    this.report.getBouquetChangesHistory(body).subscribe(data => {
      this.reportList = data.data;
      if(this.reportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }


  // downloadTableExcel(): void {
  //   let name=' Report Bouquet Modification Report'
  //   this.doc.exportTableToExcelwithImage(this.reportTable, name,'bouquet_modification_report', undefined, undefined, 'Report Date :', this.reportDate);
  // }


  downloadTableExcel() {
    let url = `${this.config.json.apiEndpoint}generate/getBouquetModificationHistory`;
  
    this.http.postwithresponseType(url,this.body).subscribe(
      (response) => {
        // Attempt to parse the response as JSON
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const jsonResponse = JSON.parse(reader.result as string);
            // Check if the response contains a failure status
            if (jsonResponse.status === 'FAIL') {
              console.error(`Error: ${jsonResponse.message}`);
              return;
            }
          } catch (error) {
            // If parsing fails, assume the response is a Blob
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'bouquet_modification_report.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
          }
        };
        reader.readAsText(response);
      },
      (error) => {
        console.error('Error downloading Excel file:', error);
      }
    );
  }
  downloadTablePdf(): void {
    if(this.reportList == undefined || this.reportList == null || this.reportList?.length == 0){
      alert('No Data')
      return;
    }
    this.pdf.generatePdfHtml('#reportTable',
    'bouquet_modification_report',
    `Report Date: ${this.reportDate ? this.reportDate : "Till Now"} ,   Source Type : SMS`
    );
  }

  formatDate(ele) {
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.","Channel ID","Resource Code ID","Resource Name","Status","Package Code","Bouquet ID","Bouquet Name"
      ,"Transaction Date"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slno : i,
        service_asset_id: line.service_asset_id,
        resource_code: line.resource_code,
        resource_name: line.resource_name,
        channel_status: line.channel_status,
        package_code: line.package_code,
        bouquet_id: line.bouquet_id,
        package_name: line.package_name,
        transaction_date: line.transaction_date,
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'bouquet_modification_report', options);
}
searchKeyword(){
  let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
  this.body = {
    ...this.body,
    searchKeyword : value
  }
  this.fetchData(this.body)
}


}
