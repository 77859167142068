import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateRegionComponent } from './create-region/create-region.component';
import { ListRegionComponent } from './list-region/list-region.component';
import { SharedModule } from '../shared/shared.module';
import { StatewiseLCNRoutingModule } from './statwiselcn-routing.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AssignLcmComponent } from './assign-lcm/assign-lcm.component';
import { AssignLcnComponent } from './assign-lcn/assign-lcn.component';



@NgModule({
  declarations: [
    CreateRegionComponent,
    ListRegionComponent,
    AssignLcmComponent,
    AssignLcnComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    StatewiseLCNRoutingModule,
    NgMultiSelectDropDownModule
  ]
})
export class StatwiseLcnModule { }
