import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { SubscriberInfo } from 'src/app/models/subscriber/subscriber-info';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-report-subscribers-list',
  templateUrl: './report-subscribers-list.component.html',
  styleUrls: ['./report-subscribers-list.component.scss'],
})
export class ReportSubscribersListComponent implements OnInit {
  reportList = [];

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}
  ngOnInit(): void {
    // this.fetchData()
    this.status = 5;
    this.statusChanger();
  }

  fetchData(): void {
    // this.report.getSubscribersListOnlyReport().subscribe(data => {
    //   //// console.log(data)
    //   this.reportList = data;
    //   // filtering data wrt from and to dates
    //   // const filteredData = data.filter(each => {
    //   //   const eachDate = new Date(each["createdDate"])
    //   //   if(eachDate >= from && eachDate <= to)
    //   //     return each;
    //   //   }
    //   // )
    //   // //// console.log(filteredData)
    //   // this.reportList = filteredData.reverse();
    // });
    // //// console.log("Calleed")
    // const url = `http://192.168.10.9:7080/admin/api/subscriber/getSubscriberDetails`
    // this.http.get(url).subscribe((res)=>{
    //   //// console.log(res)
    //   this.reportList =res
    // })
    this.report.getSubscriberListReport().subscribe(
      (res) => {
        //// console.log(res)
        this.reportList = res.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  loadingFlag = false;
  isLoad = 'Choose Date';

  statUS = ['Inactive', 'Active', 'Blacklisted', 'New', 'Terminated'];

  downloadTableExcel(): void {
    let status = [...this.statUS, 'ALL'],
      filterName = status[this.status];
    let name = 'Subscriber list Report';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'subscribers_list_report',
      undefined,
      undefined,
      'Status',
      filterName
    );
  }

  downloadTablePdf(): void {
    let status = [...this.statUS, 'ALL'],
      filterName = status[this.status];
    // this.pdf.generatePdfHtml(
    //   '#reportTable',
    //   'subscribers_list_report',
    //   `Status: ${filterName} Source type : SMS`,
    //   null,
    //   null,
    //   'a3'
    // );
    this.pdf.generatePDFForLongTable('Subscribers List',`Status: ${filterName} Source type : SMS`,1800)
  }
  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'Sl No',
        'Subscriber Code',
        'Name',
        'City',
        'State Name',
        'Phone Number',
        'Packages',
        'Status',
        'Mac Address',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((report) => {
      let csvLine = {
        slNo: i,
        subscriberCode: report.subscriberCode,
        Name: report.Name,
        city: report.city ? report.city : 'No Data',
        stateName: report.stateName ? report.stateName : 'No Data',
        mobileNumber: report.mobileNumber,
        packages: report.packages,
        status: this.statUS[report.status],
        ethernetMacAddress: report.ethernetMacAddress,
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_list_report', options);
  }

  status;
  statusChanger() {
    this.loadingFlag = true;
    let val = (document.getElementById('statusChangeApi') as HTMLSelectElement)
      .value;
    this.status = val;
    let url =
      this.config.json.apiEndpointRdbms +
      `subscriber/getsubscribersListReport/?status=${val}&searchKeyword=${
        this.searchWord ? this.searchWord : ''
      }`;
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = [];
        this.reportList = data.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  searchWord;
  searchKeyword() {
    this.loadingFlag = true;
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    this.searchWord = value;
    let url =
      this.config.json.apiEndpointRdbms +
      `subscriber/getsubscribersListReport/?status=${
        this.status ? this.status : 5
      }&searchKeyword=` +
      value;
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }
}
