import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class InvoiceDataService {

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  getInvoiceListBySubscriber(id: number): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'billing/getInvoices/' + id;
    return this.http.get(url);
  }

  getInvoiceById(id: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'billing/getInvoice?invoiceNo=' + id;
    return this.http.get(url);
  }

}
