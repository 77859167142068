import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  constructor(
    private http: HttpClientWrapperService,
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private formBuilder: FormBuilder
  ) { }

  admin = {} as LCOInfo;

  admin1 = {};

  passwordForm: FormGroup;

  currentPasswordFormControl = new FormControl();

  newPasswordFormControl = new FormControl();

  confirmNewPasswordFormControl = new FormControl();

  showPasswordChangeForm = false;

  passwordFlag = false;

  passwordChange = false;

  passwordObj = { currentPassword: '', newPassword: '' };

  passwordRegex = '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$'

  showCurrentPassword = false
  showNewPassword = false
  showConfirmPassword = false
  isLCO = this.auth.isLCO()
  ngOnInit(): void {
    this.fetchRecord(atob(window.localStorage.getItem('id')));
    this.admin1 = JSON.parse(window.localStorage.getItem('profileInfo'))
    // //// console.log(this.admin1['name'])

  }


  fetchRecord(id: string): void {
    // //// console.log(id)
    const url = this.config.json.apiEndpointRdbms + 'lco/getLCO/' + id;

    this.http.get(url).subscribe(data => {
      // //// console.log(data)
      this.admin = data;
    });
  }

  showPasswordDiv(): void {
    // this.showPasswordChangeForm = true;
    // this.passwordChange = !this.passwordChange;
    this.changePassword()
  }

  changePassword(): void {
    this.initForm();
    this.passwordChange = !this.passwordChange;
    this.passwordFlag = true;
    this.showPasswordChangeForm = true
  }



  onSave() {
    // this.initForm();
    this.passwordChange = !this.passwordChange;
    this.showPasswordChangeForm = true
    const valid = this.validateData();
    if (valid) {
      this.prepareData();
      if (this.showPasswordChangeForm) {
        this.updatePassword();
      }
    }
  }

  initForm(): void {
    // this.passwordForm = new FormGroup(
    //   {
    //     password: new FormControl('', [Validators.required, Validators.minLength(5)]),
    //     confirmPassword: new FormControl('', [Validators.required])
    //   },
    //   { validators: [this.comparePassword] });
    this.passwordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required, Validators.pattern(this.passwordRegex)],
      confirmPassword: ['', Validators.required]
    }, { validator: this.passwordMatchValidator });
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const newPass = formGroup.get('newPassword').value;
    const currentPass = formGroup.get('confirmPassword').value;
    // console.log(newPass, currentPass)
    if (newPass === currentPass) {
      formGroup.get('newPassword').setErrors(null);
    } else {
      formGroup.get('newPassword').setErrors({ mismatch: true });
    }
    // console.log(formGroup.invalid)
  }

  onSubmit() {
    // Handle form submission
    if (this.passwordForm.valid) {
      // Perform actions like sending data to the server
      // console.log('Form submitted:', this.passwordForm.value);
    } else {
      // console.log('Form is invalid');
    }
    this.passwordObj.currentPassword = this.passwordForm.get('currentPassword').value
    this.passwordObj.newPassword = this.passwordForm.get('newPassword').value
    this.updatePassword()
  }

  get password(): FormControl {
    this.passwordStringMatch()
    return this.passwordForm.get('password') as FormControl;
  }

  passwordStringMatch(): boolean {
    var myRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
    if(myRegex.test(this.passwordForm.get('newPassword').value)){
      this.passwordForm.get('newPassword').setErrors(null)
      return true
    }else{
      this.passwordForm.get('newPassword').setErrors({pattern : false})
      return false
    }
  }

  get cpassword(): FormControl {
    return this.passwordForm.get('confirmPassword') as FormControl;
  }

  cancelPasswordChange(): void {
    this.passwordFlag = false;
    this.passwordForm = null;
  }


  comparePassword(form: AbstractControl): { invalid: boolean } {
    if (form.get('password').value !== form.get('confirmPassword').value) {
      return { invalid: true };
    }

  }

  updatePassword(): void {
    this.passwordChange = true
    const url = this.config.json.apiEndpointRdbms + 'changePassword';

    this.http.post(url, this.passwordObj).subscribe(
      (res) => {
        if(res.statusCode !== 200){
          alert(res.message)
        }else{
          alert(res.data)
        }
        this.passwordFlag = false;
      },
      (err: HttpErrorResponse) => {
        window.alert(err.error.message ? err.error.message : 'Failed');
      }
    );
  }

  validateData() {
    if (this.currentPasswordFormControl.errors) {
      window.alert('Current password is not valid');
      return false;
    }
    if (this.newPasswordFormControl.errors) {
      window.alert('New password is not valid');
      return false;
    }
    return true;
  }

  prepareData() {
    if (this.showPasswordChangeForm) {
      this.passwordObj.currentPassword = this.currentPasswordFormControl.value;
      this.passwordObj.newPassword = this.newPasswordFormControl.value;
    }

  }

  updateProfile(): void {
    this.router.navigate(['/manage/lco', this.admin.id]);
  }

}
