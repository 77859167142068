import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LcoSelfRegistrationComponent } from './lco-self-registration/lco-self-registration.component';
import { ListSelfregisteredComponent } from './list-selfregistered/list-selfregistered.component';

const routes: Routes = [

  { path: '', redirectTo: 'list-selfregistered'  },
  { path:'list-selfregistered',component:ListSelfregisteredComponent}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelfRegisteredRoutingModule { }
