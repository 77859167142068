import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PathNames } from 'src/app/constants/path-names';
import { LocationInfo } from 'src/app/models/location/location-info';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Country, State, City } from 'country-state-city';
// import { ICountry, IState, ICity } from 'country-state-city/dist/lib/interface';
import { ICountry, IState, ICity } from 'country-state-city';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-create-location',
  templateUrl: './create-location.component.html',
  styleUrls: ['./create-location.component.scss'],
})
export class CreateLocationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) {}

  location = {} as LocationInfo;

  locationForm: FormGroup;

  isEdit = false;

  countryList: ICountry[] = Country.getAllCountries();
  stateList: IState[];
  cityList: ICity[];

  selectedCountry: ICountry;
  selectedState: IState;

  pincodeRegex = '^[1-9][0-9]{5}$';

  ngOnInit(): void {
    this.getPathParam();
    this.initForm();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.fetchRecord(id);
    }
  }

  fetchRecord(id: string): void {
    const url =
      this.config.json.apiEndpointRdbms + 'location/getLocation/' + id;

    this.http.get(url).subscribe((data) => {
      this.location = data;
      this.updateForm();
    });
  }

  initForm(): void {
    this.locationForm = new FormGroup({
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      locality: new FormControl('', [Validators.required]),
      pin: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.pincodeRegex),
      ]),
    });

    this.initializeFormChangeEvents();
  }

  get country(): FormControl {
    return this.locationForm.get('country') as FormControl;
  }
  get state(): FormControl {
    return this.locationForm.get('state') as FormControl;
  }
  get city(): FormControl {
    return this.locationForm.get('city') as FormControl;
  }
  get pin(): FormControl {
    return this.locationForm.get('pin') as FormControl;
  }

  updateForm(): void {
    this.locationForm.get('country').setValue(this.location.country);
    this.locationForm.get('state').setValue(this.location.state);
    this.locationForm.get('city').setValue(this.location.city);
    this.locationForm.get('locality').setValue(this.location.locality);
    this.locationForm.get('pin').setValue(this.location.pincode);
  }

  updateModel(): void {
    this.location.country = this.locationForm.get('country').value;
    this.location.state = this.locationForm.get('state').value;
    this.location.city = this.locationForm.get('city').value;
    this.location.locality = this.locationForm.get('locality').value;
    this.location.pincode = this.locationForm.get('pin').value;
  }

  persist(): void {
    this.updateModel();
    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'location/createLocation';

    this.http.post(url, this.location).subscribe((data) => {
      // //// console.log("On Save: ",data)
      if (data.statusCode == 200) {
        this.router.navigate(['/manage/location', 'list']);
      } else {
        alert(data.message);
      }
    });
  }

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'location/updateLocation';

    this.http.put(url, this.location).subscribe((data) => {
      if(data.statusCode==200){
        this.router.navigate(['/manage/location', 'list']);
        }
        else
        {
        window.alert(data.message)
        }
      
    });
  }

  initializeFormChangeEvents(): void {
    this.country.valueChanges.subscribe((ct) => {
      this.listenCountryChange(ct);
    });
    this.state.valueChanges.subscribe((st) => {
      this.listenStateChange(st);
    });

    // Setting value here, to trigger above observable after it's initialization
    this.country.setValue('India');
  }

  listenCountryChange(ctname: string): void {
    if (ctname) {
      const cntry = this.countryList.filter((ct) => ct.name === ctname);
      if (cntry.length > 0) {
        this.selectedCountry = cntry[0];
        this.stateList = State.getStatesOfCountry(this.selectedCountry.isoCode);
      }
    }
  }

  listenStateChange(stname: string): void {
    if (stname) {
      const statList = this.stateList.filter((st) => st.name === stname);
      if (statList.length > 0) {
        this.selectedState = statList[0];
        const list = City.getCitiesOfState(
          this.selectedCountry.isoCode,
          this.selectedState.isoCode
        );
        this.cityList = (list as any) !== '' ? list : [];
      }
    }
  }
}
