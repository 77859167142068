import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { ReportTraiDataService } from 'src/app/report-trai/plugins/report-trai-data.service';

@Component({
  selector: 'app-report-subscriber',
  templateUrl: './report-subscriber.component.html',
  styleUrls: ['./report-subscriber.component.scss','../report-filters.scss']
})
export class ReportSubscriberComponent implements OnInit {

  reportList = [];
  from: any = '';
  to: any = '';
  currentDate = new Date();
  dateString;
  eve;
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}
  ngOnInit(): void {
    // this.fetchData()
    this.fetchData()
  }

  
  
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.from = ev.startDate.format('YYYY-MM-DD');
      this.to = ev.endDate.format('YYYY-MM-DD');
      console.log(this.from)
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  fetchData(): void {
    let url=this.config.json.apiEndpointRdbms+'customreport/subscriberReport'
    let body ={
      "fromDate":this.from,
      "toDate":this.to
    }
    this.http.post(url,body).subscribe(
      (res) => {
        this.reportList = res.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  loadingFlag = false;
  isLoad = 'Choose Date';

  statUS = ['Inactive', 'Active', 'Blacklisted', 'New', 'Terminated'];

  downloadTableExcel(): void {
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    let status = [...this.statUS, 'ALL'],filterName
    let name = 'Subscriber Report';
    if(this.from!='' && this.to !='undefined'){
      filterName = this.from +"-"+this.to;
      this.doc.exportTableToExcelwithImage(this.reportTable,name,'subscriber_report',
        undefined,undefined,'Date',filterName);
    }
    else{
      this.doc.exportTableToExcelwithImage(this.reportTable,name,'subscriber_report');
    }
   
  }

  downloadTablePdf(): void {
    // let status = [...this.statUS, 'ALL'],
    //   filterName = status[this.status];
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    if(this.from!='' && this.to !=''){
    this.pdf.generatePdfHtml(
      '#reportTable',
      'subscriber_report',
      `Source type : SMS, from : ${this.from}, to : ${this.to}`,
      null,
      null,
      'a3'
    );
  }
  else{
    this.pdf.generatePdfHtml(
      '#reportTable',
      'subscriber_report',
      `Source type : SMS`,
      null,
      null,
      'a3'
    );
  }
  }
  downloadTableCSV(): void {
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Sl No',
        'Subscriber ID',
        'Subscriber Code',
        'Subscriber Name',
        'Subscriber Address',
        'Partner Code',
        'Partner Name',
        'Packages',
        'Mac Address', 'Stb ID' ,'Status','Date'
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((report) => {
      let csvLine = {
        slNo: i,
        subsciberId: report.subsciberId?report.subsciberId:'---',
        subscriberCode: report.subscriberCode?report.subscriberCode:'---',
        subscriberName: report.subscriberName ? report.subscriberName : 'No Data',
        subscriberAddress: report.subscriberAddress ? report.subscriberAddress : 'No Data',
        lcoCode: report.lcoCode?report.lcoCode:'---',
        lcoName: report.lcoName?report.lcoName:'---',
        packages:report.packages ? report.packages : "No Subscription",
        ethernetMacAddress:report.ethernetMacAddress?report.ethernetMacAddress:'---',
        stbId: report.stbId?report.stbId:'---',
        status: this.statUS[report.subscriberStatus],
        createdDate:report.createdDate?report.createdDate:'---'
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_report', options);
  }

  status;

  searchWord;
  // searchKeyword() {
  //   this.loadingFlag = true;
  //   let value = (document.getElementById('searchKeyword') as HTMLInputElement)
  //     .value;
  //   this.searchWord = value;
  //   let url =
  //     this.config.json.apiEndpointRdbms +
  //     `subscriber/getsubscribersListReport/?status=${
  //       this.status ? this.status : 5
  //     }&searchKeyword=` +
  //     value;
  //   this.http.get(url).subscribe(
  //     (data) => {
  //       this.reportList = data.data;
  //       if (this.reportList.length != 0) {
  //         this.loadingFlag = false;
  //       } else {
  //         this.loadingFlag = false;
  //         this.isLoad = 'No Records';
  //       }
  //     },
  //     () => {
  //       this.loadingFlag = false;
  //       this.isLoad = 'No Records';
  //     }
  //   );
  // }
}
