<div class="cms__content">
    <div class="top__heading">
      <h3>Logs Home</h3>
    </div>
  
    <div class="col-12 list__summary">
      <div class="row">
        <div class="col-12">
  
          <div class="row">
  
            <!-- <div class="col-6 col-md-3">
              <a [routerLink]="['logs-user-auth']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                    Authentication
                  </div>
                </div>
              </a>
            </div> -->
  
            <!-- <div class="col-6 col-md-3">
              <a [routerLink]="['logs-user-activity']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                    User Activity
                  </div>
                </div>
              </a>
            </div> -->
  
            <!-- <div class="col-6 col-md-3" *msoAccess>
              <a [routerLink]="['logs-bouquet-status']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                    Bouquet Status
                  </div>
                </div>
              </a>
            </div> -->
  
            <!-- <div class="col-6 col-md-3" *msoAccess>
              <a [routerLink]="['logs-channel-status']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                    Channel Status
                  </div>
                </div>
              </a>
            </div> -->
  
            <div class="col-6 col-md-3">
              <a  [routerLink]="['/logs/logs-transaction']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                    Transaction Logs
                  </div>
                </div>
              </a>
            </div>
  
            <div class="col-6 col-md-3">
              <a [routerLink]="['/logs/logs-complete-categories']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                    Complete Logs
                  </div>
                </div>
              </a>
            </div>
  
            <div class="col-6 col-md-3">
              <a  [routerLink]="['/logs/logs-stb-activation-deacivation']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                    STB Logs
                  </div>
                </div>
              </a>
            </div>
  
            <div class="col-6 col-md-3" *msoAccess>
              <a [routerLink]="['/logs/logs-bouquet-modification']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                   Bouquet Modification
                  </div>
                </div>
              </a>
            </div>
  
            <div class="col-6 col-md-3" *msoAccess>
              <a [routerLink]="['/logs/logs-product-modification']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                   Product Modification
                  </div>
                </div>
              </a>
            </div>
            
            <div class="col-6 col-md-3" *msoAccess>
              <a [routerLink]="['/logs/logs-channel-modification-new']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                  Channel Modification
                  </div>
                </div>
              </a>
            </div>
  
            <div class="col-6 col-md-3" *msoAccess>
              <a [routerLink]="['/logs/logs-product-subscription-desubscription']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                  Product  Subscription and desubscription
                  </div>
                </div>
              </a>
            </div>
  
            <div class="col-6 col-md-3" *msoAccess>
              <a [routerLink]="['/logs/logs-batch']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                  Batch Logs
                  </div>
                </div>
              </a>
            </div>
  
            <div class="col-6 col-md-3" *msoAccess>
              <a [routerLink]="['/logs/logs-subscription']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                  Subscription Logs
                  </div>
                </div>
              </a>
            </div>
            <div class="col-6 col-md-3">
              <a [routerLink]="['/logs/user-login-history']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                  User Login History
                  </div>
                </div>
              </a>
            </div>
            <div class="col-6 col-md-3">
              <a [routerLink]="['/logs/mso-lco-login-history']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                  MSO LCO Login History
                  </div>
                </div>
              </a>
            </div>
            <div  class="col-6 col-md-3" *msoAccess> 
              <a [routerLink]="['/logs/subscriber-sms-logs']">
                <div class="list__card alt__card trai__report">
                  <label class="safe__label">Logs</label>
                  <div class="card__txt">
                  Subscriber SMS History
                  </div>
                </div>
              </a>
            </div>
  
          </div>
        </div>
  
      </div>
    </div>
  
  </div>
  