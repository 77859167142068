import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WalletRoutingModule } from './wallet-routing.module';
import { AddLcoFundsComponent } from './add-lco-funds/add-lco-funds.component';
import { AddSubscriberFundsComponent } from './add-subscriber-funds/add-subscriber-funds.component';
import { ReceiveLcoFundsComponent } from './receive-lco-funds/receive-lco-funds.component';
import { TransactionLogsComponent } from './transaction-logs/transaction-logs.component';
import { WalletHomeComponent } from './wallet-home/wallet-home.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LogsDataService } from '../logs/plugins/logs-data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SubscriptionModule } from '../subscription/subscription.module';
import { LcoModule } from '../lco/lco.module';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentErrorComponent } from './payment-error/payment-error.component';
import { WalletRechargeComponent } from './wallet-recharge/wallet-recharge.component';
import { AddDistributorFundsComponent } from './add-distributor-funds/add-distributor-funds.component';
import { DistributorModule } from '../distributor/distributor.module';


@NgModule({
  declarations: [
    AddLcoFundsComponent,
    AddSubscriberFundsComponent,
    ReceiveLcoFundsComponent,
    TransactionLogsComponent,
    WalletHomeComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    WalletRechargeComponent,
    AddDistributorFundsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    WalletRoutingModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd,
    SubscriptionModule,
    LcoModule,
    DistributorModule
  ],
  providers: [
    LogsDataService
  ]
})
export class WalletModule { }
