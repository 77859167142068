import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorSelectorComponent } from '../color-selector/color-selector.component';
@Component({
  selector: 'app-theme-home',
  templateUrl: './theme-home.component.html',
  styleUrls: ['./theme-home.component.scss']
})
export class ThemeHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @ViewChild(ColorSelectorComponent) colorSelectorComponent: ColorSelectorComponent
  view = "selectColor"

  resetFactoryDefaults(){
    switch(this.view){
      case "selectColor":this.colorSelectorComponent.resetColors();break;
    }
  }
}
