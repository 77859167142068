import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

export interface PaginationInfo {
  pageSizeOptions: number[];
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  totalPages: number;
  totalElements: number;
  searchKeyword: string;
  category: string;
  fromDate: string;
  toDate: string;
}

export class PaginationObj implements PaginationInfo {

  constructor() {
    this.pageSizeOptions = environment.defaultPageSizeOptions;
    this.pageNumber = environment.defaultPageNumber;
    this.pageSize = environment.defaultPageSize;
    this.sortBy = environment.defaultSortBy;
    this.sortOrder = environment.defaultSortOrder;
    this.totalPages = this.pageNumber;
    this.totalElements = 0;
    this.searchKeyword = '';
    this.toDate = '';
    this.fromDate = '';
    this.category = '0';
  }

  pageSizeOptions: number[];
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  totalPages: number;
  totalElements: number;
  searchKeyword: string;
  category: string;
  fromDate: string;
  toDate: string;
}

export class PaginationUtilities {

  constructor(
    private config: ConfigService
  ) { }

  public static urlBuilder(urlPrefix: string, paginationObj: PaginationObj): string {
    const base = (this.url ? this.url : environment.apiEndpoint) + urlPrefix;
    const pnumber = '/' + paginationObj.pageNumber;
    const psize = '?pageSize=' + paginationObj.pageSize;
    const sBy = '&sortBy=' + paginationObj.sortBy;
    const sOrder = '&sortOrder=' + paginationObj.sortOrder;
    const sKeyword = '&searchKeyword=' + paginationObj.searchKeyword;
    return base + pnumber + psize + sBy + sOrder + sKeyword;
  }
  // public static urlBuilder(urlPrefix: string, paginationObj: PaginationObj): string {
  //   const base = environment.apiEndpoint + urlPrefix;
  //   const pnumber = '/' + paginationObj.pageNumber;
  //   const psize = '?pageSize=' + paginationObj.pageSize;
  //   const sBy = '&sortBy=' + paginationObj.sortBy;
  //   const sOrder = '&sortOrder=' + paginationObj.sortOrder;
  //   const sKeyword = '&searchKeyword=' + paginationObj.searchKeyword;
  //   return base + pnumber + psize + sBy + sOrder + sKeyword;
  // }

  static url;
  public static giveConfig(url: string) {
    this.url = url
  }

  public static urlBuilderRdbms(urlPrefix: string, paginationObj: PaginationObj): string {
    const base = (this.url ? this.url : environment.apiEndpoint) + urlPrefix;
    const pnumber = '/' + paginationObj.pageNumber;
    const psize = '?pageSize=' + paginationObj.pageSize;
    const sBy = '&sortBy=' + paginationObj.sortBy;
    const sOrder = '&sortOrder=' + paginationObj.sortOrder;
    const sKeyword = '&searchKeyword=' + paginationObj.searchKeyword;
    return base + pnumber + psize + sBy + sOrder + sKeyword;
  }
}
