import { Component, OnInit } from '@angular/core';
import colorsArray from './colors'
@Component({
  selector: 'color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss']
})
export class ColorSelectorComponent implements OnInit {

  colors = colorsArray;
  constructor() {

   }

  ngOnInit(): void {
  }

  chosenPalette(color : any){
    console.log(color);
    localStorage.setItem('chosenPalette', color.key)
    document.documentElement.style.setProperty("--darkBack",color.value.primary)
    document.documentElement.style.setProperty("--highlight-color",color.value.primary)
    document.documentElement.style.setProperty("--lightBack",color.value.primary)
    document.documentElement.style.setProperty("--nav-white",color.value.primary)
    document.documentElement.style.setProperty("--nav-font","#fff")
    document.documentElement.style.setProperty("--nav-button","#fff")
    document.documentElement.style.setProperty("--nav-button","#fff")

    alert(color.key + "  theme SET !")
  }

  public resetColors(){
    document.documentElement.style.setProperty("--darkBack","#676874")
    document.documentElement.style.setProperty("--highlight-color","#2980b9")
    document.documentElement.style.setProperty("--lightBack","#b1b3c1")
    document.documentElement.style.setProperty("--nav-white","#fff")
    document.documentElement.style.setProperty("--nav-font","#665f82")
    document.documentElement.style.setProperty("--nav-button","#2980b9")    
    localStorage.setItem('chosenPalette', "reset")

  }

  

}
