<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>SMS User Activity Logs</h3>
    <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
  </div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <label for="">Select Date :</label>
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>


    <div class="col-12 col-md-6 report_selection_block">
      <label class="report_selection_label">Select Partner :</label>
      <select class="custom__field report_selection" [(ngModel)]="selectedLco" (change)='lcoSelect()'>
        <!-- Changed ngValue binding from lco.id to lco.email -->
        <option value='0'>ALL</option>
        <option *ngFor="let lco of lcoList" [ngValue]='lco'>{{lco.name}}</option>
      </select>
    </div>
  </div>

  <div class="col-12 col-md-6">
    <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>User email</th>
            <th>Activity Type</th>
            <th>Activity Description</th>
            <th>Module name</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length ==0 && !loadingFlag" >
            <td style="text-align: center !important;" colspan="12" >{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="12">
            <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList">
              <td>{{report.id? report.id : '---'}}</td>
              <td>{{report.userEmail? report.userEmail : '---'}}</td>
              <td>{{report.activityType? report.activityType : '---'}}</td>
              <td>{{report.activityDescription? report.activityDescription : '---'}}</td>
              <td>{{report.moduleName? report.moduleName : '---'}}</td>
              <td>{{report.createdDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>
