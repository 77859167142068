import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { MSOInfo } from 'src/app/models/mso/mso-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { UserInfo } from 'src/app/models/user/user-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-selfregistered',
  templateUrl: './list-selfregistered.component.html',
  styleUrls: ['./list-selfregistered.component.scss']
})
export class ListSelfregisteredComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  userTypeList: UserInfo[] = [];

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  activatedCount = 0;
  MsoActiveCount: number;
  TotalMsoCount: number;
  sortByProperties = {
    name: 'contactPersonName',
    email: 'email',
    mobile: 'mobile',
    date: 'createdDate',
    status: 'status',
    id:'id',
    userType:'userType',
    companyName:'companyName'
  };

  lcoDetails
  LCODetails;
  viewModal = false;

  view(userDetails:any){
    this.viewModal = true;
    this.LCODetails = userDetails
  }

  viewDistributorDetails(distributorDetails: any) {
    // let distributorId = distributorDetails.id,
    // //   url = `${this.config.json.apiEndpoint}distributor/distributorDetails/`+distributorId;
    // // this.http.get(url).subscribe({
    // //     next: (res: any) => {
    // //       this.LCODetails = res.data;
    // //       this.viewModal = true;
    // //     },
    // //   });
  }

  openImageInNewTab(str: string) {
    window.open(str);
  }



  viewLCODetails(lcoDetails: any) {
    let lcoId = lcoDetails.id,
      url = `${this.config.json.apiEndpoint}lco/lcoDetails/`+lcoId;
    this.http.get(url).subscribe({
        next: (res: any) => {
          this.LCODetails = res.data;
          this.viewModal = true;
        },
      });
  }


  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
  }

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  pageSize
  isError = {
    errType : "Loading",
    bool : true
  }
  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    const url = PaginationUtilities.urlBuilder('leadGeneration/list', this.paginationObj);
    // const url = this.config.json.apiEndpointRdbms + 'mso/getMSOs/' + this.paginationObj.pageNumber + '?pageSize=' + this.paginationObj.pageSize + '&sortBy=' + this.paginationObj.sortBy + '&sortOrder=' + this.paginationObj.sortOrder + '&searchKeyword=' + this.paginationObj.searchKeyword
    this.loadingFlag = true;
    this.http.get(url).subscribe({
      next : (data: PageInfo<UserInfo>) =>{
        this.loadingFlag = false;
        this.pageSize = this.paginationObj.pageSize
        this.handleFetchPage(data);
        
      },
      error : () =>{

      }
    })
  }
  
  handleFetchPage(data: PageInfo<UserInfo>): void {
    this.userTypeList = data.data;
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countAdmin();
  }

  pageNumber
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: AdminInfo): void {
    // //// console.log(record)
    this.router.navigate(['/manage/mso', record.id]);
  }

  countAdmin(): void {
    this.activatedCount = this.userTypeList.filter(x => x.status === 1).length;
  }

  updateStatus(record: AdminInfo): void {
    record.status === 1 ? this.deactivate(record) : this.activate(record);
  }

  deactivate(record: AdminInfo): void {
    const url = this.config.json.apiEndpointRdbms + 'admin/deactivateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countAdmin();
      window.alert('Account deactivated');
    });
  }

  activate(record: AdminInfo): void {
    const url = this.config.json.apiEndpointRdbms + 'admin/activateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countAdmin();
      window.alert('Account activated');
    });
  }

}
