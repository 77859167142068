import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-wallet-recharge',
  templateUrl: './wallet-recharge.component.html',
  styleUrls: ['./wallet-recharge.component.scss'],
})
export class WalletRechargeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router
  ) {}

  user = 'lco';
  searchText!: string;
  isSearch: boolean = false;
  ngOnInit(): void {
    console.log(this.router.url);
    let url = this.router.url;
    if (url.includes('distributor-wallet-recharge')) {
      this.user = 'distributor';
      this.initFormForDistributor();
    }
    if (url.includes('lco-wallet-recharge')) {
      this.user = 'lco';
      this.initFormForLCO();
    }
  }

  initFormForLCO() {
    this.walletRechargeForm = this.fb.group({
      amount: ['', Validators.required],
      packageName: ['Merchats Payment'],
      subsId: [this.auth.getLcoId()],
      mobileNumber: [this.auth.getUserData().mobile],
      firstName: [this.auth.getUserData().name],
      email: [this.auth.getUserData().email],
      accType: [(this.auth.getRole() as string).toLocaleLowerCase()],
    });
  }

  initFormForDistributor() {
    console.log(this.auth.getDistributorData());

    this.walletRechargeForm = this.fb.group({
      amount: ['', Validators.required],
      packageName: ['Wallet-Recharge'],
      firstName: [this.auth.getDistributorData().name],
      mobileNumber: [this.auth.getDistributorData().mobileNumber],
      email: [this.auth.getDistributorData().email],
      subsId: [this.auth.getLcoId()],
      accType: [(this.auth.getRole() as string).toLocaleLowerCase()],
    });
  }

  walletRechargeForm!: FormGroup;

  save() {
    const url = `${this.config.json.apiEndpoint}wallet/payWithEaseBuzz`;
    console.log((this.auth.getRole() as string).toLocaleLowerCase());

    console.log(this.walletRechargeForm.value);

    this.http.post(url, this.walletRechargeForm.value).subscribe({
      next: (res) => {
        if (res.status == 'FAIL') {
          alert(res.message);
          return;
        }
        let data = res.data;
        localStorage.setItem(
          'amount',
          this.walletRechargeForm.get('amount').value
        );
        localStorage.setItem(
          'transactionId',
          data.txnid
        );
        window.open(data.payUrl, '_blank');
      },
    });
  }
}
