import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from './core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ConfigService } from './services/config.service';
import { SelfregistrationComponent } from './selfregistration/selfregistration.component';
import { LeadgenerationPaymentSuceessComponent } from './leadgeneration-payment-suceess/leadgeneration-payment-suceess.component';
import { LeadgenerationPaymentFailureComponent } from './leadgeneration-payment-failure/leadgeneration-payment-failure.component';
export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    SelfregistrationComponent,
    LeadgenerationPaymentSuceessComponent,
    LeadgenerationPaymentFailureComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CoreModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
