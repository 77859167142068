<div class="cms__content">
  <form action="" [formGroup]="settingsForm" (ngSubmit)="persist()">
    <div class="top__heading">
      <h3>Settings</h3>
      <!-- <button type="button" class="cta float-right" [routerLink]="['/miscellaneous']">Image</button> -->
      <button class="cta float-right" [disabled]="!settingsForm.valid">Save</button>
    </div>
    <div class="clearfix"></div>

    <div class="col-12 setting__content">
      <div class="row">

        <div class="col-12">
          <div class="row">
            <div class="col-12 section__title">
              <h5>General Settings</h5>
            </div>
            <div class="col-12 form__style">
              <div class="row">

                <div class="col-12 col-md-12">
                  <label>STB Welcome Message</label>
                  <input type="text" autocomplete="off" class="custom__field" formControlName="welcomeMessage" />
                </div>

                <div class="col-12 col-md-6">
                  <label>Channel View Log Timer (in ms)</label>
                  <input type="number" class="custom__field" min="0" formControlName="menuDisplayTimer" />
                </div>

                <div class="col-12 col-md-6">
                  <label>Mail auto-expiry timer (in days)</label>
                  <input type="number" class="custom__field" min="0" formControlName="mailAutoExpiry" />
                </div>


                <div class="col-12 col-md-12">
                  <label>Logo</label>
                  <input (change)="onLogoPicked($event)" type="file" class="custom__field" />
                  <img *ngIf="settings.brandLogo" src={{settings.brandLogo}} alt="Brand Logo" class="image--logo">
                </div>


                <!-- <div class="col-12 col-md-6">
                  <label>Logo</label>
                  <input (change)="onLogoPicked($event)" type="file" class="custom__field" />
                  <img *ngIf="settings.brandLogo" src={{settings.brandLogo}} alt="Brand Logo" class="image—logo">
                </div> -->

                <div class="col-12 col-md-6">
                  <label>Watermark location</label>
                  <select class="custom__field">
                    <option value="1">Top Left</option>
                    <option value="2">Top Right</option>
                    <option value="3">Bottom Left</option>
                    <option value="4">Bottom Rights</option>
                  </select>
                </div>

                <div class="col-12 col-md-6">
                  <label>Initial Channel to be played</label>
                  <!-- <select class="custom__field"></select> -->
                  <input type="number" class="custom__field" min="0" formControlName="initialChannelId" />

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">

        <div class="col-12">
          <div class="row">
            <div class="col-12 section__title">
              <h5>NCF and GST</h5>
            </div>
            <div class="col-12 form__style">
              <div class="row">

                <div class="col-12 col-md-6">
                  <label>NCF Primary 200</label>
                  <input type="number" class="custom__field" min="0" formControlName="ncfPrimaryLte" />
                </div>
                <div class="col-12 col-md-6">
                  <label>NCF Primary >200</label>
                  <input type="number" class="custom__field" min="0" formControlName="ncfPrimaryGt" />
                </div>
                <div class="col-12 col-md-6">
                  <label>NCF Secondary 200</label>
                  <input type="number" class="custom__field" min="0" formControlName="ncfSecondaryLte" />
                </div>
                <div class="col-12 col-md-6">
                  <label>NCF Secondary >200</label>
                  <input type="number" class="custom__field" min="0" formControlName="ncfSecondaryGt" />
                </div>
                <div class="col-12 col-md-6">
                  <label>GST %</label>
                  <input type="number" class="custom__field" min="0" formControlName="gstPercent" />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </form>
</div>