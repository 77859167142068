import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-remove-packages-to-subscribers',
  templateUrl: './add-remove-packages-to-subscribers.component.html',
  styleUrls: ['./add-remove-packages-to-subscribers.component.scss']
})
export class AddRemovePackagesToSubscribersComponent implements OnInit {

  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLco()
  }

  lcoList
  getLco() {
    // let url = this.config.json.apiEndpoint + 'lco/getLCOs'
    let url = this.config.json.apiEndpoint + 'lco/dropdown'
    this.http.get(url).subscribe({
      next: (res) => {
        this.lcoList = res.data
      }
    })
  }

  sampleData = [
    {
      'Bouquet name': 'onnetPack3'
    },
    {
      'Bouquet name': 'onnetPack2'
    },
  ];

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement
    this.excelgen.deselectSelectedFiles(input)
    this.isSubmitReady = false
  }


  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'add-remove_packages_to_subscribers_of_lco');
  }

  selectedLco
  optionSelected = false
  selectOption() {
  }

  fileSelection(e) {
    this.e = e
  }

  isSubmitReady = false;
  message: string = ''
  errorBody = []
  errorAvailable = false
  isValid
  e
  selectedFile(e) {
    this.message = ''
    if (this.active) {
      var url = this.config.json.apiEndpointRdbms + `bulkUpload/validation/addSubscription/${this.selectedLco.lcoCode}`;
    }
    if (this.deactive) {
      var url = this.config.json.apiEndpointRdbms + `bulkUpload/validation/removeSubscription/${this.selectedLco.lcoCode}`;
    }
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        // // console.log(res)
        if (res.result) {
          this.isValid = this.isSubmitReady = true
          this.errorAvailable = false
          this.e = e
          this.successMessage = 'No Errors Found'
        } else {
          this.errorAvailable = true
          this.isValid = true
          this.successMessage = 'Errors Found'
          this.errorBody = res.errorBody
        }
        if (res.hasOwnProperty('errorMessage')) {
          this.errorAvailable = false
        }
      },
      error: (error) => {
        this.isSubmitReady = false
        this.message = "Error"
      }
    });
  }

  active = true
  deactive = true
  activateDeactivate(num) {
    switch (num) {
      case 0: this.active = false; break;
      case 1: this.deactive = false; break;
    }
    this.optionSelected = true
    // this.selectedFile(this.e)
  }

  successMessage = 'No errors Found'
  uploadFile() {
    if (this.active) {
      var url = this.config.json.apiEndpointRdbms + `bulkUpload/addSubscription/${this.selectedLco.lcoCode}`;
    }
    if (this.deactive) {
      var url = this.config.json.apiEndpointRdbms + `bulkUpload/removeSubscription/${this.selectedLco.lcoCode}`;
    }
    // console.log(this.e)
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message
        } else {
          // console.log(res)
          this.errorAvailable = true
          this.successMessage = "Errors Found"
          this.errorBody = [res.message]
        }
      }
    })
  }

}
