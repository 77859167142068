<div class="cms__content">
  <app-reports-header></app-reports-header>
    <div class="top__heading">

      <h3>Bouquet Modification Report</h3>
      <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>

    <div class="row">
      <div class="filter__group col-12 col-md-6" id="date_selector">
        <label for="">Select Date </label>
        <div class="date_box">
          <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div>
      </div>
      <div class="download__report col-md-6">
        <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
          PDF
          <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
          Excel
          <img src="./assets/excel.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
          CSV
          <img src="./assets/csv.svg" alt="download" />
        </button>
      </div>
    </div>



    <div class="third_box">
      <div class="col-12 col-md-6 report">
        <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
      </div>
      <div style="display: flex; gap:20px" >
        <div class="table__right">
          <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
        </div>
        <div class="search__container">
          <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
          <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
        </div>
      </div>
    </div>

   <div class="col-12 list__table">
       <div class="row scroll">
           <table class="custom__iptv__table" #reportTable id="reportTable">
               <thead>
                   <tr>
                       <th>Sl No.</th>
                       <th>Channel ID</th>
                       <th>Resource Code ID</th>
                       <th>Resource Name</th>
                       <th>Status</th>
                       <th>Package Code</th>
                       <th>Bouquet ID</th>
                       <th>Bouquet Name</th>
                       <th>Bouquet Category</th>
                       <th>Transaction Date</th>
                   </tr>
               </thead>
               <tbody>
                   <tr *ngIf="reportList.length<=0 && !loadingFlag">
                       <td colspan="10" style="text-align: center;" >{{isLoad}}</td>
                   </tr>
                   <tr *ngIf="loadingFlag" >
                    <td colspan="10" style="text-align: center;" >
                      <app-custom-spinner></app-custom-spinner>
                    </td>
                   </tr>
                       <tr *ngFor="let report of reportList, let j=index">
                           <td>{{j+1}}</td>
                           <td>{{report.service_asset_id ?  report.service_asset_id : '---' }}</td>
                           <td>{{report.resource_code ?  report.resource_code : '---'  }}</td>
                           <td>{{report.resource_name ?  report.resource_name : '---' }}</td>
                           <td>{{report.channel_status ?  report.channel_status : '---' }}</td>
                           <td>{{report.package_code ?  report.package_code : '---' }}</td>
                           <td>{{report.bouquet_id ?  report.bouquet_id : '---' }}</td>
                           <td>{{report.package_name ?  report.package_name : '---' }}</td>
                           <td>{{report.category_name? report.category_name : '---'}}</td>
                           <td>{{report.transaction_date == null ? '---' : report.transaction_date | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                       </tr>
               </tbody>
           </table>
       </div>
   </div>
<!-- </div> -->
