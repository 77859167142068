import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListBouquetComponent } from './list-bouquet/list-bouquet.component';
import { CreateBouquetComponent } from './create-bouquet/create-bouquet.component';
import { ManagementComponent } from './management/management.component';

const routes: Routes = [
  {
    path : 'list',
    component : ListBouquetComponent
  },
  {
    path : 'create',
    component : CreateBouquetComponent
  },
  {
    path : 'edit/:id',
    component : CreateBouquetComponent
  },
  {
    path : 'clone/:id',
    component : CreateBouquetComponent
  },
  {
    path : 'bouquet-management',
    component : ManagementComponent
  },
  {
    path:'',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BouquetRoutingModule { }
