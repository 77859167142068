import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assign-stb-to-lcos',
  templateUrl: './assign-stb-to-lcos.component.html',
  styleUrls: ['./assign-stb-to-lcos.component.scss']
})
export class AssignStbToLcosComponent implements OnInit {

  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLco()
  }

  lcoList
  getLco() {
    let url = this.config.json.apiEndpoint + 'lco/getLCOs'
    this.http.get(url).subscribe({
      next: (res) => {
        this.lcoList = res.data
      }
    })
  }
  sampleData = [
    {
      'Manufacturer Name': 'TestOnnetK',
      'Serial Number': '399',
      'Ethernet Mac Address': 'f6:b9:fb:23:22:30',
      'Wifi Mac Address': 'f6:b9:fb:23:22:30',
      'Storage Space(In MB)': '150',
      'Model': 'Tx0011',
      'OS Version': '20',
      'Software Version': '15',
      'Network': 'Mixed',
      'Warranty Date': '22/12/2023',
      'Subscriber Code': 'BAN0000008',
    },
    {
      'Manufacturer Name': 'TestOnnetL',
      'Serial Number': '400',
      'Ethernet Mac Address': 'f6:b9:fb:23:22:40',
      'Wifi Mac Address': 'f6:b9:fb:23:22:40',
      'Storage Space(In MB)': '160',
      'Model': 'Tx0012',
      'OS Version': '21',
      'Software Version': '16',
      'Network': 'Mixed',
      'Warranty Date': '30/12/2023',
      'Subscriber Code': 'BAN0000009',
    },

  ];

  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'assign_stb_to_lco');
  }

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement
    this.excelgen.deselectSelectedFiles(input)
    this.isSubmitReady = false
  }
  selectedLco
  optionSelected = false
  selectOption(e) {
    this.optionSelected = true
  }


  isSubmitReady = false;
  message: string = ''
  errorBody = []
  errorAvailable = false
  e
  selectedFile(e) {
    this.message = ''
    this.e = e
    const url = this.config.json.apiEndpointRdbms + `bulkUpload/validation/stbs/${this.selectedLco.lcoCode}`;
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.isSubmitReady = true
          this.errorAvailable = false
        } else {
          this.errorAvailable = true
          this.errorBody = res.errorBody
        }
        if (res.hasOwnProperty('errorMessage')) {
          this.errorAvailable = false
        }
      },
      error: (error) => {
        this.isSubmitReady = false
        this.message = error.error.message
      }
    });
  }

  successMessage = 'No errors Found'
  uploadFile() {
    const url = this.config.json.apiEndpointRdbms + `bulkUpload/stbs/${this.selectedLco.lcoCode}`;
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message
          this.router.navigate(['/manage/lco'])
        } else {
          this.errorAvailable = true
          this.successMessage = res.message
          this.errorBody = res.errorBody
        }
      }
    })
  }

}
