import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[distributorAccess]'
})
export class DistributorAccessDirective {

  constructor(
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef
  ) {
    this.menuAccessControl();
  }

  menuAccessControl(): void {
    
    if (this.auth.getRole() == 'Distributor') {
      this.container.createEmbeddedView(this.templateRef);
    } else {
      this.container.clear();
    }
  }

}
