<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Next Seven Days Expiry Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="col-12 col-md-6">
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>
    <br/>

    <div class="third_box">
      <div class="col-12 col-md-6 report">
        <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
      </div>
      <div style="display: flex; gap:20px" >
        <div class="table__right">
          <p class="table__summary">Total <span>{{expiryReportList.length}}</span> record{{expiryReportList.length == 1? '' : 's'}}</p>
        </div>
        <div class="search__container">
          <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
          <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
        </div>
      </div>
    </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
            <!-- <th>Subscriber ID</th> -->
            <th>Sl No.</th>
            <th>Subscriber Code</th>
            <th>Subscriber Name</th>
            <th>Package Name</th>
            <th>Partner Name</th>
            <th>Mobile Number</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngIf="!responseReceived" >
            <td colspan="8" style="text-align: center;" >
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <tr *ngIf="responseReceived && expiryReportList.length==0" >
            <td colspan="8" style="text-align: center;" >
                No Records
            </td>
          </tr>
            <tr *ngFor="let report of expiryReportList, let j = index">
              <td>{{j+1}}</td>
              <!-- <td>{{report.subscriberId}}</td> -->
              <td>{{report.subscriberCode}}</td>
              <td>{{report.subscriberName}}</td>
              <td>{{report.productName}}</td>
              <td>{{report.lcoName}}</td>
              <td>{{report.mobileNumber}}</td>
              <td>{{report.startDate | date: 'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
              <td>{{report.endDate | date: 'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- "subscriberId": 5757,
            "endDate": "2023-03-11T23:59:59.000+00:00",
            "subscriberName": "aseem verma",
            "productName": "IPTV130FOR3MONTH",
            "subscriberCode": "AMB42638",
            "startDate": "2022-12-13T00:32:12.000+00 -->
