import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-log-home',
  templateUrl: './log-home.component.html',
  styleUrls: ['./log-home.component.scss']
})
export class LogHomeComponent implements OnInit {

  constructor(private auth: AuthService) { }

  isDistributor = false;
  ngOnInit(): void {
    this.isDistributor = this.auth.isDistributor();
  }



}
