import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-mso-payments',
  templateUrl: './mso-payments.component.html',
  styleUrls: ['./mso-payments.component.scss']
})
export class MsoPaymentsComponent implements OnInit {
  walletBalance;
  amount;

  constructor(
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private config: ConfigService
  ) {
  }

  // List of APIs
  // 1. Get LCO wallet balance
  // 2. Get this month due amount
  // 3. Debit LCO wallet

  ngOnInit(): void {
    // //// console.log("LCO ID:",this.auth.getLcoId())
    this.getWalletBalance()
    
  }

  getWalletBalance():void{
    const url = this.config.json.apiEndpointRdbms + 'lco/getLCOBalance';
    const body = {
      'lcoId': this.auth.getLcoId()
    }
    this.http.post(url, body).subscribe(data => {
      // //// console.log(data);
      this.walletBalance = data;
    })

  }

  debitLcoWallet():void{
    // //// console.log("amount debited",this.amount)
    const url = this.config.json.apiEndpointRdbms + 'lco/debitFromLCOWallet';
    const body = {
      'lcoId': this.auth.getLcoId(),
      'amount': this.amount
    }
    this.http.post(url, body).subscribe(data => {
      this.amount = 0;
      this.getWalletBalance();
    })

  }

}
