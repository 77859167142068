import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-wallet',
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.scss']
})
export class MyWalletComponent implements OnInit {

  amount = 0;
  walletBalance = '0.00';
  rechargeAmount = '0.00';
  paymentStatus = false;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private config: ConfigService
  ) {
  }

  getFormDataFromRedirectUrl(): void {
    const status = this.route.snapshot.queryParamMap.get('status');
    if (status === '0') {
      window.alert('Transaction Successful. Wallet recharged by Rs ' + this.route.snapshot.queryParamMap.get('rechargeAmount'));
      // this.walletBalance = this.route.snapshot.queryParamMap.get('walletAmount');
      this.rechargeAmount = this.route.snapshot.queryParamMap.get('rechargeAmount');
      this.walletBalance = this.route.snapshot.queryParamMap.get('rechargeAmount');
      this.setPaymentStatus();
    } else if (status === '1') {
      window.alert('Transaction Failed');
    } else if (status === '2') {
      window.alert('Transaction Cancelled');
    } else if (status === 'Invalid_Response') {
      window.alert('Transaction Invalid');
    }
  }

  ngOnInit(): void {
    this.getFormDataFromRedirectUrl();
    // this.setPaymentStatus();
  }

  recharge(): void {
    const url = this.config.json.apiEndpointRdbms + 'payment/atom/getRedirectUrl';
    const body = {
      'amt': this.amount + '.00',
      'userId': this.auth.getLcoId(),
      'userType': this.auth.isLCO() ? 0 : 0,
      'platformType': 0
    }
    this.http.post(url, body).subscribe(data => {
      // //// console.log(data);
      window.location.href = data.redirectUrl;
    })
  }

  getWalletBalance(): void {
    const url = this.config.json.apiEndpointRdbms + 'lco/getWalletBalance/' + this.auth.getLcoId();

    this.http.get(url).subscribe(data => {
      // //// console.log(data);
      this.walletBalance = data.balance;
    });
  }

  setPaymentStatus(): void {
    this.paymentStatus = true;
    setTimeout(() => {
      this.paymentStatus = false;
    }, 4000);
  }

}
