<div class="cms__content">
  <div class="top__heading">
    <h3>Wallet Recharge</h3>
    <a [routerLink]="['/accounts/wallet']"
      ><button type="button" class="default__button float-right">
        Back
      </button></a
    >
  </div>
  <form action="" class="custom_iptv_form" [formGroup]="walletRechargeForm">
    <div class="row">
      <div class="col-12">
        <label for="">Amount</label>
        <input
          type="text"
          placeholder="Enter Amount"
          id="amount"
          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
          class="custom__field"
          formControlName="amount"
        />
      </div>
    </div>
  </form>
  <button
    class="cta float-right"
    style="margin-top: 10px"
    [disabled]="walletRechargeForm.invalid"
    (click)="walletRechargeForm.valid ? save() : null"
  >
    Recharge
  </button>
</div>
