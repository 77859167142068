import { Injectable } from '@angular/core';
import { ChannelInfo } from '../models/channel/channel-info';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  lcoSelectedChannelList: ChannelInfo[] = [];

  datepickerPreselectedDate: any;

  constructor() { }
}
