import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MultiSelectComponent,
  NgMultiSelectDropDownModule,
} from 'ng-multiselect-dropdown';
import { SharedModule } from '../shared/shared.module';
import { CategoryRoutingModule } from './category-routing.module';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { ListCategoryComponent } from './list-category/list-category.component';
import { PluginsModule } from '../plugins/plugins.module';

@NgModule({
  declarations: [ListCategoryComponent, CreateCategoryComponent],
  imports: [
    CommonModule,
    CategoryRoutingModule,
    SharedModule,
    NgMultiSelectDropDownModule,
    PluginsModule
  ],
  exports: [ListCategoryComponent, CreateCategoryComponent],
})
export class CategoryModule {}
