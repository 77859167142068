<div class="cms__content">
  <div class="top__heading">
    <h3>MSO</h3>
    <a [routerLink]="['/manage/mso/create']"><button class="default__button float-right">+ Add New</button></a>
  </div>
  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Accounts</span>
          <span class="list__item">{{TotalMsoCount}}</span>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Activated Accounts</span>
          <span class="list__item">{{MsoActiveCount}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
              (keyup.enter)="$event.preventDefault(); onSearch()" />
            <button (click)="onSearch()"><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>

      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th>
              Sl No.
            </th>
            <th class="label__align" (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
              ID
            </th>
            <th (click)="sortByItem(sortByProperties.name)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.name}">
              Name
            </th>

            <th>Mobile</th>
            <th>Landline</th>

            <th (click)="sortByItem(sortByProperties.email)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.email}">
              Email
            </th>

            <th class="label__align" (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
              Status
            </th>



            <th class="label__align" (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
              Address
            </th>
            <th> Created Date </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngIf="loadingFlag; else showList">
            <td class="loading" colspan="9">
              <app-custom-spinner></app-custom-spinner>
            </td>
            <!-- <div class="cont">
              <div class="ring"></div>
              <div class="ring"></div>
              <div class="ring"></div>
              <p>Loading..</p>
            </div> -->
          </tr>

          <ng-template #showList>
            <tr *ngFor="let mso of msoList, let j = index">
              <td>{{((pageNumber-1)*pageSize)+(j+1)}}</td>
              <td>{{mso.id? mso.id : '---'}}</td>
              <td>{{mso.name? mso.name : '---'}}</td>
              <td>{{mso.mobile? mso.mobile : '---'}}</td>
              <td>{{mso.landline? mso.landline : '---'}}</td>
              <td>{{mso.email? mso.email : '---'}}</td>
              <td>{{mso.status === 0 ? "Inactive" : "Active"}}</td>
              <td>{{mso.address? mso.address : '---' }}</td>
              <td>
                {{mso.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                <div class="action__items">
                  <button (click)="edit(mso)">Edit</button>
                  <!-- <button class="danger"
                    (click)="updateStatus(admin)">{{admin.status===1?'Deactivate':'Activate'}}</button> -->
                </div>
              </td>

            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>