<div class="cms__content">
  <div class="top__heading">
    <h3>Create Subscribers</h3>
    <div>
      <a [routerLink]="['/batch-jobs']"><button class="default__button float-right">Back</button></a>
      <button (click)="saveSample()" id="downloadSample" class="default__button float-right">

        <img style="width: 15px; height: fit-content; fill: black" src="assets/download.svg" alt="" />
        &nbsp; Download Sample
      </button>
      <button (click)="refreshClicked()" id="downloadSample" class="default__button float-right">
        Check Status
      </button>
    </div>
  </div>
  <div class="col-12 uploadSection">
    <div class="row">
      <div class="col-12 col-md-6 offset-md-3 uploadFile">
       
        <p id="pNote">
          <br />
          Note : <br />
          1. Please Ensure that no Cell in Excel is left Empty <br />
          2. Please ensure that there are no empty rows present in the
          spreadsheet
        </p>
        <div class="bod">
          <div class="select_LCO">
            <span>Select LCO</span>
            <select name="selectLCO" id="selectLCO" [(ngModel)]="selectedLco" (change)="selectOption()">
              <option value="" disabled selected>---SELECT---</option>
              <option *ngFor="let lco of lcoList" [ngValue]="lco">
                {{ lco.name }}
              </option>
            </select>
          </div>
          <ng-container *ngIf="optionSelected">
            <div class="upload" style="margin-top: 5px">
              <span style="margin-top: 5px">Upload Subscriber Details</span>
              <input type="file" id="chooseFile" class="custom__field" accept=".xls, .xlsx"
                (change)="selectedFile($event)" />
              <p>(File types : Excel | .xls, .xlsx )</p>
              <button id="clearInput" (click)="clearInput()">Clear</button>

              <p id="pNote">{{ message }}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="isSubmitReady">
            <button class="btn btn-primary" style="margin-top: 5px" (click)="uploadFile()">
              Submit
            </button>
            &nbsp; {{ successMessage }}
          </ng-container>
        </div>
        <div class="errorMessagebox" *ngIf="errorAvailable">
          <h5>Errors Found :</h5>
          <ng-container *ngFor="let msg of errorBody">
            {{ msg }}<br />
          </ng-container>
        </div>
       
      </div>


    </div>
  </div>
</div>



<div class="modal_box" *ngIf="viewModal">
  <div class="modal_box_inner">
    <div style="display: flex;flex-direction: row;justify-content: space-between;margin-bottom: 10px;">
      <h3>Bulk Upload Status</h3>
      <div style="display: flex;gap:4px">
        <button class="default__button float-right" (click)="getPendingStatus()">Refresh</button>
        <button class="default__button float-right" (click)="pushFunction()">Push</button>
      </div>

    </div>
   
   
    <div class="custom_iptv_table">
      <div class="table__height">
        <table class="custom__iptv__table" #reportTable id="reportTable"  >
          <thead>
            <tr>
              <th>Sl no</th>
              <th>LCO ID</th>
              <th>LCO Code</th>
              <th>LCO Name</th>
              <th>Pending Request</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="reportList.length == 0">
              <td style="text-align: center !important" colspan="5">
                No Data
              </td>
            </tr>
            <tr *ngFor="let report of reportList; let j = index">
              <td>{{ j + 1 }}</td>
              <td>{{report.lcoId?report.lcoId:'---'}}</td>
              <td>{{ report.lcoCode ? report.lcoCode : "---" }}</td>
              <td>{{ report.lcoName ? report.lcoName : "---" }}</td>
              <td>{{ report.pendingRequest ? report.pendingRequest : "---" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
 
    <button class="cta float-right" (click)="viewModal = !viewModal"> Close </button>
  </div>
</div>