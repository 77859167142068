import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { DashboardDataService } from '../dashboard-service/dashboard-data.service';
import { forkJoin } from 'rxjs';
import { DashboardTopChannelInfo } from 'src/app/models/dashboard/dashboard-top-channel-info';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color, SingleDataSet } from 'ng2-charts';
import { AuthService } from 'src/app/services/auth.service';
import { plugins } from 'chart.js';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss'],
})
export class DashboardHomeComponent implements OnInit {
  constructor(
    private data: DashboardDataService,
    private authService: AuthService,
    private config:ConfigService,
    private http:HttpClientWrapperService
  ) {}

  ngOnInit(): void {
    this.loadDataForDashboard();
    this.loadtotalRegistrations();
  }

  date = new Date();
  monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  isLco = this.authService.isLCO();
  bestSellingPacks: any = [
    { key: 'Bouquet Pack', value: 2 },
    { key: 'Bouquet Pack', value: 10 },
    { key: 'Bouquet Pack', value: 25 },
    { key: 'Bouquet Pack', value: 30 },
    { key: 'Bouquet Pack', value: 50 }
  ] 
  mostViewedChannels: string[] = new Array(5).fill('');
  expiringSubscriptions = {
    day: 1,
    week: 1,
    month: 1,
    year: 1,
  };
  revenueGenerated = {
    today: 1,
    month: 1,
    year: 1,
  };
  smallBlockCounts = {
    customerOnboardedToday: 1,
    totalLco: 1,
    totalSubscribers: 1,
    broadcasters: 1,
    channels: 1,
  };
  dashboardData
  loadtotalRegistrations(){
    let url=this.config.json.apiEndpointRdbms+'dashboard/dashBoardNew'
    this.http.get(url).subscribe(
      (data)=>{
        this.dashboardData=data.data;
      }
    )
    
  }
  loadDataForDashboard() {
    let body = {
      accType: (this.authService.getRole() as string).toLocaleLowerCase(),
      id: this.authService.getLcoId(),
    };
    forkJoin([
      this.data.getDashboardDataAll(body),
      this.data.getDashboardDataMonthly(body),
    ]).subscribe({
      next: (res) => {
        this.bestSellingPacks = res[0].bestSellingPacks;
        this.mostViewedChannels = res[0].mostViewedChannels;
        this.handleSubscribersPieChart(res[0].subscribers);
        this.handleStbPieChart(res[0].stbStatus);
        this.expiringSubscriptions = { ...res[0].expiringSubscriptions };
        this.revenueGenerated = { ...res[0].revenueGenerated };
        this.smallBlockCounts = { ...res[0].smallBlockCounts };
        console.log(res[1]);

        this.allLineAndBarChartLabels = res[1].months;
        this.handleYearOnYearLineChart({
          previousYearRevenue: res[1].previousYearRevenue,
          currentYearRevenue: res[1].currentYearRevenue,
        });
        this.handleCustomerOnboardingLineChart({
          currentYearCustomers: res[1].newSubscribers,
          previousYearCustomers: res[1].oldSubscribers,
        });
        this.handleUserStatusBarChart({
          activeUsers: res[1].activeSubscribers,
          inactiveUsers: res[1].inactiveSubscribers,
        });
      },
    });
  }

  allPieChartOptions: ChartOptions = { responsive: true };
  allPieChartType: ChartType = 'pie';
  allPieChartLegend = true;
  allPieChartPlugins = [];

  subscriberPieChartLabels: Label[] = ['Active', 'Inactive'];
  subscriberPieChartData: SingleDataSet = [70, 30];
  subscriberPieChartColors: Color[] = [
    { backgroundColor: ['rgba(46, 204, 113,0.8)', 'rgba(52, 73, 94, 0.8)'] },
  ];
  handleSubscribersPieChart(data) {
    this.subscriberPieChartData = data;
  }

  stbPieChartLabels: Label[] = ['Active', 'Inactive', 'Blacklisted'];
  stbPieChartData: SingleDataSet = [70, 30, 40];
  stbPieChartColors: Color[] = [
    {
      backgroundColor: [
        'rgba(20, 144, 143,0.8)',
        'rgba(152, 173, 94, 0.8)',
        'rgba(0,0, 0, 0.8)',
      ],
    },
  ];
  handleStbPieChart(data) {
    this.stbPieChartData = data;
  }

  allLineAndBarChartLabels: Label[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  allLineAndBarChartOptions = { responsive: true };
  allLineAndBarChartLegend: true;
  allLineAndBarChartPlugins = [];
  allLabelsPreviousYear = `${
    new Date().getFullYear() - 1
  }-${new Date().getFullYear()}`;
  allLabelsCurrentYear = `${new Date().getFullYear()}-${
    new Date().getFullYear() + 1
  }`;
  allLineChartType: ChartType = 'line';
  allBarChartType: ChartType = 'bar';
  allBarChartColor: Color[] = [
    {
      backgroundColor: 'rgba(52, 152, 19, 0.8)',
      borderColor: 'rgba(41, 128, 185, 0.6)',
    },
    {
      backgroundColor: 'rgba(52, 152, 219, 0.8)',
      borderColor: 'rgba(41, 128, 185, 0.6)',
    },
  ];
  allLineChartColor: Color[] = [
    {
      borderColor: ['rgba(46, 204, 113,0.8)', 'rgba(52, 152, 219, 0.8)'],
      backgroundColor: ['rgba(0, 0, 0, 0.01)', 'rgba(0, 0, 0, 0.01)'],
    },
  ];

  yearOnYearLineChartData: ChartDataSets[] = [
    {
      data: [22, 33, 44, 55, 66, 77, 88, 99, 11, 22, 33, 44],
      label: this.allLabelsPreviousYear,
      fill: false,
    },
    {
      data: [77, 88, 99, 11, 22, 33, 44, 22, 33, 44, 55, 66],
      label: this.allLabelsCurrentYear,
      fill: false,
    },
  ];
  handleYearOnYearLineChart(data) {
    let { currentYearRevenue, previousYearRevenue } = data;
    this.yearOnYearLineChartData[0].data = previousYearRevenue;
    this.yearOnYearLineChartData[1].data = currentYearRevenue;
  }

  customerOnboardingLineChartData: ChartDataSets[] = [
    {
      data: [22, 33, 44, 55, 66, 77, 88, 99, 11, 22, 33, 44],
      label: this.allLabelsPreviousYear,
      fill: false,
    },
    {
      data: [77, 88, 99, 11, 22, 33, 44, 22, 33, 44, 55, 66],
      label: this.allLabelsCurrentYear,
      fill: false,
    },
  ];
  handleCustomerOnboardingLineChart(data) {
    let { currentYearCustomers, previousYearCustomers } = data;
    this.customerOnboardingLineChartData[0].data = previousYearCustomers;
    this.customerOnboardingLineChartData[1].data = currentYearCustomers;
  }

  userStatusBarChartData: ChartDataSets[] = [
    {
      data: [22, 33, 44, 55, 66, 77, 88, 99, 11, 22, 33, 44],
      label: 'Active',
      fill: false,
    },
    {
      data: [22, 33, 44, 55, 66, 77, 88, 99, 11, 22, 33, 44].reverse(),
      label: 'In-Active',
      fill: false,
    },
  ];
  handleUserStatusBarChart(data) {
    let { activeUsers, inactiveUsers } = data;
    this.userStatusBarChartData[0].data = activeUsers;
    this.userStatusBarChartData[1].data = inactiveUsers;
  }
}
