import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LcoSelfRegistrationComponent } from './lco-self-registration/lco-self-registration.component';
import { ListSelfregisteredComponent } from './list-selfregistered/list-selfregistered.component';
import { SelfRegisteredRoutingModule } from './selfregistration-routing.module';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    LcoSelfRegistrationComponent,
    ListSelfregisteredComponent
  ],
  imports: [
    CommonModule,
    SelfRegisteredRoutingModule,
    SharedModule
  ]
})
export class SelfRegistrationModule { }
