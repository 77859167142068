import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(values: any[], input: string): any {
    if (!values) { return []; }
    if (!input) { return values; }

    input.toLowerCase();
    // const filteredList = [];
    let property;
    return values.filter(val => {
      for (const key of Object.keys(val)) {
        property = isNaN(val[key]) ? val[key].toLowerCase() : val[key].toString().toLowerCase();

        if (property.indexOf(input) > -1) {
          // filteredList.push(val);
          // return filteredList;
          return val;
        }
      }
    });
  }

}
