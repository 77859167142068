<div class="cms__content">
  <div class="top__heading">
    <h3>Transfer STBs to LCO</h3>
    <a [routerLink]="['/batch-jobs']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="form__style">
    <div class="row">
      <div class="col-12 col-md-6">
        <label for="fromLCO">From LCO</label>
        <select
          name="selectLCO"
          id="selectLCO"
          [(ngModel)]="fromLco"
          (change)="selectOption('from')"
          class="custom__field"
        >
          <option value="" selected disabled>---SELECT---</option>
          <option *ngFor="let lco of lcoList" [ngValue]="lco"
            >{{lco.name}}</option
          >
        </select>
      </div>
      <div class="col-12 col-md-6">
        <label for="toLCO">To LCO</label>
        <select
          name="selectLCO"
          id="selectLCO"
          [(ngModel)]="toLco"
          (change)="selectOption('to')"
          class="custom__field"
        >
          <option value="" selected disabled>---SELECT---</option>
          <option *ngFor="let lco of lcoList" [ngValue]="lco"
            >{{lco.name}}</option
          >
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label for="toLCO">Status</label>
        <select
          name="selectStatus"
          id="selectStatus"
          [(ngModel)]="selectedStatus"
          (change)="selectOption('status')"
          class="custom__field"
        >
          <option value="" selected disabled>---SELECT---</option>
          <option *ngFor="let status of statusList" [ngValue]="status.value"
            >{{status.label}}</option
          >
        </select>
      </div>
    </div>
  </div>
  <div *ngIf="fromOptionSelected && toOptionSelected" class="submit__button">
    <button class="cta float-right" (click)="onSubmit()">Submit</button>
    <p class="success-message">{{ successMessage }}</p>
  </div>
</div>
