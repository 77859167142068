import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-logs-landingchannelsettings',
  templateUrl: './logs-landingchannelsettings.component.html',
  styleUrls: ['./logs-landingchannelsettings.component.scss']
})
export class LogsLandingchannelsettingsComponent implements OnInit {

 
  reportList = [];

  // macAddressList: stbMacInfo[] = []
  selectedStbMacAddress: string;

  from: any;
  to: any;
  paginationObj = new PaginationObj();
  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }
 userId
  ngOnInit(): void {
    if(this.config.json.userType=='MSO'){
      this.user=true
    }
    else{
      this.user=false
    }
    this.userId=JSON.parse(localStorage.getItem('profileInfo')).id
    this.from = '2019-01-01'
    this.to = this.formatDate(new Date())
    this.fetchData()
  }

  loadingFlag = false
  isLoad = 'Choose Date'
  user

  fetchData(): void {
    
    this.loadingFlag = true
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint)
    let url=''
     url = PaginationUtilities.urlBuilder('landingChannelSetting/logList', this.paginationObj)
    this.http.get(url).subscribe({
      next: (data: any) => {
        this.handlePageMetadata(data.metadata)
        this.reportList = data.data;
        this.loadingFlag = false;
        if (this.reportList.length!= 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = "No Records"
        }
      },
      error: (error) => {
        this.loadingFlag = false;
      }
    })
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus()
  }

  formatDate(ele) {
    if(ele == undefined){
      return false
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  changeMacAddress() { }
  dateString;
  eve
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [this.formatDate(ev.startDate), this.formatDate(ev.endDate)];
      this.calendar.setDate(ev);
      this.eve = ev
      this.dateString = ev.startDate.date()+"-"+this.doc.monthNameCalculator(ev.startDate.month())+"-"+ev.startDate.year()+" to "+ev.endDate.date()+"-"+this.doc.monthNameCalculator(ev.endDate.month())+"-"+ev.endDate.year()
      this.fetchData()
    }
  }

  // downloadTableExcel(): void {
  //   let name = 'Landing Channel Setting Logs'
  //   this.doc.exportTableToExcelwithImage(this.reportTable, name, 'landing_Channel_Setting_logs', undefined, undefined, "Report Date : ",this.dateString);
  // }


  
  downloadTableExcel(){
    let url

      url= this.config.json.apiEndpoint+'generate/landingChannelSettingLog?searchKeyword='+this.paginationObj.searchKeyword
      this.http.getwithresponseType(url).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'landing_Channel_Setting_logs.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }

  downloadTablePdf(): void {
   
    this.pdf.generatePdfHtml('#reportTable',
      'landing_Channel_Setting_logs',
      
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Sl No","LCO ID", "LCO Name", "Channel ID", "Channel Number", "Channel Name"]
    };
  
    let data = []
    let i =1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo : i,
        lcoId : line.lcoId,
        lcoName : line.lcoName,
        channelId : line.channelId,
        channelLcn : line.channelLcn,
        channelName:line.channelName,
        createdBy:line.createdBy
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'landing_Channel_Setting_logs', options);
  }

  disableFirstBtn = false;
  disablePreviousBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;

  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (
      this.paginationObj.pageNumber == this.paginationObj.totalPages
    ) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }

}

