import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyPacksComponent } from './my-packs/my-packs.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyWalletComponent } from './my-wallet/my-wallet.component';

const routes: Routes = [
  { path: '', component: MyProfileComponent },
  { path: 'profile', component: MyProfileComponent },
  { path: 'wallet', loadChildren: () => import('../wallet/wallet.module').then(m => m.WalletModule) },
  { path: 'my-packs', component: MyPacksComponent },
  { path: 'my-wallet', component: MyWalletComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
