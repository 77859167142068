import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Directive({
  selector: '[lcoAccess]'
})
export class AdminAccessDirective {

  constructor(
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef
  ) {
    this.menuAccessControl();
  }

  menuAccessControl(): void {
    if (this.auth.getRole() == "LCO") {
      this.container.createEmbeddedView(this.templateRef);
    } else {
      // is MSO, therefore should be hidden
      this.container.clear();
    }
  }
}
