import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import colors from '../../theme-manager/color-selector/colors';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private userIdle: UserIdleService,
    private router: Router,
    private config: ConfigService,
    private http: HttpClient,
    private fb: FormBuilder,
    private httpC : HttpClientWrapperService
  ) {}

  loginForm: FormGroup;
  showPasswordField = false;

  ngOnInit(): void {
    this.checkIfUserAlreadyLoggedIn()
    this.changeLoginBackground();
    this.changeTheme();
    this.initForm();
  }

  isLoggedIn = false
  checkIfUserAlreadyLoggedIn(){
    if(localStorage.getItem('TOKEN')){
      this.router.navigate(['/dashboard'])
    }
  }

  backgroundImage;
  changeLoginBackground(): void {
    let url = this.config.json.apiEndpointRdbms + 'banner';
    this.http.get(url).subscribe({
      next: (data: any) => {
        console.log(data);
        this.backgroundImage = data.data.url;
      },
    });
  }

  cancel() {
    this.forgotPassword = false;
    this.otpText = 'Get OTP';
    this.otpVerified = false;
  }

  changeTheme() {
    let colorSet = localStorage.getItem('chosenPalette');
    if (colorSet == 'reset') {
      document.documentElement.style.setProperty('--darkBack', '#676874');
      document.documentElement.style.setProperty(
        '--highlight-color',
        '#2980b9'
      );
      document.documentElement.style.setProperty('--lightBack', '#b1b3c1');
      document.documentElement.style.setProperty('--nav-white', '#fff');
      document.documentElement.style.setProperty('--nav-font', '#665f82');
      document.documentElement.style.setProperty('--nav-button', '#2980b9');
      return;
    }
    for (let colorname of colors) {
      let color = colorname[colorSet];
      console.log(color);
      if (color) {
        document.documentElement.style.setProperty('--darkBack', color.primary);
        document.documentElement.style.setProperty(
          '--highlight-color',
          color.primary
        );
        document.documentElement.style.setProperty(
          '--lightBack',
          color.primary
        );
        document.documentElement.style.setProperty(
          '--nav-white',
          color.primary
        );
        document.documentElement.style.setProperty('--nav-font', '#fff');
        document.documentElement.style.setProperty('--nav-button', '#fff');
      }
    }
  }

  showPassword(): void {
    this.showPasswordField = !this.showPasswordField;
  }

  forgotPassword = false;
  initForm(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      // ismso: new FormControl(false),
    });
  }

  login(): void {
    this.auth.login(this.loginForm.value);
    this.httpC.alertShown= false;
  }

  clear(): void {
    this.loginForm.reset();
  }

  emailPhoneForForgotPassword;
  otpText = 'Get OTP';
  getOtp() {
    let url =
      this.config.json.apiEndpointRdbms + 'gupShupSMSGateway/forgotPassword';
    let headers = new HttpHeaders().set(
      'api_key',
      '2cb7e3df-017f-438c-a434-ca2962e363eb'
    );
    this.http
      .post(
        url,
        { user: this.emailPhoneForForgotPassword },
        { headers: headers }
      )
      .subscribe({
        next: (data: any) => {
          if (data.status == 'SUCCESS') {
            this.otpText = 'Verify OTP';
            alert('otp sent successfully');
          } else {
            alert(data.message);
          }
        },
      });
  }

  otpVerified = false;
  otp;

  verifyOTP() {
    let url =
      this.config.json.apiEndpointRdbms +
      'gupShupSMSGateway/verifyOtpForgotPassword';
    let headers = new HttpHeaders().set(
      'api_key',
      '2cb7e3df-017f-438c-a434-ca2962e363eb'
    );
    let body = {
      user: this.emailPhoneForForgotPassword,
      otp: this.otp,
    };
    this.http.post(url, body, { headers: headers }).subscribe({
      next: (data: any) => {
        data.status == 'SUCCESS'
          ? (this.otpVerified = true)
          : alert(data.message);
      },
    });
  }

  newPassword;
  confirmPassword;
  getPassword(e: Event, password: string) {
    let pass = (e.target as HTMLInputElement).value;
    switch (password) {
      case 'newPassword':
        this.newPassword = pass;
        break;
      case 'confirmPassword':
        this.confirmPassword = pass;
        break;
    }
    this.passwordConfirmation();
  }

  passwordConfirmation() {
    return this.newPassword == this.confirmPassword;
  }

  changePassword() {
    console.log(this.newPassword);

    let body = {
        user: this.emailPhoneForForgotPassword,
        newPassword: this.newPassword,
      },
      url =
        (this.config.json.apiEndpointRdbms as string).replace('api', 'auth') +
        'resetPassword';
    let headers = new HttpHeaders().set(
      'api_key',
      '2cb7e3df-017f-438c-a434-ca2962e363eb'
    );
    this.http.post(url, body, { headers: headers }).subscribe({
      next: (data: any) => {
        data.status == 'SUCCESS'
          ? (this.otpVerified = this.forgotPassword = false)
          : alert(data.message);
      },
    });
  }
}
