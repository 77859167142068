<h3>Select a Color Theme</h3>
<div class="color_picker_wrapper">
  <ng-container *ngFor="let palette of colors" >
  <ng-container *ngFor="let color of palette | keyvalue" >
    <div class="theme_palette_container" (click)="chosenPalette(color)" >
        <p>{{color.key}}</p>
        <div class="color_palette">
            <ng-container *ngIf="color.value as palatteColor" >
                <div [ngStyle]="{backgroundColor : palatteColor.primary}" ></div>
                <div [ngStyle]="{backgroundColor : palatteColor.secondary}" ></div>
                <div [ngStyle]="{backgroundColor : palatteColor.hover}" ></div>
            </ng-container>
        </div>
    </div>
  </ng-container>
  </ng-container>
</div>