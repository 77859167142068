import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { InvoiceDataService } from '../plugins/invoice-data.service';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit {

  invoice = {} as any;
  invoiceId: any;
  subscriberData = {} as any;
  date = new Date();

  constructor(
    private invoiceData: InvoiceDataService,
    private route: ActivatedRoute,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.getPathParam();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.invoiceId = id;
    this.getInvoiceDetails(id);
  }

  getInvoiceDetails(id): void {
    this.invoiceData.getInvoiceById(id).subscribe(data => {
      this.invoice = data;
      this.subscriberData = this.invoice.product[0];
    })
  }

}
