<div class="cms__content">
  <div class="top__heading">
    <h3>{{isEdit?'Edit':'Create'}} Category</h3>
    <a [routerLink]="['/content/category/list']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>
  <form action="" [formGroup]="categoryForm" >
    <div class="col-12 form__style">
      <div class="row">

        <div class="col-12 col-md-6">
          <label>Name</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="name"
            [ngClass]="{'error__field': !name.valid && name.touched}" />
          <div [hidden]="!name.touched">
            <span class="error__msg" *ngIf="name.hasError('required')">Required</span>
            <span class="error__msg" *ngIf="name.errors?.cannotContainSpace">Please ensure Name does not contain
              space</span>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Status</label>
          <select class="custom__field" formControlName="status">
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Channels</label>

          <!-- <ng-multiselect-dropdown [placeholder]="'select channels'" [settings]="dropdownSettings" [data]="dropdownList"
            [(ngModel)]="selectedList" [ngModelOptions]="{standalone: true}" class="custom__field">
          </ng-multiselect-dropdown> -->
          <multi-select [multiSelectOptions]="channelListOptionsForMultiSelect" [controlName]="'channels'" [parentFormName]="categoryForm" ></multi-select>
          <!-- New proposed style -->
          <!-- <app-channel-selector></app-channel-selector> -->

        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Description</label>
          <textarea class="custom__field" formControlName="description"></textarea>
        </div>
      </div>
    </div>

  </form>
  <div class="col-12">
    <!-- <button type="button" [routerLink]="['/category/list']" class="default__button">Cancel</button> -->
    <button class="cta float-right" (click)="persist()" [disabled]="!categoryForm.valid">Save</button>
  </div>
</div>
