import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportSubscriberComponent } from './report-subscriber/report-subscriber.component';
import { CustomReportTraiHomeComponent } from './custom-report-trai-home/custom-report-trai-home.component';
import { ReportSubscriberLcoDistributorComponent } from './report-subscriber-lco-distributor/report-subscriber-lco-distributor.component';
import { ReportChannelsDetailedCountComponent } from './report-channels-detailed-count/report-channels-detailed-count.component';
import { ReportBsnlSubscribersComponent } from './report-bsnl-subscribers/report-bsnl-subscribers.component';

const routes: Routes = [
    { path: '', redirectTo: 'custom-report-home' },
    { path: 'report-subscriber', component: ReportSubscriberComponent },
    { path: 'custom-report-home', component: CustomReportTraiHomeComponent },
    { path: 'report-subscriber-lco-distributor', component: ReportSubscriberLcoDistributorComponent },
    { path: 'report-channel-detailed-count', component: ReportChannelsDetailedCountComponent },
    { path: 'report-bsnl-subscribers', component: ReportBsnlSubscribersComponent },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CustomReportTraiRoutingModule { }
