import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PathNames } from 'src/app/constants/path-names';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-lco-set-bouquet',
  templateUrl: './lco-set-bouquet.component.html',
  styleUrls: ['./lco-set-bouquet.component.scss']
})
export class LcoSetBouquetComponent implements OnInit {


  isEdit
  lcoId
  lcoSetBouquetForm: FormGroup;
  dataArray = {}
  bouquetList =[]



  constructor(private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService) { }

  ngOnInit(): void {
    this.initForm()
    this.getPathParam()
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id)
    if (id && id !== PathNames.create) {
      console.log(id)
      this.isEdit = true;
      this.lcoId = id
      this.fetchRecord(id);
      this.fetchBouquetDetails(id)
      this.lcoIdValue.setValue(id)
    }
  }

  fetchBouquetDetails(id){
    const url = this.config.json.apiEndpointRdbms + 'lco/lcoBasePacks/'+id;
    this.http.get(url).subscribe(
      (data)=>{
        this.bouquetList=data.data;
        console.log(this.bouquetList)
      }
    )
  }
  fetchRecord(id: string): void {
    const url = this.config.json.apiEndpointRdbms + 'lcobouquetsetup/'+id;
    this.http.get(url).subscribe((data) => {
      if (data.statusCode == 200) {
        this.updateForm(data.data)
      }else{
        this.isEdit= false;
        console.log(this.isEdit)
      }
    });
  }

  updateForm(data) {
    this.initialBouquet.setValue(data.initialBouquet)
    this.noOfDays.setValue(data.noOfDays)
    this.defaultBouquet.setValue(data.defaultBouquet)
  }

  persist() {
    this.updateModel()
    this.isEdit ? this.update() : this.save()
  }

  updateModel() {
    this.dataArray['initialBouquet'] = this.initialBouquet.value
    this.dataArray['noOfDays'] = this.noOfDays.value
    this.dataArray['lcoId'] = this.lcoIdValue.value
    this.dataArray['defaultBouquet'] = this.defaultBouquet.value
  }

  update() {
    let url = this.config.json.apiEndpointRdbms + 'lcobouquetsetup/update'
    this.http.put(url, this.dataArray).subscribe(
      (data) => {
        if (data.statusCode == 200) {
          this.router.navigateByUrl('manage/lco')
        }
        else {
          alert(data.message)
        }
      },
      (err => {
        alert(err)
      })
    )
  }

  save(){
    let url = this.config.json.apiEndpointRdbms + 'lcobouquetsetup/save'
    this.http.post(url, this.dataArray).subscribe(
      (data) => {
        if (data.statusCode == 200) {
          this.router.navigateByUrl('manage/lco')
        }
        else {
          alert(data.message)
        }
      },
      (err => {
        alert(err)
      })
    )

  }

  initForm(): void {
    this.lcoSetBouquetForm = new FormGroup({
      defaultBouquet: new FormControl('', [Validators.required]),
      lcoIdValue: new FormControl('', [Validators.required]),
      initialBouquet: new FormControl('', [Validators.required]),
      noOfDays: new FormControl('', [Validators.required]),
    });
  }

 
  get initialBouquet(): FormControl {
    return this.lcoSetBouquetForm.get('initialBouquet') as FormControl;
  }
  get lcoIdValue(): FormControl {
    return this.lcoSetBouquetForm.get('lcoIdValue') as FormControl;
  }

  get noOfDays(): FormControl {
    return this.lcoSetBouquetForm.get('noOfDays') as FormControl;
  }

  get defaultBouquet(): FormControl {
    return this.lcoSetBouquetForm.get('defaultBouquet') as FormControl;
  }


}
