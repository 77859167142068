import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'background-changer',
  templateUrl: './background-changer.component.html',
  styleUrls: ['./background-changer.component.scss']
})
export class BackgroundChangerComponent implements OnInit {

  constructor(
    private http : HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
  }

  jpgLocation;
  imageUploaded = false
  uploadFile(e: Event) {
    let file = (e.target as HTMLInputElement).files[0],
      formData = new FormData(),
      url = `${this.config.json.apiEndpointRdbms}app/upload`
    formData.append('app', file)
    this.http.post(url, formData).pipe(finalize(() => {
      formData = new FormData()
    })).subscribe({
      next: (res : any) => {
        this.jpgLocation = res.data
        this.imageUploaded = true
      }
    })
  }

  save(){
    let url = this.config.json.apiEndpointRdbms+'banner'
    this.http.post(url,{"url" : this.jpgLocation}).subscribe({
      next : (data : any) => {
        alert(data.status)
      }
    })
  }

}
