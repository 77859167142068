import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportCalendarService {

  startDate: moment.Moment;
  endDate: moment.Moment;

  constructor() {
    // this.initDate();
  }

  initDate(): void {
    this.startDate = moment().subtract(6, 'days');
    this.endDate = moment();
  }

  setDate(ev: any): void {
    this.startDate = ev.startDate;
    this.endDate = ev.endDate;
  }

  getDate(): any {
    return {
      startDate: this.startDate,
      endDate: this.endDate
    };
  }
}
