<div class="cms__content">
  <div class="top__heading">
    <h3>Transfer Subscribers to other LCO</h3>
    <div>
      <a [routerLink]="['/batch-jobs']"
        ><button class="default__button float-right">Back</button></a
      >
      <button
        (click)="saveSample()"
        id="downloadSample"
        class="default__button float-right"
      >
        <img
          style="width: 15px; height: fit-content; fill: black;"
          src="assets/download.svg"
          alt=""
        />
        &nbsp; Download Sample
      </button>
    </div>
  </div>
  <div class="col-12 form__style">
    <div class="row">
      <!-- <div class="col-12 col-md-6">
        <label for="fromLCO">From LCO</label>
        <select
          name="selectLCO"
          id="selectLCO"
          class="custom__field"
          [(ngModel)]="fromLco"
          (change)="selectOption('from')"
        >
          <option value="" selected disabled>--SELECT--</option>
          <option *ngFor="let lco of lcoList" [ngValue]="lco"
            >{{lco.name}}</option
          >
        </select>
      </div>
      <div class="col-12 col-md-6">
        <label for="toLCO">To LCO</label>
        <select
          name="selectLCO"
          id="selectLCO"
          class="custom__field"
          [(ngModel)]="toLco"
          (change)="selectOption('to')"
        >
          <option value="" selected disabled>--SELECT--</option>
          <option *ngFor="let lco of lcoList" [ngValue]="lco"
            >{{lco.name}}</option
          >
        </select>
      </div> -->
      <div class="col-12 col-md-6">
        <label for="fromLCO">From LCO</label>
        <select
          name="selectLCO"
          id="fromLCO" class="custom__field"
          [(ngModel)]="fromLco"
          (change)="selectOption('from')"
        >
          <option value="" selected disabled>--SELECT--</option>
          <option *ngFor="let lco of lcoList" [ngValue]="lco">{{ lco.name }}</option>
        </select>
      </div>
      <div class="col-12 col-md-6">
        <label for="toLCO">To LCO</label>
        <select
          name="selectLCO"
          id="toLCO" class="custom__field"
          [(ngModel)]="toLco"
          (change)="selectOption('to')"
        >
          <option value="" selected disabled>--SELECT--</option>
          <option *ngFor="let lco of filteredLcoList" [ngValue]="lco">{{ lco.name }}</option> </select>
      </div>
    </div>
  </div>
  <div class="row">
  </div>
  <div class="bod2">
    <div class="col-12 uploadSection">
      <div class="row">
        <div class="insertBox">
          <p id="pNote">
            Note : <br />
            1. Please Ensure that no Cell in Excel is left Empty <br />
            2. Please ensure that there are no empty rows present in the
            spreadsheet
          </p>
          <ng-container *ngIf="toOptionSelected && fromOptionSelected">
            <span>Upload Subscriber Details</span>
            <input type="file"
              id="chooseFile"
              class="custom__field"
              accept=".xls, .xlsx"
              (change)="selectedFile($event)"
            />
            <p>(File types : Excel | .xls, .xlsx )</p>
            <button id="clearInput" (click)="clearInput()">Clear</button>

            <p id="pNote">{{message}}</p>
          </ng-container>
          <ng-container *ngIf="isSubmitReady">
            <button class="cta" (click)="uploadFile()">
              Submit
            </button>
            &nbsp; {{successMessage}}
          </ng-container>
        </div>
        <div class="errorMessagebox" *ngIf="errorAvailable">
          <h5>Errors Found :</h5>
          <ng-container *ngFor="let msg of errorBody">
            {{msg}}<br />
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
