import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from 'src/app/logs/plugins/logs-data.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { ngxCsv } from 'ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';

@Component({
  selector: 'app-logs-channel-modification',
  templateUrl: './logs-channel-modification.component.html',
  styleUrls: ['./logs-channel-modification.component.scss']
})
export class LogsChannelModificationComponent implements OnInit {
  reportList = [];

  channelList: ChannelInfo[] = [];
  selectedChannelId: number;

  broadcasterList: BroadcasterInfo[] = [];
  selectedBroadcasterId: any = 0;

  from: any;
  to: any;
  paginationObj = new PaginationObj();
  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    this.fetchBroadcasterList();
    this.fetchData()
  }

  body={
    fromDate: this.formatDate("2019-01-01"),
    toDate: this.formatDate(new Date()),
    broadcasterId: this.selectedBroadcasterId,
  }
  fetchData(){
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint)
    let url = PaginationUtilities.urlBuilder('logs/channelModificationHistory', this.paginationObj)
    url += `&broadcasterId=${this.body.broadcasterId}&fromDate=${this.body.fromDate}&toDate=${this.body.toDate}`
    this.http.get(url).subscribe({
      next: (data) => {
        this.reportList = data.data;
        this.handlePageMetadata(data.metadata)
      if (this.reportList.length != 0) {
        this.loadingFlag = false
      } else {
        this.loadingFlag = false
        this.isLoad = 'No Records'
      }
      }
    })
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus()
  }

  loadingFlag = false
  isLoad = 'Choose Date'
  fetchChannelList(): void {
    this.report.getChannelList().subscribe(data => {
      this.channelList = data;
    });
  }

  formatDate(ele) {
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  fetchBroadcasterList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }

  listItem(e: Event): void {
    let broadcasterId = (e.target as HTMLSelectElement).value
    this.body ={
      ...this.body,
      broadcasterId
    }
    this.fetchData()
  }


  dateSelected = false
  broadcasteSelected = false
  ev
  reportDate;
  dateChange(ev: NgxDateRangeInfo): void {
    this.dateSelected = true
    if (ev.startDate !== null && ev.endDate !== null) {
      this.reportDate = ev.startDate.format('DD-MM-YYYY') + " to " + ev.endDate.format('DD-MM-YYYY')
      this.ev = ev
        [this.from, this.to] = [ev.startDate, ev.endDate];
        this.calendar.setDate(ev);
        this.body = {
          ...this.body,
          fromDate: ev.startDate.format('DD-MM-YYYY'),
          toDate: ev.endDate.format('DD-MM-YYYY')
        }
        this.fetchData()
        this.loadingFlag = true
        this.isLoad = 'Loading'
    }
  }
  downloadTableExcel(): void {
    let name = 'Channel Modification Logs'
    if (this.reportList.length == 0) {
      alert('No Data!!')
    } else {
      if (this.ev) {
        this.doc.exportTableToExcelwithImage(this.reportTable, name, 'Channel Modification Logs', undefined, undefined, "Filter Date :", this.reportDate, "Broadcaster Name : ", this.selectedBroadcasterId ? this.selectedBroadcasterId.name : 'ALL');
      } else {
        this.doc.exportTableToExcelwithImage(this.reportTable, name, 'Channel Modification Logs', undefined, undefined,"Broadcaster Name : ",this.selectedBroadcasterId ? this.selectedBroadcasterId.name : 'ALL');
      }
    }
  }


  downloadTablePdf(): void {
    if (this.reportList.length == 0) {
      alert('No Data!!')
    } else {
      if (this.ev) {
        this.pdf.generatePdfHtml('#reportTable',
          'channel_modification_logs',
          `Filter date : ${this.reportDate}   Broadcaster Name: ${this.selectedBroadcasterId ? this.selectedBroadcasterId.name : 'ALL'}`, 'portrait'
        );
      } else {
        this.pdf.generatePdfHtml('#reportTable',
          'channel_modification_logs',
          `From Start to Now    Broadcaster Name: ${this.selectedBroadcasterId ? this.selectedBroadcasterId.name : 'ALL'}  `, 'portrait'
        );
      }
    }
  }



  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Sl No","Date", "Broadcaster Name", "Channel Name", "Channel ID", "Channel Number", "Action"]
    };
    let data = []
    let i =1
    this.reportList.forEach(line => {
      let csvLine = {
        slno : i,
        date: line.fromDate,
        name: line.broadcasterName,
        channelName: line.channelName,
        channelId: line.channelId,
        channelNo: line.channelNo,
        action: line.action || line.ACTION
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'Channel_Modification_Logs', options);
  }

  disableFirstBtn = false;
  disablePreviousBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;

  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (
      this.paginationObj.pageNumber == this.paginationObj.totalPages
    ) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }


}
