<div class="cms__content">
  <div class="top__heading sticky">
    <h3>Batch Jobs Management</h3>
  </div>

  <div class="col-12 list__summary">
    <div class="row">
      <!-- Actual cards for category -->
      <div class="col-12">
        <div class="row">
          <div class="col-6 col-md-3">
            <a [routerLink]="['/batch-jobs/transfer-subscribers-to-other-lco']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Transfer Subscribers to other LCO
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a
              [routerLink]="['/batch-jobs/activate-deactivate-subscribers-of-lco']"
            >
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Activate-Deactivate Subscribers of LCO
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a
              [routerLink]="['/batch-jobs/add-remove-packages-to-subscribers-of-lco']"
            >
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Add-Remove packages to Subscribers of LCO
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/batch-jobs/send-mails']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Send Mails
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a
              [routerLink]="['/batch-jobs/assign-subscribers-to-subscriber-group']"
            >
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Assign Subscribers to Subscriber Group
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/batch-jobs/assign-subscribers-to-lco']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Assign Subscribers to LCO
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/batch-jobs/assign-stb-to-lco']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Assign STBs to LCO
                </div>
              </div>
            </a>
          </div>

          <!-- <div class="col-6 col-md-3" >
            <a [routerLink]="['/batch-job/create-channels']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Create Channels
                </div>
              </div>
            </a>
          </div> -->

          <div class="col-6 col-md-3">
            <a [routerLink]="['/batch-jobs/create-broadcasters']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Create Broadcasters
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/batch-jobs/create-lcos']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Create LCOs
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/batch-jobs/create-locations']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Create Locations
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/batch-jobs/transfer-stbs-to-lco']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Transfer STBs to LCO
                </div>
              </div>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a [routerLink]="['/batch-jobs/create-bouquet']">
              <div class="list__card alt__card trai__report">
                <label class="safe__label">BATCH</label>
                <div class="card__txt">
                  Create Bouquet
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
