import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncatePipe'
})
export class TruncatePipePipe implements PipeTransform {

  transform(value: string): string {
    const values = value.split(',');
    const truncatedValues = values.slice(0, 3); // Take only the first three values
    return truncatedValues.join(',');
  }
}
