<div class="cms__content">
  <div class="top__heading">
    <h3>Locations</h3>
    <a [routerLink]="['/manage/location/create']"><button class="default__button float-right">+ Add New</button></a>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-6 col-md-3 list-sum-card">
        <div class="list__card">
          <span class="list__heading">Locations</span>
          <span class="list__item">{{locationCount}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="row">

      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" (keydown.enter)="onSearch()" class="custom__field" [(ngModel)]="paginationObj.searchKeyword" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()"><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>

      <div class="scroll-box">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th>
                Sl No.
              </th>
              <th (click)="sortByItem(sortByProperties.id)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.id}">
                ID
              </th>
              <!-- <th (click)="sortByItem(sortByProperties.country)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.country}">
                Country
              </th> -->
              <th (click)="sortByItem(sortByProperties.state)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.state}">
                State
              </th>
              <th (click)="sortByItem(sortByProperties.city)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.city}">
                City
              </th>
              <th (click)="sortByItem(sortByProperties.locality)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.locality}">
                Locality
              </th>
              <th (click)="sortByItem(sortByProperties.pin)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.pin}">
                PIN
              </th>
              <th (click)="sortByItem(sortByProperties.cdate)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.cdate}">
                Created On
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="loadingFlag; else showList">
              <td class="loading" colspan="7">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>

            <ng-template #showList>
              <tr *ngFor="let location of locationList; let i = index;">
                <td>{{((pageNumber-1)*pageSize)+(i+1)}}</td>
                <td>{{location.id? location.id : '---'}}</td>
                <!-- <td>{{location.country}}</td> -->
                <td>{{location.state? location.state : '---' }}</td>
                <td>{{location.city? location.city : '---'}}</td>
                <td>{{location.locality? location.locality : '---'}}</td>
                <td>{{location.pincode? location.pincode : '---'}}</td>
                <td>
                  {{location.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                  <div class="action__items">
                    <button (click)="edit(location)">Edit</button>
                    <!-- <button class="danger" (click)="deleteItem(location, i)">Delete</button> -->
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>

      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
