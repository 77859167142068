import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StbRoutingModule } from './stb-routing.module';
import { ListSTBComponent } from './list-stb/list-stb.component';
import { CreateSTBComponent } from './create-stb/create-stb.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    CreateSTBComponent,
    ListSTBComponent
  ],
  imports: [
    CommonModule,
    StbRoutingModule,
    SharedModule
  ],
  exports: [
    CreateSTBComponent,
    ListSTBComponent
  ]
})
export class StbModule { }
