import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-report-todays-expiry',
  templateUrl: './report-todays-expiry.component.html',
  styleUrls: ['./report-todays-expiry.component.scss']
})
export class ReportTodaysExpiryComponent implements OnInit {

  expiryReportList = [];

  currentDate = new Date();

  loadingFlag=false
  isLoad = 'Choose Date'

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config : ConfigService,
    private http : HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    this.fetchData()
  }

  fetchData():void{
    this.report.getTodaysExpiryReport().subscribe((data) => {
      this.expiryReportList = data.data
      // // console.log(data)
      if(this.expiryReportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });

  }

  downloadTableExcel(): void {
    let name='Expiring by Today Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'todays_expiry_report');
  }

  downloadTablePdf(): void {
    // const tempDate = new Date(this.selectedDate);
    // const monthYear = tempDate.toLocaleString('default', {month:'long'}) + " " + tempDate.getFullYear();
    // this.pdf.generatePdfHtml('#reportTable',
    // 'todays_expiry_report', 'Source Type : SMS'
    // );

    this.pdf.generatePDFForLongTable('todays_expiry_report', 'Source Type : SMS')
  }
  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.", "Subscription Code", "Subscriber Name", "Package Name", "Mobile Number", "Partner Name", "Start Date",
      "End Date"]
    };
    let data = []
    let i = 1
    this.expiryReportList.forEach(report => {
      let csvLine = {
        slNo : i,
        subscriberCode:report.subscriberCode,
        subscriberName: report.subscriberName,
        packageName : report.productName,
        mobileNumber : report.mobileNumber,
        partnerName : report.lcoName,
        startDate : report.startDate,
        endDate : report.endDate
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'todays_expiry_report', options);
  }

  searchKeyword(){
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms+'reports/getTodaysExpiryReports/?searchKeyword='+value
    this.http.get(url).subscribe((data)=>{
      this.loadingFlag = true
      this.expiryReportList = data.data
      this.loadingFlag = false
    })
  }

}
