import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountsRoutingModule } from './accounts-routing.module';
import { SubscriberRevenueComponent } from './subscriber-revenue/subscriber-revenue.component';
import { MsoPaymentsComponent } from './mso-payments/mso-payments.component';
import { SharedModule } from '../shared/shared.module';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceDataService } from './plugins/invoice-data.service';
import { SubscriptionModule } from '../subscription/subscription.module';

@NgModule({
  declarations: [
    SubscriberRevenueComponent,
    MsoPaymentsComponent,
    InvoiceDetailsComponent,
    InvoiceListComponent
  ],
  imports: [
    CommonModule,
    AccountsRoutingModule,
    SharedModule,
    SubscriptionModule
  ],
  providers: [
    InvoiceDataService
  ]
})
export class AccountsModule { }
