import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-home-new',
  templateUrl: './report-home-new.component.html',
  styleUrls: ['./report-home-new.component.scss']
})
export class ReportHomeNewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
