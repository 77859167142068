import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-osd-report',
  templateUrl: './osd-report.component.html',
  styleUrls: ['./osd-report.component.scss'],
})
export class OsdReportComponent implements OnInit {
  reportList = [];
  from: any;
  to: any;

  currentDate = new Date();

  selected: NgxDateRangeInfo;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };
  locale: any = {
    format: 'DD/MM/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'DD/MM/YYYY', // default is format value
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Select', // detault is 'Apply'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 0, // first day is monday
  };

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) {}

  isLoad = 'Choose Date';
  isLoading = false;
  ngOnInit(): void {
    // this.setInitialDate();
    this.fetchData();
  }

  // setInitialDate(): void {
  //   this.selected = this.calendar.getDate();
  //   this.fetchData(this.selected.startDate, this.selected.endDate);
  // }

  fetchData(): void {
    this.report.getOsdReport().subscribe(
      (data) => {
        this.isLoading = false;
        if(!(data.data)){
          this.isLoading = false;
          this.isLoad = 'No Records';
          this.reportList = []
          return
        }
        this.reportList = data.data;
        if (this.reportList?.length == 0) {
          this.isLoading = false;
          this.isLoad = 'No Records';
        } else {
          this.isLoading = true;
        }
      },
      () => {
        this.isLoad = 'No Records';
        this.isLoading = false;
      }
    );
  }

  // reportDate;
  // dateChange(ev: NgxDateRangeInfo): void {
  //   this.isLoad = 'Loading'
  //   this.isLoading = true
  //   if (ev.startDate !== null && ev.endDate !== null) {
  //     [this.from, this.to] = [ev.startDate, ev.endDate];
  //     this.reportDate = ev.startDate.date()+'-'+this.doc.monthNameCalculator(ev.startDate.month())+'-'+ev.startDate.year()+' to '+ev.endDate.date()+'-'+this.doc.monthNameCalculator(ev.endDate.month())+'-'+ev.endDate.year()
  //     this.calendar.setDate(ev);
  //     this.fetchData(ev.startDate, ev.endDate);
  //   }
  // }

  downloadTableExcel(): void {
    let name = 'OSD Report';
    this.doc.exportTableToExcelwithImage(this.reportTable, name, 'osd_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable', 'osd_report', `Source Type : SMS`);
  }
  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'Subscriber ID',
        'Read flag',
        'Message',
        'Date',
        'Last Updated',
      ],
    };
    let data = [];

    this.reportList.forEach((line) => {
      let csvLine = {
        subscriberIds: line.subscriberId === '0' ? 'ALL' : line.subscriberId,
        readFlag: line.readFlag,
        message: line.osdMessage ? line.osdMessage.message : '--',
        createdDate: line.createdDate,
        updatedDate: line.updatedDate,
      };
      data.push(csvLine);
    });

    new ngxCsv(data, 'osd_report', options);
  }

  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    let url =
      this.config.json.apiEndpointRdbms + 'osd/list?searchKeyword=' + value;
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data;
        this.isLoading = false;
        if (this.reportList.length == 0) {
          this.isLoad = 'No Records';
        } else {
          this.isLoading = true;
        }
      },
      () => {
        this.isLoad = 'No Records';
        this.isLoading = false;
      }
    );
  }
}
