<div class="cms__content">
  <div class="top__heading">
    <h1>OTA Manager</h1>
    <a routerLink="/settings/ota-manager/list"
    ><button class="default__button float-right">Back</button></a
  >
  </div>
  <div class="form">
    <form [formGroup]="otaForm">
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="">Environment</label>
          <select name="" class="custom__field" (focus)="environmentSelected()" (change)="environmentSelected()" id=""
            [ngClass]="{'error__field': select && environmentSelected()}" formControlName="environment">
            <option value="" selected="true" value="null" disabled>--Select--</option>
            <option value="public">Public</option>
            <option value="local">Local</option>
          </select>
          <div [hidden]="!(select && environmentSelected())">
            <span class="error__msg" *ngIf="select && environmentSelected()">Please select environment</span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for="">Device</label>
          <select name="" class="custom__field" 
          [ngClass]="{'error__field': !device.valid && device.touched}" formControlName="device">
            <option value="" selected="true" value="null" disabled>--Select--</option>
            <option value="Android">Android</option>
            <option value="FireTv">FireTv</option>
            <option value="WebOs">WebOs</option>
          </select>
          <!-- <input type="text" autocomplete="off" name="" id="" class="custom__field"
            [ngClass]="{'error__field': !device.valid && device.touched}" formControlName="device"> -->
          <div [hidden]="device.untouched">
            <span class="error__msg" *ngIf="device.hasError('required')">Please Enter Device</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="">Version Number</label>
          <input type="text " name="" id="" class="custom__field" formControlName="versionNumber"
            [ngClass]="{'error__field': !versionNumber.valid && versionNumber.touched}">
          <div [hidden]="versionNumber.untouched">
            <span class="error__msg" *ngIf="versionNumber.hasError('required')">Please enter Version Number</span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for="">Upload file</label>
          <input type="file" name="" id="" class="custom__field" (change)="uploadFile($event)">
          <input type="text" style="display: none;" formControlName="fileR" name="" id="">
          <div [hidden]="fileR.untouched">
            <span class="error__msg" *ngIf="fileR.hasError('required')">Please upload APK</span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for="">STB Type</label>
          <select name="" class="custom__field" id="" formControlName="stbType">
            <option value="hard">Hard STB</option>
            <option value="soft">Soft STB</option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <label for="">Update type</label>
          <select name="" class="custom__field" id="" formControlName="updateType">
            <option value="Optional">Optional</option>
            <option value="Mandatory">Mandatory</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="" class="not__required">Release Notes</label>
          <textarea name="" class="custom__field" id="" cols="50" rows="5" formControlName="releaseNotes"></textarea>
        </div>

      </div>
    </form>
    <button class="cta float-right" style="margin-top: 20px;" (click)="save()"
      [disabled]="otaFormValidity()">Save</button>
  </div>
</div>
<div class="loader" *ngIf="isApkUploadLoadingFlag">
  <app-custom-spinner></app-custom-spinner>
</div>