import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { LogsDataService } from '../plugins/logs-data.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'app-log-ad',
  templateUrl: './log-ad.component.html',
  styleUrls: ['./log-ad.component.scss']
})
export class LogAdComponent implements OnInit {
  reportList = [];
  from: any;
  to: any;
  currentDate = new Date();
  loadingFlag = false;
  isLoad = 'No Data';

  dateSelected = false;
  broadcasteSelected = false;
  ev;
  reportDate;
  paginationObj = new PaginationObj();
  disableFirstBtn = false;
  disablePreviousBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private configService: ConfigService,
    private http: HttpClientWrapperService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.loadingFlag = true
    this.reportList = []
  
    PaginationUtilities.giveConfig(this.configService.json.apiEndpoint)
    let url = PaginationUtilities.urlBuilder("ad/logs",this.paginationObj)
    this.http.get(url).subscribe({
      next: (data) => {
        this.handlePageMetadata(data.metadata);
        this.reportList = data.data;
        this.loadingFlag= false
      },
    });
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus()
  }




  downloadTableExcel(){
    let url

      url= this.configService.json.apiEndpoint+'generate/adsLogs?searchKeyword='+this.paginationObj.searchKeyword

      this.http.getwithresponseType(url).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'Ads_logs.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }

  // downloadTableExcel(): void {
  //   let name = 'Ads Logs';
  //   if (this.reportList.length == 0) {
  //     alert('No Data!!');
  //   } else {
  //     this.doc.exportTableToExcelwithImage(
  //       this.reportTable,
  //       name,
  //       'Ads Logs',
  //       undefined,
  //       undefined,
  //     );
  //   }
  // }

  downloadTablePdf(): void {
    if (this.reportList.length == 0) {
      alert('No Data!!');
    } else {
      this.pdf.generatePdfHtml(
        '#reportTable',
        'Ads_logs',
        'portrait'
      );
    }
  }

  adDetails;
  viewModal = false;
  viewAdsDetails(ads: any) {
        this.adDetails = ads
        this.viewModal = true;
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Sl No',
        'Ad Name',
        'LCO Name',
        'Uploaded By',
        'Action',
        'Date',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        adName: line.adName ? line.adName : '---',
        lcoName: line.lcoName ? line.lcoName : '---',
        uploadedBy: line.uploadedBy ? line.uploadedBy : '---',
        action: line.action ? line.action : '---',
        createdDate: line.createdDate ? line.createdDate : '---',
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'Ads_Logs', options);
  }


  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (
      this.paginationObj.pageNumber == this.paginationObj.totalPages
    ) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }
}
