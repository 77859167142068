import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PaginationObj } from 'src/app/models/pagination/pagination-info';

@Component({
  selector: 'app-logs-complete',
  templateUrl: './logs-complete.component.html',
  styleUrls: ['./logs-complete.component.scss'],
})
export class LogsCompleteComponent implements OnInit {
  reportList = [];
  categoryList: any;

  // commandList: commandInfo[] = [];
  selectedCommand: string;

  // macAddressList: stbMacInfo[] = []
  selectedStbMacAddress: string;

  from: any;
  to: any;
  selectedCategory;
  currentDate = new Date();
  paginationObjcompletelog = new PaginationObj();
  user;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) {
    this.paginationObjcompletelog.pageSize = 10;
  }

  dateAlreadyCalculated = false;
  userId
  ngOnInit(): void {
    // this.fetchMacAddressList()
    if(this.config.json.userType=='MSO'){
      this.user=true
    }
    else{
      this.user=false
    }
    this.userId=JSON.parse(localStorage.getItem('profileInfo')).id
    this.fetchCategoryList();
    this.fetchDataOnInit();
    this.initPaginationOpt();
    if (!this.dateAlreadyCalculated) {
      this.yearsArrayCalculator();
    }
  }

  yearsArray = [];
  yearsArrayCalculator() {
    console.log('called');

    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2019; i--) {
      // // console.log(i)
      this.yearsArray.push(i);
    }
    this.dateAlreadyCalculated = true;
  }

  yearFilter() {
    if(this.user==true){
    this.reportList = [];
    let year = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    let fromDate = `${year}-01-01`;
    let toDate = `${year}-12-31`;
    this.eve.fromDate = fromDate;
    this.eve.toDate = toDate;
    const url =
      this.config.json.apiEndpointRdbms +
      'logs/getCompleteHistory/' +
      this.paginationObjcompletelog.pageNumber +
      '?pageSize=' +
      this.paginationObjcompletelog.pageSize +
      '&sortBy=' +
      this.paginationObjcompletelog.sortBy +
      '&sortOrder=' +
      this.paginationObjcompletelog.sortOrder +
      '&searchKeyword=' +
      this.paginationObjcompletelog.searchKeyword +
      '&fromDate=' +
      fromDate +
      '&toDate=' +
      toDate +
      '&category=' +
      this.selectedCategory;
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data.data.data;
        // // console.log(this.reportList)
        this.isLoading = false;
        if (data.data.data.length == 0) {
          this.isLoading = true;
          this.isLoad = 'No records';
          // // console.log(this.isLoading, this.isLoad)
        }
      },
      () => {
        this.isLoad = 'No Records';
      }
    );
  }
  else{
    this.reportList = [];
    let year = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    let fromDate = `${year}-01-01`;
    let toDate = `${year}-12-31`;
    this.eve.fromDate = fromDate;
    this.eve.toDate = toDate;
    const url =
      this.config.json.apiEndpointRdbms +
      'logs/getCompleteHistoryForLcoOrDistributor/' +
      this.paginationObjcompletelog.pageNumber +
      '?pageSize=' +
      this.paginationObjcompletelog.pageSize +
      '&sortBy=' +
      this.paginationObjcompletelog.sortBy +
      '&sortOrder=' +
      this.paginationObjcompletelog.sortOrder +
      '&searchKeyword=' +
      this.paginationObjcompletelog.searchKeyword +
      '&fromDate=' +
      fromDate +
      '&toDate=' +
      toDate +
      '&category=' +
      this.selectedCategory+
      '&userType='+this.config.json.userType+
      '&userId='+this.userId;
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data.data.data;
        // // console.log(this.reportList)
        this.isLoading = false;
        if (data.data.data.length == 0) {
          this.isLoading = true;
          this.isLoad = 'No records';
          // // console.log(this.isLoading, this.isLoad)
        }
      },
      () => {
        this.isLoad = 'No Records';
      }
    );
  }
  }

  initPaginationOpt(): void {
    this.paginationObjcompletelog.pageSize = 70000;
  }
  fetchCategoryList(): void {
    let userType=this.config.json.userType
    this.report.getCategoryList(userType).subscribe(
      (data) => {
        // Convenient to map the response directly, since we know response is list of JSON objects
        this.categoryList = data.data.category;
        this.isLoading = false;
      },
      () => {
        this.isLoad = 'No Records';
      }
    );
  }

  showMonth = false;
  showDateRange = false;
  dateSelector = true;
  tableDisplay = true;
  dateTypeSelectors(num) {
    if (num == 0) {
      this.dateSelector = true;
      this.showMonth = false;
      this.showDateRange = false;
      this.tableDisplay = true;
    }
    if (num == 1) {
      this.dateSelector = false;
      this.showMonth = true;
      this.showDateRange = false;
      this.tableDisplay = true;
    }
    if (num == 2) {
      this.dateSelector = false;
      this.showDateRange = true;
      this.showMonth = false;
      this.tableDisplay = false;
    }

    this.fetchDataOnInit();
  }

  listItem(): void {
    if(this.user==true){
    if (this.selectedCategory == 0) {
      this.selectedCategory = 0;
      let from = '2018-01-01';
      let dateObj = new Date();
      let to =
        dateObj.getFullYear() +
        '-' +
        (dateObj.getMonth() + 1) +
        '-' +
        dateObj.getDate();
      const url =
        this.config.json.apiEndpointRdbms +
        'logs/getCompleteHistory/' +
        this.paginationObjcompletelog.pageNumber +
        '?pageSize=' +
        this.paginationObjcompletelog.pageSize +
        '&sortBy=' +
        this.paginationObjcompletelog.sortBy +
        '&sortOrder=' +
        this.paginationObjcompletelog.sortOrder +
        '&searchKeyword=' +
        this.paginationObjcompletelog.searchKeyword +
        '&fromDate=' +
        from +
        '&toDate=' +
        this.formatDate(to) +
        '&category=' +
        this.selectedCategory;
      this.http.get(url).subscribe(
        (data: any) => {
          console.log(data)
          this.reportList = data.data.data;
          // this.reportList.push(data.data.data)

          // for (let i = 2; i <= data.data.metadata.totalPageCount; i++) {
          //   const url =
          //     this.config.json.apiEndpointRdbms +
          //     'logs/getCompleteHistory/' +
          //     i +
          //     '?pageSize=' +
          //     this.paginationObjcompletelog.pageSize +
          //     '&sortBy=' +
          //     this.paginationObjcompletelog.sortBy +
          //     '&sortOrder=' +
          //     this.paginationObjcompletelog.sortOrder +
          //     '&searchKeyword=' +
          //     this.paginationObjcompletelog.searchKeyword +
          //     '&fromDate=' +
          //     from +
          //     '&toDate=' +
          //     to +
          //     '&category=' +
          //     this.selectedCategory;
          //   this.http.get(url).subscribe(
          //     (data: any) => {
          //       // this.reportList = data.data.data;
          //       this.reportList.push(...data.data.data);
          //       this.isLoading = false;
          //     },
          //     () => {
          //       this.isLoad = 'No Records';
          //     }
          //   );
          // }
          // // console.log(this.reportList)
          this.isLoading = false;
          this.handlePageMetadata(data.data.metadata);
        },
        () => {
          this.isLoad = 'No Records';
        }
      );
    } else {
      let dateObj = new Date();
      let toDate =
        dateObj.getFullYear() +
        '-' +
        (dateObj.getMonth() + 1) +
        '-' +
        dateObj.getDate();
      this.fetchData(this.eve.fromDate, this.eve.toDate);
    }}
    else{
      if (this.selectedCategory == 0) {
        this.selectedCategory = 0;
        let from = '2018-01-01';
        let dateObj = new Date();
        let to =
          dateObj.getFullYear() +
          '-' +
          (dateObj.getMonth() + 1) +
          '-' +
          dateObj.getDate();
        const url =
          this.config.json.apiEndpointRdbms +
          'logs/getCompleteHistoryForLcoOrDistributor/' +
          this.paginationObjcompletelog.pageNumber +
          '?pageSize=' +
          this.paginationObjcompletelog.pageSize +
          '&sortBy=' +
          this.paginationObjcompletelog.sortBy +
          '&sortOrder=' +
          this.paginationObjcompletelog.sortOrder +
          '&searchKeyword=' +
          this.paginationObjcompletelog.searchKeyword +
          '&fromDate=' +
          from +
          '&toDate=' +
          this.formatDate(to) +
          '&category=' +
          this.selectedCategory+
          '&userType='+this.config.json.userType+
          '&userId='+this.userId;
        this.http.get(url).subscribe(
          (data: any) => {
            // // console.log(data)
            this.reportList = data.data.data;
            this.handlePageMetadata(data.data.metadata);
            // this.reportList.push(data.data.data)
  
            // for (let i = 2; i <= data.data.metadata.totalPageCount; i++) {
            //   const url =
            //     this.config.json.apiEndpointRdbms +
            //     'logs/getCompleteHistoryForLcoOrDistributor/' +
            //     i +
            //     '?pageSize=' +
            //     this.paginationObjcompletelog.pageSize +
            //     '&sortBy=' +
            //     this.paginationObjcompletelog.sortBy +
            //     '&sortOrder=' +
            //     this.paginationObjcompletelog.sortOrder +
            //     '&searchKeyword=' +
            //     this.paginationObjcompletelog.searchKeyword +
            //     '&fromDate=' +
            //     from +
            //     '&toDate=' +
            //     to +
            //     '&category=' +
            //     this.selectedCategory+'&userType='+this.config.json.userType+
            //     '&userId='+this.userId;
            //   this.http.get(url).subscribe(
            //     (data: any) => {
            //       // this.reportList = data.data.data;
            //       this.reportList.push(...data.data.data);
            //       this.isLoading = false;
            //     },
            //     () => {
            //       this.isLoad = 'No Records';
            //     }
            //   );
            // }
            // // console.log(this.reportList)
            this.isLoading = false;
          },
          () => {
            this.isLoad = 'No Records';
          }
        );
      } else {
        let dateObj = new Date();
        let toDate =
          dateObj.getFullYear() +
          '-' +
          (dateObj.getMonth() + 1) +
          '-' +
          dateObj.getDate();
        this.fetchData(this.eve.fromDate, this.eve.toDate);
      }
    }
  }

  fetchData(from: any, to: any): void {
    if(this.user==true){
    this.reportList = [];
    const url =
      this.config.json.apiEndpointRdbms +
      'logs/getCompleteHistory/' +
      this.paginationObjcompletelog.pageNumber +
      '?pageSize=' +
      this.paginationObjcompletelog.pageSize +
      '&sortBy=' +
      this.paginationObjcompletelog.sortBy +
      '&sortOrder=' +
      this.paginationObjcompletelog.sortOrder +
      '&searchKeyword=' +
      this.paginationObjcompletelog.searchKeyword +
      '&fromDate=' +
      from +
      '&toDate=' +
      to +
      '&category=' +
      this.selectedCategory;
    this.http.get(url).subscribe(
      (data: any) => {
        // // console.log(data)
        this.reportList = data.data.data;
        // // console.log(this.reportList)
        this.handlePageMetadata(data.data.metadata);
        // const dataArray = Object.keys(this.reportList).map(key => ({key, value: dataObject[key]}));
        this.isLoading = false;
        if (data.data.data.length == 0) {
          this.isLoading = true;
          this.isLoad = 'No records';
        }
      },
      () => {
        this.isLoad = 'No Records';
      }
    );
  }
  else{
    this.reportList = [];
    const url =
      this.config.json.apiEndpointRdbms +
      'logs/getCompleteHistoryForLcoOrDistributor/' +
      this.paginationObjcompletelog.pageNumber +
      '?pageSize=' +
      this.paginationObjcompletelog.pageSize +
      '&sortBy=' +
      this.paginationObjcompletelog.sortBy +
      '&sortOrder=' +
      this.paginationObjcompletelog.sortOrder +
      '&searchKeyword=' +
      this.paginationObjcompletelog.searchKeyword +
      '&fromDate=' +
      from +
      '&toDate=' +
      to +
      '&category=' +
      this.selectedCategory+'&userType='+this.config.json.userType+
      '&userId='+this.userId;
    this.http.get(url).subscribe(
      (data: any) => {
        // // console.log(data)
        this.reportList = data.data.data;
        // // console.log(this.reportList)
        this.handlePageMetadata(data.data.metadata);
        // const dataArray = Object.keys(this.reportList).map(key => ({key, value: dataObject[key]}));
        this.isLoading = false;
        if (data.data.data.length == 0) {
          this.isLoading = true;
          this.isLoad = 'No records';
        }
      },
      () => {
        this.isLoad = 'No Records';
      }
    );
  }
  }

  handlePageMetadata(data: any) {
    this.paginationObjcompletelog.pageNumber = data.pageNumber;
    this.paginationObjcompletelog.pageSize = data.pageSize;
    this.paginationObjcompletelog.totalElements = data.totalElementCount;
    this.paginationObjcompletelog.totalPages = data.totalPageCount;
    this.paginationObjcompletelog.searchKeyword = data.searchKeyword;
    this.handleButtonDisableStatus()
  }

  fetchDataOnInit(): void {
    if(this.user==true){
    this.reportList = [];
    this.isLoading = false;
    this.selectedCategory = 0;
    let dateObj = new Date();
    let toDate =
      dateObj.getFullYear() +
      '-' +
      (dateObj.getMonth() + 1) +
      '-' +
      dateObj.getDate();
    this.eve.fromDate = '2018-01-01';
    this.eve.toDate = toDate;
    const url =
      this.config.json.apiEndpointRdbms +
      'logs/getCompleteHistory/' +
      this.paginationObjcompletelog.pageNumber +
      '?pageSize=' +
      this.paginationObjcompletelog.pageSize +
      '&sortBy=' +
      this.paginationObjcompletelog.sortBy +
      '&sortOrder=' +
      this.paginationObjcompletelog.sortOrder +
      '&searchKeyword=' +
      this.paginationObjcompletelog.searchKeyword +
      '&fromDate=' +
      '2018-01-01' +
      '&toDate=' +
      toDate +
      '&category=' +
      this.selectedCategory;
    this.http.get(url).subscribe(
      (data: any) => {
        // // console.log(data)
        this.reportList = data.data.data;
        this.handlePageMetadata(data.data.metadata);
        this.isLoading = false;
        if (data.data.data.length == 0) {
          this.isLoading = true;
          this.isLoad = 'No records';
        }
      },
      () => {
        this.isLoad = 'No Records';
      }
    );
  }
  else{
    this.reportList = [];
    this.isLoading = false;
    this.selectedCategory = 0;
    let dateObj = new Date();
    let toDate =
      dateObj.getFullYear() +
      '-' +
      (dateObj.getMonth() + 1) +
      '-' +
      dateObj.getDate();
    this.eve.fromDate = '2018-01-01';
    this.eve.toDate = toDate;
    const url =
      this.config.json.apiEndpointRdbms +
      'logs/getCompleteHistoryForLcoOrDistributor/' +
      this.paginationObjcompletelog.pageNumber +
      '?pageSize=' +
      this.paginationObjcompletelog.pageSize +
      '&sortBy=' +
      this.paginationObjcompletelog.sortBy +
      '&sortOrder=' +
      this.paginationObjcompletelog.sortOrder +
      '&searchKeyword=' +
      this.paginationObjcompletelog.searchKeyword +
      '&fromDate=' +
      '2018-01-01' +
      '&toDate=' +
      toDate +
      '&category=' +
      this.selectedCategory+'&userType='+this.config.json.userType+
      '&userId='+this.userId;
    this.http.get(url).subscribe(
      (data: any) => {
        // // console.log(data)
        this.reportList = data.data.data;
        this.handlePageMetadata(data.data.metadata);
        this.isLoading = false;
        if (data.data.data.length == 0) {
          this.isLoading = true;
          this.isLoad = 'No records';
        }
      },
      () => {
        this.isLoad = 'No Records';
      }
    );
  }
  }
  // changeMacAddress(){}

  // changeCommand(){}

  // isLoad = 'Choose Date'
  // isLoading = false
  // dateChange(ev: NgxDateRangeInfo): void {
  //   if (ev.startDate !== null && ev.endDate !== null) {
  //     [this.from, this.to] = [ev.startDate, ev.endDate];
  //     this.calendar.setDate(ev);
  //     this.fetchData(ev.startDate, ev.endDate);
  //     this.isLoading = true
  //   }
  // }

  // downloadTableExcel(): void {
  //   let name = 'Complete Logs';
  //   this.doc.exportTableToExcelwithImage(
  //     this.reportTable,
  //     name,
  //     'complete_logs'
  //   );
  // }


  downloadTableExcel(){
    let url
    if(this.config.json.userType=='MSO'){

      url= this.config.json.apiEndpoint+'generate/completeHistory?searchKeyword=&category='+this.selectedCategory
      if (this.from != undefined && this.to != undefined) {
        url = url + "&fromDate=" + this.from + "&toDate=" + this.to;
      }
    }
      else{
        url= this.config.json.apiEndpoint+'generate/getCompleteHistoryForLcoOrDistributor?searchKeyword=&'
      +'category='+this.selectedCategory+'&userType='+this.config.json.userType+'&userId='+this.userId;
        if (this.eve.fromDate != undefined && this.eve.toDate != undefined) {
          url = url + "&fromDate=" + this.eve.fromDate + "&toDate=" + this.eve.toDate;
        }
        
      }
      this.http.getwithresponseType(url).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'complete_logs.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }

  downloadTablePdf(): void {
    this.formatDate(this.from) == null
      ? console.log('null!!')
      : console.log(this.formatDate(this.from));

    if (this.formatDate(this.eve.fromDate) == null) {
      this.pdf.generatePdfHtml(
        '#reportTable',
        'complete_logs',
        `Date : Till Now`
      );
    } else {
      this.pdf.generatePdfHtml(
        '#reportTable',
        'complete_logs',
        `Date : ${this.formatDate(this.eve.fromDate)} ${
          this.formatDate(this.eve.toDate) == null
            ? ''
            : '-' + this.formatDate(this.eve.toDate)
        }`
      );
    }
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ['Sl No', 'Category', 'Description', 'Date'],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slNo: i,
        category: line.category,
        description: line.description ? line.description : line.DESCRIPTION,
        date: line.transactionDate,
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'Complete_Logs', options);
  }

  searchKeyword() {
    if(this.user == true){
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    // this.selectedCategory = 0
    let dateObj = new Date();
    let toDate =
      dateObj.getFullYear() +
      '-' +
      (dateObj.getMonth() + 1) +
      '-' +
      dateObj.getDate();
    if (this.eve.fromDate != '' && this.eve.toDate != '') {
      var url =
        this.config.json.apiEndpointRdbms +
        'logs/getCompleteHistory/' +
        this.paginationObjcompletelog.pageNumber +
        '?pageSize=' +
        this.paginationObjcompletelog.pageSize +
        '&sortBy=' +
        this.paginationObjcompletelog.sortBy +
        '&sortOrder=' +
        this.paginationObjcompletelog.sortOrder +
        '&searchKeyword=' +
        value +
        '&fromDate=' +
        this.eve.fromDate +
        '&toDate=' +
        this.eve.toDate +
        '&category=' +
        this.selectedCategory;
    } else {
      var url =
        this.config.json.apiEndpointRdbms +
        'logs/getCompleteHistory/' +
        this.paginationObjcompletelog.pageNumber +
        '?pageSize=' +
        this.paginationObjcompletelog.pageSize +
        '&sortBy=' +
        this.paginationObjcompletelog.sortBy +
        '&sortOrder=' +
        this.paginationObjcompletelog.sortOrder +
        '&searchKeyword=' +
        value +
        '&fromDate=2018-01-01' +
        '&toDate=' +
        toDate +
        '&category=' +
        this.selectedCategory;
    }

    this.http.get(url).subscribe(
      (data: any) => {
        this.reportList = data.data.data;
        this.isLoading = false;
        if (data.data.data.length == 0) {
          this.isLoading = true;
          this.isLoad = 'No records';
          // // console.log(this.isLoading, this.isLoad)
        }
      },
      () => {
        this.isLoad = 'No Records';
      }
    );
  }
  else{
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    // this.selectedCategory = 0
    let dateObj = new Date();
    let toDate =
      dateObj.getFullYear() +
      '-' +
      (dateObj.getMonth() + 1) +
      '-' +
      dateObj.getDate();
    if (this.eve.fromDate != '' && this.eve.toDate != '') {
      var url =
        this.config.json.apiEndpointRdbms +
        'logs/getCompleteHistoryForLcoOrDistributor/' +
        this.paginationObjcompletelog.pageNumber +
        '?pageSize=' +
        this.paginationObjcompletelog.pageSize +
        '&sortBy=' +
        this.paginationObjcompletelog.sortBy +
        '&sortOrder=' +
        this.paginationObjcompletelog.sortOrder +
        '&searchKeyword=' +
        value +
        '&fromDate=' +
        this.eve.fromDate +
        '&toDate=' +
        this.eve.toDate +
        '&category=' +
        this.selectedCategory+'&userType='+this.config.json.userType+
        '&userId='+this.userId;
    } else {
      var url =
        this.config.json.apiEndpointRdbms +
        'logs/getCompleteHistoryForLcoOrDistributor/' +
        this.paginationObjcompletelog.pageNumber +
        '?pageSize=' +
        this.paginationObjcompletelog.pageSize +
        '&sortBy=' +
        this.paginationObjcompletelog.sortBy +
        '&sortOrder=' +
        this.paginationObjcompletelog.sortOrder +
        '&searchKeyword=' +
        value +
        '&fromDate=2018-01-01' +
        '&toDate=' +
        toDate +
        '&category=' +
        this.selectedCategory+'&userType='+this.config.json.userType+
        '&userId='+this.userId;
    }

    this.http.get(url).subscribe(
      (data: any) => {
        this.reportList = data.data.data;
        this.isLoading = false;
        if (data.data.data.length == 0) {
          this.isLoading = true;
          this.isLoad = 'No records';
          // // console.log(this.isLoading, this.isLoad)
        }
      },
      () => {
        this.isLoad = 'No Records';
      }
    );
  }
  }

  isLoad = 'Choose Date';
  isLoading = false;
  eve = { fromDate: '', toDate: '' };
  dateChangeRange(ev) {
    let startDate =
      ev.startDate.year() +
      '-' +
      (ev.startDate.month() + 1) +
      '-' +
      ev.startDate.date();
    let endDate =
      ev.endDate.year() +
      '-' +
      (ev.endDate.month() + 1) +
      '-' +
      ev.endDate.date();
    this.eve.fromDate = startDate;
    this.eve.toDate = endDate;
    // let body = {
    //   'fromDate' : startDate,
    //   'toDate' : endDate,
    //   'tillDate' : ''
    // }
    // // console.log(body)
    this.fetchData(this.eve.fromDate, this.eve.toDate);
  }

  formatDate(ele) {
    console.log(ele);
    if (ele == undefined) {
      return null;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  handleButtonDisableStatus(){
    if(this.paginationObjcompletelog.pageNumber == 1){
      this.disableFirstBtn = true
      this.disablePreviousBtn = true
      this.disableLastBtn = false;
      this.disableNextBtn = false
    }
    if(this.paginationObjcompletelog.pageNumber == this.paginationObjcompletelog.totalPages){
      this.disableFirstBtn = false
      this.disablePreviousBtn = false
      this.disableLastBtn = true;
      this.disableNextBtn = true
    }
    if(this.paginationObjcompletelog.pageNumber != 1  && this.paginationObjcompletelog.pageNumber != this.paginationObjcompletelog.totalPages){
      this.disableFirstBtn = false
      this.disableLastBtn = false
      this.disableNextBtn = false
      this.disablePreviousBtn = false
    }
  }

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  searchKeyWord() {
    this.paginationObjcompletelog.pageNumber = 1;
    this.fetchData(
      this.formatDate(this.eve.fromDate),
      this.formatDate(this.eve.toDate)
    );
  }

  pageSizeChange() {
    this.fetchData(
      this.formatDate(this.eve.fromDate),
      this.formatDate(this.eve.toDate)
    );
  }

  pageFirstBtnClick() {
    this.paginationObjcompletelog.pageNumber = 1;
    this.fetchData(
      this.formatDate(this.eve.fromDate),
      this.formatDate(this.eve.toDate)
    );
  }

  pageLastBtnClick() {
    this.paginationObjcompletelog.pageNumber =
      this.paginationObjcompletelog.totalPages;
    this.fetchData(
      this.formatDate(this.eve.fromDate),
      this.formatDate(this.eve.toDate)
    );
  }

  pageIncrease() {
    this.paginationObjcompletelog.pageNumber++;
    this.fetchData(
      this.formatDate(this.eve.fromDate),
      this.formatDate(this.eve.toDate)
    );
  }

  pageDecrease() {
    if (this.paginationObjcompletelog.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObjcompletelog.pageNumber--;
    this.fetchData(
      this.formatDate(this.eve.fromDate),
      this.formatDate(this.eve.toDate)
    );
  }
}
