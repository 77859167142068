<div class="cms__content">
  <app-reports-header></app-reports-header>
  <div class="top__heading">
    <h3>Subscribers List Report</h3>
    <a [routerLink]="['/reports/trai']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="clearfix"></div>
  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <!-- <app-date-picker (dateChange)="dateChange($event)"></app-date-picker> -->

      <div class="col-12 col-md-6 report_selection_block">
        <label class="report_selection_label">Status</label>
        <select
          class="custom__field report_selection"
          id="statusChangeApi"
          (change)="statusChanger()"
        >
          <option value="5">ALL</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
          <option value="4">Terminated</option>
          <option value="3">New</option>
          <option value="2">Blacklisted</option>
        </select>
      </div>
    </div>
    <div class="download__report col-md-6">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6 report">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <div style="display: flex; gap:20px" >
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
            <th>Sl no</th>
            <th>Subscriber Code</th>
            <th>Name</th>
            <!-- <th>CAF Number</th> -->
            <th>City</th>
            <th>State Name</th>
            <th>Phone Number</th>
            <th>Packages</th>
            <th>Status</th>
            <th>MAC Address</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngIf="reportList.length ==0" >
            <td style="text-align: center !important;" colspan="11">
              <app-custom-spinner></app-custom-spinner>
              </td>
          </tr> -->
          <tr *ngIf="reportList.length == 0 && loadingFlag">
            <td style="text-align: center !important" colspan="10">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <tr *ngIf="!loadingFlag && reportList.length == 0">
            <td style="text-align: center !important" colspan="10">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngFor="let report of reportList; let j = index">
            <td>{{ j + 1 }}</td>
            <td>{{ report.subscriberCode ? report.subscriberCode : "---" }}</td>
            <td style="text-wrap: nowrap; word-break: keep-all;" >
              {{
                report.Name || report["NAME"]
                  ? report.Name || report["NAME"]
                  : "---"
              }}
            </td>
            <!-- <td>{{ report.cafNumber ? report.cafNumber : "---" }}</td> -->
            <td>{{ report.city ? report.city : "---" }}</td>
            <td>{{ report.stateName ? report.stateName : "---" }}</td>
            <td>{{ report.mobileNumber ? report.mobileNumber : "---" }}</td>
            <td style="overflow-wrap: break-word;" >{{ report.packages ? report.packages : "No Subscription" }}</td>
            <td >
              {{
                report.status == 0
                  ? "Inactive"
                  : report.status == 1
                  ? "Active"
                  : report.status == 2
                  ? "Blacklisted"
                  : report.status == 3
                  ? "New"
                  : "Terminated"
              }}
            </td>
            <td style="word-wrap: break-word; word-break: break-all;" >
              {{
                report.ethernetMacAddress ? report.ethernetMacAddress : "---"
              }}
            </td>
          </tr>
          <tr *ngIf="!loadingFlag && reportList.length === []">
            <td style="text-align: center !important" colspan="11">
              No Records.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
