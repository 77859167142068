import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import MENUS_TO_REMOVE from './menu';
@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  constructor(
    private router: Router,
    private auth: AuthService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  menuArray = [];
  isNotMSO = false;
  ngOnInit(): void {
    this.getMenuFromApi();
    this.isNotMSO = !this.auth.isMSO();
    if (this.isNotMSO) {
      this.getWalletData();
    }


  }


  isOnSeparatePage(): boolean {
    if (this.router.url.includes('self-registration')) {
      return true
    }
    else if (this.router.url.includes('leadGenerationPaymentFailure')) {
      return true
    }
    else if (this.router.url.includes('leadGenerationPaymentSuccess')) {
      return true
    }
    return false
  }

  gotoDashboard() {
    this.router.navigate(['/dashboard']);
    this.applyStylesForMenu(this.menuArray.length + 1, 0)
  }

  amount = 0;
  getWalletData() {
    let id = this.auth.getLcoId();
    let user;
    if (this.auth.isLCO()) {
      user = 'lco';
    }
    if (this.auth.isDistributor()) {
      user = 'distributor';
    }
    let url = `${this.config.json.apiEndpoint}wallet/${user}?${user}Id=${id}`;
    this.http.get(url).subscribe({
      next: (res) => {
        this.amount = res.data.amount;
      },
    });
  }

  getMenuFromApi() {
    const url = this.config.json.apiEndpointRdbms + 'menu/getMenus';
    this.http.get(url).subscribe({
      next: (res) => {
        this.getMenu(res);
      },
      error: () => {
        this.auth.logout();
      },
    });
  }

  getMenu(menuObj) {
    let parentObj: any = {};
    for (let ele of menuObj) {
      if (ele.parentId == 0) {
        parentObj[ele.id] = { ...ele, children: [] };
      }
    }
    for (let ele of menuObj) {
      if (ele.parentId != 0) {
        parentObj[ele.parentId].children.push(ele);
      }
    }
    for (let ele of Object.keys(parentObj)) {
      this.menuArray.push(parentObj[ele]);
    }
    console.log(this.menuArray);

    this.filterMenu();
  }

  lcoMainMenu = MENUS_TO_REMOVE.lcoMainMenu;
  lcoSubMenu = MENUS_TO_REMOVE.lcoSubMenu;
  msoMainMenu = MENUS_TO_REMOVE.msoMainMenu;
  msoSubMenu = MENUS_TO_REMOVE.msoSubMenu;
  distributorMainMenu = MENUS_TO_REMOVE.distributorMainMenu;
  distributorSubMenu = MENUS_TO_REMOVE.distributorSubMenu;
  filterMenu() {
    let arrayOfObjects = JSON.parse(JSON.stringify(this.menuArray)) as any[];
    let mainMenuArrayofStrings;
    let subMenuArrayofStrings;
    if (this.auth.isLCO()) {
      mainMenuArrayofStrings = this.lcoMainMenu;
      subMenuArrayofStrings = this.lcoSubMenu;
    }
    if (this.auth.isMSO()) {
      mainMenuArrayofStrings = this.msoMainMenu;
      subMenuArrayofStrings = this.msoSubMenu;
    }
    if (this.auth.isDistributor()) {
      mainMenuArrayofStrings = this.distributorMainMenu;
      subMenuArrayofStrings = this.distributorSubMenu;
    }
    const mainFilteredArray = arrayOfObjects.filter(
      (obj) => !mainMenuArrayofStrings.includes(obj.name)
    );
    for (let ele of mainFilteredArray) {
      const subFilteredArray = ele.children.filter(
        (obj) => !subMenuArrayofStrings.includes(obj.name)
      );
      ele.children = subFilteredArray;
    }
    this.menuArray = JSON.parse(JSON.stringify(mainFilteredArray));
  }

  clickOutsideListener = false;
  openedSubMenuIndex;
  openMenu(index: number, menu: any) {
    this.openedSubMenuIndex = index;

    let ulEnd = document.getElementById(
      'profileSubMenuMobile'
    ) as HTMLUListElement;
    ulEnd.style.display = 'none';
    for (let i = 1; i < this.menuArray.length; i++) {
      let ul = document.getElementById('subMenu' + i);
      if (this.menuArray[i].children.length > 0) {
        if (i != index) {
          ul.style.display = 'none';
        } else {
          ul.style.display = ul.style.display == 'block' ? 'none' : 'block';
        }
      }
    }
    this.clickOutsideListener = true;
    if (menu.children.length == 0) {
      this.openMainMenu(menu.name, index);
      this.screenWidthDetect();
    }
  }

  screenWidthDetect() {
    if (window.innerWidth < 768) {
      this.openHambuger();
    }
  }

  openMainMenu(mainMenu: string, mainMenuIndex: number) {
    switch (mainMenu) {
      case 'Logs':
        this.router.navigate(['/logs']);
        break;
      case 'Batch Jobs':
        this.router.navigate(['/batch-jobs']);
        break;
      default:
        this.router.navigate(['/dashboard']);
        break;
    }
    this.applyStylesForMenu(mainMenuIndex, 0)
  }

  openSubMenu(subMenu: String, maninMenuIndex: number, subMenuIndex: number) {
    switch (subMenu) {
      case 'MSO':
        this.router.navigate(['/manage/mso']);
        break;
      case 'LCO':
        this.router.navigate(['/manage/lco']);
        break;
      case 'Distributor':
        this.router.navigate(['/manage/distributor']);
        break;
      case 'Bouquet':
        this.router.navigate(['/content/bouquet']);
        break;
      case 'Subscriber':
        this.router.navigate(['/manage/subscriber']);
        break;
      case 'STB':
        this.router.navigate(['/manage/stb']);
        break;
      case 'Subscriber Groups':
        this.router.navigate(['/manage/subscriber-groups']);
        break;
      case 'Location':
        this.router.navigate(['/manage/location']);
        break;
      case 'Bouquet Management':
        this.router.navigate(['/manage/bouquet-management']);
        break;
      case 'Form Request':
        this.router.navigate(['/manage/form-request/list-selfregistered']);
        break;
      case 'Broadcaster':
        this.router.navigate(['/content/broadcaster']);
        break;

      case 'Channel':
        this.router.navigate(['/content/channel']);
        break;
      case 'Category':
        this.router.navigate(['/content/category']);
        break;
      case 'Bouquet':
        this.router.navigate(['/content/bouquet']);
        break;
      case 'Statewise LCN':
        this.router.navigate(['/content/statewise-lcn']);
        break;
      case 'Mail':
        this.router.navigate(['/communication/mail']);
        break;
      case 'OSD':
        this.router.navigate(['/communication/osd']);
        break;
      case 'Fingerprint':
        this.router.navigate(['/communication/fingerprint']);
        break;
      case 'Add Subscription':
        this.router.navigate(['/subscription/add-subscription']);
        break;
      case 'Renew Subscription':
        this.router.navigate(['/subscription/renew-subscription']);
        break;
      case 'Remove Subscription':
        this.router.navigate(['/subscription/remove-subscription']);
        break;
      case 'Remove Subscription':
        this.router.navigate(['/subscription/remove-subscription']);
        break;
      case 'TRAI':
        this.router.navigate(['/reports/trai']);
        break;

      case 'Custom Reports':
        this.router.navigate(['/reports/custom-reports']);
        break;

      case 'General Settings':
        this.router.navigate(['/settings/general-settings']);
        break;
      case 'Access Control':
        this.router.navigate(['/settings/access-control']);
        break;
      case 'Ad Manager':
        this.router.navigate(['/settings/ad-manager']);
        break;
      case 'OTA Manager':
        this.router.navigate(['/settings/ota-manager']);
        break;
      case 'Theme Manager':
        this.router.navigate(['/settings/theme-manager']);
        break;
      case 'Invoice':
        this.router.navigate(['/accounts/invoice']);
        break;
      case 'Wallet':
        this.router.navigate(['/accounts/wallet']);
        break;
      default:
        this.router.navigate(['/dashboard']);
    }
    this.screenWidthDetect();
    this.applyStylesForMenu(maninMenuIndex, subMenuIndex)
  }

  applyStylesForMenu(maninMenuIndex: number, subMenuIndex: number) {
    for (let i = 1; i < this.menuArray.length; i++) {
      let li = document.getElementById('main-menu-id' + i) as HTMLLIElement;
      li.style.borderBottom = '2px solid transparent';
    }
    if (maninMenuIndex < this.menuArray.length + 1) {
      let liSelected = document.getElementById(
        'main-menu-id' + maninMenuIndex
      ) as HTMLLIElement;
      liSelected.style.borderBottom = '2px solid var(--nav-font)';
    }
  }

  isProfileOpen = false;
  openProfile(id: string) {
    let ul = document.getElementById(id) as HTMLUListElement;
    if (this.isProfileOpen) {
      ul.style.display = 'none';
    } else {
      ul.style.display = 'block';
    }
    this.isProfileOpen = !this.isProfileOpen;
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.isProfileOpen) {
      let ele = event.target as HTMLElement;
      if (
        ele.id != 'profileImg' &&
        ele.id != 'profileSubMenu' &&
        ele.id != 'profileLi'
      ) {
        let ul = document.getElementById('profileSubMenu');
        ul.style.display = 'none';
      }
    }
    if (!this.clickOutsideListener) {
      return;
    }
    let element = event.target as HTMLElement;
    if (
      element.className != 'sub-menu-name' &&
      element.className != 'sub-menu' &&
      element.className != 'main-menu-content' &&
      element.className != 'main-menu-name'
    ) {
      let ul = document.getElementById(
        `subMenu${this.openedSubMenuIndex}`
      ) as HTMLUListElement;
      ul.style.display = 'none';
      this.clickOutsideListener = false;
    }
  }

  openHambuger() {
    let div = document.getElementById('div4') as HTMLDivElement;
    div.style.display = div.style.display == 'flex' ? 'none' : 'flex';
  }
}
