<div class="cms__content">
    <app-reports-header></app-reports-header>

    <div class="top__heading">
        <h3>SMS User Authentication Logs</h3>
        <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
    </div>

    <div class="row">
        <div class="filter__group col-12 col-md-6">
            <label id="select_date">Select Date :</label>
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div>
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
              CSV
              <img src="./assets/csv.svg" alt="download" />
            </button>
        </div>


        <div class="col-12 col-md-6 report_selection_block">
            <label class="report_selection_label">Select Partner</label>
            <select class="custom__field report_selection" [(ngModel)]="selectedLco" (change)='lcoSelect()'>
                <!-- <option *ngFor="let lco of lcoList" [ngValue]='lco.id' [selected]='lcoListItem()'>{{lco.name}}</option> -->
                <option [ngValue]=0>ALL</option>
                <option *ngFor="let lco of lcoList" [ngValue]='lco'>{{lco.name}}</option>
            </select>
        </div>
    </div>
</div>

<div class="col-12 col-md-6">
    <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
  </div>
<div class="col-12 list__table">
    <div class="row scroll">
        <table class="custom__iptv__table" #reportTable id="reportTable">
            <thead>
                <tr>
                  <th>Sl No.</th>
                  <th>ID</th>
                  <th>User Email</th>
                  <th>User Type</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngIf="reportList.length == 0" >
                <td *ngIf="reportList.length ==0 && !loadingFlag" style="text-align: center !important;" colspan="12" >{{isLoad}}</td>
                <td style="text-align: center !important;" colspan="12" *ngIf="loadingFlag" > <app-custom-spinner></app-custom-spinner> </td>
              </tr>
                    <tr *ngFor="let report of reportList, let j = index">
                      <td>{{j+1}}</td>
                      <td>{{report.id? report.id : '---'}}</td>
                      <td>{{report.userEmail? report.userEmail : '---'}}</td>
                      <td>{{report.userType? report.userType : '---'}}</td>
                      <td>{{report.status || report['STATUS']}}</td>
                      <td>{{report.createdDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
                    </tr>
            </tbody>
        </table>
    </div>
</div>
