import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListLocationComponent } from './list-location/list-location.component';
import { CreateLocationComponent } from './create-location/create-location.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListLocationComponent },
  { path: 'create', component: CreateLocationComponent },
  { path: ':id', component: CreateLocationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocationRoutingModule { }
