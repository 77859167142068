<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>User Login History</h3>
    <a [routerLink]="['/logs']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <!-- <label id="select_date">Select Date :</label>
        <app-date-picker (dateChange)="dateChange($event)"></app-date-picker> -->
    </div>
    <div class="download__report col-md-6">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6">
      <div class="search_filter">
        <p>Select Date :</p>
        <div class="date_range">
          <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div>
      </div>
    </div>
    <div class="col-12 report">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
  </div>

  <div class="custom__table__filter">
    <span>
      Show
      <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
        <option
          *ngFor="let size of paginationObj.pageSizeOptions"
          [ngValue]="size"
        >
          {{ size }}
        </option>
      </select>
      Entries
    </span>
    <div class="table__right">
      <p class="table__summary">
        Total <span>{{ paginationObj.totalElements }}</span> records
      </p>
      <div class="search__container">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          id="searchKeyword"
          [(ngModel)]="paginationObj.searchKeyword"
          (keyup.enter)="searchKeyWord()"
        />
        <button (click)="searchKeyWord()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Subscriber Code</th>
            <th>Subscriber Name</th>
            <th>Mobile</th>
            <th>Description</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length == 0 && !loadingFlag">
            <td style="text-align: center !important" colspan="7">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngIf="loadingFlag">
            <td style="text-align: center !important" colspan="7">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <!-- <ng-template #showList> -->

          <tr *ngFor="let report of reportList; let i = index">
            <td>{{ i + 1 }}</td>
            <td>
              {{ report.subscriber_code ? report.subscriber_code : "---" }}
            </td>
            <td>
              {{ report.subscriber_name ? report.subscriber_name : "---" }}
            </td>
            <td>{{ report.mobile ? report.mobile : "---" }}</td>
            <td>{{ report.discription ? report.discription : "---" }}</td>
            <!--<td>{{report.date| date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>-->
            <td>{{ report.datee ? report.datee : "---" }}</td>
          </tr>
          <!-- </ng-template> -->
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
