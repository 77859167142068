import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  imageUpload(image: FormData): Observable<any> {
    const url = this.config.json.imageUploadEndpoint;
    return this.http.post(url, image);
  }
}
