<div class="cms__content">
    <div class="top__heading">
    <app-reports-header></app-reports-header>

        <h3>Subscriber Data Report By Channel or STB</h3>
        <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
    </div>

    <div class="row">
        <div class="filter__group col-12 col-md-6">
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div>
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
              CSV
              <img src="./assets/csv.svg" alt="download" />            </button>
        </div>


        <div class="col-12 col-md-6 report_selection_block">
            <label class="report_selection_label">Select Channel</label>
            <select class="custom__field report_selection" [(ngModel)]="selectedChannelId">
                <option *ngFor="let channel of channelList" [ngValue]='channel.id'>{{channel.name}}</option>
            </select>
        </div>

        <div class="col-12 col-md-6 report_selection_block">
            <label class="report_selection_label">Select STB</label>
            <select class="custom__field report_selection" [(ngModel)]="selectedStbId">
                <option *ngFor="let stb of stbList" [ngValue]='stb.id'>{{stb.ethernetMac}}</option>
            </select>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
      </div>

    <div class="col-12 list__table">
        <div class="row">
            <table class="custom__iptv__table" #reportTable id="reportTable">
                <thead>
                    <tr>
                      <th>Sl No.</th>
                        <th>Date</th>
                        <th>Total Subscribers</th>
                        <th>Active</th>
                        <th>Inactive</th>
                        <th>New Subscribers</th>
                    </tr>
                </thead>
                <tbody>
                  <tr *ngIf="subscriberReport.length ==0 && !loadingFlag" >
                    <td style="text-align: center !important;" colspan="3" >{{isLoad}}</td>
                  </tr>
                  <tr *ngIf="loadingFlag; else showList">
                    <td style="text-align: center !important;" colspan="3">
                    <app-custom-spinner></app-custom-spinner>
                    </td>
                  </tr>
                  <ng-template #showList>
                    <tr *ngFor="let report of subscriberReport, let j=index">
                      <td>{{j+1}}</td>
                        <td>{{report.date | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
                        <td>{{report.totalUsers}}</td>
                        <td>{{report.activeUsers}}</td>
                        <td>{{report.inActiveUsers}}</td>
                        <td>{{report.newUsers}}</td>
                    </tr>
                  </ng-template>
                </tbody>
            </table>
        </div>
    </div>
</div>
