<div class="cms__content">
  <app-reports-header></app-reports-header>

 <div class="top__heading">
   <h3>Statewise Subscriber</h3>
   <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
 </div>
 <div class="clearfix"></div>

 <div class="row filterbtnStyle">
  <div class="search_filters">
   <div class="col-12 col-md-6">
     <label for="month">Select State:</label>
     <!-- <input class="custom__field" type="month" id="month" [(ngModel)]="selectedDate" (ngModelChange)="fetchData()"> -->
     <select name="state" id="state" class="custom__field" (change)="selectState()" >
      <option value="0" >ALL</option>
       <option *ngFor="let item of stateList" value="{{item}}">{{item}}</option>
     </select>
   </div>
   <div class="col-12 col-md-6">
    <label class="report_selection_label">Status</label>
    <select class="custom__field report_selection" id="statusChangeApi" (change)="statusChange()">
      <option value=5>ALL</option>
      <option value=1>Active</option>
      <option value=0>Inactive</option>
      <option value=4>Terminated</option>
      <option value=3>New</option>
      <option value=2>Blacklisted</option>
    </select>
  </div>
</div>
   <div class="download__report col-md-6">
     <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
       PDF
       <img src="./assets/pdf.svg" alt="download" />
     </button>
     <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
       Excel
       <img src="./assets/excel.svg" alt="download" />
     </button>
     <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
           CSV
           <img src="./assets/csv.svg" alt="download" />        </button>
   </div>
 </div>

 <div class="col-12 col-md-6 report_selection_block">
   <!-- <label class="report_selection_label">Select by State</label>
   <select class="custom__field report_selection" [(ngModel)]="broadcasterId" (ngModelChange)='fetchData()'>
     Changed ngValue binding from lco.id to lco.email
     <option value = 0 >ALL</option>
     <option *ngFor="let lco of broadcasterList" [ngValue]='lco.id'>
       {{lco.state}}
     </option>
   </select> -->
 </div>

 <div class="third_box">
  <div class="col-12 col-md-6 report">
    <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
  </div>
  <div style="display: flex; gap:20px" >
    <div class="table__right">
      <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
    </div>
    <div class="search__container">
      <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
      <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
    </div>
  </div>
</div>

 <div class="col-12 list__table">
   <div class="row scroll">
     <table class="custom__iptv__table" #reportTable id="print">
       <thead>
         <tr>
           <th>Sl No</th>
           <th>Subscriber Name</th>
           <th>Subscriber Code</th>
           <th>Mac</th>
           <th>Mobile</th>
           <th>Price Package name</th>
           <th>Subscriber Status</th>
           <th>Subscription Type</th>
           <th>City</th>
           <th>State</th>
           <th>Zip Code</th>
           <th>Partner Name</th>
           <th>Partner code</th>
         </tr>
       </thead>
       <tbody>
           <tr>
             <td *ngIf="isLoading" colspan="13" style="text-align: center;">
               <app-custom-spinner></app-custom-spinner>
             </td>
           </tr>
           <ng-container *ngIf="!isLoading && reportList.length > 0">
             <tr *ngFor="let report of reportList, let j = index"  >
               <td>{{j+1}}</td>
               <td>{{report.sub_name == null ? '---' : report.sub_name}}</td>
               <td>{{report.sub_code == null ? '---' : report.sub_code}}</td>
               <td>{{report.mac == null ? '---' : report.mac}}</td>
               <td>{{report.mobile == null ? '---' : report.mobile}}</td>
               <td>{{report.price_pkg_name == null ? '---' : report.price_pkg_name}}</td>
               <td>{{statUS[report.sub_status] == null ? '---' : statUS[report.sub_status]}}</td>
               <td>{{report.subscription_type == null ? '---' : report.subscription_type}}</td>
               <td>{{report.city == null ? '---' : report.city}}</td>
               <td>{{report.state == null ? '---' : report.state}}</td>
               <td>{{report.zipcode == null ? '---' : report.zipcode}}</td>
               <td class="overFlow" >{{report.lco_name == null ? '---' : report.lco_name}}</td>
               <td class="overFlow" >{{report.lco_id == null ? '---' : report.lco_id}}</td>
             </tr>
           </ng-container>
           <tr *ngIf="!isLoading && reportList.length === 0">
            <td colspan="13" style="text-align: center;">No records</td>
          </tr>
       </tbody>
     </table>
   </div>
 </div>
</div>
