import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-report-order-history-active',
  templateUrl: './report-order-history-active.component.html',
  styleUrls: ['./report-order-history-active.component.scss']
})
export class ReportOrderHistoryActiveComponent implements OnInit {

  reportList = [];
  bouquetType = 0;
  broadcasterId: number;
  broadcasterList: BroadcasterInfo[] = [];

  selectedDate: any;

  currentDate = new Date();

  loadingFlag = true
  isLoad

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http : HttpClientWrapperService,
    private config : ConfigService
  ) { }

  ngOnInit(): void {
    // this.fetchLocationList();
    this.fetchData()
  }

  fetchLocationList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }

  fetchData(): void {
    //// console.log('Called')
    // //// console.log(this.broadcasterId, this.selectedDate)
    // if (this.selectedDate && this.broadcasterId) {
    //   const body = {
    //     "monthYear": this.selectedDate + '-01',
    //     broadcasterId: this.broadcasterId
    //   }
    //   this.report.getBroadcasterWiseChannelsReportMonthly(body).subscribe(data => {
    //     // //// console.log(data)
    //     this.reportList = data;
    //   });
    // }
    // const url = `http://192.168.10.21:7080/admin/api/subscriber/getsubscribersOrderHistoryActive`
    // this.http.get(url).subscribe((res)=>{
    //   //// console.log(res)
    //   this.reportList = res
    // })
    this.loadingFlag = true
    this.report.getSubscriberOrderHistoryActive().subscribe((res)=>{
      //// console.log(res)
      this.reportList = res.data
    })
  }

  downloadTableExcel(): void {
    let name='Subscriber Order History Active Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'active_order_history_report');
  }

  downloadTablePdf(): void {
    const tempDate = new Date(this.selectedDate);
    const monthYear = tempDate.toLocaleString('default', {month:'long'}) + " " + tempDate.getFullYear();
    this.pdf.generatePdfHtml('#reportTable',
    'subscriber_order_history_active',
    `Source Type : SMS`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["SlNo","Subscriber Code","Devices List","Process Type","Price Packages List","Transaction Date"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo : i,
        subscriber_code: line.subscriber_code,
        devices_list : line.devices_list==null? '---' : line.devices_list,
        process_type: line.process_type,
        price_packages_list: line.price_packages_list,
        transaction_date: line.transaction_date,
      }
      data.push(csvLine);
      i += 1
    });

    new ngxCsv(data, 'subscriber_order_history_active_report', options);}


    searchKeyword(){
      this.loadingFlag = true
      let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
      let url = this.config.json.apiEndpointRdbms + 'subscriber/getsubscribersOrderHistoryActive/?searchKeyword='+value
      this.http.get(url).subscribe(data => {
        this.reportList = data.data;
        if(this.reportList.length != 0){
          this.loadingFlag=false
        }else{
          this.loadingFlag=false
        this.isLoad='No Records'
        }
      },()=>{
        this.loadingFlag=false
        this.isLoad='No Records'
      });
    }
}
