import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateSTBComponent } from './create-stb/create-stb.component';
import { ListSTBComponent } from './list-stb/list-stb.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListSTBComponent },
  { path: ':id', component: CreateSTBComponent },
  { path: 'create', component: CreateSTBComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StbRoutingModule { }
