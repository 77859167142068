<div class="cms__content">
    <div class="top__heading">
      <h3>{{isEdit?'Edit':'Create'}} Region</h3>
      <a [routerLink]="['/content/statewise-lcn']"><button type="button" class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>
    <form action="" [formGroup]="regionForm" (ngSubmit)="persist()">
      <div class="col-12 form__style">
        <div class="row">
          <div class="col-12 ">
            <div class="col-md-6">
            <label>Region Name</label>
             <input type="text" autocomplete="off" class="custom__field" formControlName="regionName">
            </div>
          </div>
  
          <div class="col-12 ">
            <div class="col-md-6">
            <label>State</label>

            <ng-multiselect-dropdown [placeholder]="'Select States'" formControlName="state" [settings]="dropdownSettings"
            [data]="stateList" [(ngModel)]="stateDataSelected" class="custom__field">
          </ng-multiselect-dropdown>

        </div>
          </div>
  
          <div class="col-12">
            <button class="cta float-right" [disabled]="!regionForm.valid">Save</button>
          </div>
        </div>
      </div>
  
    </form>
  </div>