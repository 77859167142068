import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { DatePipe } from '@angular/common';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-fingerprint-report',
  templateUrl: './fingerprint-report.component.html',
  styleUrls: ['./fingerprint-report.component.scss']
})
export class FingerprintReportComponent implements OnInit {

  reportList = [];
  from: any;
  to: any;

  currentDate = new Date();

  selected: NgxDateRangeInfo;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  locale: any = {
    format: 'DD/MM/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'DD/MM/YYYY', // default is format value
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Select', // detault is 'Apply'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 0 // first day is monday
  };

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config : ConfigService,
    private http :HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    // this.setInitialDate();
    this.fetchData()
  }

  // isLoad :string = 'Choose Date'
  // isLoading: boolean = false
  // setInitialDate(): void {
  //   this.selected = this.calendar.getDate();
  //   // //// console.log(this.selected.startDate.valueOf())
  //   this.isLoad = 'Loading'
  //   this.isLoading = true
  //   this.fetchData(this.selected.startDate.valueOf(), this.selected.endDate.valueOf());
  // }

  fetchData(): void {
    this.report.getFingerprintReport().subscribe(data => {
      //// console.log(data.data)
      // data.reverse();
      this.reportList = data.data;
      // this.isLoading = true
    },(error)=>{
      // this.isLoad='No Records!'
      // // console.log(error)
    });
  }

  // reportDate;
  // dateChange(ev: NgxDateRangeInfo): void {
  //   if (ev.startDate !== null && ev.endDate !== null) {
  //     [this.from, this.to] = [ev.startDate, ev.endDate];
  //     this.calendar.setDate(ev);
  //     this.reportDate = ev.startDate.date()+'-'+this.doc.monthNameCalculator(ev.startDate.month())+'-'+ev.startDate.year()+' to '+ev.endDate.date()+'-'+this.doc.monthNameCalculator(ev.endDate.month())+'-'+ev.endDate.year()
  //     this.fetchData(ev.startDate.valueOf(), ev.endDate.valueOf());
  //   }
  // }

  // downloadTableExcel(): void {
  //   var elemRow = document.getElementById('headers') as HTMLTableRowElement
  //   //// console.log(elemRow)

  //   let headers=[]
  //   let name='Fingerprint Report'
  //   this.doc.exportTableToExcelwithImage(this.reportTable ,name,'fingerprint_report');
  // }



  downloadTableExcel(){
    let url= this.config.json.apiEndpoint+'generate/fingerprintReport'
    console.log(url)
    this.http.getwithresponseType(url).subscribe(
      (response:Blob) => {
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'fingerprint_report.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
    )
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable',
      'fingerprint_report',
      `Source Type : SMS`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Fingerprint Type","Display Type","Channel ID","Subscriber ID","Date"]
    };
    let data = []

    this.reportList.forEach(line => {
      let csvLine = {
        fingerprintType: line.fingerprintType === 1? 'ECM': 'EMM',
        displayType: line.displayType === 1? 'Covert':'Overt',
        channelIds: line.fingerprintType === 1?line.channelIds.toString():'---',
        subscriberIds:line.fingerprintType === 0? line.subscriberIds.toString():'---',
        createdDate: line.createdDate
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'fingerprint_report', options);
  }

  searchKeyword(){
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms+'fingerprint/list'+value
    this.http.get(url).subscribe(data => {
      this.reportList = data.data;
    },(error)=>{
      // // console.log(error)
    })

  }
}
