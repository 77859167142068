import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BouquetRoutingModule } from './bouquet-routing.module';
import { ListBouquetComponent } from './list-bouquet/list-bouquet.component';
import { CreateBouquetComponent } from './create-bouquet/create-bouquet.component';
import { PluginsModule } from '../plugins/plugins.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManagementComponent } from './management/management.component';


@NgModule({
  declarations: [
    ListBouquetComponent,
    CreateBouquetComponent,
    ManagementComponent
  ],
  imports: [
    CommonModule,
    BouquetRoutingModule,
    PluginsModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class BouquetModule { }
