import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { CategoryInfo } from 'src/app/models/category/category-info';
import {
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { PathNames } from 'src/app/constants/path-names';
import { environment } from 'src/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { ConfigService } from 'src/app/services/config.service';
import { MultiSelectType } from 'src/app/plugins/multiselect/multiselect.component';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss'],
})
export class CreateCategoryComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) {}

  dropdownList = [];
  selectedList = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    textField: 'name',
  };

  category = {} as CategoryInfo;

  categoryForm: FormGroup;

  isEdit = false;

  channelList: ChannelInfo[] = [];

  ngOnInit(): void {
    this.getPathParam();
    this.initForm();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.fetchData(id);
    } else {
      this.getChannelList();
    }
  }

  fetchRecord(id: string): Observable<CategoryInfo> {
    const url =
      this.config.json.apiEndpointRdbms + 'category/getCategory/' + id;
    return this.http.get(url);
  }

  fetchChannelList(): Observable<ChannelInfo[]> {
    // const url = this.config.json.apiEndpointRdbms + 'channel/getChannels';
    const url =
      this.config.json.apiEndpointRdbms + 'channel/getDropdownChannelList';
    return this.http.get(url);
  }

  fetchData(id: string): void {
    forkJoin([this.fetchRecord(id), this.fetchChannelList()]).subscribe(
      (data) => {
        this.category = data[0];
        console.log('update', data);
        this.updateForm();
        this.channelList = data[1]['data'];

        this.getChannelListDataForMultiSelect(
          data[1]['data'],
          data[0]['channels']
        );
        this.formatChannelList();
      }
    );
  }

  channelListObject: MultiSelectType = {
    dataArray: [],
    keyToDisplay: '',
    idForSelected: '',
  };
  channelListOptionsForMultiSelect = new BehaviorSubject<MultiSelectType>(
    this.channelListObject
  );
  getChannelListDataForMultiSelect(data, selectedArray?) {
    console.log(data);
    this.channelListObject = {
      dataArray: data,
      keyToDisplay: 'name',
      idForSelected: 'id',
      selectedArray: selectedArray ? selectedArray : [],
    };
    this.channelListOptionsForMultiSelect.next(this.channelListObject);
  }

  getChannelList(): void {
    this.fetchChannelList().subscribe((data) => {
      this.channelList = data['data'];
      this.getChannelListDataForMultiSelect(data['data']);
      // // console.log(this.channelList)
      this.formatChannelList();
    });
  }

  formatChannelList(): void {
    this.dropdownList = this.channelList.map((channel) => {
      return {
        id: channel.id,
        name: channel.name,
      };
    });

    this.selectedList = this.category.channels
      ? this.category.channels.map((channel) => {
          return {
            id: channel.id,
            name: channel.name,
          };
        })
      : [];
  }

  initForm(): void {
    this.categoryForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        // this.cannotContainSpace]
      }),
      channels: new FormControl([]),
      description: new FormControl('', [Validators.required]),
      status: new FormControl(1, [Validators.required]),
    });
  }

  get name(): FormControl {
    return this.categoryForm.get('name') as FormControl;
  }

  updateForm(): void {
    this.categoryForm.get('name').setValue(this.category.name);
    this.categoryForm.get('description').setValue(this.category.description);
    this.categoryForm.get('status').setValue(this.category.status);
    // this.categoryForm.get('logo').setValue(this.category.logo);
    // this.categoryForm.get('thumbnail').setValue(this.category.thumbnail);
  }

  persist(): void {
    this.updateModel();
    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'category/createCategory';

    this.http.post(url, this.category).subscribe(
      (data) => {
        if (data.statusCode == 200) {
          this.router.navigate(['/content/category', 'list']);
        } else {
          alert(data.message);
        }
      },
      (error) => {
        window.alert(error.error['message']);
      }
    );
  }

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'category/updateCategory';

    this.http.put(url, this.category).subscribe(
      (data) => {
        if(data.statusCode==200){
          this.router.navigate(['/content/category', 'list']);
          }
          else
          {
          window.alert(data.message)
          }
        
      },
      (error) => {
        window.alert(error.error['message']);
      }
    );
  }

  updateModel(): void {
    this.category.name = this.categoryForm.get('name').value;
    this.category.description = this.categoryForm.get('description').value;
    this.category.status = this.categoryForm.get('status').value;
    this.category.channels = this.categoryForm.get('channels').value;
    // this.category.logo = this.categoryForm.get('logo').value;
    // this.category.thumbnail = this.categoryForm.get('thumbnail').value;
    // this.category.channels = this.channelList.filter(channel => {
    //   return this.selectedList.some(select => {
    //     return channel.id === select.id;
    //   });
    // });
  }

  // **************************************************************************************
  // Custom Validators
  // **************************************************************************************

  cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }
    return null;
  }
}
