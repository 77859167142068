import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';

@Component({
  selector: 'app-report-suspended',
  templateUrl: './suspended-reports.component.html',
  styleUrls: ['./suspended-reports.component.scss']
})
export class SuspendedReports {
  reportList = [];
  from: any;
  to: any;

  currentDate = new Date();
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.fetchData(this.body);
  }


  searchText = ''
  openSearch = false
  isLoad = 'Choose Date';
  body = {
    fromDate: '',
    toDate: '',
    lcoId: '0',
    searchKeyword: '',
  };
  fetchData(body): void {
    this.report.getSuspendedReport(body).subscribe(
      (data) => {
        this.isLoading = false;
        this.reportList = data.data;
        if (this.reportList?.length == 0) {
          this.isLoading = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.isLoading = false;
        this.isLoad = 'No Records';
      }
    );
  }

  startSearch(){
    let input = document.getElementById('searchUser') as HTMLInputElement;
    this.searchText = input.value
    this.openSearch = false
    setTimeout(() => {
      this.openSearch = true
    }, 300);
  }
  getUserDetail(e: LcoSearchType){
    let input = document.getElementById('searchUser') as HTMLInputElement;
    input.value = `${e.lcoCode}, ${e.lcoName}`
    this.body = {
      ...this.body,
      lcoId: e.lcoId
    }
    this.fetchData(this.body)
    this.openSearch=false
  }

  isLoading = false;
  isChannelSelected = true;
  reportDate;
  eve;
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve = ev;
      this.calendar.setDate(ev);
      this.reportDate =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.body = {
        ...this.body,
        fromDate: ev.startDate.format('YYYY-MM-DD'),
        toDate: ev.endDate.format('YYYY-MM-DD'),
      };
      this.fetchData(this.body);
    }
  }

  downloadTableExcel(): void {
    let name = ' Suspended Reports';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'suspended_report',
      undefined,
      undefined,
      'Report Date :',
      this.reportDate,
      'LCO ID :', this.body.lcoId
    );
  }

  downloadTablePdf(): void {
    if (
      this.reportList == undefined ||
      this.reportList == null ||
      this.reportList?.length == 0
    ) {
      alert('No Data');
      return;
    }
    this.pdf.generatePdfHtml(
      '#reportTable',
      'suspended_reports',
      `From: ${this.reportDate ? this.reportDate : 'Till now'}, LCO ID: ${this.body.lcoId},  Source Type : SMS`
    );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Sl No',
        'Subscriber Name',
        'Ethernet Mac Address',
        'LCO Name',
        'LCO ID',
        'Subscriber Code',
        'Subscriber ID',
        'Suspended Date',
        'STB Serial Number',
      ],
    };

    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        subscriberName: line.subscriberName,
        ethernetMacAddress: line.ethernetMacAddress,
        lcoName: line.lcoName,
        lcoId: line.lcoId,
        subscriberCode: line.subscriberCode,
        subscriberId: line.subscriberId,
        suspendedDate: line.suspendedDate,
        stbSerialNo: line.stbSerialNo,
      };
      data.push(csvLine);
      i += 1;
    });

    new ngxCsv(data, 'suspended_report', options);
  }

  searchKeyword() {
    let searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement).value;
    this.body = {
      ...this.body,
      searchKeyword
    }
    this.fetchData(this.body)
  }
}
