<div class="cms__content">
  <div class="top__heading">
    <h3>Broadcasters</h3>
    <a [routerLink]="['/content/broadcaster/create']"><button class="default__button float-right">+ Add New</button></a>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-6 col-md-3">
        <div class="list__card">
          <span class="list__heading">Broadcasters</span>
          <span class="list__item">{{broadcasterCount}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="row overflow">

      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
              (keydown.enter)="onSearch()" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()"><img src="./assets/search.png"
                alt="search" /></button>
          </div>
        </div>
      </div>

      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th style="width: 10%;" >Sl No.</th>
            <th (click)="sortByItem(sortByProperties.name)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.name}">
              Name
            </th>
            <th (click)="sortByItem(sortByProperties.desc)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.desc}">
              Description
            </th>
            <th (click)="sortByItem(sortByProperties.mobile)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.mobile}">
              Mobile
            </th>
            <th (click)="sortByItem(sortByProperties.email)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.email}">
              Email
            </th>
            <th (click)="sortByItem(sortByProperties.cp)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.cp}">
              Contact Person
            </th>
            <!-- <th class="no__sort">
              Channel Count
            </th> -->
            <th (click)="sortByItem(sortByProperties.date)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.date}">
              Created On
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loadingFlag else showList">
            <td class="loading" colspan="7">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>

          <ng-template #showList>
            <tr *ngFor="let broadcaster of broadcasterList; let i = index">
              <td>{{((paginationObj.pageNumber-1)*paginationObj.pageSize)+(i+1)}}</td>
              <td>{{broadcaster.name? broadcaster.name : '---'}}</td>
              <td>{{broadcaster.description? broadcaster.description : '---'}}</td>
              <td>{{broadcaster.mobile? broadcaster.mobile : '---'}}</td>
              <td>{{broadcaster.email? broadcaster.email : '---'}}</td>
              <td>{{broadcaster.contactPerson? broadcaster.contactPerson : '---'}}</td>
              <!-- <td>0</td> -->
              <!-- <td>{{broadcaster.channels.length}}</td> -->
              <td>
                {{broadcaster.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                <div class="action__items">
                  <button (click)="edit(broadcaster)">Edit</button>
                  <!-- <button (click)="delete(broadcaster)">Delete</button> -->
                </div>
              </td>

            </tr>
          </ng-template>
        </tbody>
      </table>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
