import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-lcos',
  templateUrl: './create-lcos.component.html',
  styleUrls: ['./create-lcos.component.scss'],
})
export class CreateLCOsComponent implements OnInit {
  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private route: Router
  ) { }

  ngOnInit(): void { }

  sampleData = [
    {
      'Distributor Code' : 'DIST0000025',
      'Lco Name': 'Mohan',
      'Contact Person': 'Onnet',
      'Password': 'mohan@gmail.com',
      'Mobile Number': '7979797979',
      'Alternate Number': '9797979797',
      'Email ID': 'mohan2@fmail.com',
      'Address': '#213,4th main,kaveripura,560079',
      'Locality': 'Peenya',
      'Pincode': '560040'
    },
    {
      'Distributor Code' : 'DIST0000025',
      'Lco Name': 'Tester',
      'Contact Person': 'Onnet',
      'Password': 'tester@gmail.com',
      'Mobile Number': '7878787878',
      'Alternate Number': '8787878787',
      'Email ID': 'tester2@fmail.com',
      'Address': '#213,4th main,Summanahalli,560079',
      'Locality': 'Mathikere',
      'Pincode': '560040'
    },
  ];

  saveSample() {
    // // console.log(this.sampleData)
    this.excelgen.saveSample(this.sampleData, 'create_lco');
  }

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement
    this.excelgen.deselectSelectedFiles(input)
    this.isSubmitReady = false

  }

  isSubmitReady = false;
  message: string = ''
  errorBody = []
  errorAvailable = false
  e
  selectedFile(e) {
    this.message = ''
    this.e = e
    const url = this.config.json.apiEndpoint + 'bulkUpload/validation/lcos';
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        // // console.log(res)
        if (res.result) {
          this.isSubmitReady = true
          this.errorAvailable = false
        } else {
          this.errorAvailable = true
          this.errorBody = [res.errorBody]
          // console.log(res)
        }
      },
      error: (error) => {
        this.message = error.error.message
      }
    });
  }

  successMessage = 'No errors Found'
  uploadFile() {
    const url = this.config.json.apiEndpoint + 'bulkUpload/lcos';
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message
          if (confirm('Redirect to list of LCO?')) {
            this.route.navigate(['/manage/lco/list'])
          }
        } else {
          this.errorAvailable = true
          this.successMessage = res.message
          this.errorBody = res.errorBody
        }
      }
    })
  }
}
