import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  constructor() { }

  getResizeEvent(): Observable<any> {
    return fromEvent(window, 'resize').pipe(
      debounceTime(200), // Adjust debounce time as needed
      map((event: Event) => {
        return {
          width: window.innerWidth,
          height: window.innerHeight
        };
      })
    );
  }
}
