import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-logs-user-login',
  templateUrl: './logs-user-login.component.html',
  styleUrls: ['./logs-user-login.component.scss']
})
export class LogsUserLoginComponent implements OnInit {

  reportList = [];

  lcoList: LCOInfo[] = [];
  selectedLco
  from: any;
  to: any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    this.fetchLcoList();
    let dateObj = new Date()
    let toDate = dateObj.getFullYear()+"-"+(dateObj.getMonth()+1)+"-"+dateObj.getDate()
    this.loadingFlag = true
    let body = {
      'fromDate' : '2018-01-01',
      'toDate' : toDate,
      'searchKey' : '0'
    }
    this.fetchData(body)
    // this.fetchData('2018-01-01',toDate, 0)
  }

  fetchLcoList(): void {
    this.report.getLCOList().subscribe(data => {
      this.lcoList = data['data'];
      // //// console.log(this.lcoList.length)
    });
  }

  loadingFlag=false
  isLoad = 'Loading'

  fetchData(body): void {
    this.report.getUserActivityHistory(body).subscribe(data => {
      // Convenient to map the response directly, since we know response is list of JSON objects
      this.reportList = data.data;
      if(this.reportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }

  lcoSelect(): void {
    // Getting the selected start and end date from service, whose value is updated in dateChange function
    this.loadingFlag = true
    // // console.log(this.selectedLco)
    let startMonth = this.eve.startDate.month()>10 ? this.eve.startDate.month() : "0"+this.eve.startDate.month()
    let startdate = this.eve.startDate.date()>10 ? this.eve.startDate.date() : "0"+this.eve.startDate.date()
    let endMonth = this.eve.endDate.month()>10 ? this.eve.endDate.month() : "0"+this.eve.endDate.month()
    let enddate = this.eve.endDate.date()>10 ? this.eve.endDate.date() : "0"+this.eve.endDate.date()
    if(this.eve != undefined || this.eve != null){
      var body = {
        'fromDate' : this.eve.startDate.year()+"-"+startMonth+"-"+startdate,
        'toDate' : this.eve.endDate.year()+"-"+endMonth+"-"+enddate,
        'email' : this.selectedLco.email? this.selectedLco.email : '0'
      }
    }else{
      let dateObj = new Date()
    let todate = dateObj.getFullYear()+"-"+(dateObj.getMonth()+1 > 10 ? dateObj.getMonth()+1 : "0"+(dateObj.getMonth()+1))+"-"+(dateObj.getDate() > 10 ? dateObj.getDate() : "0"+(dateObj.getDate()))
      var body = {
        'fromDate' : '2018-01-01',
        'toDate' : todate,
        'email' : this.selectedLco.email? this.selectedLco.email : '0'
      }
    }

    this.fetchData(body);
  }

  eve
  reportDate = "From Start To Now"
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve=ev
      this.reportDate = ev.startDate.format('DD/MM/YYYY')+" TO "+ev.endDate.format('DD/MM/YYYY')
      this.calendar.setDate(ev);
      // // console.log(this.calendar.getDate())
      this.isLoad = 'Choose Partner'
      // this.fetchData(ev.startDate, ev.endDate);
    }
  }

  downloadTableExcel(): void {
    // this.pdf.generatePdfHtml('#reportTable',
    // 'fingerprint_report',
    // `From Date: ${this.from.toISOString().slice(0,10)}     To Date: ${this.to.toISOString().slice(0,10)}`
    // );
    let name='Authentication Logs'
    this.doc.exportTableToExcelwithImage(this.reportTable, name,'user_authentication_logs', undefined, undefined, 'Report Date :',this.reportDate, "Partner name :", this.selectedLco?  this.selectedLco == 0? "All" : this.selectedLco.name : "All");
  }

  downloadTablePdf(): void {
    if(this.reportList.length==0){
      alert('No Data!!')
    }else{
      if(this.eve){
        this.pdf.generatePdfHtml('#reportTable',
        'user_authentication_logs',
        `From Date: ${this.eve.startDate.day()+"-"+this.doc.monthNameCalculator(this.eve.startDate.month())+"-"+this.eve.startDate.year()}     To Date: ${this.eve.endDate.day()+"-"+this.doc.monthNameCalculator(this.eve.endDate.month())+"-"+this.eve.endDate.year()}     Partner Name: ${this.selectedLco? this.selectedLco.name : 'All'}`, 'portrait'
        );
      }else{
        this.pdf.generatePdfHtml('#reportTable',
        'user_authentication_logs',
        `From Start to Now    Partner Name: ${this.selectedLco? this.selectedLco.name : 'All'}`, 'portrait'
        );
      }
    }
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Sl No.","Id", "User Email", "User Type", "Status","Date"]
    };
    let data = []
    let i =1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo : i,
        id: line.id,
        email: line.userEmail,
        type: line.userType,
        status: line['STATUS'] || line.status,
        date: line.createdDate,
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'User_Authentication_Logs', options);
  }

}
