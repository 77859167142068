<div class="cms__content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 offset-md-3">
        <div class="col-12 centerCard">
          <div class="col-12">
            <div class="top__heading">
              <h3>My Profile</h3>
            </div>
            <div class="col-12 img__section">
              <img src="/assets/profile.svg" class="profile__change" />
            </div>
            <div class="col-12 details__sec">
              <ng-container *ngIf="!passwordChange" >
                <div class="col-12 details__sec__inner" >
                  <div class="row">
                    <div class="col-4">
                      <label>Name</label>
                    </div>
                    <div class="col-8">
                      <span>{{ admin1["name"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 details__sec__inner" *ngIf="isLCO" >
                  <div class="row">
                    <div class="col-4">
                      <label>Contact Person</label>
                    </div>
                    <div class="col-8">
                      <span>{{ admin1["contactPerson"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 details__sec__inner" >
                  <div class="row">
                    <div class="col-4">
                      <label>Email</label>
                    </div>
                    <div class="col-8">
                      <span>{{ admin1["email"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 details__sec__inner" >
                  <div class="row">
                    <div class="col-4">
                      <label>Mobile Number</label>
                    </div>
                    <div class="col-8">
                      <span>{{ admin1["mobile"] }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 details__sec__inner" >
                  <div class="row">
                    <div class="col-4">
                      <label>Landline Number</label>
                    </div>
                    <div class="col-8">
                      <span>{{ admin1["landline"] }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div
                class="col-12 password__change"
                [formGroup]="passwordForm"
                *ngIf="passwordChange"
              >
                <div class="row">
                  <div class="col-12 details__sec__inner">
                    <div class="row">
                      <div class="col-4">
                        <label>Current Password</label>
                      </div>
                      <div class="col-8 password-field_show-hide">
                        <input
                          [type]="showCurrentPassword ? 'text' : 'password'"
                          placeholder="Current password"
                          class="custom__field"
                          formControlName="currentPassword"
                        />
                        <img (click)="showCurrentPassword = !showCurrentPassword" [src]="!showCurrentPassword ? '/assets/icons/eye.svg' : '/assets/icons/eye-no.svg'" alt="Show Password">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 details__sec__inner">
                    <div class="row">
                      <div class="col-4">
                        <label>New Password</label>
                      </div>
                      <div class="col-8 password-field_show-hide">
                        <!-- <input type="password" class="custom__field"> -->
                        <input
                          [type]="showNewPassword ? 'text' : 'password'"
                          id="new-password"
                          class="custom__field"
                          placeholder="New password"
                          formControlName="newPassword"
                          />
                          <img (click)="showNewPassword = !showNewPassword" [src]="!showNewPassword ? '/assets/icons/eye.svg' : '/assets/icons/eye-no.svg'" alt="Show Password">
                      </div>
                    </div>
                    <div *ngIf="passwordForm.get('newPassword').invalid">
                      <span style="color: brown" *ngIf="!passwordStringMatch()"
                        >Passwords should contain at least one capital case, one
                        lowercase, a number and a special character</span
                      >
                    </div>
                  </div>
                  <div class="col-12 details__sec__inner">
                    <div class="row">
                      <div class="col-4">
                        <label>Confirm Password</label>
                      </div>
                      <div class="col-8 password-field_show-hide">
                        <!-- <input type="password" class="custom__field"> -->
                        <input
                          [type]="showConfirmPassword ? 'text' : 'password'"
                          id="confirm-new-password"
                          class="custom__field"
                          placeholder="Confirm New password"
                          formControlName="confirmPassword"
                        />
                        <img (click)="showConfirmPassword = !showConfirmPassword" [src]="!showConfirmPassword ? '/assets/icons/eye.svg' : '/assets/icons/eye-no.svg'" alt="Show Password">
                      </div>
                    </div>
                    <div
                      *ngIf="
                        passwordForm.get('newPassword').hasError('mismatch')
                      "
                    >
                    <span style="color: brown" >Passwords do not match</span>
                    </div>
                  </div>
                  <div class="col-12 details__sec__inner">
                    <div class="row">
                      <div class="col-6">
                        <button (click)="showPasswordDiv()">Cancel</button>
                      </div>
                      <div class="col-6">
                        <button
                          (click)="onSubmit()"
                          [disabled]="passwordForm.invalid"
                          class="cta float-right"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 password__button" *ngIf="!passwordChange">
                <div class="row">
                  <div class="col-12">
                    <button (click)="showPasswordDiv()">Change Password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
