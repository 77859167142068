<div class="cms__content">
  <div class="top__heading">
    <h3>OSD</h3>
    <a [routerLink]="['/communication/osd/create']"><button class="default__button float-right">+ Send New</button></a>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-12 col-md-3 list-sum-card">
        <div class="list__card small__top">
          <span class="list__heading">Customize Messages</span>
          <a [routerLink]="['/communication/osd/message']"><button class="nav__button">Open</button></a>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row">

      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" placeholder="Search"
              [(ngModel)]="paginationObj.searchKeyword" />
            <button (click)="onSearch()"><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>

      <div class="scroll-box">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th (click)="sortByItem(sortByProperties.subId)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.subId}">
                Subscriber Code
              </th>
              <th class="no__sort">
                Message
              </th>
              <th (click)="sortByItem(sortByProperties.status)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
                Status
              </th>
              <th (click)="sortByItem(sortByProperties.udate)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.udate}">
                Last Updated
              </th>
              <th (click)="sortByItem(sortByProperties.cdate)"
                [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.cdate}">
                Created on
              </th>
            </tr>
          </thead>
          <tbody>

            <tr *ngIf="!loadingFlag && errorFlag;">
              <td colspan="6" style="text-align: center;">No Data</td>
          </tr>

            <tr *ngIf="loadingFlag && !errorFlag; else showList">
              <td class="loading" colspan="5">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>

            <ng-template #showList>
              <tr *ngFor="let osd of osdList; let i = index">
                <!-- <td>{{osd.subscriberCode}}</td> -->
                <td>
                <span (click)="showAllValues(i)">
                  {{ osd.subscriberCode | truncatePipe:4 }} <!-- Adjust limit as needed -->
                  <span *ngIf="!showAll[i]">...</span>
                </span>
                <span *ngIf="showAll[i]">{{ osd.subscriberCode }}</span></td>
                <td>{{osd.osdMessage.message}}</td>
                <td>
                  <span [ngClass]="{'safe__label' : !osd.revoke, 'danger__label' : osd.revoke}">
                    {{osd.revoke?'X':'&#10003;'}}
                  </span>
                </td>
                <td>{{osd.updatedDate | date: 'dd-MM-yyyy HH:mm:ss'}}</td>
                <td>
                  {{osd.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                  <div class="action__items">
                    <button (click)="updateOsd(osd)">Update</button>
                    <button (click)="updateStatus(osd, i)" class="cta">{{osd.revoke?'Activate':'Revoke'}}</button>
                    <button (click)="deleteOsd(osd, i)" class="danger">Delete</button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>

      </div>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>

</div>
