import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { CreateSubcriberComponent } from './create-subcriber/create-subcriber.component';
import { ListSubcriberComponent } from './list-subcriber/list-subcriber.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListSubcriberComponent },
  {
    path: ':id', component: CreateSubcriberComponent,
    // canActivate: [AuthGuard],
    // data: {
    //   role: ['LCO']
    // }
  },
  {
    path: 'create', component: CreateSubcriberComponent,
    // canActivate: [AuthGuard],
    // data: {
    //   role: ['LCO']
    // }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriberRoutingModule { }
