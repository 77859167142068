import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-report-channel-list',
  templateUrl: './report-channel-list.component.html',
  styleUrls: ['./report-channel-list.component.scss']
})
export class ReportChannelListComponent implements OnInit {

  channelReportList = [];

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config : ConfigService,
    private http : HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    this.fetchData()
  }

  fetchData():void{
    this.report.getChannelList().subscribe((data) => {
      this.channelReportList = data
      //// console.log(data)
      //// console.log(data.data)
    })

  }

  downloadTableExcel(): void {
    let name='Channel List  Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'channels_report');
  }

  downloadTablePdf(): void {
    // const tempDate = new Date(this.selectedDate);
    // const monthYear = tempDate.toLocaleString('default', {month:'long'}) + " " + tempDate.getFullYear();
    this.pdf.generatePdfHtml('#reportTable',
    'channels_report',
    `Source Type : SMS`
    );
  }
  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.", "Channel ID", "Channel Name","Broadcaster Name", "Channel LCN","Category","Language","Genre","Description","Updated"]
    };
    let data = []
    let i = 1
    this.channelReportList.forEach(line => {
      let csvLine = {
        slNo : i,
        channelId: line.channel_id,
        channelName : line.name,
        broadcasterName : line.broadcasterName,
        channelNo: line.channel_no,
        category: line.category,
        language: line.language,
        genre: line.genre,
        description: line.description,
        updated_date : line.updated_date
      }
      data.push(csvLine);
      i += 1
    });

    new ngxCsv(data, 'channels_report', options);
  }

searchKeyword(){
  this.channelReportList = []
  let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
  let url = this.config.json.apiEndpointRdbms+'channel/getChannelList?searchKeyword='+value
  this.http.get(url).subscribe(
    (data) => {
      this.channelReportList = data
    }
  )

}

}
