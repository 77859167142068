<div class="cms__content" >
  <div class="box">
    <div class="top__heading">
      <h3>Ad Manager</h3>
    </div>
    <form action="" >
      <div class="col-12 form__style">
        <!-- <div class="row" >
          
          <label for=""  >LCO</label>
          <input type="text" class="custom__field" id="lcoId" (input)="getSearchText($event)" >
            <div class="overflow" *ngIf="isSearch" style="margin-top: 10px" >
              <app-lco-search [searchText]="searchText" (userDetail)="getLCOId($event)" ></app-lco-search>
            </div>
          
        </div> -->
        <div class="row">
          <label for="">Position</label>
          <!-- <select
            name=""
            id=""
            class="custom__field"
            (change)="selectedPosition($event)"
          > -->
          <select [(ngModel)]="positionValue" class="custom__field" (change)="positionSelected=true"  [ngModelOptions]="{standalone: true}">
            <option value="selected" disabled selected>-- SELECT --</option>
            <option value="home-banner">Home Screen - Banner</option>
            <option value="home-overlay">Channel - Overlay</option>
          </select>
          <!-- <select name="" id=""  (change)="selectedPosition($event)" class="custom__field"> 
                        <option value="selected" disabled selected> -- SELECT -- </option>
            <option value="home-banner">Home Screen - Banner</option>
            <option value="home-overlay">Channel - Overlay</option>
          </select> -->
        </div>
        <div class="row">
          <label for="">Upload</label>
          <input #myInput type="file" accept=".jpeg, .jpg, .png" class="custom__field" (input)="positionSelected? uploadAds($event) : null"/>
        </div>
        <div class="row" *ngIf="message">
          <b>{{message}}</b>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="preview">
  <!-- <table >
    <thead>
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Image</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let ad of adList" >
        <tr>
          <td>{{ad.id}}</td>
          <td>{{ad.name}}</td>
          <td>
            <img [src]="ad.url" alt="">
          </td>
          <td>1</td>
          <td>Banner</td>
          <td>
            <img src="http://103.81.157.84/upload/images/home-banner.jpg?v=0.2290020426661723" alt="">
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table> -->

  <div class="preview_tv_home" >

    <img src="../../../assets/tv_mockup.png" alt="">

    <div class="overlay_home">

      <img [src]="adList[0].url" alt="">
    </div>

    <span>{{adList[0].name}}</span>
    

  </div>

  <div class="preview_tv_channel">

    <img src="../../../assets/tv_mockup.png" alt="">

    <div class="overlay_channel">

      <img [src]="adList[1].url" alt="">
    </div>

    <span>{{adList[1].name}}</span>

  </div>

</div>

