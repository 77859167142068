<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Subscribers Order History Active</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="col-12 col-md-6">
      <!-- <label for="month">Select Month:</label>
      <input class="custom__field" type="month" id="month" [(ngModel)]="selectedDate" (ngModelChange)="fetchData()"> -->
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
        <img src="./assets/csv.svg" alt="download" />
      			</button>
    </div>
  </div>

  <!-- <div class="col-12 col-md-6 report_selection_block">
    <label class="report_selection_label">Select Broadcaster</label>
    <select class="custom__field report_selection" [(ngModel)]="broadcasterId" (ngModelChange)='fetchData()'> -->
      <!-- Changed ngValue binding from lco.id to lco.email -->
      <!-- <option value = 0 >ALL</option> -->
      <!-- <option *ngFor="let lco of broadcasterList" [ngValue]='lco.id'>
        {{lco.name}}
      </option>
    </select>
  </div> -->

  <div class="third_box">
    <div class="col-12 col-md-6 report">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div style="display: flex; gap:20px" >
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <th>Sl no</th>
            <th>Subscriber Code</th>
            <th>Devices List</th>
            <th>Process Type</th>
            <th>Price Packages List</th>
            <th>Transaction Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length == 0" >
            <td colspan="6" *ngIf="loadingFlag" style="text-align: center;" >
              <app-custom-spinner></app-custom-spinner>
            </td>
            <td colspan="6" *ngIf="!loadingFlag"  style="text-align: center;" >
              {{isLoad}}
            </td>
          </tr>
            <tr *ngFor="let report of reportList, let j = index">
              <!-- <td>{{report.channelName}}</td>
              <td>{{report.channelNo}}</td>
              <td>{{report.channelId}}</td>
              <td>{{report.subscriptionType}}</td>
              <td>{{report.subscriberCountAt7}}</td>
              <td>{{report.subscriberCountAt14}}</td>
              <td>{{report.subscriberCountAt21}}</td>
              <td>{{report.subscriberCountAt28}}</td>
              <td>{{(report.subscriberCountAt7+report.subscriberCountAt14+
                report.subscriberCountAt21+report.subscriberCountAt28)/4}}</td> -->
                <td>{{j+1}}</td>
                <td>{{report.subscriber_code}}</td>
                <td>{{report.devices_list==null? '---' : report.devices_list}}</td>
                <td>{{report.process_type}}</td>
                <td id="ppl">{{report.price_packages_list == null ? '---' : report.price_packages_list }}</td>
                <td>{{report.transaction_date| date: 'dd-MM-yyyy HH:mm:ss' : 'UTC'}}</td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
