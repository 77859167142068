import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TransferSubscriberToOtherLCOComponent } from './transfer-subscriber-to-other-lco/transfer-subscriber-to-other-lco.component';
import { CreateLocationComponent } from './create-location/create-location.component';
import { CreateLCOsComponent } from './create-lcos/create-lcos.component';
import { CreateBroadcastersComponent } from './create-broadcasters/create-broadcasters.component';
import { CreateChannelsComponent } from './create-channels/create-channels.component';
import { AssignSubscribersToLCOComponent } from './assign-subscribers-to-lco/assign-subscribers-to-lco.component';
import { AssignSubscribersToSubscriberGroupComponent } from './assign-subscribers-to-subscriber-group/assign-subscribers-to-subscriber-group.component';
import { AssignStbToLcosComponent } from './assign-stb-to-lcos/assign-stb-to-lcos.component';
import { ActivateDeactivateSubscribersOfLcoComponent } from './activate-deactivate-subscribers-of-lco/activate-deactivate-subscribers-of-lco.component';
import { SendMailsComponent } from './send-mails/send-mails.component';
import { AddRemovePackagesToSubscribersComponent } from './add-remove-packages-to-subscribers/add-remove-packages-to-subscribers.component';
import { TransferStbsToLcoComponent } from './transfer-stbs-to-lco/transfer-stbs-to-lco.component';
import { CreateBouquetComponent } from './create-bouquet/create-bouquet.component';
import { CreateSubscribersComponent } from './create-subscribers/create-subscribers.component';

const routes: Routes = [
  {
    path:'', component : HomeComponent
  },
  {
    path : 'transfer-subscribers-to-other-lco',
    component : TransferSubscriberToOtherLCOComponent
  },
  {
    path : 'transfer-stbs-to-lco',
    component : TransferStbsToLcoComponent
  },
  {
    path : 'create-locations',
    component : CreateLocationComponent
  },
  {
    path : 'create-lcos',
    component : CreateLCOsComponent
  },
  {
    path : 'create-broadcasters',
    component : CreateBroadcastersComponent
  },
  {
    path : 'create-channels',
    component : CreateChannelsComponent
  },
  {
    path : 'assign-subscribers-to-lco',
    component : AssignSubscribersToLCOComponent
  },
  {
    path : 'assign-subscribers-to-subscriber-group',
    component : AssignSubscribersToSubscriberGroupComponent
  },
  {
    path : 'assign-stb-to-lco',
    component : AssignStbToLcosComponent
  },
  {
    path : 'activate-deactivate-subscribers-of-lco',
    component : ActivateDeactivateSubscribersOfLcoComponent
  },
  {
    path : 'send-mails',
    component : SendMailsComponent
  },
  {
    path : 'add-remove-packages-to-subscribers-of-lco',
    component : AddRemovePackagesToSubscribersComponent
  },
  {
    path : 'create-bouquet',
    component : CreateBouquetComponent
  },
  {
    path : 'create-subscribers',
    component : CreateSubscribersComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BatchJobsManagementRoutingModule { }
