<div class="cms__content">
    <div class="top__heading">
        <h3>Payment Sucessfully Completed</h3>
        <!-- <a [routerLink]="['/accounts/wallet']"><button type="button"
                class="default__button float-right">Back</button></a> -->
    </div>
    <div class="whole_page">
        <div class="complete__tick">
            <img src="assets/check.svg" alt="">
        </div>
        <h4>Payment Success</h4>
        <h4>Closing in {{timerSec}} Seconds</h4>
    </div>
</div>