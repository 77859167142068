import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { PackageInfo } from 'src/app/models/package/package-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';

@Component({
  selector: 'app-logs-product-composition-change',
  templateUrl: './logs-product-composition-change.component.html',
  styleUrls: ['./logs-product-composition-change.component.scss']
})
export class LogsProductCompositionChangeComponent implements OnInit {

  reportList = [];

  bouquetList: PackageInfo[] = [];
  selectedBouquetId: number;
  from:any;
  to:any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    this.fetchBouquetList();
  }


  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe(data => {
      this.bouquetList = data;
    });
  }

  fetchData(): void {
    const body = {
      "bouquetId": this.selectedBouquetId
    }
    // this.report.getBouquetCompositionChangeHistory(this.from, this.to, body).subscribe(data => {
    //   this.reportList = data.data;
    //   // //// console.log(this.reportList)
    // });
  }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.calendar.setDate(ev);
      this.fetchData();
    }
  }

  bouquetSelect(): void {
    this.fetchData();
  }

  downloadTableExcel(): void {
    let name='Product Ciomposition Logs'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'product_composition_logs');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable',
    'product_composition_logs',
    `Bouquet ID: ${this.selectedBouquetId}`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Date", "Bouquet ID", "Bouquet Name", "Channel ID","Channel Name","Channel Status","Modified By"]
    };
    let data = []

    this.reportList.forEach(line => {
      let csvLine = {
        date: line.createdDate,
        bouquetId: line.bouquetId,
        bouquetName: line.bouquetName,
        channelId: line.channelId,
        channelName: line.channelName,
        channelStatus: line.channelStatus,
        modifiedBy: line.modifiedBy
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'Bouquet_Composition_Change_Logs', options);
  }


}
