import { ReportNextFifteenDaysExpiryComponent } from './report-next-fifteen-days-expiry/report-next-fifteen-days-expiry.component';
import { ReportTodaysExpiryComponent } from './report-todays-expiry/report-todays-expiry.component';
import { ReportProductCompositionComponent } from './report-product-composition/report-product-composition.component';
import { ReportChannelListComponent } from './report-channel-list/report-channel-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FingerprintReportComponent } from './fingerprint-report/fingerprint-report.component';
import { OsdReportComponent } from './osd-report/osd-report.component';
import { PackageAgeingReportComponent } from './package-ageing-report/package-ageing-report.component';
import { ReportBouquetSubscriptionCountComponent } from './report-bouquet-subscription-count/report-bouquet-subscription-count.component';
import { ReportStbActiveCountComponent } from './report-stb-active-count/report-stb-active-count.component';
import { ReportStbDeployedComponent } from './report-stb-deployed/report-stb-deployed.component';
import { ReportStbFaultyComponent } from './report-stb-faulty/report-stb-faulty.component';
import { ReportStbInactiveCountComponent } from './report-stb-inactive-count/report-stb-inactive-count.component';
import { ReportStbLcoStockComponent } from './report-stb-lco-stock/report-stb-lco-stock.component';
import { ReportStbLocationWiseComponent } from './report-stb-location-wise/report-stb-location-wise.component';
import { ReportStbStatusComponent } from './report-stb-status/report-stb-status.component';
import { ReportStbWarehouseStockComponent } from './report-stb-warehouse-stock/report-stb-warehouse-stock.component';
import { ReportSubscriberDataComponent } from './report-subscriber-data/report-subscriber-data.component';
import { ReportSubscriberStatusComponent } from './report-subscriber-status/report-subscriber-status.component';
import { ReportSubscriptionComponent } from './report-subscription/report-subscription.component';
import { ReportSyncCasComponent } from './report-sync-cas/report-sync-cas.component';
import { SubscriberAuthorizationReportComponent } from './subscriber-authorization-report/subscriber-authorization-report.component';
import { SubscriberPackagesHistoryComponent } from './subscriber-packages-history/subscriber-packages-history.component';
import { SubscriberPackagesReportComponent } from './subscriber-packages-report/subscriber-packages-report.component';
import { ReportBouquetwiseChannelComponent } from './report-bouquetwise-channel/report-bouquetwise-channel.component';
import { ReportBouquetSubscriptionComponent } from './report-bouquet-subscription/report-bouquet-subscription.component';
import { ReportChannelSubscriptionComponent } from './report-channel-subscription/report-channel-subscription.component';

import { TraiHomeComponent } from './trai-home/trai-home.component';
import { ReportBroadcasterChannelWiseComponent } from './report-broadcaster-channel-wise/report-broadcaster-channel-wise.component';
import { ReportStbActiveListComponent } from './report-stb-active-list/report-stb-active-list.component';
import { ReportStbInactiveListComponent } from './report-stb-inactive-list/report-stb-inactive-list.component';
import { ReportStbBlacklistListComponent } from './report-stb-blacklist-list/report-stb-blacklist-list.component';
import { ReportSubscribersListComponent } from './report-subscribers-list/report-subscribers-list.component';
import { ReportBroadcasterWeekWiseComponent } from './report-broadcaster-week-wise/report-broadcaster-week-wise.component';
import { ReportSubscriberDeviceReplacementComponent } from './report-subscriber-device-replacement/report-subscriber-device-replacement.component';
import { ReportOrderHistoryActiveComponent } from './report-order-history-active/report-order-history-active.component';
import { ReportOrderHistoryInactiveComponent } from './report-order-history-inactive/report-order-history-inactive.component';
import { ReportSubwiseActiveInactiveLDTComponent } from './report-subwise-active-inactive-ldt/report-subwise-active-inactive-ldt.component';
import { ReportStatewiseSubscriberComponent } from './report-statewise-subscriber/report-statewise-subscriber.component';
import { ReportSubscriberwiseSubscriptionActiveComponent } from './report-subscriberwise-subscription-active/report-subscriberwise-subscription-active.component';
import { ReportSubscriberwiseSubscriptionInactiveComponent } from './report-subscriberwise-subscription-inactive/report-subscriberwise-subscription-inactive.component';
import { ReportPackageAgeingInactiveComponent } from './report-package-ageing-inactive/report-package-ageing-inactive.component';
import { ReportSubscriberDetailsComponent } from './report-subscriber-details/report-subscriber-details.component';
import { ReportNextSevenDaysExpiryComponent } from './report-next-seven-days-expiry/report-next-seven-days-expiry.component';
import { ReportBoquetModificationComponent } from './report-boquet-modification/report-boquet-modification.component';
import { ReportLcoBillingComponent } from './report-lco-billing/report-lco-billing.component';
import { SuspendedReports } from './suspended-reports/suspended-reports.componet';
import { ReportStbInventoryComponent } from './report-stb-inventory/report-stb-inventory.component';
import { UniversalReportComponent } from './universal-report/universal-report.component';
import { ReportStbReconciliationCountComponent } from './report-stb-reconciliation-count/report-stb-reconciliation-count.component';
import { ReportSusbscriberReconciliationCountComponent } from './report-susbscriber-reconciliation-count/report-susbscriber-reconciliation-count.component';
import { ReportChannelReconciliationCountComponent } from './report-channel-reconciliation-count/report-channel-reconciliation-count.component';
import { ReportBoquetReconciliationCountComponent } from './report-boquet-reconciliation-count/report-boquet-reconciliation-count.component';
import { ReportSubscriptionReconciliationCountComponent } from './report-subscription-reconciliation-count/report-subscription-reconciliation-count.component';
import { ReportStbListComponent } from './report-stb-list/report-stb-list.component';
import { ReportStbCountComponent } from './report-stb-count/report-stb-count.component';
import { ReportProducrSubscriptionStatusComponent } from './report-producr-subscription-status/report-producr-subscription-status.component';
import { ReportMsrComponent } from './report-msr/report-msr.component';
import { ReportHomeNewComponent } from './report-home-new/report-home-new.component';
import { ReportChannelListNewComponent } from './report-channel-list-new/report-channel-list-new.component';
import { ReportStbInventoryNewComponent } from './report-stb-inventory-new/report-stb-inventory-new.component';
import { ReportPackageAgeingActiveInactiveComponent } from './report-package-ageing-active-inactive/report-package-ageing-active-inactive.component';
import { ReportSubscriberListNewComponent } from './report-subscriber-list-new/report-subscriber-list-new.component';
import { ReportStbMappingComponent } from './report-stb-mapping/report-stb-mapping.component';
import { ReportSubscriptionbyBouquetIdReconciliationCountComponent } from './report-subscriptionby-bouquet-id-reconciliation-count/report-subscriptionby-bouquet-id-reconciliation-count.component';

const routes: Routes = [
  //STB Reports
  { path: 'report-home-new', component: ReportHomeNewComponent },
  { path: 'report-subscriber-list-new', component: ReportSubscriberListNewComponent },
  { path: 'report-inventory', component: ReportStbInventoryComponent },
  { path: 'report-stb-status', component: ReportStbStatusComponent },
  { path: 'report-stb-active-count', component: ReportStbActiveCountComponent },
  { path: 'report-universal', component: UniversalReportComponent },
  {
    path: 'report-stb-paring-list',
    component: ReportStbMappingComponent,
  },
  {
    path: 'report-stb-inactive-count',
    component: ReportStbInactiveCountComponent,
  },
  {
    path: 'report-stb-location-wise',
    component: ReportStbLocationWiseComponent,
  },
  {
    path:'report-channels-list-new',
    component:ReportChannelListNewComponent
  },
  {
    path:'report-stb-inventory-new',
    component:ReportStbInventoryNewComponent
  },
  {
    path: 'report-stb-warehouse-stock',
    component: ReportStbWarehouseStockComponent,
  },
  { path: 'report-stb-lco-stock', component: ReportStbLcoStockComponent },
  { path: 'report-stb-deployed', component: ReportStbDeployedComponent },
  { path: 'report-stb-faulty', component: ReportStbFaultyComponent },
  { path: 'report-stb-active-list', component: ReportStbActiveListComponent },
  {
    path: 'report-stb-inactive-list',
    component: ReportStbInactiveListComponent,
  },{
    path: 'report-stb-count',
    component: ReportStbCountComponent,
  },
  {
    path: 'report-stb-blacklist-list',
    component: ReportStbBlacklistListComponent,
  },
  {
    path: 'report-product-subscription-status',
    component: ReportProducrSubscriptionStatusComponent,
  },
  { path: 'report-channel-list', component: ReportChannelListComponent },

  //Broadcaster Reports
  {
    path: 'report-broadcaster-channel-wise',
    component: ReportBroadcasterChannelWiseComponent,
  },
  {
    path: 'report-broadcaster-week-wise',
    component: ReportBroadcasterWeekWiseComponent,
  },
  {
    path: 'report-bouquet-subscription',
    component: ReportBouquetSubscriptionComponent,
  },
  {
    path: 'report-bouquet-modification',
    component: ReportBoquetModificationComponent,
  },

  //Product/Subscription Reports
  {
    path: 'report-subscribers-list',
    component: ReportSubscribersListComponent,
  },
  {
    path: 'report-subscribers-details',
    component: ReportSubscriberDetailsComponent,
  },
  {
    path: 'report-bouquetwise-channel',
    component: ReportBouquetwiseChannelComponent,
  },
  {
    path: 'report-bouquet-subscription-count',
    component: ReportBouquetSubscriptionCountComponent,
  },
  {
    path: 'report-channel-subscription',
    component: ReportChannelSubscriptionComponent,
  },
  { path: 'report-package-ageing', component: PackageAgeingReportComponent },
  { path: 'report-package-ageing-active-inactive', component: ReportPackageAgeingActiveInactiveComponent },
  
  {
    path: 'report-product-composition',
    component: ReportProductCompositionComponent,
  },
  { path: 'report-todays-expiry', component: ReportTodaysExpiryComponent },
  {
    path: 'report-next-fifteen-days-expiry',
    component: ReportNextFifteenDaysExpiryComponent,
  },
  {
    path: 'report-next-seven-days-expiry',
    component: ReportNextSevenDaysExpiryComponent,
  },
  {
    path: 'report-subscriber-device-replacement',
    component: ReportSubscriberDeviceReplacementComponent,
  },
  {
    path: 'report-subscriber-order-history-active',
    component: ReportOrderHistoryActiveComponent,
  },
  {
    path: 'report-subscriber-order-history-inactive',
    component: ReportOrderHistoryInactiveComponent,
  },
  {
    path: 'report-subwise-ai-ldt',
    component: ReportSubwiseActiveInactiveLDTComponent,
  },
  {
    path: 'report-statewise-susbscriber',
    component: ReportStatewiseSubscriberComponent,
  },
  {
    path: 'report-subscriberwise-subscription-active',
    component: ReportSubscriberwiseSubscriptionActiveComponent,
  },
  {
    path: 'report-subscriberwise-subscription-inactive',
    component: ReportSubscriberwiseSubscriptionInactiveComponent,
  },
  {
    path: 'report-stb-list',
    component: ReportStbListComponent,
  },

  //Miscellaneous Reports
  { path: 'report-osd', component: OsdReportComponent },
  { path: 'report-fingerprint', component: FingerprintReportComponent },
  { path: 'billing', component: ReportLcoBillingComponent },
  {
    path: 'report-suspended',
    component: SuspendedReports,
  },

  {
    path: 'subscriber-authorization-report',
    component: SubscriberAuthorizationReportComponent,
  },
  {
    path: 'subscriber-packages-report',
    component: SubscriberPackagesReportComponent,
  },
  {
    path: 'report-msr',
    component: ReportMsrComponent,
  },
  {
    path: 'subscriber-packages-history',
    component: SubscriberPackagesHistoryComponent,
  },
  { path: 'package-ageing-report', component: PackageAgeingReportComponent },
  {
    path: 'package-ageing-report-inactive',
    component: ReportPackageAgeingInactiveComponent,
  },
  {
    path: 'report-subscriber-status',
    component: ReportSubscriberStatusComponent,
  },
  { path: 'report-subscriber-data', component: ReportSubscriberDataComponent },
  { path: 'report-subscription', component: ReportSubscriptionComponent },
  { path: 'report-sync-cas', component: ReportSyncCasComponent },
  { path: 'report-stb-reconciliation-count', component: ReportStbReconciliationCountComponent },
  { path: 'report-subscriber-reconciliation-count', component: ReportSusbscriberReconciliationCountComponent },
  { path: 'report-channel-reconciliation-count', component: ReportChannelReconciliationCountComponent },
  { path: 'report-boquet-reconciliation-count', component: ReportBoquetReconciliationCountComponent },
  { path: 'report-subscription-reconciliation-count', component: ReportSubscriptionReconciliationCountComponent },
  { path: 'report-subscriptionbyBouquetId-reconciliation-count', component: ReportSubscriptionbyBouquetIdReconciliationCountComponent },
  { path: '', component: TraiHomeComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportTraiRoutingModule { }
