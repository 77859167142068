import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { PackageInfo } from 'src/app/models/package/package-info';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';


@Component({
  selector: 'app-report-bouquet-subscription',
  templateUrl: './report-bouquet-subscription.component.html',
  styleUrls: ['./report-bouquet-subscription.component.scss']
})
export class ReportBouquetSubscriptionComponent implements OnInit {
  reportList = []
  bouquetList: PackageInfo[] = [];
  selectedBouquetId: number =0;
  from:any;
  to:any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config :ConfigService,
    private http : HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    this.fetchBouquetList();
    this.body = {
      ...this.body,
      bouquetId : 'ALL'
    }
    this.fetchData(this.body)
  }

  //get the bouquet names to be displayed in the dropdown list.
  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe(data => {
      //// console.log(data.data)
      this.bouquetList = data.data;
    });
  }

body = {
  fromDate : '',
  toDate : '',
  bouquetId :'',
  searchKeyword :''
}
  fetchData(body : {fromDate : string; toDate : string, bouquetId : string; searchKeyword :string;}): void {

    this.report.getBouquetSubscriptionReport(body).subscribe(data => {
      this.reportList = data.data;
      if(data.data == null){
        this.reportList = []
        this.loadingFlag=false
      this.isLoad='No Records'
      return
      }
      if(data.data?.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }

  loadingFlag=false
  isLoad = 'Choose Date'
  reportDate;
  eve
  dateChange(ev: NgxDateRangeInfo): void {
    this.loadingFlag = true
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve = ev
      this.body = {
        ...this.body,
        fromDate : ev.startDate.format('YYYY-MM-DD'),
        toDate : ev.endDate.format('YYYY-MM-DD')
      }
      this.fetchData(this.body);
      this.reportDate = ev.startDate.date()+"-"+this.doc.monthNameCalculator(ev.startDate.month())+"-"+ev.startDate.year()+" to "+ev.endDate.date()+"-"+this.doc.monthNameCalculator(ev.endDate.month())+"-"+ev.endDate.year()
      this.calendar.setDate(ev);
      // // console.log(this.calendar)
    }
  }

  boquetName
  bouquetSelect(): void {
    for(let ele of this.bouquetList){
      if(ele.id == this.selectedBouquetId){
        this.boquetName = ele.name
      }
      this.selectedBouquetId ==0 ? this.boquetName ='All':null
    }
    this.body = {
      ...this.body,
      bouquetId : this.boquetName
    }
    this.fetchData(this.body);
  }

  downloadTableExcel(): void {
    let name='Bouquet Subscription Report'
    this.doc.exportTableToExcelwithImage(this.reportTable, name,'bouquet_subscription_report', undefined, undefined, 'Report Date :', this.reportDate, 'Boquet name :', this.boquetName);
  }

  downloadTablePdf(): void {
    if(this.reportList == undefined || this.reportList == null || this.reportList?.length == 0){
      alert('No Data')
      return;
    }
    this.pdf.generatePdfHtml('#reportTable',
    'bouquet_subscription_report',
    `From Date: ${this.reportDate ? this.reportDate : "Till now"} Bouquet ID: ${this.selectedBouquetId==0? 'All' : this.selectedBouquetId},  Source Type : SMS`
    );
  }

  formatDate(ele) {
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No","Subscriber Code","Subscriber Name","Bouquet Name","Bouquet ID","Mac Address","Start Date","End Date"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo : i,
        subscriber_code: line.subscriber_code,
        subscriber_name:line.subscriber_name,
        bouquet_name_list: line.bouquet_name_list,
        bouquet_id_list: line.bouquet_id_list,
        ethernet_mac_address: line.ethernet_mac_address,
        start_date: line.start_date,
        end_date: line.end_date,
      }
      data.push(csvLine);
      i += 1
    });

    new ngxCsv(data, 'bouquet_subscription_report', options);
}

searchKeyword(){
  let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
  this.body = {
    ...this.body,
    searchKeyword : value
  }
  this.fetchData(this.body)
}
}
