import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { SubscriberGroupInfo } from 'src/app/models/subscriber-group/subscriber-group-info';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-subscriber-group',
  templateUrl: './list-subscriber-group.component.html',
  styleUrls: ['./list-subscriber-group.component.scss']
})
export class ListSubscriberGroupComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;
  subscriberGroupCount : number;
  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    name: 'name',
    number: 'number',
    groupId: 'groupId',
    desc: 'description',
    date: 'createdDate',
    status: 'status',
    url: 'url',
    description: 'description'
  };

  groupList: SubscriberGroupInfo[] = [];

  activeChannelCount = 0;

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
  }

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  fetchPage(): void {
    this.auth.isLCO() ? this.fetchLco() : this.fetchmso();
  }

  fetchmso(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('subscribergroup/getSubscriberGroups', this.paginationObj);
    let url = this.config.json.apiEndpointRdbms+'subscribergroup/getSubscriberGroups/'+this.paginationObj.pageNumber+'?pageSize='+this.paginationObj.pageSize+'&sortBy='+this.paginationObj.sortBy+'&sortOrder='+this.paginationObj.sortOrder+'&searchKeyword='+this.paginationObj.searchKeyword

    this.loadingFlag = true;

    this.http.get(url).pipe(delay(250)).subscribe((data: PageInfo<SubscriberGroupInfo>) => {
      this.loadingFlag = false;
      this.handleFetchPage(data);
    });
  }

  fetchLco(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('subscribergroup/getSubscriberGroupsByLCOId', this.paginationObj)
    //   + '&lcoId=' + this.auth.getLcoId();
    // const url = environment.apiEndpointRdbms + 'subscribergroup/getSubscriberGroupsByLCO/' + this.auth.getLcoId();
    // const url = PaginationUtilities.urlBuilderRdbms('subscribergroup/getSubscriberGroups', this.paginationObj);
    let url = this.config.json.apiEndpointRdbms+'subscribergroup/getSubscriberGroups/'+this.paginationObj.pageNumber+'?pageSize='+this.paginationObj.pageSize+'&sortBy='+this.paginationObj.sortBy+'&sortOrder='+this.paginationObj.sortOrder+'&searchKeyword='+this.paginationObj.searchKeyword

    // this.loadingFlag = true;

    this.http.get(url).subscribe(data => {
      this.loadingFlag = false;
      // this.groupList = data;
      this.handleFetchPage(data);
    });
  }

  handleFetchPage(data: PageInfo<SubscriberGroupInfo>): void {
    this.groupList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.subscriberGroupCount = data.metadata.totalElementCount
    this.handlePaginationBtnDisableStatuses();
  }

  pageNumber; pageSize;
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.pageNumber = metadata.pageNumber
    this.pageSize = metadata.pageSize
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: SubscriberGroupInfo): void {
    this.router.navigate(['/manage/subscriber-groups', record.id]);
  }

}
