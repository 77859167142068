import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { ReportTraiDataService } from 'src/app/report-trai/plugins/report-trai-data.service';

@Component({
  selector: 'app-report-subscriber-lco-distributor',
  templateUrl: './report-subscriber-lco-distributor.component.html',
  styleUrls: ['./report-subscriber-lco-distributor.component.scss']
})
export class ReportSubscriberLcoDistributorComponent implements OnInit {

  reportList = [];
  from: any = '';
  to: any = '';
  currentDate = new Date();
  dateString;
  eve;
  distributorList=[]
  lcoList = []
  selectedLcoId =0
  selectedDistributorId = 0
  
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}
  ngOnInit(): void {
    this.fetchDistributorList()
    this.fetchLcoList()
    this.fetchData()
  }

  
  fetchDistributorList():void{
    let url =
      this.config.json.apiEndpointRdbms + `distributor/search?searchKeyword=`;
    this.http.get(url).subscribe((data) => {
      this.distributorList = data.data; 
    });
  }


  fetchLcoList(): void {
    this.report.getLCOList().subscribe((data) => {
      this.lcoList = data.data;
    });
  }


  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.from = ev.startDate.format('YYYY-MM-DD');
      this.to = ev.endDate.format('YYYY-MM-DD');
      console.log(this.from)
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  fetchData(): void {
    let url=this.config.json.apiEndpointRdbms+'customreport/subscriberReportLcoDistributor'
    let body ={
      "fromDate":this.from,
      "toDate":this.to,
      "distributorId":Number(this.selectedDistributorId),
      "lcoId":Number(this.selectedLcoId)
    }
    this.http.post(url,body).subscribe(
      (res) => {
        this.reportList = res.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  loadingFlag = false;
  isLoad = 'Choose Date';

  statUS = ['Inactive', 'Active', 'Blacklisted', 'New', 'Terminated'];

  // downloadTableExcel(): void {
  //   if (
  //     this.reportList == null ||
  //     this.reportList == undefined ||
  //     this.reportList?.length == 0
  //   ) {
  //     alert('No Data!');
  //     return;
  //   }
  //   let status = [...this.statUS, 'ALL'],filterName
  //   if(this.from!=''&& this.to!=''){
  //     filterName = "from : "+this.from +" , to: "+ this.to+" , Distributor Id: "+this.selectedDistributorId+" , LCO Id: "+ this.selectedLcoId
  //   }
  //   else{
  //     filterName ="Distributor Id: "+this.selectedDistributorId+ " , LCO Id: "+ this.selectedLcoId;
  //   }
  //   let name = 'Active Subscriber  Count Report';
  //   this.doc.exportTableToExcelwithImage(this.reportTable,name,'active_subscriber_count_report',
  //     undefined,undefined,'Filters',filterName);
  // }

   
downloadTableExcel(){
  if (
        this.reportList == null ||
        this.reportList == undefined ||
        this.reportList?.length == 0
      ) {
        alert('No Data!');
        return;
      }
  let url=''
  let body={
   fromDate:this.from,
   toDate:this.to ,
   distributorId:this.selectedDistributorId,
   lcoId:this.selectedLcoId
  }
     url= this.config.json.apiEndpoint+'generate/subscriberReportLcoDistributor'

 this.http.postwithresponseType(url,body).subscribe(
   (response:Blob) => {
       const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       const url = window.URL.createObjectURL(blob);
       const a = document.createElement('a');
       a.href = url;
       a.download = 'active_subscriber_count_report.xlsx';
       a.click();
       window.URL.revokeObjectURL(url);
     },
     (error) => {
       console.error('Error downloading Excel file:', error);
     }
 )
}


  downloadTablePdf(): void {
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    // this.selectedDistributor
    if(this.from!='' && this.to !='' ){
    this.pdf.generatePdfHtml(
      '#reportTable',
      'active_subscriber_count_report',
      `Source type : SMS ,Distributor ID : ${this.selectedDistributorId} , LCO : ${this.selectedLcoId} , from : ${this.from},
      to: ${this.to}`,
      null,
      null,
      'a3'
    );
  }
    else{
      this.pdf.generatePdfHtml(
        '#reportTable',
        'active_subscriber_count_report',
        `Source type : SMS ,Distributor ID : ${this.selectedDistributorId} , LCO : ${this.selectedLcoId} `,
        null,
        null,
        'a3'
      );
  }
  }
  downloadTableCSV(): void {
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Sl No',
        'LCO ID',
        'LCO Code',
        'Distributor ID',
        'Distributor Code',
        'Active SUbscriptions',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((report) => {
      let csvLine = {
        slNo: i,
        subsciberId: report.lcoId,
        subscriberCode: report.lcoCode,
        subscriberName: report.distributorId ? report.distributorId : 'No Data',
        subscriberAddress: report.distributorCode ? report.distributorCode : 'No Data',
        activeSubscriptions:report.activeSubscriber
     
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'active_subscriber_count_report', options);
  }

  status;

  searchWord;
  searchKeyword() {
    this.loadingFlag = true;
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    this.searchWord = value;
    let url =
      this.config.json.apiEndpointRdbms +
      `subscriber/getsubscribersListReport/?status=${
        this.status ? this.status : 5
      }&searchKeyword=` +
      value;
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }
}
