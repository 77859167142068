import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { MsoPaymentsComponent } from './mso-payments/mso-payments.component';
import { SubscriberRevenueComponent } from './subscriber-revenue/subscriber-revenue.component';

const routes: Routes = [
  // { path: 'subscriber-revenue', component: SubscriberRevenueComponent },
  // { path: 'mso-payments', component: MsoPaymentsComponent },
  // { path: 'invoice', pathMatch: 'full', redirectTo: 'invoice/list' },
  // { path: 'invoice/list', component: InvoiceListComponent },
  // { path: 'invoice/detail/:id', component: InvoiceDetailsComponent },
  {
    path: 'list',
    component: InvoiceListComponent
  },
  {
    path: 'detail/:id',
    component: InvoiceDetailsComponent
  },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule { }
