import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { OsdMessageInfo } from 'src/app/models/osd/osd-message-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { SortOrder, PageMetadata, PageInfo } from 'src/app/models/pagination/page-info';
import { delay } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-osd-message',
  templateUrl: './osd-message.component.html',
  styleUrls: ['./osd-message.component.scss']
})
export class OsdMessageComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;
  errorFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    title: 'title',
    message: 'message',
    udate: 'updatedDate',
    cdate: 'createdDate'
  };

  editFlag = false;
  updateFlag = false;

  messageForm: FormGroup;

  messageList: OsdMessageInfo[] = [];
  position: number;
  message: OsdMessageInfo;

  search = '';

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
  }

  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    const url = PaginationUtilities.urlBuilder('osd/message/list', this.paginationObj);
    this.loadingFlag = true;

    this.http.get(url).subscribe((data: PageInfo<OsdMessageInfo>) => {
      this.loadingFlag = false;
      this.handleFetchPage(data);
    },
      (error) => {
        // //// console.log(error)
        // window.alert(error.error.status+" "+error.error.error)
        this.loadingFlag = false;
        this.errorFlag = true;
      });
  }

  handleFetchPage(data: PageInfo<OsdMessageInfo>): void {
    this.messageList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  create(): void {
    this.initForm();
    this.editFlag = true;
    this.updateFlag = false;
  }

  update(msg: OsdMessageInfo, index: number): void {
    this.initForm(msg);
    this.editFlag = true;
    this.updateFlag = true;
    this.position = index;
    this.message = msg;
  }

  initForm(msg?: OsdMessageInfo): void {
    this.messageForm = new FormGroup({
      title: new FormControl(msg && msg.title ? msg.title : '', [Validators.required]),
      message: new FormControl(msg && msg.message ? msg.message : '', [Validators.required])
    });
  }

  cancel(): void {
    this.editFlag = false;
  }

  persist(): void {
    this.updateFlag ? this.edit() : this.save();
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'osd/message/create';
    this.http.post(url, this.messageForm.value).subscribe((data) => {
      this.messageList.push(data);
      this.editFlag = false;
      alert(data.desc)
      this.fetchPage()
    });
  }

  edit(): void {
    const url = this.config.json.apiEndpointRdbms + 'osd/message/update';
    this.updatefields();
    this.http.put(url, this.message).subscribe((data) => {
      this.messageList[this.position] = data;
      this.editFlag = false;
      alert(data.desc)
      this.fetchPage()
    });
  }

  updatefields(): void {
    this.message.title = this.messageForm.get('title').value;
    this.message.message = this.messageForm.get('message').value;
  }

  delete(msg: OsdMessageInfo, index: number): void {
    const url = this.config.json.apiEndpointRdbms + 'osd/message/' + msg.id;
    this.http.delete(url).subscribe((data) => {
      alert(`${msg.id} deleted`)
      this.messageList.splice(index)});
    
  }

}
