import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FingerprintRoutingModule } from './fingerprint-routing.module';
import { FingerprintSettingsComponent } from './fingerprint-settings/fingerprint-settings.component';
import { SharedModule } from '../shared/shared.module';
import { LcoModule } from '../lco/lco.module';


@NgModule({
  declarations: [FingerprintSettingsComponent],
  imports: [
    CommonModule,
    FingerprintRoutingModule,
    SharedModule,
    LcoModule
  ]
})
export class FingerprintModule { }
