import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../config.service';
import { HttpClientWrapperService } from '../http-client-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriberDataService {

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  getSubscribersByPagination(pageObj: PaginationObj): Observable<any> {
    // const url = PaginationUtilities.urlBuilderRdbms('subscriber/getSubscribers', pageObj)
    const url = this.config.json.apiEndpointRdbms+'subscriber/getSubscribers/'+pageObj.pageNumber+'?pageSize='+pageObj.pageSize+'&sortBy='+pageObj.sortBy+'&sortOrder='+pageObj.sortOrder+'&searchKeyword='+pageObj.searchKeyword

    return this.http.get(url);
  }
}
