import { Component, OnInit } from '@angular/core';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { UploadFileService } from '../upload-file.service';


@Component({
  selector: 'app-bulk-upload-channel',
  templateUrl: './bulk-upload-channel.component.html',
  styleUrls: ['./bulk-upload-channel.component.scss']
})
export class BulkUploadChannelComponent implements OnInit {

  title = 'File-Upload-Save';
  selectedFiles: FileList;
  currentFileUpload: File;
  selectedFile = null;

  // selectedFile: FileList;
  // currentFile: File;

  fileName = '';

  constructor(
    private uploadService: UploadFileService
  ) { }

  ngOnInit(): void {
  }

  upload() {
    this.uploadService.pushFileToStorage(this.currentFileUpload).subscribe(event => {
      // //// console.log(event);
      alert(event.message)
      this.selectedFiles = undefined;
     }, (error) => {
       alert(error.error["message"])
     }
    );
  }

  // uploadExcel(event) {
  //   let formData = new FormData();
  //   this.selectedFile = event.target.files;
  //   this.currentFile = this.selectedFile.item(0);
  //   formData.append('file', this.currentFile);
  //   if(this.currentFile) {
  //     const fileUpload = this.uploadService.uploadExcel(formData);
  //     fileUpload.subscribe(
  //       (res) => {
  //         if(res === null) {
  //           alert('File uploaded succesfully');
  //         }
  //       },
  //       (err) => {
  //         alert('Please check the format');
  //       }
  //     )
  //   }
  // }

  selectFile(files: FileList) {
    // //// console.log(files)
    this.currentFileUpload = files.item(0);
  }
}
