import { Component, OnInit } from '@angular/core';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PackageInfo } from 'src/app/models/package/package-info';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import {
  SortOrder,
  PageMetadata,
  PageInfo,
} from 'src/app/models/pagination/page-info';
import { delay } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-list-bouquet',
  templateUrl: './list-bouquet.component.html',
  styleUrls: ['./list-bouquet.component.scss'],
})
export class ListBouquetComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    name: 'name',
    desc: 'description',
    date: 'createdDate',
    status: 'status',
    price: 'price',
    packType: 'packType'
  };

  packageList: PackageInfo[] = [];

  activePackageCount = 0;
  //Bouquet counts
  BouquetActiveCount: number;
  BouquetInActiveCount: number;
  TotalBouquetCount: number;
  bouquetDetails
  viewModal = false;
  prefix = this.config.json.imageUrlPrefix

  isDistributor;

  showDisablePopup = false;
  activeSubscriptions = []

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private config: ConfigService
  ) { }



  ngOnInit(): void {
    this.fetchPage();
    this.fetchBouquetCount();
    this.isDistributor = this.auth.isDistributor();
  }


  viewBouquetDetails(subDetails: any) {
    let bouquetId = subDetails.id,
      url = `${this.config.json.apiEndpoint}bouquet/channels/` + bouquetId;
    this.http.get(url)
      .subscribe({
        next: (res: any) => {
          this.bouquetDetails = res.data;
          this.viewModal = true;
        },
      });
  }

  fetchBouquetCount() {
    const url =
      this.config.json.apiEndpointRdbms +
      'bouquet/getBouquetTotalCountAndActiveCount';
    this.http.get(url).subscribe((res) => {
      this.BouquetActiveCount = res.data.activeBouquetCount;
      this.BouquetInActiveCount = res.data.inactiveBouquetCount;
      this.TotalBouquetCount = res.data.bouquetTotalCount;
    });
  }
  fetchPage(): void {
    this.auth.isLCO() ? this.fetchLco() : this.fetchmso();
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }

  fetchmso(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('bouquet/getBouquets', this.paginationObj);
    const url =
      this.config.json.apiEndpointRdbms +
      'bouquet/getBouquets/' +
      this.paginationObj.pageNumber +
      '?pageSize=' +
      this.paginationObj.pageSize +
      '&sortBy=' +
      this.paginationObj.sortBy +
      '&sortOrder=' +
      this.paginationObj.sortOrder +
      '&searchKeyword=' +
      this.paginationObj.searchKeyword;
    this.loadingFlag = true;

    this.http
      .get(url)
      .pipe(delay(250))
      .subscribe((data: PageInfo<PackageInfo>) => {
        this.loadingFlag = false;
        this.handleFetchPage(data);
      });
  }

  fetchDisrbutors() {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      'distributor/bouquetList/',
      this.paginationObj
    );
    url += `&distributor=${this.auth.getLcoId()}`
    this.http.get(url).subscribe((data) => {
      this.loadingFlag = false;
      this.handleFetchPage(data);
    });
  }
  bouquetId

  validateToDisable(packages) {
    const url = this.config.json.apiEndpoint + 'bouquet/validate' + '/' + packages.id
    this.http.get(url).subscribe(
      (data) => {
        if (data.data.isError == false) {
          this.bouquetId = packages.id
          this.disablePackage(packages.id)
        }
        else {
          this.activeSubscriptions = data.data.activeSubscription
          this.showDisablePopup = true
        }
      })
  }
  disablePackage(id) {
    if (confirm("Are you sure do you want to Disable this Package")) {
      const url = this.config.json.apiEndpoint + 'bouquet/disable' + '/' + id
      this.http.put(url, {}).subscribe(
        (data) => {
          if (data.statusCode == 200) {
            alert("Disabled Successfully")
            this.fetchLco()
          }
          else
            alert(data.message)
        }
      )
    }
  }

  enable(packages) {
    let body = packages
    const url = this.config.json.apiEndpointRdbms + 'bouquet/updateBouquet';
    body.status = 1;
    this.http.put(url, body).subscribe(
      (data) => {
        if (data.statusCode == 200) {
          alert("Enabled Successfully")
          this.fetchLco()
        }
        else {
          this.fetchLco()
          alert(data.message)
        }
      }
    )
  }

  fetchLco(): void {
    // const url = PaginationUtilities.urlBuilderRdbms('bouquet/getBouquets', this.paginationObj);
    const url =
      this.config.json.apiEndpointRdbms +
      'bouquet/getBouquets/' +
      this.paginationObj.pageNumber +
      '?pageSize=' +
      this.paginationObj.pageSize +
      '&sortBy=' +
      this.paginationObj.sortBy +
      '&sortOrder=' +
      this.paginationObj.sortOrder +
      '&searchKeyword=' +
      this.paginationObj.searchKeyword;

    this.http.get(url).subscribe((data) => {
      this.loadingFlag = false;
      this.handleFetchPage(data);
    });
  }

  handleFetchPage(data: PageInfo<PackageInfo>): void {
    this.packageList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countPackages();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC
        ? SortOrder.ASC
        : SortOrder.DESC;
    this.fetchPage();
  }

  countPackages(): void {
    this.activePackageCount = this.packageList.filter((ch) => ch.status).length;
  }

  edit(record: PackageInfo): void {
    this.router.navigate(['content/bouquet/edit', record.id]);
  }

  // updateStatus(record: PackageInfo): void {
  //   record.status === 1 ? this.deactivate(record) : this.activate(record);
  // }

  deactivate(record: PackageInfo): void {
    const url =
      this.config.json.apiEndpointRdbms + 'packages/deactivate/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countPackages();
      window.alert('Deactivated');
    });
  }

  activate(record: PackageInfo): void {
    const url =
      this.config.json.apiEndpointRdbms + 'packages/activate/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countPackages();
      window.alert('Activated');
    });
  }
}
