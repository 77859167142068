import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';
import { SettingsInfo } from 'src/app/models/settings/settings-info';
import { FingerprintConfiguration } from 'src/app/models/fingerprint/fingerprint-configuration-info';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(
    private http: HttpClientWrapperService,
    private imageService: ImageUploadService,
    private config: ConfigService
  ) { }

  settingsForm: FormGroup;

  settings = {} as SettingsInfo;

  brandLogo: File;


  ngOnInit(): void {
    this.getSettings();
    this.initForm();
  }

  getSettings(): void {
    const url = this.config.json.apiEndpoint + 'setting/getSettings';
    this.http.get(url).subscribe((data: SettingsInfo[]) => {
      this.settings = data[0];
      this.updateForm();
    });
  }

  initForm(): void {
    this.settingsForm = new FormGroup({
      mailAutoExpiry: new FormControl(null, [Validators.required]),
      menuDisplayTimer: new FormControl(null, [Validators.required]),
      welcomeMessage: new FormControl(null, [Validators.required]),
      brandLogo: new FormControl(null),
      ncfPrimaryLte: new FormControl(null, [Validators.required]),
      ncfPrimaryGt: new FormControl(null, [Validators.required]),
      ncfSecondaryLte: new FormControl(null, [Validators.required]),
      ncfSecondaryGt: new FormControl(null, [Validators.required]),
      gstPercent: new FormControl(null, [Validators.required]),
      initialChannelId: new FormControl(null, [Validators.required]),
    });
  }

  updateForm(): void {
    this.settingsForm.get('menuDisplayTimer').setValue(this.settings.menuDisplayTimer);
    this.settingsForm.get('mailAutoExpiry').setValue(this.settings.mailAutoExpiry);
    this.settingsForm.get('welcomeMessage').setValue(this.settings.welcomeMessage);
    this.settingsForm.get('ncfPrimaryLte').setValue(this.settings.ncfPrimaryLte);
    this.settingsForm.get('ncfPrimaryGt').setValue(this.settings.ncfPrimaryGt);
    this.settingsForm.get('ncfSecondaryLte').setValue(this.settings.ncfSecondaryLte);
    this.settingsForm.get('ncfSecondaryGt').setValue(this.settings.ncfSecondaryGt);
    this.settingsForm.get('gstPercent').setValue(this.settings.gstPercent);
    this.settingsForm.get('initialChannelId').setValue(this.settings.initialChannelId);

  }

  onLogoPicked(event: Event): void {
    const FILE = (event.target as HTMLInputElement).files[0];
    this.brandLogo = FILE;
  }

  persist(): void {
    if (this.brandLogo) {
      this.uploadLogo();
    } else {
      this.save();
    }
  }

  save(): void {
    const url = this.config.json.apiEndpoint + 'setting/updateSettings';
    this.http.put(url, this.settingsForm.value).subscribe((data) => {
      window.alert('Updated');
    });
  }


  uploadLogo(): void {
    if (this.brandLogo) {
      const imageForm = new FormData();
      imageForm.append('image', this.brandLogo);
      this.imageService.imageUpload(imageForm).subscribe(
        (res) => {
          if (res.image) {
            this.settings.brandLogo = res.image;
            this.settingsForm.get('brandLogo').setValue(res.image);
          }
          this.save();
        },
        (err) => {
          alert('Image upload failed');
          this.save();
        }
      );
    } else {
      this.save();
    }
  }

}

