<div class="cms__content">
  <div class="top__heading">
    <h3>{{ isEdit ? "Edit STB" : "New STB" }}</h3>
    <a [routerLink]="['/manage/stb/list']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="clearfix"></div>
  <form action="" [formGroup]="stbForm" (ngSubmit)="persist()">
    <div class="col-12 form__style">
      <div class="row">
        <div class="col-12 col-md-6">
          <label>Manufacturer Name</label>
          <input
            class="custom__field"
            list="manu__names"
            name="manu__name"
            id="manu__name"
            formControlName="manufacturer"
            [ngClass]="{
              error__field: !manufacturer.valid && manufacturer.touched
            }"
          />
          <div [hidden]="!manufacturer.touched">
            <span class="error__msg" *ngIf="manufacturer.hasError('required')"
              >Please enter the Manufacturer Name</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Serial Number</label>
          <input
            class="custom__field"
            formControlName="serialNo"
            [ngClass]="{ error__field: !serialNo.valid && serialNo.touched }"
          />
          <div [hidden]="!serialNo.touched">
            <span class="error__msg" *ngIf="serialNo.hasError('required')"
              >Please enter the Serial Number</span
            >
          </div>
        </div>

        <!-- <div class="col-12 col-md-6">
          <label>MAC Address</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="macAddress"
            [ngClass]="{'error__field': !macAddress.valid && macAddress.touched}" />
          <div [hidden]="!macAddress.touched">
            <span class="error__msg" *ngIf="macAddress.hasError('required')">Please enter macAddress</span>
            <span class="error__msg" *ngIf="macAddress.hasError('pattern')">macAddress is invalid</span>
            <span class="error__msg" *ngIf="macAddress.errors?.duplicate">macAddress is taken</span>
          </div>
        </div> -->

        <div class="col-12 col-md-6">
          <label>Ethernet MAC Address</label>
          <input
            type="text"
            class="custom__field"
            formControlName="ethMacAddress"
            [ngClass]="{
              error__field: !ethMacAddress.valid && ethMacAddress.touched
            }"
          />
          <div [hidden]="!ethMacAddress.touched">
            <span class="error__msg" *ngIf="ethMacAddress.hasError('required')"
              >Please enter MAC Address</span
            >
            <span class="error__msg" *ngIf="ethMacAddress.hasError('pattern')"
              >MAC Address is invalid</span
            >
            <span class="error__msg" *ngIf="ethMacAddress.errors?.duplicate"
              >ethMacAddress is taken</span
            >
            <span class="error__msg" *ngIf="ethMacAddress.errors?.duplicate"
              >ethMacAddress is taken</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Wifi MAC Address</label>
          <input
            type="text"
            class="custom__field"
            formControlName="wifiMacAddress"
            [ngClass]="{
              error__field: !wifiMacAddress.valid && wifiMacAddress.touched
            }"
          />
          <div [hidden]="!wifiMacAddress.touched">
            <span class="error__msg" *ngIf="wifiMacAddress.hasError('required')"
              >Please enter MAC Address</span
            >
            <span class="error__msg" *ngIf="wifiMacAddress.hasError('pattern')"
              >MAC Address is invalid</span
            >
            <!-- <span class="error__msg" *ngIf="wifiMacAddress.errors?.duplicate">wifiMacAddress is taken</span> -->
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Total Storage Space (in MB)</label>
          <input
            type="number"
            class="custom__field"
            formControlName="totalStorage"
            [ngClass]="{
              error__field: !totalStorage.valid && totalStorage.touched
            }"
          />
          <div [hidden]="!totalStorage.touched">
            <span class="error__msg" *ngIf="totalStorage.hasError('required')"
              >Please enter Total Storage</span
            >
            <span class="error__msg" *ngIf="totalStorage.hasError('min')"
              >Storage space cannot be negative</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Model</label>
          <input
            type="text"
            class="custom__field"
            formControlName="model"
            [ngClass]="{ error__field: !model.valid && model.touched }"
          />
          <div [hidden]="!model.touched">
            <span class="error__msg" *ngIf="model.hasError('required')"
              >Please enter the Model</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>OS Version</label>
          <input
            type="text"
            class="custom__field"
            formControlName="osVersion"
            [ngClass]="{ error__field: !osVersion.valid && osVersion.touched }"
          />
          <div [hidden]="!osVersion.touched">
            <span class="error__msg" *ngIf="osVersion.hasError('required')"
              >Please enter the OS Version</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Software Version</label>
          <input
            type="text"
            class="custom__field"
            formControlName="swVersion"
            [ngClass]="{ error__field: !swVersion.valid && swVersion.touched }"
          />
          <div [hidden]="!swVersion.touched">
            <span class="error__msg" *ngIf="swVersion.hasError('required')"
              >Please enter the Software Version</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Network Type</label>
          <input
            type="text"
            class="custom__field"
            formControlName="networkType"
            [ngClass]="{
              error__field: !networkType.valid && networkType.touched
            }"
          />
          <div [hidden]="!networkType.touched">
            <span class="error__msg" *ngIf="networkType.hasError('required')"
              >Please enter the Network Type</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Activation Status</label>
          <select class="custom__field" formControlName="activationStatus">
            <option [ngValue]="1">Activated</option>
            <option [ngValue]="0">Deactivated</option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <label>Blacklisted Status</label>
          <select class="custom__field" [disabled]="stb.blacklistStatus == 1" formControlName="blacklistStatus">
            <option [ngValue]="0">No</option>
            <option [ngValue]="1">Yes</option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <label>Faulty Status</label>
          <select class="custom__field" formControlName="faultyStatus">
            <option [ngValue]="0">Working</option>
            <option [ngValue]="1">Repairable</option>
            <option [ngValue]="2">Unrepairable</option>
          </select>
        </div>

        <div class="col-12 col-md-6">
          <label>Assigned Status</label>
          <select class="custom__field" formControlName="assignedFlag">
            <option [ngValue]="0">Fresh</option>
            <option [ngValue]="1">Assigned</option>
            <option [ngValue]="2">Unassigned</option>
          </select>
        </div>

        <div class="col-12 col-md-6" *ngIf="!isEdit">
          <label>Assign to Partner</label>
          <select class="custom__field" formControlName="lcoId">
            <option *ngFor="let lco of lcoList" [ngValue]="lco.id">
              {{ lco.name }}
            </option>
          </select>
        </div>

        <div class="col-12 col-md-6" *ngIf="isEdit">
          <label>Assign to Partner</label>
          <select class="custom__field" formControlName="lcoId" disabled>
            <option *ngFor="let lco of lcoList" [ngValue]="lco.id">
              {{ lco.name }}
            </option>
          </select>
        </div>

        <div class="col-12">
          <!-- <button type="button" [routerLink]="['/stb/list']" class="default__button">Cancel</button> -->
          <button class="cta float-right" [disabled]="checkFormValidity()">
            Save
          </button>
          <!-- <div *ngIf="stb[0] === 'error'" class="alert alert-danger">
            {{ stb.error.message }}
        </div> -->
        </div>
      </div>
    </div>
  </form>
</div>
