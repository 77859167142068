import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { ListCategoryComponent } from './list-category/list-category.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListCategoryComponent },
  { path: ':id', component: CreateCategoryComponent },
  { path: 'create', component: CreateCategoryComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CategoryRoutingModule { }
