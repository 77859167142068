import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';


@Component({
  selector: 'app-report-stb-warehouse-stock',
  templateUrl: './report-stb-warehouse-stock.component.html',
  styleUrls: ['./report-stb-warehouse-stock.component.scss']
})
export class ReportStbWarehouseStockComponent implements OnInit {

  reportList = [];
  from:any;
  to:any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }


  ngOnInit(): void {
  }

  fetchData(from: any, to: any): void {
    this.report.getStbWarehouseStockReport(from, to, {}).subscribe(data => {
      this.reportList = data;
      if(this.reportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }

  loadingFlag=false
  isLoad = 'Choose Date'

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.calendar.setDate(ev);
      this.fetchData(ev.startDate, ev.endDate);
      this.loadingFlag = true
      this.isLoad = 'Loading'
    }
  }

  downloadTableExcel(): void {
    let name='Stb Warehouse Stock Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'stb_warehouse_stock_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable',
    'stb_warehouse_stock_report',
    `From Date: ${this.from.toISOString().slice(0,10)}     To Date: ${this.to.toISOString().slice(0,10)},  Source Type : SMS`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Date", "Fresh STBs for the day", "Total Fresh STBs"]
    };
    let data = []
    this.reportList.forEach(line => {
      let csvLine = {
        createdDate: line.createdDate,
        freshSTBs: line.freshSTBs,
        totalFreshSTBs: line.totalFreshSTBs,
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'stb_warehouse_stock_report', options);
  }

}
