<div class="cms__content">
  <app-reports-header></app-reports-header>
  <div class="top__heading">
    <h3>Bouquet Status Change Logs</h3>
    <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
  </div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <label for="">Select Date :</label>
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/excel.svg" alt="download" />
      </button>
    </div>


    <div class="col-12 col-md-6 report_selection_block">
      <label class="report_selection_label">Select Bouquet</label>
      <select class="custom__field report_selection" [(ngModel)]="selectedBouquetId" (change)="listItem()" >
        <option value="0" >ALL</option>
        <option *ngFor="let bq of bouquetList" [ngValue]='bq'>{{bq.name}}</option>
      </select>
    </div>
  </div>
</div>

<div class="col-12 col-md-6">
  <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
</div>

<div class="col-12 list__table">
  <div class="row scroll">
    <table class="custom__iptv__table" #reportTable id="reportTable">
      <thead>
        <tr>
          <th>Sl No.</th>
          <th>Bouquet Name</th>
          <th>Bouquet ID</th>
          <th>Bouquet Status</th>
          <th>Bouquet Type</th>
          <!-- <th>ID</th> -->
          <th>Modified by</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="reportList.length ==0 && !loadingFlag" >
          <td style="text-align: center !important;" colspan="12" >{{isLoad}}</td>
        </tr>
        <tr *ngIf="reportList.length ==0 && loadingFlag">
          <td style="text-align: center !important;" colspan="12">
          <app-custom-spinner></app-custom-spinner>
          </td>
        </tr>
          <tr *ngFor="let report of reportList, let j=index">
            <td>{{j+1}}</td>
            <td>{{report.product_name? report.product_name : '---' }}</td>
            <td>{{report.id? report.id : '---'}}</td>
            <td>{{report.product_status ==1 ? 'Active':'Inactive'}}</td>
            <td>{{report.product_type ===1?'Bouquet':'Channel'}}</td>
            <!-- <td>{{report.id}}</td> -->
            <td>{{report.modified_by? report.modified_by : '---'}}</td>
            <td>{{report.created_date | date:'dd-mm-yyyy hh:mm:ss' : 'UTC'}}</td>
          </tr>
      </tbody>
    </table>
  </div>
</div>
