import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-region',
  templateUrl: './list-region.component.html',
  styleUrls: ['./list-region.component.scss']
})
export class ListRegionComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  LcoActiveCount: number;
  TotalLcoCount: number;
  sortByProperties = {
    name: 'name',
    email: 'email',
    mobile: 'mobile',
    date: 'createdDate',
    status: 'status',
    cp: 'contactPerson',
    tp: 'transferPercent',
    distributorCode: 'distributorCode'
  };

  regionList = []
  pageSize;
  pageNumber;
  activatedCount = 0;

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService,
  ) { }

  isDistributor = false;
  ngOnInit(): void {
    this.fetchPage();
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }


  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    // let url = PaginationUtilities.urlBuilder('region/getRegion', this.paginationObj);
    let url = this.config.json.apiEndpoint + 'region/getRegion'
    this.loadingFlag = true;

    this.http.get(url).subscribe(
      (data) => {
        this.loadingFlag = false;
        this.handleFetchPage(data);
      });
  }

  handleFetchPage(data: PageInfo<LCOInfo>): void {
    this.regionList = data.data;
    // this.handlePaginationMetadata(data.metadata);
    // this.handlePaginationBtnDisableStatuses();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.pageSize = metadata.pageSize;
    this.pageNumber = metadata.pageNumber;
  }


  handlePaginationBtnDisableStatuses(): void {
    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record): void {
    this.router.navigate(['/content/statewise-lcn/edit/', record.id]);
  }
  assignLCM() {

  }

  viewLCMModal = false;
  viewLCNModal = false;
  showAddLCNModal = false;
  regionId = '';
  lcm
  LCMDetails = [];
  LCNDetails = [];
  lcmvalue 


  getLCNDetails(id) {
    let url = `${this.config.json.apiEndpoint}regionLCN/` + id;
    this.http.get(url).subscribe(
      (res) => {
        if(res.statusCode==200){
          if(res.data.length==0){
            alert("No LCN Set for this region.Please set LCN to")
          }
          else{
            this.router.navigateByUrl('/content/statewise-lcn/lcm/' + id)
          }
        }
        else{
          alert("No LCN Set for this region.Please set LCN to")}
      })
  }

  navigateToLCM(regionDetails) {
    this.regionId = regionDetails.id
    this.getLCNDetails(this.regionId)
  }


  viewLCNDetails(regionDetails: any) {
    this.regionId = regionDetails.id
    console.log(regionDetails)
    console.log(this.regionId)
    let url = `${this.config.json.apiEndpoint}regionLCN/` + this.regionId;
    this.http.get(url).subscribe({
      next: (res: any) => {
        this.LCNDetails = res.data
        this.viewLCNModal = true
      },
    })

  }

  onaddClickinLCN() {
    this.router.navigateByUrl('/content/statewise-lcn/lcn/' + this.regionId)
  }
}