import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class HttpClientWrapperService {
  constructor(
    private httpClient: HttpClient,
    private auth: AuthService,
    private router: Router,
    private config: ConfigService
  ) {}

  private getUserHeader(): string {
    const user = localStorage.getItem('user');
    const role = localStorage.getItem('ROLE');
    return `${role}-${user}`;
  }
  public alertShown: boolean = false;

  private getRequestOptions(): object {
    const header = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.auth.getToken())
      .set('User-Login', this.getUserHeader()).set('UserType',this.config.json.userType);

    return { headers: header };
  }

  private getRequestOptionsGraphql(): object {
    const header = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.auth.getToken())
      .set('User-Login', this.getUserHeader())
      .set('Content-Type', 'application/json');

    return { headers: header };
  }

  handleError(err: HttpErrorResponse) {
    console.log(err.status);
    console.log(this.alertShown)
    if(err.status === 401 && this.alertShown== false ) {
      alert('Session has Expired. Please log in again');
      this.alertShown=true
      this.auth.logout()
      this.router.navigate(['/login']);
    }
  }
  get(path): Observable<any> {
    const options = this.getRequestOptions();
    return this.httpClient.get(path, options).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        this.handleError(err);
        console.log(err);
        return err;
      })
    );
  }

  getwithoutHeader(path): Observable<any> {
    return this.httpClient.get(path).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        this.handleError(err);
        console.log(err);
        return err;
      })
    );
  }

  postgraphql(path, body): Observable<any> {
    const options = this.getRequestOptionsGraphql();
    return this.httpClient.post(path, body, options);
  }

  post(path, body): Observable<any> {
    const options = this.getRequestOptions();
    return this.httpClient.post(path, body, options).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log(err);
        this.handleError(err);
        return err;
      })
    );
  }

  postWithHeader(path, body) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.httpClient.post(path, body, { headers }).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log(err);

        return err;
      })
    );
  }

  postwithoutheader(path, body): Observable<any> {
    return this.httpClient.post(path, body).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log(err);
        this.handleError(err);
        return err;
      })
    );
  }

  // put(path, body): Observable<any> {
  //   const header = new HttpHeaders()
  //     .set('Authorization', 'Bearer ' + this.auth.getToken());
  //   const options = { headers: header };
  //   return this.httpClient.put(path, body, options);
  // }

  put(path, body): Observable<any> {
    const options = this.getRequestOptions();
    return this.httpClient.put(path, body, options).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log(err);
        this.handleError(err);
        return err;
      })
    );
  }

  // Delete method with the body appended to the options of the request
  // delete(path, body?): Observable<any> {
  //   const header = new HttpHeaders()
  //     .set('Authorization', 'Bearer ' + this.auth.getToken());
  //   const options = { headers: header };
  //   return this.httpClient.delete(path, options);
  // }

  delete(path, body?): Observable<any> {
    const options = this.getRequestOptions();
    return this.httpClient.delete(path, options).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.log(err);
        this.handleError(err);
        return err;
      })
    );
  }
}
