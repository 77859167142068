<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>STB Connection Logs</h3>
    <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
  </div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <label id="select_date">Select Date :</label>
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>

    <div class="col-12 col-md-6 report_selection_block">
      <label class="report_selection_label">Select Bouquet</label>
      <select class="custom__field report_selection" [(ngModel)]="selectedBouquetId" (change)="listItem()">
        <option [ngValue]=0>ALL</option>
        <option *ngFor="let bq of bouquetList" [ngValue]='bq'>{{bq.name}}</option>
      </select>
    </div>

    <!-- <div class="col-12 col-md-6 report_selection_block">
            <label class="report_selection_label">Select Channel</label>
            <select class="custom__field report_selection" [(ngModel)]="selectedChannelId">
                <option *ngFor="let ch of channelList" [ngValue]='ch.id'>{{ch.name}}</option>
            </select>
        </div> -->
  </div>

  <div class="col-12 col-md-6">
    <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Subscriber Code</th>
            <th>STB MAC</th>
            <th>Status Change</th>
            <th>Date</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngIf="subscriberReport.length ==0 && !loadingFlag">
            <td style="text-align: center !important;" colspan="12">{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag">
            <td style="text-align: center !important;" colspan="12">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <tr *ngFor="let report of subscriberReport,let i=index">
            <td>{{i+1}}</td>
            <td>{{report.subscriberCode? report.subscriberCode : '---'}}</td>
            <td>{{report.ethernetMac? report.ethernetMac : '---'}}</td>
            <td>{{statUS[report.commandStb]}}</td>
            <td>{{report.createdDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
