import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { SubscriberSearchType } from 'src/app/subscription/subscriber-search/subscriber-search';
@Component({
  selector: 'app-add-subscriber-funds',
  templateUrl: './add-subscriber-funds.component.html',
  styleUrls: ['./add-subscriber-funds.component.scss']
})
export class AddSubscriberFundsComponent implements OnInit {

  subscriberFundsForm: FormGroup;
  subscriberData
  subscriberID

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private auth: AuthService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.initForm()
  }

  getSubscriberCode() {
    const url = this.config.json.apiEndpointRdbms + 'subscriber/getSubscriberByCode/' + this.subscriberCode.value
    this.http.get(url).subscribe(
      (res) => {
        this.subscriberData = res.data;
        this.subscriberID = this.subscriberData.id;
      },
      (err) => {
        //// console.log(err);
      }
    )
  }

  getSubscriberDetails(e: Event) {
    this.openSearch = false
    this.searchText = (e.target as HTMLInputElement).value
    if(this.searchText.length >= 3){
      setTimeout(() => {
        this.openSearch = true
      }, 300);
    }
  }

  initForm(): void {
    this.subscriberFundsForm = new FormGroup({
      subscriberId: new FormControl('', [Validators.required]),
      modeOfPayment: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      transactionID: new FormControl('', [Validators.required]),
      remarks: new FormControl('', [Validators.required])
    });
  }

  get subscriberCode(): FormControl {
    return this.subscriberFundsForm.get('subscriberId') as FormControl;
  }

  get modeOfPayment(): FormControl {
    return this.subscriberFundsForm.get('modeOfPayment') as FormControl;
  }

  get amount(): FormControl {
    return this.subscriberFundsForm.get('amount') as FormControl;
  }

  get transactionID(): FormControl {
    return this.subscriberFundsForm.get('transactionID') as FormControl;
  }

  get remarks(): FormControl {
    return this.subscriberFundsForm.get('remarks') as FormControl;
  }

  openSearch = false
  searchText: string
  subscriberCodeForSend
  getUserDetail(e: SubscriberSearchType) {
    let input = document.getElementById('searchText') as HTMLInputElement;
    this.subscriberFundsForm.get('subscriberId').setValue(e.subscriberId)
    this.subscriberCodeForSend = e.subscriberCode
    input.value = `${e.subscriberCode}, ${e.subscriberName}`
    this.openSearch = false
  }

  clearInput() {
    let input = document.getElementById('searchText') as HTMLInputElement
    input.value = ""
    this.searchText = ""
    this.openSearch = false
    this.subscriberFundsForm.reset()
  }

  createBody() {
    let body = {}
    body['subscriberId'] = this.subscriberFundsForm.get('subscriberId').value
    body['subscriberCode'] = this.subscriberCodeForSend
    body['modeOfPayment'] = this.modeOfPayment.value
    body['amount'] = this.amount.value
    body['transactionId'] = this.transactionID.value
    body['remarks'] = this.remarks.value
    return body
  }


  persist() {
    let body = this.createBody();
    let url = this.config.json.apiEndpointRdbms + 'wallet/subscriber/add';

    this.http.post(url, body).subscribe(
      (res) => {
        if (res.status === "FAIL") {
          alert(res.message);
        } else {
          alert(res.message);
          this.subscriberFundsForm.reset()
        }
      },
      (err) => {
        alert(err);
      }
    );
  }



}
