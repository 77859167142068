import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MultiSelectType } from 'src/app/plugins/multiselect/multiselect.component';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { SubscriberSearchType } from '../subscriber-search/subscriber-search';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.scss'],
})
export class AddSubscriptionComponent {
  constructor(
    private fb: FormBuilder,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private auth: AuthService
  ) { }
  ngOnInit(): void {
    this.initForm();
  }

  addSubscriptionForm: FormGroup;

  initForm() {
    this.addSubscriptionForm = this.fb.group({
      subscriberCode: ['', [Validators.required]],
      stbId: ['', [Validators.required]],
      bouquetId: ['', [Validators.required]],
      duration1: ['', [Validators.required]],
      endDate: [''],
      duration: [''],
    });
  }

  packagesOptions: MultiSelectType = {
    dataArray: [],
    keyToDisplay: '',
    idForSelected: '',
  };
  packages = new BehaviorSubject<MultiSelectType>(this.packagesOptions);

  searchText!: string;
  openSearch = false;
  getSubscriberId(e: Event) {
    this.openSearch = false;
    this.searchText = (e.target as HTMLInputElement).value;
    if (this.searchText.length >= 3) {
      setTimeout(() => {
        this.openSearch = true;
      }, 500);
    }
  }

  subscriberId;
  isSubscriberIdSelected = false;
  getUser(e: SubscriberSearchType) {
    let input = document.getElementById('searchSubCode') as HTMLInputElement;
    this.addSubscriptionForm.get('subscriberCode').setValue(e.subscriberCode);
    this.subscriberId = e.subscriberId;
    this.openSearch = false;
    this.getSTBList();
    this.searchText = undefined;
    input.value = `${e.subscriberCode}, ${e.subscriberName}`;
  }

  isDropDown = true;
  clearInput() {
    this.addSubscriptionForm.reset();
    this.packagesOptions = {
      ...this.packagesOptions,
      selectedArray: [],
    };
    this.packages.next(this.packagesOptions);
    this.stbList = [];
    this.selectedPackages = [];
  }

  stbList;
  lcoId;
  getSTBList() {
    const url =
      this.config.json.apiEndpointRdbms +
      'stb/getSTBBySubscriberId/' +
      this.subscriberId;
    this.http.get(url).subscribe({
      next: (res) => {
        this.stbList = res;
        this.lcoId = res[0].lcoId;
        this.getPackageList();
      },
    });
  }

  getPackageList() {
    const url = `${this.config.json.apiEndpoint}bouquet/${this.lcoId + '/getBouquetDropdownList'
      }`;
    this.http.get(url).subscribe({
      next: (res) => {
        let data = res.data;
        this.packagesOptions = {
          ...this.packagesOptions,
          dataArray: data,
          keyToDisplay: 'bouquetName',
          idForSelected: 'bouquetId',
        };
        this.packages.next(this.packagesOptions);
      },
    });
  }

  selectedPackages = [];
  packageSelected(e) {
    let valcheck = this.daysChecked
    let dur = this.duration1.value
    this.selectedPackages = [];
    if (e?.length == 0 || e == undefined || e == null) {
      this.addSubscriptionForm.get('bouquetId').setValue(null);
      return;
    }

    function getLastDate() {
      if (valcheck == true) {
        return addDaysToDate(dur)
      }
      else {
        const currentDate = new Date();
        const newDate = new Date(currentDate);
        newDate.setDate((newDate.getDate()) + (dur * 30));
        return newDate;
      }
    }

    function addDaysToDate(daysToAdd: number): Date {
      const currentDate = new Date();
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + (daysToAdd * 1));
      return newDate;
    }
    let bouquetIds = '';
    for (let i = 0; i < e.length; i++) {
      this.selectedPackages.push({
        name: e[i]['bouquetName'],
        validFrom: new Date(),
        validTo: getLastDate(),
      });
      if (i == e.length - 1) {
        bouquetIds += `${e[i]['bouquetId']}`;
      } else {
        bouquetIds += `${e[i]['bouquetId']},`;
      }
    }
    this.addSubscriptionForm.get('bouquetId').setValue(bouquetIds);
  }

  daysChecked = false;


  changeDaysSub() {
    let valcheck = this.daysChecked
    let dur = this.duration1.value
    function getLastDate() {
      if (valcheck == true) {
        return addDaysToDate(dur)
      }
      else {
        const currentDate = new Date();
        const newDate = new Date(currentDate);
        newDate.setDate((newDate.getDate()) + (dur * 30));
        return newDate;
      }
    }

    function addDaysToDate(daysToAdd: number): Date {
      const currentDate = new Date();
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + (daysToAdd * 1));
      return newDate;
    }

    if (this.selectedPackages.length > 0) {
      for (let j of this.selectedPackages) {
        j.validFrom = new Date(),
          j.validTo = getLastDate()
      }
    }
  }
  changeForDays(e: Event) {
    let check = (e.target as HTMLInputElement).checked;
    this.daysChecked = check;
    let valcheck = this.daysChecked
    let dur = this.duration1.value
    function getLastDate() {
      if (valcheck == true) {
        return addDaysToDate(dur)
      }
      else {
        const currentDate = new Date();
        const newDate = new Date(currentDate);
        newDate.setDate((newDate.getDate()) + (dur * 30));
        return newDate;
      }
    }

    function addDaysToDate(daysToAdd: number): Date {
      const currentDate = new Date();
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + (daysToAdd * 1));
      return newDate;
    }

    if (this.selectedPackages.length > 0) {
      for (let j of this.selectedPackages) {
        j.validFrom = new Date(),
          j.validTo = getLastDate()
      }

    }
  }

  createBody() {
    this.addSubscriptionForm
      .get('stbId')
      .setValue(+this.addSubscriptionForm.get('stbId').value);
    let checked = (document.getElementById('days') as HTMLInputElement).checked;
    if (checked) {
      this.addSubscriptionForm
        .get('endDate')
        .setValue(
          (document.getElementById('duration1') as HTMLInputElement).value
        );
    } else {
      this.addSubscriptionForm
        .get('duration')
        .setValue(
          +(document.getElementById('duration1') as HTMLInputElement).value
        );
    }
    let body = JSON.parse(JSON.stringify(this.addSubscriptionForm.value));
    if (checked) {
      delete body['duration'];
      delete body['duration1'];
    } else {
      delete body['endDate'];
      delete body['duration1'];
    }
    return body;
  }

  save() {
    const url =
      this.config.json.apiEndpointRdbms + 'subscriber/addSubscription';
    this.http.put(url, this.createBody()).subscribe({
      next: (res) => {
        if ((res.status as string).toUpperCase() === 'FAIL') {
          alert('Subscription was not created. Error: ' + res.message);
          return;
        }
        alert('Thank you, subscription has been created');
        this.clearInput();
      },
    });
  }

  get duration1(): FormControl {
    return this.addSubscriptionForm.get('duration1') as FormControl;
  }
}
