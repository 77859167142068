import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-report-sync-cas',
  templateUrl: './report-sync-cas.component.html',
  styleUrls: ['./report-sync-cas.component.scss']
})
export class ReportSyncCasComponent implements OnInit {

  reportList = [];

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }


  ngOnInit(): void {
  }

  fetchData(from: number, to: number): void {
    this.report.getStbStatusReport(from, to, {}).subscribe(data => {
      this.reportList = data;
    });
  }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      this.calendar.setDate(ev);
      this.fetchData(ev.startDate.valueOf(), ev.endDate.valueOf());
    }
  }

  downloadTableExcel(): void {
    let name='Sync CAS  Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'sync_cas_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable', 'sync_cas_report');
  }
  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Date", "total Stbs","Active STBs","Inactive STBs", "Blacklisted", "Whitelisted",
      "Paired","Unpaired"]
    };
    let data = []
    this.reportList.forEach(line => {
      let csvLine = {
        date: line.date,
        totalSTBs: line.activeSTBs,
        activeSTBs: line.activeSTBs ,
        inActiveSTBs: line.inActiveSTBs,
        blaclistedSTBs: line.blaclistedSTBs,
        unBlacklistedSTBs: line.unBlacklistedSTBs,
        pairedSTBs: line.pairedSTBs,
        unpairedSTBs: line.unpairedSTBs ,
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_packages_history', options);
  }

}
