import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';

const routes: Routes = [

  { path: '', component: SettingsComponent }
  // { path: 'global', component: GlobalSettingsComponent },
  // { path: 'osd', component: OsdSettingsComponent },
  // { path: 'fingerprint', component: FingerprintSettingsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
