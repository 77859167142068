import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import * as moment from 'moment';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';

@Component({
  selector: 'app-report-susbscriber-reconciliation-count',
  templateUrl: './report-susbscriber-reconciliation-count.component.html',
  styleUrls: ['./report-susbscriber-reconciliation-count.component.scss']
})
export class ReportSusbscriberReconciliationCountComponent implements OnInit {

  reportList = [];
  isLoad = 'Choose Date';
  isLoading = false;
  currentDate = new Date();
  selected: NgxDateRangeInfo;
ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  locale: any = {
    format: 'DD/MM/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'DD/MM/YYYY', // default is format value
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Select', // detault is 'Apply'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 0 // first day is monday
  };

  

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  viewModal = false
  reportType = ''
  yearValue = ''
  dateType
  yearsArray = []
  showMonth = false
  showDateRange = false
  dateSelector = true
  tableDisplay = true

  downloadHistoricReport() {
    this.viewModal = true
    this.yearsArrayCalculator()

  }

  yearFilter() {
    this.yearValue = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    this.yearValue=this.yearValue+'-01-01';
    this.dateType = 'year'
  }

  MonthFilter(e) {
    this.yearValue=''
    this.yearValue += e.target.value + '-01'
    console.log(this.yearValue)
    this.dateType = 'month'
  }

  yearsArrayCalculator() {
    if (this.yearsArray.length == 0) {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2019; i--) {
        this.yearsArray.push(i);
      }
    }
  }
 
  generateReport() {
    let url = this.config.json.apiEndpoint + 'reports/generateHistoricalReportForReconcilation?value=' +
      this.yearValue + '&type=' + this.dateType + '&reportName=Subcriber_reconcilation_report' 
    this.http.get(url).subscribe(
      (res) => {
        if (res.statusCode == 200) {
          const fileUrl = res.data.fileUrl;
          const anchor = document.createElement('a');
          anchor.href = fileUrl;
          anchor.target = '_self';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          this.reportType='';this.yearValue='';
          this.viewModal=false;
          this.dateTypeSelectors(0)
        }
        else {
          alert(res.message)
        }
      }
    )
  }

  
  dateTypeSelectors(num) {
    this.yearValue = ''
    if (num == 0) {
      this.dateSelector = true
      this.showMonth = false
      this.showDateRange = false
      this.tableDisplay = true
    }
    if (num == 1) {
      this.dateSelector = false
      this.showMonth = true
      this.showDateRange = false
      this.tableDisplay = true
    }
    if (num == 2) {
      this.dateSelector = false
      this.showDateRange = true
      this.showMonth = false
      this.tableDisplay = false
      this.yearValue = new Date().getFullYear()+'-01-01'
      this.dateType='year'
      console.log(this.yearValue)
    }
  }


 
  fetchData(): void {
    this.report.getSubscriberReconciliationcount().subscribe(
      (data) => {
        this.isLoading = false;
        if(!(data.data)){
          this.isLoading = false;
          this.isLoad = 'No Records';
          this.reportList = []
          return
        }
        this.reportList = data.data;
        console.log(this.reportList)
        if (this.reportList?.length == 0) {
          this.isLoading = false;
          this.isLoad = 'No Records';
        } else {
          this.isLoading = true;
        }
      },
      () => {
        this.isLoad = 'No Records';
        this.isLoading = false;
      }
    );
  }

  // downloadTableExcel(): void {
  //   let name = 'Subscriber Reconciliation Count';
  //   this.doc.exportTableToExcelwithImage(this.reportTable, name, 'Subscriber_Reconciliation_Count');
  // }


  downloadTableExcel(){
    let url= this.config.json.apiEndpoint+'generate/subscriberReconcilationCount'
    console.log(url)
    this.http.getwithresponseType(url).subscribe(
      (response:Blob) => {
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Subscriber_Reconciliation_Count.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
    )
  }


  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable', 'Subscriber_Reconciliation_Count', `Source Type : SMS`);
  }
  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'sl no',
        'type',
        'SMS',
        'DRM',
        'Mismatch %',
        
      ],
    };
    let data = [];
    let i = 1
    this.reportList.forEach((line) => {
      let csvLine = {
        slNo : i,
        type:line['type'],
        subscriberIds: line['SMS-activeSubscribers'],
        readFlag: line['DRM-activeSubscribers'],
        mismatch: line['Mismatch %'],
        
      };
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'Subscriber_Reconciliation_Count', options);
  }

  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    let url =
      this.config.json.apiEndpointRdbms + 'osd/list?searchKeyword=' + value;
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data;
        this.isLoading = false;
        if (this.reportList.length == 0) {
          this.isLoad = 'No Records';
        } else {
          this.isLoading = true;
        }
      },
      () => {
        this.isLoad = 'No Records';
        this.isLoading = false;
      }
    );
  }

}
