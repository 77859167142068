import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-report-statewise-subscriber',
  templateUrl: './report-statewise-subscriber.component.html',
  styleUrls: ['./report-statewise-subscriber.component.scss'],
})
export class ReportStatewiseSubscriberComponent implements OnInit {
  reportList = [];
  bouquetType = 0;
  broadcasterId: number;

  broadcasterList: BroadcasterInfo[] = [];

  selectedDate: any;

  currentDate = new Date();
  stateList = [];
  isLoad;
  loadingFlag;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  isLoading = false;
  ngOnInit(): void {
    this.fetchLocationList();
    this.stateName = 'All';
    this.status = 5;
    this.fetchData();
  }

  fetchLocationList(): void {
    let url =
      this.config.json.apiEndpointRdbms + `location/getLocationDropdown`;
    this.http.get(url).subscribe((data) => {
      this.stateList = data.data.locationList;
      // // console.log(this.stateList)
    });
  }
  stateName;
  selectState() {
    var name = document.getElementById('state') as HTMLSelectElement;
    var state = name.value;
    this.stateName = state;
    // // console.log(state)
    this.isLoading = true;
    // this.fetchData()
    let url =
      this.config.json.apiEndpointRdbms +
      `subscriber/getStateWiseSubscriber/?state=${state}&searchKeyword=${
        this.searchWord ? this.searchWord : ''
      }&status=${this.status ? this.status : ''}`;
    this.http.get(url).subscribe((data) => {
      this.reportList = [];
      this.reportList = data.data;
      // // console.log(data)
      this.isLoading = false;
    });
  }

  status;
  statusChange() {
    let val = (document.getElementById('statusChangeApi') as HTMLSelectElement)
      .value;
    this.status = val;
    // // console.log(this.status)
    this.fetchData();
  }

  statUS = ['Inactive', 'Active', 'Blacklisted', 'New', 'Terminated', 'ALL'];

  fetchData(): void {
    this.isLoading = true;
    this.report
      .getStateWiseSubscriber(this.stateName, this.searchWord, this.status)
      .subscribe((res) => {
        this.reportList = res.data;
        this.isLoading = false;
      });
  }

  // downloadTableExcel(): void {
  //   let name = 'Statewise Susbcriber Report';
  //   this.doc.exportTableToExcelwithImage(
  //     this.reportTable,
  //     name,
  //     'statewise_subscriber_report',
  //     undefined,
  //     undefined,
  //     'State :',
  //     this.stateName,
  //     'Status',
  //     this.statUS[this.status]
  //   );
  // }

  downloadTableExcel(){
    let url=''
    var name = document.getElementById('state') as HTMLSelectElement;
    var state = name.value;
        url= this.config.json.apiEndpoint+'generate/stateWiseSubscriber?state='+state+
        'status='+this.status+'&searchKeyword='+this.searchWord
    console.log(url)
    this.http.getwithresponseType(url).subscribe(
      (response) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const jsonResponse = JSON.parse(reader.result as string);
            if (jsonResponse.status === 'FAIL') {
             alert(`Error: ${jsonResponse.message}`);
              return;
            }
          } catch (error) {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'statewise_subscriber_report.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
          }
        };
        reader.readAsText(response);
      },
      (error) => {
        console.error('Error downloading Excel file:', error);
      }
    );
  }

  downloadTablePdf(): void {
    const tempDate = new Date(this.selectedDate);
    const monthYear =
      tempDate.toLocaleString('default', { month: 'long' }) +
      ' ' +
      tempDate.getFullYear();
    // this.pdf.generatePdfHtml('#reportTable',
    // 'statewise_subscriber',
    // `State : ${this.stateName}, Status : ${this.statUS[this.status]}  Source Type : SMS`,null,null,"a3"
    // );
    this.pdf.generatePDFForLongTable(
      'Statewise Subscriber',
      `State : ${this.stateName}, Status : ${
        this.statUS[this.status]
      }  Source Type : SMS`
    );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'Sl No',
        'Subscriber Name',
        'Subscriber Code',
        'Mac',
        'Mobile',
        'Price Package name',
        'Subscriber Status',
        'Subscription Type',
        'State',
        'Country',
        'Zip Code',
        'Partner Name',
        'Partner code',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        sub_name: line.sub_name,
        sub_code: line.sub_code,
        mac: line.mac,
        mobile: line.mobile,
        price_pkg_name: line.price_pkg_name,
        sub_status: this.statUS[line.sub_status],
        subscription_type: line.subscription_type,
        state: line.state,
        country: line.country,
        zipcode: line.zipcode,
        lco_name: line.lco_name,
        lco_id: line.lco_id,
      };
      data.push(csvLine);
      i += 1;
    });

    new ngxCsv(data, 'statewise_subscriber_report', options);
  }

  searchWord='';
  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    this.searchWord = value;
    let url =
      this.config.json.apiEndpointRdbms +
      `subscriber/getStateWiseSubscriber/?state=${
        this.stateName == 0 ? '' : this.stateName
      }&searchKeyword=${value}&status=${this.status ? this.status : ''}`;
    // // console.log(url)
    this.http.get(url).subscribe(
      (data) => {
        this.reportList = data.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }
}
