import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateChannelComponent } from './create-channel/create-channel.component';
import { ListChannelComponent } from './list-channel/list-channel.component';
import { BulkUploadChannelComponent } from './bulk-upload-channel/bulk-upload-channel.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListChannelComponent },
  { path: 'upload', component: BulkUploadChannelComponent },
  { path: ':id', component: CreateChannelComponent },
  { path: 'create', component: CreateChannelComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChannelRoutingModule { }
