import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { delay } from 'rxjs/operators';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-channel-selector',
  templateUrl: './channel-selector.component.html',
  styleUrls: ['./channel-selector.component.scss']
})
export class ChannelSelectorComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    name: 'name',
    number: 'number',
    channelId: 'channelId',
    desc: 'description',
    date: 'createdDate',
    status: 'status',
    url: 'url'
  };

  channelList: ChannelInfo[] = [];

  selectedList: ChannelInfo[] = [];

  selectAll = false;

  @Output()
  selectedChannels: EventEmitter = new EventEmitter();

  @Input() preselectedChannelIds: number[] = [];

  initFlag = true;

  constructor(
    private http: HttpClientWrapperService,
    private store: StoreService,
    private config: ConfigService
  ) { }

  prefix = this.config.json.imageUrlPrefix
  ngOnInit(): void {
    this.paginationObj.pageSize = 16
    this.fetchPage();
    this.store.lcoSelectedChannelList = this.selectedList;
    this.selectedList = this.store.lcoSelectedChannelList;
  }

  fetchPage(): void {
    
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    // const url = PaginationUtilities.urlBuilderRdbms('channel/getChannels', this.paginationObj);
    const url = PaginationUtilities.urlBuilder('channel/getChannelswithLogos', this.paginationObj)
    // const url = this.config.json.apiEndpointRdbms + 'channel/getChannelsforfingerprint/' + this.paginationObj.pageNumber + '?pageSize=' + this.paginationObj.pageSize + '&sortBy=' + this.paginationObj.sortBy + '&sortOrder=' + this.paginationObj.sortOrder + '&searchKeyword=' + this.paginationObj.searchKeyword

    this.loadingFlag = true;

    this.http.get(url).subscribe((data: PageInfo<ChannelInfo>) => {
      // console.log(data)
      this.loadingFlag = false;
      this.handleFetchPage(data);
    });
  }

  // fetchAllChannels():void{
  //   const url = this.config.json.apiEndpointRdbms+'channel/getChannels'
  //   this.http.get(url).subscribe((data: PageInfo<ChannelInfo>) => {
  //     this.loadingFlag = false;
  //     this.handleFetchPage(data);
  //   });
  // }

  handleFetchPage(data: PageInfo<ChannelInfo>): void {

    this.selectAll = false;
    this.channelList = data.data;

    this.channelList.forEach(data => {
      // console.log(data)
      data.checked = false;

      // When new page loads, add any existing channels to selected list
      this.preselectedChannelIds.forEach(pkid => {
        // // console.log(pkid === Number(data.channel_id))
        // // console.log(this.selectedList)
        if (Number(pkid) == Number(data.channel_id)) {
          // console.log(Number(data.channel_id), Number(pkid))
          // console.log("selected list", this.selectedList)
          this.selectedList.some(ch => Number(ch.channel_id) === Number(pkid)) ? null :
            this.selectedList.push(data);
          data.checked = true;
        }
      });
      // } else {
      this.selectedList.forEach(select => {
        if (select.channel_id === data.channel_id) {
          data.checked = true;
          this.selectAll =  true
        }
      });
      // }
    });

    this.initFlag = false;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    console.log(this.paginationObj);

    this.paginationObj = {
      ...this.paginationObj,
      pageNumber : metadata.pageNumber,
      pageSize : metadata.pageSize,
      sortBy : metadata.sortBy,
      sortOrder : metadata.sortOrder,
      totalPages : metadata.totalPageCount,
      totalElements : metadata.totalElementCount,
      searchKeyword : metadata.searchKeyword,
      toDate : metadata['toDate'],
      fromDate : metadata['fromDate'],
      category : metadata['category'],
    }
  }

  pageSizeChangeThroughSelect(e : Event){
    let value = (e.target as HTMLSelectElement).value
    this.paginationObj = {
      ...this.paginationObj,
      pageSize : +value
    }
    this.fetchPage()
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  channelGridClick(channel: ChannelInfo): void {
    channel.checked = !channel.checked;

    if (channel.checked) {
      this.selectedList.push(channel);
    } else {
      const index = this.selectedList.findIndex(ch => ch.channel_id === channel.channel_id);
      index !== -1 && this.selectedList.splice(index, 1);
      this.selectAll = false;
    }
    this.store.lcoSelectedChannelList = this.selectedList
  }

  removeFromSelectedList(channel: ChannelInfo, index: number): void {

    this.channelList.forEach(ch => {
      if (channel.channel_id === ch.channel_id) {
        ch.checked = false;
      }
    });

    this.selectedList.splice(index, 1);
    this.selectAll = false;
  }

  selectAllToggle(): void {
    if (this.selectAll) {
      this.channelList.forEach(channel => {
        if (!channel.checked) {
          channel.checked = true;
          this.selectedList.push(channel);
        }
      });
    } else {
      this.channelList.forEach(channel => {
        channel.checked = false;
        let index = -1;
        this.selectedList.forEach(select => {
          if (select.channel_id === channel.channel_id) {
            index = this.selectedList.indexOf(select);
          }
        });
        if (index > -1) {
          this.selectedList.splice(index, 1);
        }
      })
    }
  }

}
