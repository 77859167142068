import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AdminInfo } from 'src/app/models/admin/admin-info';
import { DistributorInfo } from 'src/app/models/distributor/distributor_info';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import {
  PageInfo,
  PageMetadata,
  SortOrder,
} from 'src/app/models/pagination/page-info';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-distributor',
  templateUrl: './distributor.component.html',
  styleUrls: ['./distributor.component.scss'],
})
export class DistributorComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  LcoActiveCount: number;
  TotalLcoCount: number;
  sortByProperties = {
    name: 'name',
    email: 'email',
    mobile: 'mobileNumber',
    date: 'createdDate',
    status: 'status',
    cp: 'contactPerson',
    tp: 'transferPrice',
  };

  lcoList: LCOInfo[] = [];

  activatedCount = 0;
  user

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    if(this.config.json.userType=='MSO'){
      this.user=true
    }
    else{
      this.user=false
    }
    this.fetchPage();
    // this.fetchLcoCount();
  }
  distributrDetails: DistributorInfo;
  viewModal = false;
  viewDistributorDetails(distributorDetails: any) {
    let distributorId = distributorDetails.id,
      url = `${this.config.json.apiEndpoint}distributor/distributorDetails/`+distributorId;
    this.http.get(url).subscribe({
        next: (res: any) => {
          this.distributrDetails = res.data;
          this.viewModal = true;
        },
      });
  }

  onSearch() {
    this.paginationObj.searchKeyword = (
      document.getElementById('searchValue') as HTMLInputElement
    ).value;
    this.fetchPage();
  }


  login(userDetail) {
    // console.log( JSON.parse(localStorage.getItem('profileInfo') as string).email);
    let body = {
      username: userDetail.email,
      loggedIn: JSON.parse(localStorage.getItem('profileInfo') as string).email,
    };
    let openUrl =
      this.config.json.adminLoginasDistributor + 'userdistributor/' + btoa(JSON.stringify(body));
      console.log(openUrl)
    window.open(openUrl, '_blank');
  }


  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      'distributor/list',
      this.paginationObj
    );
    this.loadingFlag = true;
    this.http
      .get(url)
      .pipe(delay(250))
      .subscribe((data: PageInfo<LCOInfo>) => {
        this.loadingFlag = false;
        this.handleFetchPage(data);
        this.handlePaginationMetadata(data.metadata);
      });
  }

  handleFetchPage(data): void {
    this.lcoList = data.data;

    // this.handlePaginationMetadata(data.metadata);
    // this.handlePaginationBtnDisableStatuses();
    // this.countAdmin();
  }

  pageSize;
  pageNumber;
  handlePaginationMetadata(metadata: PageMetadata): void {
    console.log(metadata);
    console.log(this.paginationObj);

    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
    this.pageSize = metadata.pageSize;
    this.paginationObj.pageSize = metadata.pageSize;
    this.pageNumber = metadata.pageNumber;
    this.paginationObj.pageNumber = metadata.pageNumber;
  }

  handlePaginationBtnDisableStatuses(): void {
    console.log(this.paginationObj.pageNumber);

    // Left section
    this.disableFirstBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;
    this.disablePreviousBtn =
      this.paginationObj.pageNumber <= environment.defaultPageNumber
        ? true
        : false;

    // Right section
    this.disableNextBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
    this.disableLastBtn =
      this.paginationObj.pageNumber >= this.paginationObj.totalPages
        ? true
        : false;
  }

  pageSizeChange(e: Event): void {
    this.paginationObj = {
      ...this.paginationObj,
      pageSize: +((e.target as HTMLSelectElement).value)
    }
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    console.log(this.paginationObj.pageNumber);

    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    console.log(this.paginationObj.pageNumber);

    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    console.log(this.paginationObj.pageNumber);

    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder =
      this.paginationObj.sortOrder === SortOrder.DESC
        ? SortOrder.ASC
        : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: AdminInfo): void {
    this.router.navigate(['/manage/distributor/edit', record.id]);
    // alert('record not editable!')
  }

  countAdmin(): void {
    this.activatedCount = this.lcoList.filter((x) => x.status === 1).length;
    // //// console.log(this.lcoList)
  }

  updateStatus(record: AdminInfo): void {
    record.status === 1 ? this.deactivate(record) : this.activate(record);
  }

  deactivate(record: AdminInfo): void {
    const url =
      this.config.json.apiEndpointRdbms +
      'admin/deactivateAccount/' +
      record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 0;
      this.countAdmin();
      window.alert('Account deactivated');
    });
  }

  activate(record: AdminInfo): void {
    const url =
      this.config.json.apiEndpointRdbms + 'admin/activateAccount/' + record.id;

    this.http.post(url, {}).subscribe(() => {
      record.status = 1;
      this.countAdmin();
      window.alert('Account activated');
    });
  }
}
