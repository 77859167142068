import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriberGroupRoutingModule } from './subscriber-group-routing.module';
import { ListSubscriberGroupComponent } from './list-subscriber-group/list-subscriber-group.component';
import { CreateSubscriberGroupComponent } from './create-subscriber-group/create-subscriber-group.component';
import { SharedModule } from '../shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    ListSubscriberGroupComponent,
    CreateSubscriberGroupComponent
  ],
  imports: [
    CommonModule,
    SubscriberGroupRoutingModule,
    SharedModule,    
    NgMultiSelectDropDownModule
  ]
})
export class SubscriberGroupModule { }
