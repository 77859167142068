<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Bouquet Composition Change Logs</h3>
    <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
  </div>

  <div class="row">
    <!-- <div class="filter__group col-12 col-md-6">
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div> -->

    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>

        <div class="col-12 col-md-6 report_selection_block">
            <label class="report_selection_label"> Bouquet</label>
            <select class="custom__field report_selection" [(ngModel)]="selectedBouquetId" (change)='bouquetSelect()'>
                <option [ngValue] = 0 >ALL</option>
                <option *ngFor="let bq of bouquetList" [ngValue]='bq.id'>{{bq.name}}</option>
            </select>
        </div>
        <!-- <div class="col-12 col-md-6 search__bouquet">
            <input type="number" class="custom__field" placeholder="Bouquet ID" [(ngModel)]="selectedBouquetId"/>
        </div>
        <div class="col-md-2">
          <button class="default__button inline" (click)="fetchData()">Go</button>
      </div> -->

  </div>

  <div class="col-12 col-md-6">
    <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
  </div>

  <div class="col-12 list__table">
    <div class="row">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Date</th>
            <!-- <th>ID</th> -->
            <th>Bouquet ID</th>
            <th>Bouquet Name</th>
            <th>Channel ID</th>
            <th>Channel Name</th>
            <th>Channel Status</th>
            <!-- <th>Created By</th> -->
            <th>Modified By</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length<=0; else showList">
            <span>
              <h5>No records!</h5>
            </span>
          </tr>

          <ng-template #showList>
            <tr *ngFor="let report of reportList">
              <td>{{report.createdDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
              <!-- <td>{{report.id}}</td> -->
              <td>{{report.bouquetId? report.bouquetId : '---'}}</td>
              <td>{{report.bouquetName? report.bouquetName : '---'}}</td>
              <td>{{report.channelId? report.channelId : '---'}}</td>
              <td>{{report.channelName? report.channelName : '---'}}</td>
              <td>{{report.channelStatus? report.channelStatus : '---'}}</td>
              <!-- <td>{{report.createdBy}}</td> -->
              <td>{{report.modifiedBy? report.modifiedBy : '---'}}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>
