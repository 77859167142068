import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Directive({
  selector: '[msoAccess]'
})
export class SuperAdminAccessDirective {

  constructor(
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef
  ) {
    this.menuAccessControl();
  }

  menuAccessControl(): void {
    
    if (this.auth.getRole() == 'MSO') {
      this.container.createEmbeddedView(this.templateRef);
    } else {
      this.container.clear();
    }
  }


}
