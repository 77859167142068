<div class="cms__content">
  <div class="top__heading">
    <h3>{{isEdit?'Edit':'Create'}} Subscriber Group</h3>
    <a [routerLink]="['/manage/subscriber-groups/list']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>
  <form action="" [formGroup]="groupForm" (ngSubmit)="persist()">
    <div class="col-12 form__style">
      <div class="row">

        <div class="col-12">
          <label>Name</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="name"
            [ngClass]="{'error__field': !name.valid && name.touched}" />
          <div [hidden]="!name.touched">
            <span class="error__msg" *ngIf="name.hasError('required')">Required</span>
            <span class="error__msg" *ngIf="name.errors?.cannotContainSpace">Please ensure Name does not contain
              space</span>
          </div>
        </div>


        <div class="col-12">
          <label>Description</label>
          <textarea class="custom__field" formControlName="description"></textarea>
        </div>
<!-- 
        <div class="col-12">
          <label>Subscribers</label>

          <ng-multiselect-dropdown [placeholder]="'select subscribers'" [settings]="dropdownSettings"
            [data]="dropdownList" [(ngModel)]="selectedList" [ngModelOptions]="{standalone: true}"
            class="custom__field">
          </ng-multiselect-dropdown>

        </div> -->
        <div class="col-12">
          <!-- <button type="button" [routerLink]="['/group/list']" class="default__button">Cancel</button> -->
          <button class="cta float-right" [disabled]="!groupForm.valid">Save</button>
        </div>
      </div>
    </div>

  </form>
</div>