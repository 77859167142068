import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { PackageInfo } from 'src/app/models/package/package-info';
import { AuthService } from 'src/app/services/auth.service';
import { BouquetDataService } from 'src/app/services/data-services/bouquet-data.service';
import { ChannelDataService } from 'src/app/services/data-services/channel-data.service';
import { LcoDataService } from 'src/app/services/data-services/lco-data.service';

@Component({
  selector: 'app-my-packs',
  templateUrl: './my-packs.component.html',
  styleUrls: ['./my-packs.component.scss']
})
export class MyPacksComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private lcoService: LcoDataService,
    private bouquet: BouquetDataService,
    private channel: ChannelDataService
  ) { }

  lco = {} as LCOInfo;
  channelList: {
    name : string;
    subscriberPrice  : number;
    lcoShare: number;
  }[] = [];
  bouquetList: {
    id: number,
    p1: number,
    p2: number
  }[] = [];

  bouquets: PackageInfo[] = [];
  channels: ChannelInfo[] = [];

  channelMap: any;
  bouquetMap: any;

  channelCliked = false
  boqClicked = false
  ngOnInit(): void {
    this.fetchData();


  }

  fetchData(): void {
    const lcoId = Number(this.auth.getLcoId());
    forkJoin([
      this.lcoService.getLCO(lcoId),
      // this.bouquet.getBouquetsByLCO(lcoId),
      // this.channel.getChannelsByLCO(lcoId)
    ]).subscribe(data => {
      // //// console.log(data);
      this.lco = data[0];
      this.getDetails("Channels")
      // // console.log(this.lco)
      // this.bouquets = data[1];
      // this.channels = data[2];
      // this.mapBouquetData();
      // this.mapChannelData();
      // this.mapLcoData();
    })
  }
  getDetails(event) {
    // // console.log(event)
    this.bouquetList =[]
    this.channelList = []
    const lcoId = Number(this.auth.getLcoId());

    if (event == 'Channels') {
      this.channel.getChannelsByLCO(lcoId).subscribe(
        (data) => {
          this.channelCliked = true
          this.boqClicked = false
          this.channels = data
          this.channelList = (data as any)
          this.mapChannelData();
          // this.mapLCODataChannels()
        }
      )
    }
    else {
      this.bouquet.getBouquetsByLCO(lcoId).subscribe(
        (data) => {
          this.bouquetList = (data as any)
          this.channelCliked = false
          this.boqClicked = true
          this.mapBouquetData();
          this.mapLcoBouquetData()

        }
      )
    }
  }

  mapChannelData(): void {
    this.channelMap = {};
    // this.channels.forEach(rec => {
    //   this.channelMap[rec.id] = rec.name;
    // });

    this.channelMap = this.channels.reduce((acc, rec) => {
      acc[rec.id] = { name: rec.name, price: rec.price };
      return acc;
    }, {});
  }

  mapBouquetData(): void {
    this.bouquetMap = {};
    // this.bouquets.forEach(rec => {
    //   this.bouquetMap[rec.id] = rec.name;
    // });

    this.bouquetMap = this.bouquets.reduce((acc, rec) => {
      acc[rec.id] = { name: rec.name, price: rec.price };
      return acc;
    }, {});
  }

  // mapLCODataChannels() {
  //   Object.keys(this.lco.channels).forEach(k => {
  //     this.channelList.push({
  //       id: this.channelMap[k].name,
  //       p1: (this.channelMap[k].price * (100 - this.lco.channels[k].discountPercent)) / 100,
  //       p2: (((this.channelMap[k].price * (100 - this.lco.channels[k].discountPercent)) / 100) *
  //         this.lco.channels[k].lcoSharePercent) / 100
  //     });
  //   });
  //   // // console.log(this.channelList)
  // }

  mapLcoBouquetData(): void {
    // // console.log("b")
    Object.keys(this.lco.bouquets).forEach(k => {
      this.bouquetList.push({
        id: this.bouquetMap[k].name,
        p1: (this.bouquetMap[k].price * (100 - this.lco.bouquets[k].discountPercent)) / 100,
        p2: (((this.bouquetMap[k].price * (100 - this.lco.bouquets[k].discountPercent)) / 100) *
          this.lco.bouquets[k].lcoSharePercent) / 100
      });
    });
  // // console.log(this.bouquetList)

  }

}
