<div class="cms__content">
    <div class="top__heading">
        <h3>Payment Error</h3>
        <a [routerLink]="['/accounts/wallet']"><button type="button"
                class="default__button float-right">Back</button></a>
    </div>
    <div class="whole_page">
        <div class="complete__tick">
            <img src="assets/close-line.svg" alt="">
        </div>
        <h4>Payment Error</h4>
        <h4 style="text-align: center;">Closing in {{timerSec}} Seconds</h4>
    </div>
</div>