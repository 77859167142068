<div class="cms__content">
	<app-reports-header></app-reports-header>
	<div class="top__heading">
    <app-reports-header></app-reports-header>

		<h3>STB Status Report</h3>
		<a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
	</div>
	<div class="clearfix"></div>

	<div class="row">
		<div class="filter__group col-12 col-md-6">
			<app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
		</div>
		<div class="download__report col-md-6">
			<button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
				PDF
				<img src="./assets/pdf.svg" alt="download" />
			</button>
			<button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
				Excel
				<img src="./assets/excel.svg" alt="download" />
			</button>
			<button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
        <img src="./assets/csv.svg" alt="download" />			</button>
		</div>
	</div>

	<div class="col-12 col-md-6">
		<p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
	  </div>

	<div class="col-12 list__table">
		<div class="row">
			<table class="custom__iptv__table" #reportTable id="reportTable">
				<thead>
					<tr>
            <th>Sl No.</th>
						<th>Date</th>
						<th>Total STB</th>
						<th>Active</th>
						<th>Inactive</th>
						<th>Blacklisted</th>
						<th>Whitelisted</th>
						<th>Fresh</th>
						<th>Assigned</th>
						<th>Unassigned</th>
						<th>Working</th>
						<th>Repairable</th>
						<th>Unrepairable</th>
					</tr>
				</thead>
				<tbody>
					<!-- <div *ngIf="reportList.length<=0; else showList">
						<span>No records!</span>
					</div> -->
          <tr *ngIf="reportList.length ==0 && !loadingFlag" >
            <td style="text-align: center !important;" colspan="12" >{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="12">
            <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
					<ng-template #showList>
						<tr *ngFor="let report of reportList, let j=index">
              <td>{{j+1}}</td>
							<td>{{report.createdDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
							<td>{{report.activeSTBs}}</td>
							<td>{{report.activeSTBs}}</td>
							<td>{{report.inActiveSTBs}}</td>
							<td>{{report.blacklistedSTBs}}</td>
							<td>{{report.whitelistedSTBs}}</td>
							<td>{{report.freshSTBs}}</td>
							<td>{{report.assignedSTBs}}</td>
							<td>{{report.unAssignedSTBs}}</td>
							<td>{{report.workingSTBs}}</td>
							<td>{{report.repairableSTBs}}</td>
							<td>{{report.unRepairableSTBs}}</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
		</div>
	</div>
</div>
