<div class="cms__content">
  <app-reports-header></app-reports-header>
  <div class="top__heading">
    <!-- <img src="../../../assets/wibro.png" id="dispNone" alt=""> -->
    <h3>STB Active Count Report</h3>
    <a [routerLink]="['/reports/trai']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="clearfix"></div>

  <!-- card -->
  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <!-- <app-date-picker (dateChange)="dateChange($event)"></app-date-picker> -->
      <div class="list__card col-md-6">
        <p>
          Total Active STB Count :
          {{ loadingFlag ? "No Records" : totalCount ? totalCount : "Loading" }}
        </p>
      </div>
    </div>
  </div>

  <!-- filter and download -->
  <div class="row">
    <div class="col-12 col-md-6 report_selection_block">
      <label class="report_selection_label">Select Partner</label>
      <select
        class="custom__field report_selection"
        [(ngModel)]="selectedLco"
        (change)="fetchData()"
      >
        <!-- Changed ngValue binding from lco.id to lco.email -->
        <option value="0">ALL</option>
        <option *ngFor="let lco of lcoList" [ngValue]="lco">
          {{ lco.name }}
        </option>
      </select>
    </div>
    <div class="download__report col-12 col-md-6">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>

  
  <!-- report gen and search -->
  <div class="row">
    <div class="col-12 col-md-6">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <div class="col-12 col-md-6 table-search">
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input
          type="text"
          class="custom__field"
          id="searchKeyword"
          (keydown.enter)="searchKeyword()"
        />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <!-- <th>Partner ID</th> -->
            <th>Sl No.</th>
            <th>Partner Code</th>
            <th>Partner Name</th>
            <th>Active STB Count</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngIf="reportList.length ==0 && !loadingFlag" >
            <td style="text-align: center !important;" colspan="2" >{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="2">
            <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>

          <ng-template #showList> -->
          <tr *ngIf="trData">
            <td
              *ngIf="reportList.length == 0 && loadingFlag"
              colspan="4"
              style="text-align: center"
            >
              <!-- <app-custom-spinner></app-custom-spinner> -->
              No records
            </td>
            <td
              *ngIf="reportList.length == 0 && !loadingFlag"
              colspan="4"
              style="text-align: center"
            >
              <app-custom-spinner></app-custom-spinner>
              <!-- No records -->
            </td>
          </tr>
          <tr *ngFor="let report of reportList; let j = index">
            <!-- <td>{{report.createdDate | date: 'dd-MM-yy'}}</td> -->
            <!-- <td>{{report.lco_id}}</td> -->
            <td>{{ j + 1 }}</td>
            <td>
              {{
                (report.lco_code || report.lcoCode) == "null" ||
                (report.lco_code || report.lcoCode) == null ||
                (report.lco_code || report.lcoCode) == ""
                  ? "---"
                  : report.lco_code || report.lcoCode
              }}
            </td>
            <td>
              {{
                (report.name || report["NAME"]) == "null" ||
                (report.name || report["NAME"]) == null ||
                (report.name || report["NAME"]) == ""
                  ? "---"
                  : report.name || report["NAME"]
              }}
            </td>
            <td>
              {{
                (report.stb_active_count || report.stbActiveCount) == "null" ||
                (report.stb_active_count || report.stbActiveCount) == null ||
                (report.stb_active_count || report.stbActiveCount) == ""
                  ? "---"
                  : report.stb_active_count || report.stbActiveCount
              }}
            </td>
          </tr>
          <!-- </ng-template> -->
        </tbody>
      </table>
    </div>
  </div>
</div>
