import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSpinnerComponent } from './custom-spinner/custom-spinner.component';
import { ChannelSelectorComponent } from './channel-selector/channel-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ImageSrcConverterDirective } from './image-src-converter/image-src-converter.directive';
import { ReportsHeaderComponent } from './reports-header/reports-header.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { SharedModule } from '../shared/shared.module';
import { ClickOutsideCustomDirective } from '../directives/click-outside-custom.directive';

@NgModule({
  declarations: [
    CustomSpinnerComponent,
    ChannelSelectorComponent,
    DatePickerComponent,
    ImageSrcConverterDirective,
    ReportsHeaderComponent,
    MultiselectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxDaterangepickerMd,
    ReactiveFormsModule,
  ],
  exports: [
    CustomSpinnerComponent,
    ChannelSelectorComponent,
    DatePickerComponent,
    ImageSrcConverterDirective,
    ReportsHeaderComponent,
    MultiselectComponent,
  ],
})
export class PluginsModule {}
