<div class="page">
    <app-navbar *ngIf="isLoggedin"></app-navbar>
    <router-outlet></router-outlet>
    <footer *ngIf="isLoggedin" >
        <div class="date_section">
            Date : {{date}}
        </div>
        <div class="copyright_section">
            Copyright {{date.getFullYear()}} &copy; <b>ONNET SYSTEMS INDIA PVT. LTD.</b>
        </div>
    </footer>
</div>