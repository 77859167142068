import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-report-channel-list-new',
  templateUrl: './report-channel-list-new.component.html',
  styleUrls: ['./report-channel-list-new.component.scss','../report-filters.scss']
})
export class ReportChannelListNewComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;


  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  reportList = [];
  selectedLco = 0;
  from: any = '0000-00-00';
  to: any = '0000-00-00';

  lcoList: LCOInfo[] = [];

  currentDate = new Date();
  statusValue = "2"
  channelType="2"
  broadcasterList = [];
  broadcasterId :any =0;

  sortByProperties = {
    channelId: 'channelId',
    channelName: 'channelName',
    broadcasterName: 'broadcasterName',
    channelLCN: 'channelLCN',
    channelType: 'channelType',
    language: 'language',
    genre: 'genre',
    description:'description',
    createdDate:'createdDate',
    channelStatus:'channelStatus'
  };

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
  
    this.fetchBroadcasterList()
    this.fetchData()
  }

  
  isLoad = 'Choose Date';

  onSearch(){
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchData();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }
  handleFetchPage(data): void {
    this.channelReportList = data.data;
    if (this.reportList.length != 0) {
      this.loadingFlag = false;
    } else {
      this.loadingFlag = false;
      this.isLoad = 'No Records';
    }
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  pageNumber
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  fetchData(): void { 
    this.loadingFlag = true;
    this.reportList = []
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    let url = PaginationUtilities.urlBuilder('reports/channelListReport', this.paginationObj);
    url = url + "&status=" + this.statusValue+"&channelType="+this.channelType+"&broadcasterId="+this.broadcasterId
    this.http.get(url).subscribe(
        (data) => {
          this.handleFetchPage(data);
        },
        () => {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      );
  }
  fetchBroadcasterList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }
  lcoSelect(): void {
    this.from = this.calendar.getDate()['startDate']
    this.to = this.calendar.getDate()['endDate']
    this.fetchData();
  }

  dateString;
  eve;
 

  // downloadTableExcel(): void {
  //   let name='Channel List  Report'
  //   let filters=`Source Type : SMS` 
  //   this.doc.exportTableToExcelwithImage(this.reportTable,name, 'channels_report',
  //     undefined,
  //     undefined,
  //   );
  // }


  downloadTableExcel(){
    let url= this.config.json.apiEndpoint+'generate/channelsListReport?channelType='+this.channelType+'&status='+this.statusValue+'&broadcasterId='+this.broadcasterId
    console.log(url)
    this.http.getwithresponseType(url).subscribe(
      (response:Blob) => {
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'channels_report.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
    )
  }



  downloadTablePdf(): void {
    // const tempDate = new Date(this.selectedDate);
    // const monthYear = tempDate.toLocaleString('default', {month:'long'}) + " " + tempDate.getFullYear();
    // this.pdf.generatePdfHtml('#reportTable',
    // 'channels_report',
    
    // );
    // broadcaster : ${this.broadcasterId} , Type : ${this.channelType} , Status : ${this.statusValue} 
    this.pdf.generatePdfHtml("#print",'channels_report',
      `Source Type : SMS `)
  }
  
  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.", "Channel ID", "Channel Name","Broadcaster Name", "Channel LCN","Status","Channel Type","Language","Genre","Description","Created Date"]
    };
    let data = []
    let i = 1
    this.channelReportList.forEach(line => {
      let csvLine = {
        slNo : i,
        channelId: line.channelId,
        channelName : line.channelName,
        broadcasterName : line.broadcasterName,
        channelNo: line.channelLCN,
        channelStatus:line.channelStatus,
        category: line.channelType,
        language: line.language,
        genre: line.genre,
        description: line.description,
        updated_date : line.createdDate
      }
      data.push(csvLine);
      i += 1
    });

    new ngxCsv(data, 'channels_report', options);
  }


  channelReportList = [];



}
