<div class="cms__content">
  <div class="top__heading">
    <h3>Bouquet management</h3>
    <a [routerLink]="['/dashboard']"><button class="default__button float-right">Back</button></a>
  </div>

  <ng-container *ngIf="isDistributor" >
    <div class="custom__table__filter">
      <span>
        Show
        <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
          <option
            *ngFor="let size of paginationObj.pageSizeOptions"
            [ngValue]="size"
          >
            {{ size }}
          </option>
        </select>
        Entries
      </span>
      <div class="table__right">
        <p class="table__summary">
          Total <span>{{ paginationObj.totalElements }}</span> records
        </p>
        <div class="search__container">
          <input
            type="text"
            autocomplete="off"
            class="custom__field"
            id="searchKeyword"
            [(ngModel)]="paginationObj.searchKeyword"
            (keyup.enter)="searchKeyWord()"
          />
          <button (click)="searchKeyWord()">
            <img src="./assets/search.png" alt="search" />
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="col-12 list__table">
    <div class="row table">
      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th *ngIf="isDistributor" >Bouquet ID</th>
            <th *ngIf="isDistributor" >Pack Type</th>
            <th>Bouquet name</th>
            <th>MRP</th>
            <th *ngIf="!isDistributor" >MSO Amount</th>
            <th *ngIf="!isDistributor" >Amount</th>
            <th *ngIf="!isDistributor" >Action</th>
            <th *ngIf="isDistributor" >Distributor Share</th>
            <th *ngIf="isDistributor" >Created Date</th>
          </tr>
        </thead>
        <tbody>
          <ng-container  *ngFor="let bouquet of bouquetList; let i = index" >
            <tr  >
              <td>{{i+1}}</td>
              <td *ngIf="isDistributor" >{{bouquet.bouquetId}}</td>
              <td *ngIf="isDistributor" >{{bouquet.packType}}</td>
              <td>{{bouquet.bouquetName}}</td>
              <td>{{bouquet.bouquetPrice}}</td>
              <td *ngIf="!isDistributor" >{{bouquet.msoAmount}}</td>
              <td *ngIf="!isDistributor" ><input type="text" [value]="bouquet.lcoAmount" (input)="handleInput($event,i, bouquet)" class="custom__field"></td>
              <td *ngIf="!isDistributor" ><button disabled [id]="'btn'+i" (click)="postLcoBouquetAmount(bouquet, i)" >Save</button></td>
              <td *ngIf="isDistributor" >{{bouquet.distributorShare}}</td>
              <td *ngIf="isDistributor" >{{bouquet.createdDate}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <ng-container *ngIf="isDistributor">
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</div>
