<div class="cms__content">
	<div class="container">
		<app-reports-header></app-reports-header>
		<div class="row">
			<div class="col-12 header__section">
				<div class="row">
					<div class="col-6">
						<!-- <img src="https://onnetsystems.net/images/onnetlogo.png" alt="BrandLogo"> -->
						<h5>Invoice Number : {{invoiceId}}</h5>
						<p class="date__time">{{date | date}}</p>
					</div>
					<!-- <div class="col-6">
						<h3>Onnet Systems</h3>
						<p>#702, Brigade Rubix, HMT Main Road, Peenya, 560011</p>
						<p>Ph: 9876543210</p>
						<p>Email: contact@onnetsystems.net</p>

					</div> -->
				</div>

			</div>
			<div class="col-12 billing__details">
				<div class="row">
					<div class="col-12">
						<table class="table1">
							<thead>
								<tr>
									<th>Billing Address</th>
									<th>Shipping Address</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<h5>{{subscriberData.subscriberName}}</h5>
										<p>{{subscriberData.email}}</p>
										<p>{{subscriberData.mobile}}</p>
										<p>{{subscriberData.landline}}</p>
										<p>{{subscriberData.billingAddress}}</p>
									</td>
									<td>
										<h5>{{subscriberData.subscriberName}}</h5>
										<p>{{subscriberData.email}}</p>
										<p>{{subscriberData.mobile}}</p>
										<p>{{subscriberData.landline}}</p>
										<p>{{subscriberData.installationAddress}}</p>
									</td>
								</tr>
							</tbody>
						</table>
						<table class="table2">
							<thead>
								<tr>
									<th>Product Name</th>
									<th>Product No.</th>
									<th>Product Type</th>
									<th>Price</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let product of invoice.product">
									<td>
										<h5>{{product.productName}}</h5>
									</td>
									<td>
										{{product.productId}}
									</td>
									<td>
										<h5>{{product.productType}}</h5>
									</td>
									<td>
										<h5>Rs {{product.productPrice}}</h5>
									</td>
								</tr>
								<!-- <tr>
									<td>
										<h5>Package name</h5>
										<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi facere
											iste odio?</p>
									</td>
									<td>
										1001
									</td>
									<td>
										<h5>Rs 200</h5>
									</td>
								</tr>
								<tr>
									<td>
										<h5>Package name</h5>
										<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi facere
											iste odio?</p>
									</td>
									<td>
										1001
									</td>
									<td>
										<h5>Rs 200</h5>
									</td>
								</tr> -->
								<tr>
									<td class="price_summary" colspan="3">Sub Total</td>
									<td>Rs {{invoice.billAmount.subTotal}}</td>
								</tr>
								<tr>
									<td class="price_summary_main" colspan="3">NCF Charges</td>
									<td>Rs {{invoice.billAmount.ncfCharges}}</td>
								</tr>
								<tr>
									<td class="price_summary" colspan="3">Total</td>
									<td>Rs {{invoice.billAmount.total}}</td>
								</tr>
								<tr>
									<td class="price_summary_main" colspan="3">GST</td>
									<td>{{invoice.billAmount.gstPercent}}%</td>
								</tr>

							</tbody>
						</table>
						<div class="col-12 total__price">
							<h5>Grand Total : </h5>
							<h3>Rs {{invoice.billAmount.grandTotal}}</h3>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>