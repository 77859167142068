import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';
import { SettingsInfo } from 'src/app/models/settings/settings-info';
import { FingerprintConfiguration } from 'src/app/models/fingerprint/fingerprint-configuration-info';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { ActivatedRoute } from '@angular/router';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { SubscriberInfo } from 'src/app/models/subscriber/subscriber-info';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { PageInfo } from 'src/app/models/pagination/page-info';
import { FingerprintInfo } from 'src/app/models/fingerprint/fingerprint-info';
import { SubscriberGroupInfo } from 'src/app/models/subscriber-group/subscriber-group-info';
import { ConfigService } from 'src/app/services/config.service';
import { MultiSelectType } from 'src/app/plugins/multiselect/multiselect.component';
import { BehaviorSubject } from 'rxjs';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';

@Component({
  selector: 'app-fingerprint-settings',
  templateUrl: './fingerprint-settings.component.html',
  styleUrls: ['./fingerprint-settings.component.scss'],
})
export class FingerprintSettingsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  fingerprintConfigurationForm: FormGroup;

  settings = {} as SettingsInfo;

  selectedTab = 0;

  subscriberSearch = false;

  chSearch = false;
  showSidePanel = false;
  fingerprintSendForm: FormGroup;
  isFixedFp = false;

  paginationObjSubscriber = new PaginationObj();
  paginationObjChannel = new PaginationObj();

  subscriberList: SubscriberInfo[] = [];
  channelList: ChannelInfo[] = [];
  subscriberGroupList: SubscriberGroupInfo[] = [];

  subLoadingFlag = false;
  chLoadingFlag = false;

  emmOvertFlag = true;
  ecmOvertFlag = true;

  fingerprintConfig: any;
  sidepanelHeading = 0;

  fpSubscriberTarget = 0;

  ngOnInit(): void {
    this.getFingerprintConfiguration();
    this.initFpConfigForm();
    this.initFpSendForm();
    this.getQueryParam();
    this.initPaginationOpt();
    this.getSubscriberGroups();
  }

  initPaginationOpt(): void {
    this.paginationObjSubscriber.pageSize = 20;
    this.paginationObjChannel.pageSize = 20;
  }

  getFingerprintConfiguration(): void {
    const url =
      this.config.json.apiEndpointRdbms +
      'fingerprintConfig/getFingerprintConfiguration';
    // const url = environment.apiEndpoint + 'fingerprint/getFingerprintList';
    this.http.get(url).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.fingerprintConfig = data[0];
        this.updateForm();
      }
    });
  }

  initFpConfigForm(): void {
    this.fingerprintConfigurationForm = new FormGroup({
      fingerprintEmm: this.initFormEmm(),
      fingerprintEcm: this.initFormEcm(),
    });

    this.listenFormChanges();
  }

  initFormEmm(): any {
    const config = new FormGroup({
      displayType: new FormControl(0, [Validators.required]),
      fingerprintEmmOvert: this.initFormEmmOvertCovert(),
      fingerprintEmmCovert: this.initFormEmmOvertCovert(),
    });
    return config;
  }

  initFormEcm(): any {
    const config = new FormGroup({
      displayType: new FormControl(0, [Validators.required]),
      fingerprintEcmOvert: this.initFormEcmOvertCovert(),
      fingerprintEcmCovert: this.initFormEcmOvertCovert(),
    });
    return config;
  }

  initFormEmmOvertCovert(): FormGroup {
    const config = new FormGroup({
      // duration: new FormControl(0, [Validators.required]),
      repeat_duration: new FormControl(0),
      expiry: new FormControl(new Date().toISOString().substring(0, 16), [
        Validators.required,
      ]),
      // expiry: new FormControl((new Date()).getTime(), [Validators.required]),

      repeat: new FormControl(0, [Validators.required]),
      repeat_interval: new FormControl(0),
      location: new FormControl(0, [Validators.required]),
      top: new FormControl(0),
      left: new FormControl(0),
      font_style: new FormControl(0, [Validators.required]),
      font_size: new FormControl(0, [Validators.required]),
      font_color: new FormControl(0, [Validators.required]),
      bg_color: new FormControl(0, [Validators.required]),
      content_identifier: new FormControl(0, [Validators.required]),
      covert_type: new FormControl(0, [Validators.required]),
      covert_pattern: new FormControl(0, [Validators.required]),
    });
    // //// console.log(config);
    return config;
  }

  initFormEcmOvertCovert(): FormGroup {
    const config = new FormGroup({
      // duration: new FormControl(0, [Validators.required]),
      repeat_duration: new FormControl(0),

      expiry: new FormControl(new Date().toISOString().substring(0, 16), [
        Validators.required,
      ]),
      // expiry: new FormControl((new Date()).getTime(), [Validators.required]),

      repeat: new FormControl(0, [Validators.required]),
      repeat_interval: new FormControl(0),
      location: new FormControl(0, [Validators.required]),
      top: new FormControl(0),
      left: new FormControl(0),
      font_style: new FormControl(0, [Validators.required]),
      font_size: new FormControl(0, [Validators.required]),
      font_color: new FormControl(0, [Validators.required]),
      bg_color: new FormControl(0, [Validators.required]),
      content_identifier: new FormControl(0, [Validators.required]),
      covert_type: new FormControl(0, [Validators.required]),
      covert_pattern: new FormControl(0, [Validators.required]),
    });
    return config;
  }

  listenFormChanges(): void {
    const emmGroup = this.fingerprintConfigurationForm.get(
      'fingerprintEmm'
    ) as FormGroup;
    emmGroup.get('displayType').valueChanges.subscribe((val) => {
      val === 0 ? (this.emmOvertFlag = true) : (this.emmOvertFlag = false);
    });

    const ecmGroup = this.fingerprintConfigurationForm.get(
      'fingerprintEcm'
    ) as FormGroup;
    ecmGroup.get('displayType').valueChanges.subscribe((val) => {
      val === 0 ? (this.ecmOvertFlag = true) : (this.ecmOvertFlag = false);
    });

    this.listenEmmFormChanges(emmGroup);
    this.listenEcmFormChanges(ecmGroup);
  }

  listenEmmFormChanges(emmGroup: FormGroup): void {
    const emmOvertGroup = emmGroup.get('fingerprintEmmOvert');
    emmOvertGroup.get('repeat').valueChanges.subscribe((val) => {
      if (val === 0) {
        emmOvertGroup.get('interval').disable();
        emmOvertGroup.get('duration').disable();
      } else {
        emmOvertGroup.get('interval').enable();
        emmOvertGroup.get('duration').enable();
      }
    });

    emmOvertGroup.get('location').valueChanges.subscribe((val) => {
      if (val === 0) {
        emmOvertGroup.get('top').disable();
        emmOvertGroup.get('left').disable();
      } else {
        emmOvertGroup.get('top').enable();
        emmOvertGroup.get('left').enable();
      }
    });
  }

  listenEcmFormChanges(ecmGroup: FormGroup): void {
    const ecmOvertGroup = ecmGroup.get('fingerprintEcmOvert');

    ecmOvertGroup.get('repeat').valueChanges.subscribe((val) => {
      if (val === 0) {
        ecmOvertGroup.get('interval').disable();
        ecmOvertGroup.get('duration').disable();
      } else {
        ecmOvertGroup.get('interval').enable();
        ecmOvertGroup.get('duration').enable();
      }
    });

    ecmOvertGroup.get('location').valueChanges.subscribe((val) => {
      if (val === 0) {
        ecmOvertGroup.get('top').disable();
        ecmOvertGroup.get('left').disable();
      } else {
        ecmOvertGroup.get('top').enable();
        ecmOvertGroup.get('left').enable();
      }
    });

    const ecmCovertGroup = ecmGroup.get('fingerprintEcmCovert');
    ecmCovertGroup.get('repeat').valueChanges.subscribe((val) => {
      if (val === 0) {
        ecmCovertGroup.get('interval').disable();
      } else {
        ecmCovertGroup.get('interval').enable();
      }
    });
  }

  updateForm(): void {
    const emmGroup = this.fingerprintConfigurationForm.get(
      'fingerprintEmm'
    ) as FormGroup;
    const ecmGroup = this.fingerprintConfigurationForm.get(
      'fingerprintEcm'
    ) as FormGroup;

    emmGroup
      .get('displayType')
      .setValue(
        this.fingerprintConfig.configuration.fingerprintEmm.displayType
      );
    ecmGroup
      .get('displayType')
      .setValue(
        this.fingerprintConfig.configuration.fingerprintEcm.displayType
      );

    this.updateConfig(
      emmGroup.get('fingerprintEmmOvert') as FormGroup,
      this.fingerprintConfig.configuration.fingerprintEmm.fingerprintEmmOvert
    );

    this.updateConfig(
      emmGroup.get('fingerprintEmmCovert') as FormGroup,
      this.fingerprintConfig.configuration.fingerprintEmm.fingerprintEmmCovert
    );

    this.updateConfig(
      ecmGroup.get('fingerprintEcmOvert') as FormGroup,
      this.fingerprintConfig.configuration.fingerprintEcm.fingerprintEcmOvert
    );

    this.updateConfig(
      ecmGroup.get('fingerprintEcmCovert') as FormGroup,
      this.fingerprintConfig.configuration.fingerprintEcm.fingerprintEcmCovert
    );
  }

  updateConfig(config: FormGroup, data: any): void {
    config.get('duration').setValue(data.duration);
    config
      .get('expiry')
      .setValue(new Date(data.expiry).toISOString().substring(0, 16));
    config.get('repeat').setValue(data.repeat);
    // Conditional check for only the fields that can be disabled
    config.get('interval').setValue(data.interval ? data.interval : 0);
    config.get('location').setValue(data.location);
    config.get('top').setValue(data.top ? data.top : 0);
    config.get('left').setValue(data.left ? data.left : 0);
    config.get('font_style').setValue(data.font_style);
    config.get('font_size').setValue(data.font_size);
    config.get('font_color').setValue(data.font_color);
    config.get('bg_color').setValue(data.bg_color);
    config.get('content_identifier').setValue(data.content_identifier);
    config.get('covert_type').setValue(data.covert_type);
    config.get('covert_pattern').setValue(data.covert_pattern);
  }

  searchText!: string
  isSearch = false
  getSearchText(e: Event){
    this.isSearch = false
    this.searchText = (e.target as HTMLInputElement).value
    if(this.searchText.length >= 3){
      setTimeout(() => {
        this.isSearch = true
      }, 300);
    }
  }

  lcoId!: string
  getLCOId(e: LcoSearchType){
    let inp = document.getElementById('lcoId') as HTMLInputElement
    inp.value = `${e.lcoName}: ${e.lcoCode}`
    this.lcoId = e.lcoId
    this.isSearch = false
  }

  //converting expiry time from UTC date time format to epoch time format
  // epochTimeExpiry(){
  //   this.fingerprintConfig.configuration["fingerprintEcm"]["fingerprintEcmCovert"]["expiry"] = (new Date(this.fingerprintConfig.configuration["fingerprintEcm"]["fingerprintEcmCovert"]["expiry"])).getTime()
  //   this.fingerprintConfig.fingerprintConfig.expiry = (new Date(this.fingerprintConfig.fingerprintConfig.expiry)).getTime()
  // }

  save(): void {
    const url =
      this.config.json.apiEndpointRdbms +
      'fingerprintConfig/updateFingerprintConfiguration';
    this.fingerprintConfig.configuration =
      this.fingerprintConfigurationForm.value;

    console.log(this.fingerprintConfigurationForm.value)
    // this.http.put(url, this.fingerprintConfig).subscribe((data) => {
    //   // //// console.log(data);
    //   window.alert('Updated');
    // });
  }

  persist(): void {
    this.save();
  }

  //////////////////////////////////////////////////////////////////////////////////
  // Sidebar Impl
  //////////////////////////////////////////////////////////////////////////////////

  subscriberAccordion(): void {
    this.subscriberSearch = !this.subscriberSearch;
  }

  channelAccordion(): void {
    this.chSearch = !this.chSearch;
  }

  closeSidePanel(): void {
    this.showSidePanel = false;
  }

  openSidePanel(): void {
    this.showSidePanel = true;
  }

  sendFingerprint(type: number): void {
    this.fpEmmEcmType.setValue(type);
    this.sidepanelHeading = type;
    this.openSidePanel();
  }

  getQueryParam(): void {
    if (this.route.snapshot.queryParamMap.has('subscriberId')) {
      const id = this.route.snapshot.queryParamMap.get('subscriberId');
      this.fingerprintSendForm.get('subscriberId').setValue(id);
this.getSubscriberCodeBySubscriberID(id);
    } else if (this.route.snapshot.queryParamMap.has('all')) {
      // converting string to boolean
    }
  }


  getSubscriberCodeBySubscriberID(id: string){
    const url = this.config.json.apiEndpointRdbms + 'subscriber/getSubscriber/'+id
    this.http.get(url).subscribe({
      next : (data: any) => {
        console.log(data);
        this.paginationObjSubscriber.searchKeyword = data.subscriberCode
        this.searchSubscriber()
      }
    })
  }

  initFpSendForm(): void {
    this.fingerprintSendForm = new FormGroup({
      subscriberFilter: new FormControl('name'),
      channelFilter: new FormControl('channelId'),
      fpEmmEcmType: new FormControl(0), // 0-emm, 1-ecm
      fpOvertCovertType: new FormControl(0), //0-overt, 1-covert
      channelIds: new FormControl(),
      subscriberTargetType: new FormControl(0), //0-individual, 1-group, 2-global
      groupId: new FormControl(''), // pid of subscriber group
      subscriberId: new FormControl(), // pid of subscriber
      stbIds: new FormControl('')
    });
    this.listenFpSendFormChanges();
  }
  setValidators() {
    console.log(this.fpSubscriberTarget);
    if(this.fpSubscriberTarget == 0){
      this.fingerprintSendForm.get('stbIds').setErrors({required: true})
    }else{
      this.fingerprintSendForm.get('stbIds').setErrors(null)
    }
  }

  stbIdsData: MultiSelectType = {
    dataArray: [],
    keyToDisplay: '',
    idForSelected:''
  }
  stbIds = new BehaviorSubject<MultiSelectType>(this.stbIdsData)

  get fpChannelId(): FormControl {
    return this.fingerprintSendForm.get('channelIds') as FormControl;
  }

  get fpSubscriberId(): FormControl {
    return this.fingerprintSendForm.get('subscriberId') as FormControl;
  }

  get fpGroupId(): FormControl {
    return this.fingerprintSendForm.get('groupId') as FormControl;
  }

  get fpSubscriberFilter(): FormControl {
    return this.fingerprintSendForm.get('subscriberFilter') as FormControl;
  }

  get fpChannelFilter(): FormControl {
    return this.fingerprintSendForm.get('channelFilter') as FormControl;
  }

  get fpEmmEcmType(): FormControl {
    return this.fingerprintSendForm.get('fpEmmEcmType') as FormControl;
  }

  get subscriberTargetType(): FormControl {
    return this.fingerprintSendForm.get('subscriberTargetType') as FormControl;
  }

  isStbIdAll = false
  listenFpSendFormChanges(): void {
    this.subscriberTargetType.valueChanges.subscribe((value: number) => {
      this.fpSubscriberTarget = value;
      
      if(value == 2){
        this.isStbIdAll = true
      }
      this.setValidators()
    });

    

    this.fpSubscriberId.valueChanges.subscribe((res) => {
      this.getStbData(res)
      
      this.fpSubscriberId.value
        ? this.fingerprintSendForm.setErrors(null)
        : this.fingerprintSendForm.setErrors({ invalid: true });
    });

    // Making the form valid or invalid based on multiple checks and conditions
    this.fingerprintSendForm.valueChanges.subscribe(() => {
      setTimeout(() => {
        if (this.fpEmmEcmType.value === 0) {
          if (this.subscriberTargetType.value === 0) {
            this.fpSubscriberId.value
              ? this.fingerprintSendForm.setErrors(null)
              : this.fingerprintSendForm.setErrors({ invalid: true });

            // this.fpChannelId.value ?
            //   this.fingerprintSendForm.setErrors(null) : this.fingerprintSendForm.setErrors({ 'invalid': true });
          } else if (this.subscriberTargetType.value === 1) {
            this.fpGroupId.value && this.fpGroupId.value !== ''
              ? this.fingerprintSendForm.setErrors(null)
              : this.fingerprintSendForm.setErrors({ invalid: true });
          } else {
            this.fingerprintSendForm.setErrors(null);
          }
        } else if (this.fpEmmEcmType.value === 1) {
          this.fpChannelId.value
            ? this.fingerprintSendForm.setErrors(null)
            : this.fingerprintSendForm.setErrors({ invalid: true });
        }
      }, 0);
    });
  }

  getStbData(suscriberId: number){
    console.log(this.subscriberList);
    let stbIdArray = [];
    let ethernetMacArray= [];
    for(let ele of this.subscriberList){
      if(ele['subscriberId']==suscriberId){
        if(ele['ethernetMacAddress'] && ele['stbId']){
          ethernetMacArray = (ele['ethernetMacAddress'] as string).split(',')
          stbIdArray = (ele['stbId'] as unknown as string).split(',')
        }
      }
    }
    console.log(stbIdArray, ethernetMacArray);
    let dataArray = []
    for(let i=0; i< Math.min(stbIdArray.length, ethernetMacArray.length); i++){
      let obj= {
        name: `STB ID: ${stbIdArray[i]}; Ethernet Mac Address: ${ethernetMacArray[i]}`,
        stbId: stbIdArray[i]
      }
      dataArray.push(obj)
    }
    this.stbIdsData = {
      ...this.stbIdsData,
      dataArray: dataArray,
      keyToDisplay: 'name',
      idForSelected: 'stbId'
    }
    this.stbIds.next(this.stbIdsData)
  }

  searchSubscriber(): void {
    // const url = "http://localhost:7080/admin/api/" + 'subscriber/getSubscribers/' + this.paginationObjSubscriber.pageNumber + '?pageSize=' + this.paginationObjSubscriber.pageSize + '&sortBy=' + this.paginationObjSubscriber.sortBy + '&sortOrder=' + this.paginationObjSubscriber.sortOrder + '&searchKeyword=' + this.paginationObjSubscriber.searchKeyword
    const url =
      this.config.json.apiEndpointRdbms +
      'subscriber/getSubscribers/' +
      this.paginationObjSubscriber.pageNumber +
      '?pageSize=' +
      this.paginationObjSubscriber.pageSize +
      '&sortBy=' +
      this.paginationObjSubscriber.sortBy +
      '&sortOrder=' +
      this.paginationObjSubscriber.sortOrder +
      '&searchKeyword=' +
      this.paginationObjSubscriber.searchKeyword;
    this.subLoadingFlag = true;
    this.http.get(url).subscribe((data: PageInfo<SubscriberInfo>) => {
      this.subLoadingFlag = false;
      this.handleSearchSubscriber(data);
      // // console.log(data)
    });
  }

  handleSearchSubscriber(data: PageInfo<SubscriberInfo>): void {
    this.subscriberList = data.data;
    // // console.log(this.subscriberList)
    this.subscriberList.forEach((val) => (val.checked = false));
  }

  searchChannel(): void {
    //  const url = "http://localhost:7080/admin/api/" + 'channel/getChannelsforfingerprint/' + this.paginationObjChannel.pageNumber + '?pageSize=' + this.paginationObjChannel.pageSize + '&sortBy=' + this.paginationObjChannel.sortBy + '&sortOrder=' + this.paginationObjChannel.sortOrder + '&searchKeyword=' + this.paginationObjChannel.searchKeyword
    const url =
      this.config.json.apiEndpointRdbms +
      'channel/getChannelsforfingerprint/' +
      this.paginationObjChannel.pageNumber +
      '?pageSize=' +
      this.paginationObjChannel.pageSize +
      '&sortBy=' +
      this.paginationObjChannel.sortBy +
      '&sortOrder=' +
      this.paginationObjChannel.sortOrder +
      '&searchKeyword=' +
      this.paginationObjChannel.searchKeyword;
    this.chLoadingFlag = true;
    this.http.get(url).subscribe((data: PageInfo<ChannelInfo>) => {
      this.chLoadingFlag = false;
      // // console.log(data)
      this.handleSearchChannel(data);
    });
  }

  handleSearchChannel(data: PageInfo<ChannelInfo>): void {
    this.channelList = data.data;
    // // console.log(this.channelList)
    this.channelList.forEach((val) => (val.checked = false));
  }

  subscriberCheckbox(data: SubscriberInfo): void {
    data.checked = !data.checked;

    if (data.checked) {
      this.subscriberList.forEach((val) => (val.checked = false));
      data.checked = true;
      this.fpSubscriberId.setValue(data.subscriberId);
    } else {
      this.fpSubscriberId.setValue(null);
    }
  }

  channelCheckbox(data: ChannelInfo): void {
    data.checked = !data.checked;

    if (data.checked) {
      this.channelList.forEach((val) => (val.checked = false));
      data.checked = true;
      this.fpChannelId.setValue((data as any).channel_id);
    } else {
      this.fpSubscriberId.setValue(null);
    }
  }

  getSubscriberGroups(): void {
    const url =
      this.config.json.apiEndpointRdbms + 'subscribergroup/getSubscriberGroups';
    this.http.get(url).subscribe((data) => {
      this.subscriberGroupList = data;
    });
  }

  subscriberListBySubscriberGroup;
  getSubscriberListBySubscriberGroupId() {
    let id = this.fingerprintSendForm.get('groupId').value;
    this.fingerprintSendForm.setErrors({ setSubIdBySubGroup: true });
    let url =
      this.config.json.apiEndpointRdbms +
      `subscribergroup/getSubscriberByGroup/${id}`;
    this.http.get(url).subscribe({
      next: (res) => {
        if (res.statusCode == 200) {
          this.subscriberListBySubscriberGroup = res.data.subscriberIds;
          this.fingerprintSendForm.setErrors(null);
        } else {
          alert('No Subscribers!');
        }
      },
      error: () => {
        alert('No Subscribers!');
      },
    });
  }

  fpPersist(): void {
    // //// console.log(this.fingerprintSendForm.value);
    this.fpSave();
  }

  // Create the request body for save fingerprint api
  fpSaveObj(): FingerprintInfo {
    const request = {} as FingerprintInfo;
    request.subscriberIds = [];
    request.channelIds = [];
    request.fingerprintType = this.sidepanelHeading === 0 ? 0 : 1;
    request.fingerprintConfig = this.fingerprintConfigurationForm.value;

    if (request.fingerprintType === 0) {
      if (this.fpSubscriberTarget === 0) {
        request.subscriberIds.push(Number(this.fpSubscriberId.value));
        request.channelIds.push(0);
      } else if (this.fpSubscriberTarget === 1) {
        // let gp: SubscriberGroupInfo = null;
        // this.subscriberGroupList.forEach(g => {
        //   if (g.id === Number(this.fpGroupId.value)) {
        //     gp = g;
        //   }
        // });
        // console.log(gp)
        // if (gp) {
        //   gp.subscribers.forEach(s => {
        //     request.subscriberIds.push(s.id);
        //   });
        // }
        request.subscriberIds = this.subscriberListBySubscriberGroup;
      } else {
        request.subscriberIds = 'ALL';
      }

      const emmGroup = this.fingerprintConfigurationForm.get(
        'fingerprintEmm'
      ) as FormGroup;
      if (emmGroup.get('displayType').value == 0) {
        request.fingerprintConfig = emmGroup.get('fingerprintEmmOvert').value;
        request.displayType = 0;
      } else {
        request.fingerprintConfig = emmGroup.get('fingerprintEmmCovert').value;
        request.displayType = 1;
      }
    } else {
      console.log(this.fpChannelId.value)
      request.channelIds = [this.fpChannelId.value];

      const ecmGroup = this.fingerprintConfigurationForm.get(
        'fingerprintEcm'
      ) as FormGroup;
      // //// console.log(ecmGroup)
      if (ecmGroup.get('displayType').value == 0) {
        request.fingerprintConfig = ecmGroup.get('fingerprintEcmOvert').value;
        request.displayType = 0;
      } else {
        request.fingerprintConfig = ecmGroup.get('fingerprintEcmCovert').value;
        request.displayType = 1;
      }
    }

    return request;
  }

  fpSave(): void {
    const url = this.getSaveUrl();
    const body = this.fpSaveObj();
    let expiryTime = body.fingerprintConfig.expiry as string,
    newExpiry = expiryTime.split("T").join(" ")
    body.fingerprintConfig.expiry = newExpiry
    body['stbIds'] = this.isStbIdAll ? "ALL" : []
    if(!this.isStbIdAll){
      for(let ele of this.fingerprintSendForm.get('stbIds').value){
        let stbId = +ele.stbId
        body['stbIds'].push(stbId)
      }
    }
    if(this.fpSubscriberTarget == 3){
      body['subscriberIds']=''
      body['lcoId']=this.lcoId
    }
    this.http.post(url, body).subscribe((data) => {
      window.alert(`${data.status} : ${data.message}`);
    });
  }

  getSaveUrl(): string {
    // const url = "http://134.209.148.41:9083/admin/api/fingerprint/pushFingerprint"
    // apiEndpoint: 'https://ott.vbctv.in/adminmongo/admin/api/'
    const url = this.config.json.apiEndpointRdbms + 'fingerprint/send';
    return url;
  }

  // Actions to be performed after fingerprint save success
  handleFpSave(): void {
    this.closeSidePanel();

    this.fpSubscriberId.setValue('');
    this.fpChannelId.setValue('');
    this.fpEmmEcmType.setValue(false);
    this.paginationObjSubscriber.searchKeyword = '';
    this.paginationObjChannel.searchKeyword = '';

    this.channelList = [];
    this.subscriberList = [];
  }
}
