import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PathNames } from 'src/app/constants/path-names';
import { RegionInfo } from 'src/app/models/regions/region-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-assign-lcm',
  templateUrl: './assign-lcm.component.html',
  styleUrls: ['./assign-lcm.component.scss']
})
export class AssignLcmComponent implements OnInit {

  regionIds
  isEdit
  regionLCMForm: FormGroup;
  region = {} as RegionInfo;
  channelList = [];

  constructor(private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService) { }

  ngOnInit(): void {
    this.getPathParam();
    this.initForm();
  }

  initForm(): void {
    this.regionLCMForm = new FormGroup({
      regionId: new FormControl('', [Validators.required]),
      regionName: new FormControl('', [Validators.required]),
      lcm:new FormControl('', [Validators.required])
    });
  }


  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.regionIds = id
      this.fetchRecord(id);
      this.fetchLCM(id);
      this.fetchChannelLCNList(id)
    }
  }
  
  fetchChannelLCNList(id) {
    let url = `${this.config.json.apiEndpoint}regionLCN/` + id;
    this.http.get(url).subscribe({
      next: (res: any) => {
        this.channelList = res.data
      },
    });
    // let url = this.config.json.apiEndpoint + 'channel/search?searchKeyword='
    // this.http.get(url).subscribe(
    //   (data) => {
    //     this.channelList = data.data
    //   }
    // )
  }

  fetchLCM(id: string){
      let url = `${this.config.json.apiEndpoint}landingchannel/` + id;
      this.http.get(url).subscribe(
      (res) => {
          this.lcm.setValue(res.data.lcm)
          console.log(this.lcm.value)
        })
      }

  fetchRecord(id: string): void {
    const url = this.config.json.apiEndpointRdbms + 'region/' + id;
    this.http.get(url).subscribe((data) => {
      this.region = data.data;
      this.regionName.setValue(this.region.regionName)
      this.regionId.setValue(this.region.id)
      console.log(this.region)
    });
  }

  get regionId(): FormControl {
    return this.regionLCMForm.get('regionId') as FormControl;
  }
  get regionName(): FormControl {
    return this.regionLCMForm.get('regionName') as FormControl;
  }
  get state(): FormControl {
    return this.regionLCMForm.get('state') as FormControl;
  }
  get lcm(): FormControl {
    return this.regionLCMForm.get('lcm') as FormControl;
  }

  persist(){
    let url=this.config.json.apiEndpoint +'landingchannel/create';
    let body = {
      "regionId":this.regionId.value,
    "lcm":this.lcm.value
    }
    console.log(body)
    this.http.post(url,body).subscribe(
      (data)=>{
        if(data.statusCode == 200){
        this.router.navigateByUrl('content/statewise-lcn')
        }
        else{
          alert(data.message)
        }
      }
    )
  }

}
