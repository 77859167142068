<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Next Fifteen Days Expiry Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="col-12 col-md-6">
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>
    <br/>

    <div class="col-12 col-md-6">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
  <div class="col-12 list__table">
    <div class="row">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Subscriber Name</th>
            <th>Subscriber ID</th>
            <!-- <th>Subscriber Code</th> -->
            <th>Product Name</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="expiryReportList.length == 0" >
            <td colspan="5" style="text-align: center;" >
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
            <tr *ngFor="let report of expiryReportList, let j = index">
              <td>{{j+1}}</td>
              <td>{{report.subscriberName}}</td>
              <td>{{report.subscriberId}}</td>
              <!-- <td>{{report.subscriberCode}}</td> -->
              <td>{{report.productName}}</td>
              <td>{{report.startDate | date: 'dd-MM-yyyy HH:mm:ss' : 'UTC'}}</td>
              <td>{{report.endDate | date: 'dd-MM-yyyy HH:mm:ss' : 'UTC' }}</td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- "bouquetId": null,
        "bouquetName": null,
        "channelId": null,
        "channelName": null,
        "channelNo": null,
        "subscriberId": 5715,
        "subscriberName": "Vineet Gupta",
        "startDate": "2022-12-17T00:32:27.000+00:00",
        "endDate": "2023-03-16T23:59:59.000+00:00",
        "productName": "IPTV130FOR3MONTH",
        "stbMacAddress": null -->
