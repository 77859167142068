<div class="cms__content">
  <div class="top__heading">
    <h3>Subscribers</h3>
    <a *ngIf="!isDistributor" [routerLink]="['/manage/subscriber/create']">
      <button class="default__button float-right">+ Add New</button>
    </a>
  </div>
  <div class="col-12 list__summary">
    <div class="row list-sum-row">
      <div class="col-6 col-md-3 list-sum-card">
        <div class="list__card">
          <span class="list__heading">Accounts</span>
          <span class="list__item">{{TotalSubscriberCount}}</span>
        </div>
      </div>
      <div class="col-6 col-md-3 list-sum-card">
        <div class="list__card">
          <span class="list__heading">Deactivated Accounts</span>
          <span class="list__item">{{SubscriberInActiveCount}}</span>
        </div>
      </div>
      <div class="col-6 col-md-3 list-sum-card" [ngClass]="isLCO || isDistributor ? 'hidden' : ''">
        <div class="list__card alt__card small__top">
          <span class="list__heading">Send Mail to All</span>
          <button (click)="sendMailAll()">Send</button>
        </div>
      </div>
      <div class="col-6 col-md-3 list-sum-card" [ngClass]="isLCO || isDistributor ? 'hidden' : ''">
        <div class="list__card alt__card small__top">
          <span class="list__heading">Send OSD to All</span>
          <button (click)="sendOSDAll()">Send</button>
        </div>
      </div>
    </div>
  </div>
  <div class="custom__table__filter">
    <span>
      Show
      <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
        <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
      </select>
      Entries
    </span>
    <div class="table__right">
      <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
      <div class="search__container">
        <input type="text" autocomplete="off" id="searchKeyword" class="custom__field" (change)="searchKeyword()" />
        <button><img src="./assets/search.png" alt="search" (click)="searchKeyword()" /></button>
      </div>
    </div>
  </div>


  <div class="col-12 list__table">
    <div class="row">
      <div class="custom__table__filter">
        <div class="table__right">
        </div>
      </div>
      <div class="tableBox">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th class="no__sort">Sl NO</th>
              <th class="no__sort">Subscriber Code</th>
              <th class="no__sort">LCO Code</th>
              <th class="no__sort">Name</th>
              <th class="no__sort">Phone Number</th>
              <th class="no__sort">Packages</th>
              <th class="no__sort">MAC Address</th>
              <th class="no__sort">Status</th>
              <th class="no__sort">STB ID</th>
              <th class="no__sort">Location</th>
              <th class="no__sort">Created Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="loadingFlag; else showList">
              <td class="loading" colspan="11">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <ng-container *ngIf="!subscriberList">
              <td  colspan="11" >{{isLoad}}</td>
            </ng-container>
            <ng-template #showList>
              <tr *ngFor="let subscriber of subscriberList, let j = index">
                <td>{{((paginationObj.pageNumber-1)*paginationObj.pageSize)+(j+1)}}</td>
                <td>
                  {{ subscriber.subscriberCode? subscriber.subscriberCode :
                  '---' }}
                </td>
                <td>
                  {{ subscriber.lcoCode? subscriber.lcoCode :
                  '---' }}
                </td>
                <td>
                  {{ subscriber.Name || subscriber.NAME ? subscriber.Name ||
                  subscriber.NAME : '---' }}
                </td>
                <td>
                  {{ subscriber.mobileNumber? subscriber.mobileNumber : '---' }}
                </td>
                <td>
                  {{ subscriber.packages? subscriber.packages : 'No Subscription' }}
                </td>
                <td>
                  {{ subscriber.ethernetMacAddress?
                  subscriber.ethernetMacAddress : '---' }}
                </td>
                <td>
                  {{ statUS[subscriber.status] || statUS[subscriber.STATUS] }}
                </td>
                <td>{{ subscriber.stbId? subscriber.stbId : '---' }}</td>
                <td>{{ (subscriber.locality ? subscriber.locality : "--" )+", "+(subscriber.city ? subscriber.city :
                  "--")+", "+(subscriber.district ? subscriber.district : "--")+", "+(subscriber.stateName ?
                  subscriber.stateName : "--" ) }}</td>
                <td>{{ subscriber.createdDate | date}}</td>
                <td>
                  <div class="action__items">
                    <button class="cta" (click)="viewSubscriberDetails(subscriber)">View</button>
                    <button [ngClass]="isDistributor ? 'hidden' : ''" (click)="edit(subscriber)">Edit</button>
                    <button [ngClass]="isLCO || isDistributor ? 'hidden' : ''" (click)="sendMail(subscriber)">
                      Mail
                    </button>
                    <button [ngClass]="isLCO || isDistributor ? 'hidden' : ''" (click)="sendOSD(subscriber)">
                      OSD
                    </button>
                    <button [ngClass]="isLCO || isDistributor ? 'hidden' : ''" (click)="sendFingerprint(subscriber)">
                      Fingerprint
                    </button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
<div class="modal_box" *ngIf="viewModal">
  <div class="modal_box_inner">
    <h3>Subscriber Details</h3>
    <div class="parent">
      <p class="div1">Subscriber ID</p>
      <p class="div2">{{subscriberDetails.doc[0].subscriber_id}}</p>
      <p class="div3">Subscriber Name</p>
      <p class="div4">{{subscriberDetails.doc[0].subscriberName}}</p>
      <p class="div5">Subscriber Code</p>
      <p class="div6">{{subscriberDetails.doc[0].subscriber_code}}</p>
      <p class="div7">Created Date</p>
      <p class="div8">{{subscriberDetails.doc[0].created_date | date}}</p>
      <p class="div18" style="text-align:start">Wallet balance</p>
      <p class="div19">{{subscriberDetails.doc[0].walletBalance | currency:'INR'}}</p>
      <div class="div13">
        <h6>Subscriber Document</h6>
      </div>
      <div>
        <div class="div9">

          <img class="img" onerror="this.src='assets/ImageError_notfound.png'"
            [src]="config.json.imageUrlPrefix+subscriberDetails.doc[0].caf_form_url" alt="Image not Uploaded">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imageUrlPrefix+subscriberDetails.doc[0].caf_form_url )"></div>
        </div>
        <p class="div14">CAF</p>
      </div>
      <div>
        <div class="div10">
          <img class="img" onerror="this.src='assets/ImageError_notfound.png'"
            [src]="config.json.imageUrlPrefix+subscriberDetails.doc[0].address_proof_url" alt="Image not Uploaded">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imageUrlPrefix+subscriberDetails.doc[0].address_proof_url)"></div>
        </div>
        <p class="div15">Address Proof</p>
      </div>
      <div>
        <div class="div11">
          <img class="img" onerror="this.src='assets/ImageError_notfound.png'"
            [src]="config.json.imageUrlPrefix+subscriberDetails.doc[0].identity_proof_url" alt="Image not Uploaded">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imageUrlPrefix+subscriberDetails.doc[0].identity_proof_url)"></div>
        </div>
        <p class="div16">Identity Proof</p>
      </div>
      <div>
        <div class="div12">
          <img class="img" onerror="this.src='assets/ImageError_notfound.png'"
            [src]="config.json.imageUrlPrefix+subscriberDetails.doc[0].customer_photo_url" alt="Image not Uploaded">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imageUrlPrefix+subscriberDetails.doc[0].customer_photo_url)"></div>
        </div>
        <p class="div17">Photo</p>
      </div>
    </div>
    <div class="custom_iptv_table">
      <h6>Active Subscription Details</h6>
      <table class="custom__iptv__table">
        <tbody>
          <tr>
            <th>STB Id</th>
            <th>Mac Address</th>
            <th>Device Type</th>
            <th>Bouquet Name</th>
            <th>Expiry Date</th>
          </tr>
          <ng-container *ngFor="let item of subscriberDetails.subscription">
            <tr>
              <td>{{item.stb_id}}</td>
              <td>{{item.ethernet_mac_address}}</td>
              <td>{{item.device_type}}</td>
              <td>{{item.bouquetName?item.bouquetName:'No Subscription'}}</td>
              <td>{{item.expiryDate?item.expiryDate:'--'}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <button class="cta float-right" (click)="viewModal = !viewModal"> Close </button>
  </div>
</div>
