<div class="cms__content">
  <div class="top__heading">
    <h3>Customize Messages</h3>
    <a [routerLink]="['/communication/osd']"> <button type="button" class="default__button float-right">Back</button></a>
  </div>
  <button type="button" class="float-right" (click)="editFlag ? cancel() : create()">{{editFlag ? 'Cancel' : '+ Add New'}}</button>
  <div class="clearfix"></div>
  <div class="col-12 list__summary">
    <!-- <div class="row">
      <div class="col-12 offset-md-8 col-md-4">
        <input type="text" autocomplete="off" class="custom__field" placeholder="Search" [(ngModel)]="search" />
      </div>
    </div> -->
  </div>
  <div class="col-12 list__table">
    <div class="row">

      <div class="custom__table__filter" style="margin-bottom:10px">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <!-- <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field"/>
            <button><img src="./assets/search.png" alt="search"/></button>
          </div> -->
        </div>
      </div>

      <table class="custom__iptv__table">
        <thead>
          <tr>
            <!-- <th></th> -->
            <th (click)="sortByItem(sortByProperties.title)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.title}">
              Title
            </th>
            <th (click)="sortByItem(sortByProperties.message)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.message}">
              Message
            </th>
            <th (click)="sortByItem(sortByProperties.udate)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.udate}">
              Last Updated
            </th>
            <th (click)="sortByItem(sortByProperties.cdate)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.cdate}">
              Created on
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="edit__row__table" *ngIf="editFlag">
            <td></td>
            <td colspan="2">
              <form action="" [formGroup]="messageForm" (ngSubmit)="persist()">
                <h5 class="highlight">Message</h5>
                <input type="text" autocomplete="off" class="custom__field" placeholder="Title"
                  formControlName="title" />
                <textarea class="custom__field" placeholder="OSD Message" formControlName="message"></textarea>
                <button type="button" (click)="cancel()">Cancel</button>
                <button class="cta float-right">Save</button>
              </form>
            </td>
            <td></td>
          </tr>

          <tr *ngIf="!loadingFlag && errorFlag;">
            <td colspan="6" style="text-align: center;">No Data</td>
          </tr>

          <tr *ngIf="loadingFlag && !errorFlag; else showList">
            <td class="loading" colspan="5">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>

          <ng-template #showList>
            <tr *ngFor="let msg of messageList | search: search; let i = index ">
              <!-- <td></td> -->
              <td>{{msg.title}}</td>
              <td>{{msg.message}}</td>
              <td>{{msg.updatedDate | date:'MMM d, y HH:mm'}}</td>
              <td>
                {{msg.createdDate | date:'MMM d, y HH:mm'}}
                <div class="action__items">
                  <button type="button" (click)="update(msg, i)">Edit</button>
                  <button type="button" class="danger" (click)="delete(msg, i)">Delete</button>
                </div>

              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>