
  <div class="cms__content">
    <app-reports-header></app-reports-header>
  
    <div class="top__heading">
      <h3>Complete Logs</h3>
      <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>
    <!-- <div class="row filterbtnStyle">
      <div class="search_filters">
        <div class="col-12 col-md-6">
          <label for="month">Select Date:</label>
          <div class="filter__group">
          <app-date-picker (dateChange)="dateChange($event)"></app-date-picker></div>
        </div>
        <div class="col-12 col-md-6">
          <label class="report_selection_label">Status</label>
          <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="statusValue" (change)="fetchData()" >
            <option value="All">ALL</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Terminated">Terminated</option>
            <option value="Suspended">Suspended</option>
            <option value="Blacklisted">Blacklisted</option>
          </select>
        </div>
      </div>
    </div> -->
  
  
    <div class="filter-download_container">
        <div class="filter_container">
          <div class="label-container">
            <label for="">Select Date :</label>
          </div>
          <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
          <div class="div label-container">
            <label class="">Type:</label>
          </div>
          <select class="custom__field report_selection" [(ngModel)]="statusValue" (change)='fetchData()'>
              <option value = "All" >ALL</option>
              <option value = "ACL_Group" >ACL_Group</option>
              <option value = "Bouquet" >Bouquet</option>
              <option value = "Broadcaster" >Broadcaster</option>
              <option value = "CATEGORY" >CATEGORY</option>
              <option value = "Channel" >Channel</option>
              <option value = "DISTRIBUTOR" >DISTRIBUTOR</option>
              <option value = "Location" >Location</option>
              <option value = "MSO" >MSO</option>
              <option value = "SUBSCRIBER" >SUBSCRIBER</option>
              <option value = "LCO" >LCO</option>
              <option value = "SUBSCRIBER_GROUP" >SUBSCRIBER_GROUP</option>
              <option value = "STB" >STB</option>
              <option value = "SUBSCRIPTION" >SUBSCRIPTION</option>
          </select>
          
            
        </div>
      </div>
  
    <div class="row"> 
      <div class="col-md-6 report-generated-fix">
        <p>
          Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
        </p>
      </div>
      <div class="download__report col-md-6">
        <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
          PDF
          <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
          Excel
          <img src="./assets/excel.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
          CSV
          <img src="./assets/csv.svg" alt="download" /> </button>
      </div>
    </div>
  
    <div class="col-12 list__table">
      <div class="row overflow">
        <div class="custom__table__filter">
          <span>
            Show
            <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
              <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
            </select>
            Entries
          </span>
          <!-- -->
          <div class="col-12 col-md-6 table-search">
            <div class="table__right">
              <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> record{{reportList.length == 1?
                '' : 's'}}</p>
              <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
              <!-- </p> -->
            </div>
            <div class="search__container">
              <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()" [(ngModel)]="paginationObj.searchKeyword" />
              <button (click)="onSearch()" (keydown.enter)="onSearch()">
                <img src="./assets/search.png" alt="search" />
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="row scroll "> -->
            <table class="custom__iptv__table" #reportTable id="reportTable">
                <thead>
                  <tr>
                    <th>Sl No.</th>
                    <th (click)="sortByItem(sortByProperties.category)" [ngClass]="{
                      selected: paginationObj.sortBy === sortByProperties.category}">Category</th>
                    <th (click)="sortByItem(sortByProperties.ACTION)" [ngClass]="{
                      selected: paginationObj.sortBy === sortByProperties.ACTION}">Action</th>
                    <th (click)="sortByItem(sortByProperties.srNo)" [ngClass]="{
                      selected: paginationObj.sortBy === sortByProperties.srNo}">Sr No</th>
                    <th (click)="sortByItem(sortByProperties.username)" [ngClass]="{
                      selected: paginationObj.sortBy === sortByProperties.username}">UserName</th>
                    <th (click)="sortByItem(sortByProperties.description)" [ngClass]="{
                      selected: paginationObj.sortBy === sortByProperties.description}">Description</th>
                    <th (click)="sortByItem(sortByProperties.date)" [ngClass]="{
                      selected: paginationObj.sortBy === sortByProperties.date}">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="reportList.length == 0">
                    <td *ngIf="!isLoading" colspan="7" style="text-align: center">
                      <app-custom-spinner></app-custom-spinner>
                    </td>
                    <td colspan="7" style="text-align: center" *ngIf="isLoading">
                      {{ isLoad }}
                    </td>
                  </tr>
                  <tr *ngFor="let report of reportList; let j = index">
                    <!-- <tr *ngFor="let report of Object.entries(reportList), let j= index"> -->
                    <td>{{ j + 1 }}</td>
                    <td>{{ report.category ? report.category : "---No Data---" }}</td>
                    <td>{{report.ACTION}}</td>
                    <td>{{report.srNo}}</td>
                    <td>{{report.username}}</td>
        
                    <td
                      *ngIf="
                        report.description || report.DESCRIPTION as description;
                        else noDescription
                      "
                    >
                      {{ description }}
                    </td>
                    <ng-template #noDescription>
                      <td>---No Data---</td>
                    </ng-template>
        
                    <td *ngIf="report.transactionDate as date; else noDate">
                      {{ date | date : "dd-MM-yyyy HH:mm:ss" : "UTC" }}
                    </td>
                    <ng-template #noDate>
                      <td>---No Data---</td>
                    </ng-template>
                    <!-- <td>{{report.status}}</td> -->
                    <!-- <td>{{report.targetId}}</td> -->
                  </tr>
                </tbody>
              </table>
        <div class="custom__pagination">
          <ul>
            <li>
              <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                <img src="./assets/firstPage.png" /></button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                <img src="./assets/previousPage.png" /></button>
            </li>
            <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
            <li>
              <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                <img src="./assets/nextPage.png" /></button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                <img src="./assets/lastPage.png" /></button>
            </li>
          </ul>
  
        </div>
      </div>
    </div>
  </div>