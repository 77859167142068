// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Localhost
  // apiEndpoint: 'http://localhost:9083/admin/api/',
  // apiEndpointRdbms: 'http://localhost:9081/admin/api/',
  // imageUploadEndpoint: 'http://134.209.148.41:4040/upload',
  // imageHttpsUrlPrefix: 'https://keycloak.onnetsystems.ml/console/',
  // imageUrlPrefix: 'https://keycloak.onnetsystems.ml/',

  // DO iptv demo server apis (139.59.69.67)
  // apiEndpointRdbms: 'http://139.59.69.67:7080/admin/api/',
  // apiEndpoint: 'http://139.59.69.67:7090/admin/api/',
  // imageUploadEndpoint: 'http://103.40.48.155:9081/admin/upload/',
  // imageHttpsUrlPrefix: 'http://103.40.48.155:9081/console/',
  // imageUrlPrefix: 'http://103.40.48.155:9081/',

  // DO iptv demo server apis MERGED (139.59.69.67)
  // apiEndpointRdbms: 'http://139.59.69.67:7070/admin/api/',
  // apiEndpoint: 'http://139.59.69.67:7070/admin/api/',
  // imageUploadEndpoint: 'http://103.40.48.155:90812/admin/upload/',
  // imageHttpsUrlPrefix: 'http://103.40.48.155:9081/console/',
  // imageUrlPrefix: 'http://103.40.48.155:9081/',

  // Pioneer iptv demo server apis
  // apiEndpointRdbms: 'http://202.65.136.8:7080/admin/api/',
  // apiEndpoint: 'http://202.65.136.8:7090/admin/api/',
  // imageUploadEndpoint: 'http://103.40.48.155:9081/admin/upload/',
  // imageHttpsUrlPrefix: 'http://103.40.48.155:9081/console/',
  // imageUrlPrefix: 'http://103.40.48.155:9081/',

  //audit server
  // apiEndpointRdbms: 'http://125.62.213.82:9081/admin/api/',
  // apiEndpoint: 'http://125.62.213.82:9083/admin/api/',
  // imageUploadEndpoint: 'http://125.62.213.82:9081/admin/upload/',
  // imageHttpsUrlPrefix: 'http://125.62.213.82:9081/console/',
  // imageUrlPrefix: 'http://125.62.213.82:9081/',

  //Merged backend VBC
  // apiEndpointRdbms: 'http://125.62.213.82:7080/admin/api/',
  // apiEndpoint: 'http://125.62.213.82:7080/admin/api/',
  // imageUploadEndpoint: 'http://125.62.213.82:7080/admin/upload/',
  // imageHttpsUrlPrefix: 'http://125.62.213.82:7080/console/',
  // imageUrlPrefix: 'http://125.62.213.82:7080/',

  //Elixir Merged
  // apiEndpointRdbms: 'http://103.43.5.58:7080/admin/api/',
  // apiEndpoint: 'http://103.43.5.58:7080/admin/api/',
  // imageUploadEndpoint: 'http://103.43.5.58:7080/admin/upload/',
  // imageHttpsUrlPrefix: 'http://103.43.5.58:7080/console/',
  // imageUrlPrefix: 'http://103.43.5.58:7080/',

  //Pioneer IPTV
  // apiEndpointRdbms: 'http://103.248.209.132:7070/admin/api/',
  // apiEndpoint: 'http://103.248.209.132:7070/admin/api/',
  // imageUploadEndpoint: 'http://103.248.209.132:7070/admin/upload/',
  // imageHttpsUrlPrefix: 'http://103.248.209.132:7070/console/',
  // imageUrlPrefix: 'http://103.248.209.132:7070/',

  // apiEndpointRdbms: 'http://103.44.12.82:7070/admin/api/',
  // apiEndpoint: 'http://103.44.12.82:7070/admin/api/',
  // imageUploadEndpoint: 'http://103.44.12.82:7070/admin/upload/',
  // imageHttpsUrlPrefix: 'http://103.44.12.82:7070/console/',
  // imageUrlPrefix: 'http://103.44.12.82:7070/',

  // apiEndpointRdbms: 'http://192.168.1.230:7070/admin/api/',
  // apiEndpoint: 'http://192.168.1.230:7070/admin/api/',
  // imageUploadEndpoint: 'http://192.168.1.230:7070/admin/upload/',
  // imageHttpsUrlPrefix: 'http://192.168.1.230:7070/console/',
  // imageUrlPrefix: 'http://192.168.1.230:7070/',

  // apiEndpointRdbms: 'http://103.248.116.131:7070/admin/api/',
  // apiEndpoint: 'http://103.248.116.131:7070/admin/api/',
  // imageUploadEndpoint: 'http://103.248.116.131:7070/admin/upload/',
  // imageHttpsUrlPrefix: 'http://103.248.116.131:7070/console/',
  // imageUrlPrefix: 'http://103.248.116.131:7070/',
  // apiEndPointGigantic : "http://103.248.116.131:7070/admin/api/",

  // "apiEndpointRdbms": "http://64.227.186.193:7070/admin/api/",
  // "apiEndpoint":  "http://64.227.186.193:7070/admin/api/",
  // "imageUploadEndpoint": "http://64.227.186.193:7070/admin/upload/",
  // "imageHttpsUrlPrefix": "http://64.227.186.193:7070/console/",
  // "imageUrlPrefix": "http://64.227.186.193:7070/",
  // "apiEndPointGigantic" : "http://64.227.186.193:7070/admin/api/",

    "apiEndpointRdbms": "http://206.189.140.49:5000/admin/api/",
    "apiEndpoint":  "http://206.189.140.49:5000/admin/api/",
    "apiEndpointVOne":  "http://206.189.140.49:5000/iptv/v1.0.2/",
    "imageUploadEndpoint": "http://206.189.140.49:5000/admin/upload/",
    "imageHttpsUrlPrefix": "http://206.189.140.49:5000/console/",
    "imageUrlPrefix": "http://206.189.140.49:5000/",
    "apiEndPointGigantic" : "http://206.189.140.49:5000/admin/api/",
    // "localhost" : "http://192.168.10.60:7090/iptv/v1.0.2/",


  defaultPageSize: 10,
  defaultPageNumber: 1,
  defaultSortBy: 'createdDate',
  defaultSortOrder: 'DESC',
  defaultPageSizeOptions: [5, 10, 20, 50, 100, 1000, 3000]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
