import { Component, ElementRef, ViewChild } from '@angular/core';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { ngxCsv } from 'ngx-csv';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-universal-report',
  templateUrl: './universal-report.component.html',
  styleUrls: ['./universal-report.component.scss'],
})
export class UniversalReportComponent {
  profileInfo = {};
  paginationObj = new PaginationObj();
  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  broadcasterList: BroadcasterInfo[] = [];
  broadcasterId: number = 0;
  reportList = [];
  from;
  to;
  totalPages;
  searchKeywordW = '';
  currentDate = new Date();
  isLoad = 'Choose Date';
  pageSize = 10;
  pageNumber = 1;
  dateSelected = false;
  ev;
  reportDate;
  isDateSelected = false;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private doc: DocumentGeneratorService,
    private config: ConfigService,
    private pdf: PdfGeneratorService,
    private report: ReportTraiDataService,
    private http: HttpClientWrapperService
  ) {}

  ngOnInit(): void {
    this.isLoad = 'Please select Month';
    this.paginationObj.pageNumber = 1;
    this.report.getBroadcasterList().subscribe((data) => {
      this.broadcasterList = data;
      this.broadcasterId = this.broadcasterList[0].id;
      // this.fetchData()
    });
  }

  body = {
    monthYear: `${new Date().getFullYear()}-${String(
      new Date().getMonth() + 1
    ).padStart(2, '0')}-01`,
  };
  dateChange(e: Event) {
    let monthYear = (e.target as HTMLInputElement).value;
    monthYear += '-01';
    this.body = {
      ...this.body,
      monthYear,
    };
    if(monthYear=='-01'){
      this.reportList = [];
      this.isLoad = 'Please select Month';
      this.isDateSelected=false;
    }else{
      this.isDateSelected=true;
      this.fetchData(this.body);
    }
    
    
  }

  fetchData(body?: any) {
    this.loadingFlag = true;
    this.body['broadcasterId'] = this.broadcasterId;
    let url = `${this.config.json.apiEndpoint}reports/universalReport`;
    this.http.post(url, this.body).subscribe({
      next: (res) => {
        // this.handlePageMetadata(res.metadata);
        this.loadingFlag = false;
        if (res.data == null) {
          this.isLoad = 'No Data';
          this.loadingFlag = false;
        }
        if (res.data?.length == 0) {
          this.isLoad = 'No Data';
          this.loadingFlag = false;
        }
        this.reportList = res.data;
      },
      error: () => {
        this.loadingFlag = true;
        this.isLoad = 'Error Fetching Data';
      },
      complete: () => {
        this.loadingFlag = false;
        console.log(this.paginationObj);
      },
    });
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus();
  }

  // downloadTableExcel(): void {
  //   let name = 'Universal Report';
  //   if (this.reportList.length == 0) {
  //     alert('No Data');
  //     return;
  //   }
  //   this.doc.exportTableToExcelwithImage(
  //     this.reportTable,
  //     name,
  //     'universal_report'
  //   );
  // }


  downloadTableExcel(){
    let url= this.config.json.apiEndpoint+'generate/universalReport'
    this.http.postwithresponseType(url,this.body).subscribe(
      (response:Blob) => {
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'universal_report.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
    )
  }

  downloadTablePdf(): void {
    this.pdf.generatePDFForLongTable(
      'universal_report',
      `Date: ${this.body.monthYear}`, 800
    );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ['Particular', '7 Days', '14 Days', '21 Days', '28 Days'],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((report) => {
      let csvLine = {
        typee: report.typee,
        subscriberCountAt7: report.subscriberCountAt7,
        subscriberCountAt14: report.subscriberCountAt14,
        subscriberCountAt21: report.subscriberCountAt21,
        subscriberCountAt28: report.subscriberCountAt28,
      };
      data.push(csvLine);
    });

    new ngxCsv(data, 'universal_report', options);
  }

  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }

  isBroadcasterSelected = true;
  broadcasterSelected() {
    // this.isBroadcasterSelected=true
    this.reportList = [];
    if (this.isDateSelected) {
      // // console.log(this.body);
      this.fetchData(this.body);
      this.loadingFlag=true;
    } else {
      this.isLoad = 'Please select Month';
    }
  }
}
