<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Subscription wise subscriber Active & De-active with Location, Date & Time</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row row-top">
    <div class="date_filter">
      <div class="div_tabs" *ngIf="dateSelector">
        <button (click)="dateTypeSelectors(1)" >Month</button>
        <button (click)="dateTypeSelectors(2)" >Till Date</button>
      </div>
      <div class="div_tabs2" *ngIf="showMonth" >
       <div class="date_box">
        <label for="month">Select Month:</label>
        <div class="date_box_orig">
          <input class="custom__field mar_zeroed" type="month" id="month"  value="selectedDate" (change)="dateChangeMonth($event)">
        </div>
       </div>
        <button (click)="dateTypeSelectors(0)" >X</button>
      </div>
      <div class="div_tabs2" *ngIf="showDateRange" >
        <div class="date_box">
          <label for="month">Select Date:</label>
          <div class="date_box_orig">
            <input class="custom__field mar_zeroed" type="date" id="month"  value="selectedDate" (change)="dateChangeRange($event)">
          </div>
         </div>
        <button id="app_date_pick_button" (click)="dateTypeSelectors(0)" >X</button>
      </div>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
        <img src="./assets/csv.svg" alt="download" />			</button>
    </div>
  </div>

  <div class="search_filters">
    <div class="col-12 col-md-6 report_selection_block">
      <label class="report_selection_label">Select Location</label>
      <select class="custom__field report_selection" id="locationList" (change)="locationData()" [disabled] = '!dateChoosed' >
        <option value="0">All</option>
        <option *ngFor="let item of stateList" [value]="item">{{item}}</option>
      </select>
    </div>
    <div class="col-md-6 report_selection_block">
      <label class="report_selection_label">Subscriber Status</label>
      <select class="custom__field report_selection" id="statusActiveInactive" (change)="statusData()"  [disabled] = '!dateChoosed'>
        <option value="2">All</option>
        <option value="0">Inactive</option>
        <option value="1">Active</option>
      </select>
    </div>
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6 report">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div style="display: flex; gap:20px" >
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <th>Sl no</th>
            <th>Subscriber Code</th>
            <!-- <th>Subscriber ID</th> -->
            <th>Subscriber Name</th>

            <th>Subscription Type</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <!-- <th>Subscriber Name</th> -->
            <th>MAC ID</th>
            <th>Price Package</th>
            <th>Start Date</th>
            <th>Subscriber Status</th>
            <th>Subscription Status</th>

            <!-- <th>Report Generation time</th> -->
            <!-- <th>Total Subscriptions</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length ==0 && !loadingFlag" >
            <td style="text-align: center !important;" colspan="12" >{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag">
            <td style="text-align: center !important;" colspan="12">
            <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{j+1}}</td>
              <td>{{report.subscriber_code}}</td>
              <!-- <td>{{report.subscriber_id}}</td> -->
              <td>{{report.subscriber_name}}</td>
              <td>{{report.subscription_type}}</td>
              <td>{{report.city}}</td>
              <td>{{report.state_name}}</td>
              <td>{{report.country}}</td>
              <td>{{report.ethernet_mac_address}}</td>
              <td>{{report.price_package_name}}</td>
              <td>{{report.start_date| date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
              <td>{{report.subscriber_status==1? 'Active':'Inactive'}}</td>
              <td>{{report.subscription_status==1? 'Active':'Inactive'}}</td>

              <!-- <td>{{(report.subscriberCountAt7+report.subscriberCountAt14+
                report.subscriberCountAt21+report.subscriberCountAt28)/4}}</td> -->
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!--
:
"000154338"
city
:
"Central Delhi"
country
:
"India"
ethernet_mac_address
:
"00:22:6D:9B:80:D4"
price_package_name
:
"IPTV198WIBRO3MONTH,SONY SAB HD"
start_date
:
"2023-01-01T00:00:00.000+00:00"
state_name
:
"Delhi"
subscriber_code
:
"CEN44163"
subscriber_id
:
7269
subscriber_name
:
"Mr.Anil Verma"
subscriber_status
:
1
subscription_status
:
"1"
subscription_type
:
"ALACARETE_PACK,BASE_PACK" -->
