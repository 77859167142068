import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelGenAndUploadService {

  constructor(
    public http: HttpClientWrapperService,
  ) { }

  saveSample(sampleData, fileName) {
    // console.log(sampleData)
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sampleData);
    // console.log(worksheet)
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = fileName + '.xlsx';
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      // document.body.removeChild(link);
    }, 300);
  }

  uploadFileforValidation(e, url): Observable<Object> {
    let file = e.target.files[0]
    let formData = new FormData()
    formData.append('file', file)
    return this.http.post(url, formData).pipe(
      map((res) => {
        // console.log(res)
        // console.log(res.statusCode)
        alert(res.message || res.desc)
        if (res.statusCode == 200) {
          if (res.message.toUpperCase() === 'VALIDATION COMPLETED SUCCESSFULLY' && (res.errors?.length == 0 || res.errors == null)) {

            return { result: true }
          } else {
            if (res.desc != '' && res.desc != null) {
              const string = res.desc
              const regex = /\[(.*?)\]/;
              const match = string.match(regex);
              let errorBody = []
              let result = false
              if (match && match.length > 1) {
                var rows: [] = match[1].split(',').map(Number);
              }
              for (let ele in rows) {
                for (let elem in res.errors) {
                  if (ele == elem) {
                    errorBody.push(`Row ${rows[ele]}: ${res.errors[elem]}`)
                  }
                }
              }
              return { result, errorBody }
            } else {
              return { result: false, errorBody: res.errors, alert: res.message }
            }
          }
        } else {
          let result = false
          let errorMessage = res.message != null ? res.message : res.desc
          return { result, errorMessage }
        }

      }),
      finalize(()=>{
        formData = new FormData()
      })
    )
  }

  deselectSelectedFiles(...e: HTMLElement[]) {
    if (e[0] instanceof HTMLInputElement) {
      (e[0] as HTMLInputElement).value = ''
    }
    if (e[1] instanceof HTMLSelectElement) {
      (e[1] as HTMLSelectElement).value = ''
    }
  }

  uploadFileForSubmit(e, url): Observable<Object> {
    let file = e.target.files[0]
    let formData = new FormData()
    formData.append('file', file)
    return this.http.post(url, formData).pipe(
      map((res) => {
        alert(res.message)
        if (res.message === 'Bulk upload completed successfully' && res.errors.length === 0) {
          return { result: true, 'message': res.message }
        } else {
          return { result: false, 'message': res.message, errorBody: res.errors }
        }
      }),
      finalize(()=>{
        formData = new FormData()
      })
      )
  }

  uploadFileForSubmitwithparams(e, url,id): Observable<Object> {
    let file = e.target.files[0]
    let formData = new FormData()
    formData.append('file', file)
    formData.append('lcoId', id)
    return this.http.post(url, formData).pipe(
      map((res) => {
        alert(res.message)
        if (res.message === 'Bulk upload completed successfully' && res.errors.length === 0) {
          return { result: true, 'message': res.message }
        } else {
          return { result: false, 'message': res.message, errorBody: res.errors }
        }
      }),
      finalize(()=>{
        formData = new FormData()
      })
      )
  }

}
