import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  constructor() { }

  private accessSubject = new Subject<boolean>();

  getAccessSubject(): Observable<boolean> {
    return this.accessSubject.asObservable();
  }

  setAccessSubject(access: boolean): void {
    this.accessSubject.next(access);
  }
}
