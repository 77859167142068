import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from '../plugins/logs-data.service';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';


@Component({
  selector: 'app-logs-stb-activation-deactivation-new',
  templateUrl: './logs-stb-activation-deactivation-new.component.html',
  styleUrls: ['./logs-stb-activation-deactivation-new.component.scss']
})
export class LogsStbActivationDeactivationNewComponent implements OnInit {
  reportList = [];

  // macAddressList: stbMacInfo[] = []
  selectedStbMacAddress: string;

  from: any;
  to: any;
  paginationObj = new PaginationObj();
  currentDate = new Date();
  selectedCategory:any = 'All'
  categoryList =[]
  deviceType : any="All"

  sortByProperties = {
    stbId: 'stbId',
    subscriberCode: 'subscriberCode',
    ethernetMac: 'ethernetMac',
    broadcasterName:'broadcasterName',
    STATUS: 'STATUS',
    ACTION: 'ACTION',
    fromDate:'fromDate',
    commandStb:'commandStb',
    createdDate:'createdDate',
    description:'description',
   stbLevel:'stbLevel'
  };

  userId
 loadingFlag = false
  isLoad = 'Choose Date'
  user

  dateString;
  eve

  disableFirstBtn = false;
  disablePreviousBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }
 
  
  ngOnInit(): void {
    if(this.config.json.userType=='MSO'){
      this.user=true
    }
    else{
      this.user=false
    }
    this.userId=JSON.parse(localStorage.getItem('profileInfo')).id
   
    this.fetchData()
  }

  
  fetchData(): void {
  
    this.loadingFlag = true
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint)
    let url=''
    if(this.user==true){
     url = PaginationUtilities.urlBuilder('logs/stbActivity', this.paginationObj)
     url=url+"&status="+this.selectedCategory+"&deviceType="+this.deviceType
     if(this.from!=undefined && this.to!=undefined){
    url += `&fromDate=${this.from}&toDate=${this.to}`}
    }
    else{
      url = PaginationUtilities.urlBuilder('logs/stbActivityLogsForLcoOrDistributor', this.paginationObj)
    url += `&fromDate=${this.from}&toDate=${this.to}&userType=${this.config.json.userType}&userId=`+this.userId
    }
    this.http.get(url).subscribe({
      next: (data: any) => {
        this.handlePageMetadata(data.metadata)
        this.reportList = data.data;
        this.loadingFlag = false;
        if (this.reportList.length!= 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = "No Records"
        }
      },
      error: (error) => {
        this.loadingFlag = false;
      }
    })
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus()
  }

  formatDate(ele) {
    if(ele == undefined){
      return false
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  
  
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [this.formatDate(ev.startDate), this.formatDate(ev.endDate)];
      this.calendar.setDate(ev);
      this.eve = ev
      this.dateString = ev.startDate.date()+"-"+this.doc.monthNameCalculator(ev.startDate.month())+"-"+ev.startDate.year()+" to "+ev.endDate.date()+"-"+this.doc.monthNameCalculator(ev.endDate.month())+"-"+ev.endDate.year()
      this.fetchData()
    }
  }

  downloadTableExcel(): void {
    let name = 'Stb Activation Deavtivation  Logs'
    this.doc.exportTableToExcelwithImage(this.reportTable, name, 'stb_activation_deactivation_logs', undefined, undefined, "Report Date : ",this.dateString);
  }

  downloadTablePdf(): void {
    if(!this.formatDate(this.from)){
      this.pdf.generatePdfHtml('#reportTable',
        'stb_activation_deactivation_logs',
        `From Start till now`
      );
      return;
    }
    this.pdf.generatePdfHtml('#reportTable',
      'stb_activation_deactivation_logs',
      `From Date: ${this.formatDate(this.from)}     To Date: ${this.formatDate(this.to)}`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Sl No","STB Id","Subscriber Code", "STB Mac address", "Description",  "STB Level", "Status","Command",,"Date"]
    };

    let data = []
    let i =1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo : i,
        stbId : line.stbId,
        subscriberCode : line.subscriberCode,
        ethernetMac : line.ethernetMac,
        description:line.description,
        stbLevel:line.stbLevel,
        STATUS:line.STATUS,
        commandStb:line.commandStb,
        date : line.createdDate
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'stb_activation_deactivation_logs', options);
  }




  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }

  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (
      this.paginationObj.pageNumber == this.paginationObj.totalPages
    ) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }

}
