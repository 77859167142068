<table class="custom__iptv__table" >
    <thead>
        <tr>
            <th>LCO Code</th>
            <th>LCO ID</th>
            <th>LCO Name</th>
            <th>Email</th>
            <th>Address</th>
            <th>Mobile Number</th>
            <th>Created Date</th>
        </tr>
    </thead>
    <ng-container *ngIf="isEmpty" >
      <tbody>
        <tr (click)="getUser('')" >
          <td colspan="7" >
            No Data
          </td>
        </tr>
      </tbody>
    </ng-container>
    <tbody *ngFor="let user of users" >
      
        <tr (click)="getUser(user)" >
            <td>{{user.lcoCode}}</td>
            <td>{{user.lcoId}}</td>
            <td>{{user.lcoName}}</td>
            <td>{{user.email}}</td>
            <td>{{user.address}}</td>
            <td>{{user.mobileNumber}}</td>
            <td>{{user.createdDate | date}}</td>
        </tr>
    </tbody>
</table>
