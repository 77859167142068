<div class="cms__content">
    <div class="top__heading">
      <h3>Regions</h3>
      <a [routerLink]="['/content/statewise-lcn/create']"><button class="default__button float-right" *ngIf="menuAccess">+ Add New</button></a>
    </div>
    <div class="col-12 list__table">
      <div class="row overflow">
        
        <div class="tableBox">
        <table class="custom__iptv__table">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Region id</th>
              <th>Region Name</th>
              <!-- <th>LCM</th> -->
              <th style="text-align: center;">
                State Details
                <table>
                    <tr>
                      <td width=33%>id</td>
                      <td width=33%>State</td>
                      <td width=33%>State Code</td>
                    </tr>
                </table>
                </th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="loadingFlag; else showList">
              <td class="loading" colspan="5">
                <app-custom-spinner></app-custom-spinner>
              </td>
            </tr>
            <ng-template #showList>
              <tr *ngFor="let region of regionList; let j = index">
                <td>{{j + 1}}</td>
                <td>{{ region.id ? region.id : "---" }}</td>
                <td>{{ region.regionName ? region.regionName : "---" }}</td>
                <!-- <td>{{region.LCM?region.LCM:'Not set'}}</td> -->
                <td>
                    <table  class="inner__table" >
                    <tr *ngFor="let state of region.states; let k= index">
                        <td width=33%>{{state.id}}</td>
                        <td width=33%>{{ state.stateName}}</td>
                        <td width=33%>{{ state.stateCode}}</td>
                    </tr>
                    </table>
                <td>
                  {{ region.createdDate | date : "dd-MM-yyyy HH:mm:ss" }}
                  <div class="action__items">
                    <button (click)="edit(region)" *ngIf="menuAccess">Edit</button>
                    <!-- <button (click)="navigateToLCM(region)" *ngIf="menuAccess">LCM</button> -->
                    <button (click)="viewLCNDetails(region)"*ngIf="menuAccess">LCN</button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
        </div>
        <!-- <div class="custom__pagination">
          <ul>
            <li>
              <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                <img src="./assets/firstPage.png" />
              </button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                <img src="./assets/previousPage.png" />
              </button>
            </li>
            <li>
              <span>Page {{ paginationObj.pageNumber }} of
                {{ paginationObj.totalPages }}</span>
            </li>
            <li>
              <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                <img src="./assets/nextPage.png" />
              </button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                <img src="./assets/lastPage.png" />
              </button>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>

  <!-- <div class="modal_box" *ngIf="viewLCMModal">
    <div class="modal_box_inner">
        <button style="float:right" (click)="onaddClickinLCM()">Add </button><br>
        <div *ngIf="LCMDetails== null ">No LCM's click on add to Add LCM's</div>
      <h3>LCM Details</h3>
      <table class="custom__iptv__table" *ngIf="LCMDetails!= null">
        <tbody>
          <tr>
            <th>Sl No</th>
            <th>LCM</th>
          </tr>
          <ng-container *ngFor="let item of LCMDetails;let j= index">
            <tr>
              <td>{{j+1}}</td>
              <td>{{item.lcm}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <button class="cta float-right" (click)="viewLCMModal = !viewLCMModal"> Close </button>
    </div>
  </div> -->

  <div class="modal_box" *ngIf="viewLCNModal">
    <div class="modal_box_inner">
        <button style="float:right" (click)="onaddClickinLCN()">Add </button><br>

      <h3>LCN Details</h3>
      <div class="parent">
        <p class="div1">Region ID</p>
        <p class="div2">{{regionId}}</p>
      </div>
      <table class="custom__iptv__table">
        <tbody>
          <tr>
            <th>Sl No</th>
            <th>Channel Name</th>
            <th>LCN</th>
          </tr>
          <ng-container *ngFor="let item of LCNDetails;let j= index">
            <tr>
              <td>{{j+1}}</td>
              <td>{{item.channelName}}</td>
              <td>{{item.lcn}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <button class="cta float-right" (click)="viewLCNModal = !viewLCNModal"> Close </button>
    </div>
  </div>


  <div class="modal_box" *ngIf="showAddLCNModal">
    <div class="modal_box_inner">
      <h3> Create LCN Details</h3>
      <div class="parent">
        <p class="div1">Region ID</p>
        <p class="div2">{{regionId}}</p>
        <p class="div3">LCM</p>
        <p class="div4"><input type="text" [(ngModel)]="lcm"></p>
      </div>
      
      <button class="cta float-right" (click)="persist()"> Save </button>
    </div>
  </div>