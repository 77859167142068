import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../config.service';
import { HttpClientWrapperService } from '../http-client-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ChannelDataService {

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  getChannelsByLCO(id: number): Observable<ChannelInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'channel/getChannelsByLCO/' + id;
    return this.http.get(url);
  }
}
