<div class="cms__content">
  <div class="top__heading">
    <h3>Set Top Box</h3>
    <a [routerLink]="['/manage/stb/create']">
      <button  *ngIf="isDistributor== false && menuAccess == true" class="default__button float-right">+ Add New</button></a>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 list__summary">
    <div class="row">
      <div class="col-md-2 list-sum-card">
        <div class="list__card">
          <span class="list__heading">STBs</span>
          <span class="list__item">{{cardDetails.total}}</span>
        </div>
      </div>
      <!-- <div class="col-md-2">
        <div class="list__card">
          <span class="list__heading">Fresh</span>
          <span class="list__item">{{cardDetails.fresh}}</span>
        </div>
      </div> -->
      <div class="col-md-2 list-sum-card">
        <div class="list__card">
          <span class="list__heading">Assigned</span>
          <span class="list__item">{{cardDetails.assigned}}</span>
        </div>
      </div>
      <!-- <div class="col-md-2">
        <div class="list__card">
          <span class="list__heading">Unassigned</span>
          <span class="list__item">{{cardDetails.unassigned}}</span>
        </div>
      </div> -->
      <div class="col-md-2 list-sum-card">
        <div class="list__card">
          <span class="list__heading">Activated</span>
          <span class="list__item">{{cardDetails.ACTIVE}}</span>
        </div>
      </div>
      <div class="col-md-2 list-sum-card">
        <div class="list__card">
          <span class="list__heading">Blacklisted</span>
          <span class="list__item">{{cardDetails.blackList}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>Show<select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
              (keyup.enter)="$event.preventDefault(); onSearch()" />
            <button (click)="onSearch()"><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>

      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th>
              Sl No.
            </th>
            <th>Partner Code</th>
            <th>STB ID</th>
            <th (click)="sortByItem(sortByProperties.serial)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.serial}">
              Serial Number
            </th>
            <th (click)="sortByItem(sortByProperties.emac)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.emac}">
              Ethernet MAC Address
            </th>
            <th (click)="sortByItem(sortByProperties.wmac)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.wmac}">
              Wifi MAC Address
            </th>
            <th (click)="sortByItem(sortByProperties.manuf)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.manuf}">
              Manufacturer
            </th>
            <th class="label__align" (click)="sortByItem(sortByProperties.assigned)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.assigned}">
              Assigned Status
            </th>
            <th class="label__align" (click)="sortByItem(sortByProperties.activated)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.activated}">
              Activated
            </th>
            <th class="label__align" (click)="sortByItem(sortByProperties.blacklisted)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.blacklisted}">
              Blacklisted
            </th>
            <th (click)="sortByItem(sortByProperties.date)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.date}">
              Created On
            </th>
          </tr>
        </thead>
        <tbody>

          <tr *ngIf="loadingFlag; else showList">
            <td class="loading" colspan="10">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>

          <ng-template #showList>
            <tr *ngFor="let stb of stbList, let j=index">
              <td>{{((paginationObj.pageNumber-1)*paginationObj.pageSize)+(j+1)}}</td>
              <td>{{stb.lcoCode? stb.lcoCode : '---'}}</td>
              <td>{{stb.stbId? stb.stbId : '---'}}</td>
              <td>{{stb.serialNo? stb.serialNo : '---'}}</td>
              <td>{{stb.ethernetMac? stb.ethernetMac : '---'}}</td>
              <td>{{stb.wifiMac? stb.wifiMac : '---'}}</td>
              <td>{{stb.manufacturer? stb.manufacturer : '---'}}</td>
              <td class="label__align">
                <span [ngClass]="{'safe__label' : stb.assignedFlag == 1, 'danger__label' : stb.assignedFlag != 1}">
                  {{stb.assignedFlag == 1?'&#10003;':'X'}}
                </span>
              </td>
              <td class="label__align">
                <span [ngClass]="{'safe__label' : stb.activationStatus == 1, 'danger__label' : stb.activationStatus != 1}">
                  {{stb.activationStatus == 1?'&#10003;':'X'}}
                </span>
              </td>
              <td class="label__align">
                <span [ngClass]="{'safe__label' : !stb.blacklistStatus, 'danger__label' : stb.blacklistStatus}">
                  {{!stb.blacklistStatus != 1?'X':'&#10003;'}}
                </span>
              </td>
              <td>
                {{stb.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                <div class="action__items">
                  <button  *ngIf="user== true && menuAccess ==true"  (click)="edit(stb)">Edit</button>
                  <ng-container *ngIf="stb.blacklistStatus !== 1" >
                    <button (click)="updateStatus(stb)" *ngIf="stb.activationStatus && user== true && menuAccess ==true" >
                      {{stb.activationStatus != 1?"Activate":"Deactivate"}}
                    </button>
                  </ng-container>
                  <!-- <button class="danger" (click)="updateBlacklist(stb)">
                    {{stb.blacklistStatus?"Unblacklist":"Blacklist"}}
                  </button> -->
                  <!-- {{stb.blacklistStatus ? : <button class="danger"  (click)="updateBlacklist(stb)">Blacklist</button>}} -->
                  <button class="danger" *ngIf="stb.blacklistStatus == 0 && user== true && menuAccess ==true" (click)="updateBlacklist(stb)">Blacklist</button>
                </div>
              </td>

            </tr>
          </ng-template>
        </tbody>
      </table>

      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
