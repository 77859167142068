import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SubjectService } from 'src/app/services/subject.service';
import colorPalette from '../../theme-manager/color-selector/colors'
@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit, OnDestroy {

  constructor(
    private subject: SubjectService,
    private router: Router,
    private auth: AuthService
  ) { }

  accessSubcription: Subscription;

  isLoggedin = false;
  date = new Date()
  ngOnInit(): void {
    this.checkAccess();
    this.accessSubcription = this.subject.getAccessSubject().subscribe(access => {
      this.isLoggedin = access;
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && event.url.includes('/login')) {
        this.isLoggedin = false;
      } else if (event instanceof NavigationStart) {
        this.checkAccess();
      }
    });
    this.pageReloaded()
  }
  pageReloaded() {
    let chosenPalette = localStorage.getItem('chosenPalette');
    if(chosenPalette == "reset"){
      document.documentElement.style.setProperty("--darkBack","#676874")
    document.documentElement.style.setProperty("--highlight-color","#2980b9")
    document.documentElement.style.setProperty("--lightBack","#b1b3c1")
    document.documentElement.style.setProperty("--nav-white","#fff")
    document.documentElement.style.setProperty("--nav-font","#665f82")
    document.documentElement.style.setProperty("--nav-button","#2980b9")  
    return;
    }
    for(let colorset of colorPalette){
      let color = colorset[chosenPalette]
      if(color){
        document.documentElement.style.setProperty("--darkBack",color.primary)
    document.documentElement.style.setProperty("--highlight-color",color.primary)
    document.documentElement.style.setProperty("--lightBack",color.primary)
    document.documentElement.style.setProperty("--nav-white",color.primary)
    document.documentElement.style.setProperty("--nav-font","#fff")
    document.documentElement.style.setProperty("--nav-button","#fff")

      }
    }
    
  }

  checkAccess(): void {
    if (this.auth.isLoggedIn()) {
      this.isLoggedin = true;
    }
  }

  ngOnDestroy(): void {
    this.accessSubcription.unsubscribe();
  }

}
