import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'multi-select',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiselectComponent implements OnInit {
  @Input() multiSelectOptions = new BehaviorSubject<MultiSelectType>(
    null as MultiSelectType
  );
  @Input() controlName!: string;
  @Input() parentFormName!: FormGroup;
  @Output() selectedObjects: EventEmitter<any[]> = new EventEmitter<any[]>();

  options!: MultiSelectType;
  ngOnInit(): void {
    if (this.multiSelectOptions != undefined) {
      this.initialize();
      let expand = document.getElementsByClassName(
        'expanded'
      )[0] as HTMLDivElement;
      expand.style.display = 'none';
    }
  }

  get inputControl() {
    return this.parentFormName.get(this.controlName) as FormControl;
  }

  initialize() {
    this.multiSelectOptions.subscribe({
      next: (res) => {
        for (let ele of Object.keys(res)) {
          if (res[ele]) {
            this.options = {
              ...this.options,
              [ele]: res[ele],
            };
            if (ele == 'dataArray') {
              this.dataArrayCopy = JSON.parse(JSON.stringify(res[ele]));
            }
            if (ele == 'isSelectAll') {
              this.isSelectAll = res[ele];
            }
          }
        }
        if (this.options.selectedArray) {
          this.selectedData = this.options.selectedArray;
          this.sortForUpdate();
        }
      },
    });
  }

  searchThroughDataArray(e: Event) {
    let value = (e.target as HTMLInputElement).value;
    const completeArray = JSON.parse(JSON.stringify(this.options.dataArray));
    const index = completeArray.findIndex(
      (obj) =>
        (obj[this.options.keyToDisplay] as string).toUpperCase() ===
        value.toUpperCase()
    );
    if (index !== -1) {
      const objectToMove = completeArray.splice(index, 1)[0];
      completeArray.unshift(objectToMove);
    }
  }

  dataArrayCopy;
  partialSearch(e: Event) {
    let value = (e.target as HTMLInputElement).value.toLowerCase();
    this.options.dataArray = JSON.parse(JSON.stringify(this.dataArrayCopy));
    if (value != '') {
      let completeArray = this.options.dataArray;
      completeArray = completeArray.filter((object) => {
        const displayValue = (
          object[this.options.keyToDisplay] as string
        ).toLowerCase();
        return displayValue.includes(value);
      });
      this.options = {
        ...this.options,
        dataArray: completeArray,
      };
    } else {
      this.options = {
        ...this.options,
        dataArray: this.dataArrayCopy,
      };
    }

    for (let item of this.options.dataArray) {
      for (let ele of this.selectedData) {
        if (
          item[this.options.idForSelected] == ele[this.options.idForSelected]
        ) {
          item.checked = true;
        }
      }
    }
  }

  clearSelected() {
    this.selectedData = [];
    this.isSelectAll = false;
  }

  sortForUpdate() {
    const partialMap = new Map(
      this.options.selectedArray.map((obj) => [
        obj[this.options.idForSelected],
        obj,
      ])
    );
    const completeArray = this.options.dataArray;
    completeArray.forEach(
      (obj) =>
        (obj['checked'] = partialMap.has(obj[this.options.idForSelected]))
    );
    completeArray.sort((a, b) => {
      const aHasId = partialMap.has(a[this.options.idForSelected]) ? 1 : 0;
      const bHasId = partialMap.has(b[this.options.idForSelected]) ? 1 : 0;
      return bHasId - aHasId;
    });
    this.options = {
      ...this.options,
      dataArray: completeArray,
    };
  }

  selectedData = [];
  toggleSelection(data: any, index: number) {
    let input = document.getElementById(`item${index}`) as HTMLInputElement;
    if (input.checked) {
      this.selectedData.push(data);
    } else {
      this.selectedData = this.selectedData.filter(
        (obj) =>
          obj[this.options.idForSelected] !== data[this.options.idForSelected]
      );
    }
  }

  isSelectAll = false;
  selectAll() {
    this.isSelectAll = !this.isSelectAll;
    this.selectedData = [];
    if (this.isSelectAll) {
      this.selectedData = JSON.parse(JSON.stringify(this.options.dataArray));
    }
    if (this.isSelectAll == false) {
      for (let item of this.options.dataArray) {
        item.checked = false;
      }
    }
  }

  continue() {
    let expand = document.getElementsByClassName(
      'expanded'
    )[0] as HTMLDivElement;
    expand.style.display = 'none';
    console.log({ options: this.options, selectedData: this.selectedData });
    this.parentFormName.get(this.controlName).setValue(this.selectedData);
    this.selectedObjects.emit(this.selectedData);
    let div = document.querySelector('.drop-icon') as HTMLDivElement;
    div.style.transform =
      div.style.transform == 'rotate(180deg)'
        ? 'rotate(0deg)'
        : 'rotate(180deg)';
  }

  isOpen = false;
  openSelect() {
    let expand = document.getElementsByClassName(
      'expanded'
    )[0] as HTMLDivElement;
    expand.style.display = expand.style.display == 'block' ? 'none' : 'block';
    let div = document.querySelector('.drop-icon') as HTMLDivElement;
    div.style.transform =
      div.style.transform == 'rotate(180deg)'
        ? 'rotate(0deg)'
        : 'rotate(180deg)';
    this.isOpen = true;
  }
}

export type MultiSelectType = {
  dataArray: any[];
  keyToDisplay: string;
  idForSelected: string;
  selectedArray?: any[];
  idForDisabled?: string;
  valueForDisabled?: string;
  isSelectAll?: boolean;
};
