<div class="cms__content">
    <div class="top__heading">
        <h3>{{isEdit?'Edit':'Create'}} Access Control List </h3>
        <!-- <h3> Access Control List</h3> -->
        <a [routerLink]="['/settings/access-control']"> <button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>
    <form action="" [formGroup]="groupForm" (ngSubmit)="persist()">
    <div class="col-12 form__style">
        <div class="row">
            <div class="col-12 col-md-4">

                <div class="col-12">
                    <label>ACL Group Name</label>
                    <input type="text" autocomplete="off" class="custom__field" formControlName="name"
                    [ngClass]="{'error__field': !name.valid && name.touched}" />
                    <div [hidden]="!name.touched">
                        <span class="error__msg" *ngIf="name.hasError('required')">Required</span>
                    </div>
                </div>

                <div class="col-12">
                    <label>Short Description</label>
                    <textarea class="custom__field" formControlName="description"></textarea>
                </div>

                <div class="col-12">
                    <a [routerLink]="['/settings/access-control']"><button type="button" class="default__button">Cancel</button></a>
                    <button class="cta float-right" [disabled]="!groupForm.valid">Save</button>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <div class="col-12 card">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <ul class="main__menu">
                                <li>
                                    <label class="switch">
                                        <input type="checkbox" [checked]="menuAccordion[1]"
                                        (change)="menuAccordion[1] = !menuAccordion[1];
                                        updateAccordion(1)">
                                        <span class=" slider round"></span>
                                    </label>
                                    <span class="highlight">Dashboard</span>
                                    <ul class="sub__menu">
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[1]"
                                                (change)="menuAccordion[1] = !menuAccordion[1];
                                                updateFlags(1)"> 
                                                <span class=" slider round"></span>
                                            </label>
                                            Dashboard
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="main__menu">
                                <li>
                                    <label class="switch">
                                        <input type="checkbox" [checked]="menuAccordion[2]"
                                        (change)="menuAccordion[2] = !menuAccordion[2];
                                        updateAccordion(2)">
                                        <span class=" slider round"></span>
                                    </label>
                                    <span class="highlight">Manage</span>
                                    <ul class="sub__menu">
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[3]"
                                                (change)="menuAccordion[3] = !menuAccordion[3];
                                                updateFlags(2)"> 
                                                <span class=" slider round"></span>
                                            </label>
                                            MSO
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[4]"
                                                (change)="menuAccordion[4] = !menuAccordion[4];
                                                updateFlags(2)">
                                                <span class=" slider round"></span>
                                            </label>
                                            LCO
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[5]"
                                                (change)="menuAccordion[5] = !menuAccordion[5];
                                                updateFlags(2)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Subscriber
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[6]"
                                                (change)="menuAccordion[6] = !menuAccordion[6];
                                                updateFlags(2)">
                                                <span class=" slider round"></span>
                                            </label>
                                            STB
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[7]"
                                                (change)="menuAccordion[7] = !menuAccordion[7];
                                                updateFlags(2)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Subscriber Group
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[8]"
                                                (change)="menuAccordion[8] = !menuAccordion[8];
                                                updateFlags(2)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Location
                                        </li>
                                    </ul>
                                </li>
                                <li>

                                    <label class="switch">
                                        <input type="checkbox" [checked]="menuAccordion[9]"
                                        (change)="menuAccordion[9] = !menuAccordion[9];
                                        updateAccordion(9)">
                                        <span class=" slider round"></span>
                                    </label>
                                    <span class="highlight">content</span>
                                    <ul class="sub__menu">
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[10]"
                                                (change)="menuAccordion[10] = !menuAccordion[10];
                                                updateFlags(9)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Broadcaster
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[11]"
                                                (change)="menuAccordion[11] = !menuAccordion[11]; 
                                                updateFlags(9)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Channel
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[12]"
                                                (change)="menuAccordion[12] = !menuAccordion[12];
                                                updateFlags(9)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Category
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[13]"
                                                (change)="menuAccordion[13] = !menuAccordion[13];
                                                updateFlags(9)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Bouquet
                                        </li>
                                    </ul>
                                </li>
                                <li>

                                    <label class="switch">
                                        <input type="checkbox" [checked]="menuAccordion[14]"
                                        (change)="menuAccordion[14] = !menuAccordion[14];
                                        updateAccordion(14)">
                                        <span class=" slider round"></span>
                                    </label>
                                    <span class="highlight">Communication</span>
                                    <ul class="sub__menu">
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[15]"
                                                (change)="menuAccordion[15] = !menuAccordion[15];
                                                updateFlags(14)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Mail
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[16]"
                                                (change)="menuAccordion[16] = !menuAccordion[16];
                                                updateFlags(14)">
                                                <span class=" slider round"></span>
                                            </label>
                                            OSD
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[17]"
                                                (change)="menuAccordion[17] = !menuAccordion[17];
                                                updateFlags(14)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Fingerprint
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-4">
                            <ul class="main__menu">
                                <li>

                                    <label class="switch">
                                        <input type="checkbox" [checked]="menuAccordion[18]"
                                        (change)="menuAccordion[18] = !menuAccordion[18];
                                        updateAccordion(18)">
                                        <span class=" slider round"></span>
                                    </label>
                                    <span class="highlight">Plugins</span>
                                    <ul class="sub__menu">
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[19]"
                                                (change)="menuAccordion[19] = !menuAccordion[19];
                                                updateFlags(18)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Transcoder
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[20]"
                                                (change)="menuAccordion[20] = !menuAccordion[20];
                                                updateFlags(18)">
                                                <span class=" slider round"></span>
                                            </label>
                                            EPG
                                        </li>

                                    </ul>
                                </li>
                                <li>

                                    <label class="switch">
                                        <input type="checkbox" [checked]="menuAccordion[21]"
                                        (change)="menuAccordion[21] = !menuAccordion[21];
                                        updateAccordion(21)">
                                        <span class=" slider round"></span>
                                    </label>
                                    <span class="highlight">Reports</span>
                                    <ul class="sub__menu">
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[22]"
                                                (change)="menuAccordion[22] = !menuAccordion[22];
                                                updateFlags(21)">
                                                <span class=" slider round"></span>
                                            </label>
                                            TRAI Reports
                                        </li>

                                    </ul>
                                </li>
                                <li>

                                    <label class="switch">
                                        <input type="checkbox" [checked]="menuAccordion[34]"
                                        (change)="menuAccordion[34] = !menuAccordion[34];
                                        updateAccordion(34)">
                                        <span class=" slider round"></span>
                                    </label>
                                    <span class="highlight">Settings</span>
                                    <ul class="sub__menu">
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[35]" 
                                                (change)="menuAccordion[35] = !menuAccordion[35];
                                                updateFlags(34)">
                                                <span class=" slider round"></span>
                                            </label>
                                            General Settings
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[36]" 
                                                (change)="menuAccordion[36] = !menuAccordion[36];
                                                updateFlags(34)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Access Control
                                        </li>
                                    </ul>
                                </li>
                                <li>

                                    <label class="switch">
                                        <input type="checkbox" [checked]="menuAccordion[37]"
                                        (change)="menuAccordion[37] = !menuAccordion[37];
                                        updateAccordion(37)">
                                        <span class=" slider round"></span>
                                    </label>
                                    <span class="highlight">Accounts</span>
                                    <ul class="sub__menu">
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[38]"
                                                (change)="menuAccordion[38] = !menuAccordion[38];
                                                updateFlags(37)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Subscriber Revenue
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[39]"
                                                (change)="menuAccordion[39] = !menuAccordion[39];
                                                updateFlags(37)">
                                                <span class=" slider round"></span>
                                            </label>
                                            MSO Payments
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[40]"
                                                (change)="menuAccordion[40] = !menuAccordion[40];
                                                updateFlags(37)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Invoice
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-4">
                            <ul class="main__menu">
                                <li>

                                    <label class="switch">
                                        <input type="checkbox" [checked]="menuAccordion[23]"
                                        (change)="menuAccordion[23] = !menuAccordion[23];
                                        updateAccordion(23)">
                                        <span class=" slider round"></span>
                                    </label>
                                    <span class="highlight">Logs</span>
                                    <ul class="sub__menu">
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[24]"
                                                (change)="menuAccordion[24] = !menuAccordion[24];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Authentication
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[25]"
                                                (change)="menuAccordion[25] = !menuAccordion[25];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            User Activity
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[26]"
                                                (change)="menuAccordion[26] = !menuAccordion[26];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Bouquet Status
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[27]"
                                                (change)="menuAccordion[27] = !menuAccordion[27];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Channel Status
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[28]"
                                                (change)="menuAccordion[28] = !menuAccordion[28];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            STB Connection
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[29]"
                                                (change)="menuAccordion[29] = !menuAccordion[29];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Complete Logs
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[30]"
                                                (change)="menuAccordion[30] = !menuAccordion[30];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            STB Activation / Deactivation
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[31]"
                                                (change)="menuAccordion[31] = !menuAccordion[31];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Subscription Status
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[32]"
                                                (change)="menuAccordion[32] = !menuAccordion[32];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Bouquet Modification
                                        </li>
                                        <li>
                                            <label class="switch">
                                                <input type="checkbox" [checked]="menuAccordion[33]"
                                                (change)="menuAccordion[33] = !menuAccordion[33];
                                                updateFlags(23)">
                                                <span class=" slider round"></span>
                                            </label>
                                            Channel Modification
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

</div>