import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { HttpClientWrapperService } from '../services/http-client-wrapper.service';

@Component({
  selector: 'app-leadgeneration-payment-failure',
  templateUrl: './leadgeneration-payment-failure.component.html',
  styleUrls: ['./leadgeneration-payment-failure.component.scss']
})
export class LeadgenerationPaymentFailureComponent implements OnInit {

 
  constructor(
    private config: ConfigService,
    private http: HttpClientWrapperService,
  ) { }

  ngOnInit(): void {
    this.sendTransactionIdAftersuccess()
    this.config.json.paymentStatus ="Failure";
    localStorage.setItem("paymentStatus",'Failure');
  }

  pageClose = true
  timerSec = 10
  sendTransactionIdAftersuccess() {
    // const url = `${this.config.json.apiEndpoint}wallet/paymentOnSuccessOrFailure`
    // this.http.post(url, { txnid: localStorage.getItem('transactionId') }).subscribe({
    //   next: () => {
    //     localStorage.removeItem("transactionId");
        let interval;
        interval = setInterval(() => {
          this.timerSec -= 1
          if (this.timerSec == 0) {
            clearInterval(interval)
          }
        }, 1000)
        setTimeout(() => {
          window.close()
        }, 1000);
    //   }
    // })
  }

}
