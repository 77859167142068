import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { environment } from 'src/environments/environment';
import { ReportTraiDataService } from 'src/app/report-trai/plugins/report-trai-data.service';


@Component({
  selector: 'app-logs-completecategories',
  templateUrl: './logs-completecategories.component.html',
  styleUrls: ['./logs-completecategories.component.scss', '../../report-trai/report-filters.scss']
})
export class LogsCompletecategoriesComponent implements OnInit {
  paginationObj = new PaginationObj();

  loadingFlag = false;


  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;
  reportList = [];
  selectedLco = 0;
  from: any = '0000-00-00';
  to: any = '0000-00-00';

  lcoList: LCOInfo[] = [];



  sortByProperties = {
    srNo: 'srNo',
    category: 'category',
    username: 'username',
    description: 'description',
    ACTION: 'ACTION',
    date: 'date',
    triggerDate: 'triggerDate'
  };

  currentDate = new Date();
  statusValue = "All"


  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchLcoList();
    this.fetchData()
  }

  fetchLcoList(): void {
    this.report.getLCOList().subscribe((data) => {
      this.lcoList = data.data;
    });
  }

  isLoad = 'No Records';

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  sortByItem(item: string): void {
    console.log(item)
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchData();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchData();
  }
  handleFetchPage(data): void {
    this.reportList = []
    this.reportList = data.data;
    if (this.reportList.length != 0) {
      this.loadingFlag = false;
    } else {
      this.loadingFlag = true;
      this.isLoad = 'No Records';
    }
    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
  }

  pageNumber
  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.pageNumber = metadata.pageNumber
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  fetchData(): void {
    this.loadingFlag = true;
    this.reportList = []
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    let url = PaginationUtilities.urlBuilder('logs/completeHistory', this.paginationObj);
    url = url + "&category=" + this.statusValue
    if (this.from != "0000-00-00" && this.to != "0000-00-00") {
      url = url + "&fromDate=" + this.from + "&toDate=" + this.to;
    }
    this.http.get(url).subscribe(
      (res) => {
        this.handleFetchPage(res);
        this.loadingFlag = false;
        if (res.data.length == 0) {

          this.isLoad = 'No records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  lcoSelect(): void {
    this.from = this.calendar.getDate()['startDate']
    this.to = this.calendar.getDate()['endDate']
    this.fetchData();
  }

  dateString;
  eve;
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      // // console.log(ev.startDate.date(), ev.startDate.month(), ev.startDate.year())
      // // console.log(ev.endDate.date(), ev.endDate.month(), ev.endDate.year())
      this.eve = ev;
      this.dateString =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.from = ev.startDate.format('YYYY-MM-DD');
      this.to = ev.endDate.format('YYYY-MM-DD');
      console.log(this.from)
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
      this.calendar.setDate(ev);
    }
  }

  // downloadTableExcel(): void {
  //   //// console.log("hello")
  //   let headers = [];
  //   let name = 'Complete Logs Report';
  //   this.doc.exportTableToExcelwithImage(
  //     this.reportTable,
  //     name,
  //     'complete_logs_report',
  //     undefined,
  //     undefined,
  //     'Report Date : ',
  //     this.dateString
  //   );
  // }


  downloadTableExcel(){
    let url
    if(this.config.json.userType=='MSO'){

      url= this.config.json.apiEndpoint+'generate/completeHistory?searchKeyword=&category='+this.statusValue
      if (this.from != "0000-00-00" && this.to != "0000-00-00") {
        url = url + "&fromDate=" + this.from + "&toDate=" + this.to;
      }
    }
      else{
        url= this.config.json.apiEndpoint+'generate/getCompleteHistoryForLcoOrDistributor?searchKeyword=&fromDate='
        +this.from+'&toDate='+this.to+'category='+this.statusValue+'&userType='+this.config.json.userType+'&userId='
        
      }

      this.http.getwithresponseType(url).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'complete_logs.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }

  downloadTablePdf(): void {
    if (
      this.reportList == null ||
      this.reportList == undefined ||
      this.reportList?.length == 0
    ) {
      alert('No Data!');
      return;
    }
    if (!this.formatDate(this.from)) {
      this.pdf.generatePdfHtml(
        '#reportTable',
        'complete_logs',
        `Till now    Source Type : SMS`
      );
      return;
    }
    this.pdf.generatePdfHtml(
      '#reportTable',
      'complete_logs',
      `From Date: ${this.formatDate(this.from)}     To Date: ${this.formatDate(
        this.to
      )},   Source Type : SMS`
    );
  }

  formatDate(ele) {
    if (ele == undefined) {
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  downloadTableCSV(): void {
    try {
      if (
        this.reportList == null ||
        this.reportList == undefined ||
        this.reportList?.length == 0
      ) {
        alert('No Data!');
        return;
      }
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
        headers: [
          'Sl No',
          'category',
          'Description',
          'Date',
        ],
      };
      let data = [];
      let i = 1;
      this.reportList.forEach((line) => {
        let csvLine = {
          slNo: i,
          category: line.category,
          DESCRIPTION: line.DESCRIPTION,
          transactionDate: line.transactionDate,
        };
        data.push(csvLine);
        i += 1;
      });
      console.log(data)
      new ngxCsv(data, 'complete_logs', options);
    }
    catch (e) {
      console.log(e)
    }
  }
}

