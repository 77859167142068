<div class="cms__content">
  <div class="top__heading">
    <h3>{{isEdit?'Edit':'Create'}} Location</h3>
    <a [routerLink]="['/manage/location/list']"><button type="button" class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>
  <form action="" [formGroup]="locationForm" (ngSubmit)="persist()">
    <div class="col-12 form__style">
      <div class="row">
        <div class="col-12 col-md-6">
          <label>State</label>
          <select class="custom__field" formControlName="state">
            <option *ngFor="let st of stateList" [value]="st.name">{{st.name}}</option>
          </select>
        </div>

        <div class="col-12 col-md-6">
          <label>City</label>
          <select class="custom__field" formControlName="city">
            <option *ngFor="let ct of cityList" [value]="ct.name">{{ct.name}}</option>
          </select>
        </div>

        <div class="col-12 col-md-6">
          <label>Locality</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="locality">
        </div>

        <div class="col-12 col-md-6">
          <label>PIN Code</label>
          <input type="number" class="custom__field" formControlName="pin"
            [ngClass]="{'error__field': !pin.valid && pin.touched}">
          <div [hidden]="!pin.touched">
            <span class="error__msg" *ngIf="pin.hasError('required')">Please enter PIN Code</span>
            <span class="error__msg" *ngIf="pin.hasError('pattern')">PIN Code is invalid</span>
          </div>
        </div>

        <div class="col-12">
          <!-- <button type="button" [routerLink]="['/channel/list']" class="default__button">Cancel</button> -->
          <button class="cta float-right" [disabled]="!locationForm.valid">Save</button>
        </div>
      </div>
    </div>

  </form>
</div>