import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  template: '',
  selector: 'msoLoginAsDistributor',
  styles: [''],
})
export class MSOLoginAsDistributorComponent implements OnInit {
  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) {}
  ngOnInit() {
    let data= this.router.url.split('/')[2]
    console.log(this.router.url.split('/')[2]);
    setTimeout(() => {
      this.loginToDistributor(data);
    }, 1000);
  }
  loginToDistributor(data) {
    console.log(data);
    let body = JSON.parse(atob(data))
    let header = new HttpHeaders().set(
      'api_key',
      '2cb7e3df-017f-438c-a434-ca2962e363eb'
    );
    let url = this.config.json.apiEndpointAuth+"msoLoginAsDistributor"
    this.http.post(url, body, { headers: header }).subscribe({
      next : (data: any) =>{
        if(data.status === "FAIL"){
          this.router.navigate(['/login']);
          alert("Failed to login")
          return
        }
        this.auth.afterLogin(data);
      }
    })
  }
}
