<div class="cms__content">
    <div class="top__heading">
      <h3>LCO Bouquet Setup</h3>
      <a [routerLink]="['/manage/lco']"><button type="button" class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>
    <form action="" [formGroup]="lcoSetBouquetForm" (ngSubmit)="persist()">
      <div class="col-12 form__style">
        <div class="row">
          <div class="col-12 col-md-6">
            <label>lcoId</label>
             <input type="text" autocomplete="off" class="custom__field" formControlName="lcoIdValue" disabled>
          </div>
  
          <div class="col-12 col-md-6">
            <label>Initial bouquet</label>
            <select class="custom__field" formControlName="initialBouquet">
              <option>Select</option>
              <option *ngFor="let bouquet of bouquetList" value="{{bouquet.bouquetId}}">{{bouquet.bouquetName}}</option>
            </select>
          </div>

          <div class="col-12 col-md-6">
            <label>Default bouquet</label>
            <select class="custom__field" formControlName="defaultBouquet">
            <option>Select</option>
              <option *ngFor="let bouquet of bouquetList" value="{{bouquet.bouquetId}}">{{bouquet.bouquetName}}</option>
            </select>
          </div>

          <div class="col-12 col-md-6">
            <label>No of Days</label>
            <input type="text" autocomplete="off" class="custom__field" formControlName="noOfDays">
          </div>
  
          <div class="col-12">
            <button class="cta float-right" [disabled]="!lcoSetBouquetForm.valid">Save</button>
          </div>
        </div>
      </div>
  
    </form>
  </div>