import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { PathNames } from 'src/app/constants/path-names';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-broadcaster',
  templateUrl: './create-broadcaster.component.html',
  styleUrls: ['./create-broadcaster.component.scss'],
})
export class CreateBroadcasterComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) {}

  broadcaster = {} as BroadcasterInfo;

  broadcasterForm: FormGroup;

  isEdit = false;

  channelList: ChannelInfo[] = [];

  showChannels = true;

  ngOnInit(): void {
    this.getPathParam();
    this.initForm();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.fetchData(id);
    }
  }

  fetchRecord(id: string): Observable<BroadcasterInfo> {
    // const url = environment.apiEndpoint + 'broadcaster/getBroadcasterInfo/' + id;
    const url =
      this.config.json.apiEndpointRdbms + 'broadcaster/getBroadcaster/' + id;
    return this.http.get(url);
  }

  fetchChannelList(id: string): Observable<ChannelInfo[]> {
    const url =
      this.config.json.apiEndpointRdbms +
      'channel/getChannelsByBroadcaster?id=' +
      id +
      '&status=1';

    return this.http.get(url);
  }

  fetchChannelListInactive(id: string): Observable<ChannelInfo[]> {
    const url =
      this.config.json.apiEndpointRdbms +
      'channel/getChannelsByBroadcaster?id=' +
      id +
      '&status=0';

    return this.http.get(url);
  }

  fetchData(id: string): void {
    forkJoin([
      this.fetchRecord(id),
      this.fetchChannelList(id),
      this.fetchChannelListInactive(id),
    ]).subscribe((data) => {
      this.broadcaster = data[0];
      this.updateForm();
      this.channelList = data[1];
      this.channelList.concat(data[2]);
    });
  }

  initForm(): void {
    this.broadcasterForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      landline: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      contactPerson: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      pin: new FormControl(0, [Validators.required]),
    });
  }

  get name(): FormControl {
    return this.broadcasterForm.get('name') as FormControl;
  }

  get mobile(): FormControl {
    return this.broadcasterForm.get('mobile') as FormControl;
  }

  get landline(): FormControl {
    return this.broadcasterForm.get('landline') as FormControl;
  }

  get email(): FormControl {
    return this.broadcasterForm.get('email') as FormControl;
  }

  updateForm(): void {
    this.broadcasterForm.get('name').setValue(this.broadcaster.name);
    this.broadcasterForm
      .get('description')
      .setValue(this.broadcaster.description);
    this.broadcasterForm.get('email').setValue(this.broadcaster.email);
    this.broadcasterForm.get('landline').setValue(this.broadcaster.landline);
    this.broadcasterForm.get('mobile').setValue(this.broadcaster.mobile);
    this.broadcasterForm
      .get('contactPerson')
      .setValue(this.broadcaster.contactPerson);
    this.broadcasterForm.get('address').setValue(this.broadcaster.address);
    this.broadcasterForm.get('city').setValue(this.broadcaster.city);
    this.broadcasterForm.get('state').setValue(this.broadcaster.state);
    this.broadcasterForm.get('pin').setValue(this.broadcaster.pincode);
  }

  persist(): void {
    this.updateModel();
    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    // const url = environment.apiEndpoint + 'broadcaster/createBroadcaster';
    const url =
      this.config.json.apiEndpointRdbms + 'broadcaster/createBroadcaster';

    this.http.post(url, this.broadcaster).subscribe(
      (data) => {
        if (data.statusCode == 200) {
          this.router.navigate(['/content/broadcaster', 'list']);
        } else {
          alert(data.message);
        }
      },
      (error) => {
        window.alert(error.error['message']);
      }
    );
  }

  isMobileInvalid = false;
  checkMobileNumberPattern(e: Event) {
    let value = (e.target as HTMLInputElement).value;
    let phoneRegex: RegExp = /^[6-9]\d{9}$/;
    if (phoneRegex.test(value)) {
      this.isMobileInvalid = false;
      this.broadcasterForm.get('mobile').setErrors(null);
    } else {
      this.broadcasterForm.get('mobile').setErrors({ pattern: true });
      this.isMobileInvalid = true;
    }
  }

  update(): void {
    // const url = environment.apiEndpoint + 'broadcaster/updateBroadcaster';
    const url =
      this.config.json.apiEndpointRdbms + 'broadcaster/updateBroadcaster';

    this.http.put(url, this.broadcaster).subscribe(
      (data) => {
        if(data.statusCode==200){
        this.router.navigate(['/content/broadcaster', 'list']);
        }
        else
        {
        window.alert(data.message)
        }
      },
      (error) => {
        window.alert(error.error['message']);
      }
    );
  }

  updateModel(): void {
    this.broadcaster.name = this.broadcasterForm.get('name').value;
    this.broadcaster.description =
      this.broadcasterForm.get('description').value;
    this.broadcaster.email = this.broadcasterForm.get('email').value;
    this.broadcaster.landline = this.broadcasterForm.get('landline').value;
    this.broadcaster.mobile = this.broadcasterForm.get('mobile').value;
    this.broadcaster.contactPerson =
      this.broadcasterForm.get('contactPerson').value;
    this.broadcaster.address = this.broadcasterForm.get('address').value;
    this.broadcaster.city = this.broadcasterForm.get('city').value;
    this.broadcaster.state = this.broadcasterForm.get('state').value;
    this.broadcaster.pincode = this.broadcasterForm.get('pin').value;

    // this.broadcaster.channels = this.channelList.filter(channel => {
    //   return this.selectedList.some(select => {
    //     return channel.id === select.id;
    //   });
    // });
  }
}
