<div class="cms__content">
	<app-reports-header></app-reports-header>

    <div class="top__heading">
        <h3>Subscription Desubscription Logs</h3>
        <a [routerLink]="['/logs']"><button class="default__button float-right">Back</button></a>
    </div>

    <div class="row">
        <div class="filter__group col-12 col-md-6">
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div>
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
              CSV
              <img src="./assets/csv.svg" alt="download" />
            </button>
        </div>


        <!-- <div class="col-12 col-md-6 report_selection_block">
            <label class="report_selection_label">Select STB MAC Address</label>
            <select class="custom__field report_selection" [(ngModel)]="selectedStbMacAddress" (change)='changeMacAddress()'>
                <option *ngFor="let mac of macAddressList" [ngValue]='mac.id'>{{mac.name}}</option>
            </select>
        </div> -->

    </div>

    <div class="col-12 col-md-6">
        <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
      </div>

    <div class="col-12 list__table">
        <div class="row">
            <table class="custom__iptv__table" #reportTable id="reportTable">
                <thead>
                    <tr>
                        <th>Start Date</th>
                        <th>STB MAC Address</th>
                        <!-- <th>Subscriber for (bouquet name)</th> -->
                        <th>Bouquet/Channel ID</th>
                        <th>Command</th>
                        <th>STB Level</th>
                        <th>Expiry Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="reportList.length<=0; else showList">
                        <span><h5>No records!</h5></span>
                    </tr>

                    <ng-template #showList>
                        <tr *ngFor="let report of reportList">
                            <td>{{report.startDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
                            <td>{{report.stbMacAddress? report.stbMacAddress : '---'}}</td>
                            <!-- <td>{{report.subscriber}}</td> -->
                            <td>{{report.bouquetId? report.bouquetId : '---'}}</td>
                            <td>{{report.command? report.command : '---'}}</td>
                            <td>{{report.stbLevel? report.stbLevel : '---'}}</td>
                            <td>{{report.endDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
    </div>
</div>
