import { Component, ElementRef, ViewChild } from '@angular/core';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { ngxCsv } from 'ngx-csv';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-report-lco-billing',
  templateUrl: './report-lco-billing.component.html',
  styleUrls: ['./report-lco-billing.component.scss'],
})
export class ReportLcoBillingComponent {
  profileInfo = {};
  paginationObj = new PaginationObj();
  loadingFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  reportList = [];
  from;
  to;
  totalPages;
  searchKeywordW = '';
  currentDate = new Date();
  isLoad = 'Choose Date';
  pageSize = 10;
  pageNumber = 1;
  dateSelected = false;
  ev;
  reportDate;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private doc: DocumentGeneratorService,
    private config: ConfigService,
    private pdf: PdfGeneratorService,
    private report: ReportTraiDataService,
    private http: HttpClientWrapperService
  ) {}

  ngOnInit(): void {
    this.isLoad = 'Please select LCO';
    this.fetchData()
    this.paginationObj.pageNumber = 1;
  }

  fetchData() {
    this.loadingFlag = true;
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint)
    let url = PaginationUtilities.urlBuilder('lco/lcoShareBilling', this.paginationObj)
    url += `&lcoId=${this.lcoID}`
    this.http.get(url).subscribe({
      next: (res) => {
        this.handlePageMetadata(res.metadata);
        this.loadingFlag = false;
        if (res.data == null) {
          this.isLoad = 'No Data';
          this.loadingFlag = false;
        }
        if (res.data?.length == 0) {
          this.isLoad = 'No Data';
          this.loadingFlag = false;
        }
        this.reportList = res.data;
      },
      error: () => {
        this.loadingFlag = true;
        this.isLoad = 'Error Fetching Data';
      },
      complete: () => {
        this.loadingFlag = false;
        console.log(this.paginationObj);
      },
    });
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus()
  }

  searchText: string;
  isSearch = false;
  getSearchData(e?: Event, searchText?: string) {
    if (searchText !== 'ALL') {
      this.searchText = (e.target as HTMLInputElement).value;
    } else {
      this.searchText = searchText;
    }
    this.isSearch = false;
    if (this.searchText.length >= 3) {
      setTimeout(() => {
        this.isSearch = true;
      }, 300);
    }
  }

  getSearchDataForAll() {
    this.searchText = 'ALL';
    this.getSearchData(undefined, 'ALL');
  }

  lcoID="";
  lcoName = 'All';
  getLCODetails(e: LcoSearchType) {
    let search = document.getElementById('lcoSearch') as HTMLInputElement;
    search.value = `${e.lcoName}, ${e.lcoCode}`;
    this.lcoID = e.lcoId;
    this.lcoName = e.lcoName;
    this.fetchData();
    this.isSearch = false;
  }

  // downloadTableExcel(): void {
  //   let name = 'LCO Billing';
  //   if (this.reportList.length == 0) {
  //     alert('No Data');
  //     return;
  //   }
  //   this.doc.exportTableToExcelwithImage(this.reportTable, name, 'lco_billing');
  // }

  downloadTableExcel(){
    let url= this.config.json.apiEndpoint+'generate/billingReport?searchKeyword='+this.paginationObj.searchKeyword+'&lcoId='+this.lcoID
    this.http.getwithresponseType(url).subscribe(
      (response:Blob) => {
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'lco_billing.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
    )
  }


  downloadTablePdf(): void {
    let date = new Date();
    let reportGenerated = `${String(date.getDate()).padStart(2, '0')}-${String(
      date.getMonth() + 1
    ).padStart(2, '0')}-${date.getFullYear()}`;
    // this.pdf.generatePdfHtml(
    //   '#reportTable',
    //   'lco_billing', `LCO: ${this.lcoName}`
    // );
    this.pdf.generatePDFForLongTable(
      'LCO Billing',
      `LCO: ${this.lcoName}`,
      1800
    );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        'Slno',
        'LCO Code',
        'Distributor Code',
        'Subscriber Code',
        'Bouquet Name',
        'STB ID',
        'Bouquet Price',
        'LCO Share',
        'Distributor Share',
        'MSO Share',
        'MSO Share GST',
        'Pay Mode',
        'LCO Wallet Amount Before',
        'LCO Wallet Amount After',
        'Distributor Wallet Amount Before',
        'Distributor Wallet Amount After',
        'Subscription Type',
        'Created Date',
      ],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        lcoCode: line.lcoCode,
        distributorCode: line.distributorCode,
        subscriberCode: line.subscriberCode,
        bouquetName: line.bouquetName,
        stbId: line.stbId,
        bouquetPrice: line.bouquetPrice,
        lcoShare: line.lcoShare,
        distributorShare: line.distributorShare,
        msoShare: line.msoShare,
        msoShareGST: line.msoShareGST,
        payMode: line.payMode,
        lcoWalletBefore: line.lcoWalletBefore,
        lcoWalletAfter: line.lcoWalletAfter,
        distributorWalletBefore: line.distributorWalletBefore,
        distributorWalletAfter: line.distributorWalletAfter,
        subscriptionType: line.subscriptionType,
        createdDate: line.createdDate,
      };
      data.push(csvLine);
    });

    new ngxCsv(data, 'Lco_Billing', options);
  }


  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (
      this.paginationObj.pageNumber == this.paginationObj.totalPages
    ) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }
}
