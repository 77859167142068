import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';


@Component({
  selector: 'app-report-subscriber-status',
  templateUrl: './report-subscriber-status.component.html',
  styleUrls: ['./report-subscriber-status.component.scss']
})
export class ReportSubscriberStatusComponent implements OnInit {

  subscriberReport = [];

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
  }

  fetchData(from: any, to: any): void {
    this.report.getSubscriberStatusReport(from, to, {}).subscribe(data => {
      this.subscriberReport = data;
    });
  }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      this.calendar.setDate(ev);
      this.fetchData(ev.startDate, ev.endDate);
    }
  }

  downloadTableExcel(): void {
    let name='Subscriber status Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'subscriber_status_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable', 'subscriber_status_report');
  }
  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Date", "Active", "Inactive", "ID"]
    };
    let data = []
    this.subscriberReport.forEach(line => {
      let csvLine = {
        createdDate: line.createdDate,
        activeUsers: line.activeUsers,
        inActiveUsers: line.inActiveUsers,
        id: line.id,
        newUsers: line.newUsers,

      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_status_report', options);
  }

}
