import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
@Component({
  selector: 'app-report-stb-active-count',
  templateUrl: './report-stb-active-count.component.html',
  styleUrls: ['./report-stb-active-count.component.scss', '../report-filters.scss']
})
export class ReportStbActiveCountComponent implements OnInit {

  reportList = [];
  selectedLco: any;
  from: any;
  to: any;

  trData = true

  loadingFlag = false
  isLoad = 'Choose LCO'

  partnerName: string
  lcoNumber

  totalCount = 0

  currentDate = new Date();

  lcoList: LCOInfo[] = [];

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) { }


  ngOnInit(): void {
    this.fetchLcoList();
    this.selectedLco = 0
    this.fetchData()
  }



  fetchLcoList(): void {
    this.report.getLCOList().subscribe(data => {
      this.lcoList = data.data;
    });
  }


  fetchData(): void {
    // // console.log(this.selectedLco)
    this.totalCount = 0
    if (this.selectedLco == 0) {
      this.partnerName = 'All'
      this.lcoNumber = 0
    } else {
      // // console.log('call')
      this.lcoNumber = this.selectedLco.id
      this.partnerName = this.selectedLco.lcoCode

    }
    this.report.getStbActiveCountReport({ 'lcoId': this.lcoNumber, 'searchKeyword': '' }).subscribe(data => {
      this.reportList = data.data;
      for (let ele of data.data) {
        this.totalCount += ele.stb_active_count
      }
      this.loadingFlag = false
      this.trData = false
      if (this.reportList.length != 0) {
        this.loadingFlag = false
        this.trData = true
      } else {
        this.loadingFlag = true
        this.isLoad = 'No Records'
        this.trData = true
      }
    }, () => {
      this.loadingFlag = false
      this.isLoad = 'Error'
      this.trData = false
    });
  }



  // lcoSelect(): void {
  //   this.fetchData(this.calendar.getDate()['startDate'], this.calendar.getDate()['endDate']);
  // }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.fetchData();
      this.calendar.setDate(ev);
      this.loadingFlag = true
      this.isLoad = 'Loading'
    }
  }

  downloadTableExcel(): void {
    let name = 'Stb active Count Report'
    this.doc.exportTableToExcelwithImage(this.reportTable, name, 'stb_active_count_report', undefined, undefined, "Partner Code : ", this.partnerName);
  }

  downloadTablePdf(): void {
    // // console.log(this.partnerName)
    this.pdf.generatePdfHtml('#reportTable',
      'stb_active_count_report',
      `Partner Code : ${this.partnerName},  Source Type : SMS`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No", "Partner Code", 'Partner Name', 'Active STB Count']
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo: i,
        lcoId: line.lco_code,
        activeSTBs: line.name,
        stbActiveCount: line.stb_active_count
      }
      data.push(csvLine);
      i += 1
    });

    new ngxCsv(data, 'stb_active_count_report', options);
  }

  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms + 'reports/getStbActiveCountReport'
    let body = {
      'lcoId': this.lcoNumber,
      'searchKeyword': value
    }
    this.http.post(url, body).subscribe(data => {
      this.reportList = data.data;
      this.totalCount = 0
      for (let ele of data.data) {
        this.totalCount += ele.stb_active_count
      }
      this.loadingFlag = false
      this.trData = false
      if (this.reportList.length != 0) {
        this.loadingFlag = false
        this.trData = true
      } else {
        this.loadingFlag = true
        this.isLoad = 'No Records'
        this.trData = true
      }
    }, () => {
      this.loadingFlag = false
      this.isLoad = 'Error'
      this.trData = false
    });
  }

}
