<div class="cms__content">
  <div class="top__heading">
    <h3>{{ isEdit ? "Edit" : "Create" }} Broadcaster</h3>
    <a [routerLink]="['/content/broadcaster/list']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="clearfix"></div>
  <form action="" [formGroup]="broadcasterForm" (ngSubmit)="persist()">
    <div class="col-12 form__style">
      <div class="row">
        <div class="col-12 col-md-6">
          <label>Name</label>
          <input type="text" autocomplete="off"
            class="custom__field"
            formControlName="name"
            [ngClass]="{ error__field: !name.valid && name.touched }"
          />
          <div [hidden]="!name.touched">
            <span class="error__msg" *ngIf="name.hasError('required')"
              >Required</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Contact Person</label>
          <input type="text" autocomplete="off"
            class="custom__field"
            formControlName="contactPerson"
          />
        </div>

        <!-- <div class="col-12">
          <label>Channels</label>

          <ng-multiselect-dropdown [placeholder]="'select channels'" [settings]="dropdownSettings" [data]="dropdownList"
            [(ngModel)]="selectedList" [ngModelOptions]="{standalone: true}" class="custom__field">
          </ng-multiselect-dropdown>

        </div> -->

        <div class="col-12">
          <label>Description</label>
          <textarea
            class="custom__field"
            formControlName="description"
          ></textarea>
        </div>

        <div class="col-12 col-md-6">
          <label>Landline</label>
          <input type="text" autocomplete="off"
            class="custom__field"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
            pattern="^\d{10}$"
            maxlength="10"
            formControlName="landline"
            [ngClass]="{ error__field: !landline.valid && landline.touched }"
          />
          <div [hidden]="!landline.touched">
            <span class="error__msg" *ngIf="landline.hasError('required')"
              >Required</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Mobile</label>
          <input type="text" autocomplete="off"
            class="custom__field"
            formControlName="mobile"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
            pattern="^\d{10}$"
            maxlength="10"
            (input)="checkMobileNumberPattern($event)"
            [ngClass]="{ error__field: !mobile.valid && mobile.touched }"
          />
          <div [hidden]="!mobile.touched">
            <span class="error__msg" *ngIf="mobile.hasError('required')"
              >Required</span
            >
            <span class="error__msg" *ngIf="isMobileInvalid" >Mobile numer is Invalid</span>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>Email</label>
          <input type="text" autocomplete="off"
            class="custom__field"
            formControlName="email"
            [ngClass]="{ error__field: !email.valid && email.touched }"
          />
          <div [hidden]="!email.touched">
            <span class="error__msg" *ngIf="email.hasError('required')"
              >Required</span
            >
            <span class="error__msg" *ngIf="email.hasError('email')"
              >Email is invalid</span
            >
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label>City</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="city" />
        </div>
        <div class="col-12 col-md-6">
          <label>State</label>
          <input type="text" autocomplete="off" class="custom__field" formControlName="state" />
        </div>
        <div class="col-12 col-md-6">
          <label>PIN</label>
          <input type="number" class="custom__field" maxlength="6" formControlName="pin" />
        </div>
        <div class="col-12">
          <label>Address</label>
          <textarea class="custom__field" formControlName="address"></textarea>
        </div>

        <div class="col-12" *ngIf="isEdit">
          <div class="col-12 channel_list__settings">
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="not__required">Show channel list</label>
                <label class="switch">
                  <input type="checkbox"
                    [(ngModel)]="showChannels"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <span class="slider round"></span> </label
                ><br />
              </div>
            </div>
            <div
              *ngIf="showChannels"
              class="col-12 channel_list__settings__inner"
            >
              <label class="not__required">
                {{
                  channelList.length > 0
                    ? "Channels"
                    : "No Channels under this broadcaster"
                }}
              </label>
              <div class="overflow__url" *ngIf="channelList.length > 0">
                <ul>
                  <li *ngFor="let channel of channelList">
                    {{ channel.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <!-- <button type="button" [routerLink]="['/broadcaster/list']" class="default__button">Cancel</button> -->
          <button class="cta float-right" [disabled]="!broadcasterForm.valid">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
