import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PathNames } from 'src/app/constants/path-names';
import { RegionInfo } from 'src/app/models/regions/region-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-assign-lcn',
  templateUrl: './assign-lcn.component.html',
  styleUrls: ['./assign-lcn.component.scss']
})
export class AssignLcnComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService) { }

  regionIds
  isEdit
  regionLCNForm: FormGroup;
  region = {} as RegionInfo;
  lCNSetupChannels = [];
  selectedChannel = ''
  channelList = [];
  filteredChannelList = [];
  LCNDetails
  channelLCN
  showPopup = false
  index = 0;
  savelistChannel = []
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    textField: 'name',
  };

  ngOnInit(): void {
    this.getPathParam();
    this.initForm();
    this.fetchChannels();

  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.regionIds = id
      this.fetchRecord(id);
    }
  }
 

  fetchChannels() {
    let url = this.config.json.apiEndpoint + 'channel/search?searchKeyword='
    this.http.get(url).subscribe(
      (data) => {
        this.channelList = data.data
        this.filteredChannelList = this.channelList;
      this.fetchLCNDetails(this.regionIds);

      }
    )

  }


  filterChannels(searchTerm: string) {
    if (!searchTerm) {
      this.filteredChannelList = this.channelList; // Reset to full list if search term is empty
    } else {
      searchTerm = searchTerm.toLowerCase();
      this.filteredChannelList = this.channelList.filter(channel =>
        channel.channelName.toLowerCase().includes(searchTerm) ||
        channel.channelId.toString().includes(searchTerm) ||
        channel.defaultLCN.toString().includes(searchTerm)
      );
    }
  }
 

  fetchLCNDetails(id) {
    let url = `${this.config.json.apiEndpoint}regionLCN/` + id;
    this.http.get(url).subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          console.log(res.data)
          this.LCNDetails = res.data
          this.matchLCNDetails();
        }
        else {
          this.LCNDetails = []
        }
      },
    )
  }


  // setLCNDetails(list,i){
  //   for(let j of this.filteredChannelList){
  //     j['setFlag'] = false;
  //   }
  // this.filteredChannelList[i]['setFlag'] = true;
  // if(this.filteredChannelList[i]['lcn'])
  //   {
  //     this.channelLCN =this.filteredChannelList[i]['lcn']
  //   }
  //   else{
  //     this.channelLCN =''
  //   }
  // }

 


  setLCNDetails(channel, j) {
    this.index = j;
    this.showPopup = true;
    this.channelId.setValue(channel.channelId)
    this.channelName.setValue(channel.channelName)
    if (channel.lcn) {
      this.channelLCN = channel.lcn
    }
    else {
      this.channelLCN = ''
    }
  }

  cancelClick() {
    this.showPopup = false
    this.channelId.setValue('')
    this.channelLCN = ''
    this.channelName.setValue('')
  }


  // setChannelLCNValue(channel,j){
  //   this.filteredChannelList[j].lcn=this.channelLCN
  //   this.showPopup = false
  //   this.channelLCN=''
  //   this.savelistChannel.push(this.filteredChannelList[j])

  //   // this.channelList.find(channel)
  //   // this.matchLCNDetails()
  // }

  setChannelLCNValue(channel) {
    this.filteredChannelList[this.index].lcn = this.channelLCN
    this.showPopup = false
    // this.savelistChannel.push(this.filteredChannelList[this.index])
    console.log(this.savelistChannel)
    let exist = false
    for (let j of this.savelistChannel) {
      if (this.filteredChannelList[this.index].channelId == j.channelId) {
        j.lcn = this.channelLCN
        console.log(j)
        exist = true
        this.channelLCN=''
      }
    }

    if (exist == false) {
      this.savelistChannel.push(this.filteredChannelList[this.index]);
      this.channelLCN = ''
    }

  }

  matchLCNDetails() {
    for (let j of this.filteredChannelList) {
      j['setFlag'] = false
      for (let k of this.LCNDetails) {
        if (j.channelId == k.channelId) {
          j['lcn'] = k.lcn
          console.log(j)
          this.savelistChannel.push(j)
        }
      }
    }
  }

  fetchRecord(id: string): void {
    const url =
      this.config.json.apiEndpointRdbms + 'region/' + id;

    this.http.get(url).subscribe((data) => {
      this.region = data.data;
      this.regionName.setValue(this.region.regionName)
      this.regionId.setValue(this.region.id)
    });
  }

  initForm(): void {
    this.regionLCNForm = new FormGroup({
      regionId: new FormControl(''),
      regionName: new FormControl(''),
      channelId: new FormControl(''),
      channelLCN: new FormControl(''),
      channelName: new FormControl('')
    });
  }
  get regionId(): FormControl {
    return this.regionLCNForm.get('regionId') as FormControl;
  }
  get regionName(): FormControl {
    return this.regionLCNForm.get('regionName') as FormControl;
  }
  get state(): FormControl {
    return this.regionLCNForm.get('state') as FormControl;
  }
  get channelId(): FormControl {
    return this.regionLCNForm.get('channelId') as FormControl;
  }

  get channelName(): FormControl {
    return this.regionLCNForm.get('channelName') as FormControl;
  }

  updateModel() {
    for (let j of this.savelistChannel) {
      j.regionId = this.regionId.value
    }
    this.save()
  }

  persist() {
    this.updateModel();
  }

  save() {
    let url = this.config.json.apiEndpoint + 'regionLCN/create'
    this.http.post(url, this.savelistChannel).subscribe(
      (data) => {
        if (data.statusCode == 200) {
          this.router.navigateByUrl('content/statewise-lcn');
        } else {
          alert(data.message)
        }
      }
    )
  }

}
