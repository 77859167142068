<div class="cms__content">
    <div class="top__heading">
    <app-reports-header></app-reports-header>

        <h3>Subscriber Status Report</h3>
        <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
    </div>

    <div class="row">
        <div class="filter__group col-12 col-md-6">
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div>
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
        <img src="./assets/csv.svg" alt="download" />			</button>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
      </div>

    <div class="col-12 list__table">
        <div class="row">
            <table class="custom__iptv__table" #reportTable id="reportTable">
                <thead>
                    <tr>
                      <th>Sl No.</th>
                        <th>Date</th>
                        <!-- <th>Total Subscribers</th> -->
                        <th>Active</th>
                        <th>Inactive</th>
                        <th>ID</th>
                        <!-- <th>New Subscribers</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let report of subscriberReport, let j = index">
                        <td>{{report.createdDate | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
                        <!-- <td>{{report.totalUsers}}</td> -->
                        <td>{{report.activeUsers}}</td>
                        <td>{{report.inActiveUsers}}</td>
                        <td>{{report.id}}</td>
                        <!-- <td>{{report.newUsers}}</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
