export default [
    {
        "Sapphire" : {
           primary : "#46127f",
           secondary: "#310d59",
           hover:"#140525"
        }
    },
    {
        "Violet" : {
           primary : "#7502e9",
           secondary: "#5b01b5",
           hover:"#4e019b"
        }
    },
    {
        "Purple" : {
           primary : "#ae3ddf",
           secondary: "#862fac",
           hover:"#712890"
        }
    },
    {
        "Ocean" : {
           primary : "#2196f3",
           secondary: "#1a76c0",
           hover:"#166fa5"
        }
    },
    {
        "Sakura" : {
           primary : "#ff7f9a",
           secondary: "#cc657b",
           hover:"#b0576a"
        }
    },
    {
        "Salmon" : {
           primary : "#ff5733",
           secondary: "#cc4528",
           hover:"#b13c23"
        }
    },
    {
        "Grass" : {
           primary : "#73b872",
           secondary: "#538452",
           hover:"#436b42"
        }
    },
    {
        "Forest" : {
           primary : "#37725e",
           secondary: "#254c3f",
           hover:"#0b1712"
        }
    },





   
]