import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(
    private https: HttpClient,
    private http: HttpClientWrapperService,
    private auth: AuthService,
    private httpClient: HttpClient,
    private config: ConfigService
    ) { }

    httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.auth.getToken()
      })
    }

  pushFileToStorage(file: File): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'channel/uploadBulkChannels'
    const data: FormData = new FormData();
    data.append('file', file);

    const header = new HttpHeaders({
      'Authorization' : 'Bearer ' + this.auth.getToken(),
      // 'Content-Type' : 'multipart/form-data'
    });
    const options = { headers: header };
    return this.https.post(url, data, options);
}

uploadExcel(file) {
  const url = this.config.json.apiEndpointRdbms + 'channel/uploadBulkChannels';
  return this.https.post(url, file, this.httpOptions);
}
}

