<div class="cms__content">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-2 top__heading">
                <h3>MSO Payment</h3>
            </div>
            <div class="col-12 col-md-8 offset-md-2">
                <div class="card">
                    <label class="safe__label">Sufficient Balance</label>
                    <!-- <b>Amount Due</b>
                    <p class="superBig highlight">Rs 4,554.52</p> -->
                    <b>Wallet Balance</b>
                    <p class="superBig">{{this.walletBalance}}</p>
                    <b>Enter Amount</b>
                    <input type="number" class="custom__field" placeholder="Enter Amount" [(ngModel)]="amount"/>
                    <div class="button__divs">
                        <button [routerLink]="['/profile/my-wallet']">Recharge</button>
                        <button class="cta" (click)="debitLcoWallet()">Pay Now</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>