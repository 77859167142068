import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lco-self-registration',
  templateUrl: './lco-self-registration.component.html',
  styleUrls: ['./lco-self-registration.component.scss']
})
export class LcoSelfRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
//     import { Component, OnInit } from '@angular/core';
// import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, FormBuilder } from '@angular/forms';
// import { ActivatedRoute, Router } from '@angular/router';
// import { IDropdownSettings } from 'ng-multiselect-dropdown';
// import { Observable, forkJoin } from 'rxjs';
// import { PathNames } from '../constants/path-names';
// import { AclGroupInfo } from '../models/acl-group/acl-group-info';
// import { LocationInfo } from '../models/location/location-info';
// import { MSOInfo } from '../models/mso/mso-info';
// import { PackageInfo } from '../models/package/package-info';
// import { PaginationObj } from '../models/pagination/pagination-info';
// import { ConfigService } from '../services/config.service';
// import { HttpClientWrapperService } from '../services/http-client-wrapper.service';
// import { StoreService } from '../services/store.service';
// import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { Form } from '@angular/forms';
// import { UserInfo } from '../models/user/user-info';


// @Component({
//   selector: 'app-selfregistration',
//   templateUrl: './selfregistration.component.html',
//   styleUrls: ['./selfregistration.component.scss']
// })
// export class SelfregistrationComponent implements OnInit {
//   basicDetailsForm: FormGroup;

//   constructor(private formBuilder: FormBuilder) {}

//   ngOnInit() {}
//   //   this.basicDetailsForm = this.formBuilder.group({
//   //     name: ['', Validators.required],
//   //     age: [''],
//   //     email: ['', Validators.email]
//   //   });
//   // }

//   formData = {
//     name: '',
//     email: ''
//     // Add more fields as needed
//   };

//   submitForm() {
//     // Handle form submission here
//     console.log(this.formData);
//   }
  
  

  
// //   passwordStringMatch(): boolean {
// //     var myRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
// //     if(myRegex.test(this.userForm.get('password').value)){
// //       this.userForm.get('password').setErrors(null)
// //       return true
// //     }else{
// //       this.userForm.get('password').setErrors({pattern : false})
// //       return false
// //     }
// //   }

  

// //   userForm: FormGroup;

// //   user = {} as UserInfo;

// //   showPassword = false;
// //   showConfirmPassword = false;
// //   // phonePattern = '^[+][9][1][6-9][0-9]{9}$';
// //   phonePattern = "^((\\+91-?)|0)?[0-9]{10}$"
// //   passwordRegex = '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$'

// //   locationList: LocationInfo[] = [];

// //   paginationObjPackage = new PaginationObj();

// //   loadingFlagPackage = false;

// //   disableFirstBtnPackage = false;
// //   disableLastBtnPackage = false;
// //   disableNextBtnPackage = false;
// //   disablePreviousBtnPackage = false;

  

// //   dropdownList = [];
// //   selectedList = [];
// //   dropdownSettings: IDropdownSettings = {
// //     singleSelection: false,
// //     selectAllText: 'Select All',
// //     unSelectAllText: 'Unselect All',
// //     textField: 'name',
// //   };

// //   AclGroupList = []

// //   constructor(
// //     private route: ActivatedRoute,
// //     private http: HttpClientWrapperService,
// //     private store: StoreService,
// //     private router: Router,
// //     private config: ConfigService
// //   ) { }

// //   ngOnInit(): void {
// //     this.getPathParam();
// //     this.initForm();
// //   }

// //   getPathParam(): void {
// //     const id = this.route.snapshot.paramMap.get('id');
// //       this.createFlow();   
// //   }

// //   isNumError = false
// //   numValidation(e: Event){
// //     let input = (e.target as HTMLInputElement).value;
// //     let phoneRegex: RegExp = /^[6-9]\d{9}$/;
// //     if(input == ''){
// //       return
// //     }
// //     if(phoneRegex.test(input)){
// //       this.isNumError = false
// //       this.userForm.get('mobile').setErrors(null)
// //     }else{
// //       this.userForm.get('mobile').setErrors({pattern: true})
// //       this.isNumError = true
// //     }
// //   }

// //   createFlow(): void {
// //     this.fetchCreateData();
// //   }


  

// //   fetchStates() {
// //     const url = this.config.json.apiEndpointRdbms + 'mobileApp/location/states';
// //     return this.http.getwithoutHeader(url);
// //   }

// //   fetchCreateData(): void {
// //     forkJoin([
// //       this.fetchStates()
// //     ]).subscribe(data => {
// //       this.AclGroupList = data[0].data;
// //     });
    
// //   }

// // cities =[]
// // getCities(){
// //   let url = this.config.json.apiEndpointRdbms + 'mobileApp/location/'+this.stateName.value+'/cities'
// // this.http.getwithoutHeader(url).subscribe(data=>{
// //   this.cities=data.data
// // })
// // }

// // pincodes =[]
// // getPincode(){
// //   let url = this.config.json.apiEndpointRdbms + 'mobileApp/location/'+this.stateName.value+'/'+this.cityName.value+'/pincodes'
// // this.http.getwithoutHeader(url).subscribe(data=>{
// //   this.pincodes=data.data
// // })
// // }

// // getLocation(){
// //   let url = this.config.json.apiEndpointRdbms + 'mobileApp/location/'+this.pincode.value+'/city/state'
// // this.http.getwithoutHeader(url).subscribe(data=>{
// //   this.cityName.setValue(data.data.city)
// //   this.stateName.setValue(data.data.state)
// //   this.getCities()
// //   this.getPincode()
// // })
// // }



// //   getAclList(): void {
// //     this.fetchStates().subscribe(data => {
// //       this.AclGroupList = data;
// //     });
// //   }

// //   formatAclList(): void {
// //     this.dropdownList = this.AclGroupList.map(grp => {
// //       return {
// //         id: grp.id,
// //         name: grp.name
// //       };
// //     });


// //   }

// //   initForm(): void {
// //     this.userForm = new FormGroup({
// //       userType: new FormControl('', [Validators.required]),
// //       companyName: new FormControl(''),
// //       contactPersonName: new FormControl('', [Validators.required]),
// //       email: new FormControl('', {
// //         validators: [Validators.required, Validators.email],
// //       }),
// //       mobile: new FormControl('', {
// //         validators: [Validators.required, Validators.pattern(this.phonePattern)],
// //       }),
// //       landline: new FormControl(''),
// //       password: new FormControl(''),
// //       confirmPassword: new FormControl(''),
// //       gstNumber: new FormControl('', [Validators.required]),
// //       address: new FormControl(''),
// //       stateName: new FormControl('', [Validators.required]),
// //       cityName: new FormControl('', [Validators.required]),
// //       pincode: new FormControl('', [Validators.required])
// //     });

// //     this.conditionalValidators();
// //   }

// //   get userType(): FormControl {
// //     return this.userForm.get('userType') as FormControl;
// //   }
  
// //   get companyName(): FormControl {
// //     return this.userForm.get('companyName') as FormControl;
// //   }

// //   get contactPersonName(): FormControl {
// //     return this.userForm.get('contactPersonName') as FormControl;
// //   }

// //   get email(): FormControl {
// //     return this.userForm.get('email') as FormControl;
// //   }

// //   get mobile(): FormControl {
// //     return this.userForm.get('mobile') as FormControl;
// //   }

// //   get landline(): FormControl {
// //     return this.userForm.get('landline') as FormControl;
// //   }

// //   get password(): FormControl {
// //     this.passwordStringMatch()
// //     return this.userForm.get('password') as FormControl;
// //   }

// //   get cpassword(): FormControl {
// //     return this.userForm.get('confirmPassword') as FormControl;
// //   }

// //   get address(): FormControl {
// //     return this.userForm.get('address') as FormControl;
// //   }

// //   get gstNumber(): FormControl {
// //     return this.userForm.get('gstNumber') as FormControl;
// //   }
  
// //   get stateName(): FormControl {
// //     return this.userForm.get('stateName') as FormControl;
// //   }

// //   get cityName(): FormControl {
// //     return this.userForm.get('cityName') as FormControl;
// //   }

// //   get pincode(): FormControl {
// //     return this.userForm.get('pincode') as FormControl;
// //   }
  


// //   conditionalValidators(): void {

// //       this.userForm.get('password').setValidators(Validators.required);
// //       this.userForm.get('password').setValidators(Validators.minLength(8));
// //       this.userForm.get('confirmPassword').setValidators(Validators.required);
// //       this.userForm.setValidators(this.confirmPasswordValidator);
    
// //   }

// //   updateForm(): void {
// //     this.userForm.get('userType').setValue(this.user.userType);
// //     this.userForm.get('companyName').setValue(this.user.companyName);
// //     this.userForm.get('contactPersonName').setValue(this.user.contactPersonName);
// //     this.userForm.get('email').setValue(this.user.email);
// //     this.userForm.get('mobile').setValue(this.user.mobileNumber);
// //     this.userForm.get('landline').setValue(this.user.landlineNumber);
// //     this.userForm.get('address').setValue(this.user.address);
// //     this.userForm.get('gstNumber').setValue(this.user.gstNumber);
// //     this.userForm.get('cityName').setValue(this.user.cityName);
// //     this.userForm.get('password').setValue(this.user.password);
// //     this.userForm.get('stateName').setValue(this.user.stateName);
// //     this.userForm.get('pincode').setValue(this.user.pincode);
// //   }
  

// //   persist(): void {
// //     if(this.userType.value =='lco' && this.userForm.get('companyName').value ==''){
// //       alert("company name required for lco")
// //     }
// //     else{
// //       this.updateModel()
// //       this.save();
// //     }
// //   }

// //   style = {
// //     position: 'relative'
// //   }

// //   save(): void {
// //     const url = this.config.json.apiEndpointRdbms + 'leadGeneration';

// //     this.http.postwithoutheader(url, this.user).subscribe(data => {

// //       if (data.statusCode == 200) {
// //         // this.router.navigate(['/manage/mso', 'list']);
// //         alert(this.user.userType +" has been created thank you");
// //         this.userForm.reset();

// //       }
// //       else {
// //         alert(data.message)
// //       }

// //     },
// //       (error) => {
// //         window.alert(error.error["message"])
// //       }
// //     );
// //   }

  

// //   updateModel(): void {
// //     this.user.userType = this.userForm.get('userType').value;
// //     this.user.companyName = this.userForm.get('companyName').value;
// //     this.user.contactPersonName = this.userForm.get('contactPersonName').value;
// //     this.user.email = this.userForm.get('email').value
// //     this.user.mobileNumber = this.userForm.get('mobile').value;
// //     this.user.gstNumber = this.userForm.get('gstNumber').value;
// //     this.user.companyName = this.userForm.get('companyName').value;
// //     this.user.cityName = this.userForm.get('cityName').value;
// //     this.user.stateName = this.userForm.get('stateName').value;
// //     this.user.address = this.userForm.get('address').value;
// //     this.user.pincode = this.userForm.get('pincode').value;
// //     this.user.landlineNumber = this.userForm.get('landline').value    
// //     this.user.password = this.userForm.get('password').value 
// //   }

// //   submit(): void {
// //     this.persist();
// //   }


// //   confirmPasswordValidator(group: AbstractControl): ValidationErrors | null {
// //     if (group.get('confirmPassword').value !== group.get('password').value) {
// //       return { mismatch: true };
// //     }
// //   }



// }

  }

}
