import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateSubscriberGroupComponent } from './create-subscriber-group/create-subscriber-group.component';
import { ListSubscriberGroupComponent } from './list-subscriber-group/list-subscriber-group.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: ListSubscriberGroupComponent },
  { path: ':id', component: CreateSubscriberGroupComponent },
  { path: 'create', component: CreateSubscriberGroupComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriberGroupRoutingModule { }
