import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutsideCustom]'
})
export class ClickOutsideCustomDirective {

  @Output() clickOutside = new EventEmitter<boolean>();

  @Input() listen: boolean = false;

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.listen) {
      this.clickOutside.emit(true);
    }
  }


}
