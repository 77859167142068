import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Injectable({
  providedIn: 'root',
})
export class PdfGeneratorService {
  constructor() { }


  // generatePdfHtml(
  //   table: any,
  //   fileName: string,
  //   info: string = '',
  //   orientation?,
  //   imgSize?: { x: number; y: number; minus: number },
  //   pageSizeFormat?: string
  // ): void {
  //   const doc = new jsPDF({
  //     orientation: orientation ? orientation : 'landscape',
  //     format: pageSizeFormat ? pageSizeFormat : undefined,
  //   });
  //   const dateGenerated = new Date();
  //   const dateGenString =
  //     'Generated on ' +
  //     dateGenerated.toISOString().slice(0, 10) +
  //     ' ' +
  //     dateGenerated.getHours() +
  //     ':' +
  //     dateGenerated.getMinutes() +
  //     ':' +
  //     dateGenerated.getSeconds();
  //   const img = new Image();
  //   img.src = 'assets/logos/skypro_excel_header.png';

  //   // For finding page dimensions
  //   const pageSize = doc.internal.pageSize;
  //   const pageHeight = pageSize.getHeight();
  //   const pageWidth = pageSize.getWidth();
  //   if (imgSize) {
  //     console.log('imgsize true');
  //     if (pageSizeFormat) {
  //       console.log('imgsize true pageSize true');
  //       doc.addImage(img, 'jpg', 100, 20, pageWidth - 180, 40);
  //     } else {
  //       console.log('imgsize true pageSize false');
  //       doc.addImage(
  //         img,
  //         'jpg',
  //         imgSize.x ? imgSize.x : 25,
  //         imgSize.y ? imgSize.y : 10,
  //         pageWidth - (imgSize.minus ? imgSize.minus : 50),
  //         240
  //       );
  //     }
  //   } else {
  //     console.log('imgsize false');
  //     if (orientation) {
  //       console.log('imgsize false orientation true');
  //       if (pageSizeFormat) {
  //         console.log('imgsize false orientation true pageSize true');
  //         doc.addImage(img, 'jpg', 100, 20, pageWidth - 180, 40);
  //       } else {
  //         console.log('imgsize false orientation true pageSize false');
  //         doc.addImage(img, 'jpg', 40, 20, pageWidth - 80, 40);
  //       }
  //     } else {
  //       console.log('imgsize false orientation false pageSize false');
  //       if (pageSizeFormat) {
  //         doc.addImage(img, 'jpg', 110, 20, pageWidth - 200, 50);
  //       } else {
  //         doc.addImage(img, 'jpg', 60, 20, pageWidth - 120, 50);
  //       }
  //     }
  //   }

  //   // For adding heading
  //   const headingTitle = fileName.split('_').map((each) => {
  //     return each.charAt(0).toUpperCase() + each.slice(1);
  //   });
  //   doc.text(headingTitle.join(' '), 31, 85);

  //   // For adding subheading data
  //   doc.setFontSize(10);
  //   doc.text(info, 31, 95);

  //   autoTable(doc, {
  //     html: table,
  //     startY: 100,
  //     tableWidth: pageWidth,
  //     margin: { left: 20, right: 20 },
  //     styles: { halign: 'justify' },
  //     headStyles: { fillColor: [103, 104, 116], cellWidth: 'wrap' },
  //     useCss: true,
  //   });

  //   for (var i = 1; i <= doc.getNumberOfPages(); i++) {
  //     doc.setPage(i);

  //     // For adding footer
  //     doc.text('IPTV OnSMS v0.1.3', 31, pageHeight - 10);

  //     // For Page Number
  //     doc.text(`Page ${i}`, Math.round(pageWidth / 2), pageHeight - 10);

  //     // For adding subfooter date
  //     doc.text(dateGenString, pageWidth - 85, pageHeight - 10);
  //   }

  //   doc.save(fileName + '_' + dateGenerated.getTime());
  // }

  generatePDFForLongTable(fileName: string, info?: string, windowWidth?: number) {
    const pdf = new jsPDF({
      orientation: 'landscape',
      format: 'letter',
    });
    let element = document.getElementById('print');
    console.log(element)
    const img = new Image();
    img.src = 'assets/logos/esto_header_1.pngs';
    let pw = pdf.internal.pageSize.width;
    pdf.addImage(img, 'jpg', 90, 10, pw - 180, 40);
    // For adding heading
    pdf.text(fileName, 31, 65);
    pdf.setFontSize(10);
    pdf.text(info, 31, 70);
    pdf.html(element, {
      callback: (doc) => {
        doc.save(fileName + new Date());
      },
      width: pw - 10,
      windowWidth: windowWidth ? windowWidth : 1200,
      y: 75,
      x: 5,
    });
  }
  // generatePdfHtml(table: any, fileName: string, info: string = '', orientation?: string, imgSize?: { x: number; y: number; minus: number },
  //   pageSizeFormat?: string): void {
  //   const doc = new jsPDF({
  //     orientation: orientation ? orientation : 'landscape',
  //     format: pageSizeFormat ? pageSizeFormat : 'a4',
  //   } as any);
  //   const dateGenerated = new Date();
  //   const dateGenString =
  //     'Generated on ' +
  //     dateGenerated.toISOString().slice(0, 10) +
  //     ' ' +
  //     dateGenerated.getHours() +
  //     ':' +
  //     dateGenerated.getMinutes() +
  //     ':' +
  //     dateGenerated.getSeconds();
  //   const img = new Image();
  //   img.src = 'assets/logos/skypro_excel_header.png';

  //   const pageSize = doc.internal.pageSize;
  //   const pageHeight = pageSize.getHeight();
  //   const pageWidth = pageSize.getWidth();
  //   const padding = 20;

  //   if (imgSize) {
  //     if (pageSizeFormat) {
  //       doc.addImage(img, 'jpg', 100, 10, pageWidth - 180, 40);
  //     } else {
  //       doc.addImage(img,'jpg',imgSize.x ? imgSize.x : 25,imgSize.y ? imgSize.y : 8,pageWidth - (imgSize.minus ? imgSize.minus : 50),
  //         240);
  //     }
  //   } else {
  //     if (orientation) {
  //       if (pageSizeFormat) {
  //         doc.addImage(img, 'jpg', 100, 10, pageWidth - 180, 40);
  //       } else {
  //         doc.addImage(img, 'jpg', 40, 10, pageWidth - 80, 40);
  //       }
  //     } else {
  //       if (pageSizeFormat) {
  //         doc.addImage(img, 'jpg', 110, 20, pageWidth - 200, 50);
  //       } else {
  //         doc.addImage(img, 'jpg', 60, 20, pageWidth - 120, 50);
  //       }
  //     }
  //   }

   
  //   const headingTitle = fileName.split('_').map((each) => {
  //     return each.charAt(0).toUpperCase() + each.slice(1);
  //   });
  //   doc.text(headingTitle.join(' '), 31, 59);

    
  //   doc.setFontSize(10);
  //   doc.text(info, 31, 65);

  //   autoTable(doc, {
  //     html: table,
  //     startY: 70,
  //     tableWidth: 'auto',  
  //     margin: { left: padding, right: padding },
  //     styles: { halign: 'justify' },
  //     headStyles: { fillColor: [103, 104, 116], cellWidth: 'wrap' },
  //     useCss: true,
  //   });

  //   for (let i = 1; i <= doc.getNumberOfPages(); i++) {
  //     doc.setPage(i);

  //     doc.text('IPTV OnSMS v0.1.3', padding, pageHeight - 10);

  //     doc.text(`Page ${i}`, Math.round(pageWidth / 2), pageHeight - 10);

  //     doc.text(dateGenString, pageWidth - 85, pageHeight - 10);
  //   }

  //   doc.save(`${fileName}_${dateGenerated.getTime()}.pdf`);
  // }


  generatePdfHtml(table: any, fileName: string, info: string = '', orientation?: string, imgSize?: { x: number; y: number; minus: number }, pageSizeFormat?: string): void {
    const doc = new jsPDF({
      orientation: orientation ? orientation : 'landscape',
      format: pageSizeFormat ? pageSizeFormat : 'a4',
    } as any);
  
    const dateGenerated = new Date();
    const dateGenString =
      'Generated on ' +
      dateGenerated.toISOString().slice(0, 10) +
      ' ' +
      dateGenerated.getHours() +
      ':' +
      dateGenerated.getMinutes() +
      ':' +
      dateGenerated.getSeconds();
  
    const img = new Image();
    img.src = 'assets/logos/esto_header_1.png';
  
    img.onload = () => {
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.getHeight()+10;
      const pageWidth = pageSize.getWidth()+10;
      const padding = 20;
  
      // Calculate the aspect ratio of the image
      const aspectRatio = img.width / img.height;
  
      // Determine the new dimensions while maintaining the aspect ratio
      let imgWidth = imgSize && imgSize.x ? imgSize.x : pageWidth - 50;
      let imgHeight = imgWidth / aspectRatio;
      
      if (imgHeight > (imgSize && imgSize.y ? imgSize.y : 40)) {
        imgHeight = imgSize && imgSize.y ? imgSize.y : 40;
        imgWidth = imgHeight * aspectRatio;
      }
  
      // Adjust the image dimensions based on imgSize and aspect ratio
      if (imgSize) {
        if (pageSizeFormat) {
          doc.addImage(img, 'jpg', 100, 10, imgWidth, imgHeight);
        } else {
          doc.addImage(img, 'jpg', imgSize.x ? imgSize.x : 25, imgSize.y ? imgSize.y : 8, imgWidth, imgHeight);
        }
      } else {
        if (orientation) {
          if (pageSizeFormat) {
            doc.addImage(img, 'jpg', 100, 10, imgWidth, imgHeight);
          } else {
            doc.addImage(img, 'jpg', 40, 10, imgWidth, imgHeight);
          }
        } else {
          if (pageSizeFormat) {
            doc.addImage(img, 'jpg', 110, 20, imgWidth, imgHeight);
          } else {
            doc.addImage(img, 'jpg', 60, 20, imgWidth, imgHeight);
          }
        }
      }
  
      const headingTitle = fileName.split('_').map((each) => {
        return each.charAt(0).toUpperCase() + each.slice(1);
      });
      doc.text(headingTitle.join(' '), 61, 70);
  
      doc.setFontSize(10);
      doc.text(info, 61, 75);
  
      autoTable(doc, {
        html: table,
        startY: 78,
        tableWidth: 'auto',
        margin: { left: padding, right: padding },
        styles: { halign: 'justify' },
        headStyles: { fillColor: [103, 104, 116], cellWidth: 'wrap' },
        useCss: true,
      });
  
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.text('IPTV OnSMS v0.1.3', padding, pageHeight - 10);
        doc.text(`Page ${i}`, Math.round(pageWidth / 2), pageHeight - 10);
        doc.text(dateGenString, pageWidth - 85, pageHeight - 10);
      }
  
      doc.save(`${fileName}_${dateGenerated.getTime()}.pdf`);
    };
  }
  
}
