<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Package Composition Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>

  <div class="row">
    <div class="filter__group col-12 col-md-6">
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
        CSV
        <img src="./assets/csv.svg" alt="download" /> </button>
    </div>
  </div>

  <div class="row row-top">


    <div class="col-6 col-md-3 report_selection_block">
      <label class="report_selection_label">Product : </label>
      <select class="custom__field report_selection" [(ngModel)]="selectedBouquetId" (change)='fetchData()'>
        <option value=0>ALL</option>
        <option *ngFor="let bq of bouquetList" [ngValue]='bq.id'>{{bq.name}}</option>
      </select>
    </div>
    <div class="col-6 col-md-3 report_selection_block">
      <label class="report_selection_label">Product Type : </label>
      <select class="custom__field report_selection" [(ngModel)]="selectBouquetType" (change)='fetchData()'>
        <option value="All">ALL</option>
        <option value="ADD_ON_PACK">ADD_ON_PACK</option>
        <option value="ALACARETE_PACK">ALACARETE_PACK</option>
        <option value="BASE_PACK">BASE_PACK</option>
      </select>
    </div>

    <div class="col-6 col-md-3 report_selection_block">
      <label class="report_selection_label">Broadcaster : </label>
      <select class="custom__field report_selection" [(ngModel)]="selectBroadcaster" (change)='fetchData()'>
        <option value=0>ALL</option>
        <option *ngFor="let bq of broadcasterList" [ngValue]='bq.id'>{{bq.name}}</option>
      </select>
    </div>
  </div>

  <!-- <div class="filter-download_container">
        <div class="filter_container">
          <div class="label-container">
            <label for="">Select Date :</label>
          </div>
          <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div>
      </div> -->

  <div class="row">
    <div class="col-md-6 report-generated-fix">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
   
  </div>

  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <!-- -->
        <div class="col-12 col-md-6 table-search">
          <div class="table__right">
            <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> record{{reportList.length == 1?
              '' : 's'}}</p>
            <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
            <!-- </p> -->
          </div>
          <div class="search__container">
            <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()"
              [(ngModel)]="paginationObj.searchKeyword" />
            <button (click)="onSearch()" (keydown.enter)="onSearch()">
              <img src="./assets/search.png" alt="search" />
            </button>
          </div>
        </div>
      </div>
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.broadcasterName)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.broadcasterName}">Broadcaster Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquetName)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquetName}">Product Name</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquetId)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquetId}">Product ID</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.bouquetType)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.bouquetType}">Product Type</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.channelStatus)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.channelStatus}">Channel Status</th>
              <th class="label__align" (click)="sortByItem(sortByProperties.channelId)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.channelId}">Channel Id</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.channelName)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.channelName}">Channel Name</th>
   

          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length ==0 && !loadingFlag">
            <td style="text-align: center !important;" colspan="7">{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="7">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{j+1}}</td>
              <td>{{report.broadcasterName}}</td>
              <td>{{report.bouquetName}}</td>
              <td>{{report.bouquetId}}</td>
              <td>{{report.bouquetType}}</td>
              <td>{{report.channelStatus}}</td>
              <td>{{report.channelId}}</td>
              <td>{{report.channelName}}</td>
          

            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>