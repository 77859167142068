import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-report-next-seven-days-expiry',
  templateUrl: './report-next-seven-days-expiry.component.html',
  styleUrls: ['./report-next-seven-days-expiry.component.scss']
})
export class ReportNextSevenDaysExpiryComponent implements OnInit {
  expiryReportList = [];
  responseReceived = false;
  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config : ConfigService,
    private http :HttpClientWrapperService

  ) { }

  ngOnInit(): void {
    this.fetchData()
  }

  fetchData():void{
    this.responseReceived = false;
    this.report.getNextSevenDaysExpiryReport().subscribe((data) => {
      this.expiryReportList = data.data
      this.responseReceived = true;
      //// console.log(data)
    })

  }

  downloadTableExcel(): void {
    let name='Expiry for next 7 Days Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'next_seven_days_expiry_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePDFForLongTable('Next Seven Days Expiry Report','Source SMS')
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.", "Subscriber Code","Subscriber Name","Package Name","Partner Name", "Mobile Number","Start Date","End Date"]
    };
    let data = []
    let i =1
    this.expiryReportList.forEach(report => {
      let csvLine = {
        slNo : i,
        subscriberCode : report.subscriberCode,
        subscriberName : report.subscriberName,
        productName : report.productName,
        lcoName : report.lcoName,
        mobileNumber : report.mobileNumber,
        startDate : report.startDate,
        endDate : report.endDate
      }
      data.push(csvLine);
      i+=1
    });

    new ngxCsv(data, 'next_seven_days_expiry_report', options);
  }

  searchKeyword(){
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms+'reports/getNextSevenDaysExpiryReport/?searchKeyword='+value
    this.http.get(url).subscribe((data) => {
      this.expiryReportList = data.data
      this.responseReceived = true;
      //// console.log(data)
    })
  }

}
