import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class LogsDataService {

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  getLCOList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'lco/getLCOs';
    return this.http.get(url);
  }

  getChannelList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'channel/getChannels';
    return this.http.get(url);
  }

  getStbList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'stb/getSTBs';
    return this.http.get(url);
  }

  getBouquetList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'bouquet/getBouquets';
    return this.http.get(url);
  }

  getBroadcasterList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'broadcaster/getBroadcasters';
    return this.http.get(url);
  }

  getWalletRechargeHistory(body: {
    fromDate: string;
    toDate: string;
    type: string;
    id: string;
  }) {
    const url = this.config.json.apiEndpoint + 'wallet/rechargeHistory';
    return this.http.post(url, body);
  }


  getChannelModificationHistory2(body: any): Observable<any> {
    // body['fromDate'] = from.format('YYYY-MM-DD');
    // body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'logs/getChannelModificationHistory';
    return this.http.post(url, body);
  }


  getUserLoginHistory(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'logs/getUserLoginHistory';
    return this.http.post(url, body);
  }

  getMsoLcoLoginHistory(body){
    const url = this.config.json.apiEndpointRdbms + 'logs/getMSOLCOLoginHistory'
    return this.http.post(url, body);
  }

  getUserActivityHistory(body): Observable<any> {

    const url = this.config.json.apiEndpointRdbms + 'logs/getUserActivityHistory';
    return this.http.post(url, body);
  }

  getProductStatusChangeHistory(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'logs/getProductStatusChangeHistory';
    return this.http.post(url, body);
  }
  // getProductStatusChangeHistoryAll(from: any, to: any, body: any): Observable<any> {
  getProductStatusChangeHistoryAll(): Observable<any> {
    // body['fromDate'] = from.format('YYYY-MM-DD');
    // body['toDate'] = to.format('YYYY-MM-DD');
    // //// console.log(body);
    const url = this.config.json.apiEndpointRdbms + 'logs/getProductStatusChangeHistory';
    return this.http.post(url,{});
  }

  getBouquetCompositionChangeHistory(body): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'logs/getBouquetModificationHistory';
    return this.http.post(url, body);
  }

  getCompleteLogs(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    // const url = environment.apiEndpointRdbms + 'logs/getCompleteLogs';
    const url = this.config.json.apiEndpointRdbms + 'logs/getCompleteHistory';
    return this.http.post(url, body);
  }
  getCompleteLogs2(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'logs/getCompleteHistory';
    return this.http.post(url, body);
  }
  getCategoryList(userType): Observable<any> {

    const url = this.config.json.apiEndpointRdbms + 'logs/getCatagoryDropdownForLog?userType='+userType;
    return this.http.get(url);
  }

  getStbActivationHistory(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'logs/getSTBActivationHistory';
    return this.http.post(url, body);
  }
  getStbActivationHistory2(body: any): Observable<any> {
    // body['fromDate'] = from.format('YYYY-MM-DD');
    // body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'logs/getSTBActivationHistory';
    return this.http.post(url, body);
  }

  getSubscriptionHistory(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'logs/getSubscriptionHistory';
    return this.http.post(url, body);
  }

  getBouquetModificationHistory(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'logs/getBouquetModificationHistory';
    return this.http.post(url, body);
  }

  getChannelModificationHistory(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'logs/getChannelModificationHistory';
    return this.http.post(url, body);
  }

  getBatchLogs(){
    return this.http.get(this.config.json.apiEndpoint + 'category/bulkUploadLogs')
  }
  getBouquetModificationLogs(){
    const url = this.config.json.apiEndpointRdbms+'bouquet/getPackageChangesHistory'
    // //// console.log(url)
    return this.http.get(url)
  }

  getSubscriptionLogs(body){
    const url = this.config.json.apiEndpointRdbms+'logs/getSubscriptionLogs'
    // //// console.log(url)
    return this.http.post(url, body)
  }

}
