import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-report-channel-subscription',
  templateUrl: './report-channel-subscription.component.html',
  styleUrls: ['./report-channel-subscription.component.scss'],
})
export class ReportChannelSubscriptionComponent implements OnInit {
  reportList = [];
  channelList: ChannelInfo[] = [];
  selectedChannelId: number = 0;
  from: any;
  to: any;

  currentDate = new Date();
  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.fetchChannelList();
    this.fetchData(this.body);
  }

  fetchChannelList(): void {
    this.report.getChannelListAlacarete().subscribe((data) => {
      this.channelList = data;
      // // console.log( this.channelList )
    });
  }

  isLoad = 'Choose Date';
  body = {
    fromDate: '',
    toDate: '',
    channelId: '0',
    searchKeyword: '',
  };
  fetchData(body): void {
    this.report.getChannelSubscriptionReport(body).subscribe(
      (data) => {
        this.isLoading = false;
        this.reportList = data.data;
        if (this.reportList?.length == 0) {
          this.isLoading = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.isLoading = false;
        this.isLoad = 'No Records';
      }
    );
  }

  isLoading = false;
  isChannelSelected = true;
  reportDate;
  eve;
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve = ev;
      this.calendar.setDate(ev);
      this.reportDate =
        ev.startDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.startDate.month()) +
        '-' +
        ev.startDate.year() +
        ' to ' +
        ev.endDate.date() +
        '-' +
        this.doc.monthNameCalculator(ev.endDate.month()) +
        '-' +
        ev.endDate.year();
      this.body = {
        ...this.body,
        fromDate: ev.startDate.format('YYYY-MM-DD'),
        toDate: ev.endDate.format('YYYY-MM-DD'),
      };
      this.fetchData(this.body);
    }
  }

  channelName;

  channelSelect(): void {
    this.isChannelSelected = false;
    
    for (let ele of this.channelList) {
      if ((ele as any).bouquet_id == this.selectedChannelId) {
        this.channelName = ele.name;
      }
      this.body = {
        ...this.body,
        channelId: String(this.selectedChannelId),
      };
    }
    console.log(this.body);
    if (Object.keys(this.calendar)?.length == 0) {
      this.isLoad = 'Select Date';
    } else {
      this.fetchData(this.body);
    }
  }

  downloadTableExcel(): void {
    let name = ' Channel Subscription Report';
    this.doc.exportTableToExcelwithImage(
      this.reportTable,
      name,
      'channel_subscription_report',
      undefined,
      undefined,
      'Report Date :',
      this.reportDate,
      'Channel Name :',
      this.channelName
    );
  }

  downloadTablePdf(): void {
    if (
      this.reportList == undefined ||
      this.reportList == null ||
      this.reportList?.length == 0
    ) {
      alert('No Data');
      return;
    }
    this.pdf.generatePdfHtml(
      '#reportTable',
      'channel_subscription_report',
      `From: ${this.reportDate ? this.reportDate : 'Till now'}, Channel: ${
        this.selectedChannelId == 0 ? 'All' : this.channelName
      },  Source Type : SMS`
    );
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: [
        'Sl No',
        'Subscriber Name',
        'Subscriber Code',
        'Channel Name',
        'Bouquet Name',
        'Channel ID',
        'Channel Number',
        'Mac Address',
        'Start Date',
        'End Date',
      ],
    };

    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        subscriberName: line.subscriberName,
        subscriberCode: line.subscriberCode,
        channelName: line.channelName,
        bouquet_name_list: line.bouquet_name_list,
        channelId: line.channelId,
        channelNo: line.channelNo,
        StbMacAddress: line.StbMacAddress,
        startDate: line.startDate,
        endDate: line.endDate,
      };
      data.push(csvLine);
      i += 1;
    });

    new ngxCsv(data, 'channel_subscription_report', options);
  }

  searchKeyword() {
    // // console.log(this.eve)
    let value = (document.getElementById('searchKeyword') as HTMLInputElement)
      .value;
    let url =
      this.config.json.apiEndpointRdbms + 'reports/getChannelSubscribersReport';
    let body = {
      fromDate: this.eve.startDate.format('YYYY-MM-DD'),
      toDate: this.eve.endDate.format('YYYY-MM-DD'),
      channelId: this.selectedChannelId,
      searchKeyword: value,
    };
    // // console.log(body)
    this.http.post(url, body).subscribe(
      (data) => {
        this.isLoading = false;
        this.reportList = data.data;
        if (this.reportList?.length == 0) {
          this.isLoading = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.isLoading = false;
        this.isLoad = 'No Records';
      }
    );
  }
}
