import { Component, ElementRef, OnInit ,ViewChild} from '@angular/core';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-ad-manager',
  templateUrl: './ad-manager.component.html',
  styleUrls: ['./ad-manager.component.scss']
})


export class AdManagerComponent implements OnInit {

  @ViewChild('myInput') myInputVariable: ElementRef;

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }
  user
  ngOnInit(): void {
    if (this.config.json.userType == 'MSO') {
      this.user = true
    }
    else {
      this.user = false
    }
this.fetchAdList();
  }

  positionSelected = false
  positionValue

  selectedPosition(e: Event) {
    this.positionSelected = true
    this.positionValue = (e.target as HTMLSelectElement).value
  }

  message = null as string
  lcoId
  searchText!: string
  isSearch = false

  getSearchText(e: Event) {
    this.isSearch = false
    this.searchText = (e.target as HTMLInputElement).value
    if (this.searchText.length >= 3) {
      setTimeout(() => {
        this.isSearch = true
      }, 300);
    }
  }

  getLCOId(e: LcoSearchType) {
    let inp = document.getElementById('lcoId') as HTMLInputElement
    inp.value = `${e.lcoName}: ${e.lcoCode}`
    this.lcoId = e.lcoId
    this.isSearch = false
  }
  uploadAds(e: Event) {
    let url = ''
    if (this.user == true) {
      url = this.config.json.apiEndpointRdbms + `ad/insert?name=${this.positionValue}`
    }
    else {
      url = this.config.json.apiEndpointRdbms + `ad/insert/${this.lcoId}?name=${this.positionValue}`
    }

    let file = (e.target as HTMLInputElement).files[0]
    let formData = new FormData()
    formData.append('image', file)
    this.http.post(url, formData).pipe().subscribe({
      next: (res) => {
        this.positionValue = "selected"
        this.positionSelected = false
        this.myInputVariable.nativeElement.value = "";
          alert("Upload Succesful")
          
      },
      error: (err) => {
        alert("Something went wrong")
      },
      complete: () => {
        formData = new FormData()
        this.fetchAdList()
      }
    })
  }


  adList
  fetchAdList() {
    if (this.user == true) {
      this.http.get(this.config.json.apiEndpoint + 'ad/list').subscribe({
        next: (res) => {
          this.adList = res.data
          console.log(this.adList)
        }
      })
    }
    else {
      let id = atob(localStorage.getItem('id'));
      this.lcoId = id
      this.http.get(this.config.json.apiEndpoint + 'ad/list/' + this.lcoId).subscribe({
        next: (res) => {
          this.adList = res.data
        }
      })
    }
  }

}
