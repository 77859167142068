import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { LcoSearchType } from './lco-search';

@Component({
  selector: 'app-lco-search',
  templateUrl: './lco-search.component.html',
  styleUrls: ['./lco-search.component.scss']
})
export class LcoSearchComponent implements OnInit {

  @Input() searchText: string
  @Output() userDetail: EventEmitter<LcoSearchType> = new EventEmitter<LcoSearchType>()
  constructor(
    private configService: ConfigService,
    private http: HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    if (this.searchText) {
      this.fetchSearchData(this.searchText)
    }
  }

  users
  user
  isEmpty = false
  fetchSearchData(searchText: string) {
    let url = ''
    if (this.configService.json.userType == 'MSO') {
      this.user = true
      url = this.configService.json.apiEndpointRdbms + `lco/searchLco/0/${searchText}`
    }
    else {
      this.user = false
      let id = atob(localStorage.getItem('id'));
      url = this.configService.json.apiEndpointRdbms + 'lco/searchLco/' + id + `/${searchText}`
    }

    this.http.get(url).subscribe({
      next: (data: any) => {
        console.log(data);
        this.users = data.data
        if (data.data?.length == 0) {
          this.isEmpty = true
        }
      }
    })
  }

  getUser(user: any) {
    console.log(user)
    this.userDetail.emit(user)
  }

}
