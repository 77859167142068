import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-report-subwise-active-inactive-ldt',
  templateUrl: './report-subwise-active-inactive-ldt.component.html',
  styleUrls: ['./report-subwise-active-inactive-ldt.component.scss']
})
export class ReportSubwiseActiveInactiveLDTComponent implements OnInit {

  dateChoosed = false

  reportList = [];
  bouquetType = 0;
  broadcasterId: number;

  broadcasterList: BroadcasterInfo[] = [];

  selectedDate: any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config : ConfigService,
    private http:HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    this.fetchLocationList();
    this.createBody()
  }


  createBody(){
    this.body['fromDate'] = ''
    this.body['toDate'] = ''
    this.body['monthYear'] = ''
    this.body['status'] = this.status
    this.body['state']= this.stateName
  }

  stateList
  fetchLocationList(): void {
    let url = this.config.json.apiEndpointRdbms+ `location/getLocationDropdown`
    this.http.get(url).subscribe((data)=>{
      this.stateList = data.data.locationList;
      // // console.log(this.stateList)
    });
  }

  stateName = "0"
  locationData(){
    let obj = (document.getElementById('locationList') as HTMLSelectElement).value
    this.stateName = obj
    this.body['state'] = this.stateName
    this.fetchData(this.body)
  }

  status = 2
  statusData(){
    let obj = (document.getElementById('statusActiveInactive') as HTMLSelectElement).value
    this.status = parseInt(obj)
    this.body['status'] = this.status
    this.fetchData(this.body)
  }

  fetchData(body? :any): void {
    this.loadingFlag=true
    this.reportList = []
    body['searchKeyword']= ''
    this.report.getSubscriptionwiseSubscriberActiveDeactiveWithLocation(body).subscribe((data)=>{
      this.reportList = data.data;
          if(this.reportList.length != 0){
            this.loadingFlag=false
          }else{
            this.loadingFlag=false
          this.isLoad='No Records'
          }
        },()=>{
          this.loadingFlag=false
          this.isLoad='No Records'
        })
  }

  loadingFlag=false
  isLoad = 'Choose Date'

  showMonth = false
  showDateRange = false
  dateSelector = true
  dateTypeSelectors(num){
    if(num == 0){
      this.dateSelector = true
      this.showMonth = false
      this.showDateRange = false
    }
    if(num==1){
      this.dateSelector = false
      this.showMonth=true
      this.showDateRange = false
    }
    if(num == 2){
      this.dateSelector = false
      this.showDateRange = true
      this.showMonth = false
    }
  }


  isDateSelected = false
  body = {}
  tillDateString
  dateChangeRange(ev){
    this.dateChoosed = true
    // // console.log(this.dateChoosed)
    this.body={}
    this.reportList =[]
    let dateObj = new Date(ev.target.value)
    const toDate = dateObj.getFullYear()+"-"+String(dateObj.getMonth()+1).padStart(2,'0')+"-"+String(dateObj.getDate()).padStart(2,'0')
    this.tillDateString = toDate
    this.body['fromDate'] = ''
    this.body['toDate'] = this.tillDateString
    this.body['monthYear'] = ''
    this.body['status'] = this.status
    this.body['state']= this.stateName
      this.fetchData(this.body)
      this.loadingFlag = true
      this.eve=ev
  }


  monthDate
  dateChangeMonth(ev){
    this.dateChoosed = true
    // // console.log(this.dateChoosed)
    this.body={}
    this.reportList =[]
    this.isDateSelected = true
      this.monthDate = this.body['monthYear'] = ev.target.value+"-01"
      this.body['fromDate'] = ''
      this.body['toDate'] = ''
      this.body['status'] = this.status
      this.body['state']= this.stateName
      // // console.log(this.body)
      this.fetchData(this.body)
      this.loadingFlag = true
  }



  getReportDate(){
    let dateObj = new Date(this.monthDate)
    let reportDate =this.doc.monthNameCalculator(dateObj.getMonth())+" "+dateObj.getFullYear()
     return reportDate;
  }

  // downloadTableExcel(): void {
  //   let reportDate = this.getReportDate()
  //   let name='Subscriberwise active inactive with LDT Report'
  //   this.doc.exportTableToExcelwithImage(this.reportTable,name, 'subscriberwise_active_inactive_with_LDT', undefined, undefined, "Report Month :", this.showDateRange? this.tillDateString  : reportDate);
  // }

  
downloadTableExcel(){
  
  let url= this.config.json.apiEndpoint+'generate/getSubscriptionWiseSubscriberActiveDeactiveWithLocation?searchKeyword='+this.searchWord 
  this.http.postwithresponseType(url,this.body).subscribe(
    (response) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const jsonResponse = JSON.parse(reader.result as string);
          if (jsonResponse.status === 'FAIL') {
           alert(`Error: ${jsonResponse.message}`);
            return;
          }
        } catch (error) {
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'subscriberwise_active_inactive_with_location_date_and_time.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
        }
      };
      reader.readAsText(response);
    },
    (error) => {
      console.error('Error downloading Excel file:', error);
    }
  );
}

  downloadTablePdf(): void {
    if(this.reportList == undefined || this.reportList == null || this.reportList?.length == 0){
      alert('No Data')
      return;
    }
    let reportDate = this.getReportDate()
    const tempDate = new Date(this.selectedDate);
    const monthYear = tempDate.toLocaleString('default', {month:'long'}) + " " + tempDate.getFullYear();
    // this.pdf.generatePdfHtml('#reportTable',
    // 'subscriberwise_active_inactive_with_location_date_and_time',
    // `${this.showDateRange? 'Till Date' : 'Report Date'} : ${this.showDateRange? this.tillDateString : reportDate}`,null,'1800','a3'
    // );
    this.pdf.generatePDFForLongTable('Subscription wise subscriber Active & De-active with Location, Date & Time',
      `${this.showDateRange? 'Till Date' : 'Report Date'} : ${this.showDateRange? this.tillDateString : reportDate}`,
      1800    )
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.","Subscriber Code","Subscriber Name","Subscription Type","City","State","Country",
    ,"MAC ID","Price Package","Start Date","Subscriber Status","Subscription Status"]
    };
    let data = []
    let i = 1
    this.reportList.forEach(report => {
      let csvLine = {
        slNo : i,
        subscriber_code : report.subscriber_code,
        subscriber_name : report.subscriber_name,
        subscription_type : report.subscription_type,
        city : report.city,
        state_name : report.state_name,
        country : report.country,
        ethernet_mac_address : report.ethernet_mac_address,
        price_package_name : report.price_package_name,
        start_date : report.start_date,
        subscriber_status : report.subscriber_status==1? 'Active':'Inactive',
        subscription_status : report.subscription_status==1? 'Active':'Inactive'
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriberwise_active_inactive_with_location_date_and_time', options);}

eve
searchWord = ''
    searchKeyword(){
      let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
      this.searchWord = value
     let body ={}
      let url = this.config.json.apiEndpointRdbms + 'reports/getSubscriptionwiseSubscriberActiveDeactiveWithLocation'
      if(this.showDateRange){
        body = {
          'fromDate' : '',
          'toDate' : this.tillDateString,
          'searchKeyword' : value,
          'monthYear' : '',
          'state': this.stateName,
          'status': this.status
        }

      }
      if(this.showMonth){
        body = {
          'fromDate' : '',
          'toDate' : '',
          'monthYear' : this.monthDate,
          'searchKeyword' : value,
          'state': this.stateName,
          'status': this.status
        }
      }
      this.http.post(url,body).subscribe(data => {
        this.reportList = data.data;
        if(this.reportList.length != 0){
          this.loadingFlag=false
        }else{
          this.loadingFlag=false
        this.isLoad='No Records'
        }
      },()=>{
        this.loadingFlag=false
        this.isLoad='No Records'
      });
    }

}
