import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OTAManagerRoutingModule } from './otamanager-routing.module';
import { OtaManagerComponent } from './ota-manager/ota-manager.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PluginsModule } from '../plugins/plugins.module';
import { ListOtaUpdateComponent } from './list-ota-update/list-ota-update.component';


@NgModule({
  declarations: [
    OtaManagerComponent,
    ListOtaUpdateComponent
  ],
  imports: [
    CommonModule,
    OTAManagerRoutingModule,
    ReactiveFormsModule,
    PluginsModule,
    FormsModule
  ]
})
export class OTAManagerModule {

 }
