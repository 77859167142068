<div class="cms__content">
    <div class="dashboard_item item--1">
      <h6 class="div1">Revenue Generated</h6>
      <div class="revenue_split div2">
        <h3>&#8377;{{revenueGenerated.today}}</h3>
        <p>Today</p>
      </div>
      <div class="revenue_split div3">
        <h3>&#8377;{{revenueGenerated.month}}</h3>
        <p>On {{monthArray[date.getMonth()]}}</p>
      </div>
      <div class="revenue_split div4">
        <h3>&#8377;{{revenueGenerated.year}}</h3>
        <p>On {{date.getFullYear()}}</p>
      </div>
    </div>
    <div class="dashboard_item item--2">
        <p>Year on Year Revenue Projection</p>
        <canvas
          baseChart
          [datasets]="yearOnYearLineChartData"
          [labels]="allLineAndBarChartLabels"
          [options]="allLineAndBarChartOptions"
          [colors]="allLineChartColor"
          [legend]="allLineAndBarChartLegend"
          [chartType]="allLineChartType"
          [plugins]="allLineAndBarChartPlugins"
        >
        </canvas>
    </div>
    <div class="dashboard_item item--3">
        <p>User Status</p>
        <canvas
          baseChart
          [datasets]="userStatusBarChartData"
          [labels]="allLineAndBarChartLabels"
          [options]="allLineAndBarChartOptions"
          [colors]="allBarChartColor"
          [legend]="allLineAndBarChartLegend"
          [chartType]="allBarChartType"
          [plugins]="allLineAndBarChartPlugins"
        >
        </canvas>
    </div>
    <div class="dashboard_item item--4">
        <p>Customer Onboarding</p>
        <canvas
          baseChart
          [datasets]="customerOnboardingLineChartData"
          [labels]="allLineAndBarChartLabels"
          [options]="allLineAndBarChartOptions"
          [colors]="allLineChartColor"
          [legend]="allLineAndBarChartLegend"
          [chartType]="allLineChartType"
          [plugins]="allLineAndBarChartPlugins"
        >
        </canvas>
    </div>
    <div class="dashboard_item item--5">
        <p>Subscribers</p>
        <canvas
          baseChart
          [data]="subscriberPieChartData"
          [labels]="subscriberPieChartLabels"
          [chartType]="allPieChartType"
          [options]="allPieChartOptions"
          [plugins]="allPieChartPlugins"
          [legend]="allPieChartLegend"
          [colors]="subscriberPieChartColors"
        >
        </canvas>
    </div>
    <div class="dashboard_item item--6">
        <p>Customer Onboarded today</p>
        <p>{{smallBlockCounts.customerOnboardedToday}}</p>
        <p>Total {{isLco ? 'Subscribers' : 'LCO'}}</p>
        <p>{{isLco ? smallBlockCounts.totalSubscribers : smallBlockCounts.totalLco}}</p>
    </div>
    <div class="dashboard_item item--7">
        <p>Broadcaster</p>
        <p>{{smallBlockCounts.broadcasters}}</p>
        <p>Channels</p>
        <p>{{smallBlockCounts.channels}}</p>
    </div>
    <div class="dashboard_item item--8">
        <p>Most Viewed Channels</p>
        <div class="most_viewed_channels">
            <ng-container *ngFor="let item of mostViewedChannels" >
                <div class="channel" [title]="item" >
                    <img onerror="this.src='assets/imageError.png'" [src]="'https://epg.pishow.tv/bucket/images/'+item" alt="">
                </div>
            </ng-container>
        </div>
    </div>
    <div class="dashboard_item item--9">
        <p>Best Selling Packs</p>
        <ng-container *ngFor="let item of bestSellingPacks" >
            <div class="pack">
                <span>{{item.key}}</span><span>{{item.value}}</span>
            </div>
        </ng-container>
    </div>
    <div class="dashboard_item item--10">
        <p>Expiring Subscriptions</p>
        <div class="expiring_subscribers">
            <h4>{{expiringSubscriptions.day}}</h4>
            <p>Today</p>
        </div>
        <div class="expiring_subscribers">
            <h4>{{expiringSubscriptions.week}}</h4>
            <p>This week</p>
        </div>
        <div class="expiring_subscribers">
            <h4>{{expiringSubscriptions.month}}</h4>
            <p>This month</p>
        </div>
        <!-- <div class="expiring_subscribers">
            <h4>{{expiringSubscriptions.year}}</h4>
            <p>This year</p>
        </div> -->
    </div>
    <div class="dashboard_item item--11">
        <p>STB Status</p>
        <canvas
        baseChart
        [data]="stbPieChartData"
        [labels]="stbPieChartLabels"
        [chartType]="allPieChartType"
        [options]="allPieChartOptions"
        [plugins]="allPieChartPlugins"
        [legend]="allPieChartLegend"
        [colors]="stbPieChartColors"
      >
        </canvas>
    </div>

    <div class="dashboard_item item--12">
        <p>Total Registrations today</p>
        <p>{{dashboardData.countData.todaysCount}}</p>
        <p>Total Activation today</p>
        <p>{{dashboardData.countData.todaysActiveCount}}</p>
    </div>

    <div class="dashboard_item item--13">
        <p>Total Registrations Yesterday</p>
        <p>{{dashboardData.countData.yesterdayCount}}</p>
        <p>Total Activation Yesterday</p>
        <p>{{dashboardData.countData.yesterdayCount}}</p>
    </div>

    <div class="dashboard_item item--14">
        <p>Total Registrations last 7 days</p>
        <p>{{dashboardData.countData.last7Count}}</p>
        <p>Total Activation last 7 days</p>
        <p>{{dashboardData.countData.last7ActiveCount}}</p>
    </div>

    <div class="dashboard_item item--15">
        <p>Total Registrations this Month</p>
        <p>{{dashboardData.countData.thismonthCount}}</p>
        <p>Total Activation this month</p>
        <p>{{dashboardData.countData.thismonthActiveCount}}</p>
    </div>

    <div class="dashboard_item item--16">
        <p>Total Registrations last Month</p>
        <p>{{dashboardData.countData.lastmonthCount}}</p>
        <p>Total Activation last month</p>
        <p>{{dashboardData.countData.lastmonthActiveCount}}</p>
    </div>

    <div class="dashboard_item item--17">
        <p style="text-align:center">Top 5 LCO's</p>
       <table class="custom__iptv__table">
        <thead>
            <tr>
                <th>LCO Id</th>  <th>LCO Code</th>  <th>LCO Name</th>  <th>Active subs</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of dashboardData.lcoData">
                <td>{{data.lcoId}}</td>
                <td>{{data.lcoCode}}</td><td>{{data.lcoName}}</td><td>{{data.activeSubs}}</td>
            </tr>
        </tbody>
       </table>
    </div>
</div>