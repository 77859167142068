<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Package Ageing Inactive Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <!-- <div class="filter__group col-12 col-md-6">
      <input type="text" autocomplete="off" ngxDaterangepickerMd [(ngModel)]="selected" placeholder="Choose date"
        (change)="dateChange($event)" [ranges]="ranges" [alwaysShowCalendars]="true" [customRangeDirection]="true"
        [locale]="locale" class="custom__field" />
    </div> -->
    <!-- <div class="col-12 col-md-4 search__subscriber">
        <input type="number" class="custom__field" placeholder="Subscriber ID" [(ngModel)]="subscriberId" />
    </div> -->
    <!-- <div class="col-md-2">
      <button class="default__button inline" (click)="fetchData()">Go</button>
  </div> -->
  <div class="filter__group col-12 col-md-6"></div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
        <img src="./assets/csv.svg" alt="download" />			</button>
    </div>
  </div>

  <div class="col-12 col-md-6 report_selection_block">
    <!-- <label class="report_selection_label">Select Subscriber</label>
    <select class="custom__field report_selection" [(ngModel)]="subscriberId" (change)='selectSubscriber()'>
      <option [ngValue]=0>ALL</option>
      <option *ngFor="let sb of subscriberList" [ngValue]='sb.id'>
        {{sb.firstName + " " + sb.lastName}}
      </option>
    </select> -->
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6 report">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div style="display: flex; gap:20px" >
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Subscriber Code</th>
            <th>MAC ID</th>
            <th>Price Package Name</th>
            <!-- <th>Email</th> -->
            <th>Subscription Status</th>
            <th>Activation Date</th>
            <th>Expiry Date</th>
             <th>Ageing Days</th>
            <!-- <th>Report Date</th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="8" *ngIf="!isLoading" style="text-align: center;" >{{isload}}</td>
            <td *ngIf="isLoading && reportList.length == 0" colspan="9" style="text-align: center" ><app-custom-spinner></app-custom-spinner></td>
          </tr>
          <tr *ngFor="let report of reportList, let j = index">
            <!-- <td>{{report.createdDate | date: 'dd-MM-yy HH:mm:ss'}}</td>
              <td>{{report.name}}</td>
              <td>{{report.email}}</td>
              <td>{{report.subscriberId}}</td>
              <td>{{report.mobileNo}}</td>
              <td>{{report.productType}}</td>
              <td>{{report.productId}}</td>
              <td>{{report.productName}}</td>
              <td>{{report.productAge}} days</td>
              <td>{{report.status === 1 ? "Active" : "Inactive"}}</td>
              <td>{{report.startDate  | date: 'dd-MM-yy HH:mm:ss'}}</td>
              <td>{{report.endDate  | date: 'dd-MM-yy HH:mm:ss'}}</td> -->
              <td>{{j+1}}</td>
              <td>{{report.subscriber_code}}</td>
              <td>{{report.ethernet_mac_address}}</td>
              <td>{{report.price_package_name}}</td>
              <td>{{report.subscription_status}}</td>
              <td>{{report.start_date  | date:'dd-MM-yyyy HH:mm:ss' : 'UTC' }}</td>
              <td>{{report.end_date | date:'dd-MM-yyyy HH:mm:ss' : 'UTC' }}</td>
               <td>{{report.ageing}}</td>
              <!-- <td>{{report.report_date  }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
