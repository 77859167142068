<div class="cms__content">
    <app-reports-header></app-reports-header>
    <div class="top__heading">
        <h3>Subscription Reconciliation Report</h3>
        <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>

    <div class="row">
        <div class="filter__group col-12 col-md-6">
        <input type="text" autocomplete="off" ngxDaterangepickerMd [(ngModel)]="selected" placeholder="Choose date"
          (change)="dateChange($event)" [ranges]="ranges" [alwaysShowCalendars]="true" [customRangeDirection]="true"
          [locale]="locale" class="custom__field" />
       </div> 
        <!-- <div class="filter__group col-12 col-md-6">
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
        </div> -->
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
                CSV
                <img src="./assets/csv.svg" alt="download" />
            </button>
        </div>
    </div>

    <div class="third_box">
        <div class="col-12 col-md-6 report">
            <p>
                Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
            </p>
        </div>
        <div style="display: flex; gap: 20px">
            <!-- <div class="table__right">
                <p class="table__summary">
                    Total <span>{{ reportList?.length }}</span> record{{
                    reportList?.length == 1 ? "" : "s"
                    }}
                </p>
            </div> -->
            <!-- <div class="search__container">
                <input type="text" autocomplete="off" class="custom__field" id="searchKeyword"
                    (keydown.enter)="searchKeyword()" />
                <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()">
                    <img src="./assets/search.png" alt="search" />
                </button>
            </div> -->
        </div>
    </div>
    <div class="col-12 list__table">
        <div class="row scroll">
            <table class="custom__iptv__table" #reportTable id="reportTable">
                <thead>
                    <tr>
                        <th>Sl No</th>
                        <th>SMS</th>
                        <th>DRM</th>
                        <th>Mismatch%</th>
                        <th>Mismatch Desc</th>
                        <th>Type</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="reportList?.length == 0">
                        <td style="text-align: center" *ngIf="isLoading" colspan="6">
                            <app-custom-spinner></app-custom-spinner>
                        </td>
                        <td style="text-align: center" *ngIf="!isLoading" colspan="6">
                            {{ isLoad }}
                        </td>
                    </tr>
                    <tr *ngFor="let stbCount of reportList; let j = index">
                        <td>{{ j + 1 }}</td>
                    <ng-container *ngFor="let p of  sortKeysByPrefix(stbCount)">
                        <td *ngIf="p.key!='Mismatch1'&& p.key!='Mismatch %' && p.key!='type'" >{{p.key}}<b>-></b>{{p.value}}</td>
                        <td *ngIf="p.key=='Mismatch1'|| p.key=='Mismatch %'">{{p.value}}</td>
                        <td *ngIf="p.key=='type'">{{p.value}}</td>
                    </ng-container>
                </tr>

                </tbody>
            </table>
        </div>
    </div>
</div>