<div class="cms__content">
	<app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Subscriber Device Replacement</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="col-12 col-md-6">
      <label for="month">Select Month:</label>
      <input class="custom__field" type="month" id="month" [(ngModel)]="selectedDate" (ngModelChange)="fetchData()">
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
        <img src="./assets/csv.svg" alt="download" />			</button>
    </div>
  </div>

  <div class="col-12 col-md-6 report_selection_block">
    <label class="report_selection_label">Select Broadcaster</label>
    <select class="custom__field report_selection" [(ngModel)]="broadcasterId" (ngModelChange)='fetchData()'>
      <!-- Changed ngValue binding from lco.id to lco.email -->
      <!-- <option value = 0 >ALL</option> -->
      <option *ngFor="let lco of broadcasterList" [ngValue]='lco.id'>
        {{lco.name}}
      </option>
    </select>
  </div>

  <div class="col-12 col-md-6">
    <p>Report generated on : {{currentDate | date:'dd-MM-yy HH:mm:ss'}}</p>
  </div>

  <div class="col-12 list__table">
    <div class="row">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <th>Sl no</th>
            <th>Subscriber Name</th>
            <th>Subscriber Id</th>
            <th>Old Device</th>
            <th>New Device</th>
            <th>Replacement Date</th>
          </tr>
        </thead>
        <tbody>
          <div *ngIf="reportList.length<=0; else showList">
            <!-- <td class="loading" colspan="9"> -->
              <span><h5>No records!</h5></span>
            <!-- </td> -->
          </div>

          <ng-template #showList>
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{j+1}}</td>
              <td>{{report.channelName}}</td>
              <td>{{report.channelNo}}</td>
              <td>{{report.channelId}}</td>
              <td>{{report.subscriptionType}}</td>
              <td>{{report.subscriberCountAt7}}</td>
              <td>{{report.subscriberCountAt14}}</td>
              <td>{{report.subscriberCountAt21}}</td>
              <td>{{report.subscriberCountAt28}}</td>
              <td>{{(report.subscriberCountAt7+report.subscriberCountAt14+
                report.subscriberCountAt21+report.subscriberCountAt28)/4}}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>

