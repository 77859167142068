import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { AddSubscriptionComponent } from './add-subscription/add-subscription.component';
import { EditSubscriptionComponent } from './edit-subscription/edit-subscription.component';
import { RemoveSubscriptionComponent } from './remove-subscription/remove-subscription.component';


const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'add-subscription', component:AddSubscriptionComponent  },
  { path: 'renew-subscription', component:EditSubscriptionComponent  },

  { path: 'remove-subscription', component:RemoveSubscriptionComponent  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionRoutingModule { }
