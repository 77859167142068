import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { OsdMessageInfo } from 'src/app/models/osd/osd-message-info';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PathNames } from 'src/app/constants/path-names';
import { environment } from 'src/environments/environment';
import { OsdInfo } from 'src/app/models/osd/osd-info';
import { ConfigService } from 'src/app/services/config.service';
import { SubscriberSearchType } from 'src/app/subscription/subscriber-search/subscriber-search';
import { MultiSelectType } from 'src/app/plugins/multiselect/multiselect.component';
import { BehaviorSubject } from 'rxjs';
import { LcoSearchComponent } from 'src/app/lco/lco-search/lco-search.component';
import { LcoSearchType } from 'src/app/lco/lco-search/lco-search';

@Component({
  selector: 'app-osd-create',
  templateUrl: './osd-create.component.html',
  styleUrls: ['./osd-create.component.scss'],
})
export class OsdCreateComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) {}

  osdForm: FormGroup;
  osd = {} as OsdInfo;

  messageList: OsdMessageInfo[];

  isEdit = false;
  isSendAllSelected = false;
  ngOnInit(): void {
    this.getPathParam();
    this.loadOsdMessages();
    this.initForm();
    this.getQueryParam();
  }

  id
  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.id= id
      this.isEdit = true;
      this.fetchData(id);
    }
  }
  getQueryParam(): void {
    if (this.route.snapshot.queryParamMap.has('subscriberId')) {
      const id = this.route.snapshot.queryParamMap.get('subscriberId');
      this.osdForm.get('subscriberId').setValue(Number(id));
    } else if (this.route.snapshot.queryParamMap.has('all')) {
      // converting string to boolean
      this.isSendAll.setValue(true);
    }
  }

  loadOsdMessages(): void {
    const url = this.config.json.apiEndpointRdbms + 'osd/message/list';
    this.http.get(url).subscribe((data) => (this.messageList = data.data));
  }

  initForm(): void {
    this.osdForm = new FormGroup({
      id: new FormControl(null),
      subscriberId: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
      osdConfiguration: new FormGroup({
        duration: new FormControl(10000, [Validators.required]),
        startTime: new FormControl(new Date().toISOString().substring(0, 16), [
          Validators.required,
        ]),
        contentIdentifier: new FormControl(0, [Validators.required]),
        transparency: new FormControl(20, [
          Validators.required,
          Validators.min(20),
          Validators.max(80),
        ]),
        fontStyle: new FormControl(0, [Validators.required]),
        fontSize: new FormControl(0, [Validators.required]),
        fontColour: new FormControl(0, [Validators.required]),
        bgColour: new FormControl(0, [Validators.required]),
        forced: new FormControl(0, [Validators.required]),
        scrolling: new FormControl(0, [Validators.required]),
        polarization: new FormControl(0, [Validators.required]),
        marque: new FormControl(0, [Validators.required]),
      }),
      isSendAll: new FormControl(false),
      stbIds: new FormControl([]),
      lcoId: new FormControl(),
    });

    this.listenFormChanges();
  }

  formValidity() {
    // console.log(this.osd?Form.controls);

    return this.osdForm.invalid;
  }

  searchSubscriberStyle = {
    display: 'none',
  };
  searchLCOStyle = {
    display: 'none',
  };
  selectSubscriberGroupStyle = {
    display: 'none',
  };
  selectSendTo(e: Event) {
    let value = (e.target as HTMLSelectElement).value;
    const all = () => {
      this.osdForm.get('isSendAll').setValue(true);
      this.isSendAllSelected = true;
      this.searchSubscriberStyle =
        this.searchLCOStyle =
        this.selectSubscriberGroupStyle =
          {
            display: 'none',
          };
      this.osdForm.get('subscriberId').clearValidators();
      this.osdForm.get('lcoId').clearValidators();
      this.osdForm.get('subscriberId').updateValueAndValidity();
      this.osdForm.get('lcoId').updateValueAndValidity();
    };
    const subscriber = () => {
      this.searchSubscriberStyle = {
        display: 'block',
      };
      this.searchLCOStyle = this.selectSubscriberGroupStyle = {
        display: 'none',
      };
      this.osdForm.get('isSendAll').setValue(false);
      this.isSendAllSelected = false;
      this.osdForm.get('subscriberId').setValidators([Validators.required]);
      this.osdForm.get('lcoId').clearValidators();
      this.osdForm.get('subscriberId').updateValueAndValidity();
      this.osdForm.get('lcoId').updateValueAndValidity();
    };
    const lco = () => {
      this.osdForm.get('lcoId').setValidators([Validators.required]);
      this.osdForm.get('subscriberId').clearValidators();
      this.searchSubscriberStyle = this.selectSubscriberGroupStyle = {
        display: 'none',
      };
      this.searchLCOStyle = {
        display: 'block',
      };
      this.osdForm.get('isSendAll').setValue(false);
      this.isSendAllSelected = false;
      this.osdForm.get('lcoId').updateValueAndValidity();
      this.osdForm.get('subscriberId').updateValueAndValidity();
    };
    const subscribergroup = () => {
      this.getSubscriberGroups();
      this.searchLCOStyle = this.searchSubscriberStyle = {
        display: 'none',
      };
      this.selectSubscriberGroupStyle = {
        display: 'block',
      };
      this.osdForm.get('isSendAll').setValue(false);
      this.isSendAllSelected = false;
      this.osdForm.get('subscriberId').clearValidators();
      this.osdForm.get('lcoId').clearValidators();
      this.osdForm.get('subscriberId').updateValueAndValidity();
      this.osdForm.get('lcoId').updateValueAndValidity();
    };
    switch (value) {
      case 'all':
        all();
        break;
      case 'subscriber':
        subscriber();
        break;
      case 'lco':
        lco();
        break;
      case 'subscribergroup':
        subscribergroup();
        break;
    }
    this.searchText = "",
    this.openSearch = false
  }

  subscriberGroupList: any;
  getSubscriberGroups(): void {
    const url =
      this.config.json.apiEndpointRdbms + 'subscribergroup/getSubscriberGroups';
    this.http.get(url).subscribe((data) => {
      this.subscriberGroupList = data;
    });
  }

  getSubscriberIdsBySubscriberGroupId(e: Event) {
    let id = (e.target as HTMLSelectElement).value;
    let url =
      this.config.json.apiEndpointRdbms +
      `subscribergroup/getSubscriberByGroup/${id}`;
    this.http.get(url).subscribe({
      next: (res) => {
        this.osdForm.get('subscriberId').setValue(res.data.subscriberIds);
        this.subscriberID = res.data.subscriberIds;
        console.log(this.osdForm.value);

        if (res.statusCode == 200) {
        } else {
          alert('No Subscribers!');
        }
      },
      error: () => {
        alert('No Subscribers!');
      },
    });
  }

  searchText: string;
  openSearch = false;
  getUserDetail(e: SubscriberSearchType) {
    let input = document.getElementById('to__address') as HTMLInputElement;
    this.subscriberID = [e.subscriberId];
    this.getSTBList();
    this.osdForm.get('subscriberId').setValue([e.subscriberId]);
    input.value = `${e.subscriberCode}, ${e.subscriberName}`;
    this.openSearch = false;
  }

  getLCODetail(e: LcoSearchType) {
    let input = document.getElementById('lcoId') as HTMLInputElement;
    this.osdForm.get('lcoId').setValue(e.lcoId);
    input.value = `${e.lcoCode}, ${e.lcoName}`;
    this.openSearch = false;
  }

  stbListData: MultiSelectType = {
    dataArray: [],
    keyToDisplay: '',
    idForSelected: '',
  };
  stbList = new BehaviorSubject<MultiSelectType>(this.stbListData);
  getSTBList() {
    const url =
      this.config.json.apiEndpointRdbms +
      'stb/getSTBBySubscriberId/' +
      this.subscriberID[0];
    this.http.get(url).subscribe({
      next: (res) => {
        console.log(res);
        let arr = [];
        for (let ele of res) {
          let obj = {
            name: `Ethernet Mac Address: ${ele['ethernetMac']}, STB ID: ${ele['id']} `,
            stbId: ele['id'],
          };
          arr.push(obj);
        }
        console.log(arr);

        this.stbListData = {
          ...this.stbListData,
          dataArray: arr,
          keyToDisplay: 'name',
          idForSelected: 'stbId',
        };
        this.stbList.next(this.stbListData);
      },
    });
  }

  clear() {
    this.openSearch = false;
    let input = document.getElementById('to__address') as HTMLInputElement;
    input.value = '';
    this.subscriberID = undefined;
    this.osdForm.reset();
  }

  searchValue(e: Event) {
    this.openSearch = false;
    let input = e.target as HTMLInputElement;
    this.searchText = input.value;
    if(this.searchText.length >= 3){
      setTimeout(() => {
        this.openSearch = true;
      }, 300);
    }
  }

  showAllUser(){
    this.openSearch = false
    this.searchText = "ALL"
    setTimeout(() => {
      this.openSearch = true;
    }, 300);
  }

  get transparency(): FormControl {
    const config = this.osdForm.get('osdConfiguration') as FormGroup;
    return config.get('transparency') as FormControl;
  }

  get to(): FormControl {
    return this.osdForm.get('subscriberId') as FormControl;
  }

  get isSendAll(): FormControl {
    return this.osdForm.get('isSendAll') as FormControl;
  }

  fetchData(id: string): void {
    const url = this.config.json.apiEndpointRdbms + 'osd/' + id;
    this.http.get(url).subscribe((data) => {
      this.osd = data.data;
      this.updateForm();
    });
  }

  updateForm(): void {
    let input = document.getElementById('to__address') as HTMLInputElement;
    input.value = this.osd.subscriberId[0];

    this.osdForm.get('subscriberId').setValue(this.osd.id);
    this.osdForm.get('message').setValue(this.osd.osdMessage.id);
    let data = this.osd.osdConfiguration,
      date = new Date(data.startTime);
    let osdConfig = {
      duration: data.duration,
      scrolling: data.scrolling,
      forced: data.forced,
      transparency: data.transparency,
      bgColour: data.bgColour,
      startTime: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(date.getDate()).padStart(2, '0')} ${String(
        date.getHours()
      ).padStart(2, '0')}:${String(date.getMinutes()).padStart(
        2,
        '0'
      )}:${String(date.getSeconds()).padStart(2, '0')}`,
      fontSize: data.fontSize,
      polarization: data.polarization,
      contentIdentifier: data.contentIdentifier,
      fontStyle: data.fontStyle,
      fontColour: data.fontColour,
      marque: data.marque,
    };
    console.log(osdConfig);
    this.osdForm.get('osdConfiguration').setValue(osdConfig);
    if (this.osd.subscriberId === 0) {
      this.isSendAll.setValue(true);
    } else {
      this.osdForm.get('subscriberId').setValue(this.osd.subscriberId);
    }
  }

  persist(): void {
    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    const url = this.getSaveUrl();
    this.http.post(url, this.getSubmitValue()).subscribe((data) => {
      if (data.statusCode == 200) {
        this.router.navigate(['/manage/osd']);
      } else {
        alert(data.message);
      }
    });
  }

  getSaveUrl(): string {
    const isAll = this.isSendAll.value;
    const url = this.config.json.apiEndpointRdbms + 'osd/send';
    return url;
  }

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'osd/update';
    this.http.put(url, this.getSubmitValue()).subscribe((data) => {
      if(data.statusCode==200){
        this.router.navigate(['/manage/osd']);
        }
        else
        {
        window.alert(data.message)
        }
     
    });
  }

  getSubmitValue(): any {
    let arr = [];
    for (let ele of this.osdForm.get('stbIds').value) {
      if (ele['stbId']) {
        arr.push(ele['stbId']);
      }
    }
    const osd = {
      ...this.osdForm.value,
      osdMessage: this.selectedMessageObject,
      subscriberId: this.subscriberID ? [...this.subscriberID] : [],
      stbIds: arr,
    };

    if(this.isEdit){
      osd.id = this.id
    }
    if (this.isSendAll.value) {
      osd.subscriberId = 'ALL';
      osd.stbIds = 'ALL';
    }
    return osd;
  }

  selectedMessageObject;
  selectedMessage(e: Event) {
    let messageId = (e.target as HTMLSelectElement).value;
    for (let ele of this.messageList) {
      if (ele.id == messageId) {
        
        this.selectedMessageObject = ele;
      }
    }
  }

  subscriberID;
  getSubscriberIdBySubscriberCode(e: Event) {
    let subCode = (e.target as HTMLInputElement).value;
    let url =
      this.config.json.apiEndpointRdbms +
      `subscriber/getSubscriberByCode/${subCode}`;
    this.http.get(url).subscribe({
      next: (res: any) => {
        if (res.statusCode == 200) {
          this.subscriberID = [res.data.id];
        } else {
          this.osdForm.setErrors({ isWrongSubscriberCode: true });
          alert('No Subscriber found');
        }
      },
    });
  }

  listenFormChanges(): void {
    this.isSendAll.valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.to.disable();
      } else {
        this.to.enable();
      }
    });
  }
}
