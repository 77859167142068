<div class="cms__content">
  <div class="top__heading">
    <h3>Universal Report</h3>
    <a [routerLink]="['/reports/trai']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="filter__group col-12 col-md-6" data-name="lcoSelect">
      <label for="select_date">Select Date</label>
      <input
        type="month"
        name="select_date"
        id="select_date"
        class="custom__field"
        (input)="dateChange($event)"
      />
    </div>
  </div>
    <div class="row">
    <div class="filter__group col-12 col-md-6 report_selection_block">
      <label class="report_selection_label">Select Broadcaster </label>
      <select class="custom__field report_selection" [(ngModel)]="broadcasterId" (ngModelChange)='broadcasterSelected()'>
        <!-- Changed ngValue binding from lco.id to lco.email -->
        <!-- <option value = 0 >ALL</option> -->
        <option *ngFor="let lco of broadcasterList" [ngValue]='lco.id'>
          {{lco.name}}
        </option>
      </select>
    </div>
    <div class="download__report col-md-12">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <!-- <div style="display: flex; gap:20px" >
      <div class="table__right">
        <p class="table__summary">Total <span>{{expiryReportList.length}}</span> record{{expiryReportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div> -->
  </div>

  <!-- Pagination -->

  <!-- <div class="custom__table__filter">
      <span>
        Show
        <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
          <option
            *ngFor="let size of paginationObj.pageSizeOptions"
            [ngValue]="size"
          >
            {{ size }}
          </option>
        </select>
        Entries
      </span>
      <div class="table__right">
        <p class="table__summary">
          Total <span>{{ paginationObj.totalElements }}</span> records
        </p>
        <div class="search__container">
          <input
            type="text"
            autocomplete="off"
            class="custom__field"
            id="searchKeyword"
            [(ngModel)]="paginationObj.searchKeyword"
            (keyup.enter)="searchKeyWord()"
          />
          <button (click)="searchKeyWord()">
            <img src="./assets/search.png" alt="search" />
          </button>
        </div>
      </div>
    </div> -->

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
            <th>Particular</th>
            <th>7 Days</th>
            <th>14 Days</th>
            <th>21 Days</th>
            <th>28 days</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList?.length == 0 && !loadingFlag">
            <td style="text-align: center !important" colspan="5">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important" colspan="5">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList; let j = index">
              <td>{{ report.typee ? report.typee : "---" }}</td>
              <td>
                {{
                  report.subscriberCountAt7 != null && report.subscriberCountAt7 != undefined ? report.subscriberCountAt7 : "---"
                }}
              </td>
              <td>
                {{
                  report.subscriberCountAt14 != null && report.subscriberCountAt14 != undefined
                    ? report.subscriberCountAt14
                    : "---"
                }}
              </td>
              <td>
                {{
                  report.subscriberCountAt21 != null && report.subscriberCountAt21 != undefined
                    ? report.subscriberCountAt21
                    : "---"
                }}
              </td>
              <td>
                {{
                  report.subscriberCountAt28 != null && report.subscriberCountAt28 != undefined
                    ? report.subscriberCountAt28
                    : "---"
                }}
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <!-- <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div> -->
  </div>
</div>
