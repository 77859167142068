import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import * as moment from 'moment';
import * as dayjs from 'dayjs'
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  selected: NgxDateRangeInfo;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  locale: any = {
    format: 'DD/MM/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'DD/MM/YYYY', // default is format value
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // default is 'Cancel'
    applyLabel: 'Select', // default is 'Apply'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 0 // first day is monday
  };
  maxDate: any;
  @Output('dateChange') dateChangeEvent = new EventEmitter();

  constructor(
    private calendar: ReportCalendarService,
    private store: StoreService
  ) {
    
      this.maxDate = moment()
   }

  ngOnInit(): void {
    // this.setInitialDate();
    console.log(this.maxDate)
    // this.maxDate = dayjs().add(2,  'weeks');
  }

  setInitialDate(): void {
    if (this.store.datepickerPreselectedDate) {
      this.selected = this.store.datepickerPreselectedDate;
      this.dateChangeEvent.emit(this.selected);
    }
  }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      this.calendar.setDate(ev);
      this.store.datepickerPreselectedDate = ev;
      this.dateChangeEvent.emit(ev);
    }
  }

}
