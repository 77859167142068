import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AclCreateComponent } from './acl-create/acl-create.component';
import { AclComponent } from './acl/acl.component';

const routes: Routes = [
  { path: '', redirectTo: 'list' },
  { path: 'list', component: AclComponent },
  { path: ':id', component: AclCreateComponent },
  { path: 'create', component: AclCreateComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccessControlListRoutingModule { }
