<div class="cms__content">
  <div class="top__heading">
    <h3>STB Inventory</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>

  <!-- <div class="filter-download_container">
    <div class="filter_container">
      <div class="div label-container">
        <label class="report_selection_label">Status:</label>
      </div>
      <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="statusValue" (change)="fetchData()" >
        <option value="All">ALL</option>
        <option value="Active">Active</option>
        <option value="Inactive">Inactive</option>
        <option value="Terminated">Terminated</option>
        <option value="Suspended">Suspended</option>
        <option value="Blacklist">Blacklisted</option>
        <option value="Faulty">Faulty</option>
      </select>
      <div class="div label-container">
        <label>Select Manufacturer:</label>
      </div>
      <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="manufacturervalue" (change)="fetchData()">
        <option value="All">ALL</option>
        <option *ngFor="let man of manufactrerList" [ngValue]="lco">
          {{ man.name }}
        </option>
      </select>
    </div>
  </div> -->
  <div class="row row-top">
    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label"> Type: </label>
      <select class="custom__field report_selection" id="statusChangeApi"  [(ngModel)]="statusValue" (change)="fetchData()" >
        <option value="All">ALL</option>
        <option value="Active">Active</option>
        <option value="Inactive">Inactive</option>
        <option value="Terminated">Terminated</option>
        <option value="Suspended">Suspended</option>
        <option value="Blacklist">Blacklisted</option>
        <option value="Faulty">Faulty</option>
      </select>
    </div>

    <div class="col-6 col-md-3 report_selection_block" style="margin-top:1%;">
      <label class="report_selection_label" style="margin-right: 1%;"> Manufacturer: </label>
      <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="manufacturervalue" (change)="fetchData()">
        <option value="All">ALL</option>
        <option *ngFor="let man of manufactrerList" [ngValue]="man">
          {{ man}}
        </option>
      </select>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
          PDF
          <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
          Excel
          <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
          CSV
          <img src="./assets/csv.svg" alt="download" /> </button>
  </div>
    
  </div>
  <div class="row">
    <div class="col-md-6 report-generated-fix">
        <p>
            Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
        </p>
    </div>
    
</div>

  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
            Show
            <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
                <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
            </select>
            Entries
        </span>
        <!-- -->
        <div class="col-12 col-md-6 table-search">
            <div class="table__right">
                <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span>
                    record{{reportList.length == 1?
                    '' : 's'}}</p>
                <!-- <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}} -->
                <!-- </p> -->
            </div>
            <div class="search__container">
                <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()" />
                <button (click)="onSearch()" (keydown.enter)="onSearch()">
                    <img src="./assets/search.png" alt="search" />
                </button>
            </div>
        </div>
    </div>
      <table class="custom__iptv__table" #reportTable id="print">
        <thead>
          <tr>
          <!-- 3. Ethernet  4.Manufacturer 5. Comapny
6. Transaction date 7. status  9. STB Type 10 Faulty Status -->
            <th >SL No.</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.id)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.id}">STB ID</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.ethernetMac)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.ethernetMac}">Ethernet address</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.manufacturer)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.manufacturer}">Manufacturer</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.manufacturer)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.manufacturer}">Company</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.createdDate)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.createdDate}">Transaction Date</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.activationStatus)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.activationStatus}">Status</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.deviceType)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.deviceType}">StB Type</th>
            <th class="label__align" (click)="sortByItem(sortByProperties.faultyStatus)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.faultyStatus}">Faulty Status</th>
      
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList?.length == 0 && !loadingFlag">
            <td style="text-align: center !important" colspan="10">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important" colspan="10">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList; let j = index">
              <td>
                {{
                (paginationObj.pageNumber - 1) * paginationObj.pageSize +
                (j + 1)
                }}
              </td>
              <td>{{ report.id ? report.id : '---' }}</td>
              <td>{{ report.ethernetMac ? report.ethernetMac : '---' }}</td>
              <td>{{ report.manufacturer ? report.manufacturer : '---' }}</td>
              <td>{{ report.manufacturer ? report.manufacturer : '---' }}</td>
              <td>{{ report.createdDate ? report.createdDate : '---' }}</td>
              <td>{{  report.activationStatus == 1 ? 'Active' : 'Inactive' }}</td>
              <td>{{report.deviceType?report.deviceType:'---'}}</td>
              <td>{{ report.faultyStatus == 0 ? report.faultyStatus == 1 ? 'True' : 'False' : '---' }}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disablePreviousBtn" (click)="pageDecrease()">
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span>Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span>
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageIncrease()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>