import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-mails',
  templateUrl: './send-mails.component.html',
  styleUrls: ['./send-mails.component.scss']
})
export class SendMailsComponent implements OnInit {

  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }


  sampleData = [
    {
      'Subscriber Code': 'BEN0000005'
    },
    {
      'Subscriber Code': 'BEN0000003'
    },
  ];

  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'send_mail');
  }

  message: string = ''
  errorAvailable = false
  isError = false
  errorBody = []
  e
  isValidated = false
  selectedFile(e) {
    this.message = ''
    this.e = e
    const url = this.config.json.apiEndpointRdbms + `bulkUpload/validation/mails`;
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.isValidated = true
        } else {
          this.isError = true
          this.errorBody = res.errorBody
        }
        if (res.hasOwnProperty('errorMessage')) {
          this.errorAvailable = false
          alert(res.errorMessage)
        }
      },
      error: (error) => {
        // console.log(error)
        // this.message = error.error.message
      }
    });
  }

  clearInput() {
    let input = document.getElementById('to') as HTMLInputElement
    this.excelgen.deselectSelectedFiles(input)
    this.isValidated = false
    this.isBody = false
    this.isSend = false
  }

  subjectMessage
  isBody = false
  subject() {
    this.subjectMessage = (document.getElementById('subject') as HTMLInputElement).value
    if (this.subjectMessage != '') {
      this.isBody = true
    } else {
      this.isBody = false
    }
  }

  bodyMessage
  isSend = false
  body() {
    this.bodyMessage = (document.getElementById('body_') as HTMLTextAreaElement).value
    if (this.bodyMessage != '') {
      this.isSend = true
    } else {
      this.isSend = false
    }
  }

  submit() {
    let file = this.e.target.files[0]
    let formData = new FormData()
    formData.append('file', file)
    let mailRequest = {
      subject: this.subjectMessage,
      body: this.bodyMessage,
      subscriberId: 0
    }
    formData.append('mailRequest', JSON.stringify(mailRequest))
    let url = this.config.json.apiEndpointRdbms + 'bulkUpload/sendMail'
    this.http.post(url, formData).subscribe({
      next: (res) => {
        this.router.navigate(['/communication/mail/list'])
      }
    })
  }
}
