import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-create-bouquet',
  templateUrl: './create-bouquet.component.html',
  styleUrls: ['./create-bouquet.component.scss']
})
export class CreateBouquetComponent implements OnInit {

  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
  }

  sampleData = [
    {
      'BouquetName': 'onnetPack1',
      'BouquetType': 'MSO Bouquet',
      'PackType': 'Base',
      'BroadcasterName': 'MSO',
      'ChannelsName': 'VH1 HD1,CNBC Prime HD,Aaj Tak HD1',
      'Description': 'inserted on 19/12/2023',
      'Duration': '3',
      'Price': '599',
    },
    {
      'BouquetName': 'onnetPack2',
      'BouquetType': 'Broadcaster Bouquet',
      'PackType': 'Add-on',
      'BroadcasterName': 'TestingBroadcaster',
      'ChannelsName': 'Nickelodeon-Nick Junior,DD SPORTS1',
      'Description': 'broadcaster bouquet',
      'Duration': '12',
      'Price': '1',
    },
    {
      'BouquetName': 'onnetPack3',
      'BouquetType': 'MSO BOUQUET',
      'PackType': 'Alacarte pack',
      'BroadcasterName': 'MSO',
      'ChannelsName': 'Times Now World',
      'Description': 'Testing Bouquet',
      'Duration': '1',
      'Price': '0',
    },

  ];

  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'create_bouquet')
  }

  isSubmitReady = false;
  message: string = ''
  errorBody = []
  errorAvailable = false
  e
  selectedFile(e) {
    this.message = ''
    this.e = e
    const url = this.config.json.apiEndpoint + 'bulkUpload/validation/bouquets';
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.isSubmitReady = true
          this.errorAvailable = false
        } else {
          this.errorAvailable = true
          this.errorBody = res.errorBody
        }
        if (res.result == 'wrongSheet') {
          this.isSubmitReady = false
          this.errorAvailable = false
          this.message = res.message
        }
      },
      error: (error) => {
        this.message = error.error.message
      }
    });
  }

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement
    this.excelgen.deselectSelectedFiles(input)
    this.isSubmitReady = false
  }

  successMessage = 'No errors Found'
  uploadFile() {
    const url = this.config.json.apiEndpoint + 'bulkUpload/bouquets';
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message
        } else {
          this.errorAvailable = true
          this.successMessage = res.message
          this.errorBody = res.errorBody
        }
      }
    })
  }
}
