import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SearchPipe } from '../pipes/search.pipe';
import { PluginsModule } from '../plugins/plugins.module';
import { AdminAccessDirective } from '../directives/admin-access.directive';
import { SuperAdminAccessDirective } from '../directives/super-admin-access.directive';
import { ClickOutsideCustomDirective } from '../directives/click-outside-custom.directive';
import { DistributorAccessDirective } from '../directives/distributor-access.directive';
import { TruncatePipePipe } from '../pipes/truncate-pipe.pipe';

@NgModule({
  declarations: [
    SearchPipe,
    TruncatePipePipe,
    AdminAccessDirective,
    SuperAdminAccessDirective,
    ClickOutsideCustomDirective,
    DistributorAccessDirective
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, PluginsModule],
  exports: [
    SearchPipe,
    TruncatePipePipe,
    ReactiveFormsModule,
    FormsModule,
    PluginsModule,
    AdminAccessDirective,
    SuperAdminAccessDirective,
    ClickOutsideCustomDirective,
    DistributorAccessDirective
  ],
})
export class SharedModule {}
