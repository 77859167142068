import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-report-subscriber-device-replacement',
  templateUrl: './report-subscriber-device-replacement.component.html',
  styleUrls: ['./report-subscriber-device-replacement.component.scss']
})
export class ReportSubscriberDeviceReplacementComponent implements OnInit {

  reportList = [];
  bouquetType = 0;
  broadcasterId: number;

  broadcasterList: BroadcasterInfo[] = [];

  selectedDate: any;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    this.fetchLocationList();
  }

  fetchLocationList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }

  fetchData(): void {
    //// console.log(this.broadcasterId, this.selectedDate)
    if (this.selectedDate && this.broadcasterId) {
      const body = {
        "monthYear": this.selectedDate + '-01',
        broadcasterId: this.broadcasterId
      }
      this.report.getBroadcasterWiseChannelsReportMonthly(body).subscribe(data => {
        // //// console.log(data)
        this.reportList = data;
      });
    }
  }

  downloadTableExcel(): void {
    let name='Subscriber Devioce Replacement Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'subscriber_device_replacement');
  }

  downloadTablePdf(): void {
    const tempDate = new Date(this.selectedDate);
    const monthYear = tempDate.toLocaleString('default', {month:'long'}) + " " + tempDate.getFullYear();
    this.pdf.generatePdfHtml('#reportTable',
    'broadcaster_channel_wise_report',
    `Month: ${monthYear}     Broadcaster ID: ${this.broadcasterId}`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Channel Name","Channel LCN","Channel ID","Subscription Type","Subscriptions on 7th","Subscriptions on 14th","Subscriptions on 21st","Subscriptions on 28th",
    ,"Average Subscription"]
    };
    let data = []

    this.reportList.forEach(line => {
      let csvLine = {
        channelName: line.channelName,
        channelNo:line.channelNo,
        channelId: line.channelId,
        subscriptionType: line.subscriptionType,
        subscriberCountAt7: line.subscriberCountAt7,
        subscriberCountAt14: line.subscriberCountAt14,
        subscriberCountAt21: line.subscriberCountAt21,
        subscriberCountAt28: line.subscriberCountAt28,
averageSubscription:(line.subscriberCountAt7+line.subscriberCountAt14+
  line.subscriberCountAt21+line.subscriberCountAt28)/4

      }
      data.push(csvLine);
    });

    new ngxCsv(this.reportList, 'broadcaster_channel_wise_report', options);}

}
