import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { empty } from 'rxjs';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';


@Component({
  selector: 'app-report-bouquet-subscription-count',
  templateUrl: './report-bouquet-subscription-count.component.html',
  styleUrls: ['./report-bouquet-subscription-count.component.scss']
})
export class ReportBouquetSubscriptionCountComponent implements OnInit {

  reportList = [];
  bouquetType = 0;
  broadcasterId = 0;
  from:any;
  to:any;

  currentDate = new Date();

  broadcasterList: BroadcasterInfo[] = [];

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config : ConfigService,
    private http : HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    this.fetchLocationList();
  }

  fetchLocationList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }

  isLoad = 'Choose Date'
  isLoading = false
  fetchData(from: any, to: any): void {
    this.valueEditor()
    const body = {
      bouquetType: this.bouquetType,
      broadcasterId: this.broadcasterId,
      'searchKeyword':''
    }
    this.report.getBouquetSubscriptionCountReport(from, to, body).subscribe(data => {
      this.reportList = data;
      this.isLoading = true
      //// console.log(this.reportList)
      if(this.reportList.length == 0){
        this.isLoading = false
        this.isLoad = 'No Records!!'
      }
    },()=>{
      this.isLoading = false
      this.isLoad = 'No Records!!'
    });
  }

  lcoSelect(): void {
    this.isLoading = true
    this.isLoad = 'Loading'
    this.fetchData(this.calendar.getDate()['startDate'], this.calendar.getDate()['endDate']);
  }

  reportDate
  eve
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.eve = ev
      this.reportDate = ev.startDate.date()+"-"+this.doc.monthNameCalculator(ev.startDate.month())+"-"+ev.startDate.year()+" to "+ev.endDate.date()+"-"+this.doc.monthNameCalculator(ev.endDate.month())+"-"+ev.endDate.year()
      this.fetchData(ev.startDate, ev.endDate);
      this.calendar.setDate(ev);
    }
  }

  filterKey
  filterValue
  valueEditor(){
    if(this.bouquetType == 0){
      this.filterKey = "Broadcaster Type"
      this.filterValue = "MSO"
    }else{
      this.filterKey = "Broadcaster Name :"
      for(let ele of this.broadcasterList){
        if(ele.id == this.broadcasterId){
          this.filterValue = ele.name
        }
      }
      if(this.broadcasterId == 0) this.filterValue ='All'

      // this.broadcasterId==0 ?  : null;
    }
  }

  downloadTableExcel(): void {
    let name=' Report Bouquet Subscription Count  Report'
    this.doc.exportTableToExcelwithImage(this.reportTable, name,'bouquet_subscription_count_report',undefined,undefined,"Report Date :",this.reportDate, this.filterKey, this.filterValue);
  }

  downloadTablePdf(): void {
    if(this.reportList == undefined || this.reportList == null || this.reportList?.length == 0){
      alert('No data')
      return;
    }
    if(!this.formatDate(this.from)){
      this.pdf.generatePdfHtml('#reportTable', 'bouquet_subscription_count_report', `Till now    Source Type : SMS`)
      return;
    }
    this.pdf.generatePdfHtml('#reportTable',
    'bouquet_subscription_count_report',
    `From Date: ${this.formatDate(this.from)}     To Date: ${this.formatDate(this.to)},     Bouquet Type: ${this.bouquetType==0? 'MSO' : 'Broadcaster'},  Source Type : SMS`
    );
  }
  // Broadcaster ID: ${this.broadcasterId}
  formatDate(ele) {
    if(ele == undefined){
      return false;
    }
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No","Bouquet ID","Bouquet Name ","Bouquet Type", "Subscription Count"]
    };
    let data = []
    let i = 1;
    this.reportList.forEach(line => {
      let csvLine = {
        slno : i,
        bouquetId: line.bouquetId,
        bouquetName: line.bouquetName,
        bouquetType: line.bouquetType,
        subscriptionCount: line.subscriptionCount,
      }
      i += 1 ;
      data.push(csvLine);
    });

    new ngxCsv(data, 'bouquet_subscription_count_report', options);
  }

  searchKeyword(){
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms + 'reports/getBouquetSubscriptionCountReport'
    let body = {
      'fromDate' : this.eve.startDate.format('YYYY-MM-DD'),
      'toDate' : this.eve.endDate.format('YYYY-MM-DD'),
      'bouquetType': this.bouquetType,
      'broadcasterId': this.broadcasterId,
      'searchKeyword' : value
    }
    this.http.post(url,body).subscribe(data => {
      this.reportList = data;
      this.isLoading = true
      //// console.log(this.reportList)
      if(this.reportList.length == 0){
        this.isLoading = false
        this.isLoad = 'No Records!!'
      }
    },()=>{
      this.isLoading = false
      this.isLoad = 'No Records!!'
    })

  }
}
