import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-report-stb-status',
  templateUrl: './report-stb-status.component.html',
  styleUrls: ['./report-stb-status.component.scss']
})
export class ReportStbStatusComponent implements OnInit {

  reportList = [];
  from:any;
  to:any;

  currentDate = new Date();




  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }

  ngOnInit(): void {
    //// console.log(this.reportList)
  }

  fetchData(from: any, to: any): void {
    this.report.getStbStatusReport(from, to, {}).subscribe(data => {
      //// console.log("called")
      //// console.log(data)
      this.reportList = data;
      if(this.reportList.length != 0){
        this.loadingFlag=false
      }else{
        this.loadingFlag=false
      this.isLoad='No Records'
      }
    },()=>{
      this.loadingFlag=false
      this.isLoad='No Records'
    });
  }
  loadingFlag=false
  isLoad = 'Choose Date'
  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.calendar.setDate(ev);
      // //// console.log(ev)
      // //// console.log(ev.startDate, ev.endDate)
      this.fetchData(ev.startDate, ev.endDate);
      this.loadingFlag = true
      this.isLoad = 'Loading'
    }
  }

  downloadTableExcel(): void {
    let name='STB Status Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'stb_status_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable',
    'stb_status_report',
    `From Date: ${this.from.toISOString().slice(0,10)}     To Date: ${this.to.toISOString().slice(0,10)},   Source Type : SMS`
    );
  }

  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Total", "Active", "Inactive","Blacklisted","Whitelisted","Fresh","Assigned","Unassigned","Working","Repairable","Unrepairable",]
    };
    let data = []
    this.reportList.forEach(line => {
      let csvLine = {
        activeSTBs: line.activeSTBs,
        totalSTBs: line.totalSTBs,
        inActiveSTBs: line.inActiveSTBs,
        blacklistedSTBs: line.blacklistedSTBs,
        whitelistedSTBs: line.whitelistedSTBs,
        freshSTBs: line.freshSTBs,
        assignedSTBs: line.assignedSTBs,
        unAssignedSTBs: line.unAssignedSTBs,
        workingSTBs: line.workingSTBs,
        repairableSTBs: line.repairableSTBs,
        unRepairableSTBs: line.unRepairableSTBs,
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'stb_status_report', options);
  }
}
