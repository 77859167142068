import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { CoreRoutingModule } from './core-routing.module';
import { CoreComponent } from './core/core.component';
import { NavigationModule } from '../navigation/navigation.module';

import { UserIdleModule } from 'angular-user-idle';
import { MSOLoginAsLCOComponent } from './mso-login-lco';
import { MSOLoginAsDistributorComponent } from './mso-login-distributor';

@NgModule({
  declarations: [CoreComponent, MSOLoginAsLCOComponent,MSOLoginAsDistributorComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    NavigationModule,
    HttpClientModule,
    // UserIdleModule.forRoot({idle: 10, timeout: 10, ping: 10})
    UserIdleModule.forRoot({ idle: 3600, timeout: 3600, ping: 3600 }),
  ],
  exports: [CoreComponent],
})
export class CoreModule {}
