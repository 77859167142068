import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-ota-manager',
  templateUrl: './ota-manager.component.html',
  styleUrls: ['./ota-manager.component.scss']
})
export class OtaManagerComponent implements OnInit {

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm()
  }

  otaForm: FormGroup
  initForm() {
    this.otaForm = new FormGroup({
      versionNumber: new FormControl('', [Validators.required]),
      releaseNotes: new FormControl(''),
      uploadedBy: new FormControl(`${(this.authService.getRole() as string).toLocaleLowerCase()}-${this.authService.getLcoId()}`),
      device: new FormControl('', [Validators.required]),
      environment: new FormControl('null', [Validators.required]),
      stbType: new FormControl('null', [Validators.required]),
      fileR:new FormControl('',[Validators.required]),
    })
  }

  get versionNumber(): FormControl {
    return this.otaForm.get('versionNumber') as FormControl
  }
  get fileR(): FormControl {
    return this.otaForm.get('fileR') as FormControl
  }
  get device(): FormControl {
    return this.otaForm.get('device') as FormControl
  }

select = false
  environmentSelected(){
    this.select = true
    return this.otaForm.get('environment').value == "null"
  }

  otaFormValidity():boolean{
    return !this.otaForm.valid
  }


  apkLocation;
  isApkUploadLoadingFlag = false
  uploadFile(e: Event) {
    this.isApkUploadLoadingFlag = true
    let file = (e.target as HTMLInputElement).files[0],
      formData = new FormData(),
      url = `${this.config.json.apiEndpointRdbms}app/upload`
    formData.append('app', file)
    this.http.post(url, formData).pipe(finalize(() => {
      formData = new FormData()
    })).subscribe({
      next: (res) => {
        this.isApkUploadLoadingFlag = false
        if(res.statusCode != 200){
          this.otaForm.setErrors({uploadFileError : true})
          alert(res.desc)
          return;
        }
        this.apkLocation = res.data
        this.fileR.setValue(res.data)
      },
      error : (err) =>{
        this.otaForm.setErrors({uploadFileError : true})
        alert(err.message)
      }
    })
  }

  save() {
    let url = `${this.config.json.apiEndpointRdbms}app/add`,
      obj = {
        ...this.otaForm.value,
        apkLocation : this.apkLocation
      }
    this.http.post(url, obj).subscribe({
      next: (res) => {
        if(res.status === 200) {
          this.router.navigate(['/settings/ota-manager/list'])       
        }else{
          alert(res.desc)
        }
      }
    })
  }
}
