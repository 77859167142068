import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BroadcasterRoutingModule } from './broadcaster-routing.module';
import { ListBroadcasterComponent } from './list-broadcaster/list-broadcaster.component';
import { CreateBroadcasterComponent } from './create-broadcaster/create-broadcaster.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [ListBroadcasterComponent, CreateBroadcasterComponent],
  imports: [
    CommonModule,
    BroadcasterRoutingModule,
    SharedModule,
    NgMultiSelectDropDownModule,
    FormsModule
  ]
})
export class BroadcasterModule { }
