import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OsdCreateComponent } from './osd-create/osd-create.component';
import { OsdListComponent } from './osd-list/osd-list.component';
import { OsdMessageComponent } from './osd-message/osd-message.component';

const routes: Routes = [

  { path: '', component: OsdListComponent },
  { path: 'message', component: OsdMessageComponent },
  { path: 'create', component: OsdCreateComponent },
  { path: ':id', component: OsdCreateComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OsdRoutingModule { }
