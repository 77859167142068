import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assign-subscribers-to-lco',
  templateUrl: './assign-subscribers-to-lco.component.html',
  styleUrls: ['./assign-subscribers-to-lco.component.scss'],
})
export class AssignSubscribersToLCOComponent implements OnInit {
  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService,
    private http: HttpClientWrapperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getLco();
  }

  lcoList;
  getLco() {
    let url = this.config.json.apiEndpoint + 'lco/getLCOs';
    this.http.get(url).subscribe({
      next: (res) => {
        this.lcoList = res.data;
      },
    });
  }
  sampleData = [
    {
      'First Name': 'Ajay',
      'Last Name': 'k',
      Gender: 'M',
      'Mobile Number': '8776500121',
      'Alternate Number': '8776500121 ',
      Email: 'ajay@yahoo.com',
      'Billing Address': 'managala Pg',
      'Installation Address': 'managala Pg',
      Locality: 'peenya',
      Pincode: '560010',
    },
    {
      'First Name': 'Chetan',
      'Last Name': 'M',
      Gender: 'M',
      'Mobile Number': '9887611232',
      'Alternate Number': '9887611232',
      Email: 'chetan@gmail.com',
      'Billing Address': 'Hoskote',
      'Installation Address': 'Hoskote',
      Locality: 'Hoskote',
      Pincode: '560110',
    },
  ];

  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'assign_subscribers_to_lco');
  }

  selectedLco;
  optionSelected = false;
  selectOption() {
    this.optionSelected = true;
  }

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement;
    this.excelgen.deselectSelectedFiles(input);
    this.isSubmitReady = false;
  }

  isSubmitReady = false;
  message: string = '';
  errorBody = [];
  errorAvailable = false;
  e;
  selectedFile(e) {
    this.message = '';
    this.e = e;
    const url =
      this.config.json.apiEndpointRdbms +
      `bulkUpload/validation/subscribers/${this.selectedLco.lcoCode}`;
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        // // console.log(res)
        if (res.result) {
          this.isSubmitReady = true;
          this.errorAvailable = false;
        } else {
          this.errorAvailable = true;
          this.errorBody = res.errorBody;
        }
        if (res.hasOwnProperty('errorMessage')) {
          this.errorAvailable = false;
        }
      },
      error: (error) => {
        this.isSubmitReady = false;
        this.message = error.error.message;
      },
    });
  }

  successMessage = 'No errors Found';
  uploadFile() {
    const url =
      this.config.json.apiEndpointRdbms +
      `bulkUpload/subscribers/${this.selectedLco.lcoCode}`;
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message;
          this.router.navigate(['/manage/subscriber', 'list']);
        } else {
          this.errorAvailable = true;
          this.successMessage = res.message;
          this.errorBody = res.errorBody;
        }
      },
    });
  }
}
