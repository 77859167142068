import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateLcoComponent } from 'src/app/lco/create-lco/create-lco.component';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-create-distributor',
  templateUrl: './create-distributor.component.html',
  styleUrls: ['./create-distributor.component.scss'],
})
export class CreateDistributorComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClientWrapperService,
    private store: StoreService,
    private config: ConfigService
  ) { }

  isEdit = false;
  id;

  newPassword = true;
  confirmPassword = true;
  isPasswordSame = true;
  password;

  @Output() distributorDetails = new EventEmitter<DistributorType>();
  @Input() distributor!: any;

  passwordStringMatch(): boolean {
    var myRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
    if (myRegex.test(this.distributorForm.get('password').value)) {
      this.distributorForm.get('password').setErrors(null)
      return true
    } else {
      this.distributorForm.get('password').setErrors({ pattern: false })
      return false
    }
  }
  ngOnInit(): void {
    // localStorage.getItem(distributor")
    console.log(this.distributor)
    this.initForm();
    this.locationList();
    this.route.params.subscribe((res) => {
      console.log(res.id)
      if (res.id!==undefined) {
        this.id = res.id;
        this.updateUser(res.id);
        this.isEdit = true;
        console.log(this.isEdit)
        this.distributorForm.get('password').clearValidators();
        this.distributorForm.get('confirmPassword').clearValidators();
        this.distributorForm.get('confirmPassword').updateValueAndValidity();
        this.distributorForm.get('password').updateValueAndValidity();
      }
    });
  }

  updateUserData;
  updateUser(id) {
    let url = this.config.json.apiEndpointRdbms + 'distributor/' + id;
    this.http.get(url).subscribe({
      next: (res) => {
        this.updateUserData = res.data;
        this.updateForm(res.data);
      },
    });
  }

  get passwordValidity() {
    return this.distributorForm.get('password') as FormControl
  }

  updateForm(data) {
    this.distributorForm.get('name').patchValue(data.name);
    this.distributorForm.get('mobileNumber').patchValue(data.mobileNumber);
    this.distributorForm.get('email').patchValue(data.email);
    this.distributorForm.get('status').patchValue(data.status);
    this.distributorForm.get('locationId').patchValue(data.locationId);
    this.distributorForm.get('address').patchValue(data.address);
    this.distributorForm.get('percentage').patchValue(data.percentage);
    this.distributorForm.get('password').patchValue(data.password);
    this.distributorForm.get('confirmPassword').patchValue(data.confirmPassword);
    this.password = data.password;
  }

  distributorForm: FormGroup;
  initForm() {
    this.distributorForm = new FormGroup(
      {
        name: new FormControl('', [Validators.required]),
        mobileNumber: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        status: new FormControl('1', [Validators.required]),
        locationId: new FormControl('', [Validators.required]),
        address: new FormControl('', [Validators.required]),
        percentage: new FormControl(0),
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      { validators: this.passwordMatchValidator }
    );
    if (this.distributor) {
      this.updateForm(this.distributor);
      // this.updateUser(this.distributor);
    }
  }

  isConfirmPasswordError = false;
  passwordMatchValidator: ValidatorFn = (control: AbstractControl) => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    if (!password || !confirmPassword) {
      return null;
    }
    if (password.touched) {
      console.log(password.value == confirmPassword.value);
      if (password.value == confirmPassword.value) {
        this.isConfirmPasswordError = false;
      } else {
        this.isConfirmPasswordError = true;
      }
    }
    return password.value === confirmPassword.value
      ? null
      : this.isEdit
        ? null
        : { mismatch: true };
  };

  showPasswordField = true;
  showConfirmPasswordField = true;
  passwordChanges() {
    this.distributorForm
      .get('confirmPassword')
      .valueChanges.subscribe((value: string) => {
        if (
          this.distributorForm.get('password').value ==
          this.distributorForm.get('confirmPassword').value
        ) {
          this.distributorForm.get('password').setErrors({ mismatch: true });
        } else {
          this.distributorForm.get('password').setErrors(null);
        }
      });
    // this.distributorForm.get('confirmPassword').valueChanges.subscribe((value: string) => {
    //   this.distributorForm.get('password').patchValue(value);
    // });
  }

  formCalculated() {
    return this.distributorForm.invalid;
  }

  location = [];
  locationList() {
    let fetchLocationList = () => {
      const url = this.config.json.apiEndpointRdbms + 'location/getLocations';
      return this.http.get(url);
    }
    fetchLocationList().subscribe({
      next: (res: any) => {
        this.location = res;
      },
    });
  }


  checkConfirmPassword() {
    let pass = (document.getElementById('password') as HTMLInputElement).value;
    let passC = (document.getElementById('passwordC') as HTMLInputElement)
      .value;

    if (pass === passC) {
      this.isPasswordSame = true;
      this.password = pass;
    } else {
      this.isPasswordSame = false;
    }
  }

  isMobileNumberInvalid = false;
  checkMobileNumberFormat(e: Event) {
    let value = (e.target as HTMLInputElement).value;
    let phoneRegex: RegExp = /^[6-9]\d{9}$/;
    if (phoneRegex.test(value)) {
      console.log('pass');
      this.isMobileNumberInvalid = false;
      this.distributorForm.get('mobileNumber').setErrors(null);
    } else {
      this.isMobileNumberInvalid = true;
      this.distributorForm.get('mobileNumber').setErrors({ required: true });
    }
  }

  update() {
    let user = JSON.parse(JSON.stringify(this.distributorForm.value));
    user['password'] = this.updateUserData.password;
    const url = this.config.json.apiEndpointRdbms + 'distributor/update';
    user = {
      ...this.updateUserData,
      ...user,
      percentage: 0,
    };
    console.log(user);
    this.distributorDetails.emit(user);
  }

  getFormData() {
    const url = this.config.json.apiEndpointRdbms + 'distributor/create';
    let body = JSON.parse(JSON.stringify(this.distributorForm.value));
    body['password'] = this.password;

    this.distributorDetails.emit(body);
  }
}

export type DistributorType = {
  name: string;
  mobileNumber: string;
  email: string;
  status: string;
  locationId: string;
  address: string;
  percentage: number;
  password: string;
};
