import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomPreloadingService } from './services/custom-preloading.service';
import { SelfregistrationComponent } from './selfregistration/selfregistration.component';
import { LeadgenerationPaymentSuceessComponent } from './leadgeneration-payment-suceess/leadgeneration-payment-suceess.component';
import { LeadgenerationPaymentFailureComponent } from './leadgeneration-payment-failure/leadgeneration-payment-failure.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./core/core.module').then(m => m.CoreModule) },
  {path:'self-registration',component:SelfregistrationComponent},
  {path:'leadGenerationPaymentSuccess',component:LeadgenerationPaymentSuceessComponent},
  {path:'leadGenerationPaymentFailure',component:LeadgenerationPaymentFailureComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingService }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

