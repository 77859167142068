<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Batch Logs</h3>
    <a [routerLink]="['/logs']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="clearfix"></div>

  <div class="row">
    <div class="download__report col-12">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>

  <div class="col-12 report">
    <p>
      Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
    </p>
    <div class="table__right">
      <p class="table__summary">
        Total <span>{{ reportList.length }}</span> record{{
          reportList.length == 1 ? "" : "s"
        }}
      </p>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Type</th>
            <th>Status</th>
            <th>Uploaded By</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngIf="reportList.length ==0 && !loadingFlag">
            <td style="text-align: center !important;" colspan="5">{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="5">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr> -->
          <!-- <ng-template #showList> -->
          <tr *ngFor="let report of reportList; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ report.type ? report.type : "---" }}</td>
            <td>{{ report.status ? report.status : "---" }}</td>
            <td>{{ report.uploaded_by ? report.uploaded_by : "---" }}</td>
            <td>{{ report.date | date : "dd-MM-yyyy HH:mm:ss" : "UTC" }}</td>
          </tr>
          <!-- </ng-template> -->
        </tbody>
      </table>
    </div>
  </div>
</div>
