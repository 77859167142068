import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocationInfo } from 'src/app/models/location/location-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';


@Component({
  selector: 'app-report-stb-location-wise',
  templateUrl: './report-stb-location-wise.component.html',
  styleUrls: ['./report-stb-location-wise.component.scss', '../report-filters.scss']
})
export class ReportStbLocationWiseComponent implements OnInit {

  reportList = [];
  selectedLocation = '';
  from: any;
  to: any;

  locationList: LocationInfo[] = [];
  locationLocality = '';
  locationCity = '';

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchLocationList();
    this.selectedLocation = '0'
    this.status = '5'
    this.locationName = 'All'
  }

  fetchLocationList(): void {
    this.report.getLocationList().subscribe(data => {
      // //// console.log(data)
      this.locationList = data;
      // // console.log(this.locationList)
      this.fetchDatas(0,5)
    });
  }

  fetchData(from: any, to: any): void {
    // const dat = this.selectedLocation.split('-');
    // const body = {
    //   city: dat[1],
    //   locality: dat[0]
    // }
    // this.report.getStbLocationWiseReport(from, to, body).subscribe(data => {
    //   this.reportList = data;
    //   if(this.reportList.length != 0){
    //     this.loadingFlag=false
    //   }else{
    //     this.loadingFlag=false
    //   this.isLoad='No Records'
    //   }
    // },()=>{
    //   this.loadingFlag=false
    //   this.isLoad='No Records'
    // });
    //// console.log()
    this.http.post(`${this.config.json.apiEndpointRdbms}reports/getStbLocationWiseReport`, { "city": "North Delhi" }).subscribe((data) => {
      // //// console.log(data)
    })
  }

  loadingFlag = false
  isLoad = 'Loading'
  pinCode = ''
  state = ''
  lcoSelect(): void {
    let city = document.getElementById('lco') as HTMLSelectElement | undefined
    // // console.log(city.value)
    if (city.value == '0') {
      this.state = ''
      this.pinCode = ''
      this.locationName = '0'
      this.fetchDatas('0',this.status)
    } else {
      let cityName = this.selectedLocation.split('-')[0]
      this.locationName = cityName
      this.state = this.selectedLocation.split('-')[1] != 'null'? this.selectedLocation.split('-')[1] : ''
      this.pinCode = this.selectedLocation.split('-')[2]  != 'null'? this.selectedLocation.split('-')[2] : ''
      // // console.log(this.selectedLocation)
      // if(cityName != '0'){
      //   this.locationName = cityName
      // }else{
      //   this.locationName = 'All'
      // }
      this.fetchDatas(cityName,this.status)
      this.reportList = []
      this.loadingFlag = true
    }
  }


  status
  statusChange(){
    let val = (document.getElementById('status') as HTMLSelectElement).value
    this.status = val
    // console.log(this.status)
    this.fetchDatas(this.locationName,this.status)
    // this.fetchDatas(this.cityName, this.status )
    // let url = this.config.json.apiEndpointRdbms+ `subscriber/getsubscribersListReport/?status=${val}&searchKeyword=${this.searchWord? this.searchWord : ''}`
    // this.http.get(url).subscribe((data)=>{
    //   this.reportList = []
    //   this.reportList = data.data
    //   if(this.reportList.length != 0){
    //     this.loadingFlag=false
    //   }else{
    //     this.loadingFlag=false
    //   this.isLoad='No Records'
    //   }
    // },()=>{
    //   this.loadingFlag=false
    //   this.isLoad='No Records'
    // })
  }

  getStatusValue(param){
    switch (param) {
        case 0: return 'Inactive'
        case 1: return 'Active'
        case 2: return 'Blacklisted'
        case 3: return 'New'
        case 4: return 'Terminated'
    }
  }

  fetchDatas(cityName, status?) {
    //// console.log(cityName)
    this.loadingFlag = true;
    let body = {
      'city': cityName,
      'searchKeyword': '',
      'pincode': this.pinCode,
      'state': this.state,
      'status': status
    }
    this.report.getStbLocationWiseReports(body).subscribe((data) => {
      // //// console.log(data)
      this.reportList = data.data
      if (this.reportList.length != 0) {
        this.loadingFlag = false
      } else {
        this.loadingFlag = false
        this.isLoad = 'No Records'
      }
    }, () => {
      this.loadingFlag = false
      this.isLoad = 'No Records'
    })
  }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      [this.from, this.to] = [ev.startDate, ev.endDate];
      this.fetchData(ev.startDate, ev.endDate);
      this.calendar.setDate(ev);
      this.loadingFlag = true
      this.isLoad = 'Loading'
    }
  }

  locationName;

  downloadTableExcel(): void {
    let name = 'Stb Location  Report'
    this.doc.exportTableToExcelwithImage(this.reportTable, name, 'stb_location-wise_report', undefined, undefined, "Location : ", this.locationName);
  }

  downloadTablePdf(): void {
    // // console.log(this.from)
    // // console.log(this.to)
    // this.pdf.generatePdfHtml('#reportTable',
    //   'stb_location-wise_report',
    //   `Location: ${this.locationName},  Source Type : SMS`
    // );
    this.pdf.generatePDFForLongTable('STB Location Wise Report',`Location: ${this.locationName},  Source Type : SMS`,1600)
  }


  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Sl No.", "Subscriber Name", "STB MAC", "Subscriber Code", "Mobile Number", 'City','Subscriber Status']
    };
    let data = []
    let i = 1
    this.reportList.forEach(line => {
      let csvLine = {
        slNo: i,
        fullName: line.fullName,
        ethernetMac: line.ethernetMac,
        subscriberCode: line.subscriberCode,
        mobileNumber: line.mobileNumber,
        city: line.city,
        subscriber_status : this.getStatusValue(line.subscriber_status)
      }
      i += 1
      data.push(csvLine);
    });

    new ngxCsv(data, 'stb_location-wise_report', options);
  }

  searchKeyword() {
    let value = (document.getElementById('searchKeyword') as HTMLInputElement).value
    let url = this.config.json.apiEndpointRdbms + 'reports/getStbLocationWiseReport'
    let body = {
      "city": this.locationName,
      'searchKeyword': value,
      'pincode': this.pinCode,
      'state': this.state,
      'status': this.status
    }
    this.http.post(url, body).subscribe(data => {
      this.reportList = []
      this.reportList = data.data;
      //// console.log(this.reportList)
      if (this.reportList.length != 0) {
        this.loadingFlag = false
      } else {
        this.loadingFlag = false
        this.isLoad = 'No Records'
      }
    }, () => {
      this.loadingFlag = false
      this.isLoad = 'No Records'
    });
  }

}
