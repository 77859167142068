import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { FormGroup, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { PathNames } from 'src/app/constants/path-names';
import { environment } from 'src/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin, Observable } from 'rxjs';
import { SubscriberGroupInfo } from 'src/app/models/subscriber-group/subscriber-group-info';
import { SubscriberInfo } from 'src/app/models/subscriber/subscriber-info';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-create-subscriber-group',
  templateUrl: './create-subscriber-group.component.html',
  styleUrls: ['./create-subscriber-group.component.scss']
})
export class CreateSubscriberGroupComponent implements OnInit {

  dropdownList = [];
  selectedList = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    textField: 'name',
  };

  group = {} as SubscriberGroupInfo;

  groupForm: FormGroup;

  isEdit = false;

  subList: SubscriberInfo[] = [];

  lcoId;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private auth: AuthService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.lcoId = this.auth.getLcoId();
    this.getPathParam();
    this.initForm();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.updateFlow(Number(id));
    } else {
      this.createFlow();
    }
  }

  getSubscribersByLcoObs(): Observable<SubscriberInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'subscriber/getSubscribersByLCO/' + this.lcoId;
    return this.http.get(url);
  }

  getSubscriberGroupByIdObs(id: number): Observable<SubscriberGroupInfo> {
    const url = this.config.json.apiEndpointRdbms + 'subscribergroup/getSubscriberGroup/' + id;
    return this.http.get(url);
  }

  createFlow(): void {
    // forkJoin([this.getSubscribersByLcoObs()]).subscribe(data => {
    //   this.subList = data[0];
    //   this.formatSubscriberList();
    // });
  }

  updateFlow(id: number): void {
    forkJoin([this.getSubscriberGroupByIdObs(id)]).subscribe(data => {
      this.group = data[0];
      this.updateForm();
      // this.subList = data[1];
      // this.formatSubscriberList();
    });
  }

  // formatSubscriberList(): void {
  //   this.dropdownList = this.subList.map(sub => {
  //     return {
  //       id: sub.id,
  //       name: sub.firstName + ' ' + sub.lastName
  //     };
  //   });

  //   this.selectedList = this.group.subscribers ? this.group.subscribers.map(sub => {
  //     return {
  //       id: sub.id,
  //       name: sub.firstName + ' ' + sub.lastName
  //     };
  //   }) : [];
  // }


  initForm(): void {
    this.groupForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required]
      }),
      description: new FormControl('', [Validators.required]),
      lcoId: new FormControl(this.lcoId),
      status: new FormControl(1, [Validators.required])
    });
  }

  get name(): FormControl {
    return this.groupForm.get('name') as FormControl;
  }

  get description(): FormControl {
    return this.groupForm.get('description') as FormControl;
  }

  updateForm(): void {
    this.groupForm.get('name').setValue(this.group.name);
    this.groupForm.get('description').setValue(this.group.description);
  }


  persist(): void {
    this.updateModel();
    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'subscribergroup/createSubscriberGroup';

    this.http.post(url, this.group).subscribe(data => {
      this.router.navigate(['/manage/subscriber-groups', 'list']);
    }, error => {
      window.alert(error.error["message"])
    });
  }

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'subscribergroup/updateSubscriberGroup';

    this.http.put(url, this.group).subscribe(data => {
      this.router.navigate(['/manage/subscriber-groups', 'list']);
    },
      error => {
        window.alert(error.error["message"])
      }
    );
  }

  updateModel(): void {
    this.group.name = this.groupForm.get('name').value;
    this.group.description = this.groupForm.get('description').value;
    this.group.lcoId = this.groupForm.get('lcoId').value;

    // this.group.subscribers = this.subList.filter(group => {
    //   return this.selectedList.some(select => {
    //     return group.id === select.id;
    //   });
    // });
  }


  // **************************************************************************************
  // Custom Validators
  // **************************************************************************************

  cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }
    return null;
  }

}