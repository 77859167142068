<div class="cms__content">
	<app-reports-header></app-reports-header>
  <div class="top__heading">

    <h3>Bouquet Subscription Report</h3>
    <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>
<!--
  <div class="row">
    <div class="filter__group col-12 col-md-6" id="date_selector">
      <label for="">Select Date </label>
      <div class="date_box">
        <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
      </div>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
				<img src="./assets/csv.svg" alt="download" />
			</button>
    </div>
  </div>

  <div class="col-12 col-md-6 report_selection_block">
    <label class="report_selection_label">Select Bouquet</label>
    <select class="custom__field report_selection" [(ngModel)]="selectedBouquetId" (change)='bouquetSelect()'>
      <option [ngValue]=0>ALL</option>
      <option *ngFor="let bq of bouquetList" [ngValue]='bq.id'>
        {{bq.name}}
      </option>
    </select>
  </div> -->

  <div class="filter-download_container">
    <div class="filter_container">
      <div class="label-container">
        <label for="">Select Date :</label>
      </div>
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
      <div class="div label-container">
        <label for="">Select Bouquet :</label>
      </div>
      <select class="custom__field report_selection" [(ngModel)]="selectedBouquetId" (change)='bouquetSelect()'>
        <option [ngValue]=0>ALL</option>
        <option *ngFor="let bq of bouquetList" [ngValue]='bq.id'>
          {{bq.name}}
        </option>
      </select>
    </div>
    <div class="download_container">
      <div class="download__report">
        <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
          PDF
          <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
          Excel
          <img src="./assets/excel.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
          CSV
          <img src="./assets/csv.svg" alt="download" />
        </button>
      </div>
    </div>
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6 report-generation__container report">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div style="display: flex; gap:20px" >
      <div class="table__right">
        <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
      </div>
      <div class="search__container">
        <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Subscriber Code</th>
            <th>Subscriber Name</th>
            <th>Bouquet Name</th>
            <th>Bouquet ID</th>
            <th>Mac Address</th>
            <th>Start Date</th>
            <th>End Date</th>
            <!-- <th>Channel Name</th> -->
            <!-- <th>Channel ID</th> -->
            <!-- <th>Channel No</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList?.length ==0 && !loadingFlag" >
            <td style="text-align: center !important;" colspan="8" >{{isLoad}}</td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important;" colspan="8">
            <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <tr *ngIf="selectedBouquetId==null && loadingFlag" >
            <td  colspan="7" style="text-align: center;" >Select Boquet</td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList, let j = index">
              <td>{{j+1}}</td>
              <td>{{report.subscriber_code? report.subscriber_code : '---'}}</td>
              <!-- <td>{{report.subscriber_id}}</td> -->
              <td>{{report.subscriber_name? report.subscriber_name : '---'}}</td>
              <td>{{report.bouquet_name_list? report.bouquet_name_list : '---'}}</td>
              <td>{{report.bouquet_id_list? report.bouquet_id_list : '---'}}</td>
              <td>{{report.ethernet_mac_address? report.ethernet_mac_address : '---'}}</td>
              <td>{{report.start_date | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
              <td>{{report.end_date | date:'dd-MM-yyyy HH:mm:ss':'UTC'}}</td>
              <!-- <td>{{report.channelName}}</td> -->
              <!-- <td>{{report.channelId}}</td> -->
              <!-- <td>{{report.channelNo}}</td> -->
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>
