import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss']
})
export class PaymentErrorComponent implements OnInit {

  constructor(
    private config: ConfigService,
    private http: HttpClientWrapperService,
  ) { }

  ngOnInit(): void {
    this.sendTransactionIdAftersuccess()
    this.config.json.paymentStatus ="Failure";
  }

  pageClose = true
  timerSec = 10
  sendTransactionIdAftersuccess() {
    const url = `${this.config.json.apiEndpoint}wallet/paymentOnSuccessOrFailure`
    this.http.post(url, { txnid: localStorage.getItem('transactionId') }).subscribe({
      next: () => {
        localStorage.removeItem("transactionId");
        let interval;
        interval = setInterval(() => {
          this.timerSec -= 1
          if (this.timerSec == 0) {
            clearInterval(interval)
          }
        }, 1000)
        setTimeout(() => {
          window.close()
        }, 1000);
      }
    })
  }

}
