<div class="cms__content">
  <div class="top__heading">
    <h3>{{ isEdit ? "Edit" : "Create" }} Bouquet</h3>
    <button [routerLink]="['/content/bouquet/list']">Back</button>
  </div>
  <form [formGroup]="bouquetForm" class="custom__iptv__form">
    <div class="row">
      <div class="col-12 col-md-6">
        <label for="">Name</label>
        <input
          type="text"
          class="custom__field"
          autocomplete="off"
          formControlName="name"
        />
        <div class="hidden" *ngIf="bouquetForm.get('name').touched">
          <span
            class="error__msg"
            *ngIf="bouquetForm.get('name').hasError('required')"
            >Field Required</span
          >
        </div>
      </div>
      <!-- <div class="col-12 col-md-6">
        <label for="">Status</label>
        <select name="" id="" class="custom__field" formControlName="status">
          <option value="null" disabled>--SELECT--</option>
          <option selected value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
        <div class="hidden" *ngIf="bouquetForm.get('status').touched">
          <span
            class="error__msg"
            *ngIf="bouquetForm.get('status').hasError('required')"
            >Field Required</span
          >
        </div>
      </div> -->
      <div class="col-12 col-md-6">
        <label for="">Bouquet Type</label>
        <select
          name=""
          formControlName="bouquetType"
          (change)="bouquetTypeChanged()"
          id="bouquetType"
          class="custom__field"
        >
          <option value="null" selected disabled>--SELECT--</option>
          <option value="1">Broadcaster Bouquet</option>
          <option value="0">MSO Bouquet</option>
        </select>
        <div class="hidden" *ngIf="bouquetForm.get('bouquetType').touched">
          <span
            class="error__msg"
            *ngIf="bouquetForm.get('bouquetType').hasError('required')"
            >Field Required</span
          >
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label for="">Pack Type</label>
        <select name="" formControlName="packType" id="" class="custom__field">
          <option value="null" selected disabled>--SELECT--</option>
          <option value="BASE_PACK">Base Pack</option>
          <option value="ADD_ON_PACK">Add-On Pack</option>
          <option value="ALACARETE_PACK">Alacarte Pack</option>
        </select>
        <div class="hidden" *ngIf="bouquetForm.get('packType').touched">
          <span
            class="error__msg"
            *ngIf="bouquetForm.get('packType').hasError('required')"
            >Field Required</span
          >
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label for="">Broadcaster</label>
        <select
          disabled
          formControlName="broadcasterId"
          (change)="broadcasterChanged()"
          name=""
          id="broadcaster"
          class="custom__field"
        >
          <option value="null" selected disabled>--SELECT--</option>
          <option
            *ngFor="let broadcaster of broadcasters"
            [value]="broadcaster.id"
          >
            {{ broadcaster.name }}
          </option>
        </select>
        <div class="hidden" *ngIf="bouquetForm.get('broadcasterId').touched">
          <span
            class="error__msg"
            *ngIf="bouquetForm.get('broadcasterId').hasError('required')"
            >Field Required</span
          >
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label for="">Channels</label>
        <multi-select
          [multiSelectOptions]="channelsData"
          [controlName]="'channels'"
          [parentFormName]="bouquetForm"
          (selectedObjects)="selectedChannels($event)"
        >
        </multi-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label for="">Description</label>
        <textarea
          class="custom__field"
          name=""
          id=""
          cols="30"
          rows="5"
          formControlName="description"
        ></textarea>
        <div class="hidden" *ngIf="bouquetForm.get('description').touched">
          <span
            class="error__msg"
            *ngIf="bouquetForm.get('description').hasError('required')"
            >Field Required</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-12 col-md-6">
        <label for="">Duration(months)</label>
        <select formControlName="duration" class="custom__field" name="" id="">
          <option value="null" selected disabled>--SELECT--</option>
          <option
            *ngFor="let num of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
            value="{{ num }}"
          >
            {{ num }} Months
          </option>
        </select>
        <div class="hidden" *ngIf="bouquetForm.get('duration').touched">
          <span
            class="error__msg"
            *ngIf="bouquetForm.get('duration').hasError('required')"
            >Field Required</span
          >
        </div>
      </div> -->
      <div class="col-12" style="position: relative">
        <label for="">Price</label>
        <div
          style="
            position: absolute;
            right: 0;
            top: 0;
            margin-right: 15px;
            display: flex;
            gap: 10px;
          "
        >
          <label for="" class="not__required">With GST</label>
          <input type="checkbox" name="" id="" formControlName="withGST" (change)="calculateGST()" />
        </div>
        <input
          type="text"
          oninput="this.value = this.value.replace(/[^0-9].[^0-9]/g, '')"
          class="custom__field"
          formControlName="price"
          (input)="calculateGST()"
        />
        <div class="hidden" *ngIf="bouquetForm.get('price').touched">
          <span
            class="error__msg"
            *ngIf="bouquetForm.get('price').hasError('required')"
            >Field Required</span
          >
        </div>
      </div>
    </div>
  </form>
  <button
    style="margin: 10px 0"
    class="cta float-right"
    [disabled]="formValidity()"
    (click)="persist()"
  >
    Submit
  </button>
</div>
<div style="margin: 0 15px">
  <table class="custom__iptv__table">
    <thead>
      <tr>
        <th>Type</th>
        <th>Price</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Price</td>
        <td>{{gstPrice.price}}</td>
      </tr>
      <tr>
        <td>GST Price(18%)</td>
        <td>{{gstPrice.gst}}</td>
      </tr>
    </tbody>
  </table>
</div>
