import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { PackageInfo } from 'src/app/models/package/package-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ReportTraiDataService } from '../plugins/report-trai-data.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';


@Component({
  selector: 'app-report-subscription',
  templateUrl: './report-subscription.component.html',
  styleUrls: ['./report-subscription.component.scss']
})
export class ReportSubscriptionComponent implements OnInit {

  reportList = [];

  channelList: ChannelInfo[] = [];
  selectedChannelId: number;

  bouquetList: PackageInfo[] = [];
  selectedBouquetId: number;

  currentDate = new Date();

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: ReportTraiDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService
  ) { }


  ngOnInit(): void {
    this.fetchChannelList();
    this.fetchBouquetList();
  }

  fetchChannelList(): void {
    this.report.getChannelList().subscribe(data => {
      this.channelList = data;
    });
  }

  fetchBouquetList(): void {
    this.report.getBouquetList().subscribe(data => {
      this.bouquetList = data;
    });
  }

  fetchData(from: number, to: number): void {
    this.report.getStbStatusReport(from, to, {}).subscribe(data => {
      this.reportList = data;
    });
  }

  dateChange(ev: NgxDateRangeInfo): void {
    if (ev.startDate !== null && ev.endDate !== null) {
      this.calendar.setDate(ev);
      this.fetchData(ev.startDate.valueOf(), ev.endDate.valueOf());
    }
  }

  downloadTableExcel(): void {
    let name='Subscription Report'
    this.doc.exportTableToExcelwithImage(this.reportTable,name, 'subscription_report');
  }

  downloadTablePdf(): void {
    this.pdf.generatePdfHtml('#reportTable', 'subscription_report');
  }
  downloadTableCSV(): void {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      // title: false,
      useBom: true,
      // noDownload: true,
      headers: ["Date", "total Stbs","Active STBs","Inactive STBs", "Blacklisted", "Whitelisted",
      "Paired","Unpaired"]
    };
    let data = []
    this.reportList.forEach(line => {
      let csvLine = {
        date: line.date,
        totalSTBs: line.activeSTBs,
        activeSTBs: line.activeSTBs ,
        inActiveSTBs: line.inActiveSTBs,
        blaclistedSTBs: line.blaclistedSTBs,
        unBlacklistedSTBs: line.unBlacklistedSTBs,
        pairedSTBs: line.pairedSTBs,
        unpairedSTBs: line.unpairedSTBs ,
      }
      data.push(csvLine);
    });

    new ngxCsv(data, 'subscriber_packages_history', options);
  }
}
