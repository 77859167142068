import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';
import { MailInfo } from 'src/app/models/mail/mail-info';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { SortOrder, PageMetadata, PageInfo } from 'src/app/models/pagination/page-info';
import { delay } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-list-mail',
  templateUrl: './list-mail.component.html',
  styleUrls: ['./list-mail.component.scss']
})
export class ListMailComponent implements OnInit {

  paginationObj = new PaginationObj();

  loadingFlag = false;
  errorFlag = false;

  disableFirstBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;
  disablePreviousBtn = false;

  sortByProperties = {
    subId: 'subscriberId',
    subject: 'subject',
    cdate: 'createdDate',
    udate: 'updatedDate',
    body: 'body',
    readFlag: 'readFlag'
  };

  mailList: MailInfo[] = [];

  unreadMail = 0;

  constructor(
    private router: Router,
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.fetchPage();
  }

  onSearch() {
    this.paginationObj.pageNumber = 1;
    this.fetchPage();
  }


  fetchPage(): void {
    PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
    const url = PaginationUtilities.urlBuilder('mail/list', this.paginationObj);
    // const url = this.config.json.apiEndpointRdbms+`mail/list`
    this.loadingFlag = true;

    this.http.get(url).pipe(delay(500)).subscribe((data: PageInfo<MailInfo>) => {
      this.loadingFlag = false;
      this.handleFetchPage(data);
    },
      (error) => {
        // //// console.log(error)
        // window.alert(error.error.status+" "+error.error.error)
        this.loadingFlag = false;
        this.errorFlag = true;
      }
    );
  }

  handleFetchPage(data: PageInfo<MailInfo>): void {
    this.mailList = data.data;

    this.handlePaginationMetadata(data.metadata);
    this.handlePaginationBtnDisableStatuses();
    this.countMail();
  }

  handlePaginationMetadata(metadata: PageMetadata): void {
    this.paginationObj.totalPages = metadata.totalPageCount;
    this.paginationObj.totalElements = metadata.totalElementCount;
  }

  handlePaginationBtnDisableStatuses(): void {

    // Left section
    this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
    this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;

    // Right section
    this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
  }

  pageSizeChange(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;
    this.fetchPage();
  }

  pagePreviousBtnClick(): void {
    if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
      this.paginationObj.pageNumber--;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageNextBtnClick(): void {
    if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
      this.paginationObj.pageNumber++;
    }
    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageFirstBtnClick(): void {
    this.paginationObj.pageNumber = environment.defaultPageNumber;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  pageLastBtnClick(): void {
    this.paginationObj.pageNumber = this.paginationObj.totalPages;

    this.handlePaginationBtnDisableStatuses();
    this.fetchPage();
  }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchPage();
  }

  edit(record: MailInfo): void {
    this.router.navigate(['/communication/mail', record.id]);
  }

  deleteMail(record: MailInfo, index: number): void {
    const url = this.config.json.apiEndpointRdbms + 'mail/deleteMail/' + record.id;
    this.http.delete(url).subscribe(() => {
      window.alert('Deleted Mail');
      this.mailList.splice(index, 1);
      this.countMail();
    });
  }

  countMail(): void {
    this.unreadMail = this.mailList.filter(mail => !mail.readFlag).length;
  }

}
