import { Component, OnInit } from '@angular/core';
import {
  PaginationObj,
  PaginationUtilities,
} from 'src/app/models/pagination/pagination-info';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss'],
})
export class ManagementComponent implements OnInit {
  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService,
    private auth: AuthService
  ) {}
  paginationObj = new PaginationObj();

  isDistributor = false;
  ngOnInit(): void {
    this.isDistributor = this.auth.isDistributor();
    this.getData();
  }

  getData() {
    if (this.isDistributor) {
      this.fetchData();
    } else {
      this.getBouquetByLcoId();
    }
  }

  fetchData() {
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint);
    let url = PaginationUtilities.urlBuilder(
      'distributor/bouquetList',
      this.paginationObj
    );
    url += `&distributorId=${this.auth.getLcoId()}`;
    this.http.get(url).subscribe({
      next: (res) => {
        this.bouquetList = res.data;
        this.handlePageMetadata(res.metadata);
      },
    });
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus();
  }

  bouquetList = [];
  getBouquetByLcoId() {
    const lcoId = this.auth.getLcoId();
    const url = `${this.config.json.apiEndpoint}bouquet/getBouquetByLco/${lcoId}`;
    this.http.get(url).subscribe({
      next: (res) => {
        this.bouquetList = res.data;
      },
    });
  }

  postLcoBouquetAmount(bouquet: any, index: string) {
    let amount;
    if (this.updateObject.hasOwnProperty(index)) {
      amount = this.updateObject[index];
    } else {
      amount = bouquet.lcoAmount;
    }
    let body = {
      lcoId: this.auth.getLcoId(),
      bouquetId: bouquet.bouquetId,
      amount,
    };
    const url = `${this.config.json.apiEndpoint}lco/lcoBouquetAmount`;
    this.http.put(url, body).subscribe({
      next: (res) => {
        if (res.statusCode == 200) {
          alert('Succesful');
        } else {
          alert(res.message);
        }
      },
    });
  }

  updateObject = {};
  handleInput(e: Event, index, bouquet: any) {
    let input = (e.target as HTMLInputElement).value;
    let btn = document.getElementById(`btn${index}`) as HTMLButtonElement;
    if (input != '') {
      btn.disabled = false;
    } else {
      btn.disabled = true;
    }
    if (+bouquet.bouquetPrice < +input) {
      alert('Amount can not be greater than MRP');
      btn.disabled = true;
    } else {
      btn.disabled = false;
    }
    this.updateObject[index] = input;
  }

  disableFirstBtn = false;
  disablePreviousBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;

  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }
}
