<div class="cms__content">
    <app-reports-header></app-reports-header>
    <div class="top__heading">
      <h3>Subscription by Bouquet ID Reconciliation Report</h3>
      <a [routerLink]="['/reports/trai']"
        ><button class="default__button float-right">Back</button></a
      >
    </div>
    <div class="clearfix"></div>
  
    <div class="row">
     
      <div class="filter__group col-12 col-md-6">
      </div>
      <div class="download__report col-md-6">
        <button
          class="default__button"
          (click)="downloadTablePdf()"
          title="Download PDF"
        >
          PDF
          <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableExcel()"
          title="Download Excel"
        >
          Excel
          <img src="./assets/excel.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableCSV()"
          title="Download Excel"
        >
          CSV
          <img src="./assets/csv.svg" alt="download" />
        </button>
        <button class="default__button" (click)="downloadHistoricReport()" title="Download PDF">
          Historical Report
          <img src="./assets/download.svg" alt="download" />
        </button>
      </div>
    </div>
  
    <div class="third_box">
      <div class="col-12 col-md-6 report">
        <p>
          Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
        </p>
      </div>
      <div style="display: flex; gap: 20px">
       
      </div>
    </div>
    <div class="col-12 list__table">
      <div class="row scroll">
        <div class="tableBox">
        <table class="custom__iptv__table" #reportTable id="reportTable">
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Bouquet ID</th>
              <th>Bouquet Name</th>
              <th> Package Type</th>
              <th> Stb IDs</th>
              <th>SMS</th>
              <th>DRM</th>
              <th>Mismatch %</th>
             
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="reportList?.length == 0">
              <td style="text-align: center" *ngIf="isLoading" colspan="6">
                <app-custom-spinner></app-custom-spinner>
              </td>
              <td style="text-align: center" *ngIf="!isLoading" colspan="6">
                {{ isLoad }}
              </td>
            </tr>
  
            <tr *ngFor="let report of reportList; let j = index">
              <td>{{ j + 1 }}</td>
              <td>{{report['bouquetId']}}</td>
              <td>{{report['bouquetName']}}</td>
              <td>{{report['packType']}}</td>
              <td>{{report.stbIds}}</td>
              <td>
                {{ report['SMS-activeSubscribers']}}
              </td>
              <td>{{ report['DRM-activeSubscribers'] }}</td>
              
              <td>
                {{
                  report['Mismatch %']
                }}
              </td>
              
              
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>

  
<div class="modal_box" *ngIf="viewModal">
  <div class="modal_box_inner">
    <!-- <h3>LCO Details</h3> -->
    <div class="parent">
      <!-- <p class="div1"></p> -->
      <div class="div1">
        <div class="row row-top">
          <div class="date_filter">
            <div class="div_tabs" *ngIf="dateSelector">
              <button (click)="dateTypeSelectors(1)" >Month</button>
              <button (click)="dateTypeSelectors(2)" >Year</button>
            </div>
            <div class="div_tabs2" *ngIf="showMonth" >
             <div class="date_box">
              <label for="month">Select Month:</label>
              <div class="date_box_orig">
                <input class="custom__field mar_zeroed" type="month" id="month"  value="selectedDate" (change)="MonthFilter($event)">
              </div>
             </div>
              <button (click)="dateTypeSelectors(0)" >X</button>
            </div>
            <div class="div_tabs2" *ngIf="showDateRange" >
              <div class="date_box">
                <label for="month">Select Year:</label>
                <div class="date_box_orig">
                  <select name="" id="yearCalc" (change)="yearFilter()" class="custom__field">
                    <option *ngFor="let item of yearsArray" value="{{ item }}">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <button (click)="dateTypeSelectors(0)">X</button>
            </div>
          </div>
         
        </div>
      </div>
      
    </div>
   
   
    <button class="float-left" (click)="dateTypeSelectors(0);viewModal = !viewModal;this.reportType='';this.yearValue='';"> Close </button>
    <button class="cta float-right" (click)="generateReport()" [disabled]="yearValue==''"> Generate </button>
  </div>
</div>
  