import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChannelRoutingModule } from './channel-routing.module';
import { ListChannelComponent } from './list-channel/list-channel.component';
import { CreateChannelComponent } from './create-channel/create-channel.component';
import { SharedModule } from '../shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BulkUploadChannelComponent } from './bulk-upload-channel/bulk-upload-channel.component';


@NgModule({
  declarations: [
    CreateChannelComponent,
    ListChannelComponent,
    BulkUploadChannelComponent],
  imports: [
    CommonModule,
    ChannelRoutingModule,
    SharedModule,
    NgMultiSelectDropDownModule
  ],
  exports: [
    CreateChannelComponent,
    ListChannelComponent]
})
export class ChannelModule { }
