import { DashboardInfo } from './../../models/dashboard/dashboard-info';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { FormGroup, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { PathNames } from 'src/app/constants/path-names';
import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AclGroupInfo, AclMenus } from 'src/app/models/acl-group/acl-group-info';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-acl-create',
  templateUrl: './acl-create.component.html',
  styleUrls: ['./acl-create.component.scss']
})
export class AclCreateComponent implements OnInit {

  groupForm: FormGroup;
  isEdit = false;
  group = {
    name: "",
    description: "",
    id: 0,
    // menus: {} as AclMenus
    menus: [] as AclMenus[],
    checked: false,
  } as AclGroupInfo

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) { }

  testParam = ""

  accordionMapping = {}
  menuData = []
  menuAccordion = {}

  ngOnInit(): void {
    this.initParams();
    this.initForm();
    // //// console.log("Accordion MAP: ",this.accordionMapping)
    // //// console.log("MENU Accordion: ", this.menuAccordion)
  }

  getMenus(): Observable<any>{
    const url = this.config.json.apiEndpointRdbms + 'menu/getMenus';
    return this.http.get(url);
  }

  initParams() {
    this.getMenus().subscribe((data) => {
      this.menuData = data
      data.map((each) => {
        // //// console.log(data);
        this.menuAccordion[each.id] = false
        if(this.accordionMapping[each.parentId] !== undefined){
          this.accordionMapping[each.parentId].push(each.id)
        }
        else{
          this.accordionMapping[each.parentId] = [each.id]
        }
      })
      // to assign id to dashboard menu
      this.accordionMapping[1] = [1]
      this.getPathParam();
    })

  }

  updateFlags(param){
      const idList = this.accordionMapping[param]
      let count = 0
      let flagTrue = 0

      for(const id of idList){
          count += 1
          flagTrue += (this.menuAccordion[id] ? 1 : 0)
      }
      // //// console.log(flagTrue, count, param)
      if (flagTrue > 0) this.menuAccordion[param] = true
      else this.menuAccordion[param] = false
  }

  updateAccordion(param){
    const idList = this.accordionMapping[param]
    const flagUpdate = this.menuAccordion[param]
    // //// console.log(this.menuAccordion, idList, this.accordionMapping)
    for(const id of idList){
      this.menuAccordion[id] = flagUpdate
    }
  }

  initForm(): void {
    this.groupForm = new FormGroup({
      name: new FormControl(''),
      description: new FormControl(''),
    });
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.updateFlow(Number(id));
    } else {
      this.createFlow();
    }
  }

  createFlow(): void {}

  getAclGroupById(id: number): Observable<AclGroupInfo> {
    const url = this.config.json.apiEndpointRdbms + 'aclgroup/getAclGroup/' + id;
    return this.http.get(url);
  }

  updateForm(): void {
    this.groupForm.get('name').setValue(this.group.name);
    this.groupForm.get('description').setValue(this.group.description);
    this.group.menus.map((each) => {
      this.menuAccordion[each.id] = true;
    })
  }

  updateFlow(id: number): void {
    forkJoin([this.getAclGroupById(id)]).subscribe(data => {
      this.group = data[0];
      this.updateForm();
    });
  }

  get name(): FormControl {
    return this.groupForm.get('name') as FormControl;
  }

  persist(): void {
    this.updateModel();
    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'aclgroup/createAclGroup';
    this.http.post(url, this.group).subscribe(data => {
      this.router.navigate(['/settings/access-control', 'list'])
    }, error => {
      // //// console.log(error)
      window.alert(error.error["message"])
    });
  }

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'aclgroup/updateAclGroup';
    // //// console.log(this.group);
    this.http.put(url, this.group).subscribe(data => {
      // //// console.log(data)
      this.router.navigate(['/settings/access-control', 'list']);
    },
    error => {
      window.alert(error.error["message"])
    });
  }

  updateModel(): void {
    this.group.name = this.groupForm.get('name').value;
    this.group.description = this.groupForm.get('description').value;
    // //// console.log(this.menuAccordion, this.group.menus)

    for(let each in this.menuAccordion){
      if(this.menuAccordion[each]) {
        // //// console.log(this.menuAccordion[each], each)
        this.group.menus.push({"id":each} as AclMenus)
      }
    }
    // //// console.log(this.menuAccordion, this.group.menus)


  }

}
