import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { LogsDataService } from '../plugins/logs-data.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-logs-batch',
  templateUrl: './logs-batch.component.html',
  styleUrls: ['./logs-batch.component.scss'],
})
export class LogsBatchComponent implements OnInit {
  reportList = [];
  from: any;
  to: any;
  currentDate = new Date();
  loadingFlag = false;
  isLoad = 'Choose Date';

  dateSelected = false;
  broadcasteSelected = false;
  ev;
  reportDate;

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private http:HttpClientWrapperService,
    private config:ConfigService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  formatDate(ele) {
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  fetchData(): void {
    const body = {
      broadcasterId: '',
    };
    // console.log(body)

    this.report.getBatchLogs().subscribe(
      (data) => {
        // // console.log(data)
        this.reportList = data.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }
  fetchDataonInit(): void {
    this.loadingFlag = true;
    this.isLoad = 'Loading';
    const currentdate = new Date();
    let from = this.formatDate('2019-01-01');
    let to = this.formatDate(currentdate);
    const body = {
      fromDate: this.formatDate('2019-01-01'),
      toDate: this.formatDate(currentdate),
      broadcasterId: 0,
    };
    this.report.getChannelModificationHistory2(body).subscribe(
      (data) => {
        this.reportList = data.data;
        if (this.reportList.length != 0) {
          this.loadingFlag = false;
        } else {
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      },
      () => {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
    );
  }

  listItem(): void {
    this.fetchData();
  }

  dateChange(ev: NgxDateRangeInfo): void {
    this.dateSelected = true;
    if (ev.startDate !== null && ev.endDate !== null) {
      this.reportDate =
        ev.startDate.format('DD-MM-YYYY') +
        ' to ' +
        ev.endDate.format('DD-MM-YYYY');
      this.ev = ev[(this.from, this.to)] = [ev.startDate, ev.endDate];
      this.calendar.setDate(ev);
      this.fetchData();
      this.loadingFlag = true;
      this.isLoad = 'Loading';
    }
  }

  // downloadTableExcel(): void {
  //   let name = 'Channel Modification Logs';
  //   if (this.reportList.length == 0) {
  //     alert('No Data!!');
  //   } else {
  //     if (this.ev) {
  //       this.doc.exportTableToExcelwithImage(
  //         this.reportTable,
  //         name,
  //         'Batch Logs',
  //         undefined,
  //         undefined,
  //         'Filter Date :'
  //       );
  //     } else {
  //       this.doc.exportTableToExcelwithImage(
  //         this.reportTable,
  //         name,
  //         'Batch Logs',
  //         undefined,
  //         undefined
  //       );
  //     }
  //   }
  // }

  downloadTableExcel(){
    let url
      url= this.config.json.apiEndpoint+'generate/batchLogs'
      
      
      this.http.getwithresponseType(url).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'batch_logs.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }


  downloadTablePdf(): void {
    if (this.reportList.length == 0) {
      alert('No Data!!');
    } else {
      if (this.ev) {
        this.pdf.generatePdfHtml(
          '#reportTable',
          'Batch_logs',
          `Filter date : ${this.reportDate}`,
          'portrait'
        );
      } else {
        this.pdf.generatePdfHtml(
          '#reportTable',
          'Batch_logs',
          `From Start to Now`,
          'portrait'
        );
      }
    }
  }

  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ['Sl No', 'Type', 'Status', 'Uploaded By', 'Date'],
    };
    let data = [];
    let i = 1;
    this.reportList.forEach((line) => {
      let csvLine = {
        slno: i,
        date: line.type,
        name: line.status,
        channelName: line.uploaded_by,
        channelId: line.date,
      };
      i += 1;
      data.push(csvLine);
    });

    new ngxCsv(data, 'Batch_Logs', options);
  }
}
