<div class="cms__content">
  <app-reports-header></app-reports-header>
    <div class="top__heading">

        <h3>Bouquet Wise Channel Report</h3>
        <a [routerLink]="['/reports/trai']"><button class="default__button float-right">Back</button></a>
      </div>
      <div class="clearfix"></div>

      <div class="row">
        <div class="filter__group col-12 col-md-6"></div>
            <div class="download__report col-md-6">
                <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
                </button>
                <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
                </button>
                <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
                  CSV
                  <img src="./assets/csv.svg" alt="download" />
                </button>
          </div>
      </div>

      <div class="col-12 col-md-6 report_selection_block">
        <label class="report_selection_label">Select Bouquet</label>
        <select class="custom__field report_selection" [(ngModel)]="selectedBouquetId" (change)='bouquetSelect()'>
          <option  [ngValue]='0'>
            All
          </option>
          <option *ngFor="let boq of bouquetList" [ngValue]='boq.id'>
            {{boq.name}}
          </option>
        </select>
      </div>

      <div class="third_box">
        <div class="col-12 col-md-6 report">
          <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
        </div>
        <div style="display: flex; gap:20px" >
          <div class="table__right">
            <p class="table__summary">Total <span>{{reportList.length}}</span> record{{reportList.length == 1? '' : 's'}}</p>
          </div>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
            <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>

      <div class="col-12 list__table">
        <div class="row scroll">
            <table class="custom__iptv__table" #reportTable id="reportTable">
                <thead>
                    <tr>
                        <th>SL No</th>
                        <th>Broadcaster</th>
                        <th>Price Package Name</th>
                        <th>Bouquet Name</th>
                        <th>Subscription Type</th>
                        <th>Channel ID</th>
                        <th>Channel Name</th>
                        <th>Channel Number</th>
                        <!-- <th>Channel Type</th> -->
                    </tr>
                </thead>
                <tbody>
                  <tr *ngIf="isLoading">
                    <td colspan="9" style="text-align: center;"><app-custom-spinner></app-custom-spinner></td>
                  </tr>
                  <tr *ngIf="reportList.length === 0 && !isLoading">
                    <td colspan="9" style="text-align: center;">No Records</td>
                  </tr>
                  <tr *ngFor="let report of reportList, let j = index">
                    <td>{{j+1}}</td>
                    <td>{{report.broadcaster_name ? report.broadcaster_name : '---'}}</td>
                    <td>{{report.price_package_name ? report.price_package_name : '---' }}</td>
                    <td>{{report.bouquet_name ? report.bouquet_name : '---'}}</td>
                    <td>{{report.pack_type ? report.pack_type :'---'}}</td>
                    <td>{{report.channel_id ? report.channel_id : ' ---' }}</td>
                    <td>{{report.channel_name ? report.channel_name : '---' }}</td>
                    <td>{{report.channel_no ? report.channel_no : '---'}}</td>
                    <td></td>
                  </tr>
                </tbody>
            </table>
        </div>
      </div>
</div>
<!-- bouquet_id:337
bouquet_name:"& FLIX HD"
broadcaster_id:57
broadcaster_name:"ZEE ENTERTAINMENTENT"
channel_id:2490
channel_name:"AND-FLIX-HD"
channel_no:671
pack_type:"ALACARETE_PACK"
price_package_name:"& FLIX HD" -->
