<div class="cms__content">
  <div class="container">
    <div class="row">
      <div class="top__heading">
        <h3>My Packs</h3>
      </div>
      <div class="col-12 col-md-6 report_selection_block">
        <label class="report_selection_label">Select Item</label>
        <select class="custom__field report_selection"  id="lco" (change)="getDetails($event.target.value)">
          <!-- Changed ngValue binding from lco.id to lco.email -->
          <option value='Channel'>Channels</option>

          <option value='Bouquet'>Bouquet</option>
        </select>
      </div>
      <br/>
      <!-- <div style="display:flex">
        <h4>
          <ng-container *ngIf="boqClicked">Bouquets</ng-container>
          <ng-container *ngIf="channelCliked">Channels</ng-container>
  
        </h4>
      </div> -->
      
      <table class="custom__iptv__table inner__table">
        
        <thead>
          <tr><ng-container *ngIf="channelCliked" >
            <th>Channel Name</th>
            <th>Subscriber Price</th>
            <th>LCO Share</th>
            </ng-container>
            <ng-container *ngIf="boqClicked">
              <th>Bouquet Name</th>
              <th>Subscriber Price</th>
              <th>LCO Share</th>
            </ng-container>
          </tr>
        </thead>
          <tbody *ngIf="boqClicked">
            <tr *ngIf="boqClicked && bouquetList.length==0" colspan="3" style="text-align:center">No Records</tr>
            <tr *ngFor="let ch of bouquetList">
              <td>{{ch.id}}</td>
              <td>Rs {{ch.p1}}</td>
              <td>Rs {{ch.p2}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="channelCliked">
          <tr *ngIf="channelCliked && channelList.length==0" colspan="3" style="text-align:center">No Records</tr>

          <tr *ngFor="let ch of channelList">
            <td>{{ch.id}}</td>
            <td>Rs {{ch.p1}}</td>
            <td>Rs {{ch.p2}}</td>
          </tr>
          </tbody>
      </table>
    </div>
  </div>
</div>