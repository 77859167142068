import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { ngxCsv } from 'ngx-csv';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import * as moment from 'moment';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';
import { environment } from 'src/environments/environment';
import { ReportTraiDataService } from '../report-trai/plugins/report-trai-data.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

    reportList = [];
    isLoad = 'No Records';
    isLoading = false;
    currentDate = new Date();
    from:any;
    to:any;
    onDate
  
    paginationObj = new PaginationObj();

    loadingFlag = false;
  
  
    disableFirstBtn = false;
    disableLastBtn = false;
    disableNextBtn = false;
    disablePreviousBtn = false;
    
  
  
    @ViewChild('reportTable', { static: false }) reportTable: ElementRef;
  
    constructor(private report: ReportTraiDataService,
      private calendar: ReportCalendarService,
      private doc: DocumentGeneratorService,
      private pdf: PdfGeneratorService,
      private config: ConfigService,
      private http: HttpClientWrapperService) { }
  
    ngOnInit(): void {
      this.onDate=this.currentDate.toISOString().split('T')[0];
      this.fetchData()
    }
  
    sortByItem(item: string): void {
      console.log(item)
      this.paginationObj.sortBy = item;
      this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
      this.fetchData();
    }

    onSearch() {
      this.paginationObj.pageNumber = 1;
      this.fetchData();
    }
  
  
    handlePaginationBtnDisableStatuses(): void {
      this.disableFirstBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
      this.disablePreviousBtn = this.paginationObj.pageNumber <= environment.defaultPageNumber ? true : false;
      this.disableNextBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
      this.disableLastBtn = this.paginationObj.pageNumber >= this.paginationObj.totalPages ? true : false;
    }
  
    pageSizeChange(): void {
      this.paginationObj.pageNumber = environment.defaultPageNumber;
      this.fetchData();
    }
  
    pagePreviousBtnClick(): void {
      if (this.paginationObj.pageNumber > environment.defaultPageNumber) {
        this.paginationObj.pageNumber--;
      }
      this.handlePaginationBtnDisableStatuses();
      this.fetchData();
    }
  
    pageNextBtnClick(): void {
      if (this.paginationObj.pageNumber < this.paginationObj.totalPages) {
        this.paginationObj.pageNumber++;
      }
      this.handlePaginationBtnDisableStatuses();
      this.fetchData();
    }
  
    pageFirstBtnClick(): void {
      this.paginationObj.pageNumber = environment.defaultPageNumber;
  
      this.handlePaginationBtnDisableStatuses();
      this.fetchData();
    }
  
    pageLastBtnClick(): void {
      this.paginationObj.pageNumber = this.paginationObj.totalPages;
  
      this.handlePaginationBtnDisableStatuses();
      this.fetchData();
    }
    handleFetchPage(data): void {
      console.log(this.loadingFlag)
      this.reportList = []
      this.reportList = data.data;
      this.filteredReports = data.data;
      if (this.reportList.length != 0) {
        this.loadingFlag = false;
      } else {
        this.loadingFlag = false;
        this.isLoad = 'No Records';
      }
      this.handlePaginationMetadata(data.metadata);
      this.handlePaginationBtnDisableStatuses();
    }
  
    pageNumber
    handlePaginationMetadata(metadata: PageMetadata): void {
      this.paginationObj.totalPages = metadata.totalPageCount;
      this.pageNumber = metadata.pageNumber
      this.paginationObj.totalElements = metadata.totalElementCount;
    }
  
    
    sync(){
      let url= this.config.json.apiEndpoint+'bsnl/syncOrders'
      this.http.post(url,{}).subscribe(
        (data)=>{
          alert(data.desc)
        }
      )
    }
 
  
    statusvalue= 'Open'
  

    fetchData() {
      this.loadingFlag = true
      this.reportList = []
      this.filteredReports = []
      this.skWord=''
      PaginationUtilities.giveConfig(this.config.json.apiEndpointRdbms)
      let url = PaginationUtilities.urlBuilder('bsnl/orders', this.paginationObj);
       url= url+'&status='+this.statusvalue
      this.http.get(url).subscribe(
        (data) => {
          this.handleFetchPage(data)
        },
        (error) => {
          console.log("call")
          this.loadingFlag = false;
          this.isLoad = 'No Records';
        }
      );
    }
  
   
  
    
  
    downloadTableExcel(): void {
      let name = 'BSNL Orders';
      this.doc.exportTableToExcelwithImage(this.reportTable, name, 'BSNL_orders');
    }
  
    downloadTablePdf(): void {
      this.pdf.generatePDFForLongTable(
        'BSNL Orders',
        `Status: ${this.statusvalue}`,
        2400
      );
    }
  
    
    downloadTableCSV(): void {
      if (this.reportTable) {
        const tableData = this.getTableData(this.reportTable.nativeElement);
        if (tableData) {
          var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            useBom: true,
          };
          new ngxCsv(tableData, 'BSNL_orders', options);
        }
      }
    }
  
    private getTableData(table: any): any[] {
      const data = [];
      const rows = table.rows;
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowData = [];
        for (let j = 0; j < row.cells.length; j++) {
          rowData.push(row.cells[j].innerText.trim());
        }
        data.push(rowData);
      }
      return data;
  }
  filteredReports
  skWord = ''
    searchKeyword() {
      let keyword = this.skWord
          if (!keyword) {
            this.filteredReports = this.reportList;
            return;
          }
      
          this.filteredReports = this.reportList.filter(report => {
            return Object.values(report).some(value => {
              if (value && typeof value === 'string') {
                return value.toLowerCase().includes(keyword.toLowerCase());
              }
              return false;
            });
          });
        }
      
    
  
  }
  
  
