import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomReportTraiHomeComponent } from './custom-report-trai-home/custom-report-trai-home.component';
import { ReportSubscriberComponent } from './report-subscriber/report-subscriber.component';
import { CustomReportTraiRoutingModule } from './custom_report_trai-routing.module';
import { ReportSubscriberLcoDistributorComponent } from './report-subscriber-lco-distributor/report-subscriber-lco-distributor.component';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PluginsModule } from '../plugins/plugins.module';
import { SharedModule } from '../shared/shared.module';
import { ReportChannelsDetailedCountComponent } from './report-channels-detailed-count/report-channels-detailed-count.component';
import { ReportBsnlSubscribersComponent } from './report-bsnl-subscribers/report-bsnl-subscribers.component';


@NgModule({
  declarations: [
    CustomReportTraiHomeComponent,
    ReportSubscriberComponent,
    ReportSubscriberLcoDistributorComponent,
    ReportChannelsDetailedCountComponent,
    ReportBsnlSubscribersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxDaterangepickerMd,
    PluginsModule,
    SharedModule,
    CustomReportTraiRoutingModule
  ]
})
export class CustomReportTraiModule { }
