<div class="cms__content">
  <div class="top__heading">
    <h3>Landing Channel Management</h3>
    <a [routerLink]="['/content/statewise-lcn']"><button type="button"
        class="default__button float-right">Back</button></a>
  </div>
  <div class="clearfix"></div>
  <form action="" [formGroup]="regionLCMForm" (ngSubmit)="persist()">
    <div class="col-12 form__style">
      <div class="row">

        <div class="col-12 ">
          <div class="col-md-6">
            <label>Region Id</label>
            <input type="text" autocomplete="off" class="custom__field" formControlName="regionId" disabled>
          </div>
        </div>

        <div class="col-12 ">
          <div class="col-md-6">
            <label>Region Name</label>
            <input type="text" autocomplete="off" class="custom__field" formControlName="regionName" disabled>
          </div>
        </div>

        <div class="col-12 ">
          <div class="col-md-6">
            <label>Select Channel for LCM</label>
            <select class="custom__field" formControlName="lcm">
              <option value="{{ch.lcn}}" *ngFor="let ch of channelList">{{ch.channelName}}</option>
            </select>
          </div>
        </div>

        <div class="col-12">
          <button class="cta float-right" [disabled]="!regionLCMForm.valid">Save</button>
        </div>
      </div>
    </div>

  </form>
</div>