<h3>Upload new background</h3>
<input type="file" accept=".jpg, .png, .jpeg" class="custom__field" name="" (input)="uploadFile($event)" id="">
<ng-container *ngIf="imageUploaded" >
    <div class="uploaded_image">
        <h5 >Uploaded Image</h5>
    <img [src]="jpgLocation" alt="">
    <div class="col-12">
        <button (click)="jpgLocation=''; imageUploaded = false" >Cancel</button>
        <button class="cta float-right" (click)="save()" >Approve </button>
    </div>
    </div>
</ng-container>