<div class="cms__content">
  <div class="top__heading">
    <h3>Subscriber Revenue</h3>

  </div>

  <div class="col-12 list__table">
    <div class="row">
      <div class="custom__table__filter">
        <span>
          Show
          <select>
            <option>1</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Search <span></span> subscribers</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" />
            <button><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>

      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th>
              Subscriber
            </th>
            <th>
              Subscriber Phone
            </th>
            <th>
              Subscriber Email
            </th>
            <th>
              Packages / Channels
            </th>
            <th>
              My Share
            </th>
            <th>
              Price
            </th>
            <th>
              Action
            </th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="loading" colspan="6">
              <!-- <app-custom-spinner></app-custom-spinner> -->
            </td>
          </tr>
          <tr>
            <td>Sub Name</td>
            <td>Phone Number</td>
            <td>Subscriber Email</td>
            <td>
              <table class="custom__iptv__table">
                <tbody>
                  <tr>
                    <td>Package name</td>
                    <td>Package Price</td>
                  </tr>
                  <tr>
                    <td>Package name</td>
                    <td>Package Price</td>
                  </tr>
                  <tr>
                    <td>Package name</td>
                    <td>Package Price</td>
                  </tr>
                  <tr>
                    <td>Channel name</td>
                    <td>Channel Price</td>
                  </tr>
                  <tr>
                    <td>Channel name</td>
                    <td>Channel Price</td>
                  </tr>
                  <tr>
                    <td>Channel name</td>
                    <td>Channel Price</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              500
            </td>
            <td>
              500
            </td>
            <td>
              <button class="danger">Cancel Sub</button>
              <button class="cta">Renew Sub</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <!-- <li>
              <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                <img src="./assets/firstPage.png" /></button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                <img src="./assets/previousPage.png" /></button>
            </li>
            <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
            <li>
              <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                <img src="./assets/nextPage.png" /></button>
            </li>
            <li>
              <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                <img src="./assets/lastPage.png" /></button>
            </li> -->
        </ul>

      </div>
    </div>
  </div>
</div>