import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MultiSelectType } from 'src/app/plugins/multiselect/multiselect.component';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-create-bouquet',
  templateUrl: './create-bouquet.component.html',
  styleUrls: ['./create-bouquet.component.scss'],
})
export class CreateBouquetComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private http: HttpClientWrapperService,
    private config: ConfigService,
    private route: Router
  ) {}

  isEdit = false;
  ngOnInit(): void {
    this.initForm();
    this.getParam();
    this.getChannelList();
    this.getBroadcasterList();
  }

  getParam() {
    const id = this.router.snapshot.paramMap.get('id');
    if (id != null || id != undefined) {
      this.isEdit = true;
      this.updateDataById(id);
    }
  }

  getChannelList() {
    const url =
      this.config.json.apiEndpointRdbms + 'channel/getDropdownChannelList';
    this.http.get(url).subscribe({
      next: (res) => {
        this.channelsData.next({
          ...this.channelsDataObject,
          dataArray: res.data,
          keyToDisplay: 'name',
          idForSelected: 'channelId',
        });
      },
    });
  }

  channelsDataObject: MultiSelectType = {
    dataArray: [],
    keyToDisplay: '',
    idForSelected: '',
  };
  getChannelListByBroadcasterId(id: string) {
    let url = `${this.config.json.apiEndpoint}channel/broadcaster/${id}`;
    this.http.get(url).subscribe({
      next: (res) => {
        this.bouquet = {
          ...this.bouquet,
          channels: res.data,
        };
        this.channelsData.next({
          ...this.channelsDataObject,
          dataArray: res.data,
          keyToDisplay: 'name',
          idForSelected: 'id',
        });
      },
    });
  }

  bouquetForm!: FormGroup;
  initForm() {
    this.bouquetForm = this.fb.group({
      name: ['', Validators.required],
      status: ['1', Validators.required],
      bouquetType: ['', Validators.required],
      packType: ['', Validators.required],
      broadcasterId: [null],
      channels: ['', Validators.required],
      description: ['', Validators.required],
      duration: [1],
      price: ['', Validators.required],
      withGST: [false],
    });
  }

  gstPrice = {
    price: 0,
    gst: 0,
  };
  calculateGST() {
    let gst = this.bouquetForm.get('withGST').value;
    let price = +this.bouquetForm.get('price').value;
    (this.gstPrice = {
      ...this.gstPrice,
      price: !gst
        ? +((price * 1.18) as number).toFixed(2)
        : +((price / 1.18) as number).toFixed(2),
    }),
      (this.gstPrice = {
        ...this.gstPrice,
        gst: !gst
          ? +(this.gstPrice.price - price).toFixed(2)
          : +(price - this.gstPrice.price).toFixed(2),
      });
  }

  bouquet;
  updateDataById(id: string) {
    const url = this.config.json.apiEndpointRdbms + 'bouquet/getBouquet/' + id;
    this.http.get(url).subscribe({
      next: (res) => {
        console.log(res);
        this.bouquet = res;
      },
      complete: () => {
        this.updateForm();
      },
    });
  }

  updateForm() {
    this.bouquetForm.setValue({
      name: this.bouquet.name,
      status:'1',
      bouquetType: this.bouquet.bouquetType,
      packType: this.bouquet.packType,
      broadcasterId: this.bouquet.broadcasterId,
      description: this.bouquet.description,
      duration: this.bouquet.duration,
      price: this.bouquet.price,
      channels: this.bouquet.channels,
      withGST: 1,
    });
    this.bouquetTypeChanged();
    this.calculateGST();
  }

  channelsData = new BehaviorSubject<MultiSelectType>(this.channelsDataObject);

  formValidity() {
    return this.bouquetForm.invalid;
  }

  bouquetTypeChanged() {
    let select = document.getElementById('bouquetType') as HTMLSelectElement;
    let broadcasterSelect = document.getElementById(
      'broadcaster'
    ) as HTMLSelectElement;
    if (select.value == '0') {
      broadcasterSelect.disabled = true;
      this.bouquetForm.get('broadcasterId').setValue(null);
      this.getChannelList();

      if (this.isEdit) {
        this.channelsData.next({
          ...this.channelsDataObject,
          selectedArray: this.bouquet.channels,
        });
      }
    } else {
      broadcasterSelect.disabled = false;
      if (this.isEdit) {
        this.broadcasterChanged();
        this.channelsData.next({
          ...this.channelsDataObject,
          selectedArray: this.bouquet.channels,
        });
      }
    }
  }

  broadcasters;
  getBroadcasterList() {
    const url =
      this.config.json.apiEndpointRdbms + 'broadcaster/getBroadcasters';
    this.http.get(url).subscribe({
      next: (res) => {
        this.broadcasters = res;
      },
    });
  }

  broadcasterChanged() {
    let select = document.getElementById('broadcaster') as HTMLSelectElement;
    this.bouquetForm.get('broadcasterId').setValue(select.value);
    this.getChannelListByBroadcasterId(select.value);
  }

  channels;
  selectedChannels(channels) {
    console.log(channels);
  }

  createB;
  persist() {
    this.isEdit ? this.update() : this.save();
  }
  update() {
    const url = this.config.json.apiEndpointRdbms + 'bouquet/updateBouquet';
    if(!this.bouquetForm.get('withGST').value){
      this.bouquetForm.get('price').setValue(this.gstPrice.price);
    }
    this.bouquet = {
      ...this.bouquet,
      ...this.bouquetForm.value,
    };
    this.http.put(url, this.bouquet).subscribe({
      next: (data) => {
        if (data.statusCode == 200) {
          this.route.navigate(['/content/bouquet', 'list']);
        } else {
          alert(data.message);
        }
      },
    });
  }
  save() {
    const url = this.config.json.apiEndpointRdbms + 'bouquet/createBouquet';
    if(!this.bouquetForm.get('withGST').value){
      this.bouquetForm.get('price').setValue(this.gstPrice.price);
    }
    this.bouquetForm.get("withGST").setValue(1)
    this.http.post(url, this.bouquetForm.value).subscribe({
      next: (data) => {
        if (data.statusCode == 200) {
          this.route.navigate(['/content/bouquet', 'list']);
        } else {
          alert(data.message);
        }
      },
    });
  }
}
