import {  Component,  ElementRef,  OnDestroy,  OnInit,  ViewChild} from '@angular/core';
import {  AbstractControl,  AsyncValidatorFn,  FormControl,  FormGroup,  ValidationErrors,  Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PathNames } from 'src/app/constants/path-names';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { LocationInfo } from 'src/app/models/location/location-info';
import { PackageInfo } from 'src/app/models/package/package-info';
import { PaginationObj } from 'src/app/models/pagination/pagination-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { StoreService } from 'src/app/services/store.service';
import { LcoChannelInfo } from '../models/lco-channel-info';
import { LcoPackageInfo } from '../models/lco-package-info';
import { DistributorInfo } from 'src/app/models/distributor/distributor_info';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-create-lco',
  templateUrl: './create-lco.component.html',
  styleUrls: ['./create-lco.component.scss'],
})
export class CreateLcoComponent implements OnInit, OnDestroy {
  lco = {} as LCOInfo;
  lcoForm: FormGroup;
  isEdit = false;
  phonePattern = '^[+][9][1][6-9][0-9]{9}$';
  // passwordRegex = '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$'
  passwordRegex =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
  locationList: LocationInfo[] = [];
  wizardNav = 0;
  wizardNavname = '';
  verifiedWizardNav = 0;
  selectedChannelList: ChannelInfo[] = [];
  selectedLcoChannelList: LcoChannelInfo[] = [];
  selectedLcoPackageList: LcoPackageInfo[] = [];
  selectedPackageList: PackageInfo[] = [];
  paginationObjPackage = new PaginationObj();
  loadingFlagPackage = false;
  disableFirstBtnPackage = false;
  disableLastBtnPackage = false;
  disableNextBtnPackage = false;
  disablePreviousBtnPackage = false;

  distributorList: DistributorInfo[] = [];

  selectAllClicked = false;

  sortByPropertiesPackage = {
    name: 'name',
    desc: 'description',
    date: 'createdDate',
    status: 'status',
    price: 'price',
  };

  channelIds: string[] = [];
  packageList: PackageInfo[] = [];
  selectedPackageIds: string[] = [];
  onLoadFlag = false;
  showPassword = false;
  showConfirmPassword = false;
  isDistributor = false;
  lcoPercent 

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private store: StoreService,
    private config: ConfigService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.isDistributor = this.auth.isDistributor();
    this.getPathParam();
    this.initForm();
    this.getCurrentWizardItem();
    this.selectedChannelList = this.store.lcoSelectedChannelList;
    this.getLCOPercent()
  }
  getLCOPercent(){
    let url=this.config.json.apiEndpointRdbms+'setting/getLcoAndDistributorPercent'
    this.http.get(url).subscribe(
      (res)=>{
        this.lcoPercent = res.data["lco_percent"]
        this.lcoPercent= this.lcoPercent/100;
      }
    )
  }
  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.fetchRecord(id);
      this.updateFlow(id);
    } else {
      this.createFlow();
    }
  }

  createFlow(): void {
    this.fetchCreateData();
  }

  updateFlow(id: string): void {
    this.isEdit = true;
    this.fetchUpdateData(id);
  }

  fetchRecord(id: string): Observable<LCOInfo> {
    const url = this.config.json.apiEndpointRdbms + 'lco/getLCO/' + id;
    return this.http.get(url);
  }

  fetchLocationList(): Observable<LocationInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'location/getLocations';
    return this.http.get(url);
  }
  fetchDistributorList() {
    const url = this.config.json.apiEndpointRdbms + 'distributor/all';
    this.http.get(url).subscribe((data) => {
      this.distributorList = data.data;
    });
  }

  fetchUpdateData(id: string): void {
    this.fetchDistributorList();
    forkJoin([this.fetchRecord(id), this.fetchLocationList()]).subscribe(
      (data) => {
        this.onLoadFlag = true;
        this.lco = data[0];
        this.locationList = data[1];
        this.updateForm();
        this.getChannelIdsforChannelSelector();
        this.editDirectToReviewFunc();
      }
    );
  }

  getChannelIdsforChannelSelector(): void {
    if (this.lco.channels) {
      this.channelIds = Object.keys(this.lco.channels);
    }
  }

  mapFetchUpdatePackageListtoSelectedList(
    pklist: PackageInfo[],
    sharePercentList: any
  ) {
    pklist.forEach((val) => {
      val.checked = false;
      if (!(sharePercentList[val.id] === undefined)) {
        val.checked = true;
        this.selectedPackageList.push(val);
      }
    });
  }

  fetchCreateData(): void {
    this.fetchDistributorList();
    forkJoin([this.fetchLocationList()]).subscribe((data) => {
      this.locationList = data[0];
    });
  }

  initForm(): void {
    this.lcoForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      contactPerson: new FormControl('', [Validators.required]),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        // asyncValidators: [this.emailValidator()]
      }),
      mobile: new FormControl('', {
        validators: [
          Validators.required,
          // Validators.pattern(this.phonePattern)
        ],
        // asyncValidators: [this.phoneValidator()]
      }),
      landline: new FormControl('', {
        validators: [
          Validators.required,
          // Validators.pattern(this.phonePattern)
        ],
        // asyncValidators: [this.phoneValidator()]
      }),
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
      feedType: new FormControl(''),
      status: new FormControl(1, [Validators.required]),
      address: new FormControl('', [Validators.required]),
      locationId: new FormControl(null, [Validators.required]),
      gstNo: new FormControl('', Validators.required),
      transferPrice: new FormControl(0),
      distributorId: new FormControl(null, [Validators.required]),
    });

    this.conditionalValidators();
    if (this.isDistributor) {
      this.setDistributorId();
    }
  }

  distributorDropDown = {
    display: 'block',
  };
  setDistributorId() {
    this.distributorDropDown = {
      display: 'none',
    };
    this.lcoForm.get('distributorId').setValue(this.auth.getLcoId());
  }

  get email(): FormControl {
    return this.lcoForm.get('email') as FormControl;
  }

  get mobile(): FormControl {
    return this.lcoForm.get('mobile') as FormControl;
  }

  get landline(): FormControl {
    return this.lcoForm.get('landline') as FormControl;
  }

  get password(): FormControl {
    return this.lcoForm.get('password') as FormControl;
  }

  get cpassword(): FormControl {
    return this.lcoForm.get('confirmPassword') as FormControl;
  }

  get feedType(): FormControl {
    return this.lcoForm.get('feedType') as FormControl;
  }

  conditionalValidators(): void {
    if (!this.isEdit) {
      this.lcoForm.get('password').setValidators(Validators.required);
      this.lcoForm.get('password').setValidators(Validators.minLength(8));
      this.lcoForm
        .get('password')
        .setValidators(Validators.pattern(this.passwordRegex));
      this.lcoForm.get('confirmPassword').setValidators(Validators.required);
      this.lcoForm.setValidators(this.confirmPasswordValidator);
    }
  }

  selectedChannelListForUpdate = [];
  selectedBouquetListForUpdate = [];
  updateForm(): void {
    this.lcoForm.get('name').setValue(this.lco.name);
    this.lcoForm.get('contactPerson').setValue(this.lco.contactPerson);
    this.lcoForm.get('email').setValue(this.lco.email);
    this.lcoForm.get('mobile').setValue(this.lco.mobile);
    this.lcoForm.get('landline').setValue(this.lco.landline);
    this.lcoForm.get('address').setValue(this.lco.address);
    this.lcoForm.get('status').setValue(this.lco.status);
    this.lcoForm.get('transferPrice').setValue(this.lco.transferPrice);
    this.lcoForm.get('locationId').setValue(this.lco.locationId);
    this.lcoForm.get('feedType').setValue(this.lco.feedType);
    this.lcoForm.get('distributorId').setValue(this.lco.distributorId);
    this.lcoForm.get('gstNo').setValue((this.lco as any).gstNo);
    this.selectedBouquetListForUpdate;
    for (let ele of Object.keys(this.lco.bouquets)) {
      this.selectedBouquetListForUpdate.push({
        ...this.lco.bouquets[ele],
        id: ele,
      });
    }
    this.selectedChannelListForUpdate = JSON.parse(
      JSON.stringify(Object.keys(this.lco.channels))
    );
    this.getBoquetByDistributorId();
  }

  persist(): void {
    this.isEdit ? this.update() : this.save();
  }

  createBody() {
    let obj = JSON.parse(JSON.stringify(this.lcoForm.value));
    delete obj.confirmPassword;
    let newObj = {
      ...this.lco,
      ...obj,
    };
    return newObj;
  }

  copyOfPackagesList;
  isSearch = false;

  search() {
    let input = document.getElementById('searchBox') as HTMLInputElement;
    let value = input.value;
    this.isSearch = !this.isSearch;
    this.mapSelectedPackages();
    let url = `${this.config.json.apiEndpoint}bouquet/getBouquetSearch?search=${value}`+'&distributorId='+this.distributorId;
    this.copyOfPackagesList = JSON.parse(JSON.stringify(this.packageList));
    this.http.get(url).subscribe({
      next: (res) => {
        this.packageList = res.data;
       this.checkSelectedPackages();
      },
    });
  }

  selectedIdsFromSearch = [];

  checkSelectedPackages(){
    console.log(this.selectedPackageList)
    for(let i of this.packageList){
      for(let j of this.selectedPackageList)
        {
          if(i.id == j.id){
            i.checked= true;
          }
        }
    }
  }

  cancel() {
    this.isSearch = false;
    for (let ele of this.packageList) {
      if (ele.checked == true) {
        this.selectedIdsFromSearch.push(ele.id);
      }
    }
    this.packageList = JSON.parse(JSON.stringify(this.copyOfPackagesList));
    for (let ele of this.packageList) {
      if (this.selectedIdsFromSearch.includes(ele.id)) {
        ele.checked = true;
      }
    }
    let input = document.getElementById('searchBox') as HTMLInputElement;
    input.value = '';
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'lco/createLCO';
    console.log(this.createBody());
    this.http.post(url, this.createBody()).subscribe(
      (data) => {
        if (data.statusCode == 200) {
          if (this.wizardNav === this.verifiedWizardNav) {
            this.verifiedWizardNav++;
          }
          this.wizardNav++;
        } else {
          alert(data.message);
        }
      },
      (error) => {
        window.alert(error.error['message']);
        // window.alert(err.error.message);
      }
    );
  }

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'lco/updateLCO';
    console.log(this.lco);

    this.http.put(url, this.lco).subscribe(
      (data) => {
        // this.router.navigate(['lco', 'list']);

        if (data.statusCode == 200) {
          if (this.wizardNav === this.verifiedWizardNav) {
            this.verifiedWizardNav++;
          }
          this.wizardNav++;
        } else {
          alert(data.message);
        }
      },
      (err) => {
        // window.alert(err.error.error);
        window.alert(err.error['message']);
      }
    );
  }

  updateModel(): void {
    this.lco.name = this.lcoForm.get('name').value;
    this.lco.contactPerson = this.lcoForm.get('contactPerson').value;
    this.lco.email = (this.lcoForm.get('email').value as string).toLowerCase();
    this.lco.mobile = this.lcoForm.get('mobile').value;
    this.lco.landline = this.lcoForm.get('landline').value;
    this.lco.address = this.lcoForm.get('address').value;
    this.lco.status = this.lcoForm.get('status').value;
    this.lco.locationId = this.lcoForm.get('locationId').value;
    this.lco.transferPrice = this.lcoForm.get('transferPrice').value;
    this.lco.feedType = this.lcoForm.get('feedType').value;
    this.lco.distributorId = this.lcoForm.get('distributorId').value;
    (this.lco as any).gstNo = this.lcoForm.get('gstNo').value;
    // this.lco.lcoCode = this.lcoForm.get('lcoCode').value
    this.lco.type = 0;
    if (!this.isEdit) {
      this.lco.password = this.lcoForm.get('password').value;
    }
  }

  submit(): void {
    this.persist();
  }

  review(): void {
    // this.mapSelectedPackages();
    // this.mapSelectedChannels();
    console.log('review');

    this.submitMapLcoPackageData();
    this.submitMapLcoChannelData();
    this.updateModel();
  }

  submitMapLcoPackageData(): void {
    const obj = {};
    console.log(this.selectedLcoPackageList);

    this.selectedLcoPackageList.forEach((val) => {
      obj[val.id] = {
        discountPercent: val.discountPercent,
        lcoSharePercent: val.lcoSharePercent,
        lcogivenAmount: (val as any).lcogivenAmount,
      };
    });
    this.lco.bouquets = obj;
    console.log(this.lco);
  }

  submitMapLcoChannelData(): void {
    const obj = {};
    this.selectedLcoChannelList.forEach((val) => {
      obj[val.channel_id] = {
        discountPercent: val.discountPercent,
        lcoSharePercent: val.lcoSharePercent,
      };
    });
    this.lco.channels = obj;
  }

  wizardNavigationNext(): void {
    switch (this.wizardNav) {
      case 0: {
        if (this.wizardNav === this.verifiedWizardNav) {
          this.verifiedWizardNav = 1;
        }
        this.wizardNav = 1;
        this.getCurrentWizardItem();
        this.fetchPackageList();
        this.loadingFlagPackage = true;
        break;
      }
      case 1: {
        if (this.wizardNav === this.verifiedWizardNav) {
          this.verifiedWizardNav = 2;
        }
        this.wizardNav = 2;

        this.getCurrentWizardItem();
        this.mapSelectedPackages();
        break;
      }
      case 2: {
        if (this.wizardNav === this.verifiedWizardNav) {
          this.verifiedWizardNav = 5;
        }
        this.wizardNav = 5;
        this.getCurrentWizardItem();
        this.review();
        break;
      }
      case 3: {
        if (this.wizardNav === this.verifiedWizardNav) {
          this.verifiedWizardNav = 4;
        }
        this.wizardNav++;
        this.getCurrentWizardItem();
        this.mapSelectedChannels();
        break;
      }
      case 4: {
        if (this.wizardNav === this.verifiedWizardNav) {
          this.verifiedWizardNav = 5;
        }
        this.wizardNav++;

        break;
      }
      case 5: {
        this.getCurrentWizardItem();
        this.submit();
        break;
      }
      case 6: {
        this.getCurrentWizardItem();
        break;
      }
    }
  }

  wizardNavigationPrevious(): void {
    switch (this.wizardNav) {
      case 0: {
        this.getCurrentWizardItem();
        break;
      }
      case 1: {
        this.wizardNav = 0;
        this.getCurrentWizardItem();
        break;
      }
      case 2: {
        this.wizardNav = 1;
        this.getCurrentWizardItem();
        // this.fetchPackageList();
        break;
      }
      case 3: {
        this.wizardNav = 2;
        this.getCurrentWizardItem();
        this.mapSelectedPackages();
        break;
      }
      case 4: {
        this.wizardNav = 3;
        this.getCurrentWizardItem();
        break;
      }
      // .wizardNav = 4 was wrong changed to 2
      case 5: {
        this.wizardNav = 2;
        this.getCurrentWizardItem();
        this.mapSelectedChannels();
        break;
      }
      case 6: {
        this.wizardNav--;
        this.getCurrentWizardItem();
        this.review();
        break;
      }
    }
  }

  wizardDirectNavigation(section: number): void {
    if (this.verifiedWizardNav >= section) {
      this.wizardNav = section;
      if (section === 4) {
        this.mapSelectedChannels();
      }
      if (section === 1) {
        this.fetchPackageList();
      }
      if (section === 2) {
        this.mapSelectedPackages();
      }
      if (section === 5) {
        this.review();
      }
    }
    this.getCurrentWizardItem();
  }

  mapSelectedChannels(): void {
    const transferp = this.lcoForm.get('transferPrice').value;
    this.selectedChannelList = this.store.lcoSelectedChannelList;
    this.selectedLcoChannelList = this.selectedChannelList.map((ch) => {
      return {
        channel_id: ch.channel_id,
        name: ch.name,
        price: ch.price,
        discountPercent: 0,
        discountPrice: ch.price,
        lcoSharePercent: transferp,
        lcoSharePrice: (ch.price * transferp) / 100,
        selected: false,
      } as any;
    });
  }

  ngModelChange(): number {
    return 0;
  }

  updateLcoChannelDiscountPrice(ch: LcoChannelInfo) {
    ch.discountPrice = ch.price - (ch.price * ch.discountPercent) / 100;
    ch.lcoSharePrice = (ch.discountPrice * ch.lcoSharePercent) / 100;
  }

  updateLcoChannelTotalPrice(): number {
    let total = 0;
    this.selectedLcoChannelList.forEach((ch) => {
      total += ch.lcoSharePrice;
    });
    return total;
  }

  // **************************************************************************************
  // Custom Async Validators
  // **************************************************************************************

  emailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkEmail(control.value).pipe(
        map((res) => {
          // duplicate is the key name in response coming from server
          return res.duplicate ? { duplicate: true } : null;
        })
      );
    };
  }

  checkEmail(value: string): Observable<any> {
    const url = this.config.json.apiEndpoint + 'lco/duplicateEmailCheck';
    const body = {
      email: value,
      id: this.isEdit ? this.lco.id : null,
    };

    return this.http.post(url, body);
  }

  phoneValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkPhone(control.value).pipe(
        map((res) => {
          // duplicate is the key name in response coming from server
          return res.duplicate ? { duplicate: true } : null;
        })
      );
    };
  }

  checkPhone(value: string): Observable<any> {
    const url = this.config.json.apiEndpoint + 'lco/duplicatePhoneCheck';
    const body = {
      phone: value,
      id: this.isEdit ? this.lco.id : null,
    };

    return this.http.post(url, body);
  }

  confirmPasswordValidator(group: AbstractControl): ValidationErrors | null {
    if (group.get('confirmPassword').value !== group.get('password').value) {
      return { mismatch: true };
    }
  }

  distributorId: string = this.auth.isDistributor()
    ? this.auth.getLcoId()
    : undefined;
  getBoquetByDistributorId(e?: Event) {
    if (e) {
      this.distributorId = (e?.target as HTMLSelectElement).value;
    } else {
      if (this.lcoForm) {
        this.distributorId = this.lcoForm.get('distributorId').value;
      }
    }
  }

  fetchPackageListObs(): Observable<PackageInfo[]> {
    const url =
      this.config.json.apiEndpointRdbms +
      'bouquet/getBouquetsByDistributor/'+this.distributorId;
    return this.http.get(url);
  }

  fetchPackageList(): void {
    this.fetchPackageListObs().subscribe((data) => {
      this.handleFetchPackageList(data);
    });
  }

  handleFetchPackageList(data: PackageInfo[]): void {
    //edi here
    this.packageList = data['data'];
    this.loadingFlagPackage = false;

    if (this.packageList != null || this.packageList?.length != 0) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
    }
    if (this.selectedBouquetListForUpdate.length != 0) {
      for (let item of this.packageList) {
        this.selectedBouquetListForUpdate.some((obj2) => {
          if (obj2.id === String(item.id)) {
            item.checked = true;
            this.selectedPackageList.push(item);
            console.log("hai");
            (item as any)['lcoSharePercent'] = obj2.lcoSharePercent;
            (item as any)['lcogivenAmount'] = obj2.lcogivenAmount;
            (item as any)['discountPercent'] = obj2.discountPercent;
          }
        });
      }
    }
    console.log(this.selectedPackageList)
    console.log(this.packageList);
  }

  packageSelectChecked(pk: PackageInfo): void {
    if (pk.checked) {
      this.selectedPackageList.push(pk);
    } else {
      for (let ele of this.packageList) {
        if(pk.id==ele.id){
          ele.checked=false;
        }
      }
      const index = this.selectedPackageList.findIndex((ch) => ch.id === pk.id);
      index !== -1 && this.selectedPackageList.splice(index, 1);
    }
  }

  selectAll() {
    this.selectAllClicked = true;
    for (let i of this.packageList) {
      i.checked = true;
      this.selectedPackageList.push(i);
    }
  }

  unselectAll() {
    this.selectAllClicked = false;
    for (let i of this.packageList) {
      i.checked = false;
      const index = this.selectedPackageList.findIndex((ch) => ch.id === i.id);
      index !== -1 && this.selectedPackageList.splice(index, 1);
    }
  }

  newDiscount;
  LcoShareDiscount;
  mapSelectedPackages(): void {
    const transferp = this.lcoForm.get('transferPrice').value;
    console.log(this.packageList)
    for (let ele of this.packageList) {
      if (ele.checked) {
        console.log(ele);
        let exists=false
        for(let ele1 of this.selectedLcoPackageList){
          if(ele.id == ele1.id){
            exists=true
          }
        }
        if(exists==false){
          if(ele['packType']!="ALACARETE_PACK"){
            (this.selectedLcoPackageList as any).push({
              ...ele,
              id: ele.id,
              name: ele.name,
              price: ele.price,
              discountPrice: (ele as any).discountPrice,
              lcoSharePercent:(ele as any).lcoSharePercent,
              lcoSharePrice: (ele as any).lcoSharePrice,
              lcogivenAmount: (ele as any).lcogivenAmount,
              selected: ele.checked,
            });
          }
          else
          (this.selectedLcoPackageList as any).push({
            ...ele,
            id: ele.id,
            name: ele.name,
            price: ele.price,
            discountPrice: (ele as any).discountPrice,
            lcoSharePercent:(ele as any).lcoSharePercent?(ele as any).lcoSharePercent:(ele.price-(ele.price*this.lcoPercent)).toFixed(2),
            lcoSharePrice: (ele as any).lcoSharePrice,
            lcogivenAmount: (ele as any).lcogivenAmount,
            selected: ele.checked,
          });
        }
      }
      else{
        for (let ele1 of this.selectedLcoPackageList) {
          if(ele.id==ele1.id){
            const index = this.selectedLcoPackageList.findIndex((ch) => ch.id === ele.id);
            this.selectedLcoPackageList.splice(index,1)
          }
        }
      }
    }

    // this.selectedLcoPackageList = (this.selectedPackageList as any).map(
    //   (ch) => {
    //     console.log("item", ch);

    //     return {
    //       id: ch.id,
    //       name: ch.name,
    //       price: ch.price,
    //       discountPercent: ch.discountPercent as number,
    //       discountPrice: ch.discountPrice,
    //       lcoSharePercent: ch.lcoSharePercent,
    //       lcoSharePrice: ch.lcoSharePrice,
    //       selected: ch.selected,
    //     } as any;
    //   }
    // );
  }

  editDirectToReviewFunc(): void {
    this.mapSelectedPackages();
    this.mapSelectedChannels();
    this.submitMapLcoChannelData();
  }

  discountPercentChanged(e: Event, ch: any, index: number) {
    let value = parseInt((e.target as HTMLInputElement).value);
    //  ch.price - (ch.price * newDiscount/100)
    let discountPrice = ch.price - ch.price * (value / 100);
    ch.discountPrice = discountPrice;
    ch.discountPercent = value;
    ch.lcoSharePrice = (ch.discountPrice * ch.lcoSharePercent) / 100;
    this.selectedLcoPackageList[index] = ch;
  }

  lcoSharePercentChanged(e: Event, ch: any, index: number) {
    let value: string | number = (e.target as HTMLInputElement).value;
    if (value == '') {
      value = '0';
      return;
    }
    value = parseInt(value) as number;
    if (value < 0) {
      value *= -1;
    }
    if (value <= +ch.price) {
      ch.lcoSharePercent = value;
      ch.lcoSharePrice = ch.discountPrice * (value / 100);
      this.selectedLcoPackageList[index] = ch;
    } else {
      alert('Price can not be greater than MRP');
      ch.lcoSharePercent = '0';
    }
  }

  lcoSharePercentChangedforChannels(e: Event, ch: any, index: number) {
    let value = parseInt((e.target as HTMLInputElement).value);
    ch.lcoSharePercent = value;
    ch.lcoSharePrice = ch.discountPrice * (value / 100);
    this.selectedLcoChannelList[index] = ch;
  }

  updateLcoPackageTotalPrice(): number {
    let total = 0;
    this.selectedLcoPackageList.forEach((ch) => {
      total += ch.lcoSharePrice;
    });
    return total;
  }

  getCurrentWizardItem(): void {
    switch (this.wizardNav) {
      case 0:
        this.wizardNavname = ' - Basic Details';
        break;
      case 1:
        this.wizardNavname = ' - Add Packages';
        break;
      case 2:
        this.wizardNavname = ' - Set Package Prices';
        break;
      case 3:
        this.wizardNavname = ' - Add ala-carte Channels';
        break;
      case 4:
        this.wizardNavname = ' - Set Channel Prices';
        break;
      case 5:
        this.wizardNavname = ' - Review';
        break;
      case 6:
        this.wizardNavname = ' - Confirmation';
        break;
    }
  }

  isUp = true;
  @ViewChild('tableBody', { static: false }) tableBody: ElementRef;
  goscroll() {
    if (this.isUp) {
      window.scrollTo(0, document.body.scrollHeight);
      this.isUp = false;
    } else {
      window.scrollTo(document.body.scrollHeight, 0);
      this.isUp = true;
    }
  }

  isScrolling = false;
  numError = false;
  mobError = false;
  isPatternCorrect = false;
  numValidation(ev, phone) {
    if (phone == 'mobile') {
      if (isNaN(ev.target.value)) {
        this.numError = true;
      }
      if (!isNaN(ev.target.value)) {
        let phoneRegex: RegExp = /^[6-9]\d{9}$/;
        let value = ((ev as Event).target as HTMLInputElement).value;
        console.log('testing');
        if (phoneRegex.test(value)) {
          console.log('pass');
          this.isPatternCorrect = false;
          this.lcoForm.get('mobile').setErrors(null);
        } else {
          this.isPatternCorrect = true;
          this.lcoForm.get('mobile').setErrors({ pattern: true });
        }
        this.numError = false;
      }
    }
    if (phone == 'landline') {
      if (isNaN(ev.target.value)) {
        this.mobError = true;
      }
      if (!isNaN(ev.target.value)) {
        this.mobError = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.store.lcoSelectedChannelList = [];
  }
}
