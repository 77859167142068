<div class="cms__content">
  <div class="top__heading">
    <h3>Subscribers SMS Logs</h3>
    <a [routerLink]="['/logs']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>

  <div class="row">
    <div class="download__report col-md-6">
      <button
        class="default__button"
        (click)="downloadTablePdf()"
        title="Download PDF"
      >
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableExcel()"
        title="Download Excel"
      >
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button
        class="default__button"
        (click)="downloadTableCSV()"
        title="Download Excel"
      >
        CSV
        <img src="./assets/csv.svg" alt="download" />
      </button>
    </div>
  </div>
  <div class="custom__table__filter">
    <span>
      Show
      <select
        [(ngModel)]="paginationObj.pageSize"
        (change)="pageSizeChange($event)"
      >
        <option
          *ngFor="let size of paginationObj.pageSizeOptions"
          [value]="size"
        >
          {{ size }}
        </option>
      </select>
      Entries
    </span>
    <div class="table__right">
      <p class="table__summary">
        Total <span>{{ paginationObj.totalElements }}</span> records
      </p>
      <div class="search__container">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          (input)="searchText = $event.target.value"
          (keydown.enter)="searchKeyword()"
        />
        <button>
          <img
            src="./assets/search.png"
            alt="search"
            (click)="searchKeyword()"
          />
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 list__table">
    <div class="">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Date</th>
            <th>Mobile Number</th>
            <th>Message Body</th>
            <th>Response Body</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList.length == 0 && !loadingFlag">
            <td style="text-align: center !important" colspan="6">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngIf="loadingFlag; else showList">
            <td style="text-align: center !important" colspan="6">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <ng-template #showList>
            <tr *ngFor="let report of reportList; let j = index">
              <td>
                {{
                  (paginationObj.pageNumber - 1) * paginationObj.pageSize +
                    (j + 1)
                }}
              </td>
              <td>{{ report.createdDate | date }}</td>
              <td>{{ report.mobileNumber }}</td>
              <td>{{ report.messageBody }}</td>
              <td>{{ report.responseBody }}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="custom__pagination">
        <ul>
          <li>
            <button
              [disabled]="disablePreviousBtn"
              (click)="pageFirstBtnClick()"
            >
              <img src="./assets/firstPage.png" />
            </button>
          </li>
          <li>
            <button
              [disabled]="disablePreviousBtn"
              (click)="pagePreviousBtnClick()"
            >
              <img src="./assets/previousPage.png" />
            </button>
          </li>
          <li>
            <span
              >Page {{ paginationObj.pageNumber }} of
              {{ paginationObj.totalPages }}</span
            >
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" />
            </button>
          </li>
          <li>
            <button [disabled]="disableNextBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
