import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-set-packages-prices',
  templateUrl: './set-packages-prices.component.html',
  styleUrls: ['./set-packages-prices.component.scss'],
})
export class SetPackagesPricesComponent implements OnInit {
  @Input() selectedPackages!: any;
  @Output() packagePrices = new EventEmitter();
  @Output() goBack = new EventEmitter();
  constructor( private http: HttpClientWrapperService,
    private config: ConfigService,) {}

    distributoPercent

  packagesList;
  ngOnInit(): void {
    this.getDistributorPercent();
  }

  getSelectedObjects(){
    console.log(this.distributoPercent)
    if (this.selectedPackages) {
      this.packagesList = JSON.parse(JSON.stringify(this.selectedPackages));
      for (let ele of this.packagesList) {
        if(ele.packType!="ALACARETE_PACK"){
          if(!ele['priceUpdate'])
            ele['priceUpdate'] = {[ele.id]: {distributorShare: ele.share?.distributorShare? ele.share.distributorShare: 0},
            };
        }else{
          if(!ele['priceUpdate'])
            ele['priceUpdate'] = {[ele.id]: {distributorShare: ele.share?.distributorShare? ele.share.distributorShare: ((ele.price*this.distributoPercent).toFixed(2))},
            };
            else{
              ele['priceUpdate'] = {[ele.id]: {distributorShare: ele.share?.distributorShare? ele.share.distributorShare:((ele.price*this.distributoPercent).toFixed(2))}
            }
          }
        }
      }
    }
  }


  getDistributorPercent(){
    let url=this.config.json.apiEndpointRdbms+'setting/getLcoAndDistributorPercent'
    this.http.get(url).subscribe(
      (res)=>{
        this.distributoPercent = res.data["distributor_percent"]
        this.distributoPercent= this.distributoPercent/100;
        console.log(this.distributoPercent)
        this.distributoPercent= this.distributoPercent.toFixed(2)
        this.getSelectedObjects()
      }
    )
  }

  updatePrice(e: Event, id: string, price: string) {
    let value: string | number = (e.target as HTMLInputElement).value
    if(value  == ''){
      value = '0';
      return
    }
    value = parseInt(value) as number;
    if(value < 0){
      value *= -1
    }
    if (value <= +price) {
      let obj = {
        [id]: {
          distributorShare: +value,
        },
      };
      for (let ele of this.packagesList) {
        if (ele.id == id) {
          ele['priceUpdate'] = obj;
        }
      }
      for (let ele of this.selectedPackages) {
        if (ele.id == id) {
          ele['priceUpdate'] = obj;
        }
      }
    } else {
      alert('Price can not be greater than MRP');
      let input = e.target as HTMLInputElement;
      input.value = price;
    }
  }

  setPackagePrices() {
   console.log(this.packagePrices)
    this.packagePrices.emit(this.packagesList);
  }

  back() {
    this.goBack.emit(true);
  }
}
export type PriceUpdateType = {
  [id: string]: {
    distributorShare: string;
  };
};
