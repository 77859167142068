<div class="cms__content">
  <div class="top__heading">
    <h3>Create Channels</h3>
    <a [routerLink]="['/batch-jobs']"
      ><button class="default__button float-right">Back</button></a
    >
    <button
      (click)="saveSample()"
      id="downloadSample"
      class="default__button float-right"
    >
      <img
        style="width: 15px; height: fit-content; fill: black;"
        src="assets/download.svg"
        alt=""
      />
      &nbsp; Download Sample
    </button>
  </div>
  <div class="col-12 uploadSection">
    <div class="row">
      <div class="col-12 col-md-6 offset-md-3 uploadFile">
        <p id="pNote">
          <br />
          Note : <br />
          1. Please Ensure that no Cell in Excel is left Empty <br />
          2. Please ensure that there are no empty rows present in the
          spreadsheet
        </p>
        <span>Upload File</span>
        <input type="file"
          id="chooseFile"
          class="custom__field"
          accept=".xls, .xlsx"
          (change)="selectedFile($event)"
        />
        <p>(File types : Excel | .xls, .xlsx )</p>
        <button id="clearInput" (click)="clearInput()">Clear</button>

        <p id="pNote">{{message}}</p>
        <ng-container *ngIf="isSubmitReady">
          <button class="btn btn-primary" (click)="uploadFile()">Submit</button>
          &nbsp; {{successMessage}}
        </ng-container>
        <div class="errorMessagebox" *ngIf="errorAvailable">
          <h5>Errors Found :</h5>
          <ng-container *ngFor="let msg of errorBody">
            {{msg}}<br />
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
