import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';

@Component({
  selector: 'app-subscriber-search',
  templateUrl: './subscriber-search.component.html',
  styleUrls: ['./subscriber-search.component.scss'],
})
export class SubscriberSearchComponent implements OnInit {
  @Input() searchText: string;
  @Output() userDetail: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    private configService: ConfigService,
    private http: HttpClientWrapperService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.searchText) {
      this.fetchSearchData(this.searchText);
    }
  }

  users;
  isEmpty = false;
  fetchSearchData(searchText: string) {
    let lcoCode = this.authService.isLCO() ? this.authService.getLcoId() : '0';
    let url =
      this.configService.json.apiEndpointRdbms +
      `subscriber/searchUser/${lcoCode}/${searchText}`;
    this.http.get(url).subscribe({
      next: (data: any) => {
        console.log(data);
        console.log(this.isEmpty );
        this.users = data.data;
        if (data.data == null || data.data?.length == 0) {
          this.isEmpty = true;
        }
      },
    });
  }

  getUser(user: any) {
    this.userDetail.emit(user);
  }
}
