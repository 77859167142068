import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ChannelInfo } from 'src/app/models/channel/channel-info';
import { NgxDateRangeInfo } from 'src/app/models/report/date-range-picker-ngx-info';
import { ReportCalendarService } from 'src/app/services/report-calendar.service';
import { LogsDataService } from 'src/app/logs/plugins/logs-data.service';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { BroadcasterInfo } from 'src/app/models/broadcaster/broadcaster-info';
import { ngxCsv } from 'ngx-csv';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';
import { PaginationObj, PaginationUtilities } from 'src/app/models/pagination/pagination-info';
import { PageInfo, PageMetadata, SortOrder } from 'src/app/models/pagination/page-info';

@Component({
  selector: 'app-logs-channel-modification-new',
  templateUrl: './logs-channel-modification-new.component.html',
  styleUrls: ['./logs-channel-modification-new.component.scss']
})
export class LogsChannelModificationNewComponent implements OnInit {
  reportList = [];

  channelList: ChannelInfo[] = [];
  selectedChannelId: number;

  broadcasterList: BroadcasterInfo[] = [];
  selectedBroadcasterId: any = 0;

  from: any;
  to: any;
  paginationObj = new PaginationObj();
  currentDate = new Date();
  yearsArray = [];

  sortByProperties = {
    srNo: 'srNo',
    category: 'category',
    username: 'username',
    broadcasterName:'broadcasterName',
    description: 'description',
    ACTION: 'ACTION',
    fromDate:'fromDate',
    channelId:'channelId',
    channelNo:'channelNo',
    channelName:'channelName'
  };

  @ViewChild('reportTable', { static: false }) reportTable: ElementRef;

  constructor(
    private report: LogsDataService,
    private calendar: ReportCalendarService,
    private doc: DocumentGeneratorService,
    private pdf: PdfGeneratorService,
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) { }

  sortByItem(item: string): void {
    this.paginationObj.sortBy = item;
    this.paginationObj.sortOrder = this.paginationObj.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    this.fetchData();
  }
  
 


  ngOnInit(): void {
    this.yearsArrayCalculator();
    this.fetchBroadcasterList();
    this.fetchData()
  }
  yearsArrayCalculator() {
    this.yearsArray = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2019; i--) {
      this.yearsArray.push(i);
    }
  }

  isLoad = '';
  isLoading = false;
  
  showMonth = false;
  showDateRange = false;
  showYearSelector = false;
  dateSelector = true;
  dateTypeSelectors(num) {
    if (num == 0) {
      this.showYearSelector = false;
      this.dateSelector = true;
      this.showMonth = false;
      this.showDateRange = false;
      this.body ={
        fromDate: '',
        toDate: '',
        tillDate: '',
      }
      this.fetchData();
    }
    if (num == 1) {
      this.showYearSelector = false;
      this.dateSelector = false;
      this.showMonth = true;
      this.showDateRange = false;
    }
    if (num == 2) {
      this.showYearSelector = false;
      this.dateSelector = false;
      this.showDateRange = true;
      this.showMonth = false;
    }
    if (num == 3) {
      this.showYearSelector = true;
      this.dateSelector = false;
      this.showDateRange = false;
      this.showMonth = false;
    }
  }

  isDateSelected = false;
  eve;
  selectBouquetType:any="All"
  reportDate =
    this.reportList.length == 0 ? 'Not Selected' : 'From Start to Now';
  dateChangeRange(ev) {
    let startDate = ev.startDate.format('YYYY-MM-DD');
    let endDate = ev.endDate.format('YYYY-MM-DD');
    this.body = {
      fromDate: startDate,
      toDate: endDate,
      tillDate: '',
    };
    this.reportDate = this.body.fromDate + ' to ' + this.body.toDate;
    this.fetchData();
  }

  dateChangeMonth(ev) {
    let dateobj = new Date(ev.target.value);
    let tillDate =
      dateobj.getFullYear() +
      '-' +
      String(dateobj.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(dateobj.getDate()).padStart(2, '0');
    this.body = {
      fromDate: '',
      toDate: '',
      tillDate: tillDate,
    };
    this.reportDate = this.body.tillDate;
    this.fetchData();
  }

  yearFilter() {
    this.isLoading = true;
    this.reportList = [];
    let year = (document.getElementById('yearCalc') as HTMLSelectElement).value;
    this.reportDate = year;
    let fromDate = `${year}-01-01`;
    let toDate = `${year}-12-31`;
    this.body = {
      ...this.body,
      fromDate,
      toDate,
    };
    this.fetchData();
  }

  body={
    fromDate: this.formatDate("2019-01-01"),
    toDate: this.formatDate(new Date()),
    tillDate: '',
  }

  fetchData(){
    this.loadingFlag= true
    this.reportList = []
    PaginationUtilities.giveConfig(this.config.json.apiEndpoint)
    let url = PaginationUtilities.urlBuilder('logs/channelModification', this.paginationObj)
    url += `&broadcasterId=${ this.selectedBroadcasterId}&fromDate=${this.body.fromDate}&toDate=${this.body.toDate}`
    url += '&action='+this.selectBouquetType
    this.http.get(url).subscribe({
      next: (data) => {
        this.reportList = data.data;
        this.handlePageMetadata(data.metadata)
      if (this.reportList.length != 0) {
        this.loadingFlag = false
      } else {
        this.loadingFlag = false
        this.isLoad = 'No Records'
      }
      }
    })
  }

  handlePageMetadata(data: any) {
    this.paginationObj.pageNumber = data.pageNumber;
    this.paginationObj.pageSize = data.pageSize;
    this.paginationObj.totalElements = data.totalElementCount;
    this.paginationObj.totalPages = data.totalPageCount;
    this.paginationObj.searchKeyword = data.searchKeyword;
    this.handleButtonStatus()
  }

  loadingFlag = false
  fetchChannelList(): void {
    this.report.getChannelList().subscribe(data => {
      this.channelList = data;
    });
  }

  formatDate(ele) {
    var d = new Date(ele),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  fetchBroadcasterList(): void {
    this.report.getBroadcasterList().subscribe(data => {
      this.broadcasterList = data;
    });
  }

  listItem(e: Event): void {
    let broadcasterId = (e.target as HTMLSelectElement).value
    this.selectedBroadcasterId = broadcasterId
    this.body ={
      ...this.body,
     
    }
    this.fetchData()
  }


  dateSelected = false
  broadcasteSelected = false
  ev
  // reportDate;
  dateChange(ev: NgxDateRangeInfo): void {
    this.dateSelected = true
    if (ev.startDate !== null && ev.endDate !== null) {
      this.reportDate = ev.startDate.format('DD-MM-YYYY') + " to " + ev.endDate.format('DD-MM-YYYY')
      this.ev = ev
        [this.from, this.to] = [ev.startDate, ev.endDate];
        this.calendar.setDate(ev);
        this.body = {
          ...this.body,
          fromDate: ev.startDate.format('DD-MM-YYYY'),
          toDate: ev.endDate.format('DD-MM-YYYY')
        }
        this.fetchData()
        this.loadingFlag = true
        this.isLoad = 'Loading'
    }
  }
  // downloadTableExcel(): void {
  //   let name = 'Channel Modification Logs'
  //   if (this.reportList.length == 0) {
  //     alert('No Data!!')
  //   } else {
  //     if (this.ev) {
  //       this.doc.exportTableToExcelwithImage(this.reportTable, name, 'Channel Modification Logs', undefined, undefined, "Filter Date :", this.reportDate, "Broadcaster Name : ", this.selectedBroadcasterId ? this.selectedBroadcasterId.name : 'ALL');
  //     } else {
  //       this.doc.exportTableToExcelwithImage(this.reportTable, name, 'Channel Modification Logs', undefined, undefined,"Broadcaster Name : ",this.selectedBroadcasterId ? this.selectedBroadcasterId.name : 'ALL');
  //     }
  //   }
  // }

  downloadTableExcel(){
    let url
    
      url= this.config.json.apiEndpoint+'generate/channelModification?searchKeyword=&broadcasterId='+this.selectedBroadcasterId+'&fromDate='+this.body.fromDate+'&toDate='+this.body.toDate+'&action='+this.selectBouquetType
    
      this.http.getwithresponseType(url).subscribe(
        (response) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonResponse = JSON.parse(reader.result as string);
              if (jsonResponse.status === 'FAIL') {
               alert(`${jsonResponse.message}`);
                return;
              }
            } catch (error) {
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'channel_modification_logs.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          reader.readAsText(response);
        },
        (error) => {
          console.error('Error downloading Excel file:', error);
        }
      );
  }


  downloadTablePdf(): void {
    if (this.reportList.length == 0) {
      alert('No Data!!')
    } else {
      if (this.ev) {
        this.pdf.generatePdfHtml('#reportTable',
          'channel_modification_logs',
          `Filter date : ${this.reportDate}   Broadcaster Name: ${this.selectedBroadcasterId ? this.selectedBroadcasterId.name : 'ALL'}`, 'portrait'
        );
      } else {
        this.pdf.generatePdfHtml('#reportTable',
          'channel_modification_logs',
          `From Start to Now    Broadcaster Name: ${this.selectedBroadcasterId ? this.selectedBroadcasterId.name : 'ALL'}  `, 'portrait'
        );
      }
    }
  }



  downloadTableCSV(): void {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ["Sl No","Broadcaster Name", "Channel ID" ,"Channel Name", "Channel Number", "Action","Transaction Date"]
    };
    let data = []
    let i =1
    this.reportList.forEach(line => {
      let csvLine = {
        slno : i,
        broadcasterName: line.broadcasterName,
        channelId: line.channelId,
        channelName: line.channelName,
        channelNo: line.channelNo,
        action: line.action || line.ACTION,
        fromDate:line.fromDate
      }
      i+=1
      data.push(csvLine);
    });

    new ngxCsv(data, 'Channel_Modification_Logs', options);
  }

  disableFirstBtn = false;
  disablePreviousBtn = false;
  disableLastBtn = false;
  disableNextBtn = false;

  handleButtonStatus() {
    if (this.paginationObj.pageNumber == 1) {
      this.disableFirstBtn = true;
      this.disablePreviousBtn = true;
      this.disableLastBtn = false;
      this.disableNextBtn = false;
    } else if (
      this.paginationObj.pageNumber == this.paginationObj.totalPages
    ) {
      this.disableFirstBtn = false;
      this.disablePreviousBtn = false;
      this.disableLastBtn = true;
      this.disableNextBtn = true;
    } else {
      this.disableFirstBtn =
        this.disablePreviousBtn =
        this.disableNextBtn =
        this.disableLastBtn =
          false;
    }
  }

  searchKeyWord() {
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageSizeChange() {
    this.fetchData();
  }

  pageFirstBtnClick() {
    this.disableFirstBtn = true;
    this.disablePreviousBtn = true;
    this.paginationObj.pageNumber = 1;
    this.fetchData();
  }

  pageLastBtnClick() {
    this.disableLastBtn = true;
    this.disableNextBtn = true;
    this.paginationObj.pageNumber = this.paginationObj.totalPages;
    this.fetchData();
  }

  pageIncrease() {
    if (this.paginationObj.pageNumber == this.paginationObj.totalPages) {
      this.disableNextBtn = true;
      this.disableLastBtn = true;
    }
    this.paginationObj.pageNumber++;
    this.fetchData();
  }

  pageDecrease() {
    if (this.paginationObj.pageNumber == 1) {
      this.disablePreviousBtn = true;
      this.disableFirstBtn = true;
    }
    this.paginationObj.pageNumber--;
    this.fetchData();
  }


}
