<div class="dashboard">
  <div class="top-metrics">
    <div class="metric-card">
      <div class="title"><span class="material-icons">currency_rupee</span>Revenue Dashboard</div>
      <div class="time_period">
        <span [class.active]="selectedPeriodRevenue === '1d'" (click)="setPeriodRevenue('1d')">1d</span>
        <span [class.active]="selectedPeriodRevenue === '1w'" (click)="setPeriodRevenue('1w')">1w</span>
        <span [class.active]="selectedPeriodRevenue === '1m'" (click)="setPeriodRevenue('1m')">1m</span>
        <span [class.active]="selectedPeriodRevenue === '3m'" (click)="setPeriodRevenue('3m')">3m</span>
        <span [class.active]="selectedPeriodRevenue === '1y'" (click)="setPeriodRevenue('1y')">1y</span>
      </div>

      <div class="value" *ngIf="selectedPeriodRevenue === '1d'">{{ revenueDetails.today? (revenueDetails.today |
        currency:'INR'):('0'| currency:'INR')}}</div>
      <div class="value" *ngIf="selectedPeriodRevenue === '1w'">{{ revenueDetails.lastWeek? (revenueDetails.lastWeek |
        currency:'INR'):('0'| currency:'INR')}}</div>
      <div class="value" *ngIf="selectedPeriodRevenue === '1m'">{{
        revenueDetails.lastOneMonth?(revenueDetails.lastOneMonth | currency:'INR'):('0'| currency:'INR')}}</div>
      <div class="value" *ngIf="selectedPeriodRevenue === '3m'">
        {{revenueDetails.lastThreeMonth?(revenueDetails.lastThreeMonth | currency:'INR'):('0'| currency:'INR')}}</div>
      <div class="value" *ngIf="selectedPeriodRevenue === '1y'">{{revenueDetails.lastYear?(revenueDetails.lastYear |
        currency:'INR'):('0'| currency:'INR')}}</div>
      <!-- <div class="change positive">▲ 25% Increase Since Yesterday</div> -->
    </div>
    <div class="metric-card">
      <div class="title"><span class="material-symbols-rounded">group</span>Registrations</div>
      <div class="time_period">
        <span [class.active]="selectedPeriodRegistration === '1d'" (click)="setPeriodRegistration('1d')">1d</span>
        <span [class.active]="selectedPeriodRegistration === '1w'" (click)="setPeriodRegistration('1w')">1w</span>
        <span [class.active]="selectedPeriodRegistration === '1m'" (click)="setPeriodRegistration('1m')">1m</span>
        <span [class.active]="selectedPeriodRegistration === '3m'" (click)="setPeriodRegistration('3m')">3m</span>
        <span [class.active]="selectedPeriodRegistration === '1y'" (click)="setPeriodRegistration('1y')">1y</span>
      </div>
      <div class="value" *ngIf="selectedPeriodRegistration === '1d'">{{registrationDetails.today?
        registrationDetails.today:'0' }}</div>
      <div class="value" *ngIf="selectedPeriodRegistration === '1w'">{{
        registrationDetails.lastWeek?registrationDetails.lastWeek :'0' }}</div>
      <div class="value" *ngIf="selectedPeriodRegistration === '1m'">{{registrationDetails.lastOneMonth?
        registrationDetails.lastOneMonth:'0' }}</div>
      <div class="value" *ngIf="selectedPeriodRegistration === '3m'">{{
        registrationDetails.lastThreeMonth?registrationDetails.lastThreeMonth:'0' }}</div>
      <div class="value" *ngIf="selectedPeriodRegistration === '1y'">{{registrationDetails.lastYear?
        registrationDetails.lastYear:'0' }}</div>

      <!-- <div class="change positive">▲ 25% Increase Since Yesterday</div> -->
    </div>
    <div class="metric-card">
      <div class="title"><span class="material-symbols-rounded">subscriptions</span>Subscriptions</div>
      <div class="time_period">
        <span [ngClass]="{'active': selectedPeriodSUbscription === '1d'}"
          (click)="setPeriodSubscription('1d')">1d</span>
        <span [ngClass]="{'active': selectedPeriodSUbscription === '1w'}"
          (click)="setPeriodSubscription('1w')">1w</span>
        <span [ngClass]="{'active': selectedPeriodSUbscription === '1m'}"
          (click)="setPeriodSubscription('1m')">1m</span>
        <span [ngClass]="{'active': selectedPeriodSUbscription === '3m'}"
          (click)="setPeriodSubscription('3m')">3m</span>
        <span [ngClass]="{'active': selectedPeriodSUbscription === '1y'}"
          (click)="setPeriodSubscription('1y')">1y</span>
      </div>

      <div class="value" *ngIf="selectedPeriodSUbscription === '1d'">{{ susbscriptionDetails.today?
        susbscriptionDetails.today:'0' }}</div>
      <div class="value" *ngIf="selectedPeriodSUbscription === '1w'">{{
        susbscriptionDetails.lastWeek?susbscriptionDetails.lastWeek:'0' }}</div>
      <div class="value" *ngIf="selectedPeriodSUbscription === '1m'">{{
        susbscriptionDetails.lastOneMonth?susbscriptionDetails.lastOneMonth:'0' }}</div>
      <div class="value" *ngIf="selectedPeriodSUbscription === '3m'">{{susbscriptionDetails.lastThreeMonth?
        susbscriptionDetails.lastThreeMonth :'0' }}</div>
      <div class="value" *ngIf="selectedPeriodSUbscription === '1y'">{{
        susbscriptionDetails.lastYear?susbscriptionDetails.lastYear:'0' }}</div>


    </div>
    <div class="metric-card">
      <div class="title"><span class="material-icons">subscriptions</span>Expiring</div>
      <div class="time_period">
        <span [ngClass]="{'active': selectedPeriodExpiry === '1d'}" (click)="setPeriodExpiry('1d')">1d</span>
        <span [ngClass]="{'active': selectedPeriodExpiry === '1w'}" (click)="setPeriodExpiry('1w')">1w</span>
        <span [ngClass]="{'active': selectedPeriodExpiry === '1m'}" (click)="setPeriodExpiry('1m')">1m</span>
        <span [ngClass]="{'active': selectedPeriodExpiry === '3m'}" (click)="setPeriodExpiry('3m')">3m</span>
        <span [ngClass]="{'active': selectedPeriodExpiry === '1y'}" (click)="setPeriodExpiry('1y')">1y</span>
      </div>
      <div class="value" *ngIf="selectedPeriodExpiry === '1d'">{{ expiryDetails.today ?expiryDetails.today :'0' }}</div>
      <div class="value" *ngIf="selectedPeriodExpiry === '1w'">{{expiryDetails.inWeek ? expiryDetails.inWeek :'0' }}
      </div>
      <div class="value" *ngIf="selectedPeriodExpiry === '1m'">{{expiryDetails.inOneMonth? expiryDetails.inOneMonth :'0'
        }}</div>
      <div class="value" *ngIf="selectedPeriodExpiry === '3m'">{{ expiryDetails.inThreeMonth?expiryDetails.inThreeMonth
        :'0' }}</div>
      <div class="value" *ngIf="selectedPeriodExpiry === '1y'">{{expiryDetails.inYear? expiryDetails.inYear:'0' }}</div>
      >
    </div>
  </div>

  <div class="main-content">
    <div class="section" (click)="routetoSubscriberList()"  [ngStyle]="config.json.userType === 'MSO' ? {'cursor': 'pointer'} : {}">
      <div class="chart">
        <div class="animated-card-container" *ngIf="allZeroSub">
          <div class="animated-card card-1">
            <div class="card-dash"></div>
            <div class="card-dash2"></div>
          </div>
          <div class="animated-card card-2">
            <div class="card-dash"></div>
            <div class="card-text">No Data</div>
            <div class="card-dash2"></div>
          </div>
        </div>


        <div class="chart-content" *ngIf="!allZeroSub">
          <canvas id="myDoughnutChart"></canvas>
          <div>Active Includes user with and without STBs</div>
          <div class="chart-title">Total Number of Users</div>
        </div>

      </div>
    </div>

    <div class="section__table">
      <div class="top-5">
        <table class="styled-table">
          <thead>
            <tr>
              <th style="display: flex;  align-items: center; font-size: 14px;" *ngIf="sortOrder"><span
                  class="material-icons" style="color:#5A85E3">sort</span>Top 5 <span
                  class="material-symbols-outlined rotate-icon" (click)="sortOrder=!sortOrder;getTableTop5Data()">
                  switch_right
                </span>

              </th>
              <th style="display: flex;  align-items: center; font-size: 14px;" *ngIf="!sortOrder"><span
                  class="material-icons rotate-sort-icon" style="color:#5A85E3">sort</span>Least 5 <span
                  class="material-symbols-outlined rotate-icon"
                  (click)="sortOrder=!sortOrder;getTableTop5Data()">switch_left
                </span>
              </th>
              <th *ngIf="userType">LCOs By Subs.</th>
              <th *ngIf="userType">LCOs By ₹.</th>
              <th>Packs</th>
              <th>Channels</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of combinedData;let i = index">
              <td style="color: #5A85E3;font-weight: bold; font-size: 14px;"># {{i+1}}</td>
              <td *ngIf="userType">
                <div style="color: black;text-decoration: underline;margin-bottom: 2px;">{{data[0].lcoName}}</div>
                <div style="color:#ccc">{{data[0].lcoCode}} | {{data[0].subscriberCount}} Subs</div>
              </td>
              <td *ngIf="userType">
                <div style="color: black;text-decoration: underline;margin-bottom: 2px;">{{data[1].lcoName}}</div>
                <div style="color:#ccc">{{data[1].lcoCode}} | {{data[1].revenueAmount|currency:'INR'}}</div>
              </td>
              <td>
                <div style="color: black;text-decoration: underline;margin-bottom: 2px;">
                  {{data[2].bouquetName?data[2].bouquetName:'---'}}</div>
                <div style="color:#ccc"> {{data[2].bouquetPrice|currency:'INR'}}</div>
              </td>
              <td>
                <img src="{{config.json.imageUrlPrefix}}{{data[3].logoUrl}}" alt="" />
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>

    <div class="section" (click)="routetostb()"  [ngStyle]="config.json.userType === 'MSO' ? {'cursor': 'pointer'} : {}">
      <div class="chart">
        <div class="animated-card-container" *ngIf="allZeroSTB">
          <div class="animated-card card-1">
            <div class="card-dash"></div>
            <div class="card-dash2"></div>
          </div>
          <div class="animated-card card-2">
            <div class="card-dash"></div>
            <div class="card-text">No Data</div>
            <div class="card-dash2"></div>
          </div>
        </div>
        <div class="chart-content" *ngIf="!allZeroSTB">
          <canvas id="myDoughnutChartSTB"></canvas>
          <div>Count Includes hard and soft STBs</div>
          <div class="chart-title">STBs</div>
        </div>

      </div>
    </div>
  </div>

  <div class="footer-metrics">
    <div class="section" (click)="routetoActive()"  [ngStyle]="config.json.userType === 'MSO' ? {'cursor': 'pointer'} : {}">
      <div class="chart">
        <div class="animated-card-container" *ngIf="allZeroSubhalf">
          <div class="animated-card card-1">
            <div class="card-dash"></div>
            <div class="card-dash2"></div>
          </div>
          <div class="animated-card card-2">
            <div class="card-dash"></div>
            <div class="card-text">No Data</div>
            <div class="card-dash2"></div>
          </div>
        </div>
        <div class="chart-content" *ngIf="!allZeroSubhalf">
          <canvas id="myDoughnutCharthalf" style="width: '50%'; height:'50%';top:'0%'"></canvas>
        </div>
        <div class="chart-title" *ngIf="!allZeroSubhalf">Active Users</div>
      </div>
    </div>
    <div class="section__table">

      <div class="metric-card" *ngIf="userType">
        <div class="title"><span class="material-symbols-rounded">account_balance_wallet</span>Unused Wallet Balance
        </div>
        <div class="unusedone">
          <div>
            <div class="value">{{walletBalances['userUnusedWalletBalance']|currency:'INR'}}</div>
            <div class="description">Subscribers</div>
          </div>
          <div>
            <div class="value">{{walletBalances['lcoUnusedWalletBalance']|currency:'INR'}}</div>
            <div class="description">LCOs</div>
          </div>
          <div>
            <div class="value">{{walletBalances['distributorUnusedWalletBalance']|currency:'INR'}}</div>
            <div class="description">Distributors</div>
          </div>
        </div>
      </div>
      <div class="metric-card" *ngIf="!userType">
        <div class="title"><span class="material-symbols-rounded">notifications</span>Notifications
        </div>
        <div class="notifications" *ngFor="let data of notificationData|slice:0:5">
          <div>{{data.message}}</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="chart">
        <div class="animated-card-container" *ngIf="allZeroSTBhalf">
          <div class="animated-card card-1">
            <div class="card-dash"></div>
            <div class="card-dash2"></div>
          </div>
          <div class="animated-card card-2">
            <div class="card-dash"></div>
            <div class="card-text">No Data</div>
            <div class="card-dash2"></div>
          </div>
        </div>
        <div class="chart-content" *ngIf="!allZeroSTBhalf">
          <canvas id="myDoughnutCharthalfSTB" style="width: '50%'; height:'50%';top:'0%'"></canvas>
        </div>
        <div class="chart-title" *ngIf="!allZeroSTBhalf">Online STBs</div>
      </div>
    </div>
  </div>
</div>