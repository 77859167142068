import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccessControlListRoutingModule } from './access-control-list-routing.module';
import { AclComponent } from './acl/acl.component';
import { AclCreateComponent } from './acl-create/acl-create.component';


@NgModule({
  declarations: [
    AclComponent,
    AclCreateComponent
  ],
  imports: [
    CommonModule,
    AccessControlListRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AccessControlListModule { }
