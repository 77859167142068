import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { HttpClientWrapperService } from '../services/http-client-wrapper.service';

@Component({
  selector: 'app-leadgeneration-payment-suceess',
  templateUrl: './leadgeneration-payment-suceess.component.html',
  styleUrls: ['./leadgeneration-payment-suceess.component.scss']
})
export class LeadgenerationPaymentSuceessComponent implements OnInit {

  constructor(
    private config: ConfigService,
    private http: HttpClientWrapperService
  ) { }

  ngOnInit(): void {
    this.sendTransactionIdAftersuccess()
    this.config.json.paymentStatus ="Success"
    localStorage.setItem("paymentStatus",'Success');
  }

  pageClose = true
  timerSec = 10
  sendTransactionIdAftersuccess() {
  //   const url = `${this.config.json.apiEndpoint}wallet/paymentOnSuccessOrFailure`
  //   this.http.post(url, { txnid: localStorage.getItem('transactionId') }).subscribe({
  //     next: () => {
        let interval;
        // localStorage.removeItem("transactionId");
        interval = setInterval(() => {
          this.timerSec -= 1
          if (this.timerSec == 0) {
            clearInterval(interval)
          }
        }, 1000)
        setTimeout(() => {
          window.close()
        }, 1000);
      }
  //   })
  }
// }
