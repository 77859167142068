import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PathNames } from 'src/app/constants/path-names';
import { LCOInfo } from 'src/app/models/lco/lco-info';
import { StbInfo } from 'src/app/models/stb/stb-info';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-stb',
  templateUrl: './create-stb.component.html',
  styleUrls: ['./create-stb.component.scss']
})
export class CreateSTBComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private http: HttpClientWrapperService,
    private router: Router,
    private config: ConfigService
  ) { }

  stb = {} as StbInfo;

  public error: any;

  stbForm: FormGroup;

  isEdit = false;
  macPattern = '^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$';

  lcoList: LCOInfo[] = [];

  ngOnInit(): void {
    this.getPathParam();
    this.initForm();
  }

  getPathParam(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== PathNames.create) {
      this.isEdit = true;
      this.fetchEditData(id);
    } else {
      this.fetchSaveData();
    }
  }

  checkFormValidity(): boolean {
    // console.log(this.stbForm)
    return !this.stbForm.valid
  }


  fetchEditData(id: string): void {
    forkJoin([this.fetchRecordObs(id), this.fetchLCOListObs()]).subscribe(data => {
      this.handleFetchRecord(data[0]);
      this.handleFetchLCOList(data[1]);
    });
  }

  fetchSaveData(): void {
    forkJoin([this.fetchLCOListObs()]).subscribe(data => {
      this.handleFetchLCOList(data[0]);
    });
  }

  fetchRecordObs(id: string): Observable<StbInfo> {
    const url = this.config.json.apiEndpointRdbms + 'stb/getSTB/' + id;
    return this.http.get(url);
  }

  fetchLCOListObs(): Observable<LCOInfo[]> {
    const url = this.config.json.apiEndpointRdbms + 'lco/getLCOs';
    return this.http.get(url);
  }


  handleFetchRecord(data: StbInfo): void {
    this.stb = data;
    // console.log(data)
    if (data[0] === "error") {
      alert(data["error"]["message"])
    }
    this.updateForm();
  }

  handleFetchLCOList(data: LCOInfo[]): void {
    // //// console.log(data['data'])
    this.lcoList = data['data'];
  }

  initForm(): void {
    this.stbForm = new FormGroup({
      // macAddress: new FormControl('', {
      //   validators: [Validators.required, Validators.pattern(this.macPattern)],
      //   asyncValidators: [this.macAddressValidator()]
      // }),
      ethMacAddress: new FormControl('', {
        validators: [Validators.required],
        // asyncValidators: [this.macAddressValidator()]
      }),
      wifiMacAddress: new FormControl('', {
        validators: [Validators.required],
        // asyncValidators: [this.macAddressValidator()]
      }),

      serialNo: new FormControl('', [Validators.required]),
      manufacturer: new FormControl('', [Validators.required]),
      totalStorage: new FormControl('', [Validators.required, Validators.min(0)]),
      model: new FormControl('', [Validators.required]),
      osVersion: new FormControl('', [Validators.required]),
      swVersion: new FormControl('', [Validators.required]),
      networkType: new FormControl('', [Validators.required]),
      activationStatus: new FormControl(1, [Validators.required]),
      blacklistStatus: new FormControl({ value: 0, disabled: true }, [ Validators.required]),
      faultyStatus: new FormControl(0, [Validators.required]),
      assignedFlag: new FormControl({ value: 0, disabled: true }, [Validators.required]),
      lcoId: new FormControl(null, []),
    });
  }
  get serialNo(): FormControl {
    return this.stbForm.get('serialNo') as FormControl;
  }

  get manufacturer(): FormControl {
    return this.stbForm.get('manufacturer') as FormControl;
  }

  get networkType(): FormControl {
    return this.stbForm.get('networkType') as FormControl;
  }

  get osVersion(): FormControl {
    return this.stbForm.get('osVersion') as FormControl;
  }

  get swVersion(): FormControl {
    return this.stbForm.get('swVersion') as FormControl;
  }

  get wifiMacAddress(): FormControl {
    return this.stbForm.get('wifiMacAddress') as FormControl;
  }

  get ethMacAddress(): FormControl {
    return this.stbForm.get('ethMacAddress') as FormControl;
  }

  get totalStorage(): FormControl {
    return this.stbForm.get('totalStorage') as FormControl;
  }

  get model(): FormControl {
    return this.stbForm.get('model') as FormControl;
  }

  updateForm(): void {
    this.stbForm.get('ethMacAddress').setValue(this.stb.ethernetMac);
    this.stbForm.get('wifiMacAddress').setValue(this.stb.wifiMac);
    this.stbForm.get('serialNo').setValue(this.stb.serialNo);
    this.stbForm.get('manufacturer').setValue(this.stb.manufacturer);
    this.stbForm.get('totalStorage').setValue(this.stb.totalStorage);
    this.stbForm.get('model').setValue(this.stb.model);
    this.stbForm.get('osVersion').setValue(this.stb.osVersion);
    this.stbForm.get('swVersion').setValue(this.stb.swVersion);
    this.stbForm.get('networkType').setValue(this.stb.networkType);
    this.stbForm.get('activationStatus').setValue(this.stb.activationStatus);
    this.stbForm.get('blacklistStatus').setValue(this.stb.blacklistStatus);
    this.stbForm.get('faultyStatus').setValue(this.stb.faultyStatus);
    this.stbForm.get('assignedFlag').setValue(this.stb.assignedFlag);
    this.stbForm.get('lcoId').setValue(this.stb.lcoId);
  }


  persist(): void {
    this.updateModel();
    this.isEdit ? this.update() : this.save();
  }

  save(): void {
    const url = this.config.json.apiEndpointRdbms + 'stb/createSTB';
    this.http.post(url, this.stb).subscribe(data => {
      this.router.navigate(['/manage/stb', 'list']);
    },
      // error => {
      //   this.error = error;
      //   window.alert(error.error["message"])}
    );
  }

  update(): void {
    const url = this.config.json.apiEndpointRdbms + 'stb/updateSTB';

    this.http.put(url, this.stb).subscribe(data => {
      this.router.navigate(['/manage/stb', 'list']);
    },
      error => {
        // //// console.log(error);
        this.error = error;
        // window.alert(error.error["message"])
      });
  }

  updateModel(): void {
    this.stb.ethernetMac = this.stbForm.get('ethMacAddress').value;
    this.stb.wifiMac = this.stbForm.get('wifiMacAddress').value;
    this.stb.serialNo = this.stbForm.get('serialNo').value;
    this.stb.manufacturer = this.stbForm.get('manufacturer').value;
    this.stb.totalStorage = this.stbForm.get('totalStorage').value;
    this.stb.model = this.stbForm.get('model').value;
    this.stb.osVersion = this.stbForm.get('osVersion').value;
    this.stb.swVersion = this.stbForm.get('swVersion').value;
    this.stb.networkType = this.stbForm.get('networkType').value;
    this.stb.activationStatus = this.stbForm.get('activationStatus').value;
    this.stb.blacklistStatus = this.stbForm.get('blacklistStatus').value;
    this.stb.faultyStatus = this.stbForm.get('faultyStatus').value;
    this.stb.assignedFlag = this.stbForm.get('assignedFlag').value;
    this.stb.lcoId = this.stbForm.get('lcoId').value;
  }




  // **************************************************************************************
  // Custom Async Validators
  // **************************************************************************************

  macAddressValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkMacAddress(control.value).pipe(
        map(res => {
          // duplicate is the key name in response coming from server
          return res.duplicate ? { duplicate: true } : null;
        })
      );
    };
  }

  checkMacAddress(value: string): Observable<any> {
    const url = this.config.json.apiEndpoint + 'stb/duplicateMacAddressCheck';
    const body = {
      macAddress: value,
      id: this.isEdit ? this.stb.id : null
    };

    return this.http.post(url, body);
  }
}
