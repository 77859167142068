<div class="cms__content">
    <app-reports-header></app-reports-header>
    <div class="top__heading">
        <h3> BSNL Subscribers List Report</h3>
        <a [routerLink]="['/reports/custom-reports']"><button class="default__button float-right">Back</button></a>
    </div>
    <div class="clearfix"></div>

    <div class="filter-download_container">
        <div class="filter_container">
            <div class="label-container">
                <label for="">Select Date :</label>
            </div>
            <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
            <div class="div label-container">
                <label class="report_selection_label"> LCO</label>
            </div>

            <select class="custom__field report_selection" id="statusChangeApi" [(ngModel)]="lcoValue"
                (change)="fetchData()">
                <option value="0">ALL</option>
                <option *ngFor="let lco of lcoList" [ngValue]="lco.id">
                    {{ lco.name }}
                </option>
            </select>
        </div>
        <div class="download__report col-md-6">
            <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
                PDF
                <img src="./assets/pdf.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
                Excel
                <img src="./assets/excel.svg" alt="download" />
            </button>
            <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
                CSV
                <img src="./assets/csv.svg" alt="download" /> </button>

        </div>

    </div>


    <div class="third_box">
        <div class="col-12 col-md-6 report">
            <p>
                Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
            </p>
        </div>
    </div>


    <div class="col-12 list__table">
        <div class="row ">
            <div class="custom__table__filter">
                <span>
                    Show
                    <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
                        <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
                    </select>
                    Entries
                </span>
                <div class="col-12 col-md-6 table-search">
                    <div class="table__right">
                        <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span>
                            record{{reportList.length == 1?
                            '' : 's'}}</p>
                    </div>
                    <div class="search__container">
                        <input type="text" class="custom__field" id="searchKeyword" (keydown.enter)="onSearch()"
                            [(ngModel)]="paginationObj.searchKeyword" />
                        <button (click)="onSearch()" (keydown.enter)="onSearch()">
                            <img src="./assets/search.png" alt="search" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="scroll">

                <table class="custom__iptv__table" #reportTable id="print">
                    <thead>
                        <tr>
                            <th>SL No</th>
                            <th>Subscriber Id</th>
                            <th>Subscriber Name</th>
                            <th>LCO Name</th>
                            <th>Landline</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>STB IDs</th>
                            <th>Bouquets</th>
                            <th>Reference Id</th>
                            <th>Exchange Code</th>
                            <th>Fr Code</th>
                            <th>RMN</th>
                            <th>Zone</th>
                            <th>Circle</th>
                            <th>Date</th>


                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="reportList.length == 0 && loadingFlag">
                            <td style="text-align: center !important" colspan="18">
                                <app-custom-spinner></app-custom-spinner>
                            </td>
                        </tr>
                        <tr *ngIf="!loadingFlag && reportList.length == 0">
                            <td style="text-align: center !important" colspan="18">
                                {{ isLoad }}
                            </td>
                        </tr>
                        <tr *ngFor="let report of reportList; let j = index">
                            <td>{{ j + 1 }}</td>
                            <td>{{report.subscriberId ? report.subscriberId : '---'}}</td>
                            <td>{{report.subscriberName ? report.subscriberName : '---'}}</td>
                            <td>{{report.lcoName ? report.lcoName : '---'}}</td>
                            <td>{{report.phoneNumber ? report.phoneNumber : '---'}}</td>
                            <td>{{report.mobileNumber ? report.mobileNumber : '---'}}</td>
                            <td>{{report.email ? report.email : '---'}}</td>
                            <td>{{report.address ? report.address : '---'}}</td>
                            <td>{{report.stbIds ? report.stbIds : '---'}}</td>
                            <td>{{report.bouquets ? report.bouquets : '---'}}</td>
                            <td>{{report.referenceId ? report.referenceId : '---'}}</td>
                            <td>{{report.exchangeCode ? report.exchangeCode : '---'}}</td>
                            <td>{{report.frCode ? report.frCode : '---'}}</td>
                            <td>{{report.rmn ? report.rmn : '---'}}</td>
                            <td>{{report.zone ? report.zone : '---'}}</td>
                            <td>{{report.circle ? report.circle : '---'}}</td>
                            <td>{{report.createdDate ? report.createdDate : '---'}}</td>

                        </tr>
                        <tr *ngIf="!loadingFlag && reportList.length === []">
                            <td style="text-align: center !important" colspan="4">
                                No Records.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="custom__pagination">
                <ul>
                    <li>
                        <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
                            <img src="./assets/firstPage.png" /></button>
                    </li>
                    <li>
                        <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
                            <img src="./assets/previousPage.png" /></button>
                    </li>
                    <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
                    <li>
                        <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
                            <img src="./assets/nextPage.png" /></button>
                    </li>
                    <li>
                        <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
                            <img src="./assets/lastPage.png" /></button>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</div>