<div class="cms__content">
  <app-reports-header></app-reports-header>

  <div class="top__heading">
    <h3>Suspended Report</h3>
    <a [routerLink]="['/reports/trai']"
      ><button class="default__button float-right">Back</button></a
    >
  </div>
  <div class="clearfix"></div>

  <!-- <div class="row">
    <div class="filter__group col-12 col-md-6" id="date_selector">
      <label for="">Select Date </label>
      <div class="date_box">
        <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
      </div>
    </div>
    <div class="download__report col-md-6">
      <button class="default__button" (click)="downloadTablePdf()" title="Download PDF">
        PDF
        <img src="./assets/pdf.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableExcel()" title="Download Excel">
        Excel
        <img src="./assets/excel.svg" alt="download" />
      </button>
      <button class="default__button" (click)="downloadTableCSV()" title="Download CSV">
				CSV
				<img src="./assets/csv.svg" alt="download" />
			</button>
    </div>
  </div>

  <div class="col-12 col-md-6 report_selection_block">
    <label class="report_selection_label">Select Channel</label>
    <select class="custom__field report_selection" id="selctedChannel" [(ngModel)]="selectedChannelId" (change)='channelSelect()'>
      <option value = 0>ALL</option>
      <option *ngFor="let ch of channelList" value ={{ch.bouquet_id}} >
        {{ch.name}}
      </option>
    </select>
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6">
      <p>Report generated on : {{currentDate | date:'dd-MM-yyyy HH:mm:ss'}}</p>
    </div>
    <div class="search__container">
      <input type="text" autocomplete="off" class="custom__field" id="searchKeyword" (keydown.enter)="searchKeyword()" />
      <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()" ><img src="./assets/search.png" alt="search" /></button>
    </div>
  </div> -->

  <div class="filter-download_container">
    <div class="filter_container">
      <div class="label-container">
        <label for="">Select Date :</label>
      </div>
      <app-date-picker (dateChange)="dateChange($event)"></app-date-picker>
      <div class="div label-container">
        <label for="">Search LCO :</label>
      </div>
      <div class="search_param">
        <input type="text" class="custom__field" id="searchUser" />
        <button class="search_btn" (click)="startSearch()">Search</button>
        <div class="search" *ngIf="openSearch">
          <app-lco-search
            [searchText]="searchText"
            (userDetail)="getUserDetail($event)"
          ></app-lco-search>
        </div>
      </div>
    </div>
    <div class="download_container">
      <div class="download__report">
        <button
          class="default__button"
          (click)="downloadTablePdf()"
          title="Download PDF"
        >
          PDF
          <img src="./assets/pdf.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableExcel()"
          title="Download Excel"
        >
          Excel
          <img src="./assets/excel.svg" alt="download" />
        </button>
        <button
          class="default__button"
          (click)="downloadTableCSV()"
          title="Download Excel"
        >
          CSV
          <img src="./assets/csv.svg" alt="download" />
        </button>
      </div>
    </div>
  </div>

  <div class="third_box">
    <div class="col-12 col-md-6 report-generation__container report">
      <p>
        Report generated on : {{ currentDate | date : "dd-MM-yyyy HH:mm:ss" }}
      </p>
    </div>
    <div style="display: flex; gap: 20px">
      <div class="table__right">
        <p class="table__summary">
          Total <span>{{ reportList?.length }}</span> record{{
            reportList?.length == 1 ? "" : "s"
          }}
        </p>
      </div>
      <div class="search__container">
        <input
          type="text"
          autocomplete="off"
          class="custom__field"
          id="searchKeyword"
          (keydown.enter)="searchKeyword()"
        />
        <button (click)="searchKeyword()" (keydown.enter)="searchKeyword()">
          <img src="./assets/search.png" alt="search" />
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 list__table">
    <!-- <app-reports-header #reportTable id="reportTable"></app-reports-header> -->
    <div class="row scroll">
      <table class="custom__iptv__table" #reportTable id="reportTable">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Subscriber Name</th>
            <th>Ethernet Mac Address</th>
            <th>LCO Name</th>
            <th>LCO ID</th>
            <th>Subscriber Code</th>
            <th>Subscriber ID</th>
            <th>Suspended Date</th>
            <th>STB Serial Number</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="reportList?.length <= 0 && !isLoading">
            <td class="loading" colspan="11" style="text-align: center">
              {{ isLoad }}
            </td>
          </tr>
          <tr *ngIf="isLoading">
            <td colspan="11" style="text-align: center">
              <app-custom-spinner></app-custom-spinner>
            </td>
          </tr>
          <tr *ngFor="let report of reportList; let j = index">
            <td>{{ j + 1 }}</td>
            <td>{{ report.subscriberName ? report.subscriberName : "---" }}</td>
            <td>
              {{
                report.ethernetMacAddress ? report.ethernetMacAddress : "---"
              }}
            </td>
            <td>{{ report.lcoName ? report.lcoName : "---" }}</td>
            <td>{{ report.lcoId ? report.lcoId : "---" }}</td>
            <td>{{ report.subscriberCode ? report.subscriberCode : "---" }}</td>
            <td>{{ report.subscriberId ? report.subscriberId : "---" }}</td>
            <td>{{ report.suspendedDate }}</td>
            <td>{{ report.stbSerialNo }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
