export default [
    {
        "id": 1,
        "parentId": 0,
        "name": "Dashboard",
        "description": "Dashboard",
        "url": null,
        "displayOrder": "0",
        "status": 1,
        "createdDate": 1636671988000,
        "updatedDate": 1636675289000,
        "children": []
    },
    {
        "id": 2,
        "parentId": 0,
        "name": "Manage",
        "description": "Manage",
        "url": null,
        "displayOrder": "1",
        "status": 1,
        "createdDate": 1636671992000,
        "updatedDate": 1636675303000,
        "children": [
            {
                "id": 5,
                "parentId": 2,
                "name": "Subscriber",
                "description": "Subscriber",
                "url": null,
                "displayOrder": "13",
                "status": 1,
                "createdDate": 1636672000000,
                "updatedDate": 1636672430000
            },
            {
                "id": 8,
                "parentId": 2,
                "name": "Location",
                "description": "Location",
                "url": null,
                "displayOrder": "16",
                "status": 1,
                "createdDate": 1636672012000,
                "updatedDate": 1636672430000
            },
            {
                "id": 6,
                "parentId": 2,
                "name": "STB",
                "description": "STB",
                "url": null,
                "displayOrder": "14",
                "status": 1,
                "createdDate": 1636672002000,
                "updatedDate": 1636672430000
            },
            {
                "id": 4,
                "parentId": 2,
                "name": "LCO",
                "description": "LCO",
                "url": null,
                "displayOrder": "12",
                "status": 1,
                "createdDate": 1636671998000,
                "updatedDate": 1636672430000
            },
            {
                "id": 3,
                "parentId": 2,
                "name": "MSO",
                "description": "MSO",
                "url": null,
                "displayOrder": "11",
                "status": 1,
                "createdDate": 1636671997000,
                "updatedDate": 1636672430000
            },
            {
                "id": 7,
                "parentId": 2,
                "name": "Subscriber Groups",
                "description": "Subscriber Groups",
                "url": null,
                "displayOrder": "15",
                "status": 1,
                "createdDate": 1636672008000,
                "updatedDate": 1636672430000
            }
        ]
    },
    {
        "id": 9,
        "parentId": 0,
        "name": "Content",
        "description": "Content",
        "url": null,
        "displayOrder": "2",
        "status": 1,
        "createdDate": 1636672016000,
        "updatedDate": 1636675316000,
        "children": [
            {
                "id": 13,
                "parentId": 9,
                "name": "Bouquet",
                "description": "Bouquet",
                "url": null,
                "displayOrder": "24",
                "status": 1,
                "createdDate": 1636672026000,
                "updatedDate": 1636672430000
            },
            {
                "id": 12,
                "parentId": 9,
                "name": "Category",
                "description": "Category",
                "url": null,
                "displayOrder": "23",
                "status": 1,
                "createdDate": 1636672024000,
                "updatedDate": 1636672430000
            },
            {
                "id": 11,
                "parentId": 9,
                "name": "Channel",
                "description": "Channel",
                "url": null,
                "displayOrder": "22",
                "status": 1,
                "createdDate": 1636672021000,
                "updatedDate": 1636672430000
            },
            {
                "id": 10,
                "parentId": 9,
                "name": "Broadcaster",
                "description": "Broadcaster",
                "url": null,
                "displayOrder": "21",
                "status": 1,
                "createdDate": 1636672019000,
                "updatedDate": 1636672430000
            }
        ]
    },
    {
        "id": 14,
        "parentId": 0,
        "name": "Communication",
        "description": "Communication",
        "url": null,
        "displayOrder": "3",
        "status": 1,
        "createdDate": 1636672029000,
        "updatedDate": 1636675320000,
        "children": [
            {
                "id": 16,
                "parentId": 14,
                "name": "OSD",
                "description": "OSD",
                "url": null,
                "displayOrder": "32",
                "status": 1,
                "createdDate": 1636672032000,
                "updatedDate": 1636672430000
            },
            {
                "id": 17,
                "parentId": 14,
                "name": "Fingerprint",
                "description": "Fingerprint",
                "url": null,
                "displayOrder": "33",
                "status": 1,
                "createdDate": 1636672035000,
                "updatedDate": 1636672430000
            },
            {
                "id": 15,
                "parentId": 14,
                "name": "Mail",
                "description": "Mail",
                "url": null,
                "displayOrder": "31",
                "status": 1,
                "createdDate": 1636672031000,
                "updatedDate": 1636672430000
            }
        ]
    },
    {
        "id": 18,
        "parentId": 0,
        "name": "Plugins",
        "description": "Plugins",
        "url": null,
        "displayOrder": "4",
        "status": 1,
        "createdDate": 1636672040000,
        "updatedDate": 1636675324000,
        "children": [
            {
                "id": 19,
                "parentId": 18,
                "name": "Transcoder",
                "description": "Transcoder",
                "url": null,
                "displayOrder": "41",
                "status": 1,
                "createdDate": 1636672044000,
                "updatedDate": 1636672430000
            },
            {
                "id": 20,
                "parentId": 18,
                "name": "EPG",
                "description": "EPG",
                "url": null,
                "displayOrder": "42",
                "status": 1,
                "createdDate": 1636672047000,
                "updatedDate": 1636672430000
            }
        ]
    },
    {
        "id": 21,
        "parentId": 0,
        "name": "Reports",
        "description": "Reports",
        "url": null,
        "displayOrder": "5",
        "status": 1,
        "createdDate": 1636672056000,
        "updatedDate": 1636675329000,
        "children": [
            {
                "id": 22,
                "parentId": 21,
                "name": "TRAI",
                "description": "TRAI",
                "url": null,
                "displayOrder": "51",
                "status": 1,
                "createdDate": 1636672058000,
                "updatedDate": 1636672430000
            }
        ]
    },
    {
        "id": 23,
        "parentId": 0,
        "name": "Logs",
        "description": "Logs",
        "url": null,
        "displayOrder": "6",
        "status": 1,
        "createdDate": 1636672061000,
        "updatedDate": 1636675333000,
        "children": [
            {
                "id": 32,
                "parentId": 23,
                "name": "Bouquet Modification",
                "description": "Bouquet Modification",
                "url": null,
                "displayOrder": "69",
                "status": 1,
                "createdDate": 1636672136000,
                "updatedDate": 1636672430000
            },
            {
                "id": 31,
                "parentId": 23,
                "name": "Subscription/Desubsription",
                "description": "Subscription/Desubsription",
                "url": null,
                "displayOrder": "68",
                "status": 1,
                "createdDate": 1636672130000,
                "updatedDate": 1636672430000
            },
            {
                "id": 25,
                "parentId": 23,
                "name": "User Activity",
                "description": "User Activity",
                "url": null,
                "displayOrder": "62",
                "status": 1,
                "createdDate": 1636672068000,
                "updatedDate": 1636672430000
            },
            {
                "id": 33,
                "parentId": 23,
                "name": "Channel Modification",
                "description": "Channel Modification",
                "url": null,
                "displayOrder": "70",
                "status": 1,
                "createdDate": 1636672140000,
                "updatedDate": 1636672430000
            },
            {
                "id": 29,
                "parentId": 23,
                "name": "Complete Logs",
                "description": "Complete Logs",
                "url": null,
                "displayOrder": "66",
                "status": 1,
                "createdDate": 1636672108000,
                "updatedDate": 1636672430000
            },
            {
                "id": 28,
                "parentId": 23,
                "name": "STB Connection",
                "description": "STB Connection",
                "url": null,
                "displayOrder": "65",
                "status": 1,
                "createdDate": 1636672105000,
                "updatedDate": 1636672430000
            },
            {
                "id": 26,
                "parentId": 23,
                "name": "Bouquet Status",
                "description": "Bouquet Status",
                "url": null,
                "displayOrder": "63",
                "status": 1,
                "createdDate": 1636672095000,
                "updatedDate": 1636672430000
            },
            {
                "id": 24,
                "parentId": 23,
                "name": "Authentication",
                "description": "Authentication",
                "url": null,
                "displayOrder": "61",
                "status": 1,
                "createdDate": 1636672064000,
                "updatedDate": 1636672430000
            },
            {
                "id": 27,
                "parentId": 23,
                "name": "Channel Status",
                "description": "Channel Status",
                "url": null,
                "displayOrder": "64",
                "status": 1,
                "createdDate": 1636672098000,
                "updatedDate": 1636672430000
            },
            {
                "id": 30,
                "parentId": 23,
                "name": "STB Activate/Deactivate",
                "description": "STB Activate/Deactivate",
                "url": null,
                "displayOrder": "67",
                "status": 1,
                "createdDate": 1636672123000,
                "updatedDate": 1636672430000
            }
        ]
    },
    {
        "id": 34,
        "parentId": 0,
        "name": "Settings",
        "description": "Settings",
        "url": null,
        "displayOrder": "7",
        "status": 1,
        "createdDate": 1636672142000,
        "updatedDate": 1636675337000,
        "children": [
            {
                "id": 36,
                "parentId": 34,
                "name": "Access Control",
                "description": "Access Control",
                "url": null,
                "displayOrder": "72",
                "status": 1,
                "createdDate": 1636672151000,
                "updatedDate": 1636672430000
            },
            {
                "id": 35,
                "parentId": 34,
                "name": "General Settings",
                "description": "General Settings",
                "url": null,
                "displayOrder": "71",
                "status": 1,
                "createdDate": 1636672148000,
                "updatedDate": 1636672430000
            }
        ]
    },
    {
        "id": 37,
        "parentId": 0,
        "name": "Accounts",
        "description": "Accounts",
        "url": null,
        "displayOrder": "8",
        "status": 1,
        "createdDate": 1636672153000,
        "updatedDate": 1636675339000,
        "children": [
            {
                "id": 38,
                "parentId": 37,
                "name": "Subscriber Revenue",
                "description": "Subscriber Revenue",
                "url": null,
                "displayOrder": "81",
                "status": 1,
                "createdDate": 1636672158000,
                "updatedDate": 1636672430000
            },
            {
                "id": 39,
                "parentId": 37,
                "name": "MSO Payments",
                "description": "MSO Payments",
                "url": null,
                "displayOrder": "82",
                "status": 1,
                "createdDate": 1636672161000,
                "updatedDate": 1636672430000
            },
            {
                "id": 40,
                "parentId": 37,
                "name": "Invoice",
                "description": "Invoice",
                "url": null,
                "displayOrder": "83",
                "status": 1,
                "createdDate": 1636672163000,
                "updatedDate": 1636672430000
            }
        ]
    }
]