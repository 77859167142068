// Using generics for reusability with multiple models
export interface PageInfo<T> {
  metadata: PageMetadata;
  data: T[];
}

export interface PageMetadata {

  pageNumber: number;

  pageSize: number;
  pageElementCount: number;

  totalElementCount: number;
  totalPageCount: number;

  sortBy: string;
  sortOrder: string;
  searchKeyword: string;
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}
