<div class="cms__content">
  <div class="top__heading">
    <h3>Self Registered Users</h3>
  </div>
  <div class="col-12 list__table">
    <div class="row overflow">
      <div class="custom__table__filter">
        <span>
          Show
          <select [(ngModel)]="paginationObj.pageSize" (change)="pageSizeChange()">
            <option *ngFor="let size of paginationObj.pageSizeOptions" [ngValue]="size">{{size}}</option>
          </select>
          Entries
        </span>
        <div class="table__right">
          <p class="table__summary">Total <span>{{paginationObj.totalElements}}</span> records</p>
          <div class="search__container">
            <input type="text" autocomplete="off" class="custom__field" [(ngModel)]="paginationObj.searchKeyword"
              (keyup.enter)="$event.preventDefault(); onSearch()" />
            <button (click)="onSearch()"><img src="./assets/search.png" alt="search" /></button>
          </div>
        </div>
      </div>
      <div class="tableBox">
      <table class="custom__iptv__table">
        <thead>
          <tr>
            <th>
              Sl No.
            </th>
            <th class="label__align" (click)="sortByItem(sortByProperties.id)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
            ID
          </th>
          <th class="label__align" (click)="sortByItem(sortByProperties.userType)"
          [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.userType}">
          UserType
        </th>
            <th (click)="sortByItem(sortByProperties.name)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.name}">
              Name
            </th>

            <th (click)="sortByItem(sortByProperties.companyName)"
            [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.companyName}">
            Company Name
          </th>

            <th>Mobile</th>
            <th>Landline</th>

            <th (click)="sortByItem(sortByProperties.email)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.email}">
              Email
            </th>

            <th class="label__align" (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
             Approval Status
            </th>



            <th class="label__align" (click)="sortByItem(sortByProperties.status)"
              [ngClass]="{'selected': paginationObj.sortBy===sortByProperties.status}">
              Address
            </th>
            <th> Created Date </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngIf="loadingFlag; else showList">
              <td class="loading" colspan="9">
                <app-custom-spinner></app-custom-spinner>
              </td>
          </tr>

          <ng-template #showList>
            <tr *ngFor="let user of userTypeList, let j = index">
              <td>{{((pageNumber-1)*pageSize)+(j+1)}}</td>
              <td>{{user.id? user.id : '---'}}</td>
              <td>{{user.userType=="distributor"?"Distributor":
                user.userType=="lco"?"LCO":'---' }}</td>
              <td>{{user.contactPersonName? user.contactPersonName : '---'}}</td>
              <td>{{user.companyName}}</td>
              <td>{{user.mobileNumber? user.mobileNumber : '---'}}</td>
              <td>{{user.landlineNumber? user.landlineNumber : '---'}}</td>
              <td>{{user.email? user.email : '---'}}</td>
              <td>{{user.approvalStatus === 0 ? "Not Approved" : "Approved"}}</td>
              <td>{{user.address? user.address : '---' }}</td>
              <td>
                {{user.createdDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                <div class="action__items">
                  <button (click)="view(user)">View</button>
                  <!-- <button class="danger"
                    (click)="updateStatus(admin)">{{admin.status===1?'Deactivate':'Activate'}}</button> -->
                </div>
              </td>

            </tr>
          </ng-template>
        </tbody>
      </table>
      </div>
      <div class="custom__pagination">
        <ul>
          <li>
            <button [disabled]="loadingFlag || disableFirstBtn" (click)="pageFirstBtnClick()">
              <img src="./assets/firstPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disablePreviousBtn" (click)="pagePreviousBtnClick()">
              <img src="./assets/previousPage.png" /></button>
          </li>
          <li><span>Page {{paginationObj.pageNumber}} of {{paginationObj.totalPages}}</span></li>
          <li>
            <button [disabled]="loadingFlag || disableNextBtn" (click)="pageNextBtnClick()">
              <img src="./assets/nextPage.png" /></button>
          </li>
          <li>
            <button [disabled]="loadingFlag || disableLastBtn" (click)="pageLastBtnClick()">
              <img src="./assets/lastPage.png" /></button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>

<div class="modal_box" *ngIf="viewModal">
  <div class="modal_box_inner">
    <h3>{{LCODetails.userType=="distributor"?"Distributor":"LCO"}} details</h3>
    <div class="parent">
      <p class="div1">  Name</p>
      <p class="div2">{{LCODetails.contactPersonName}}</p>
      <p class="div3"> Mobile Number</p>
      <p class="div4">{{LCODetails.mobileNumber}}</p>
      <p class="div5"> Aadhar Number</p>
      <p class="div6">{{LCODetails.aadharNumber}}</p>
      <p class="div18"style="text-align:start" >GST Number</p>
      <p class="div19">{{LCODetails.gstNumber? LCODetails.gstNumber:'No GST'}}</p>
      <p class="div7" style="text-align:start">{{LCODetails.userType=="distributor"?"Distributor":"LCO"}} Pan Number</p>
      <p class="div8">{{LCODetails.panNumber}}</p>
      <!-- <div class="div13">
        <h6>Subscriber Document</h6>
      </div> -->
      <div>
        <div class="div9">

          <img class="img" onerror="this.src='assets/imageError.png'"
            [src]="config.json.imagPrefix+LCODetails.aadharUrl" alt="Image not Uploaded">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.aadharUrl)"></div>
        </div>
        <p class="div14">Aadhar Card</p>
      </div>
      <div>
        <div class="div10">
          <img class="img" onerror="this.src='assets/imageError.png'"
            [src]="config.json.imagPrefix+LCODetails.panUrl" alt="Image not Uploaded">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.panUrl)"></div>
        </div>
        <p class="div15">Pan Card</p>
      </div>
      <div>
        <div class="div11">
          <img class="img" onerror="this.src='assets/imageError.png'"
            [src]="config.json.imagPrefix+LCODetails.photoUrl" alt="Image not Uploaded">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.photoUrl)"></div>
        </div>
        <p class="div16">Photo</p>
      </div>
      <div>
        <div class="div12">
          <img class="img" onerror="this.src='assets/imageError.png'"
            [src]="config.json.imagPrefix+LCODetails.gstUrl" alt="Image not Uploaded">
          <div class="hover"
            (click)="openImageInNewTab(config.json.imagPrefix+LCODetails.gstUrl)"></div>
        </div>
        <p class="div17">GST</p>
      </div>
    </div>
    
    <button class="cta float-right" (click)="viewModal = !viewModal"> Close </button>
  </div>
</div>
