import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClientWrapperService } from 'src/app/services/http-client-wrapper.service';
import { ConfigService } from 'src/app/services/config.service';


@Injectable({
  providedIn: 'root'
})
export class ReportTraiDataService {

  constructor(
    private http: HttpClientWrapperService,
    private config: ConfigService
  ) { }



  getSubscriberAuthorizationReport(from: number, to: number): Observable<any> {
    const url = this.config.json.apiEndpoint + 'reports/getSubscriberReport/?fromDate=' + from + '&toDate=' + to;
    return this.http.get(url);
  }

  getSubscriberPackagesReport(from: number, to: number): Observable<any> {
    const url = this.config.json.apiEndpoint + 'reports/getPackageReport/?fromDate=' + from + '&toDate=' + to;
    return this.http.get(url);
  }



  // getOsdReport(from: number, to: number): Observable<any> {
  //   const url = environment.apiEndpoint + 'reports/getOsdReport?fromDate=' + from + '&toDate=' + to;
  //   return this.http.get(url);
  // }

  getOsdReport(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'osd/list?searchKeyword=';
    return this.http.get(url);
  }

  getStbReconciliationcount(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'stb/stbCountReconcilation';
    return this.http.get(url);
  }

  getSubscriberReconciliationcount(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'subscriber/countReconciliation';
    return this.http.get(url);
  }

  getSubscriptionReconciliationcount(from: number, to: number): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'subscriber/subscription/countReconciliation?fromDate=' + from + '&toDate=' + to ;
    return this.http.get(url);
  }

  getchannelCountReconcilation(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'channelCountReconcilation';
    return this.http.get(url);
  }

  getboquetCountReconcilation(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'bouquetCountReconcilation';
    return this.http.get(url);
  }
  getFingerprintReport(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'fingerprint/list?searchKeyword=';
    return this.http.get(url);
  }

  getSubscriberPackagesHistory(from: number, to: number, userId: string): Observable<any> {
    const url = this.config.json.apiEndpoint + 'reports/getSubscriptionReport?fromDate=' + from + '&toDate=' + to + '&userId=' + userId;
    return this.http.get(url);
  }

  getSmsLogReport(from: number, to: number): Observable<any> {
    const url = this.config.json.apiEndpoint + '';
    return this.http.get(url);
  }

  getLCOList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'lco/getLCOs';
    return this.http.get(url);
  }

  getChannelList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'channel/getChannelList?searchKeyword=';
    return this.http.get(url);
  }

  getLCOBilling(lcoId: string, pageNumber: string){
    const url = this.config.json.apiEndpointRdbms + 'lco/lcoShareBilling/'+lcoId+'/'+pageNumber;
    return this.http.get(url);
  }
  getDistributorBilling(distributorId: string, pageNumber: string){
    const url = this.config.json.apiEndpointRdbms + 'distributor/distributorShareBilling/'+distributorId+'/'+pageNumber;
    return this.http.get(url);
  }

  getChannelListAlacarete(){
    const url = this.config.json.apiEndpointRdbms + 'channel/getChannelListAlacarete'
    return this.http.get(url)
  }
  getStbList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'stb/getSTBs';
    return this.http.get(url);
  }

  getBouquetList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'bouquet/getBouquets';
    return this.http.get(url);
  }

  getBroadcasterList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'broadcaster/getBroadcasters';
    return this.http.get(url);
  }

  getLocationList(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'location/getLocations';
    return this.http.get(url);
  }

  getStbStatusReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbStatusReport';
    return this.http.post(url, body);
  }

  getStbFaultyReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbFaultyReport';
    return this.http.post(url, body);
  }

  getStbWarehouseStockReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbWarehouseStockReport';
    return this.http.post(url, body);
  }

  getStbDeployedReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbDeployedReport';
    return this.http.post(url, body);
  }

  getStbLCOStockReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbLCOStockReport';
    return this.http.post(url, body);
  }

  getSubscriberStatusReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getSubscriberStatusReport';
    return this.http.post(url, body);
  }

  getSubscriberDataReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getSubscriberDataReport';
    return this.http.post(url, body);
  }

  getSubscriptionReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getSubscriptionReport';
    return this.http.post(url, body);
  }

  getStbActiveCountReport(body: any): Observable<any> {
    // body['fromDate'] = from.format('YYYY-MM-DD');
    // body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbActiveCountReport';
    return this.http.post(url, body);
  }

  getStbInactiveCountReport( body: any): Observable<any> {
    // body['fromDate'] = from.format('YYYY-MM-DD');
    // body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbDeactiveCountReport';
    return this.http.post(url, body);
  }

  getStbActiveListReport(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbActiveListReport';
    return this.http.post(url, body);
  }

  getStbInactiveListReport(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbDeactiveListReport';
    return this.http.post(url, body);
  }

  getStbBlacklistReport(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbBlacklistReport';
    return this.http.post(url, body);
  }

  getStbLocationWiseReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbLocationWiseReport';
    return this.http.post(url, body);
  }
  getStbLocationWiseReports(body){
    const url = this.config.json.apiEndpointRdbms + 'reports/getStbLocationWiseReport'
    return this.http.post(url, body)
  }

  getBouquetSubscriptionCountReport(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getBouquetSubscriptionCountReport';
    return this.http.post(url, body);
  }

  getBroadcasterChannelWiseReport(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getBroadcasterChannelWiseReport';
    return this.http.post(url, body);
  }

  getBroadcasterWiseChannelsReportMonthly(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getBroadcasterWiseChannelsReport';
    return this.http.post(url, body);
  }
  getSubscriptionwiseSubscriberActiveDeactiveWithLocation(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getSubscriptionwiseSubscriberActiveDeactiveWithLocation';
    return this.http.post(url, body);
  }

  getBroadcasterWiseChannelsWeekwiseReport(body){
    const url = this.config.json.apiEndpointRdbms + 'reports/getBroadcasterWiseChannelsWeekwiseReport';
    return this.http.post(url, body);

  }

  getBouquetwiseChannelReport(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getBouquetWiseChannelsReport';
    return this.http.post(url, body);
  }

  getBouquetSubscriptionReport(body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getBouquetSubscriptionReport'
    return this.http.post(url, body);
  }

  getChannelSubscriptionReport(body: any): Observable<any> {

    const url = this.config.json.apiEndpointRdbms + 'reports/getChannelSubscribersReport';
    return this.http.post(url, body);
  }

  getSuspendedReport(body:{fromDate: string; toDate: string; lcoId: string; searchKeyword: string}){
    let url = this.config.json.apiEndpointRdbms + 'reports/getStbSuspendedlistReport';
    return this.http.post(url,body)
  }

  getPackageAgeingReport(from: any, to: any, body: any): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getAgeingOfBouquetChannelReport';
    return this.http.post(url, body);
  }

  getSubscribersListReport(from: any, to: any, body: any): Observable<any> {
    // const url = environment.apiEndpointRdbms + 'subscriber/getSubscribers';
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'reports/getSubscriberListReport';
    return this.http.post(url, body);
  }

  getSubscribersListOnlyReport(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'subscriber/getSubscribers';
    return this.http.get(url);
  }

  getBouquetComposition(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'logs/getBouquetComposition';
    return this.http.post(url, body);
  }

  getBouquetCompositionChangeHistory(from: any, to: any, body: any): Observable<any> {
    body['fromDate'] = from.format('YYYY-MM-DD');
    body['toDate'] = to.format('YYYY-MM-DD');
    const url = this.config.json.apiEndpointRdbms + 'logs/getBouquetCompositionChangeHistory';
    return this.http.post(url, body);
  }

  getTodaysExpiryReport(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getTodaysExpiryReports/?searchKeyword=';
    return this.http.get(url);
  }

  getNextFifteenDaysExpiryReport(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getNextFifteenDaysExpiryReport/?searchKeyword=';
    return this.http.get(url);
  }

  getNextSevenDaysExpiryReport(): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'reports/getNextSevenDaysExpiryReport/?searchKeyword=';
    return this.http.get(url);
  }

  //for gigantic
  getSubscriberList(){
    // //// console.log(this.config.json.apiEndpointRdbms)
    const url = this.config.json.apiEndpointRdbms + 'subscriber/getsubscribersListReport'
    return this.http.get(url);
  }

  getSubscriberListReport(){
    // getsubscribersListReport
    // //// console.log(this.config.json.apiEndpointRdbms)
    const url = this.config.json.apiEndpointRdbms + 'subscriber/getsubscribersListReport/?searchKeyword='
    // const url='http://192.168.10.19:7080/admin/api/subscriber/getsubscribersListReport'
    return this.http.get(url);
  }
  getSubscriberOrderHistoryActive(){
    const url = this.config.json.apiEndpointRdbms +'subscriber/getsubscribersOrderHistoryActive/?searchKeyword='
    // //// console.log(url)
    return this.http.get(url);
  }
  getSubscriberOrderHistoryInActive(){
    const url = this.config.json.apiEndpointRdbms +'subscriber/getsubscribersOrderHistoryInactive/?searchKeyword='
    // //// console.log(url)
    return this.http.get(url);
  }
  getPackageAgeingActive(){
    const url= this.config.json.apiEndpointRdbms+'subscriber/getPackageAgeingActiveList/?searchKeyword='
    return this.http.get(url);
  }
  getPackageAgeingInActive(){
    const url= this.config.json.apiEndpointRdbms+'subscriber/getPackageAgeingInactiveList/?searchKeyword='
    return this.http.get(url);
  }
  getBouquetWiseChannel(body){
    //// console.log(body)
    const url= this.config.json.apiEndpointRdbms+'bouquet/getPackageWiseChannels'
    return this.http.post(url,body);
  }
  getSubscriberDetails(){
    const url= this.config.json.apiEndpointRdbms+'subscriber/getSubscriberDetails/?searchKeyword='
    return this.http.get(url)
  }
  getSubscriberWiseSubscriptionActive(body){
    const url= this.config.json.apiEndpointRdbms+'subscriber/subscriberWiseSubscriptionsActiveFromToDate'
    return this.http.post(url,body)
  }
  getSubscriberWiseSubscriptionInActive(body){
    const url= this.config.json.apiEndpointRdbms+'subscriber/getSubscriberWiseSubscriptionsInactive'
    return this.http.post(url,body)
  }
  getStateWiseSubscriber(state?, searchKeyword?, status?){
    // // console.log("Called service")
    const url= this.config.json.apiEndpointRdbms+`subscriber/getStateWiseSubscriber/?state=${(state? state:'')}&searchKeyword=${(searchKeyword? searchKeyword:'')}&status=${(status? status:'')}`
    // // console.log(url)
    return this.http.get(url)
  }
  getBouquetChangesHistory(body): Observable<any> {
    const url = this.config.json.apiEndpointRdbms + 'logs/getBouquetModificationHistory';
    return this.http.post(url, body);
  }
}
