import { Component, OnInit } from '@angular/core';
import { ExcelGenAndUploadService } from '../services/excel-gen-and-upload.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-create-channels',
  templateUrl: './create-channels.component.html',
  styleUrls: ['./create-channels.component.scss']
})
export class CreateChannelsComponent implements OnInit {

  constructor(
    private excelgen: ExcelGenAndUploadService,
    private config: ConfigService

  ) { }

  ngOnInit(): void {
  }

  sampleData = [
    {
      'Channel Name': 'Mohan TV HD',
      'Channel Number': '13',
      'Description': 'Test Channel',
      'Channel Custom ID': '39',
      'Channel URL': 'http://www.mohanTV.com',
      'Language': 'English',
      'Protocol': 'UDP',
      'Channel Quality': 'FHD',
    },
    {
      'Channel Name': 'Mohan TV',
      'Channel Number': '14',
      'Description': 'Test Channel',
      'Channel Custom ID': '52',
      'Channel URL': 'http://www.mohanTV.com',
      'Language': 'English',
      'Protocol': 'UDP',
      'Channel Quality': 'SD',
    },
  ];

  saveSample() {
    this.excelgen.saveSample(this.sampleData, 'create_channels')
  }

  clearInput() {
    let input = document.getElementById('chooseFile') as HTMLInputElement
    this.excelgen.deselectSelectedFiles(input)
    this.isSubmitReady = false

  }

  isSubmitReady = false;
  message: string = ''
  errorBody = []
  errorAvailable = false
  e
  selectedFile(e) {
    this.message = ''
    this.e = e
    const url = this.config.json.apiEndpoint + 'bulkUpload/validation/channels';
    this.excelgen.uploadFileforValidation(e, url).subscribe({
      next: (res: any) => {
        // // console.log(res)
        if (res.result) {
          this.isSubmitReady = true
          this.errorAvailable = false
        } else {
          this.errorAvailable = true
          this.errorBody = res.errorBody
        }
      },
      error: (error) => {
        this.message = error.error.message
      }
    });
  }

  successMessage = 'No errors Found'
  uploadFile() {
    const url = this.config.json.apiEndpoint + 'bulkUpload/channels';
    this.excelgen.uploadFileForSubmit(this.e, url).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.successMessage = res.message
        } else {
          this.errorAvailable = true
          this.successMessage = res.message
          this.errorBody = res.errorBody
        }
      }
    })
  }

}
